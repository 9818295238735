import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import localeData from 'dayjs/plugin/localeData';
import relativeTime from 'dayjs/plugin/relativeTime';
import { DateFormats } from './types';
import { DATE_FORMAT_CONFIGS, DateFormatSelectOptions } from './constants';

dayjs.extend(localeData);
dayjs.extend(calendar);
dayjs.extend(relativeTime);

function calendarFunction(
  date: dayjs.ConfigType,
  referenceTime?: dayjs.ConfigType,
  formats?: object
) {
  return dayjs(date).calendar(referenceTime, formats);
}

function fromNow(date: dayjs.ConfigType) {
  return dayjs(date).fromNow();
}

class Format {
  formatKey: DateFormats =
    (localStorage.getItem('date_format') as DateFormats) || 'US';

  formatDate(date: dayjs.ConfigType) {
    return dayjs(date).format(DATE_FORMAT_CONFIGS[this.formatKey].date);
  }

  formatDateTime(date: dayjs.ConfigType) {
    return dayjs(date).format(DATE_FORMAT_CONFIGS[this.formatKey].dateTime);
  }

  updateFormat(format: DateFormats) {
    localStorage.setItem('date_format', format);

    this.formatKey = format;
  }
}

const DateFormat = new Format();

export {
  dayjs,
  calendarFunction as calendar,
  fromNow,
  DateFormat,
  DATE_FORMAT_CONFIGS,
  DateFormatSelectOptions,
  type DateFormats,
};
