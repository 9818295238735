import styled from '@emotion/styled';

export const Conteiner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.5);
`;
