import { DefaultLink, Link, Text } from '../Text';
import { ExternalRefLinkViewProps } from './types';
import { useExternalRefLinkController } from './controller';

export function ExternalRefLink({
  external_ref,
  size = 14,
  withoutIcon,
  ...props
}: ExternalRefLinkViewProps) {
  const { href, text } = useExternalRefLinkController(external_ref);

  if (href) {
    if (withoutIcon) {
      return (
        <DefaultLink
          {...props}
          key={external_ref.id}
          to={href}
          text={text}
          size={size}
        />
      );
    }

    return (
      <Link
        {...props}
        key={external_ref.id}
        to={href}
        text={text}
        size={size}
      />
    );
  }
  return <Text {...props} key={external_ref.id} text={text} size={size} />;
}
