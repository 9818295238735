import { gql } from '@apollo/client';
import { TRANSACTION_TAG_FRAGMENT } from '../../../fragment/tm/TransactionTag';

export const TransactionTags = gql`
  ${TRANSACTION_TAG_FRAGMENT}
  query TransactionTags($page: Int, $per: Int, $q: String) {
    transactionTags(page: $page, per: $per, q: $q)
      @rest(type: "ResponseTransactionTags", path: "/tm/tags?{args}") {
      data @type(name: "TransactionTag") {
        ...TransactionTagFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const TransactionTag = gql`
  ${TRANSACTION_TAG_FRAGMENT}
  query TransactionTag($id: String!) {
    transactionTag(id: $id)
      @rest(type: "ResponseTransactionTag", path: "/tm/tags/{args.id}") {
      data @type(name: "TransactionTag") {
        ...TransactionTagFragment
      }
      success
    }
  }
`;
