import { getColor, getSpace, GridContainer } from '@aid-module/ui';
import styled from '@emotion/styled';

export const Container = styled(GridContainer)`
  :last-child {
    div:first-of-type {
      background: ${getColor('main-deep-2')};
      span {
        font-weight: 700;
        color: ${getColor('main-deep-4')};
      }
    }

    div:last-child {
      display: none;
    }
  }
`;

export const Breadcrumb = styled.div`
  padding: ${getSpace('4')} ${getSpace('10')} ${getSpace('6')};
  background: ${getColor('main-deep-1')};
  border-radius: 4px;
  cursor: pointer;

  span {
    text-transform: capitalize;
  }
`;
