import gql from 'graphql-tag';

export const BlacklistedDeviceInfos = gql`
  query BlacklistedDeviceInfos($page: Int, $per: Int, $q: String) {
    blacklistedDeviceInfos(page: $page, per: $per, q: $q)
      @rest(
        type: "ResponseBlacklistedDeviceInfos"
        path: "/core/client/blacklisted_device_infos?{args}"
      ) {
      data @type(name: "BlacklistedDeviceInfo") {
        id
        device_info @type(name: "DeviceInfo") {
          id
          ip
          city
          country_code
        }
        deleted_at
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const BlacklistedDeviceInfo = gql`
  query BlacklistedDeviceInfo($id: String) {
    blacklistedDeviceInfo(id: $id)
    @rest(
      type: "ResponseBlacklistedDeviceInfo"
      path: "/core/client/blacklisted_device_infos/{args.id}"
    ) {
      data @type(name: "BlacklistedDeviceInfo") {
        id
        device_info @type(name: "DeviceInfo") {
          id
          ip
          city
          country_code
          customer_id
          device_info_doubles_count
          form_submission_id
          incognito
          form_submission_id
          created_at
          browser_info {
            device
            os
            osVersion
            userAgent
            browserName
            browserMajorVersion
            browserFullVersion
          }
        }
        deleted_at
      }
      success
    }
  }
`;