import { gql } from '@apollo/client';

export const UpdateProvider = gql`
  mutation UpdateProvider($id: String!, $body: BodyUpdateProvider!) {
    updateProvider(id: $id, body: $body)
      @rest(
        type: "UpdateProvider"
        path: "/tm/providers/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "TransactionProvider") {
        settings
        client_id
        created_at
        enabled
        external
        id
        kind
      }
      success
    }
  }
`;

export const SyncUnit21AlertsAndCases = gql`
  mutation SyncUnit21AlertsAndCases(
    $id: String!
    $body: BodySyncUnit21AlertsAndCases!
  ) {
    syncUnit21AlertsAndCases(id: $id, body: $body)
      @rest(
        type: "SyncUnit21AlertsAndCases"
        path: "/tm/providers/{args.id}/sync_unit21_alerts_and_cases"
        bodyKey: "body"
        method: "PATCH"
      ) {
      data @type(name: "TransactionProvider") {
        settings
        client_id
        created_at
        enabled
        external
        id
        kind
      }
      success
    }
  }
`;
