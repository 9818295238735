import { useProfileModel } from '../model';

export function useProfileController() {
  const {
    fullName,
    email,
    phone,
    isNewStatus,
    isCompletedStatus,
    redirectToFlow,
    isExist,
    statusColor,
  } = useProfileModel();

  return {
    fullName,
    email,
    phone,
    isExist,
    isNewStatus,
    isCompletedStatus,
    redirectToFlow,
    statusColor,
  };
}
