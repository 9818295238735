import { from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RestLink } from 'apollo-link-rest';
import { RestResponse } from './types';
import { getToken } from './todo';
import { getClient, getClientId, getMeAccountsClients } from './utils';
import NavigateLink from './NavigateLink';
import { UploadLink } from './UploadLink';
import { AttachLink } from './AttachLink';
import { ReportLink } from './ReportLink';
import { DownloadLink } from './DownloadLink';
import { ScoringLink } from './ScoringLink';
import { PoltergeistLink } from './Poltergeist';
import { TMLink } from './TMLink';
import HandlerLink from './HandlerLink';
import { ErrorLink } from './ErrorLink';
import BillingLink from './BillingLink';

const API_HOST = process.env.API_HOST || process.env.REACT_APP_API_HOST;
const AUTH_HOST = process.env.AUTH_HOST || process.env.REACT_APP_AUTH_HOST;
const IS_NEW_AUTH = process.env.REACT_APP_NEW_AUTH;

class AWSFBResponse extends Response {
  get ok() {
    return true;
  }
}

export const restLink = new RestLink({
  uri: `${API_HOST}`,
  endpoints: { auth: `${AUTH_HOST}` },
  credentials: 'include',
  bodySerializers: {
    empty: (_, headers) => ({
      body: null,
      headers,
    }),
  },
  customFetch: (uri, options) =>
    new Promise(async (resolve, reject) => {
      const parsedUrl = decodeURI(uri.toString()).replace(
        /\[.[0-9]*\]/gm,
        '[]'
      );
      const headers: HeadersInit = new Headers(options.headers);
      headers.set('x-aid-client', 'app');
      if (!IS_NEW_AUTH) {
        const res: Response = await fetch(parsedUrl, { ...options, headers });
        if (res.status === 200) {
          return resolve(res);
        }
        return reject(res);
      } else {
        try {
          const res: Response = await fetch(parsedUrl, { ...options, headers });
          if (
            res.redirected ||
            res.status === 302 ||
            res.status === 303 ||
            res.status === 401
          ) {
            window.location.href = `${API_HOST}/auth/login?redirect_uri=${window.location.href}`;
          } else {
            if (res.status === 200) {
              return resolve(res);
            } else if (res.status === 405) {
              return resolve(
                new AWSFBResponse('{}', {
                  headers: res.headers,
                  status: res.status,
                  statusText: res.statusText,
                })
              );
            }
            return reject(res);
          }
        } catch (e) {
          window.location.href = `${API_HOST}/auth/login?redirect_uri=${window.location.href}`;
        }
      }
    }),
  responseTransformer: async (response) =>
    response.json().then((data: RestResponse) => data),
});

const authLink = setContext((_, { headers, navigate }) => {
  return new Promise(async (res) => {
    let newHeaders = headers;
    if (!IS_NEW_AUTH) {
      const token = await getToken(navigate);
      newHeaders = {
        ...newHeaders,
        authorization: token,
      };
    }
    res({
      headers: {
        ...newHeaders,
      },
    });
  });
});

const clientLink = setContext((_, { cache, headers, navigate }) => {
  const clientId = getClientId();
  const accounts_clients = getMeAccountsClients(cache);
  if (accounts_clients) {
    const currentClient = getClient(clientId, accounts_clients);
    if (currentClient?.client) {
      if (currentClient.client.id !== clientId) {
        const href = `/${currentClient.client.id}/profile`;
        if (navigate) {
          navigate(href);
        } else {
          window.location.href = href;
        }
      }
      return {
        headers: {
          ...headers,
          'x-client-id': currentClient.client.id,
        },
      };
    }
  }

  return { headers };
});

export const link = from([
  ErrorLink,
  HandlerLink,
  NavigateLink,
  clientLink,
  authLink,
  new PoltergeistLink(),
  new ScoringLink(),
  new DownloadLink(),
  new ReportLink(),
  new UploadLink(),
  new AttachLink(),
  new TMLink(),
  BillingLink,
  restLink,
]);

export const onboardingLink = from([ErrorLink, restLink]);
