import {
  TableWithHeaderLoader,
  LoadableFunctionalComponent,
} from '@aid-module/ui';
import loadable from '@loadable/component';

export const ScreeningAlertsRegister = loadable(() => import('./component'), {
  resolveComponent: (components) => components.ScreeningAlertsRegisterComponent,
  fallback: <TableWithHeaderLoader />,
}) as LoadableFunctionalComponent<{}>;
