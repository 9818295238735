import gql from 'graphql-tag';
import { BLACKLIST_REASON_FRAGMENT } from '../../../fragment/admin/blacklistReasons';

export const BlacklistReasonUpdate = gql`
  ${BLACKLIST_REASON_FRAGMENT}
  mutation BlacklistReasonUpdate(
    $id: String!
    $body: BodyCreateOrUpdateBlacklistReason!
  ) {
    updateBlacklistReason(id: $id, body: $body)
      @rest(
        type: "BlacklistReasonUpdate"
        path: "/core/client/blacklist_reasons/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "BlacklistReason") {
        ...BlacklistReasonFragment
      }
      success
    }
  }
`;

export const BlacklistReasonCreate = gql`
  ${BLACKLIST_REASON_FRAGMENT}
  mutation BlacklistReasonCreate($body: BodyCreateOrUpdateBlacklistReason!) {
    createBlacklistReason(body: $body)
      @rest(
        type: "BlacklistReasonCreate"
        path: "/core/client/blacklist_reasons"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "BlacklistReason") {
        ...BlacklistReasonFragment
      }
      success
    }
  }
`;

export const BlacklistReasonDelete = gql`
  mutation BlacklistReasonDelete($id: String!) {
    deleteBlacklistReason(id: $id)
      @rest(
        type: "BlacklistReasonDelete"
        path: "/core/client/blacklist_reasons/{args.id}"
        method: "DELETE"
      ) {
      data {
        id
      }
      success
    }
  }
`;
