import { getColor, GridContainer } from '@aid-module/ui';
import styled from '@emotion/styled';

export const Container = styled(GridContainer)`
  width: 560px;
  border: 1px solid ${getColor('main-deep-2')};
  border-radius: 8px;
  min-height: 560px;
  background: ${getColor('white-primary')};
`;
