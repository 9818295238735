import { TmEntityStatusViewProps } from './types';
import { useTmEntityStatusController } from './controller';
import { Text } from '../Text';
import { Tag } from '../Count';
import {
  TRANSACTION_ENTITY_STATUSES,
  TRANSACTION_ENTITY_DISPLAY_STATUSES,
} from './constants';

function TmEntityStatus({ status }: TmEntityStatusViewProps) {
  const { background, text } = useTmEntityStatusController(status);

  if (text) {
    return <Tag count={text} padding="S" size={11} background={background} />;
  }

  return <Text text="-" size={12} color="main-deep-4" />;
}

export {
  TmEntityStatus,
  TRANSACTION_ENTITY_STATUSES,
  TRANSACTION_ENTITY_DISPLAY_STATUSES,
};
