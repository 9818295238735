import { useCallback } from 'react';
import { useBreadcrumbItemModel } from '../model';
import { BreadcrumbItemViewProps } from '../types';

export function useBreadcrumbItemController(
  path: BreadcrumbItemViewProps['path']
) {
  const { navigateToPath } = useBreadcrumbItemModel(path);

  const onClickBreadcrumb = useCallback(
    () => navigateToPath(),
    [navigateToPath]
  );

  return { onClickBreadcrumb };
}
