import gql from 'graphql-tag';

export const CdsCableIdvResults = gql`
  query CdsCableIdvResults(
    $page: Int
    $per: Int
    $user_id: String
    $company_id: String
  ) {
    cdsCableIdvResults(
      page: $page
      per: $per
      user_id: $user_id
      company_id: $company_id
    )
      @rest(
        type: "ResponseCdsCableIdvResults"
        path: "/cds/cable/idv_results?{args}"
      ) {
      data @type(name: "CdsCableIdvResult") {
        id
        client_id
        company_id
        created_at
        document_birth_date
        document_check_outcome
        document_expiry_date
        document_extracted_information @type(name: "IdenfyRawResultFull") {
          attempt_count
          client @type(name: "IdenfyRawResultClient") {
            country
            date_of_birth
            date_of_issue
            default_country
            document_number
            document_type
            expiry_date
            name
            nationality
            personal_id_number
            sex
            surname
            utility_address
            videocallsession
          }
          client_identity_document
            @type(name: "IdenfyRawResultClientIdentityDocument") {
            doc_address
            doc_country
            doc_date_of_birth
            doc_date_of_issue
            doc_document_number
            doc_document_type
            doc_expiry_date
            doc_license_categories
            doc_mothers_maiden_name
            doc_name
            doc_nationality
            doc_personal_code
            doc_sex
            doc_surname
            document_type
            manual_additional_step_status
            manual_address
            manual_country
            manual_date_of_birth
            manual_date_of_issue
            manual_document_number
            manual_document_type
            manual_expiry_date
            manual_mothers_maiden_name
            manual_mrz_string
            manual_name
            manual_nationality
            manual_personal_code
            manual_sex
            manual_surname
            manual_utility_address
            mrz_address
            mrz_country
            mrz_date_of_birth
            mrz_date_of_issue
            mrz_document_number
            mrz_document_subtype
            mrz_document_type
            mrz_expiry_date
            mrz_license_categories
            mrz_name
            mrz_nationality
            mrz_optional_data
            mrz_personal_code
            mrz_sex
            mrz_string
            mrz_surname
            org_address
            org_authority
            org_birth_place
            org_name
            org_nationality
            org_surname
            urjanet_utility_data
          }
          document_validity
          face_match_result
          finish_time
          manual_document_validity
          manual_face_match_result
          mismatch_tags
          review_time
          start_time
          suspection_reasons
        }
        document_issue_date
        document_number
        document_type
        outcome
        provider_name
        selfie_check_outcome
        updated_at
        user_id
        verification_type
      }
      meta {
        count
        page
        items
        pages
      }
      success
    }
  }
`;
