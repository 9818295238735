import { FlexContainer, getColor, getSpace } from '@aid-module/ui';
import styled from '@emotion/styled/macro';
import { NavLink } from 'react-router-dom';

export const Container = styled(FlexContainer)`
  padding: 0 ${getSpace('12')} ${getSpace('24')} ${getSpace('4')};
  overflow: auto;

  & > div {
    padding-bottom: ${getSpace('20')};
    width: 100%;
  }
`;

export const GroupContainer = styled.div``;

export let MenuGroupTitle = styled(NavLink)`
  text-transform: uppercase;
  margin-bottom: 8px;
  letter-spacing: 0.115em;
  text-decoration: none;
  border-radius: 4px;
  padding: ${getSpace('6')} ${getSpace('6')};

  &:hover {
    background: ${getColor('main-deep-1')};
  }
  &.active {
    background: ${getColor('main-deep-2')};
  }

  &.active {
    & > span {
      font-weight: bold;
    }
  }
`;
