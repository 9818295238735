import { gql } from '@apollo/client';

export const CreateExternalRef = gql`
  mutation CreateExternalRef($body: BodyCreateExternalRef!) {
    createExternalRef(body: $body)
      @rest(
        type: "CreateExternalRef"
        path: "/checkups/client/external_refs"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "ExternalRef") {
        id
        external_entity_id
        external_entity_type
        external_system
      }
      success
    }
  }
`;

export const DeleteExternalRef = gql`
  mutation DeleteExternalRef($id: String!) {
    deleteExternalRef(id: $id)
      @rest(
        type: "DeleteExternalRef"
        path: "/checkups/client/external_refs/{args.id}"
        method: "DELETE"
      ) {
      data @type(name: "ExternalRef") {
        id
        external_entity_id
        external_entity_type
        external_system
      }
      success
    }
  }
`;
