import { ContentLoader, GridContainer } from '@aid-module/ui';

export function Fallback() {
  return (
    <GridContainer gap="20">
      <ContentLoader width="100%" height="36.4">
        <rect rx="4" ry="4" width="100%" height="36.4" />
      </ContentLoader>
      <GridContainer gap="8">
        <ContentLoader width="100%" height="64.2">
          <rect rx="4" ry="4" width="100%" height="64.2" />
        </ContentLoader>
        <ContentLoader width="100%" height="64.2">
          <rect rx="4" ry="4" width="100%" height="64.2" />
        </ContentLoader>
        <ContentLoader width="100%" height="64.2">
          <rect rx="4" ry="4" width="100%" height="64.2" />
        </ContentLoader>
        <ContentLoader width="100%" height="64.2">
          <rect rx="4" ry="4" width="100%" height="64.2" />
        </ContentLoader>
        <ContentLoader width="100%" height="64.2">
          <rect rx="4" ry="4" width="100%" height="64.2" />
        </ContentLoader>
        <ContentLoader width="100%" height="64.2">
          <rect rx="4" ry="4" width="100%" height="64.2" />
        </ContentLoader>
        <ContentLoader width="100%" height="64.2">
          <rect rx="4" ry="4" width="100%" height="64.2" />
        </ContentLoader>
        <ContentLoader width="100%" height="64.2">
          <rect rx="4" ry="4" width="100%" height="64.2" />
        </ContentLoader>
        <ContentLoader width="100%" height="64.2">
          <rect rx="4" ry="4" width="100%" height="64.2" />
        </ContentLoader>
        <ContentLoader width="100%" height="64.2">
          <rect rx="4" ry="4" width="100%" height="64.2" />
        </ContentLoader>
      </GridContainer>
    </GridContainer>
  );
}
