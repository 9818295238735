import { GridContainer, getColor } from '@aid-module/ui';
import styled from '@emotion/styled';

export const Container = styled(GridContainer)`
`;

export const SvgPath = styled.path`
  fill: none;
  stroke: ${getColor('main-deep-4')};
  stroke-width: 7.5;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-dashoffset: 175;
`;
