import { useRef } from 'react';
import { Controller } from 'react-hook-form';
import { FuzzinessInput } from '@aid-module/ui';
import { FormRenderProps } from '../../../../types';
import { FuzzinessViewProps } from './types';

export function Fuzziness({ field }: FuzzinessViewProps) {
  const renderFuzziness = useRef(
    ({
      field: { value, onChange },
      fieldState: { error },
    }: FormRenderProps<string>) => {
      return (
        <FuzzinessInput
          label={`${field.title} ${field.required ? ' *' : ''}`}
          placeholder={`Enter ${field.title}...`}
          error={error}
          value={value}
          onChange={onChange}
          menuPlacement="bottom"
        />
      );
    }
  );
  return <Controller name={field.code} render={renderFuzziness.current} />;
}
