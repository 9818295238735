import gql from 'graphql-tag';

export const CreateCdsFieldMapping = gql`
  mutation CreateCdsFieldMapping($body: BodyCreateCdsFieldMapping!) {
    createCdsFieldMapping(body: $body)
      @rest(
        type: "CreateCdsFieldMapping"
        path: "/cds/field_mappings"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "CdsFieldMapping") {
        created_at
        entity_template_id
        form_field_id
        form_field_reference_key
        form_mapping_id
        id
        map_to_field_name
        map_to_table_name
        mapper_klass
        updated_at
      }
      success
    }
  }
`;

export const UpdateCdsFieldMapping = gql`
  mutation UpdateCdsFieldMapping(
    $id: String!
    $body: BodyUpdateCdsFieldMapping!
  ) {
    updateCdsFieldMapping(id: $id, body: $body)
      @rest(
        type: "UpdateCdsFieldMapping"
        path: "/cds/field_mappings/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "CdsFieldMapping") {
        created_at
        entity_template_id
        form_field_id
        form_field_reference_key
        form_mapping_id
        id
        map_to_field_name
        map_to_table_name
        mapper_klass
        updated_at
      }
      success
    }
  }
`;

export const DeleteCdsFieldMapping = gql`
  mutation DeleteCdsFieldMapping($id: String!) {
    deleteCdsFieldMapping(id: $id)
      @rest(
        type: "DeleteCdsFieldMapping"
        path: "/cds/field_mappings/{args.id}"
        method: "DELETE"
      ) {
      success
    }
  }
`;
