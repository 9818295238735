import {
  BooleanInputLoader,
  DefaultButtonLoader,
  GridContainer,
} from '@aid-module/ui';
import { useCallback } from 'react';
import { Container } from '../../component/components/Roles/components/AsyncRoles/components/Container';
import { FallbackRolesViewProps } from './types';

export function FallbackRoles({ count, infinityRef }: FallbackRolesViewProps) {
  const renderItem = useCallback(
    (_: unknown, index: number) => (
      <Container key={index}>
        <BooleanInputLoader height={43.19} />
        <DefaultButtonLoader width={24} height={24} />
      </Container>
    ),
    []
  );

  return (
    <GridContainer
      ref={infinityRef}
      gridTemplateColumns="repeat(4, 1fr)"
      gap="20"
      gridTemplateRows="1fr"
    >
      {count ? Array.from({ length: count }, renderItem) : null}
    </GridContainer>
  );
}
