import {
  TableWithHeaderLoader,
  LoadableFunctionalComponent,
} from '@aid-module/ui';
import loadable from '@loadable/component';

export const Register = loadable(() => import('./component'), {
  resolveComponent: (components) => components.RegisterComponent,
  fallback: <TableWithHeaderLoader />,
}) as LoadableFunctionalComponent<{}>;
