import { MouseEvent, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigationContext } from '../../../../../../../../../context';
import { useActionsModel } from '../model';

export function useActionsController() {
  const { set, clear } = useActionsModel();
  const { toggleShowFilters } = useNavigationContext();
  const { handleSubmit } = useFormContext();

  const onClickClear = useCallback(() => {
    clear();
    toggleShowFilters();
  }, [toggleShowFilters, clear]);

  const onSubmit = useCallback(
    (variables: any) => {
      set(variables);
      toggleShowFilters();
    },
    [set, toggleShowFilters]
  );

  const onClickSubmit = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      handleSubmit(onSubmit)(e);
    },
    [handleSubmit, onSubmit]
  );

  return {
    onClickClear,
    onClickSubmit,
  };
}
