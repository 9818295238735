import { ContentLoader, GridContainer } from '@aid-module/ui';
import { Fallback as FormFallback } from '../../../context/components';

export function Fallback() {
  return (
    <GridContainer gap="12">
      <ContentLoader width="100%" height="26px">
        <rect rx="4px" ry="4px" width="100%" height="26px" />
      </ContentLoader>
      <FormFallback />
    </GridContainer>
  );
}
