import { GridContainer, getSpace } from '@aid-module/ui';
import styled from '@emotion/styled';

export const Container = styled(GridContainer)`
  overflow: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 14px;
  padding: ${getSpace('14')} ${getSpace('20')} 0px;
`;

export const PaddingContainer = styled(GridContainer)`
  position: relative;
  padding: ${getSpace('14')} ${getSpace('20')};
`;
