import { gql } from '@apollo/client';
import { SERVICE_CHECKUP_FRAGMENT } from '../../../fragment/checkup';

export const UpdateProviderStatus = gql`
  ${SERVICE_CHECKUP_FRAGMENT}
  mutation UpdateProviderStatus(
    $checkup_id: String!
    $body: BodyUpdateProviderStatus!
  ) {
    updateProviderStatus(checkup_id: $checkup_id, body: $body)
      @rest(
        type: "UpdateProviderStatus"
        path: "/checkups/client/checkups/{args.checkup_id}/change_provider_status"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "ServiceCheckup") {
        ...ServiceCheckupFragment
      }
      success
    }
  }
`;

export const UpdateAuditStatus = gql`
  ${SERVICE_CHECKUP_FRAGMENT}
  mutation UpdateAuditStatus(
    $checkup_id: String!
    $body: BodyUpdateProviderStatus!
  ) {
    updateAuditStatus(checkup_id: $checkup_id, body: $body)
      @rest(
        type: "UpdateAuditStatus"
        path: "/checkups/client/checkups/{args.checkup_id}/change_audit_status"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "ServiceCheckup") {
        ...ServiceCheckupFragment
      }
      success
    }
  }
`;

export const UpdateResolution = gql`
  ${SERVICE_CHECKUP_FRAGMENT}
  mutation UpdateResolution(
    $checkup_id: String!
    $body: BodyUpdateResolution!
  ) {
    updateResolution(checkup_id: $checkup_id, body: $body)
      @rest(
        type: "UpdateResolution"
        path: "/checkups/client/checkups/{args.checkup_id}/change_resolution"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "ServiceCheckup") {
        ...ServiceCheckupFragment
      }
      success
    }
  }
`;

export const UpdateCheckupProfiles = gql`
  mutation UpdateCheckupProfiles($body: BodyUpdateCheckupProfiles!) {
    updateCheckupProfiles(body: $body)
      @rest(
        type: "UpdateCheckupProfiles"
        path: "/checkups/client/checkup_profiles"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "ResponseUpdateCheckupProfile") {
        id
        checkup_profiles @type(name: "CheckupProfileItem") {
          profile_id
          checkup_id
          profile_display_name
        }
      }
      success
    }
  }
`;

export const DeleteLinkedProfile = gql`
  mutation DeleteLinkedProfile($id: String!) {
    deleteLinkedProfile(id: $id)
      @rest(
        type: "DeleteLinkedProfile"
        path: "/checkups/client/checkup_profiles/{args.id}"
        method: "DELETE"
      ) {
      data @type(name: "ResponseUpdateCheckupProfile") {
        checkup_id
        id
        profile_display_name
        profile_id
      }
      success
    }
  }
`;

export const AssignCheckup = gql`
  mutation AssignCheckup($id: String!, $body: BodyAssignCheckup!) {
    assignCheckup(id: $id, body: $body)
      @rest(
        type: "AssignCheckup"
        path: "/checkups/client/checkups/{args.id}/assign"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "Checkup") {
        id
      }
      success
    }
  }
`;
