import {
  DefaultButtonLoader,
  InputLoader,
} from '../../../../../../../../../../shared/Loaders';
import { GridContainer } from '../../../../../../../../../../containers';
import { ActionContainer, ContentContainer } from '../../component/styles';

export function Fallback() {
  return (
    <GridContainer gridTemplateRows="minmax(0, 1fr)">
      <ContentContainer>
        <GridContainer gap="20">
          <InputLoader />
          <InputLoader />
        </GridContainer>
      </ContentContainer>
      <ActionContainer>
        <GridContainer gap="20" gridTemplateColumns="repeat(2, 1fr)">
          <DefaultButtonLoader />
          <DefaultButtonLoader />
        </GridContainer>
      </ActionContainer>
    </GridContainer>
  );
}
