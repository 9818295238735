import { createContext, useContext } from 'react';
import { AssignToContextParams } from './types';

export const AssignToContext = createContext<AssignToContextParams>({
  id: '',
});

export function useAssignToContext() {
  const context = useContext(AssignToContext);

  return context;
}
