import { PropsWithChildren } from 'react';
import { useMultiplePathsLinkContainerController } from './controller';
import { Container } from './styles';
import { MultiplePathsLinkContainerViewProps } from './types';

export function MultiplePathsLinkContainer({
  children,
  paths,
}: PropsWithChildren<MultiplePathsLinkContainerViewProps>) {
  const { isActive } = useMultiplePathsLinkContainerController(paths);

  return <Container isActive={isActive}>{children}</Container>;
}
