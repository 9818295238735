import styled from '@emotion/styled/macro';
import { GridContainer } from '../../../../../../../../containers';
import { Text } from '../../../Text/styles';

export const CopyIconContainer = styled.div`
  display: none;
`;

export const Container = styled(GridContainer)`
  cursor: pointer;
  grid-template-columns: max-content 12px;
  width: max-content;

  & ${Text} {
    max-width: 330px;
  }

  &:hover {
    & > ${CopyIconContainer} {
      display: flex;
    }
  }
`;
