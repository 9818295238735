import gql from 'graphql-tag';
import { CLIENT_CHECKUP_KIND_FRAGMENT } from '../../../fragment/admin/clientCheckupKinds';

export const ToggleClientCheckupKind = gql`
  ${CLIENT_CHECKUP_KIND_FRAGMENT}
  mutation ToggleClientCheckupKind($id: String!, $body: BodyToggleClientCheckupKind) {
    toggleClientCheckupKind(id: $id, body: $body)
      @rest(
        type: "ToggleClientCheckupKind"
        path: "/checkups/admin/checkup_kinds_clients/{args.id}/toggle"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "ClientCheckupKind") {
        ...ClientCheckupKindFragment
      }
      success
    }
  }
`;
