import {
  ContentLoader,
  GridContainer,
  ProfileCardLoader,
  TableLoader,
} from '@aid-module/ui';

export function Fallback() {
  return (
    <GridContainer gap="32" gridTemplateColumns="1fr" gridTemplateRows="1fr">
      <ProfileCardLoader />
      <GridContainer gap="20">
        <ContentLoader width="100%" height="36.2px">
          <rect rx="8" ry="8" width="100%" height="36.2px" />
        </ContentLoader>
        <TableLoader />
      </GridContainer>
    </GridContainer>
  );
}
