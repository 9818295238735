import gql from 'graphql-tag';

export const CreateDataRetentionConfig = gql`
  mutation CreateDataRetentionConfig(
    $body: BodyUpdateDataRetention!
  ) {
    createDataRetentionConfig(body: $body)
      @rest(
        type: "CreateDataRetentionConfig"
        path: "/core/client/data_retention_configs"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "DataRetentionConfig") {
        client_id
        created_at
        created_by @type(name: "Account") {
          avatar_id
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        data_retention_approve
        data_storage_period_days
        id
        updated_at
      }
      success
    }
  }
`;

export const UpdateDataRetentionConfig = gql`
  mutation UpdateDataRetentionConfig(
    $id: String!
    $body: BodyUpdateDataRetention!
  ) {
    updateDataRetentionConfig(id: $id, body: $body)
      @rest(
        type: "UpdateDataRetentionConfig"
        path: "/core/client/data_retention_configs/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "DataRetentionConfig") {
        client_id
        created_at
        created_by @type(name: "Account") {
          avatar_id
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        data_retention_approve
        data_storage_period_days
        id
        updated_at
      }
      success
    }
  }
`;

export const UpdateDataRetentionPlan = gql`
  mutation UpdateDataRetentionPlan(
    $id: String!
    $body: BodyUpdateDataRetentionPlan!
  ) {
    updateDataRetentionPlan(id: $id, body: $body)
      @rest(
        type: "UpdateDataRetentionPlan"
        path: "/core/client/data_retention_plans/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "DataRetentionPlan") {
        id
        created_at
        data_retention_config @type(name: "DataRetentionConfig") {
          client_id
          created_at
          created_by @type(name: "Account") {
            avatar_id
            email
            file_name
            file_size
            full_name
            id
            phone
          }
          data_retention_approve
          data_storage_period_days
          id
          updated_at
        }
        data_retention_config_id
        updated_at
        soft_delete_date
        state
        object_counts {
          Case
          Profile
          Customer
          CustomerVerification
          FormSubmission
          CustomerDocument
          Checkup
          Document
        }
      }
      success
    }
  }
`;

export const CreateDataRetentionPlan = gql`
  mutation CreateDataRetentionPlan(
    $body: BodyCreateDataRetentionPlan!
  ) {
    createDataRetentionPlan(body: $body)
    @rest(
      type: "CreateDataRetentionPlan"
      path: "/core/client/data_retention_plans"
      bodyKey: "body"
      method: "POST"
    ) {
      data @type(name: "DataRetentionPlan") {
        id
        created_at
        data_retention_config @type(name: "DataRetentionConfig") {
          client_id
          created_at
          created_by @type(name: "Account") {
            avatar_id
            email
            file_name
            file_size
            full_name
            id
            phone
          }
          data_retention_approve
          data_storage_period_days
          id
          updated_at
        }
        data_retention_config_id
        updated_at
        soft_delete_date
        state
        object_counts {
          Case
          Profile
          Customer
          CustomerVerification
          FormSubmission
          CustomerDocument
          Checkup
          Document
        }
      }
      success
    }
  }
`;

export const DeleteDataRetentionPlan = gql`
  mutation DeleteDataRetentionPlan($id: String!) {
    deleteDataRetentionPlan(id: $id)
      @rest(
        type: "DeleteDataRetentionPlan"
        path: "/core/client/data_retention_plans/{args.id}"
        method: "DELETE"
      ) {
      data @type(name: "DataRetentionPlan") {
        id
        created_at
        data_retention_config @type(name: "DataRetentionConfig") {
          client_id
          created_at
          created_by @type(name: "Account") {
            avatar_id
            email
            file_name
            file_size
            full_name
            id
            phone
          }
          data_retention_approve
          data_storage_period_days
          id
          updated_at
        }
        data_retention_config_id
        updated_at
        soft_delete_date
        state
        object_counts {
          Case
          Profile
          Customer
          CustomerVerification
          FormSubmission
          CustomerDocument
          Checkup
          Document
        }
      }
      success
    }
  }
`;
