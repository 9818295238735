import { ContentLoader, GridContainer, TableLoader } from '@aid-module/ui';

export function Fallback() {
  return (
    <GridContainer gap="20">
      <GridContainer gap="18">
        <ContentLoader width="100%" height="206.2px">
          <rect width="100%" height="206.2px" rx="3" ry="3" />
        </ContentLoader>
        <ContentLoader width="100%" height="32.2px">
          <rect width="100%" height="32.2px" rx="3" ry="3" />
        </ContentLoader>
        <ContentLoader width="100%" height="32.2px">
          <rect width="100%" height="32.2px" rx="3" ry="3" />
        </ContentLoader>
      </GridContainer>
      <TableLoader />
    </GridContainer>
  );
}
