import { ModalButtonViewProps } from '@aid-module/ui';
import { MouseEvent, useCallback, useMemo } from 'react';
import { useOnBoardingContext } from '../../../../../../../context';

export function useButtonController(onOpen: ModalButtonViewProps['onOpen']) {
  const { stepStatus } = useOnBoardingContext();

  const onClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      onOpen?.();
    },
    [onOpen]
  );

  const isDisabled = useMemo(() => stepStatus !== 'completed', [stepStatus]);

  return { isDisabled, onClick };
}
