import gql from 'graphql-tag';
import { DEFAULT_CLIENT_FRAGMENT } from '../DefaultClient';
import { IDENFY_RESULT_FRAGMENT } from '../pdf/case/identityVerifications/IdenfyResult';
import { VERIFF_RESULT_FRAGMENT } from '../pdf/case/identityVerifications/VeriffResult';
import { CASE_FRAGMENT } from '../cm';

export const IDENTITY_VERIFICATION_FRAGMENT = gql`
  ${DEFAULT_CLIENT_FRAGMENT}
  ${IDENFY_RESULT_FRAGMENT}
  ${VERIFF_RESULT_FRAGMENT}
  ${CASE_FRAGMENT}
  fragment IdentityVerificationFragment on IdentityVerificationItem {
    id
    name
    created_at
    updated_at
    country
    idenfy_result_id
    status
    surname
    veriff_result_id
    date_of_birth_at
    linked_to_cases @type(name: "Case") {
      id
      profile_display_name
    }
    client @type(name: "Client") {
      ...DefaultClientFragment
    }
    idenfy_result @type(name: "IdenfyResult") {
      ...IdenfyResultFragment
    }
    veriff_result @type(name: "VeriffResult") {
      ...VeriffResultFragment
    }
  }
`;
