import { LoadableFunctionalComponent } from '@aid-module/ui';
import loadable from '@loadable/component';
import { FormContainer } from '../../../components';
import { Fallback } from './components';

export const ProviderUpdate = loadable(() => import('./component'), {
  resolveComponent: (components) => components.ProviderUpdateComponent,
  fallback: (
    <FormContainer label="Provider" icon="kycDocVerification" isLoading>
      <Fallback />
    </FormContainer>
  ),
}) as LoadableFunctionalComponent<{}>;
