import gql from 'graphql-tag';
import { PROFILE_FRAGMENT } from '../../fragment/profiles/profiles';
import { CUSTOMER_FRAGMENT } from '../../fragment/cm';
import { ATTACHMENT_FRAGMENT } from '../../fragment/attachment';

export const CustomerDocumentChangeBlacklisted = gql`
  ${PROFILE_FRAGMENT}
  ${CUSTOMER_FRAGMENT}
  ${ATTACHMENT_FRAGMENT}
  mutation CustomerDocumentChangeBlacklisted($id: String!, $body: BodyCustomerDocumentChangeBlacklisted!) {
    customerDocumentChangeBlacklisted(id: $id, body: $body)
    @rest(
      type: "CustomerDocumentChangeBlacklisted"
      path: "/core/client/customer_documents/{args.id}/change_blacklisted"
      bodyKey: "body"
      method: "PUT"
    ) {
      data @type(name: "CustomerDocument") {
        id
        client_id
        veriff_result_id
        idenfy_result_id
        created_at
        updated_at
        date_of_birth
        date_of_issue
        expiry_date
        first_name
        last_name
        gender
        document_number
        document_type
        blacklisted
        country
        nationality
        attachments @type(name: "Attachment") {
          ...AttachmentFragment
        }
        profiles @type(name: "Profile") {
          ...ProfileFragment
        }
        customers @type(name: "Customer") {
          ...CustomerFragment
        }
      }
      success
    }
  }
`;
