import { ContentLoader } from '../ContentLoader';
import { ActionButtonLoaderViewProps } from './types';

export function ActionButtonLoader({
  width,
  height,
}: ActionButtonLoaderViewProps) {
  return (
    <ContentLoader width={`${width || 110}px`} height={`${height || 32}px`}>
      <rect width={`${width || 110}px`} height={`${height || 32}px`} rx="4"  />
    </ContentLoader>
  );
}
