import { getSpace, GridContainer, Text } from '@aid-module/ui';
import styled from '@emotion/styled';

export const Container = styled.div`
  width: 195px;
  padding: 0px ${getSpace('20')};
`;

export const ContentContainer = styled(GridContainer)`
  padding: ${getSpace('12')} 0px;
`;

export const Label = styled(Text)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
