import gql from 'graphql-tag';
import { VERIFF_RESULT_FULL_FRAGMENT } from '../VeriffRawResultFull';

export const VERIFF_RESULT_FRAGMENT = gql`
  ${VERIFF_RESULT_FULL_FRAGMENT}
  fragment VeriffResultFragment on VeriffResult {
    attachments @type(name: "VeriffAttachment") {
      account_id
      additional_data {
        veriff_kind
      }
      client_id
      created_at
      file_name
      file_size
      id
      kind
      subject_id
      subject_type
      updated_at
      uploaded_document_id
    }
    created_at
    id
    decision @type(name: "VeriffResultFull") {
      ...VeriffResultFullFragment
    }
    status
    updated_at
    verification_id
    verification_step_passing_id
  }
`;
