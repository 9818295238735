import gql from 'graphql-tag';
import { FORM_TEMPLATE_FRAGMENT } from '../../../fragment/admin/formTemplates';

export const FormTemplates = gql`
  ${FORM_TEMPLATE_FRAGMENT}
  query FormTemplates($enabled: Boolean, $client_id: String, $page: Int, $q: String) {
    formTemplates(enabled: $enabled, client_id: $client_id, page: $page, per: 20, q: $q)
      @rest(type: "ResponseFormTemplates", path: "/core/admin/whitelabel_templates?{args}") {
      data @type(name: "FormTemplate") {
        ...FormTemplateFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const ClientFormTemplates = gql`
  ${FORM_TEMPLATE_FRAGMENT}
  query ClientFormTemplates($enabled: Boolean, $client_id: String, $page: Int, $q: String) {
    formTemplates(enabled: $enabled, client_id: $client_id, page: $page, per: 20, q: $q)
      @rest(type: "ResponseFormTemplates", path: "/core/client/whitelabel_templates?{args}") {
      data @type(name: "FormTemplate") {
        ...FormTemplateFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;
