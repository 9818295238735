import gql from 'graphql-tag';
import { CASE_CONFIG_FRAGMENT } from '../../fragment/caseConfig/CaseConfig';

export const CreateCaseConfig = gql`
  ${CASE_CONFIG_FRAGMENT}
  mutation CreateCaseConfig($body: BodyCreateOrUpdateCaseConfig!) {
    createCaseConfig(body: $body)
      @rest(
        type: "CaseConfigCreate"
        path: "/core/client/case_configs"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "CaseConfig") {
        ...CaseConfigFragment
      }
      success
    }
  }
`;

export const UpdateCaseConfig = gql`
  ${CASE_CONFIG_FRAGMENT}
  mutation UpdateCaseConfig(
    $id: String!
    $body: BodyCreateOrUpdateCaseConfig!
  ) {
    updateCaseConfig(id: $id, body: $body)
      @rest(
        type: "CaseConfigUpdate"
        path: "/core/client/case_configs/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "CaseConfig") {
        ...CaseConfigFragment
      }
      success
    }
  }
`;
