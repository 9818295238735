import { MouseEvent, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { ModalBodyViewProps } from '../../../../../../../../../../../../../../../../../../../../../../../../../Modal/Modal/types';
import { useFilterTemplatesContext } from '../../../../../../../../../../../../../../../../../../../../../../../../../Table/context';
import { useFiltersValuesContext } from '../../../../../../../context';
import { FormProps } from '../../../types';
import { useActionsModel } from '../model';

export function useActionsController(onClose: ModalBodyViewProps['onClose']) {
  const { handleSubmit } = useFormContext<FormProps>();
  const { filters, rawFilterValues } = useFiltersValuesContext();
  const { createTabRegistry, loading } = useActionsModel();
  const { code } = useFilterTemplatesContext();


  const onSubmit = useCallback(
    async (values: FormProps) => {
      const params = {
        ...values,
        name: values.name?.trim(),
        filters,
        meta: rawFilterValues,
        registry_type: code || '',
      };
      const res = await createTabRegistry(params);
      if (res) {
        onClose?.();
      }
    },
    [createTabRegistry, onClose, filters, code]
  );

  const onClickSubmit = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      handleSubmit(onSubmit)(e);
    },
    [handleSubmit, onSubmit]
  );

  return {
    onClickSubmit,
    loading,
  };
}
