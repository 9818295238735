import { createContext, useContext } from 'react';
import { FiltersConfig } from './types';

export const ConfigContext = createContext<FiltersConfig | undefined>(
  undefined
);

export function useConfigContext() {
  const context = useContext(ConfigContext);

  return context;
}
