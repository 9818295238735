import gql from 'graphql-tag';
import {
  ACCOUNT_CLIENT_FRAGMENT,
  ACCOUNT_FRAGMENT,
} from '../../../fragment/admin/accounts';
import { META_FRAGMENT } from '../../../fragment/Meta';

export const Accounts = gql`
  ${ACCOUNT_FRAGMENT}
  ${META_FRAGMENT}
  query Accounts($page: Int, $status: UserStatus, $q: String) {
    accounts(page: $page, per: 20, status: $status, q: $q)
      @rest(type: "ResponseAccounts", path: "/core/admin/accounts?{args}") {
      data @type(name: "Account") {
        ...AccountFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const Account = gql`
  ${ACCOUNT_FRAGMENT}
  ${ACCOUNT_CLIENT_FRAGMENT}
  query Account($id: String!) {
    account(id: $id)
      @rest(type: "ResponseAccount", path: "/core/admin/accounts/{args.id}") {
      data @type(name: "Account") {
        accounts_clients @type(name: "AccountClient") {
          ...AccountClientFragment
        }
        ...AccountFragment
      }
      success
    }
  }
`;
