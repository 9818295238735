import { PropsWithChildren } from 'react';
import { ExternalStyledLink, LinkText } from '../shared.styles';
import { ExternalLinkViewProps } from '../types';
import { stopPropagation } from '../utils';

export function DefaultExternalLink({
  to,
  target,
  onClick,
  size = 14,
  color = 'main-deep-4',
  children,
  ...props
}: PropsWithChildren<ExternalLinkViewProps>) {
  return (
    <ExternalStyledLink
      onClick={onClick || stopPropagation}
      target={target}
      href={to}
      rel="noopener noreferrer"
    >
      <LinkText {...props} size={size} color={color} />
      {children}
    </ExternalStyledLink>
  );
}
