import gql from 'graphql-tag';
import { CASE_SECTION_SUBJECT_EVALUATION_SCORING_FRAGMENT } from './CaseSectionSubjectEvaluationScoring';
import { SCORING_AUDIT_LOG_FRAGMENT } from './ScoringAuditLog';

export const CASE_SECTION_EVALUATION_FRAGMENT = gql`
  ${SCORING_AUDIT_LOG_FRAGMENT}
  ${CASE_SECTION_SUBJECT_EVALUATION_SCORING_FRAGMENT}
  fragment CaseSectionEvaluationScoringFragment on CaseSectionEvaluationScoring {
    audit_logs @type(name: "ScoringAuditLog") {
      ...ScoringAuditLogFragment
    }
    case_evaluation_id
    case_id
    case_section_id
    case_section_subject_evaluations
      @type(name: "CaseSectionSubjectEvaluationScoring") {
      ...CaseSectionSubjectEvaluationScoringFragment
    }
    coefficient
    created_at
    has_conflict
    id
    is_changed_manually
    kind
    updated_at
    value
    value_enabled
  }
`;
