import gql from 'graphql-tag';
import { CASE_FRAGMENT } from '../../../cm';
import { FORM_SCREEN_FRAGMENT } from '../../../form';
import { PROFILE_FRAGMENT } from '../../../profiles/profiles';

export const CASE_INFO_FRAGMENT = gql`
  ${CASE_FRAGMENT}
  ${PROFILE_FRAGMENT}
  ${FORM_SCREEN_FRAGMENT}
  fragment CaseInfoFragment on CaseInfo {
    case @type(name: "PdfCase") {
      account @type(name: "PdfAccount") {
        first_name
        last_name
        full_name
        avatar_id
        id
        phone
        email
      }
      assigned_to @type(name: "PdfAccount") {
        email
        file_name
        file_size
        full_name
        id
        phone
      }
      audit_repeat_interval
      auto_audit_state
      case_sections_count
      created_at
      customer @type(name: "PdfCustomer") {
        first_name
        last_name
        full_name
        avatar_id
        id
        phone
        email
        profile @type(name: "PdfCustomerProfile") {
          email
          phone
        }
      }
      id
      is_ongoing
    }
    form_configurations @type(name: "PdfForm") {
      aid_scoring_enabled
      abandoned_in
      audit_repeat_interval
      auto_create_case
      auto_start_audit
      callback_uri
      client_id
      created_at
      description
      enabled
      form_screens_count
      form_submissions_count
      global
      id
      is_actual_revision
      is_additional
      is_favorite
      is_ongoing
      linked_form_configuration_id
      linked_form_submission_id
      name
      notify_complete_to_emails
      only_authorized
      options
      profile_kind
      redirect_uri
      reference_key
      revision_number
      tags
      technical_name
      ubt_enabled
      updated_at
      form_screens @type(name: "FormScreen") {
        ...FormScreenFragment
      }
    }
    form_submissions @type(name: "PdfSubmission") {
      id
      updated_at
      form_configuration @type(name: "PdfFormConfiguration") {
        id
      }
      field_submissions @type(name: "FieldSubmission") {
        business_passport_id
        created_at
        file_name
        file_size
        form_field @type(name: "FormField") {
          created_at
          description
          field_select_values
          field_template @type(name: "FormFieldTemplate") {
            code
            common
            created_at
            defaults
            field_select_values
            id
            kind
            name
            options
            updated_at
          }
          form_group @type(name: "FormGroup") {
            created_at
            form_screen_id
            id
            name
            options
            position
            repeatable
            tags
            updated_at
          }
          field_template_id
          id
          name
          options
          position
          protected_tags
          reference_key
          tags
          updated_at
        }
        form_group_index
        form_group_subindex
        form_invitation_id
        id
        personal_passport_id
        updated_at
        uploaded_document_id
        value
      }
    }
    profiles @type(name: "PdfProfile") {
      ...ProfileFragment
    }
  }
`;
