import { FEATURES, PERMISSIONS } from '@aid-package/context';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  CaseCreate,
  CasesRegister,
  Entity,
  Instrument,
  MonitoringDescription,
  MonitoringRegister,
  Providers,
  RuleSets,
  RuleCreate,
  RulesRegister,
  RuleUpdate,
  RuleDescription,
  MyAlertsRegister,
  AlertsQueuesRegister,
  MyCasesRegister,
  CasesQueuesRegister,
  TransactionQueuesRegister,
  TemplateCreate,
  TemplatesRegister,
  AlertDescription,
  CaseDescription,
  TagsRegister,
  TagCreate,
  TagUpdate,
  TemplateUpdate,
  DispositionsRegister,
  MonitoringAlertsRegister,
  ScreeningAlertsRegister,
  MatricesRegister,
  MatrixCreate,
  MatrixDescription,
  BlacklistGroupRegister,
  BlacklistGroupUpdate,
  CreateSarReport,
  SarRegister,
  EditSarReport,
} from './components';
import {
  ProtectedRoute,
  ErrorPages,
  ProtectedFeaturesRoute,
} from '@aid-module/ui';

export { Client, Account } from './components';

export function TransactionRouter() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute
            check={[
              PERMISSIONS.SEE_TM,
              PERMISSIONS.MANAGE_TM,
              PERMISSIONS.CLIENT_MANAGE,
              PERMISSIONS.SUBMISSIONS_SEE_OTHERS,
              PERMISSIONS.MANAGE_TM,
              PERMISSIONS.SEE_TM,
              PERMISSIONS.SEE_TM_CASES,
              PERMISSIONS.MANAGE_TM_CASES,
              PERMISSIONS.MANAGE_PROFILE,
              PERMISSIONS.SEE_MONITORING_ALERTS,
              PERMISSIONS.SEE_SCREENING_ALERTS,
              PERMISSIONS.MANAGE_TM_SCORING_RULES,
              PERMISSIONS.SEE_CASE_RELATED_TRANSACTIONS,
              PERMISSIONS.FILL_SAR_REPORT,
              PERMISSIONS.MANAGE_SAR_REPORT,
            ]}
          />
        }
      >
        <Route index element={<Navigate to="monitoring" replace />} />
        <Route
          path="/monitoring"
          element={
            <ProtectedRoute
              check={[
                PERMISSIONS.SEE_TM,
                PERMISSIONS.SEE_CASE_RELATED_TRANSACTIONS,
              ]}
            />
          }
        >
          <Route index element={<MonitoringRegister />} />
        </Route>
        <Route
          path="/monitoring/:id"
          element={
            <ProtectedRoute
              check={[
                PERMISSIONS.SEE_TM,
                PERMISSIONS.SEE_CASE_RELATED_TRANSACTIONS,
              ]}
            />
          }
        >
          <Route index element={<MonitoringDescription />} />
        </Route>
        <Route
          path="/screening_alerts"
          element={
            <ProtectedRoute
              check={[
                PERMISSIONS.SEE_SCREENING_ALERTS,
                PERMISSIONS.SEE_CASE_RELATED_TRANSACTIONS,
              ]}
            />
          }
        >
          <Route index element={<ScreeningAlertsRegister />} />
          <Route path=":id" element={<AlertDescription />} />
        </Route>
        <Route
          path="/monitoring_alerts"
          element={
            <ProtectedRoute
              check={[
                PERMISSIONS.SEE_MONITORING_ALERTS,
                PERMISSIONS.SEE_CASE_RELATED_TRANSACTIONS,
              ]}
            />
          }
        >
          <Route index element={<MonitoringAlertsRegister />} />
          <Route path=":id" element={<AlertDescription />} />
        </Route>
        <Route
          path="/my_alerts"
          element={
            <ProtectedRoute
              check={[
                PERMISSIONS.SEE_MONITORING_ALERTS,
                PERMISSIONS.SEE_SCREENING_ALERTS,
              ]}
            />
          }
        >
          <Route index element={<MyAlertsRegister />} />
          <Route path=":id" element={<AlertDescription />} />
        </Route>
        <Route
          path="/alerts_queues"
          element={
            <ProtectedRoute
              check={[
                PERMISSIONS.SEE_MONITORING_ALERTS,
                PERMISSIONS.SEE_SCREENING_ALERTS,
              ]}
            />
          }
        >
          <Route index element={<AlertsQueuesRegister />} />
          <Route path=":id" element={<AlertDescription />} />
        </Route>
        <Route
          path="/investigations"
          element={
            <ProtectedRoute
              check={[
                PERMISSIONS.SEE_CASE_RELATED_TRANSACTIONS,
                PERMISSIONS.SEE_TM,
              ]}
            />
          }
        >
          <Route index element={<CasesRegister />} />
          <Route
            element={
              <ProtectedFeaturesRoute check={FEATURES.TRANSACTION_MONITORING} />
            }
          >
            <Route path="form" element={<CaseCreate />} />
          </Route>
          <Route path="form/:id" element={<CaseDescription />} />
        </Route>
        <Route
          path="/my_cases"
          element={<ProtectedRoute check={PERMISSIONS.SEE_TM} />}
        >
          <Route index element={<MyCasesRegister />} />
          <Route path="form" element={<CaseCreate />} />
          <Route path="form/:id" element={<CaseDescription />} />
        </Route>
        <Route
          path="/cases_queues"
          element={<ProtectedRoute check={PERMISSIONS.SEE_TM} />}
        >
          <Route index element={<CasesQueuesRegister />} />
          <Route path="form" element={<CaseCreate />} />
          <Route path="form/:id" element={<CaseDescription />} />
        </Route>
        <Route
          path="/queues"
          element={<ProtectedRoute check={PERMISSIONS.SEE_TM} />}
        >
          <Route index element={<TransactionQueuesRegister />} />
        </Route>
        <Route
          path="/entity/:id"
          element={
            <ProtectedRoute
              check={[
                PERMISSIONS.CLIENT_MANAGE,
                PERMISSIONS.SUBMISSIONS_SEE_OTHERS,
                PERMISSIONS.MANAGE_TM,
                PERMISSIONS.SEE_TM,
                PERMISSIONS.SEE_TM_CASES,
                PERMISSIONS.MANAGE_TM_CASES,
                PERMISSIONS.MANAGE_PROFILE,
                PERMISSIONS.SEE_CASE_RELATED_TRANSACTIONS,
              ]}
            />
          }
        >
          <Route index element={<Entity />} />
        </Route>
        <Route
          path="/instrument/:id"
          element={
            <ProtectedRoute
              check={[
                PERMISSIONS.CLIENT_MANAGE,
                PERMISSIONS.SUBMISSIONS_SEE_OTHERS,
                PERMISSIONS.MANAGE_TM,
                PERMISSIONS.SEE_TM,
                PERMISSIONS.SEE_TM_CASES,
                PERMISSIONS.MANAGE_TM_CASES,
                PERMISSIONS.MANAGE_PROFILE,
                PERMISSIONS.SEE_CASE_RELATED_TRANSACTIONS,
              ]}
            />
          }
        >
          <Route index element={<Instrument />} />
        </Route>
        <Route
          path="/rulesets"
          element={<ProtectedRoute check={PERMISSIONS.MANAGE_TM} />}
        >
          <Route index element={<RuleSets />} />
        </Route>
        <Route
          path="/providers"
          element={<ProtectedRoute check={PERMISSIONS.MANAGE_TM} />}
        >
          <Route index element={<Providers />} />
        </Route>
        <Route
          path="/tags"
          element={<ProtectedRoute check={PERMISSIONS.SEE_TM} />}
        >
          <Route index element={<TagsRegister />} />
          <Route
            element={
              <ProtectedFeaturesRoute check={FEATURES.TRANSACTION_MONITORING} />
            }
          >
            <Route path="form" element={<TagCreate />} />
          </Route>

          <Route path="form/:tag_id" element={<TagUpdate />} />
        </Route>
        <Route
          path="/rules"
          element={<ProtectedRoute check={PERMISSIONS.MANAGE_TM} />}
        >
          <Route index element={<RulesRegister />} />
          <Route path=":id" element={<RuleDescription />} />
          <Route path="form">
            <Route
              element={
                <ProtectedFeaturesRoute
                  check={FEATURES.TRANSACTION_MONITORING}
                />
              }
            >
              <Route index element={<RuleCreate />} />
              <Route path=":code" element={<RuleCreate />} />
            </Route>

            <Route path=":code/:id" element={<RuleUpdate />} />
          </Route>
        </Route>
        <Route
          path="/narrative_templates"
          element={<ProtectedRoute check={PERMISSIONS.SEE_TM} />}
        >
          <Route index element={<TemplatesRegister />} />
          <Route path="form">
            <Route
              element={
                <ProtectedFeaturesRoute
                  check={FEATURES.TRANSACTION_MONITORING}
                />
              }
            >
              <Route index element={<TemplateCreate />} />
            </Route>
            <Route path=":id" element={<TemplateUpdate />} />
          </Route>
        </Route>
        <Route
          path="/dispositions"
          element={<ProtectedRoute check={PERMISSIONS.SEE_TM} />}
        >
          <Route index element={<DispositionsRegister />} />
        </Route>
        <Route
          path="/matrices"
          element={
            <ProtectedRoute check={PERMISSIONS.MANAGE_TM_SCORING_RULES} />
          }
        >
          <Route index element={<MatricesRegister />} />
          <Route path="form">
            <Route
              element={
                <ProtectedFeaturesRoute check={FEATURES.TRX_RISK_SCORE} />
              }
            >
              <Route index element={<MatrixCreate />} />
            </Route>

            <Route path=":id" element={<MatrixDescription />} />
          </Route>
        </Route>
        <Route
          path="/blacklist_groups"
          element={<ProtectedRoute check={PERMISSIONS.SEE_TM} />}
        >
          <Route index element={<BlacklistGroupRegister />} />
          <Route
            path=":blacklist_group_id"
            element={<BlacklistGroupUpdate />}
          />
        </Route>
        <Route
          path="/sar"
          element={
            <ProtectedRoute
              check={[
                PERMISSIONS.FILL_SAR_REPORT,
                PERMISSIONS.MANAGE_SAR_REPORT,
              ]}
            />
          }
        >
          <Route index element={<SarRegister />} />
          <Route path=":id/*" element={<EditSarReport />} />
        </Route>
        <Route path="*" element={<ErrorPages type="local" code={404} />} />
      </Route>
    </Routes>
  );
}
