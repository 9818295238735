import gql from 'graphql-tag';

export const CreateCdsCableRefreshTask = gql`
  mutation CreateCdsCableRefreshTask($body: BodyCreateCdsCableRefreshTask!) {
    createCdsCableRefreshTask(body: $body)
      @rest(
        type: "CreateCdsCableRefreshTask"
        path: "/cds/cable/refresh_tasks"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "CdsCableRefreshTask") {
        completeness
        created_at
        id
        overall_events
        performed_at
        processed_events
        status
        updated_at
      }
      success
    }
  }
`;

export const CreateCdsCableCsvExport = gql`
  mutation CreateCdsCableCsvExport($body: BodyCreateCdsCableCsvExport!) {
    createCdsCableCsvExport(body: $body)
      @rest(
        type: "CreateCdsCableCsvExport"
        path: "/cds/cable/csv_export_tasks"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "CdsCableCsvExport") {
        created_at
        delta_from
        delta_upto
        id
        performed_at
        status
        table_name
        updated_at
        uploaded_document_id
      }
      success
    }
  }
`;

export const CreateCdsCableCsvExportAll = gql`
  mutation CreateCdsCableCsvExportAll($body: BodyCreateCdsCableCsvExportAll!) {
    createCdsCableCsvExportAll(body: $body)
      @rest(
        type: "CreateCdsCableCsvExport"
        path: "/cds/cable/csv_export_tasks/all"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "CdsCableCsvExport") {
        created_at
        delta_from
        delta_upto
        id
        performed_at
        status
        table_name
        updated_at
        uploaded_document_id
      }
      success
    }
  }
`;

export const UploadToCableCsvExport = gql`
  mutation UploadToCableCsvExport($id: String!) {
    uploadToCableCsvExport(id: $id)
      @rest(
        type: "UploadToCableCsvExport"
        path: "/cds/cable/csv_export_tasks/{args.id}/upload_to_cable"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "CdsCableCsvExport") {
        created_at
        delta_from
        delta_upto
        id
        performed_at
        status
        table_name
        updated_at
        uploaded_document_id
      }
      success
    }
  }
`;
