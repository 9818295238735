import {
  AutomaticTooltip,
  DefaultInputLoader,
  Icon,
  Text,
  TextInput,
} from '@aid-module/ui';
import { Container, SearchButton } from './styles';
import { useSearchInputController } from './controller';
import { ViewProps } from './types';

function View({
  initiateRef,
  value,
  disabled,
  disabledButton,
  loading,
  onPressEnter,
  onChange,
  handlerSubmit,
}: ViewProps) {
  return (
    <Container ref={initiateRef} gridTemplateColumns="1fr max-content">
      <TextInput
        autoFocus={true}
        fallback={<DefaultInputLoader />}
        onKeyPress={onPressEnter}
        value={value}
        disabled={disabled}
        onChange={onChange}
        placeholder="Search..."
      />
      <SearchButton disabled={disabledButton} onClick={handlerSubmit}>
        <Icon
          glyph={loading ? 'spinner' : 'search'}
          color="white-primary"
          size={12}
        />
      </SearchButton>
    </Container>
  );
}

export function SearchInput() {
  const {
    value,
    disabled,
    loading,
    canAvailabel,
    onChange,
    handlerSubmit,
    onPressEnter,
  } = useSearchInputController();

  return (
    <AutomaticTooltip
      canShow={!canAvailabel}
      pattern="bottom,right,left,top"
      background="main-deep-3"
      view={
        <View
          disabled={!canAvailabel}
          disabledButton={disabled}
          onChange={onChange}
          value={value}
          loading={loading}
          handlerSubmit={handlerSubmit}
          onPressEnter={onPressEnter}
        />
      }
    >
      <Text
        color="white-primary"
        text="Feature is not available. Check your subscription and limits"
        size={14}
      />
    </AutomaticTooltip>
  );
}
