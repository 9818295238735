import { useCaSearchesQuery } from '@aid-package/api';
import { useCheckupContext } from '../../../../../../context';

export function useSearchDataProviderModel() {
  const checkup = useCheckupContext();

  const { data, loading } = useCaSearchesQuery({
    variables: {
      ca_search_id: checkup.ca_search_id,
    },
    skip: !checkup.ca_search_id,
  });

  return {
    loading,
    searchData: data?.caSearches.data,
  };
}
