import gql from 'graphql-tag';

export const CdsCableAccountStatuses = gql`
  query CdsCableAccountStatuses(
    $page: Int
    $per: Int
    $user_id: String
    $company_id: String
  ) {
    cdsCableAccountStatuses(
      page: $page
      per: $per
      user_id: $user_id
      company_id: $company_id
    )
      @rest(
        type: "ResponseCdsCableAccountStatuses"
        path: "/cds/cable/account_statuses?{args}"
      ) {
      data @type(name: "CdsCableAccountStatus") {
        id
        account_status
        case_id
        client_id
        company_id
        created_at
        updated_at
        user_id
      }
      meta {
        count
        page
        items
        pages
      }
      success
    }
  }
`;
