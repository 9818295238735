import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useModalContext } from '../../../../../../../context';

export function useToNotificationsController() {
  const { client_id } = useParams();
  const { close } = useModalContext();
  const navigate = useNavigate();

  const toNotifications = useCallback(() => {
    close();
    navigate(`/${client_id}/notification/notifications`);
  }, [client_id, navigate, close]);

  return {
    toNotifications,
  };
}
