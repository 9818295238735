import loadable from '@loadable/component';
import { LoadableFunctionalComponent } from '../../utils';
import { DefaultButtonLoader } from '../../shared';
import { SelectButtonComponentViewProps } from './component/types';
import { PropsWithChildren } from 'react';

export const SelectButton = loadable(() => import('./component'), {
  resolveComponent: (components) => components.SelectButtonComponent,
  fallback: <DefaultButtonLoader />,
}) as LoadableFunctionalComponent<
  PropsWithChildren<SelectButtonComponentViewProps>
>;
