import { GridContainer, PrimaryButton, SecondaryButton, ModalBodyViewProps } from '@aid-module/ui';
import { useActionsController } from './contoller';

export function Actions({ onClose }: ModalBodyViewProps) {
  const { onClickSubmit, disabled } = useActionsController(onClose);

  return (
    <GridContainer gridTemplateColumns="repeat(2, 1fr)" gap="10">
      <SecondaryButton text="Cancel" onClick={onClose} />
      <PrimaryButton text="Submit" onClick={onClickSubmit} disabled={disabled} />
    </GridContainer>
  );
}