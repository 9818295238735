import gql from 'graphql-tag';

export const CdsFieldMappings = gql`
  query CdsFieldMappings($page: Int, $per: Int) {
    cdsFieldMappings(page: $page, per: $per)
      @rest(
        type: "ResponseCdsFieldMappings"
        path: "/cds/field_mappings?{args}"
      ) {
      data @type(name: "CdsFieldMapping") {
        created_at
        entity_template_id
        form_field_id
        form_field_reference_key
        form_mapping_id
        id
        map_to_field_name
        static_value
        map_to_table_name
        mapper_klass
        updated_at
      }
      meta {
        count
        page
        items
        pages
      }
      success
    }
  }
`;

export const CdsFieldMapping = gql`
  query CdsFieldMapping($id: String!) {
    cdsFieldMapping(id: $id)
      @rest(
        type: "ResponseCdsFieldMapping"
        path: "/cds/field_mappings/{args.id}"
      ) {
      data @type(name: "CdsFieldMapping") {
        created_at
        entity_template_id
        form_field_id
        form_field_reference_key
        form_mapping_id
        id
        map_to_field_name
        static_value
        map_to_table_name
        mapper_klass
        updated_at
      }
      success
    }
  }
`;
