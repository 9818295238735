import {
  useServiceCheckupKindQuery,
  useServiceCheckupKindByCodeQuery,
} from '@aid-package/api';

export function useFormModel(code?: string, id?: string) {
  const { data: serviceCheckupKindData, loading: serviceKindDataLoading } =
    useServiceCheckupKindQuery({
      variables: { id: id || '' },
      skip: !id,
    });

  const {
    data: serviceCheckupKindByCodeData,
    loading: serviceKindDataByCodeLoading,
  } = useServiceCheckupKindByCodeQuery({
    variables: { checkupKindCode: code || '' },
    skip: !code || !!id,
  });

  return {
    loading: serviceKindDataLoading || serviceKindDataByCodeLoading,
    field_schema:
      serviceCheckupKindData?.serviceCheckupKind?.data.fields_schema ||
      serviceCheckupKindByCodeData?.serviceCheckupKindByCode?.data
        .fields_schema,
  };
}
