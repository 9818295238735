import { ContentLoader, LoadableFunctionalComponent } from '@aid-module/ui';
import loadable from '@loadable/component';
import { PropsWithChildren } from 'react';
import { FormViewProps } from './component/types';

export function FormLoader() {
  return (
    <ContentLoader width="100%" height="442.84px">
      <rect rx="4" ry="4" width="100%" height="442.84px" />
    </ContentLoader>
  );
}

export const Form = loadable(() => import('./component'), {
  resolveComponent: (components) => components.FormComponent,
  fallback: <FormLoader />,
}) as LoadableFunctionalComponent<PropsWithChildren<FormViewProps>>;
