import { ContentLoader, DefaultButtonLoader, ListLoader } from '@aid-module/ui';

export function Fallback() {
  return (
    <>
      <ListLoader count={7} />
      <ContentLoader width="100%" height="27.3">
        <rect rx="8" ry="8" width="100%" height="27.3" />
      </ContentLoader>
      <ListLoader count={5} />
      <DefaultButtonLoader />
    </>
  );
}
