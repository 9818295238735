import styled from '@emotion/styled';
import {
  SecondaryButton,
  ErrorButton as UIErrorButton,
} from '../../../../../../../../../../../../../../../../../../Button';

export const Button = styled(SecondaryButton)`
  width: 24px;
  height: 24px;
`;

export const ErrorButton = styled(UIErrorButton)`
  width: 24px;
  height: 24px;
`;
