import {
  Card,
  FlexContainer,
  getColor,
  getSpace,
  GridContainer,
} from '@aid-module/ui';
import styled from '@emotion/styled/macro';
import {
  EmotionEmptyContainerViewProps,
  EmotionNotificationsContainerViewProps,
} from './types';

export const Container = styled(Card)`
  padding: 0px;
  overflow: hidden;
`;

export const ContentContainer = styled(Card)`
  border-radius: 0;
  padding: ${getSpace('12')} ${getSpace('20')};
  border-bottom: 1px solid ${getColor('main-deep-2')};
`;

export const ScrollContainer = styled(FlexContainer)`
  overflow-anchor: none;
  max-height: 65vh;
  overflow: auto;
`;

export const EmptyContainer = styled(
  GridContainer
)<EmotionEmptyContainerViewProps>`
  display: none;
  max-height: max-content;
  padding: ${getSpace('32')};

  ${({ isEmpty }) => isEmpty && 'true'};
`;

export const NotificationsContainer = styled(
  GridContainer
)<EmotionNotificationsContainerViewProps>`
  max-height: 65vh;
  overflow: auto;
  & > ${ScrollContainer}:empty + ${EmptyContainer} {
    display: grid;
  }
`;
