import { Container } from './styles';
import { FlexContainer } from '../../../../../../containers';
import { Text } from '../../../../../../components/Text';

export function LogoSvg() {
  return (
    <Container gap="18" alignItems="center">
      <FlexContainer justifyContent="center">
        <svg
          width="74"
          height="32"
          viewBox="0 0 74 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M37.2004 25.4076L27.5155 8.0735C24.9542 3.80159 20.3118 0.92627 15.0292 0.92627C6.86507 0.92627 0.301758 7.66274 0.301758 15.9601C0.301758 24.2574 6.86507 31.0761 15.0292 31.0761C15.0292 31.0761 16.4699 31.0761 16.8701 31.0761C19.0312 31.0761 20.7921 29.3509 20.7921 27.2971C20.7921 25.2433 19.1112 23.5181 17.1102 23.5181C16.7901 23.5181 15.0292 23.5181 15.0292 23.5181C10.9471 23.5181 7.66547 20.1498 7.66547 15.9601C7.66547 11.7703 10.9471 8.40211 15.0292 8.40211C17.5905 8.40211 19.8316 9.71654 21.1122 11.7703L21.1923 11.8525L30.8772 29.1866C31.9177 30.9939 34.1588 31.569 35.9197 30.5832C37.6806 29.5152 38.2409 27.2149 37.2004 25.4076Z"
            fill="#1F3B8E"
          />
          <path
            d="M48.567 30.5818C46.8061 31.6498 44.565 30.9926 43.5245 29.1853L30.8781 6.59346C29.8376 4.78611 30.4779 2.48585 32.2388 1.41788C33.9997 0.349901 36.2408 1.00712 37.2813 2.81446L50.0077 25.4063C50.9682 27.2136 50.3279 29.5139 48.567 30.5818Z"
            fill="url(#paint0_linear_1122_1402)"
          />
          <path
            d="M59.6924 0.925232H46.8059C44.8049 0.925232 43.124 2.65042 43.124 4.70423C43.124 6.75803 44.8049 8.48322 46.8059 8.48322H59.5323C63.3742 8.72968 66.4158 12.0158 66.4158 16.0412C66.4158 19.9023 63.7744 23.0241 59.9325 23.517C59.9325 23.517 59.6924 23.517 59.4522 23.5992C57.4512 23.6813 55.7704 25.3244 55.7704 27.3782C55.7704 29.432 57.4512 31.1572 59.4522 31.1572C59.6924 31.1572 59.8524 31.1572 59.8524 31.1572C67.5363 30.6643 73.6994 24.1743 73.6994 16.1234C73.6994 7.90815 67.4563 1.33599 59.6924 0.925232Z"
            fill="#1F3B8E"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1122_1402"
              x1="40.4154"
              y1="0.903503"
              x2="40.4154"
              y2="31.0962"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#1F3B8E" />
              <stop offset="1" stopColor="#8BDEEF" />
            </linearGradient>
          </defs>
        </svg>
      </FlexContainer>
      <Text
        text="Compliance as a service"
        size={20}
        lineHeight="1.3"
        color="main-deep-4"
        uppercase
        weight="Bold"
        align="center"
      />
    </Container>
  );
}
