import { DefaultLink } from '../../../Text';
import { stopPropagation } from '../../../../utils';
import { Icon } from '../../../Icon';
import { useLinkButtonController } from './controller';
import { Container, DefaultLinkContainer } from './styles';
import { LinkButtonViewProps } from './types';

export function LinkButton({ path }: LinkButtonViewProps) {
  const { href } = useLinkButtonController(path);

  return (
    <DefaultLinkContainer>
      <DefaultLink to={href} target="_blank" onClick={stopPropagation}>
        <Container justifyContent="center" alignItems="center">
          <Icon size={10} glyph="externalLink" color="main-deep-4" />
        </Container>
      </DefaultLink>
    </DefaultLinkContainer>
  );
}
