import { ContentLoader, GridContainer, TabsLoader } from '@aid-module/ui';

export function Fallback() {
  return (
    <GridContainer gap="20">
      <ContentLoader width="100%" height="32.2px">
        <rect width="100%" height="32.2px" rx="3" ry="3" />
      </ContentLoader>
      <ContentLoader width="100%" height="260.78px">
        <rect width="100%" height="260.78px" rx="3" ry="3" />
      </ContentLoader>
      <TabsLoader />
    </GridContainer>
  );
}
