import { useCallback, useEffect } from 'react';
import { useBalanceModel } from '../model';
import { ResolveType, useConfirmModal } from '@aid-module/ui';
import {
  PERMISSIONS,
  getShow,
  usePermissionsContext,
} from '@aid-package/context';
import { MIN_BALANCE_LIMIT } from './constants';

export function useBalanceController() {
  const {
    balanceText,
    balanceAmount,
    client,
    loading,
    navigateToBiling,
    client_id,
  } = useBalanceModel();
  const permissionsContext = usePermissionsContext();

  const onConfirmCallback = useCallback(
    (type?: ResolveType) => {
      if (type === 'confirm' && client_id) {
        navigateToBiling.current(client_id);
      }
    },
    [client_id, navigateToBiling]
  );

  const { show } = useConfirmModal({
    title: `${client?.full_name ? `Hey, ${client?.full_name}!` : 'Hey!'}`,
    body: `Your account balance is below ${MIN_BALANCE_LIMIT} USD. Please top up to continue using the services without interruption.`,
    confirmActionTitle: 'Top up',
    cancelActionTitle: 'Cancel',
    onConfirmCallback,
  });

  const checkShowModal = useCallback(() => {
    if (
      typeof balanceAmount === 'number' &&
      !loading &&
      client_id === permissionsContext.client_id &&
      !!permissionsContext.permissions
    ) {
      const balanceLessThenLimit = balanceAmount < MIN_BALANCE_LIMIT;

      if (balanceLessThenLimit) {
        const canShow = getShow(
          permissionsContext,
          [PERMISSIONS.SEE_BALANCE, PERMISSIONS.REFILL_BALANCE],
          true
        );
        if (canShow) {
          show();
        }
      }
    }
  }, [show, balanceAmount, loading, permissionsContext, client_id]);

  useEffect(() => {
    checkShowModal();
  }, [checkShowModal]);

  return { balanceText, loading };
}
