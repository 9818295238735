import gql from 'graphql-tag';
import { TRANSACTION_FRAGMENT } from '../../../fragment/account/transactions';
import { META_FRAGMENT } from '../../../fragment/Meta';

export const Transactions = gql`
  ${TRANSACTION_FRAGMENT}
  ${META_FRAGMENT}
  query Transactions(
    $page: Int
    $amount_to: String
    $amount_from: String
    $status: TransactionStatus
    $kind: TransactionKind
    $q: String
  ) {
    transactions(
      page: $page
      per: 20
      amount_to: $amount_to
      amount_from: $amount_from
      status: $status
      kind: $kind
      q: $q
    )
      @rest(
        type: "ResponseTransactions"
        path: "/billing/client/transactions?{args}"
      ) {
      data @type(name: "Transaction") {
        ...TransactionFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const Transaction = gql`
  ${TRANSACTION_FRAGMENT}
  query Transaction($id: String!) {
    transaction(id: $id)
      @rest(
        type: "ResponseTransaction"
        path: "/core/client/billing/transactions/{args.id}"
      ) {
      data @type(name: "Transaction") {
        ...TransactionFragment
      }
      success
    }
  }
`;
