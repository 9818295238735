import { getSpace } from '@aid-module/ui';
import styled from '@emotion/styled';

export const Container = styled.div`
  margin: auto;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 1600px;
  padding: 0 ${getSpace('60')} ${getSpace('28')} ${getSpace('40')};
  min-width: 1250px;
  box-sizing: border-box;
  position: relative;
  overflow: auto;
`;
