import {
  LoadableFunctionalComponent,
  TableWithoutSubHeader,
} from '@aid-module/ui';
import loadable from '@loadable/component';

export const SubmissionsStatisticsTemplatesRegister = loadable(
  () => import('./component'),
  {
    resolveComponent: (components) => components.SubmissionsRegisterComponent,
    fallback: <TableWithoutSubHeader />,
  }
) as LoadableFunctionalComponent<{}>;
