import { ContentLoader, GridContainer, TableLoader } from '@aid-module/ui';

export function Fallback() {
  return (
    <GridContainer gap="20">
      <GridContainer gap="20">
        <ContentLoader width="500px" height="36.4px">
          <rect width="500px" height="36.4px" rx="3" ry="3" />
        </ContentLoader>
        <ContentLoader width="100%" height="82.49px">
          <rect width="100%" height="82.49px" rx="3" ry="3" />
        </ContentLoader>
        <ContentLoader width="100%" height="32.2px">
          <rect width="100%" height="32.2px" rx="3" ry="3" />
        </ContentLoader>
      </GridContainer>
      <TableLoader />
    </GridContainer>
  );
}
