import gql from 'graphql-tag';

export const DeviceInfos = gql`
  query DeviceInfos($profile_id: String!, $page: Int) {
    deviceInfos(profile_id: $profile_id, page: $page, per: 10)
      @rest(
        type: "ResponseDeviceInfos"
        path: "/core/client/device_infos?{args}"
      ) {
      data @type(name: "DeviceInfo") {
        account_id
        browser_info @type(name: "BrowserInfo") {
          browserFullVersion
          browserMajorVersion
          browserName
          device
          os
          osVersion
          userAgent
        }
        city
        country_code
        created_at
        customer_id
        device_info_doubles_count
        form_submission_id
        id
        incognito
        ip
        blacklisted_device_info @type(name: "BlacklistedDeviceInfo") {
          id
          deleted_at
        }
        raw_fingerprintjs_response @type(name: "RawFingerprintjsResponse") {
          incognito
          ip
          tag
          time
          timestamp
          url
          visitorFound
          browserDetails @type (name: "BrowserInfo") {
            browserFullVersion
            browserMajorVersion
            browserName
            device
            os
            osVersion
            userAgent
          }
          confidence @type(name: "Confidence") {
            score
          }
          firstSeenAt @type(name: "SeenAt") {
            global
            subscription
          }
          lastSeenAt @type(name: "SeenAt") {
            global
            subscription
          }
          ipLocation @type(name: "IpLocation") {
            accuracyRadius
            timezone
            postalCode
            longitude
            latitude
            city @type(name: "IpLocationCity") {
              name
            }
            country @type(name: "IpLocationItem") {
              code
              name
            }
            continent @type(name: "IpLocationItem") {
              code
              name
            }
            subdivisions @type(name: "Subdivision") {
              isoCode
              name
            }
          }
        }
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;
