import {
  ApolloLink,
  Operation,
  NextLink,
  Observable,
  FetchResult,
} from '@apollo/client';
import axios, { AxiosResponse } from 'axios';

export class DownloadLink extends ApolloLink {
  constructor() {
    super();
  }

  downloadDocument(operation: Operation, url: string) {
    return new Observable<FetchResult<{ downloadDocument: Blob }>>(
      (observer) => {
        const context = operation.getContext();
        axios
          .get(url, {
            responseType: 'blob',
            headers: context.headers,
            withCredentials: true,
          })
          .then((res: AxiosResponse<Blob>) => {
            observer.next({
              data: {
                downloadDocument: res.data,
              },
            });
            observer.complete();
          })
          .catch((e) => {
            observer.error(e);
          });
      }
    );
  }

  operation(operation: Operation) {
    const {
      variables: { url },
    } = operation;
    return this.downloadDocument(operation, url);
  }

  request(operation: Operation, forward: NextLink) {
    switch (operation.operationName) {
      case 'DownloadDocument':
        return this.operation(operation);
      default:
        return forward(operation);
    }
  }
}
