import gql from 'graphql-tag';

export const CreateCdsEntityTemplate = gql`
  mutation CreateCdsEntityTemplate($body: BodyCreateCdsEntityTemplate!) {
    createCdsEntityTemplate(body: $body)
      @rest(
        type: "CreateCdsEntityTemplate"
        path: "/cds/entity_templates"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "CdsEntityTemplate") {
        created_at
        entity_type
        form_mapping_id
        id
        kind
        updated_at
      }
      success
    }
  }
`;

export const UpdateCdsEntityTemplate = gql`
  mutation UpdateCdsEntityTemplate(
    $id: String!
    $body: BodyUpdateCdsEntityTemplate!
  ) {
    updateCdsEntityTemplate(id: $id, body: $body)
      @rest(
        type: "UpdateCdsEntityTemplate"
        path: "/cds/entity_templates/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "CdsEntityTemplate") {
        created_at
        entity_type
        form_mapping_id
        id
        kind
        updated_at
      }
      success
    }
  }
`;

export const DeleteCdsEntityTemplate = gql`
  mutation DeleteCdsEntityTemplate($id: String!) {
    deleteCdsEntityTemplate(id: $id)
      @rest(
        type: "DeleteCdsEntityTemplate"
        path: "/cds/entity_templates/{args.id}"
        method: "DELETE"
      ) {
      success
    }
  }
`;
