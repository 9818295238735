import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useModalContext } from '../../../../../context';

export function useToSettingsController() {
  const { client_id } = useParams();
  const { close } = useModalContext();

  const href = useMemo(() => `/${client_id}/profile/edit/notification_settings`, [client_id]);

  return {
    href,
    close,
  };
}
