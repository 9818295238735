import { useMemo } from 'react';
import { usePermissionsProviderModel } from '../model';
import { useParams } from 'react-router-dom';

export function usePermissionsProviderController() {
  const { client_id } = useParams();
  const { permissions, role, loading } = usePermissionsProviderModel();

  const context = useMemo(
    () => ({
      permissions,
      role,
      client_id,
    }),
    [permissions, role]
  );

  return { context, loading };
}
