import { useMemo } from 'react';
import { ProfilesTableInfoComponentViewProps } from '../types';
import { SortOrder } from './constants';

export function useProfilesTableInfoController(
  profiles: ProfilesTableInfoComponentViewProps['profiles'],
  customer: ProfilesTableInfoComponentViewProps['customer']
) {
  const sortedProfiles = useMemo(
    () => [...profiles].sort((a, b) => SortOrder[a.kind] - SortOrder[b.kind]),
    [profiles]
  );

  const count = useMemo(() => {
    return profiles.length - 1;
  }, [profiles]);

  const profile = useMemo(() => {
    return sortedProfiles?.[0] || customer?.profile;
  }, [sortedProfiles, customer?.profile]);

  return {
    count,
    profile,
    sortedProfiles,
  };
}
