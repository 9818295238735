import { gql } from '@apollo/client';
import { SLA_GENERAL_FRAGMENT } from '../../../../fragment/stats/sla';

export const SlaGeneral = gql`
  ${SLA_GENERAL_FRAGMENT}
  query SlaGeneral($page: Int!, $states: [CaseStatus!]) {
    slaGeneral(page: $page, states: $states)
      @rest(
        type: "ResponseSlaGeneral"
        path: "/stats/client/sla_general?{args}"
      ) {
      data @type(name: "SlaGeneral") {
        ...SlaGeneralFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;
