import { FlexContainer } from '../../../../../../../../../../../../../../../../../containers';
import { AutomaticControllerTooltipInitiateView } from '../../../../../../../../../../../../../../../../AutomaticTooltip/types';
import { Icon } from '../../../../../../../../../../../../../../../../Icon';

export function Button({
  initiateRef,
}: AutomaticControllerTooltipInitiateView) {
  return (
    <FlexContainer ref={initiateRef}>
      <Icon size={14} glyph="info" color="main-deep-2" fill="main-deep-4" />
    </FlexContainer>
  );
}
