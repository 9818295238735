import { PropsWithChildren } from 'react';
import {
  DefaultButton,
  CircleButton,
  SmallButton,
  TinyButton,
  IconButton,
} from './components';
import { ButtonViewProps } from '../../types';

export function ButtonByType(props: PropsWithChildren<ButtonViewProps>) {
  switch (props.type) {
    case 'circle':
      return <CircleButton {...props} />;
    case 'small':
      return <SmallButton {...props} />;
    case 'tiny':
      return <TinyButton {...props} />;
    case 'icon':
      return <IconButton {...props} />;
    default:
      return <DefaultButton {...props} />;
  }
}
