import { useFormController } from './controller';
import { FormProvider, Controller } from 'react-hook-form';
import { useCallback } from 'react';
import { GridContainer } from '../../../../../../../../../../../containers';
import { ButtonContainer } from './styles';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../../../../../../../../Button';
import { FieldWithName } from './controller/types';

export function Form() {
  const {
    formContext,
    onClickClear,
    onClickSubmit,
    filterInputs,
    toggleShowFilters,
  } = useFormController();

  const renderField = useCallback(
    (config: FieldWithName) => (
      <GridContainer gridTemplateRows="max-content" key={config.name}>
        <Controller
          key={config.name}
          name={config.name}
          render={config.render}
        />
      </GridContainer>
    ),
    []
  );

  return (
    <FormProvider {...formContext}>
      <GridContainer
        gridTemplateColumns="repeat(4, 1fr)"
        gap="20"
        gridTemplateRows="max-content"
      >
        {filterInputs.map(renderField)}
      </GridContainer>
      <ButtonContainer gridTemplateColumns="repeat(3, max-content)" gap="20">
        <SecondaryButton text="Close" onClick={toggleShowFilters} />
        <SecondaryButton text="Clear" onClick={onClickClear} />
        <PrimaryButton text="Apply" onClick={onClickSubmit} />
      </ButtonContainer>
    </FormProvider>
  );
}
