import gql from 'graphql-tag';

export const UpdateClientDictionary = gql`
  mutation UpdateClientDictionary($id: String!, $body: RequestCreateOrUpdateClientDictionary!) {
    updateClientDictionary(id: $id, body: $body)
    @rest(
      type: "ClientDictionaryUpdate"
      path: "/core/client/client_dictionaries/{args.id}"
      bodyKey: "body"
      method: "PUT"
    ) {
      data @type(name: "ClientDictionaryItem") {
        id
        created_at
        updated_at
        name
        code
        client_dictionary_items @type(name: "ClientDictionaryItemsAttribute") {
          name
          value
        }
      }
      success
      error
    }
  }
`;

export const CreateClientDictionary = gql`
  mutation CreateClientDictionary($body: RequestCreateOrUpdateClientDictionary!) {
    createClientDictionary(body: $body)
    @rest(
      type: "ClientDictionaryCreate"
      path: "/core/client/client_dictionaries"
      bodyKey: "body"
      method: "POST"
    ) {
      data @type(name: "ClientDictionaryItem") {
        id
        created_at
        updated_at
        name
        code
        client_dictionary_items @type(name: "ClientDictionaryItemsAttribute") {
          name
          value
        }
      }
      success
      error
    }
  }
`;

export const DeleteClientDictionary = gql`
  mutation DeleteClientDictionary($id: String!) {
    deleteClientDictionary(id: $id)
    @rest(
      type: "ClientDictionaryDelete"
      path: "/core/client/client_dictionaries/{args.id}"
      method: "DELETE"
    ) {
      success
    }
  }
`;
