import { useFilterTemplatesProviderModel } from '../model';
import { FilterTemplatesProviderViewProps } from '../types';

export function useFilterTemplatesProviderController(
  code: FilterTemplatesProviderViewProps['code']
) {
  const data = useFilterTemplatesProviderModel(code);

  return { data, code };
}
