import { useMemo } from 'react';
import { ErrorTemplateViewProps } from './components/ErrorTemplate/types';
import { Error403 } from './Error403';
import { Error404 } from './Error404';
import { Error500 } from './Error500';
import { ErrorBundle } from './ErrorBundle';
import { ErrorCritical } from './ErrorCritical';

const Components = {
  500: Error500,
  404: Error404,
  403: Error403,
};

function ErrorPages({
  code = 500,
  type = 'global',
}: Partial<Pick<ErrorTemplateViewProps, 'code' | 'type'>>) {
  const Component = useMemo(() => Components[code], [code]);

  if (Component) {
    return <Component type={type} />;
  }

  return null;
}

export { ErrorBundle, ErrorPages, ErrorCritical };
