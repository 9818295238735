import { useCallback, useEffect, useState } from 'react';
import { PagginationOptions } from './types';
import { useVariablesContext } from '../../Veriables';
import { usePaginationContextModel } from '../model';
import { PagginationProviderViewProps } from '../types';

export function usePagginationProviderController<T>(
  query: PagginationProviderViewProps['query'],
  parseVariables: PagginationProviderViewProps['parseVariables']
) {
  const { variables } = useVariablesContext();
  const [pagginationOptions, setPagginationOptions] =
    useState<PagginationOptions>({
      page: 1,
      per: 20,
    });
  const { data, loading, refetch, updateQuery } = usePaginationContextModel<T>(
    query,
    parseVariables,
    pagginationOptions
  );

  const [pagginationSupport, setPagginationSupport] = useState({
    count: 0,
    pages: 0,
  });
  const [supportingLoading, setSupportingLoading] = useState(true);

  const clean = useCallback(() => {
    setSupportingLoading(true);
    setPagginationOptions((prev) => {
      if (prev.page === 1) {
        refetch();
        return prev;
      }
      return {
        ...prev,
        page: 1,
      };
    });
  }, [refetch, updateQuery]);

  useEffect(() => {
    if (!loading) {
      setSupportingLoading(false);
    }
  }, [loading]);

  useEffect(() => {
    setSupportingLoading(true);
    setPagginationOptions((prev) => {
      if (prev.page !== 1) {
        return {
          ...prev,
          page: 1,
        };
      }
      return prev;
    });
  }, [variables]);

  useEffect(() => {
    const meta = data?.meta;
    if (meta) {
      setPagginationSupport((prev) => {
        if (prev.pages !== meta.pages || prev.count !== meta.count) {
          return {
            count: meta.count,
            pages: meta.pages,
          };
        }
        return prev;
      });
    }
  }, [data?.meta]);

  const changePage = useCallback((next: number) => {
    setPagginationOptions((prev) => {
      if (prev.page !== next) {
        return {
          ...prev,
          page: next,
        };
      }
      return prev;
    });
  }, []);

  const changePerPage = useCallback((next: number) => {
    setPagginationOptions((prev) => {
      if (prev.per !== next) {
        setSupportingLoading(true);
        return {
          page: 1,
          per: next,
        };
      }
      return prev;
    });
  }, []);

  return {
    queryParams: pagginationOptions,
    data,
    supporting: pagginationSupport,
    loading: supportingLoading,
    changePage,
    changePerPage,
    clean,
  };
}
