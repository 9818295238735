import { PERMISSIONS, PermissionsContextParams } from '../../permissions/types';
import { Check } from '../types';

function get(
  permissions: PermissionsContextParams,
  check: PERMISSIONS
): boolean | undefined {
  const path = check.split('.');

  let searchedPermission: any = permissions;
  for (let i = 0, count = path.length; i < count; i++) {
    const key = path[i];
    if (searchedPermission[key]) {
      searchedPermission = searchedPermission[key];
      continue;
    }
    searchedPermission = undefined;
    break;
  }
  return searchedPermission;
}

function getShowFromArray(
  check: Exclude<Check, PERMISSIONS>,
  permissions: PermissionsContextParams,
  strong: boolean
): boolean {
  let show = strong;

  for (let i = 0, length = check.length; i < length; i++) {
    const currentPermissions = check[i];
    if (Array.isArray(currentPermissions)) {
      const value = getShowFromArray(currentPermissions, permissions, strong);
      if (value) {
        show = value;
      } else {
        show = false;
        break;
      }
    } else {
      const value = Boolean(get(permissions, currentPermissions));
      if (value === !strong) {
        show = !strong;
        break;
      }
    }
  }

  return show;
}

export function getShow(
  permissions: PermissionsContextParams,
  check: Check,
  strong: boolean
) {
  if (typeof check === 'string') {
    return Boolean(get(permissions, check));
  }
  return getShowFromArray(check, permissions, Boolean(strong));
}
