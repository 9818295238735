import { getColor, getSpace, GridContainer } from '@aid-module/ui';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const ContentContainer = styled(GridContainer)`
  overflow: hidden;
  border-radius: 8px;
  background: ${getColor('white-primary')};
  border: 1px solid ${getColor('main-deep-2')};
`;

export const HeaderContainer = styled.div`
  background: ${getColor('main-deep-1')};
  padding: ${getSpace('14')} ${getSpace('20')};
`;

export const MenuContainer = styled.div`
  background: ${getColor('main-deep-1')};
  padding: ${getSpace('14')} ${getSpace('20')};
`;

export const ActionContainer = styled(Link)`
  padding: 0px ${getSpace('20')};
  text-decoration: none;
  display: block;
  cursor: pointer;
  margin: -1px 0px;

  &:hover {
    & > div {
      border-bottom: 1px solid ${getColor('main-deep-1')}!important;
    }
    background: ${getColor('main-deep-1')};
  }
`;

export const ActionContentContainer = styled(GridContainer)`
  padding: ${getSpace('14')} 0px;
  border-bottom: 1px solid ${getColor('main-deep-2')};
`;

export const ActionContentContainerWithPadding = styled(GridContainer)`
  padding: ${getSpace('14')} ${getSpace('20')};
  cursor: pointer;
  &:hover {
    background: ${getColor('main-deep-1')};
  }
`;
