import gql from 'graphql-tag';
import { BLACKLIST_REASON_FRAGMENT } from '../../../fragment/admin/blacklistReasons';

export const BlacklistReasons = gql`
  ${BLACKLIST_REASON_FRAGMENT}

  query BlacklistReasons($page: Int, $q: String) {
    blacklistReasons(page: $page, per: 20, q: $q)
      @rest(type: "BlacklistReasonsResponse", path: "/core/client/blacklist_reasons?{args}") {
      data @type(name: "BlacklistReason") {
        ...BlacklistReasonFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const BlacklistReason = gql`
  ${BLACKLIST_REASON_FRAGMENT}

  query BlacklistReason($id: String!) {
    blacklistReason(id: $id)
      @rest(type: "BlacklistReasonResponse", path: "/core/client/blacklist_reasons/{args.id}") {
      data @type(name: "BlacklistReason") {
        ...BlacklistReasonFragment
      }
      success
    }
  }
`;
