import { useCallback, useMemo } from 'react';
import { useCheckPermission } from '@aid-package/context';
import { useSideWidgetProviderContext } from '../../SideWidget';
import { Widget } from '../components';
import { AssignToViewProps } from '../types';

export function useAssignToController(
  permissions: AssignToViewProps['permissions'],
  id: AssignToViewProps['id'],
  additionalUserSelect: AssignToViewProps['additionalUserSelect'],
  withoutNote: AssignToViewProps['withoutNote'],
  selectLabel: AssignToViewProps['selectLabel'],
  disabledProp: AssignToViewProps['disabledProp'],
  assign: AssignToViewProps['assign'],
  disabledText: AssignToViewProps['disabledText']
) {
  const { canShow } = useCheckPermission(permissions || [], true);
  const { show } = useSideWidgetProviderContext();

  const onClick = useCallback(() => {
    show(
      <Widget
        withoutNote={withoutNote}
        additionalUserSelect={additionalUserSelect}
        id={id}
        selectLabel={selectLabel}
        assign={assign}
      />,
      `assign_${id}`
    );
  }, [show]);

  const tooltipText = useMemo(() => {
    const defaultText = 'You do not have permissions';
    return disabledProp ? disabledText || defaultText : defaultText;
  }, [disabledProp, disabledText]);

  return { tooltipText, disabled: disabledProp || !canShow, onClick };
}
