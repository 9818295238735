import { SubmissionCounterViewProps } from './types';
import { Text } from '../../../Text';
import { useSubmissionCounterController } from './controller';
import { Container } from './styles';

export function SubmissionCounter({ count }: SubmissionCounterViewProps) {
  const { text } = useSubmissionCounterController(count);

  return (
    <Container alignItems="center" justifyContent="center">
      <Text color="white-primary" size={8} text={`${count} ${text}`} />
    </Container>
  );
}
