import { GridContainer, Text, Select, ContentLoader } from '@aid-module/ui';
import { StyledCard } from './styles';
import { RetentionPeriodsOptions } from './constants';
import { Controller, FormProvider } from 'react-hook-form';
import { useModalController } from './controller';
import { FormRenderProps } from './types';
import { Actions } from './components';

function renderQuestion({
  field: { value, onChange },
}: FormRenderProps<'data_storage_period_days'>) {
  return (
    <Select
      value={value}
      onChange={onChange}
      placeholder="Choose one of options"
      options={RetentionPeriodsOptions}
      fallback={
        <ContentLoader width="100%" height="32.2">
          <rect x="0" y="21.59" rx="3" ry="3" width="100%" height="32.2" />
        </ContentLoader>
      }
    />
  );
}

export function Modal() {
  const formContext = useModalController();
  return (
    <FormProvider {...formContext}>
      <StyledCard border="main-deep-2" background="main-deep-1">
        <GridContainer gridTemplateColumns="320px" gap="20">
          <GridContainer gap="14">
            <Text
              size={18}
              weight="VeryBig"
              color="main-deep-4"
              text="Please specify the desired data retention period for onboarding"
            />
            <GridContainer>
              <Text
                size={14}
                weight="Thin"
                text="Any onboarding data that is received by A.ID will be automatically deleted after the retention period, and it will not be possible to recover it."
              />
              <Text
                size={14}
                weight="Thin"
                text="The data retention period can be changed later in the client settings."
              />
            </GridContainer>
            <Controller
              name="data_storage_period_days"
              render={renderQuestion}
            />
          </GridContainer>
          <Actions />
        </GridContainer>
      </StyledCard>
    </FormProvider>
  );
}
