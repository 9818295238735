import {
  Container,
  DescriptionContainer,
  TitleContainer,
  FooterText,
  WrappedText,
  UnderlineText,
} from './styles';
import { LogoSvg } from './components';
import { useExternalLinkController } from './controller';
import {
  GridContainer,
  Text,
  DefaultExternalLink as Link,
  FlexContainer,
} from '@aid-module/ui';

export function ExternalLink() {
  const { href, copyrightText } = useExternalLinkController();
  return (
    <Container
      gridTemplateRows="max-content minmax(max-content, 1fr) max-content"
      justifyContent="center"
      alignItems="center"
    >
      <GridContainer gridTemplateRows="200px" alignItems="center">
        <LogoSvg />
      </GridContainer>
      <DescriptionContainer gap="40" flexDirection="column" alignItems="center">
        <GridContainer justifyContent="center" alignItems="center" gap="40">
          <GridContainer justifyContent="center">
            <svg
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1645_486)">
                <path
                  d="M79.3134 22.745L57.255 0.686562C56.8155 0.247031 56.2194 0 55.5978 0H24.4022C23.7806 0 23.1845 0.247031 22.745 0.686562L0.686562 22.745C0.247031 23.1845 0 23.7806 0 24.4022V55.5977C0 56.2192 0.247031 56.8153 0.686562 57.2548L22.745 79.3133C23.1845 79.7528 23.7806 79.9998 24.4022 79.9998H55.5977C56.2192 79.9998 56.8153 79.7528 57.2548 79.3133L79.3133 57.2548C79.7528 56.8153 79.9998 56.2192 79.9998 55.5977V24.4022C80 23.7806 79.753 23.1845 79.3134 22.745Z"
                  fill="#D89F0D"
                />
                <path
                  d="M80 24.4016V55.5984C80 56.2187 79.7531 56.8156 79.3141 57.2547L57.2547 79.3141C56.8156 79.7531 56.2187 80 55.5984 80H40V0H55.5984C56.2187 0 56.8156 0.246875 57.2547 0.685937L79.3141 22.7453C79.7531 23.1844 80 23.7812 80 24.4016Z"
                  fill="#C65C10"
                />
                <path
                  d="M37.0578 16.5625H42.9418C44.3056 16.5625 45.3814 17.7222 45.279 19.0822L43.2801 45.6447C43.1881 46.8675 42.1692 47.8125 40.9429 47.8125H39.0565C37.8303 47.8125 36.8114 46.8673 36.7193 45.6447L34.7206 19.0822C34.6184 17.7222 35.694 16.5625 37.0578 16.5625Z"
                  fill="white"
                />
                <path
                  d="M45.2797 19.0828L43.2797 45.6453C43.1891 46.8672 42.1688 47.8125 40.9438 47.8125H40V16.5625H42.9422C44.3062 16.5625 45.3813 17.7219 45.2797 19.0828Z"
                  fill="white"
                />
                <path
                  d="M35.3203 58.7422V58.7578C35.3203 61.3423 37.4155 63.4375 40 63.4375C42.5845 63.4375 44.6797 61.3423 44.6797 58.7578V58.7422C44.6797 56.1577 42.5845 54.0625 40 54.0625C37.4155 54.0625 35.3203 56.1577 35.3203 58.7422Z"
                  fill="white"
                />
                <path
                  d="M44.6797 58.7422V58.7578C44.6797 60.05 44.1562 61.2203 43.3094 62.0672C42.4625 62.9141 41.2922 63.4375 40 63.4375V54.0625C42.5844 54.0625 44.6797 56.1578 44.6797 58.7422Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_1645_486">
                  <rect width="80" height="80" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </GridContainer>
          <TitleContainer gap="20" alignItems="center">
            <Text
              text="Proceed with caution!"
              size={32}
              lineHeight="1.5"
              color="warning-deep-5"
              uppercase
              weight="Bold"
              align="center"
            />
            <GridContainer gridTemplateColumns="minmax(0px, 665px)" gap="40">
              <WrappedText
                size={20}
                lineHeight="1.3"
                weight="Medium"
                align="center"
              >
                {'You are being redirected to '}
                <UnderlineText
                  text={href}
                  color="main-deep-4"
                  size={20}
                  lineHeight="1.3"
                  weight="Medium"
                />
                . This site is out of our control.
              </WrappedText>
              <Text
                text="Be warned that any time you see this message you may be heading to a non-trusted site. Untrusted sites may contain malware or may imitate the design of other sites in attempt to deceit you to give away credentials, personal or financial information."
                lineHeight="1.3"
                align="center"
              />
              <FlexContainer justifyContent="center" alignItems="center">
                <Link
                  to={href || ''}
                  align="center"
                  color="warning-deep-5"
                  weight="Medium"
                  text={`Proceed to ${href}`}
                />
                <GridContainer gridTemplateColumns="12px">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.3535 5.64646L7.8535 2.14646C7.7592 2.05538 7.6329 2.00498 7.5018 2.00612C7.3707 2.00726 7.24529 2.05985 7.15259 2.15255C7.05989 2.24525 7.0073 2.37066 7.00616 2.50176C7.00502 2.63286 7.05542 2.75916 7.1465 2.85346L9.793 5.49996H1C0.867392 5.49996 0.740215 5.55264 0.646447 5.64641C0.552678 5.74017 0.5 5.86735 0.5 5.99996C0.5 6.13257 0.552678 6.25974 0.646447 6.35351C0.740215 6.44728 0.867392 6.49996 1 6.49996H9.793L7.1465 9.14646C7.09874 9.19258 7.06065 9.24776 7.03445 9.30876C7.00824 9.36976 6.99445 9.43537 6.99387 9.50176C6.9933 9.56815 7.00595 9.63399 7.03109 9.69544C7.05623 9.75688 7.09336 9.81271 7.1403 9.85966C7.18725 9.9066 7.24307 9.94373 7.30452 9.96887C7.36597 9.99401 7.43181 10.0067 7.4982 10.0061C7.56459 10.0055 7.6302 9.99171 7.6912 9.96551C7.7522 9.93931 7.80738 9.90122 7.8535 9.85346L11.3535 6.35346C11.4472 6.2597 11.4999 6.13254 11.4999 5.99996C11.4999 5.86738 11.4472 5.74022 11.3535 5.64646Z"
                      fill="#C65C10"
                    />
                  </svg>
                </GridContainer>
              </FlexContainer>
            </GridContainer>
          </TitleContainer>
        </GridContainer>
      </DescriptionContainer>
      <GridContainer gridTemplateRows="200px" alignItems="center">
        <FooterText
          align="center"
          size={12}
          color="main-deep-4"
          text={copyrightText}
        />
      </GridContainer>
    </Container>
  );
}
