import gql from 'graphql-tag';
import { ROLE_PERMISSIONS_FRAGMENT } from '../../../fragment/account/roles';

export const RoleUpdate = gql`
  ${ROLE_PERMISSIONS_FRAGMENT}
  mutation RoleUpdate($id: String!, $body: BodyRoleCreateOrUpdate!) {
    updateRole(id: $id, body: $body)
      @rest(
        type: "RoleUpdate"
        path: "/core/client/roles/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "Role") {
        ...RolePermissionsFragment
      }
      success
      errors {
        name
      }
    }
  }
`;

export const RoleCopy = gql`
  ${ROLE_PERMISSIONS_FRAGMENT}
  mutation RoleCopy($id: String!, $body: BodyRoleCopy!) {
    copyRole(id: $id, body: $body)
    @rest(
      type: "RoleCopy"
      path: "/core/client/roles/{args.id}/copy"
      method: "POST"
      bodyKey: "body"
    ) {
      data @type(name: "Role") {
        ...RolePermissionsFragment
      }
      success
      errors {
        name
      }
    }
  }
`;

export const RoleCreate = gql`
  ${ROLE_PERMISSIONS_FRAGMENT}
  mutation RoleCreate($body: BodyRoleCreateOrUpdate!) {
    createRole(body: $body)
      @rest(
        type: "RoleCreate"
        path: "/core/client/roles"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "Role") {
        ...RolePermissionsFragment
      }
      success
      errors {
        name
      }
    }
  }
`;

export const DeleteRole = gql`
  mutation DeleteRole($id: String!) {
    deleteRole(id: $id)
      @rest(
        type: "RoleDelete"
        path: "/core/client/roles/{args.id}"
        method: "DELETE"
      ) {
      success
    }
  }
`;
