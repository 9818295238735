import { TabRegistries, useCreateTabRegistryMutation, TYPES } from '@aid-package/api';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { showToast } from '../../../../../../../../../../../../../../../../../../../../../../../../../Toast';
import { prepareTabRegistry } from '../../../../../../../../../../../../../utils';

export function useActionsModel() {
  const { setError } = useFormContext();
  const [mutation, { loading }] = useCreateTabRegistryMutation({
    refetchQueries: [TabRegistries],
  });

  const createTabRegistry = useCallback(
    async (tab_registry: TYPES.CreateOrUpdateTabRegistry) => {
      const { data } = await mutation({
        variables: { body: { tab_registry: prepareTabRegistry(tab_registry) } },
      });

      if (data?.createTabRegistry?.success) {
        showToast({
          type: 'success',
          message: 'Changes were successfully saved',
        });
        return true;
      } else if (data?.createTabRegistry?.errors) {
        if (data?.createTabRegistry?.errors?.name) {
          const text =
            data?.createTabRegistry?.errors?.name[0] ===
            'has already been taken'
              ? 'Has already been taken'
              : 'Should include only numbers and symbols _ / : .';
          setError('name', { message: text, type: 'api' });
          return;
        }
      }
      showToast({
        type: 'error',
        message: 'Something went wrong',
      });
      return;
    },
    [mutation, setError]
  );

  return { loading, createTabRegistry };
}
