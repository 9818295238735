import { GridContainer, ListLoader } from '@aid-module/ui';
import { Fallback as CredentialsFallback } from '../../../../components/Credentials/Fallback';
import { DescriptionContainer } from '../../../../components/DescriptionContainer';

export function Fallback() {
  return (
    <GridContainer gridTemplateColumns="1fr" gap="32">
      <DescriptionContainer>
        <ListLoader />
      </DescriptionContainer>
      <CredentialsFallback />
    </GridContainer>
  );
}
