import { createContext, PropsWithChildren, useContext } from 'react';
import { CheckupsCreateConfig } from '../types';
import { CheckupKindContextParams } from './types';
import { useCheckupKindContextController } from './controller';

export const CheckupCreateConfigContext = createContext<
  CheckupsCreateConfig | undefined
  >(undefined);

export function useCheckupCreateConfigContext() {
  const context = useContext(CheckupCreateConfigContext);

  return context;
}

export const CheckupKindContext = createContext<CheckupKindContextParams>({
  checkupKind: null,
  setCheckupKind: () => {},
});

export function useCheckupKindContext() {
  const context = useContext(CheckupKindContext);

  return context;
}

export function CheckupKindProvider({ children }: PropsWithChildren<{}>) {
  const context = useCheckupKindContextController();

  return (
    <CheckupKindContext.Provider value={context}>
      {children}
    </CheckupKindContext.Provider>
  );
}
