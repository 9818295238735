import gql from 'graphql-tag';
import { PROVIDER_FRAGMENT } from '../../../fragment/admin/providers';

export const Providers = gql`
  ${PROVIDER_FRAGMENT}
  query Providers($page: Int, $q: String) {
    providers(page: $page, per: 20, q: $q)
      @rest(type: "ResponseProviders", path: "/checkups/admin/providers?{args}") {
      data @type(name: "Provider") {
        ...ProviderFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const Provider = gql`
  ${PROVIDER_FRAGMENT}
  query Provider($id: String!) {
    provider(id: $id)
      @rest(type: "ResponseProvider", path: "/checkups/admin/providers/{args.id}") {
      data @type(name: "Provider") {
        ...ProviderFragment
      }
      success
    }
  }
`;
