import {
  createContext,
  PropsWithChildren,
  useContext,
} from 'react';
import { useVirtualizerProviderController } from './controller';
import { VirtualizerViewProps } from './types';

export const VirtualizerContext = createContext<VirtualizerViewProps>({
  virtualizer: undefined,
});

export function useVirtualizerContext() {
  const context = useContext(VirtualizerContext);

  return context;
}

export function VirtualizerContextProvider({
  children,
}: PropsWithChildren<{}>) {
  const context = useVirtualizerProviderController();

  return (
    <VirtualizerContext.Provider value={context}>
      {children}
    </VirtualizerContext.Provider>
  );
}
