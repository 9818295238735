import { useEffect, useMemo } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { SelectOption } from '@aid-module/ui';
import { useCheckupCreateConfigContext } from '../../../../../../../../../../../context';
import { FormProps } from '../types';
import { resolver } from './validationSchema';

export function useSelectController() {
  const config = useCheckupCreateConfigContext();

  const defaultValues = useMemo(() => {
    return {
      profiles: config?.defaultValues?.profiles
        ? config.defaultValues.profiles.reduce<SelectOption[]>(
            (acc, profile) => {
              if (profile) {
                return [
                  ...acc,
                  {
                    label: profile.display_name || 'External profile',
                    value: profile.id,
                  },
                ];
              }
              return acc;
            },
            []
          )
        : null,
    };
  }, [config?.defaultValues]);

  const { ...formContext } = useForm<FormProps>({
    resolver,
    defaultValues,
  });

  const { setValue } = useFormContext();
  const profileValue = formContext.watch('profiles');

  useEffect(() => {
    setValue('profiles', profileValue);
  }, [setValue, profileValue]);

  return {
    formContext: { ...formContext },
  };
}
