import { ContentLoader, GridContainer, TabsLoader } from '@aid-module/ui';

export function Fallback() {
  return (
    <GridContainer gap="20">
      <GridContainer gap="8">
        <ContentLoader width="100%" height="26">
          <rect rx="4" ry="4" width="100%" height="26" />
        </ContentLoader>
        <ContentLoader width="100%" height="20.8">
          <rect rx="4" ry="4" width="100%" height="20.8" />
        </ContentLoader>
      </GridContainer>
      <TabsLoader />
    </GridContainer>
  );
}
