import { useCallback, useRef } from 'react';

export function useIconsCacheContextProviderController() {
  const subscribers = useRef<
    Record<string, Record<number, (isRender?: boolean) => void>>
  >({});
  const cache = useRef<Record<string, Record<number, boolean>>>({});
  const render = useRef<Record<string, number | undefined>>({});

  const use = useRef(
    (name: string, key: number, subscriber: (isRender?: boolean) => void) => {
      if (cache.current[name]) {
        cache.current[name][key] = true;
      } else {
        cache.current[name] = { [key]: true };
      }
      if (subscribers.current[name]) {
        subscribers.current[name][key] = subscriber;
      } else {
        subscribers.current[name] = { [key]: subscriber };
      }
      if (typeof render.current[name] !== 'number') {
        render.current = {
          ...render.current,
          [name]: key,
        };
        subscriber(true);
      }
    }
  );

  const clear = useRef((name: string, key: number) => {
    if (cache.current[name][key]) {
      delete cache.current[name][key];
    }
    if (subscribers.current[name][key]) {
      delete subscribers.current[name][key];
    }
    if (key === render.current[name]) {
      const firstKey = Number(Object.keys(cache.current[name])[0]);
      if (!isNaN(firstKey)) {
        render.current = {
          ...render.current,
          [name]: firstKey,
        };
        subscribers.current[name]?.[firstKey]?.(true);
      } else {
        render.current = {
          ...render.current,
          [name]: undefined,
        };
      }
    }
  });

  return { use: use.current, clear: clear.current };
}
