import gql from 'graphql-tag';
import { RISK_SCORING_TEMPLATE_FRAGMENT } from '../../../fragment/account/riskScoringTemplates';

export const RiskScoringTemplates = gql`
  ${RISK_SCORING_TEMPLATE_FRAGMENT}
  query RiskScoringTemplates($page: Int, $q: String) {
    riskScoringTemplates(page: $page, per: 20, q: $q)
      @rest(
        type: "ResponseRiskScoringTemplates"
        path: "/core/client/risk_scoring_items?{args}"
      ) {
      data @type(name: "RiskScoringTemplate") {
        ...RiskScoringTemplateFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const RiskScoringTemplate = gql`
  ${RISK_SCORING_TEMPLATE_FRAGMENT}
  query RiskScoringTemplate($id: String!) {
    riskScoringTemplate(id: $id)
      @rest(
        type: "ResponseRiskScoringTemplate"
        path: "/core/client/risk_scoring_items/{args.id}"
      ) {
      data @type(name: "RiskScoringTemplate") {
        ...RiskScoringTemplateFragment
      }
      success
    }
  }
`;
