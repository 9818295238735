import {
  TableWithHeaderLoader,
  LoadableFunctionalComponent,
} from '@aid-module/ui';
import loadable from '@loadable/component';

export const TemplatesRegister = loadable(() => import('./component'), {
  resolveComponent: (components) => components.TemplatesComponent,
  fallback: <TableWithHeaderLoader />,
}) as LoadableFunctionalComponent<{}>;
