import { GridContainer } from '../../../containers';
import { ContentLoader } from '../ContentLoader';
import { TableLoader } from '../TableLoader';

export function TableWithoutSubHeader() {
  return (
    <GridContainer gap="32">
      <ContentLoader width="100%" height="33.8px">
        <rect width="100%" height="33.8px" rx="3" ry="3" />
      </ContentLoader>
      <TableLoader />
    </GridContainer>
  );
}
