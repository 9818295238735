import { useCallback } from 'react';
import { BreadcrumbItem, PageNavigation } from './components';
import { BreadcrumbItemViewProps } from './components/BreadcrumbItem/types';
import {
  CaseManagementNavigationItems,
  DashboardsNavigationItems,
  AlertsNavigationItems,
  TmCasesNavigationItems,
  TMSettingsItems,
  UsersNavigationItems,
  EmailsNavigationItems,
  RemindersNavigationItems,
  DataRetentionNavigationItems,
} from './constants';
import { useBreadcrumbsController } from './controller';
import { Container } from './styles';

export function Breadcrumbs() {
  const {
    breadcrumbsItems,
    isCaseManagementNavigation,
    isDashboardsNavigation,
    isAlertsNavigation,
    isTmCasesNavigation,
    isTMSettingsNavigation,
    isUsersNavigation,
    isEmailsNavigation,
    isRemindersNavigation,
    isDataRetentionNavigation,
  } = useBreadcrumbsController();

  const renderBreadcrumb = useCallback(
    ({ name, path }: BreadcrumbItemViewProps) => (
      <BreadcrumbItem key={name} name={name} path={path} />
    ),
    []
  );

  if (isAlertsNavigation) {
    return (
      <PageNavigation
        gridTemplateColumns="repeat(4, 265px)"
        items={AlertsNavigationItems}
      />
    );
  }

  if (isTmCasesNavigation) {
    return <PageNavigation items={TmCasesNavigationItems} />;
  }

  if (isCaseManagementNavigation) {
    return <PageNavigation items={CaseManagementNavigationItems} />;
  }

  if (isDashboardsNavigation) {
    return (
      <PageNavigation
        items={DashboardsNavigationItems}
        gridTemplateColumns="repeat(6, 217px)"
      />
    );
  }

  if (isTMSettingsNavigation) {
    return (
      <PageNavigation
        items={TMSettingsItems}
        gridTemplateColumns="repeat(6, 217px)"
      />
    );
  }

  if (isUsersNavigation) {
    return <PageNavigation items={UsersNavigationItems} />;
  }

  if (isEmailsNavigation) {
    return <PageNavigation items={EmailsNavigationItems} />;
  }

  if (isRemindersNavigation) {
    return <PageNavigation items={RemindersNavigationItems} />;
  }

  if (isDataRetentionNavigation) {
    return <PageNavigation items={DataRetentionNavigationItems} gridTemplateColumns="250px 140px" />;
  }

  return (
    <Container isExist={!!breadcrumbsItems.length}>
      {breadcrumbsItems.map(renderBreadcrumb)}
    </Container>
  );
}
