import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCheckupKindContext } from '../../../../../context';
import { CheckUpsCreateViewProps } from '../types';
import { getValidationSchema } from './validationSchema';
import { useFormModel } from '../model';
import { parseSchema } from './utils';

export function useCreateFormController(key: CheckUpsCreateViewProps['name']) {
  const { checkupKind } = useCheckupKindContext();
  const checkupKindCode = useMemo(
    () => key || checkupKind?.code || '',
    [key, checkupKind?.code]
  );
  const checkupKindId = checkupKind?.value;
  const isCodeExist = useMemo(() => !!key, [key]);
  const isCodePicked = useMemo(
    () => checkupKindId || checkupKindCode,
    [checkupKindId, checkupKindCode]
  );

  const { field_schema, loading } = useFormModel(
    checkupKindCode,
    checkupKindId
  );

  const parsedSchema = useMemo(() => {
    if (field_schema) {
      return parseSchema(field_schema);
    }
    return [];
  }, [field_schema, checkupKindCode]);

  const resolver = useMemo(
    () => yupResolver(getValidationSchema(parsedSchema)),
    [parsedSchema]
  );

  const formContext = useForm({
    resolver,
  });

  useEffect(() => {
    formContext.setValue('profile', null);
  }, [checkupKindId, formContext]);

  return {
    formContext,
    parsedSchema,
    loading,
    isCodeExist,
    isCodePicked,
    checkupKindCode,
    checkupKindId,
  };
}
