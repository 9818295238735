import gql from 'graphql-tag';
import { SUBMISSION_FRAGMENT } from '../../fragment/submission';

export const CreateMetricsReport = gql`
  mutation CreateMetricsReport($body: BodyCreateMetricsReport!) {
    createMetricsReport(body: $body)
      @rest(
        type: "ResponseReport"
        path: "/stats/client/to_csv?csv_type=metric"
        bodyKey: "body"
        method: "POST"
      )
  }
`;

export const CreateComplianceViewReport = gql`
  mutation CreateComplianceViewReport($body: BodyComplianceViewReport!) {
    createComplianceViewReport(body: $body)
      @rest(
        type: "ResponseReport"
        path: "/stats/client/to_csv?csv_type=сompliance_view"
        bodyKey: "body"
        method: "POST"
      )
  }
`;

export const CreateQuickViewReport = gql`
  mutation CreateQuickViewReport($body: BodyQuickViewReport!) {
    createQuickViewReport(body: $body)
      @rest(
        type: "ResponseReport"
        path: "/stats/client/to_csv?csv_type=quick_view"
        bodyKey: "body"
        method: "POST"
      )
  }
`;

export const FetchSubmissionsStats = gql`
  mutation FetchSubmissionsStats($id: String!) {
    fetchSubmissionsStats(id: $id, body: ".")
      @rest(
        type: "ResponseSubmissionsStats"
        path: "/stats/submission_field_presets/{args.id}/fetch_stats"
        bodyKey: "body"
        method: "PATCH"
        bodySerializer: "empty"
      ) {
      data
      success
    }
  }
`;

export const CreateSubmissionsStatsPreset = gql`
  mutation CreateSubmissionsStatsPreset(
    $body: BodyCreateOrUpdateSubmissionsStatsPreset!
  ) {
    createSubmissionsStatsPreset(body: $body)
      @rest(
        type: "CreateSubmissionsStatsPreset"
        path: "/stats/submission_field_presets"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "SubmissionsStatsPreset") {
        form_configuration_name
        created_by_name
        client_id
        created_at
        created_by_id
        date_from
        date_to
        form_configuration_reference_key
        form_field_reference_keys
        id
        name
        updated_at
      }
      success
    }
  }
`;

export const FetchSubmissionsByValues = gql`
  ${SUBMISSION_FRAGMENT}
  mutation FetchSubmissionsByValues($body: BodySubmissionsByValues!) {
    fetchSubmissionsByValues(body: $body)
      @rest(
        type: "ResponseSubmissionsByValues"
        method: "POST"
        bodyKey: "body"
        path: "/stats/submission_field_presets/fetch_submission_ids_by_value"
      ) {
      data @type(name: "Submission") {
        ...SubmissionFragment
      }
      success
    }
  }
`;

export const UpdateSubmissionsStatsPreset = gql`
  mutation UpdateSubmissionsStatsPreset(
    $id: String!
    $body: BodyCreateOrUpdateSubmissionsStatsPreset!
  ) {
    updateSubmissionsStatsPreset(id: $id, body: $body)
      @rest(
        type: "UpdateSubmissionsStatsPreset"
        path: "/stats/submission_field_presets/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "SubmissionsStatsPreset") {
        form_configuration_name
        created_by_name
        client_id
        created_at
        created_by_id
        date_from
        date_to
        form_configuration_reference_key
        form_field_reference_keys
        id
        name
        updated_at
      }
      success
    }
  }
`;

export const DashboardPlus = gql`
  mutation DashboardPlus($body: BodyDashboardPlus!) {
    dashboardPlus(body: $body)
      @rest(
        type: "DashboardPlus"
        path: "/stats/client/dashboard_plus"
        bodyKey: "body"
        method: "POST"
      ) {
      data {
        approved_count
        days_to_submit
        new_count
        partition
        rejected_count
        submitted_count
        year
      }
      success
    }
  }
`;

export const DeleteSubmissionsStatsPreset = gql`
  mutation DeleteSubmissionsStatsPreset($id: String!) {
    deleteSubmissionsStatsPreset(id: $id)
      @rest(
        type: "DeleteSubmissionsStatsPreset"
        path: "/stats/submission_field_presets/{args.id}"
        method: "DELETE"
      ) {
      success
    }
  }
`;
