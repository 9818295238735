import { ApolloLink, Operation, NextLink } from '@apollo/client';
import { UsersQuery } from '../../api';
import { poltergeists } from './utils';

export class PoltergeistLink extends ApolloLink {
  constructor() {
    super();
  }
  request(operation: Operation, forward: NextLink) {
    switch (operation.operationName) {
      case 'Users':
        return forward(operation).map((result) => {
          const users = (
            result.data?.users.data as UsersQuery['users']['data']
          ).reduce<UsersQuery['users']['data']>((acc, user) => {
            if (user?.account?.id && poltergeists[user?.account?.id]) {
              return acc;
            }
            return [...acc, user];
          }, []);
          return {
            ...result,
            data: result.data
              ? {
                  ...result.data,
                  users: {
                    ...result.data.users,
                    data: users,
                  },
                }
              : undefined,
          };
        });
      default:
        return forward(operation);
    }
  }
}
