import { LoadableFunctionalComponent } from '@aid-module/ui';
import loadable from '@loadable/component';
import { FormContainer } from '../components';
import { Fallback } from './components';

export const BlacklistReasonsUpdate = loadable(() => import('./component'), {
  resolveComponent: (components) => components.BlacklistReasonUpdateComponent,
  fallback: (
    <FormContainer label="Update blacklist reason" icon="blacklist" isLoading>
      <Fallback />
    </FormContainer>
  ),
}) as LoadableFunctionalComponent<{}>;
