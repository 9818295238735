import { SelectOption, SelectViewProps, Select } from '@aid-module/ui';
import { useClientMethodsSelectController } from './controller';

export function ClientMethodsSelect(
  props: Pick<
    SelectViewProps<true, SelectOption>,
    'value' | 'onChange' | 'error' | 'label' | 'placeholder'
  >
) {
  const { options } = useClientMethodsSelectController();
  return <Select {...props} isMulti options={options} />;
}
