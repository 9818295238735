import {
  ContentLoader,
  DefaultButtonLoader,
  GridContainer,
  InputLoader,
  ImageLoader,
} from '@aid-module/ui';

export function Fallback() {
  return (
    <GridContainer gap="20">
      <ContentLoader width="100%" height="36.2px">
        <rect rx="3" ry="3" width="100%" height="36.2px" />
      </ContentLoader>
      <GridContainer gap="20">
        <InputLoader />
        <InputLoader />
        <ImageLoader />
        <DefaultButtonLoader />
      </GridContainer>
    </GridContainer>
  );
}
