import { useModalController } from './controller';
import { DefaultModal, ModalFallbackContainer } from '../../../Modal';
import { ModalViewProps } from './types';
import { AIDLoader } from '@aid-package/context';
import { styles } from './stypes';
import { ConditionBody } from './components';

export function Modal({ controller }: ModalViewProps) {
  const { isOpen, onRequestReject, props, onClickCancel, onClickConfirm } =
    useModalController(controller);
  return (
    <DefaultModal
      isOpen={isOpen}
      onRequestClose={onRequestReject}
      styles={styles}
      withoutClose
      withoutSpace
      fallback={
        isOpen ? (
          <ModalFallbackContainer>
            <AIDLoader />
          </ModalFallbackContainer>
        ) : undefined
      }
    >
      {isOpen && (
        <ConditionBody
          {...props}
          onClickCancel={onClickCancel}
          onClickConfirm={onClickConfirm}
        />
      )}
    </DefaultModal>
  );
}
