import gql from 'graphql-tag';

export const ApprovalIndividual = gql`
  query ApprovalIndividual(
    $role_ids: [String!]
    $account_ids: [String!]
    $form_configuration_ids: [String!]
    $form_configuration_reference_keys: [String!]
    $date_from: String
    $date_to: String
  ) {
    approvalIndividual(
      role_ids: $role_ids
      account_ids: $account_ids
      form_configuration_ids: $form_configuration_ids
      form_configuration_reference_keys: $form_configuration_reference_keys
      date_from: $date_from
      date_to: $date_to
    )
      @rest(
        type: "ResponseApprovalIndividual"
        path: "/stats/client/approval/individual?{args}"
      ) {
      data {
        individual_approval_rate
      }
      success
    }
  }
`;

export const ApprovalGeneral = gql`
  query ApprovalGeneral(
    $form_configuration_ids: [String!]
    $form_configuration_reference_keys: [String!]
    $date_from: String
    $date_to: String
  ) {
    approvalGeneral(
      form_configuration_ids: $form_configuration_ids
      form_configuration_reference_keys: $form_configuration_reference_keys
      date_from: $date_from
      date_to: $date_to
    )
      @rest(
        type: "ResponseApprovalGeneral"
        path: "/stats/client/approval/general?{args}"
      ) {
      data {
        assignment_rate
        group_approval_rate
        total_cases
      }
      success
    }
  }
`;
