import { Default, Scoring, Success, Warning, Error } from './components';
import { ConditionBodyViewProps } from './types';

export function ConditionBody({ type, ...props }: ConditionBodyViewProps) {
  switch (type) {
    case 'success':
      return <Success {...props} />;
    case 'warning':
      return <Warning {...props} />;
    case 'scoring':
      return <Scoring {...props} />;
    case 'error':
      return <Error {...props} />;
    default:
      return <Default {...props} />;
  }
}
