import gql from 'graphql-tag';
import { CLIENT_PROVIDER_FRAGMENT } from '../../../fragment/admin/clientProviders';

export const ClientProviders = gql`
  ${CLIENT_PROVIDER_FRAGMENT}
  query ClientProviders($page: Int, $q: String) {
    clientProviders(page: $page, per: 20, q: $q)
      @rest(type: "ResponseClientProviders", path: "/checkups/admin/clients_providers?{args}") {
      data @type(name: "ClientProvider") {
        ...ClientProviderFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const ClientProvider = gql`
  ${CLIENT_PROVIDER_FRAGMENT}
  query ClientProvider($id: String!) {
    clientProvider(id: $id)
      @rest(type: "ResponseClientProvider", path: "/checkups/admin/clients_providers/{args.id}") {
      data @type(name: "ClientProvider") {
        ...ClientProviderFragment
      }
      success
    }
  }
`;
