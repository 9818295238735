import gql from 'graphql-tag';
import { CA_SEARCH_FRAGMENT } from '../../../fragment/checkup';

export const Dictionaries = gql`
  ${CA_SEARCH_FRAGMENT}
  query Dictionaries(
    $dictionary_name: String!
    $q: String
    $order_col: String
    $order_dir: String
  ) {
    dictionaries(
      dictionary_name: $dictionary_name
      q: $q
      order_col: $order_col
      order_dir: $order_dir
    )
      @rest(
        type: "ResponseCaSearches"
        path: "/core/public/dictionaries?{args}"
      ) {
      data @type(name: "Dictionary") {
        name
        value
        id
      }
      success
    }
  }
`;
