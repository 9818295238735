import gql from 'graphql-tag';

export const ACCOUNT_FRAGMENT = gql`
  fragment AccountFragment on Account {
    avatar_id
    created_at
    email
    extra_attributes {
      about_me
      birthday
      city
      company
    }
    file_name
    file_size
    first_name
    full_name
    id
    last_name
    phone
    region_code
    role
    status
    updated_at
  }
`;
