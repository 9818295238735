import loadable from '@loadable/component';
import { LoadableFunctionalComponent } from '../../../utils';
import { IconLoader } from '../../../shared/Loaders';
import { CountriesIconViewProps } from './component/types';
export { CountriesIcons } from './component/components/countries/types';

export const CountriesIcon = loadable(() => import('./component'), {
  resolveComponent: (components) => components.CountriesIconComponent,
  fallback: <IconLoader height={12} width={18} />,
}) as LoadableFunctionalComponent<CountriesIconViewProps>;
