import {
  LoadableFunctionalComponent,
  TableWithHeaderLoader,
} from '@aid-module/ui';
import loadable from '@loadable/component';

export const RulesRegister = loadable(() => import('./component'), {
  resolveComponent: (components) => components.RulesRegisterComponent,
  fallback: <TableWithHeaderLoader />,
}) as LoadableFunctionalComponent<{}>;
