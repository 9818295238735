import { AsyncSelect } from '@aid-module/ui';
import { SubmissionsStatsPresets } from '@aid-package/api';
import { useAccountSelectController } from './controller';

export function TemplatesSelect() {
  const { reduceData, additional, handlerChange } =
    useAccountSelectController();

  return (
    <AsyncSelect
      label="Template"
      additional={additional}
      placeholder="Select Template..."
      onChange={handlerChange}
      query={SubmissionsStatsPresets}
      reduceData={reduceData}
      isClearable
    />
  );
}
