import {
  ContentLoader,
  GridContainer,
  ProfileCardLoader,
} from '@aid-module/ui';
import { FallbackRoles } from '../FallbackRoles';

export function Fallback() {
  return (
    <GridContainer gap="40">
      <ProfileCardLoader />
      <ContentLoader width="100%" height="31.2px">
        <rect rx="8px" ry="8px" width="100%" height="31.2px" />
      </ContentLoader>
      <FallbackRoles count={20} />
    </GridContainer>
  );
}
