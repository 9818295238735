import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useButtonModel } from '../model';
import { useWSModel } from '../model/WS';

export function useButtonController() {
  const refContainer = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const openModal = useRef(() => {
    setTimeout(() => {
      setOpen(true);
    }, 0);
  });

  useEffect(() => {
    const handleClick = (e: Event) => {
      if (
        refContainer.current &&
        !refContainer.current.contains(e.target as Node)
      ) {
        setOpen((prev) => {
          if (prev) {
            return !prev;
          }
          return prev;
        });
      }
    };
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const {
    notifications,
    loading,
    loadingCount,
    isEmpty,
    count,
    hasLoadMore,
    loadMore,
    refetch,
  } = useButtonModel();
  useWSModel(openModal.current, refetch);

  const toggleOpen = useCallback(() => setOpen((prev) => !prev), []);

  const context = useMemo(() => ({ close: () => setOpen(false) }), []);

  const [infinityRef] = useInfiniteScroll({
    loading,
    hasNextPage: hasLoadMore,
    onLoadMore: loadMore,
  });

  return {
    open,
    isEmpty,
    loading,
    notifications,
    hasLoadMore,
    context,
    refContainer,
    loadingCount,
    count,
    toggleOpen,
    loadMore,
    infinityRef,
  };
}
