import { SecondaryButton } from '../../../../../../../../../../../../../../../../../../Button';
import { ModalButtonViewProps } from '../../../../../../../../../../../../../../../../../../Modal/Modal/types';
import { useButtonController } from './controller';

export function Button({ onOpen }: ModalButtonViewProps) {
  const { disabled } = useButtonController();
  return (
    <SecondaryButton
      text="Save as a template"
      icon="save"
      disabled={disabled}
      onClick={onOpen}
    />
  );
}
