import { DocumentNode, useMutation } from '@aid-package/api';
import { useCallback } from 'react';

export function useEnabledModel(mutation: DocumentNode) {
  const [mutate, { loading }] = useMutation(mutation);

  const toggleEnabled = useCallback(
    (id: string, enabled: boolean) => {
      if (!loading) {
        mutate({
          variables: {
            id,
            body: {
              enabled,
            },
          },
        });
      }
    },
    [loading]
  );

  return { toggleEnabled, loading };
}
