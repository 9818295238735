import { TYPES } from '@aid-package/api';
import { createContext, useContext } from 'react';

export const CheckupContext = createContext<
  TYPES.ServiceCheckupQuery['serviceCheckup']['data'] & { alert_id?: string }
>({} as any);

export function useCheckupContext() {
  const context = useContext(CheckupContext);

  return context;
}
