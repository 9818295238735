import { PortalSideWidgetProvider } from './Provider';
export type {
  PortalButtonWidgetComponentViewProps,
  PortalBodyWidgetComponentViewProps,
} from './PortalWidget/types';
import { PortalWidgetHeader } from './Header';
import { PortalWidget } from './PortalWidget';
export * from './Hook';
export const PortalSideWidget = {
  Header: PortalWidgetHeader,
  Provider: PortalSideWidgetProvider,
  View: PortalWidget,
};
