import { gql } from '@apollo/client';

export const TransactionStatsEvents = gql`
  query TransactionStatsEvents(
    $profile_id: String
    $sort_field: String
    $sort_order: String
    $checkup_result_state: String
    $entity_id: String
    $instrument_id: String
    $from_date: String
    $to_date: String
    $amount_to: String
    $amount_from: String
    $status: String
    $currency: String
    $q: String
    $rejection_reason_name: String
    $bank_client_id: String
  ) {
    transactionStatsEvents(
      profile_id: $profile_id
      sort_field: $sort_field
      sort_order: $sort_order
      checkup_result_state: $checkup_result_state
      entity_id: $entity_id
      instrument_id: $instrument_id
      from_date: $from_date
      to_date: $to_date
      amount_to: $amount_to
      currency: $currency
      amount_from: $amount_from
      status: $status
      q: $q
      rejection_reason_name: $rejection_reason_name
      bank_client_id: $bank_client_id
    )
      @rest(
        type: "ResponseTransactionStatsEvents"
        path: "/tm/stats/events?{args}"
      ) {
      data @type(name: "TransactionStatsEvents") {
        external {
          amount_sum
          count
          countries
          status_reject_count
        }
        internal {
          amount_sum
          count
          countries
          status_reject_count
        }
      }
      success
    }
  }
`;

export const TransactionStatsMonthsEvents = gql`
  query TransactionStatsMonthsEvents(
    $profile_id: String
    $sort_field: String
    $sort_order: String
    $checkup_result_state: String
    $entity_id: String
    $instrument_id: String
    $date_from: String
    $date_to: String
    $currency: String
    $amount_to: String
    $amount_from: String
    $status: String
    $q: String
    $rejection_reason_name: String
    $bank_client_id: String
  ) {
    transactionStatsMonthsEvents(
      profile_id: $profile_id
      sort_field: $sort_field
      sort_order: $sort_order
      checkup_result_state: $checkup_result_state
      entity_id: $entity_id
      instrument_id: $instrument_id
      date_from: $date_from
      date_to: $date_to
      currency: $currency
      amount_to: $amount_to
      amount_from: $amount_from
      status: $status
      q: $q
      rejection_reason_name: $rejection_reason_name
      bank_client_id: $bank_client_id
    )
      @rest(
        type: "ResponseTransactionStatsMonthsEvents"
        path: "/tm/stats/months_events?{args}"
      ) {
      data
      success
    }
  }
`;
