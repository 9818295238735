import gql from 'graphql-tag';

export const AlertsStats = gql`
  query AlertsStats(
    $ruleset_id: String
    $rule_id: String
    $event_id: String
    $sender_entity_id: String
    $receiver_entity_id: String
    $rule_code: String
    $from_date: String
    $to_date: String
    $deadline_at_from: String
    $deadline_at_to: String
    $profile_id: String
    $disposition: String
    $assigned_to_id: String
    $state: String
    $is_for_test: Boolean
    $is_from_scheduled_rule: Boolean
    $q: String
  ) {
    alertsStats(
      ruleset_id: $ruleset_id
      rule_id: $rule_id
      event_id: $event_id
      sender_entity_id: $sender_entity_id
      receiver_entity_id: $receiver_entity_id
      rule_code: $rule_code
      from_date: $from_date
      to_date: $to_date
      deadline_at_from: $deadline_at_from
      deadline_at_to: $deadline_at_to
      profile_id: $profile_id
      disposition: $disposition
      assigned_to_id: $assigned_to_id
      state: $state
      is_for_test: $is_for_test
      is_from_scheduled_rule: $is_from_scheduled_rule
      q: $q
    ) @rest(type: "ResponseAlertsStats", path: "/tm/alerts/stats?{args}") {
      data {
        all_alerts
        assigned_to_me
        assigned_to_me_closed
        assigned_to_me_opened
        closed_alerts
        open_alerts
      }
      success
    }
  }
`;

export const QueuedAlertsStats = gql`
  query QueuedAlertsStats(
    $ruleset_id: String
    $rule_id: String
    $event_id: String
    $sender_entity_id: String
    $receiver_entity_id: String
    $rule_code: String
    $from_date: String
    $to_date: String
    $deadline_at_from: String
    $deadline_at_to: String
    $profile_id: String
    $disposition: String
    $assigned_to_id: String
    $state: String
    $is_for_test: Boolean
    $q: String
  ) {
    queuedAlertsStats(
      ruleset_id: $ruleset_id
      rule_id: $rule_id
      event_id: $event_id
      sender_entity_id: $sender_entity_id
      receiver_entity_id: $receiver_entity_id
      rule_code: $rule_code
      from_date: $from_date
      to_date: $to_date
      deadline_at_from: $deadline_at_from
      deadline_at_to: $deadline_at_to
      profile_id: $profile_id
      disposition: $disposition
      assigned_to_id: $assigned_to_id
      state: $state
      is_for_test: $is_for_test
      q: $q
    )
      @rest(
        type: "ResponseQueuedAlertsStats"
        path: "/tm/alerts/stats_queued?{args}"
      ) {
      data {
        all_alerts
        assigned_to_me
        assigned_to_me_closed
        assigned_to_me_opened
        closed_alerts
        open_alerts
      }
      success
    }
  }
`;
