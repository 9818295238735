import { ModalControllerPromise, ModalProps, ResolveType } from './types';

export class ModalController {
  uuid: string;

  open = false;

  props: ModalProps;

  onStatusChange?: (next: boolean) => void;

  onUpdateProps?: (next: ModalProps) => void;

  promise?: ModalControllerPromise;

  constructor(uuid: string, props: ModalProps) {
    this.uuid = uuid;
    this.props = props;
  }

  updateProps(next: ModalProps) {
    if (JSON.stringify(this.props) !== JSON.stringify(next)) {
      this.props = next;
      this.onUpdateProps?.(this.props);
    }
  }

  private updateOpen(next: boolean) {
    this.open = next;
    this.onStatusChange?.(this.open);
  }

  private createPromise() {
    return new Promise<ResolveType>((resolve, reject) => {
      this.promise = { resolve, reject };
    });
  }

  async show() {
    if (!this.promise) {
      this.updateOpen(true);
      return await this.createPromise();
    }
    throw new Error('Promise still exists');
  }

  private callPromise(callback: (promise: ModalControllerPromise) => void) {
    if (this.promise) {
      this.updateOpen(false);
      callback(this.promise);
      this.promise = undefined;
    }
  }

  confirm(type: ResolveType) {
    this.callPromise((promise) => {
      promise.resolve(type);
    });
  }

  close() {
    this.callPromise((promise) => {
      promise.reject();
    });
  }
}
