import { THEME_COLORS } from '../../../shared/colors/types';
import { TM_CASE_STATUSES } from '../constants';
import { TmCaseStatusViewProps } from '../types';

export function getColorByStatus(
  status?: TmCaseStatusViewProps['status']
): keyof THEME_COLORS {
  switch (status) {
    case TM_CASE_STATUSES.OPEN:
    case TM_CASE_STATUSES.PRE_OPEN:
      return 'error-deep-3';
    case TM_CASE_STATUSES.PRE_CLOSED:
    case TM_CASE_STATUSES.CLOSED:
      return 'success-deep-4';
    default:
      return 'warning-deep-4';
  }
}
