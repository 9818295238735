import { useMemo } from 'react';
import { useDictionaryMethodsContextModel } from '../model';
import { SelectOption } from '@aid-module/ui';

export function useDictionaryMethodsContextController() {
  const { items, loading, error } = useDictionaryMethodsContextModel();

  const elements = useMemo(() => {
    return (items || [])?.reduce<Record<string, SelectOption>>(
      (acc, item) => ({
        ...acc,
        [item.value]: {
          label: item.name || '-',
          value: item.value || '-',
        },
      }),
      {}
    );
  }, [items]);

  return { elements, loading, error };
}
