import dayjs from 'dayjs';
import { useMemo } from 'react';

export function useErrorTemplateController() {
  const copyrightText = useMemo(
    () => `© ${dayjs().year()} A.ID Europe UAB. All rights reserved`,
    []
  );

  return {
    copyrightText,
  };
}
