import { Select } from '@aid-module/ui';
import { useBankTariffSelectController } from './controller';
import { BankTariffSelectViewProps } from './types';

export function BankTariffSelect({
  onChange,
  value,
}: BankTariffSelectViewProps) {
  const { tariffOptions } = useBankTariffSelectController();

  return (
    <Select
      isMulti
      label="Pricing plans"
      value={value}
      options={tariffOptions}
      onChange={onChange}
    />
  );
}
