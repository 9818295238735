import gql from 'graphql-tag';

export const CdsEntityTemplates = gql`
  query CdsEntityTemplates($page: Int, $per: Int) {
    cdsEntityTemplates(page: $page, per: $per)
      @rest(
        type: "ResponseCdsEntityTemplates"
        path: "/cds/entity_templates?{args}"
      ) {
      data @type(name: "CdsEntityTemplate") {
        created_at
        entity_type
        form_mapping_id
        id
        kind
        updated_at
      }
      meta {
        count
        page
        items
        pages
      }
      success
    }
  }
`;

export const CdsEntityTemplate = gql`
  query CdsEntityTemplate($id: String!) {
    cdsEntityTemplate(id: $id)
      @rest(
        type: "ResponseCdsEntityTemplate"
        path: "/cds/entity_templates/{args.id}"
      ) {
      data @type(name: "CdsEntityTemplate") {
        created_at
        entity_type
        form_mapping_id
        id
        kind
        updated_at
      }
      success
    }
  }
`;
