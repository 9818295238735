import { stopPropagation } from '@aid-module/ui';
import { Body, Header } from './components';
import { Container } from './styles';

export function Widget() {
  return (
    <Container onClick={stopPropagation} gridTemplateRows="62px 1fr">
      <Header />
      <Body />
    </Container>
  );
}
