import gql from 'graphql-tag';

export const RESOLUTION_FRAGMENT = gql`
  fragment ResolutionFragment on Resolution {
    id
    created_at
    code
    name
    updated_at
  }
`;
