import { Modal } from './components';
import { useDataRetentionController } from './controller';
import { Container } from './styles';

export function DataRetention() {
  const { showModal } = useDataRetentionController();

  if (showModal) {
    return (
      <Container>
        <Modal />
      </Container>
    );
  }

  return null;
}
