import gql from 'graphql-tag';

export const PricingPlanClients = gql`
  query PricingPlanClients(
    $sort_field: String
    $sort_order: String
    $q: String
    $subscription_status: String
    $plan_id: String
    $page: Int
    $per: Int
  ) {
    pricingPlanClients(
      sort_field: $sort_field
      sort_order: $sort_order
      q: $q
      subscription_status: $subscription_status
      plan_id: $plan_id
      page: $page
      per: $per
    )
      @rest(
        type: "ResponsePricingPlanClients"
        path: "/billing/admin/clients?{args}"
      ) {
      data @type(name: "PricingPlanClient") {
        id
        accounts_count
        created_at
        customers_count
        description
        logo_id
        name
        swift_code
        updated_at
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;
