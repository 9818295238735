import { PERMISSIONS } from '@aid-package/context';
import { Routes, Route } from 'react-router-dom';
import {
  CheckupKindsRegister,
  ProvidersRegister,
  ClientProvidersRegister,
  ClientCheckupKindsRegister,
  ProviderUpdate,
  CheckupKindUpdate,
  ClientProviderUpdate,
  ClientCheckupKindUpdate,
} from './checkupService';
import { CheckupsRegister, CheckupUpdate } from './checkups';
import { ClientCreate, ClientsRegister, ClientUpdate } from './clients';
import {
  FieldTemplateCreate,
  FieldTemplatesRegister,
  FieldTemplateUpdate,
} from './fieldTemplates';
import { FormTemplatesRegister } from './formTemplates';
import { UsersRegister, UserUpdate } from './users';
import { Stats } from './stats';
import { RegionsRegister, RegionUpdate } from './regions';
import { ProtectedRoute, ErrorPages } from '@aid-module/ui';
import { AuditRegister } from './audit';

export function AdminRouter() {
  return (
    <Routes>
      <Route path="/" element={<ProtectedRoute check={PERMISSIONS.ADMIN} />}>
        <Route index element={<Stats />} />
        <Route path="/audit" element={<AuditRegister />} />
        <Route path="users">
          <Route index element={<UsersRegister />} />
          <Route path=":user_id" element={<UserUpdate />} />
        </Route>
        <Route path="clients">
          <Route index element={<ClientsRegister />} />
          <Route path="form" element={<ClientCreate />} />
          <Route path="form/:id" element={<ClientUpdate />} />
          <Route
            path=":id/form_templates"
            element={<FormTemplatesRegister />}
          />
        </Route>
        <Route path="field_templates">
          <Route index element={<FieldTemplatesRegister />} />
          <Route path="form" element={<FieldTemplateCreate />} />
          <Route
            path="form/:field_template_id"
            element={<FieldTemplateUpdate />}
          />
        </Route>
        <Route path="checkups">
          <Route index element={<CheckupsRegister />} />
          <Route path=":checkup_id" element={<CheckupUpdate />} />
        </Route>
        <Route path="checkup_service">
          <Route path="providers" element={<ProvidersRegister />} />
          <Route path="providers/:provider_id" element={<ProviderUpdate />} />
          <Route path="checkup_kinds" element={<CheckupKindsRegister />} />
          <Route
            path="checkup_kinds/:checkup_kind_id"
            element={<CheckupKindUpdate />}
          />
          <Route
            path="clients_providers"
            element={<ClientProvidersRegister />}
          />
          <Route
            path="clients_providers/:client_provider_id"
            element={<ClientProviderUpdate />}
          />
          <Route
            path="checkup_kinds_clients"
            element={<ClientCheckupKindsRegister />}
          />
          <Route
            path="checkup_kinds_clients/:client_checkup_kind_id"
            element={<ClientCheckupKindUpdate />}
          />
        </Route>
        <Route path="regions">
          <Route index element={<RegionsRegister />} />
          <Route path=":region_id" element={<RegionUpdate />} />
        </Route>
        <Route path="*" element={<ErrorPages type="local" code={404} />} />
      </Route>
    </Routes>
  );
}
