import gql from 'graphql-tag';

export const FORM_FRAGMENT = gql`
  fragment FormFragment on Form {
    aid_scoring_enabled
    abandoned_in
    audit_repeat_interval
    auto_create_case
    purpose_kind
    auto_start_audit
    callback_uri
    client_id
    created_at
    description
    enabled
    form_screens_count
    form_submissions_count
    global
    id
    is_actual_revision
    is_additional
    is_favorite
    is_ongoing
    linked_form_configuration_id
    linked_form_submission_id
    name
    notify_complete_to_emails
    only_authorized
    options
    profile_kind
    redirect_uri
    reference_key
    revision_number
    tags
    technical_name
    ubt_enabled
    updated_at
  }
`;
