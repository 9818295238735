import gql from 'graphql-tag';
import { AVAILABLE_FEATURE_FRAGMENT } from '../../../fragment/caseConfig/AvailableFeature';

export const AvailableFeatures = gql`
  ${AVAILABLE_FEATURE_FRAGMENT}
  query AvailableFeatures($q: String) {
    availableFeatures(q: $q)
      @rest(type: "AvailableFeature", path: "/core/client/case_configs/available_features") {
        ...AvailableFeatureFragment
    }
  }
`;
