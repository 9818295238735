import loadable from '@loadable/component';
import {
  TableWithHeaderLoader,
  LoadableFunctionalComponent,
} from '@aid-module/ui';

export const StopWordsRegister = loadable(() => import('./component'), {
  resolveComponent: (components) => components.StopWordsComponent,
  fallback: <TableWithHeaderLoader />,
}) as LoadableFunctionalComponent<{}>;
