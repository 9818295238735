import { gql } from '@apollo/client';

export const CreateTransactionScoringScoreRule = gql`
  mutation CreateTransactionScoringScoreRule(
    $body: BodyCreateTransactionScoringScoreRule!
  ) {
    createTransactionScoringScoreRule(body: $body)
      @rest(
        type: "CreateTransactionScoringRule"
        path: "/tm/scoring/score_rules"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "TransactionScoringScoreRule") {
        id
        client_id
        created_at
        params {
          less_than
          greater_than
          equal_to
          condition
          color
        }
        rfi_suggested
        scoring_matrix_id
        supporting_docs_required
        transaction_stopped
        updated_at
      }
      success
    }
  }
`;

export const UpdateTransactionScoringScoreRule = gql`
  mutation UpdateTransactionScoringScoreRule(
    $id: String!
    $body: BodyUpdateTransactionScoringScoreRule!
  ) {
    updateTransactionScoringScoreRule(id: $id, body: $body)
      @rest(
        type: "UpdateTransactionScoringScoreRule"
        path: "/tm/scoring/score_rules/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "TransactionScoringScoreRule") {
        id
        client_id
        created_at
        params {
          less_than
          greater_than
          equal_to
          condition
          color
        }
        rfi_suggested
        scoring_matrix_id
        supporting_docs_required
        transaction_stopped
        updated_at
      }
      success
    }
  }
`;

export const DeleteTransactionScoringScoreRule = gql`
  mutation DeleteTransactionScoringScoreRule($id: String!) {
    deleteTransactionScoringScoreRule(id: $id)
      @rest(
        type: "DeleteTransactionScoringScoreRule"
        path: "/tm/scoring/score_rules/{args.id}"
        method: "DELETE"
      ) {
      success
    }
  }
`;
