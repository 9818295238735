import styled from '@emotion/styled';
import { FlexContainer } from '../../../containers';

export const Container = styled(FlexContainer)`
  background: rgba(255, 255, 255, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
`;
