import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useNotificationsLazyQuery } from '@aid-package/api';

export function useButtonModel() {
  const loadingRef = useRef(false);
  const [, { data, loading, fetchMore }] = useNotificationsLazyQuery({
    variables: { is_readed_modal: false, page: 1 },
  });

  const isEmpty = useMemo(
    () => !loading && !data?.notifications?.meta?.count,
    [loading, data?.notifications?.meta?.count]
  );

  const hasLoadMore = useMemo(() => {
    const meta = data?.notifications?.meta;
    if (meta) {
      return meta.page !== meta.pages;
    }
    return true;
  }, [data?.notifications?.meta]);

  const refetch = useRef(() => {
    fetchMore({
      variables: { page: 1 },
    });
  });

  const loadMore = useCallback(async () => {
    const meta = data?.notifications?.meta;
    if (!loadingRef.current && meta ? meta.page !== meta.pages : true) {
      loadingRef.current = true;
      await fetchMore({ variables: { page: (meta?.page || 0) + 1 } });
      loadingRef.current = false;
    }
  }, [data?.notifications?.meta, fetchMore]);

  const loadingCount = useMemo(() => {
    if (data?.notifications) {
      let count = 20;
      const meta = data?.notifications?.meta;
      if (meta) {
        const unionCount = meta.count - meta.page * meta.items;
        if (unionCount < 20) {
          count = unionCount;
        }
      }
      return hasLoadMore ? count : 0;
    }
    return 20;
  }, [data?.notifications, hasLoadMore]);

  useEffect(() => {
    refetch.current();
  }, []);

  return {
    notifications: data?.notifications?.data,
    count: data?.notifications.meta?.count,
    isEmpty,
    loading,
    hasLoadMore,
    loadMore,
    refetch: refetch.current,
    loadingCount,
  };
}
