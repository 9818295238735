import gql from 'graphql-tag';
import { CASE_SECTION_ITEM_FRAGMENT } from '../caseSection';

export const SUPPORTING_DOCUMENTS_FRAGMENT = gql`
  ${CASE_SECTION_ITEM_FRAGMENT}
  fragment SupportingDocumentsFragment on SupportingDocuments {
    case_section @type(name: "CaseSection") {
      ...CaseSectionFragment
    }
  }
`;
