import { PrimaryButton, SecondaryButton } from '@aid-module/ui';
import { useActionsController } from './contoller';
import { ActionsPropsView } from './types';
import { ActionContainer } from './styles';

export function Actions({ name, parsedSchema }: ActionsPropsView) {
  const { onClickSubmit, disabled, onClickBack, loading } =
    useActionsController(name, parsedSchema);
  return (
    <ActionContainer gridTemplateColumns="repeat(2, 1fr)" gap="20">
      <SecondaryButton text="Cancel" onClick={onClickBack} disabled={loading} />
      <PrimaryButton
        text="Create"
        onClick={onClickSubmit}
        disabled={disabled}
      />
    </ActionContainer>
  );
}
