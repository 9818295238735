import gql from 'graphql-tag';

export const CaseScoringModel = gql`
  query CaseScoringModel($case_config_id: String!) {
    caseScoringModel(case_config_id: $case_config_id)
      @rest(
        type: "ResponseCaseScoringModel"
        path: "/scoring/case_scoring_models/by_case_config/{args.case_config_id}"
      ) {
      data @type(name: "CaseScoringModel") {
        case_config_id
        case_rules @type(name: "CaseScoringRule") {
          coefficient
          enabled
          id
          subject_class
          subject_kind
        }
        created_at
        enabled
        id
        updated_at
      }
      success
    }
  }
`;

export const FormScoringModel = gql`
  query FormScoringModel($form_configuration_id: String!) {
    formScoringModel(form_configuration_id: $form_configuration_id)
      @rest(
        type: "ResponseFormScoringModel"
        path: "/scoring/models/by_form_configuration/{args.form_configuration_id}"
      ) {
      data @type(name: "FormScoringModel") {
        created_at
        form_configuration_id
        id
        name
        se_coefficient
        updated_at
        rules @type(name: "FieldScoring") {
          coefficient
          expression
          created_at
          form_field_id
          id
          kind
          name
          rule_select_values
          state
          updated_at
          value
        }
      }
      success
    }
  }
`;
