import gql from 'graphql-tag';
import {
  TRANSACTION_EVENT_FRAGMENT,
  TRANSACTION_CHECKUP_RESULT_FRAGMENT,
} from '../../../fragment/tm';

export const TransactionEvents = gql`
  ${TRANSACTION_EVENT_FRAGMENT}
  query TransactionEvents(
    $profile_id: String
    $sort_field: String
    $sort_order: String
    $checkup_result_state: String
    $entity_id: String
    $instrument_id: String
    $from_date: String
    $to_date: String
    $amount_to: String
    $amount_from: String
    $status: String
    $page: Int
    $per: Int
    $q: String
    $rejection_reason_name: String
    $bank_client_id: String
    $event_ids: [String!]
  ) {
    transactionEvents(
      profile_id: $profile_id
      sort_field: $sort_field
      sort_order: $sort_order
      checkup_result_state: $checkup_result_state
      entity_id: $entity_id
      instrument_id: $instrument_id
      from_date: $from_date
      to_date: $to_date
      amount_to: $amount_to
      amount_from: $amount_from
      status: $status
      page: $page
      per: $per
      q: $q
      rejection_reason_name: $rejection_reason_name
      bank_client_id: $bank_client_id
      event_ids: $event_ids
    ) @rest(type: "ResponseTransactionEvents", path: "/tm/events?{args}") {
      data @type(name: "TransactionEvent") {
        ...TransactionEventFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const TransactionPagginationEvents = gql`
  ${TRANSACTION_EVENT_FRAGMENT}
  query TransactionPagginationEvents(
    $profile_id: String
    $sort_field: String
    $sort_order: String
    $checkup_result_state: String
    $entity_id: String
    $instrument_id: String
    $from_date: String
    $to_date: String
    $amount_to: String
    $amount_from: String
    $status: String
    $page: Int
    $per: Int = 20
    $q: String
    $rejection_reason_name: String
  ) {
    transactionPagginationEvents(
      profile_id: $profile_id
      sort_field: $sort_field
      sort_order: $sort_order
      checkup_result_state: $checkup_result_state
      entity_id: $entity_id
      instrument_id: $instrument_id
      from_date: $from_date
      to_date: $to_date
      amount_to: $amount_to
      amount_from: $amount_from
      status: $status
      page: $page
      per: $per
      q: $q
      rejection_reason_name: $rejection_reason_name
    ) @rest(type: "ResponseTransactionEvents", path: "/tm/events?{args}") {
      data @type(name: "TransactionEvent") {
        ...TransactionEventFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const TransactionEvent = gql`
  ${TRANSACTION_EVENT_FRAGMENT}
  ${TRANSACTION_CHECKUP_RESULT_FRAGMENT}
  query TransactionEvent($id: String!) {
    transactionEvent(id: $id)
      @rest(type: "ResponseTransactionEvent", path: "/tm/events/{args.id}") {
      data @type(name: "TransactionEvent") {
        ...TransactionEventFragment
        checkup_result @type(name: "TransactionCheckupResult") {
          ...TransactionCheckupResultFragment
        }
        rulesets @type(name: "RuleSet") {
          can_sync
          client_id
          created_at
          deleted
          enabled
          id
          name
          is_additional
          rules_expression
        }
      }
      success
    }
  }
`;
