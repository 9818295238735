import {
  ContentLoader,
  DefaultButtonLoader,
  GridContainer,
} from '@aid-module/ui';
import { FormLoader } from '../../../components';

export function Fallback() {
  return (
    <FormLoader>
      <ContentLoader width="100%" height="250px">
        <rect rx={8} ry={8} width="100%" height="250px" />
      </ContentLoader>
      <GridContainer gap="20" gridTemplateColumns="repeat(2, 1fr)">
        <DefaultButtonLoader />
        <DefaultButtonLoader />
      </GridContainer>
    </FormLoader>
  );
}
