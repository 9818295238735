import { GridContainer, InputLoader, MediaLoader } from '@aid-module/ui';
import { ActionFallback } from '../../../../components/ActionFallback';

export function Fallback() {
  return (
    <GridContainer gap="20" gridTemplateColumns="1fr">
      <MediaLoader />
      <InputLoader />
      <InputLoader />
      <InputLoader />
      <InputLoader />
      <InputLoader />
      <InputLoader />
      <ActionFallback count={2} />
    </GridContainer>
  );
}
