import { FlexContainer, GridContainer } from '../../containers';
import { useMemo } from 'react';
import { ImageLoader } from '../../shared';
import { Card } from '../Card';
import { ClientAvatar } from '../ClientAvatar';
import { Contacts, DefaultName } from './components';
import { ExtendAccountType, ProfileCardViewProps } from './types';
import { PERMISSIONS, Protected } from '@aid-package/context';
export * from './types';

export function ProfileCard<T extends ExtendAccountType>({
  account,
  className,
  Name,
}: ProfileCardViewProps<T>) {
  const name = useMemo(() => {
    if (Name) {
      return <Name account={account} />;
    }
    return <DefaultName account={account} />;
  }, [account, Name]);

  return (
    <Card
      padding="S"
      background="main-deep-1"
      loaderBackground="main-deep-2"
      loaderForeground="main-deep-1"
      border="main-deep-2"
      className={className}
    >
      <GridContainer gap="18" gridTemplateColumns="120px 1fr max-content">
        <ClientAvatar
          client={account}
          size="big"
          fallback={<ImageLoader width={120} height={120} radius={8} />}
        />
        <FlexContainer alignItems="center">{name}</FlexContainer>
        <Protected
          check={[
            PERMISSIONS.MANAGE_PROFILE,
            PERMISSIONS.SEE_PROFILE_CONFIDENTIAL,
            PERMISSIONS.SEE_SHARED_CASES,
          ]}
        >
          <Contacts account={account} />
        </Protected>
      </GridContainer>
    </Card>
  );
}
