import gql from 'graphql-tag';

export const Balance = gql`
  query Balance {
    balance
      @rest(type: "ResponseBalance", path: "/core/client/billing/transactions/balance") {
      data @type(name: "Balance") {
        frozen_balance
        real_balance
      }
      success
    }
  }
`;
