import gql from 'graphql-tag';
import { COMMENT_FRAGMENT } from '../../fragment/comment';

export const Comments = gql`
  ${COMMENT_FRAGMENT}
  query Comments(
    $account_id: String
    $event_id: String
    $is_not_deleted: String
    $type: CommentType!
    $state: [CommentState!]
    $subject_id: String!
    $subject_type: String!
    $subject_entity_type: String
    $subject_entity_id: String
    $include_deleted: Boolean
    $all_and_my_hidden: Boolean
    $page: Int
  ) {
    comments(
      account_id: $account_id
      event_id: $event_id
      is_not_deleted: $is_not_deleted
      type: $type
      state: $state
      subject_id: $subject_id
      subject_type: $subject_type
      subject_entity_type: $subject_entity_type
      subject_entity_id: $subject_entity_id
      include_deleted: $include_deleted
      all_and_my_hidden: $all_and_my_hidden
      page: $page
      per: 10
    ) @rest(type: "ResponseComments", path: "{args.type}?{args}") {
      data @type(name: "Comment") {
        ...CommentFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;
