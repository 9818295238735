import { GridContainer, PrimaryButton, SecondaryButton } from '@aid-module/ui';
import { useActionsController } from './controller';

export function Actions() {
  const { disabled, onClickSubmit, onClose } = useActionsController();

  return (
    <GridContainer
      justifyContent="end"
      gridTemplateColumns="repeat(2, 210px)"
      gap="20"
    >
      <PrimaryButton
        text="Publish"
        disabled={disabled}
        onClick={onClickSubmit}
      />
      <SecondaryButton text="Cancel" onClick={onClose} />
    </GridContainer>
  );
}
