import { TabRegistries, useUpdateTabRegistryMutation, TYPES } from '@aid-package/api';
import { useCallback } from 'react';
import { showToast } from '../../../../../../../../../../../../../../../../../../../../../../../../../Toast';
import { prepareTabRegistry } from '../../../../../../../../../../../../../utils';

export function useActionsModel() {
  const [mutation, { loading }] = useUpdateTabRegistryMutation({
    refetchQueries: [TabRegistries],
  });

  const updateTabRegistry = useCallback(
    async (id: string, tab_registry: TYPES.CreateOrUpdateTabRegistry) => {
      const { data } = await mutation({
        variables: { id, body: { tab_registry: prepareTabRegistry(tab_registry) } },
      });

      if (data?.updateTabRegistry?.success) {
        showToast({
          type: 'success',
          message: 'Changes were successfully saved',
        });
        return true;
      }
      showToast({
        type: 'error',
        message: 'Something went wrong',
      });
      return;
    },
    [mutation]
  );

  return { loading, updateTabRegistry };
}
