import gql from 'graphql-tag';

export const EmailTemplates = gql`
  query EmailTemplates(
    $page: Int
    $q: String
    $sort_field: String
    $sort_order: String
    $created_at_to: String
    $created_at_from: String
    $account_ids: [String]!
  ) {
    emailTemplates(
      page: $page
      per: 20
      q: $q
      sort_field: $sort_field
      sort_order: $sort_order
      created_at_to: $created_at_to
      created_at_from: $created_at_from
      account_ids: $account_ids
    )
      @rest(
        type: "ResponseEmailTemplates"
        path: "/core/client/email_templates?{args}"
      ) {
      data @type(name: "EmailTemplate") {
        id
        name
        subject
        body
        created_at
        updated_at
        account @type(name: "Account") {
          id
          full_name
        }
        template_fields @type(name: "EmailTemplateField") {
          field
          type
          header
          required
        }
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const EmailTemplate = gql`
  query EmailTemplate($id: String!) {
    emailTemplate(id: $id)
      @rest(
        type: "ResponseEmailTemplate"
        path: "/core/client/email_templates/{args.id}"
      ) {
      data @type(name: "EmailTemplate") {
        id
        name
        subject
        body
        created_at
        updated_at
        account @type(name: "Account") {
          id
          full_name
        }
        template_fields @type(name: "EmailTemplateField") {
          field
          type
          header
          required
        }
      }
      success
    }
  }
`;
