import { gql } from '@apollo/client';

export const SarMexicanStates = gql`
  query SarMexicanStates($page: Int, $q: String) {
    sarMexicanStates(page: $page, per: 20, q: $q)
      @rest(
        type: "ResponseSarMexicanStates"
        path: "/sar/dictionaries/mexican_states?{args}"
      ) {
      data
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const SarUsStates = gql`
  query SarUsStates($page: Int, $q: String) {
    sarUsStates(page: $page, per: 20, q: $q)
      @rest(
        type: "ResponseSarUsStates"
        path: "/sar/dictionaries/us_states?{args}"
      ) {
      data
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const SarCanadianStates = gql`
  query SarCanadianStates($page: Int, $q: String) {
    sarCanadianStates(page: $page, per: 20, q: $q)
      @rest(
        type: "ResponseSarCanadianStates"
        path: "/sar/dictionaries/canadian_states?{args}"
      ) {
      data
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const SarNaicsCodes = gql`
  query SarNaicsCodes($page: Int, $q: String) {
    sarNaicsCode(page: $page, per: 20, q: $q)
      @rest(
        type: "ResponseSarNaicsCode"
        path: "/sar/dictionaries/naics_code?{args}"
      ) {
      data
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;
