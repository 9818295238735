import styled from '@emotion/styled';

export const StyledLoader = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const AidIconLoader = styled.svg`
  .animation {
    #first {
      animation: draw 0.8s ease-in forwards;
      animation-delay: 0.2s;
    }

    #second {
      animation: draw 0.8s ease-in-out forwards;
      animation-delay: 0.7s;
      animation-direction: alternate;
    }

    #third {
      animation: draw 1.2s ease-out forwards;
      animation-delay: 0.9s;
      animation-direction: alternate;
    }
  }

  @keyframes draw {
    from {
      stroke-dashoffset: 175;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
`;

const DefaultPath = styled.path`
  fill: none;
  stroke-width: 7.5;
  stroke-linecap: round;
  stroke-miterlimit: 10;
`;

export const StyledPath = styled(DefaultPath)`
  stroke: #dce3f5;
`;

export const StyledAnimatedPath = styled(DefaultPath)`
  stroke: #2950bb;
  stroke-dasharray: 175;
  stroke-dashoffset: 175;
`;
