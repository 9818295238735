import gql from 'graphql-tag';
import { TRANSLATION_FRAGMENT } from '../../../fragment/checkup/translation';

export const Translations = gql`
  ${TRANSLATION_FRAGMENT}
  query Translations(
    $page: Int
    $except_state: States
    $state: States
    $source_lang: String
    $target_lang: String
  ) {
    translations(
      page: $page
      per: 20
      except_state: $except_state
      state: $state
      source_lang: $source_lang
      target_lang: $target_lang
    )
      @rest(
        type: "ResponseTranslations"
        path: "/core/client/translation/translations?{args}"
      ) {
      data @type(name: "Translation") {
        ...TranslationFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;
