import { WidgetViewProps } from './types';
import { Container } from './styles';
import { SideWidgenProvider } from '../../../../../../../../SideWidget';
import { Droppable } from './components';

export function Widget({ columns }: WidgetViewProps) {
  return (
    <Container gridTemplateRows="max-content minmax(0, 1fr)">
      <SideWidgenProvider.Header label="Columns" />
      <Droppable columns={columns} />
    </Container>
  );
}
