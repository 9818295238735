import { Tag } from '@aid-module/ui';
import { useActionsController } from './controller';
import { Container, HintContainer, Icon } from './styles';
import { MarkAsReadViewProps } from './types';

export function MarkAsRead({ notification }: MarkAsReadViewProps) {
  const { onClick, loading } = useActionsController(notification);

  if (!notification.is_readed) {
    return (
      <Container
        justifyContent="center"
        alignItems="center"
        disabled={loading}
        onClick={onClick}
      >
        <HintContainer>
          <Tag
            count="Mark as read"
            color="main-deep-4"
            background="gray-deep-1"
          />
        </HintContainer>

        <Icon />
      </Container>
    );
  }

  return null;
}
