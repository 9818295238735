import { ContentLoader } from '../ContentLoader';
import { BolleanInputLoaderViewProps } from './types';

export function BooleanInputLoader({ height }: BolleanInputLoaderViewProps) {
  return (
    <ContentLoader width="100%" height={`${height || 30.2}px`}>
      <rect x="0" y="0" rx="3" ry="3" width="100%" height={`${height || 30.2}px`} />
    </ContentLoader>
  );
}
