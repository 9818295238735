import { LoaderThemeProvider } from '../../../../shared';
import { ToastComponent } from '../ToastComponent';
import { ToastComponentProps } from '../types';

export function Success(props: ToastComponentProps) {
  return (
    <LoaderThemeProvider
      background='success-deep-4'
      foreground='success-deep-3'
    >
      <ToastComponent icon="checkmark" color="success-deep-4" {...props} />
    </LoaderThemeProvider>
  );
}
