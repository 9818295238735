import { Tag } from '../Count';
import { Text } from '../Text';
import { useIdentityVerificationStatusController } from './controller';
import { IdentityVerificationStatusViewProps } from './types';
import {
  IDENTITY_VERIFICATIONS_DISPLAY_STATUSES,
  IDENTITY_VERIFICATIONS_STATUSES,
} from './constants';

function IdentityVerificationStatus({
  status,
}: IdentityVerificationStatusViewProps) {
  const { background, text } = useIdentityVerificationStatusController(status);

  if (text) {
    return <Tag count={text} padding="S" size={11} background={background} />;
  }

  return <Text text="-" size={12} color="main-deep-4" />;
}

export {
  IdentityVerificationStatus,
  IDENTITY_VERIFICATIONS_DISPLAY_STATUSES,
  IDENTITY_VERIFICATIONS_STATUSES,
};
