import isFunction from 'lodash.isfunction';
import { useCallback, useMemo, useState } from 'react';
import { useHeaderTableModel } from '../model';
import { HeaderViewProps } from '../types';
import { useNavigate } from 'react-router-dom';

export function useHeaderTableController(
  createRef: HeaderViewProps<any>['createRef']
) {
  const navigate = useNavigate();
  const { onChangeSearch, onChangeSelect, filtersConfig } =
    useHeaderTableModel();
  const [showFilters, setShowFilters] = useState(false);

  const toggleShowFilters = useCallback(
    () => setShowFilters((prev) => !prev),
    []
  );

  const to = useMemo(() => {
    if (createRef) {
      if (isFunction(createRef)) {
        return createRef();
      }
      return createRef;
    }
    return;
  }, [createRef]);

  const onClickCreate = useCallback(() => {
    if (to) {
      navigate(to);
    }
  }, [to]);

  return {
    onChangeSearch,
    onChangeSelect,
    onClickCreate,
    showFilters,
    toggleShowFilters,
    to,
    filtersConfig,
  };
}
