import gql from 'graphql-tag';

export const CHECKUP_CONFIG_FRAGMENT = gql`
  fragment CheckupConfigFragment on CheckupConfig {
    checkup_config_fields @type(name: "CheckupConfigField") {
      checkup_kind_field
      default_value
      form_field_id
      form_field_ref
      id
    }
    checkup_kind @type(name: "CheckupKind") {
      code
      description
      id
      name
      provider_id
      fields_contract {
        keys
      }
      fields_schema
      provider @type(name: "Provider") {
        code
        id
        name
      }
    }
    description
    id
    is_valid
    status
  }
`;
