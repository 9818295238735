import styled from '@emotion/styled';
import { GridContainer } from '../../../../../../../../../../../../../../containers';
import { Card as UICard } from '../../../../../../../../../../../../../Card';
import { getSpace } from '../../../../../../../../../../../../../../utils';

export const Card = styled(UICard)`
  padding: ${getSpace('14')};
  height: calc(100% - 28px);
  display: grid;
`;

export const ContentContainer = styled(GridContainer)<{ overflow?: string }>`
  height: 200px;
  ${({ overflow }) => overflow && `overflow: ${overflow};`}
`;
