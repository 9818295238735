import { ErrorActions, ErrorTemplate } from './components';
import { ErrorTemplateViewProps } from './components/ErrorTemplate/types';

export function Error403({ type }: Pick<ErrorTemplateViewProps, 'type'>) {
  return (
    <ErrorTemplate
      type={type}
      title="Forbidden"
      description="unfortunately you do not have access to this page"
      code={403}
    >
      <ErrorActions />
    </ErrorTemplate>
  );
}
