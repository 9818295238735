import {
  Select,
  SelectOption,
  SelectViewProps as UISelectVIewProps,
} from '@aid-module/ui';
import { SelectViewProps } from './types';
import { useCallback } from 'react';
import { FormRenderProps } from '../../../../types';
import { Controller } from 'react-hook-form';

function RenderSelectField({
  field,
  value,
  onChange,
  error,
}: Pick<
  UISelectVIewProps<boolean, SelectOption>,
  'value' | 'onChange' | 'error'
> &
  SelectViewProps) {
  return (
    <Select
      isSearchable
      options={field.options}
      label={`${field.title} ${field.required ? ' *' : ''}`}
      placeholder={`Select ${field.title}...`}
      value={value}
      isMulti={field.code === 'array_options'}
      error={error}
      onChange={onChange}
    />
  );
}

export function SelectOptions({ field }: SelectViewProps) {
  const renderSelect = useCallback(
    ({
      field: { value, onChange },
      fieldState: { error },
    }: FormRenderProps<string>) => {
      return (
        <RenderSelectField
          field={field}
          error={error}
          value={value}
          onChange={onChange}
        />
      );
    },
    [field]
  );
  return <Controller name={field.code} render={renderSelect} />;
}
