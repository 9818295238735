import gql from 'graphql-tag';

export const CreateGlobalSearchEntrie = gql`
  mutation CreateGlobalSearchEntrie($body: BodyCreateGlobalSearchEntrie!) {
    createGlobalSearchEntrie(body: $body)
      @rest(
        type: "CreateGlobalSearchEntrie"
        path: "/core/client/global_search_entries"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "GlobalSearchEntrie") {
        id
        client_id
        created_at
        search_query
        updated_at
        created_by @type(name: "Account") {
          id
          full_name
        }
        entry_count @type(name: "GlobalSearchEntrieCount") {
          tm
          case
          identityVerification
          checkup
          submission
          profile
        }
        filter @type(name: "GlobalSearchEntrieFilter") {
          type
          field
          header
          required
        }
      }
      success
    }
  }
`;
