import { GridContainer } from '../../../../../../../containers';
import { useCallback } from 'react';
import { FilterValue } from '../FilterValue';
import { useFilterValuesController } from './controller';
import { FilterValuesProps } from './controller/types';

export function FilterValues() {
  const { selectedFilters } = useFilterValuesController();

  const renderValue = useCallback(
    (item: FilterValuesProps) => (
      <FilterValue
        key={item.key}
        label={item.label}
        value={item.value}
        itemKey={item.key}
      />
    ),
    []
  );

  return (
    <GridContainer gap="4">{selectedFilters.map(renderValue)}</GridContainer>
  );
}
