import gql from 'graphql-tag';

export const FIELD_TEMPLATE_FRAGMENT = gql`
  fragment FieldTemplateFragment on FieldTemplate {
    code
    common
    created_at
    id
    kind
    name
    updated_at
    field_select_values
  }
`;
