import {
  Text,
  Tag,
  FlexContainer,
  GridContainer,
  getColor,
  getSpace,
} from '@aid-module/ui';
import styled from '@emotion/styled';
import { EmotionBorderContainerViewProps } from './types';

export const Container = styled.a`
  text-decoration: none;
`;

export const Label = styled(Text)`
  overflow-wrap: break-word;
`;

export const CapitalizeTag = styled(Tag)`
  text-transform: capitalize;
`;

export const TagsContainer = styled(FlexContainer)`
  flex-wrap: wrap;
  gap: 8px;
`;

export const BorderContainer = styled(
  GridContainer
)<EmotionBorderContainerViewProps>`
  border-bottom: 1px solid ${getColor('main-deep-2')};
  ${({ paddingBottom }) =>
    paddingBottom ? `padding-bottom: ${getSpace(paddingBottom)};` : ''}
`;
