import gql from 'graphql-tag';

export const ROLE_FRAGMENT = gql`
  fragment RoleFragment on Role {
    id
    name
    permissions_count
    accounts_count
    client_id
    created_at
    updated_at
  }
`;
