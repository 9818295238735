import gql from 'graphql-tag';
import { CUSTOMER_VERIFICATION_FRAGMENT } from '../../../fragment/verification';

export const CustomerVerificationCreate = gql`
  ${CUSTOMER_VERIFICATION_FRAGMENT}
  mutation CustomerVerificationCreate($body: BodyCreateCustomerVerification!) {
    createCustomerVerification(body: $body)
      @rest(
        type: "CustomerVerificationCreate"
        path: "/core/client/customer_verifications"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "CustomerVerification") {
        ...CustomerVerificationFragment
      }
      success
    }
  }
`;
