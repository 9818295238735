import { Icon } from '../../../../components/Icon';
import { CloseButtonProps } from 'react-toastify';
import { ButtonContainer } from './styles';

export function CloseButton({ closeToast }: CloseButtonProps) {
  return (
    <ButtonContainer>
      <Icon onClick={closeToast} glyph="cross" color="main-deep-5" size={16} />
    </ButtonContainer>
  );
}
