import { Cache } from '../Cache';
import { VariablesStateParams } from '../controller/types';
import { Observer } from './Observer';
import { SubscriberFunction } from './types';

export class Variables extends Observer {
  private _variables: VariablesStateParams;
  private cache?: Cache;
  private defaultVariables?: VariablesStateParams;

  get variables() {
    return this._variables;
  }

  set variables(next: VariablesStateParams) {
    this._variables = next;
    this.cache?.save(this._variables);
    this.onUpdateObserverValue('variables', this._variables);
  }

  constructor(defaultVariables?: VariablesStateParams, cacheKey?: string) {
    super();
    if (cacheKey) {
      this.cache = Cache.init(cacheKey);
    }
    this.defaultVariables = defaultVariables;
    this._variables = {
      ...(defaultVariables || {}),
      ...(this.cache?.variables || {}),
      staticFilters: (defaultVariables?.staticFilters ||
        this.cache?.variables?.staticFilters) && {
        ...(defaultVariables?.staticFilters || {}),
        ...(this.cache?.variables?.staticFilters || {}),
      },
      filters: (defaultVariables?.filters ||
        this.cache?.variables?.filters) && {
        ...(defaultVariables?.filters || {}),
        ...(this.cache?.variables?.filters || {}),
      },
      filterTemplate:
        this.cache?.variables?.filterTemplate ||
        defaultVariables?.filterTemplate ||
        undefined,
    };
  }

  static init(defaultVariables?: VariablesStateParams, cacheKey?: string) {
    return new Variables(defaultVariables, cacheKey);
  }

  subscribe(
    key: 'variables',
    cb: SubscriberFunction<VariablesStateParams>
  ): void;
  subscribe(key: 'variables', cb: SubscriberFunction<any>): void {
    super.subscribe(key, cb);
  }

  onChangeVariables = (
    callback: (prev: VariablesStateParams) => VariablesStateParams
  ) => {
    this.variables = callback(this.variables);
  };

  onUpdateDefaultVariables = (next?: VariablesStateParams) => {
    if (JSON.stringify(this.defaultVariables) !== JSON.stringify(next)) {
      this.defaultVariables = next;
      this.variables = {
        ...this._variables,
        ...(next || {}),
        staticFilters: (this._variables.staticFilters ||
          next?.staticFilters) && {
          ...(this._variables.staticFilters || {}),
          ...(next?.staticFilters || {}),
        },
        filters: (this._variables.filters || next?.filters) && {
          ...(this._variables.filters || {}),
          ...(next?.filters || {}),
        },
        filterTemplate:
          next?.filterTemplate || this._variables.filterTemplate || undefined,
      };
    }
  };

  removeFilter = (key: string) => {
    const filters = this.variables.filters;
    delete filters?.[key];
    this.variables = {
      ...this.variables,
      filters,
    };
  };
}
