import styled from '@emotion/styled';
import { GridContainer } from '../../../containers';

export const Container = styled(GridContainer)`
  height: 100%;
  width: 100%;

  & > svg {
    margin: 12px;
  }
`;
