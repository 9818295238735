import { GridContainer, ListLoader } from '@aid-module/ui';
import { Fallback as CredentialsFallback } from '../../../../components/Credentials/Fallback';
import { DescriptionContainer } from '../../../../components';

export function Fallback() {
  return (
    <GridContainer gridTemplateColumns="1fr" gap="32">
      <DescriptionContainer>
        <ListLoader count={2} />
      </DescriptionContainer>
      <CredentialsFallback />
    </GridContainer>
  );
}
