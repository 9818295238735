import { TYPES } from '@aid-package/api';
import { useMemo } from 'react';
import {
  useFilterTemplatesContext,
  useVariablesContext,
} from '../../../../../../context';

export function useFilterTemplatesController() {
  const { code, data } = useFilterTemplatesContext();
  const { variables } = useVariablesContext();

  const renderTemplates = useMemo(() => {
    const { favorites, templates } =
      (data?.favorites?.length || 0) > 0
        ? { favorites: data?.favorites || [], templates: data?.templates || [] }
        : {
            favorites: data?.templates?.slice(0, 4) || [],
            templates: data?.templates?.slice(4) || [],
          };
    const reduce = favorites.reduce<{
      array: TYPES.TabRegistriesQuery['tabRegistries']['data'];
      ids: Record<string, boolean>;
    }>(
      (acc, tab) => {
        acc.array.splice((tab.position || 0), 0, tab);   
        return { ...acc, ids: { ...acc.ids, [tab.id]: true } };
      },
      { array: [], ids: {} }
    );

    if (variables.filterTemplate && !reduce.ids[variables.filterTemplate.id]) {
      return {
        favorites: [...reduce.array, variables.filterTemplate],
        templates: templates.filter(
          (template) => template.id !== variables.filterTemplate?.id
        ),
      };
    }
    return { favorites: reduce.array, templates };
  }, [variables?.filterTemplate, data?.favorites, data?.templates]);

  return {
    isFilterTemplatesExist: !!code,
    loading: data?.loading,
    renderTemplates,
  };
}
