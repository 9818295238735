import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { AlertsNavigationItems } from '../../../../../../Breadcrumbs/constants';
import { useCheckPermission } from '@aid-package/context';

export function useTransactionAlertsLinkController() {
  const { client_id } = useParams();

  const { canShow: canMonitoringAlerts } = useCheckPermission(
    AlertsNavigationItems[0].check,
    false
  );

  const { canShow: canScreeningAlert } = useCheckPermission(
    AlertsNavigationItems[1].check,
    false
  );

  const alertsAccesiblePath = useMemo(() => {
    const alertsCanShows = [canMonitoringAlerts, canScreeningAlert];
    const firstAccesiblePathIndex = alertsCanShows.indexOf(true);

    const firstAccesiblePath =
      AlertsNavigationItems[firstAccesiblePathIndex]?.path;

    return `/${client_id}${firstAccesiblePath}`;
  }, [canMonitoringAlerts, canScreeningAlert, client_id]);

  const transactionAlertsPaths = useMemo(() => {
    return AlertsNavigationItems.map((item) => `/${client_id}${item.path}`);
  }, [client_id]);

  return {
    transactionAlertsPaths,
    alertsAccesiblePath,
  };
}
