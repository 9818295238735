import { useMemo } from 'react';
import { ContentLoader } from '../ContentLoader';
import { DropdownLoaderViewProps } from './types';

export function DropdownLoader({
  height = 56,
  width,
}: DropdownLoaderViewProps) {
  const currentWidth = useMemo(() => (width ? `${width}px` : '100%'), [width]);
  return (
    <ContentLoader width={currentWidth} height={`${height}px`}>
      <rect
        x="0"
        y="0"
        rx="3"
        ry="3"
        width={currentWidth}
        height={`${height}px`}
      />
    </ContentLoader>
  );
}
