import gql from 'graphql-tag';
import { CLIENT_PROVIDER_FRAGMENT } from '../../../fragment/admin/clientProviders';

export const ToggleClientProvider = gql`
  ${CLIENT_PROVIDER_FRAGMENT}
  mutation ToggleClientProvider($id: String!, $body: BodyToggleClientProvider) {
    toggleClientProvider(id: $id, body: $body)
      @rest(
        type: "ToggleClientProvider"
        path: "/checkups/admin/clients_providers/{args.id}/toggle"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "ClientProvider") {
        ...ClientProviderFragment
      }
      success
    }
  }
`;
