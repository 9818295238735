import { DatePicker, GridContainer, Text, TextInput } from '@aid-module/ui';
import { useBodyController } from './controller';
import { FormProvider, Controller } from 'react-hook-form';
import { FormRenderProps } from './types';
import { Card, Container, ContentContainer, RelativeContainer } from './styles';
import { FormSelect } from '../../../../../../../../../../FormSelect';
import { Actions, TemplatesSelect } from './components';
import { SubmissionStatusSelect } from '../../../../../../../../../../SubmissionStatusSelect';
import { CaseStatusSelect } from '../../../../../../../../../../CaseStatusSelect';
import { DateTo } from '../../../../../../../DateTo';
import { Structure } from '../../../../../../../Structure';
import { BankTariffSelect } from '../../../../../../../BankTariffSelect';

const renderName = ({
  field: { value, onChange },
  fieldState: { error },
}: FormRenderProps<'name'>) => {
  return (
    <TextInput
      label="Name"
      placeholder="Enter Name..."
      error={error}
      value={value}
      onChange={onChange}
    />
  );
};

const renderSubmissionStatus = ({
  field: { value, onChange },
}: FormRenderProps<'submission_statuses'>) => {
  return (
    <SubmissionStatusSelect
      isMulti
      label="Verification statuses"
      placeholder="Select Verification Statuses..."
      value={value}
      onChange={onChange}
    />
  );
};

const renderCaseStatus = ({
  field: { value, onChange },
}: FormRenderProps<'case_statuses'>) => {
  return (
    <CaseStatusSelect
      isMulti
      label="Case statuses"
      placeholder="Select Case Statuses..."
      value={value}
      onChange={onChange}
    />
  );
};

const renderDateFrom = ({
  field: { value, onChange },
  fieldState: { error },
}: FormRenderProps<'date_from'>) => {
  return (
    <DatePicker
      label="Date from"
      error={error}
      value={value}
      onChange={onChange}
      maxDate={new Date()}
    />
  );
};

const renderDateTo = ({
  field: { value, onChange },
  fieldState: { error },
}: FormRenderProps<'date_to'>) => {
  return <DateTo error={error} value={value} onChange={onChange} />;
};

const renderScoreFrom = ({
  field: { value, onChange },
  fieldState: { error },
}: FormRenderProps<'score_from'>) => {
  return (
    <TextInput
      error={error}
      type="number"
      label="Score from"
      placeholder="0.0"
      value={value}
      onChange={onChange}
    />
  );
};

const renderScoreTo = ({
  field: { value, onChange },
  fieldState: { error },
}: FormRenderProps<'score_to'>) => {
  return (
    <TextInput
      error={error}
      type="number"
      label="Score to"
      placeholder="0.0"
      value={value}
      onChange={onChange}
    />
  );
};

const renderBankTariffName = ({
  field: { value, onChange },
  fieldState: { error },
}: FormRenderProps<'bank_tariff_names'>) => {
  return <BankTariffSelect value={value} onChange={onChange} />;
};

const renderForm = ({
  field: { value, onChange },
  fieldState: { error },
}: FormRenderProps<'reference_key'>) => {
  return <FormSelect error={error} value={value} onChange={onChange} />;
};

export function Body() {
  const { context } = useBodyController();
  return (
    <FormProvider {...context}>
      <ContentContainer gridTemplateRows="max-content minmax(0px, 1fr)">
        <Container gap="8">
          <Controller name="name" render={renderName} />
          <TemplatesSelect />
          <GridContainer
            gridTemplateColumns="repeat(2, 1fr)"
            gap="12"
            alignItems="flex-start"
          >
            <Controller name="date_from" render={renderDateFrom} />
            <Controller name="date_to" render={renderDateTo} />
          </GridContainer>
          <GridContainer
            gridTemplateColumns="repeat(2, 1fr)"
            gap="12"
            alignItems="flex-start"
          >
            <Controller name="case_statuses" render={renderCaseStatus} />
            <Controller
              name="submission_statuses"
              render={renderSubmissionStatus}
            />
          </GridContainer>
          <GridContainer
            gap="8"
            gridTemplateColumns="1fr 1fr"
            alignItems="flex-start"
          >
            <Controller name="reference_key" render={renderForm} />
            <Controller
              name="bank_tariff_names"
              render={renderBankTariffName}
            />
          </GridContainer>
          <GridContainer gap="8" gridTemplateColumns="1fr 1fr">
            <Controller name="score_from" render={renderScoreFrom} />
            <Controller name="score_to" render={renderScoreTo} />
          </GridContainer>
        </Container>
        <GridContainer gridTemplateRows="max-content minmax(450px, 1fr) max-content">
          <Card background="main-deep-1">
            <Text text="FORM STRUCTURE" color="main-deep-4" weight="VeryBig" />
          </Card>
          <RelativeContainer>
            <Structure />
          </RelativeContainer>
          <Actions />
        </GridContainer>
      </ContentContainer>
    </FormProvider>
  );
}
