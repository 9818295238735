import gql from 'graphql-tag';

export const BLACKLISTED_PROFILE_FRAGMENT = gql`
  fragment BlacklistedProfileFragment on BlacklistedProfile {
    profile @type(name: "Profile") {
      blacklisted_profile_id
      display_name
      email
      id
      is_whitelisted
      kind
      phone
    }
    created_at
    id
  }
`;
