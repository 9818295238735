import gql from 'graphql-tag';
import { FIELD_TEMPLATE_FRAGMENT } from '../../../fragment/admin/fieldTemplates';

export const FieldTemplates = gql`
  ${FIELD_TEMPLATE_FRAGMENT}
  query FieldTemplates(
    $page: Int
    $q: String
    $kind: FieldTemplateKind
    $codes: String
    $kinds: String
    $common: Boolean
    $except_hidden: Boolean
    $per: Int = 20
  ) {
    fieldTemplates(
      page: $page
      per: $per
      q: $q
      kind: $kind
      codes: $codes
      kinds: $kinds
      common: $common
      except_hidden: $except_hidden
    )
      @rest(
        type: "FieldTemplatesResponse"
        path: "/core/client/field_templates?{args}"
      ) {
      data @type(name: "FieldTemplate") {
        ...FieldTemplateFragment
        options
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const FieldTemplate = gql`
  ${FIELD_TEMPLATE_FRAGMENT}
  query FieldTemplate($id: String!) {
    fieldTemplate(id: $id)
      @rest(
        type: "FieldTemplateResponse"
        path: "/core/client/field_templates/{args.id}"
      ) {
      data @type(name: "FieldTemplate") {
        ...FieldTemplateFragment
        options
      }
      success
    }
  }
`;

export const AdminFieldTemplates = gql`
  ${FIELD_TEMPLATE_FRAGMENT}
  query AdminFieldTemplates(
    $page: Int
    $q: String
    $kind: FieldTemplateKind
    $codes: String
    $kinds: String
    $common: Boolean
    $except_hidden: Boolean
    $per: Int = 20
  ) {
    adminFieldTemplates(
      page: $page
      per: $per
      q: $q
      kind: $kind
      codes: $codes
      kinds: $kinds
      common: $common
      except_hidden: $except_hidden
    )
      @rest(
        type: "AdminFieldTemplatesResponse"
        path: "/core/admin/field_templates?{args}"
      ) {
      data @type(name: "FieldTemplate") {
        ...FieldTemplateFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const AdminFieldTemplate = gql`
  ${FIELD_TEMPLATE_FRAGMENT}
  query AdminFieldTemplate($id: String!) {
    adminFieldTemplate(id: $id)
      @rest(
        type: "AdminFieldTemplateResponse"
        path: "/core/admin/field_templates/{args.id}"
      ) {
      data @type(name: "FieldTemplate") {
        ...FieldTemplateFragment
      }
      success
    }
  }
`;
