import gql from 'graphql-tag';

export const FincenList = gql`
  query FincenList(
    $kind: String
    $with_checkups_counters: Boolean
    $page: Int
    $per: Int
  ) {
    fincenList(
      kind: $kind
      with_checkups_counters: $with_checkups_counters
      page: $page
      per: $per
    )
      @rest(
        type: "ResponseFincenList"
        path: "/checkups/client/fincen/lists?{args}"
      ) {
      data @type(name: "FincenList") {
        aid_checkups_count
        created_at
        created_by @type(name: "Account") {
          email
          first_name
          id
          last_name
        }
        fincen_business_records_count
        fincen_personal_records_count
        id
        kind
        last_mass_screened_at
        last_screened_at
        last_screened_by @type(name: "Account") {
          email
          first_name
          id
          last_name
        }
        last_screened_by_id
        tm_checkups_count
        uploaded_document_id
      }
      meta {
        count
        page
        items
        pages
      }
      success
    }
  }
`;

export const FincenPersonalRecords = gql`
  query FincenPersonalRecords(
    $fincen_list_id: String!
    $q: String
    $page: Int
  ) {
    fincenPersonalRecords(
      fincen_list_id: $fincen_list_id
      q: $q
      page: $page
      per: 20
    )
      @rest(
        type: "ResponseFincenPersonalRecords"
        path: "/checkups/client/fincen/personal_records?{args}"
      ) {
      data @type(name: "FincenPersonalRecord") {
        alias_first_name
        alias_last_name
        alias_middle_name
        alias_suffix
        city
        country
        dob
        first_name
        id
        last_name
        middle_name
        number
        number_type
        phone
        state
        street
        suffix
        tracking_number
        zip
      }
      meta {
        count
        page
        items
        pages
      }
      success
    }
  }
`;

export const FincenBusinessRecords = gql`
  query FincenBusinessRecords(
    $fincen_list_id: String!
    $q: String
    $page: Int
  ) {
    fincenBusinessRecords(
      fincen_list_id: $fincen_list_id
      q: $q
      page: $page
      per: 20
    )
      @rest(
        type: "ResponseFincenBusinessRecords"
        path: "/checkups/client/fincen/business_records?{args}"
      ) {
      data @type(name: "FincenBusinessRecord") {
        business_name
        city
        country
        dba_name
        id
        incorporated
        number
        number_type
        phone
        state
        street
        tracking_number
        zip
      }
      meta {
        count
        page
        items
        pages
      }
      success
    }
  }
`;

export const FincenCheckupsReport = gql`
  query FincenCheckupsReport($fincen_list_id: String) {
    fincenCheckupsReport(fincen_list_id: $fincen_list_id)
      @rest(
        type: "ResponseReport"
        path: "/checkups/client/fincen/reports/checkups_csv?{args}"
      )
  }
`;
