import gql from 'graphql-tag';

export const FieldsPresets = gql`
  query FieldsPresets($page: Int) {
    fieldsPresets(page: $page)
      @rest(
        type: "ResponseFieldsPresets"
        path: "/core/client/field_presets?{args}"
      ) {
      data @type(name: "FieldsPreset") {
        id
        name
        meta
        fields_presets_json
      }
      meta {
        count
        page
        items
        pages
      }
      success
    }
  }
`;

export const FieldsPreset = gql`
  query FieldsPreset($id: String!) {
    fieldsPreset(id: $id)
      @rest(
        type: "ResponseFieldsPreset"
        path: "/core/client/field_presets/{args.id}"
      ) {
      data @type(name: "FieldsPreset") {
        id
        name
        meta
        fields_presets_json
      }
      success
    }
  }
`;
