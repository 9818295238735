import gql from 'graphql-tag';
import { IDENFY_RAW_RESULT_CLIENT_FRAGMENT } from '../IdenfyRawResultClient';
import { IDENFY_RAW_RESULT_CLIENT_IDENTITY_FRAGMENT } from '../IdenfyRawResultClientIdentityDocument';

export const IDENFY_RAW_RESULT_FULL_FRAGMENT = gql`
  ${IDENFY_RAW_RESULT_CLIENT_FRAGMENT}
  ${IDENFY_RAW_RESULT_CLIENT_IDENTITY_FRAGMENT}
  fragment IdenfyRawResultFullFragment on IdenfyRawResultFull {
    attempt_count
    client @type(name: "IdenfyRawResultClient") {
      ...IdenfyRawResultClientFragment
    }
    client_identity_document
      @type(name: "IdenfyRawResultClientIdentityDocument") {
      ...IdenfyRawResultClientIdentityDocument
    }
    document_validity
    face_match_result
    finish_time
    manual_document_validity
    manual_face_match_result
    mismatch_tags
    review_time
    start_time
    suspection_reasons
  }
`;
