import styled from '@emotion/styled';
import { Card, WarningButton, getSpace, getColor, GridContainer } from '@aid-module/ui';

export const ClientCard = styled(Card)`
  border-radius: 4px;
`;

export const ClientContentCard = styled(Card)`
  padding: ${getSpace('12')} ${getSpace('18')};
  border-radius: 8px;
`;

export const ClientButton = styled(WarningButton)`
  background: ${getColor('warning-deep-5')};
  border-color: ${getColor('warning-deep-5')};
`;

export const StatusContainer = styled(GridContainer)`
  background: ${getColor('warning-deep-5')};
  padding: ${getSpace('4')} ${getSpace('8')};
  border-radius: 4px;
`;
