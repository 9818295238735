import {
  AsyncSelect,
  SelectOption,
  SelectViewProps as UISelectVIewProps,
} from '@aid-module/ui';
import { SelectViewProps } from './types';
import { Dictionaries } from '@aid-package/api';
import { useSelectController } from './controller';
import { useCallback, useMemo } from 'react';
import { FormRenderProps } from '../../../../types';
import { Controller } from 'react-hook-form';

function RenderSelectField({
  field,
  value,
  onChange,
  error,
}: Pick<
  UISelectVIewProps<boolean, SelectOption>,
  'value' | 'onChange' | 'error'
> &
  SelectViewProps) {
  const { reduceData } = useSelectController();

  const additional = useMemo(
    () => ({
      dictionary_name: field.dictionary_name,
      order_dir: 'asc',
      order_col: 'name',
    }),
    [field.dictionary_name]
  );

  return (
    <AsyncSelect
      isSearchable
      query={Dictionaries}
      label={`${field.title} ${field.required ? ' *' : ''}`}
      placeholder={`Select ${field.title}...`}
      additional={additional}
      value={value}
      error={error}
      isMulti={field.type === 'array_dictionary'}
      onChange={onChange}
      reduceData={reduceData}
    />
  );
}

export function SelectDictionary({ field }: SelectViewProps) {
  const renderSelect = useCallback(
    ({
      field: { value, onChange },
      fieldState: { error },
    }: FormRenderProps<string>) => {
      return (
        <RenderSelectField
          field={field}
          error={error}
          value={value}
          onChange={onChange}
        />
      );
    },
    [field]
  );
  return <Controller name={field.code} render={renderSelect} />;
}
