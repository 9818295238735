import { ErrorBundle, ErrorCritical } from '@aid-module/ui';
import { Component, PropsWithChildren } from 'react';
import { ErrorClassViewProps, ErrorClassViewState } from './types';
import * as Sentry from '@sentry/react';

export class ErrorClass extends Component<
  PropsWithChildren<ErrorClassViewProps>,
  ErrorClassViewState
> {
  state = { type: undefined };

  componentDidCatch(error: Error) {
    Sentry.captureException(error);
    if (error.name === 'ChunkLoadError') {
      this.setState({
        type: 'bundle',
      });
    } else {
      this.setState({
        type: 'some',
      });
    }
  }

  render() {
    if (this.state.type) {
      if (this.state.type === 'bundle') {
        return <ErrorBundle type="global" />;
      }
      return (
        <ErrorCritical
          type="global"
          description="Something went wrong. We see this error and will try to fix it as soon as possible."
        />
      );
    }

    return this.props.children;
  }
}
