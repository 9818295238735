import { AsyncSelect } from '@aid-module/ui';
import { Forms } from '@aid-package/api';
import { useFormSelectController } from './controller';
import { FormSelectViewProps } from './types';

export function FormSelect<multi extends boolean>({
  onChange,
  value,
  isDisabled,
  isMulti,
  error,
}: FormSelectViewProps<multi>) {
  const { reduceData } = useFormSelectController();

  return (
    <AsyncSelect
      label="Form"
      placeholder="Select forms..."
      isMulti={isMulti}
      isDisabled={isDisabled}
      value={value}
      onChange={onChange}
      query={Forms}
      reduceData={reduceData}
      error={error}
    />
  );
}
