import gql from 'graphql-tag';
import { CA_ACTUAL_SEARCH_REVISION_FRAGMENT } from './caActualSearchRevisionData';

export const CA_SEARCH_FRAGMENT = gql`
  ${CA_ACTUAL_SEARCH_REVISION_FRAGMENT}
  fragment CaSearchFragment on CaSearch {
    actual_search_revision @type(name: "CaActualSearchRevision") {
      id
      payload @type(name: "CaActualSearchRevisionPayload") {
        data @type(name: "CaActualSearchRevisionData") {
          ...CaActualSearchRevisionData
        }
      }
    }
    created_at
    id
    is_monitored
    ref_id
    search_id
    share_url
  }
`;
