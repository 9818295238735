import { PropsWithChildren } from 'react';
import { Container } from './styles';
import { HorisontalActionInputDecoratorViewProps } from './types';

export function HorisontalActionInputDecorator({
  children,
  ...props
}: PropsWithChildren<HorisontalActionInputDecoratorViewProps>) {
  return <Container {...props}>{children}</Container>;
}
