import { gql } from '@apollo/client';

export const TransactionCaseOrigins = gql`
  query TransactionCaseOrigins($page: Int, $q: String) {
    transactionCaseOrigins(page: $page, per: 20, q: $q)
      @rest(
        type: "ResponseTransactionActivityTypes"
        path: "/tm/case_origins?{args}"
      ) {
      data @type(name: "TransactionCaseOrigin") {
        id
        name
        created_at
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;
