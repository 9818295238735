import {
  TableWithHeaderLoader,
  LoadableFunctionalComponent,
} from '@aid-module/ui';
import loadable from '@loadable/component';

export const FormMappingExportsRegister = loadable(
  () => import('./component'),
  {
    resolveComponent: (components) => components.RegisterComponent,
    fallback: <TableWithHeaderLoader />,
  }
) as LoadableFunctionalComponent<{}>;
