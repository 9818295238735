import { useEffect, useState, useMemo } from 'react';
import { ColumnsProvidersViewProps } from '../types';
import { Columns } from '../Columns';

export function useColumnsContextController(
  columnsKey: ColumnsProvidersViewProps['columnsKey']
) {
  const variablesRef = useMemo(() => {
    return Columns.init(columnsKey);
  }, []);
  const [columns, setColumns] = useState<string[] | undefined>(
    variablesRef.columns
  );

  useEffect(() => {
    variablesRef.subscribe('columns', setColumns);
  }, []);

  const selectedColumns = useMemo(
    () =>
      columns
        ? columns.reduce((acc, key) => ({ ...acc, [key]: true }), {})
        : undefined,
    [columns]
  );

  return {
    columnsKey,
    columnsRenderKeys: columns,
    selectedColumns,
    onChangeColumns: variablesRef.onChangeColumns,
  };
}
