import { gql } from '@apollo/client';
import { CREATED_TRANSLATION_FRAGMENT } from '../../../fragment/checkup/translation';

export const CreateTranslationQuote = gql`
  ${CREATED_TRANSLATION_FRAGMENT}
  mutation CreateTranslationQuote($body: RequestCreateTranslation!) {
    createTranslationQuote(body: $body)
      @rest(
        type: "TranslationQuoteCreate"
        path: "/core/client/translation/translated/quote"
        bodyKey: "body"
        method: "POST"
      ) {
      result @type(name: "ResponseCreateTranslation") {
        ...CreatedTranslationFragment
      }
      success
      errors {
        name
      }
    }
  }
`;

export const CreateTranslationIBM = gql`
  ${CREATED_TRANSLATION_FRAGMENT}
  mutation CreateTranslationIBM($body: RequestCreateTranslation!) {
    createTranslationIBM(body: $body)
      @rest(
        type: "TranslationIBMCreate"
        path: "/core/client/translation/ibm_translate/translate_file"
        bodyKey: "body"
        method: "POST"
      ) {
      result @type(name: "ResponseCreateTranslation") {
        ...CreatedTranslationFragment
      }
      success
      errors {
        name
      }
    }
  }
`;
