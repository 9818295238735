import { DefaultButtonLoader } from '@aid-module/ui';
import { useMemo } from 'react';
import { Container } from './styles';
import { ArrayButtonsViewProps } from './types';

export function ArrayButtons({ count }: ArrayButtonsViewProps) {
  const buttonArray = useMemo(() => {
    return Array.from({ length: count }, (_, i) => (
      <DefaultButtonLoader key={i} />
    ));
  }, [count]);

  return (
    <Container gap="20" count={count}>
      {buttonArray}
    </Container>
  );
}
