import gql from 'graphql-tag';

export const CustomerMessages = gql`
  query CustomerMessages(
    $page: Int
    $q: String
    $sort_field: String
    $sort_order: String
    $sent_at_from: String
    $sent_at_to: String
    $created_at_to: String
    $created_at_from: String
    $account_ids: [String]!
    $state: String
  ) {
    customerMessages(
      page: $page
      per: 20
      q: $q
      sort_field: $sort_field
      sort_order: $sort_order
      sent_at_to: $sent_at_to
      sent_at_from: $sent_at_from
      created_at_to: $created_at_to
      created_at_from: $created_at_from
      account_ids: $account_ids
      state: $state
    )
      @rest(
        type: "ResponseCustomerMessages"
        path: "/core/client/customer_messages?{args}"
      ) {
      data @type(name: "CustomerMessage") {
        id
        body
        client_id
        created_at
        name
        email
        state
        subject
        updated_at
        account @type(name: "Account") {
          id
          full_name
        }
        header
        sent_at
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const CustomerMessage = gql`
  query CustomerMessage($id: String!) {
    customerMessage(id: $id)
      @rest(
        type: "ResponseCustomerMessage"
        path: "/core/client/customer_messages/{args.id}"
      ) {
      data @type(name: "CustomerMessage") {
        id
        body
        client_id
        created_at
        name
        state
        email
        subject
        updated_at
        account @type(name: "Account") {
          id
          full_name
        }
        header
        sent_at
      }
      success
    }
  }
`;
