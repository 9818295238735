import gql from 'graphql-tag';
import { EDD_REPORT_FRAGMENT } from '../../fragment/edd/eddReport';

export const EDDReports = gql`
  ${EDD_REPORT_FRAGMENT}
  query EDDReports(
    $case_ids: [String!]
    $assigned_to_id: String
    $form_configuration_id: String
    $status: String
    $main_profile_id: String
    $form_submission_id: String
    $from_created_at: String
    $to_created_at: String
    $completed_at_from: String
    $to_completed_at: String
    $profile_kinds: [String!]
    $sort_order: String
    $sort_field: String
    $page: Int
    $per: Int
    $q: String
  ) {
    eddReports(
      case_ids: $case_ids
      assigned_to_id: $assigned_to_id
      form_configuration_id: $form_configuration_id
      status: $status
      main_profile_id: $main_profile_id
      form_submission_id: $form_submission_id
      from_created_at: $from_created_at
      to_created_at: $to_created_at
      completed_at_from: $completed_at_from
      to_completed_at: $to_completed_at
      profile_kinds: $profile_kinds
      sort_field: $sort_field
      sort_order: $sort_order
      page: $page
      per: $per
      q: $q
    )
      @rest(
        type: "ResponseEDDReports"
        path: "/core/client/edd_reports?{args}"
      ) {
      data @type(name: "EDDReport") {
        ...EDDReportFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const EDDReport = gql`
  ${EDD_REPORT_FRAGMENT}
  query EDDReport($id: String!) {
    eddReport(id: $id)
      @rest(
        type: "ResponseEDDReport"
        path: "/core/client/edd_reports/{args.id}"
      ) {
      data @type(name: "EDDReport") {
        ...EDDReportFragment
      }
      success
    }
  }
`;

export const EDDReportDocuments = gql`
  query EDDReportDocuments($edd_report_id: String!, $page: Int!) {
    eddReportDocuments(edd_report_id: $edd_report_id, page: $page, per: 20)
      @rest(
        type: "ResponseEDDReportDocuments"
        path: "/core/client/edd_report_documents?{args}"
      ) {
      data @type(name: "EDDReportDocument") {
        id
        edd_report_id
        created_at
        update_at
        description
        uploaded_document_id
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const EDDCSVReport = gql`
  query EDDCSVReport(
    $case_ids: [String!]
    $assigned_to_id: String
    $form_configuration_id: String
    $status: String
    $main_profile_id: String
    $form_submission_id: String
    $from_created_at: String
    $to_created_at: String
    $completed_at_from: String
    $to_completed_at: String
    $profile_kinds: [String!]
    $sort_order: String
    $sort_field: String
    $page: Int
    $per: Int
    $q: String
  ) {
    eddCSVReport(
      case_ids: $case_ids
      assigned_to_id: $assigned_to_id
      form_configuration_id: $form_configuration_id
      status: $status
      main_profile_id: $main_profile_id
      form_submission_id: $form_submission_id
      from_created_at: $from_created_at
      to_created_at: $to_created_at
      completed_at_from: $completed_at_from
      to_completed_at: $to_completed_at
      profile_kinds: $profile_kinds
      sort_field: $sort_field
      sort_order: $sort_order
      page: $page
      per: $per
      q: $q
    ) @rest(type: "ResponseReport", path: "/core/edd_reports/edd/to_csv?{args}")
  }
`;
