import { useMyFeaturesQuery } from '@aid-package/api';
import { useMemo } from 'react';
import { FeaturesParams } from '../types';

export function useFeaturesProviderModel() {
  const { loading, data } = useMyFeaturesQuery({
    fetchPolicy: 'cache-first',
  });

  const features = useMemo(() => {
    return data?.myFeatures?.data?.reduce<FeaturesParams>((acc, item) => {
      if (item?.code) {
        return {
          ...acc,
          [item.code]: item?.available,
        };
      }

      return acc;
    }, {});
  }, [data?.myFeatures?.data]);

  return { features, loading };
}
