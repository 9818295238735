import { gql, InMemoryCache } from '@apollo/client';
import { Maybe, AccountClient } from '../api';
import { ReadMe } from './types';

export function getClientId() {
  const pathArray = location.pathname.split('/');
  return pathArray[1];
}

export function getMeAccountsClients(cache: InMemoryCache) {
  const data = cache.readQuery<ReadMe>({
    query: gql`
      query ReadMy {
        me @rest(type: "ResponseMe", path: "/core/accounts/me") {
          data @type(name: "Account") {
            accounts_clients @type(name: "AccountClient") {
              id
              client @type(name: "Client") {
                id
              }
            }
          }
          success
        }
      }
    `,
  });

  return data?.me?.data?.accounts_clients;
}

export function getClient(
  clientId: string,
  accountsClients: Maybe<AccountClient>[]
) {
  return (
    accountsClients.find((it) => it?.client?.id === clientId) ||
    accountsClients[0]
  );
}

export function toEndCallStack(callback: () => void) {
  setTimeout(() => {
    callback();
  }, 0);
}
