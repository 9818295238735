import { SelectOption } from '@aid-module/ui';
import { UnionField } from '../../../controller/types';
import { FormProps } from '../../../types';

function parseValue(
  type:
    | 'image'
    | 'cryptocurrency_symbol'
    | 'date'
    | 'select_dictionary'
    | 'select_options'
    | 'select_static'
    | 'boolean'
    | 'number'
    | 'string'
    | 'array_dictionary'
    | 'fuzziness'
    | 'array_options'
    | 'blockchain',
  value: string | number | SelectOption
) {
  switch (type) {
    case 'image': {
      if (typeof value === 'object' && !Array.isArray(value)) {
        return value?.id;
      }
      return;
    }
    case 'array_dictionary':
    case 'array_options':
      if (Array.isArray(value)) {
        return value?.map((item) => item.value);
      }
      return;
    case 'select_dictionary':
    case 'select_options':
    case 'select_static':
    case 'cryptocurrency_symbol':
    case 'blockchain': {
      if (typeof value === 'object' && !Array.isArray(value)) {
        return value?.value;
      }
      return;
    }
    default:
      return value;
  }
}

export function parseValues(fields: UnionField[], values: FormProps) {
  return fields.reduce((acc, item) => {
    const { code, type } = item;
    const sendValue = parseValue(type, values[code]);
    if (sendValue) {
      return {
        ...acc,
        [code]: sendValue,
      };
    }
    return acc;
  }, {});
}
