import { ClientController, logOut } from '@aid-package/api';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { MenuViewProps } from '../types';

export function useMenuController(onClickItem: MenuViewProps['onClickItem']) {
  const { client_id } = useParams();

  const hrefAccountSettings = useMemo(
    () => `/${client_id}/profile/edit/accout_settings`,
    [client_id]
  );

  const onLogout = useCallback(() => {
    logOut();
    onClickItem();
  }, [onClickItem]);

  const onNewLogout = useCallback(() => {
    ClientController.stopWSSWorker();
    window.location.href = `${process.env.REACT_APP_API_HOST}/auth/logout?redirect_uri=${process.env.REACT_APP_API_HOST}/auth/login?redirect_uri=${window.location.href}&client_id=912f3a10-95b5-4ea4-90c7-7bda8aa6d947`;
    onClickItem();
  }, [onClickItem]);

  return { hrefAccountSettings, onLogout, onNewLogout };
}
