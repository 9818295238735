import { ContentLoader, GridContainer } from '@aid-module/ui';
import { RegisterFallback } from '../RegiterFallback';

export function Fallback() {
  return (
    <GridContainer gap="18">
      <GridContainer gap="18">
        <ContentLoader width="100%" height="32.2">
          <rect rx={8} ry={8} width="100%" height="32.2" />
        </ContentLoader>
        <ContentLoader width="100%" height="103.39">
          <rect rx={8} ry={8} width="100%" height="103.39" />
        </ContentLoader>
      </GridContainer>
      <GridContainer gap="32">
        <RegisterFallback />
        <RegisterFallback />
        <RegisterFallback />
        <RegisterFallback />
      </GridContainer>
    </GridContainer>
  );
}
