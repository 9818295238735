import { cloneElement, PropsWithChildren, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { useAutomaticTooltipController } from './controller';
import { Container, Card } from './styles';
import { AutomaticControllerTooltip } from './types';

export function AutomaticTooltip({
  children,
  view: View,
  canShow = true,
  pattern = 'top,left,right,bottom',
  background = 'main-deep-1',
  border = 'main-deep-1',
  loaderBackground = 'main-deep-2',
  loaderForeground = 'main-deep-1',
  padding,
}: PropsWithChildren<AutomaticControllerTooltip>) {
  const { initiateRef, containerRef, checkedRef, themeRef } =
    useAutomaticTooltipController(pattern, canShow);

  const view = useMemo(
    () => cloneElement(View, { initiateRef, checkedRef }),
    [View]
  );

  return (
    <>
      {view}
      {createPortal(
        <Container ref={containerRef}>
          <Card
            background={background}
            border={border}
            loaderBackground={loaderBackground}
            loaderForeground={loaderForeground}
            customPadding={padding}
          >
            {children}
          </Card>
        </Container>,
        themeRef.current || document.body
      )}
    </>
  );
}

export type { AutomaticControllerTooltipInitiateView } from './types';
