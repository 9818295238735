import { PropsWithChildren } from 'react';
import { HorisontalInputDecoratorViewProps } from './types';
import { Container, InputContainer, Label } from './styles';
import { ErrorContainer, NoteContainer, ErrorRow } from '../shared.styles';
import { Text } from '../../../Text';
import { HorisontalActionInputDecorator } from '../HorisontalActionInputDecorator';
import { useHorisontalInputDecoratorController } from './controller';
import { InfoTooltip } from '../InfoTooltip';

export function HorisontalInputDecorator({
  error,
  children,
  label,
  disabled,
  note,
  warning,
  labelSize,
  uppercase,
  weight,
  information,
  className,
}: PropsWithChildren<HorisontalInputDecoratorViewProps>) {
  const { onClickContainer } = useHorisontalInputDecoratorController();
  return (
    <Container className={className} onClick={onClickContainer}>
      <HorisontalActionInputDecorator label={label} disabled={disabled}>
        <InputContainer disabled={disabled} label={label}>
          {children}
          {label && (
            <Label
              error={error}
              weight={weight || 'Medium'}
              disabled={disabled}
              size={labelSize}
              uppercase={uppercase}
            >
              {label}
              {information ? (
                <>
                  {' '}
                  <InfoTooltip information={information} />
                </>
              ) : null}
            </Label>
          )}
        </InputContainer>
      </HorisontalActionInputDecorator>
      {note && (
        <NoteContainer>
          <Text text={note} size={12} color="main-deep-4" />
        </NoteContainer>
      )}
      {warning && (
        <NoteContainer>
          <Text text={warning} size={12} color="warning-deep-4" />
        </NoteContainer>
      )}
      {error && error?.message && (
        <ErrorContainer>
          <ErrorRow text={error?.message} size={12} color="error-deep-3" />
        </ErrorContainer>
      )}
    </Container>
  );
}
