import { gql } from '@apollo/client';

export const AddFincenList = gql`
  mutation AddFincenList($body: BodyAddFincenList!) {
    addFincenList(body: $body)
      @rest(
        type: "AddFincenList"
        path: "/checkups/client/fincen/lists"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "FincenList") {
        aid_checkups_count
        created_at
        created_by @type(name: "Account") {
          email
          first_name
          id
          last_name
        }
        fincen_business_records_count
        fincen_personal_records_count
        id
        kind
        last_mass_screened_at
        tm_checkups_count
        uploaded_document_id
      }
      success
    }
  }
`;

export const RunMassScreening = gql`
  mutation RunMassScreening($body: BodyRunMassScreening!) {
    runMassScreening(body: $body)
      @rest(
        type: "RunMassScreening"
        path: "/pp/mass_screenings"
        bodyKey: "body"
        method: "POST"
      ) {
      success
    }
  }
`;
