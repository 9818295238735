import gql from 'graphql-tag';
import { CLIENT_CHECKUP_KIND_FRAGMENT } from '../../../fragment/admin/clientCheckupKinds';

export const ClientCheckupKinds = gql`
  ${CLIENT_CHECKUP_KIND_FRAGMENT}
  query ClientCheckupKinds($page: Int, $per: Int, $q: String) {
    clientCheckupKinds(page: $page, per: $per, q: $q)
      @rest(
        type: "ResponseClientCheckupKinds"
        path: "/checkups/admin/checkup_kinds_clients?{args}"
      ) {
      data @type(name: "ClientCheckupKind") {
        ...ClientCheckupKindFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const ClientCheckupKind = gql`
  ${CLIENT_CHECKUP_KIND_FRAGMENT}
  query ClientCheckupKind($id: String!) {
    clientCheckupKind(id: $id)
      @rest(
        type: "ResponseClientCheckupKind"
        path: "/checkups/admin/checkup_kinds_clients/{args.id}"
      ) {
      data @type(name: "ClientCheckupKind") {
        ...ClientCheckupKindFragment
      }
      success
    }
  }
`;
