import {
  LoadableFunctionalComponent,
  TableWithHeaderLoader,
} from '@aid-module/ui';
import loadable from '@loadable/component';
import { Fallback } from './components';

export const PricingPlanCreate = loadable(() => import('./component'), {
  resolveComponent: (components) => components.PricingPlansCreateComponent,
  fallback: <Fallback />,
}) as LoadableFunctionalComponent<{}>;
