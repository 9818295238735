export const VERIFICATION_STATUSES = {
  COMPLETED: 'completed',
  CLEAN: 'clean',
  IN_PROGRESS: 'in_progress',
  DRAFT: 'draft',
  WARNING: 'warning',
  FAILED: 'failed',
  UNPROCESSED: 'unprocessed',
  NO_INFO: 'no_info',
};

export const VERIFICATION_DISPLAY_STATUSES = {
  [VERIFICATION_STATUSES.COMPLETED]: 'Completed',
  [VERIFICATION_STATUSES.CLEAN]: 'No matches',
  [VERIFICATION_STATUSES.IN_PROGRESS]: 'In Progress',
  [VERIFICATION_STATUSES.DRAFT]: 'Draft',
  [VERIFICATION_STATUSES.WARNING]: 'Warning',
  [VERIFICATION_STATUSES.FAILED]: 'Failed',
  [VERIFICATION_STATUSES.UNPROCESSED]: 'Unprocessed',
  [VERIFICATION_STATUSES.NO_INFO]: 'No Information',
};
