import { Checkbox } from '../../../..//Checkbox';
import { Container, ActionContainer } from './styles';
import { EditViewProps } from './types';
import { useEditController } from './controller';

export function Edit({ id, item }: EditViewProps) {
  const { value, disabled, onClick, onChange } = useEditController(id, item);
  return (
    <Container>
      <ActionContainer onClick={onClick}>
        <Checkbox
          disabled={disabled}
          value={value}
          onChange={onChange}
          checkboxColor="warning-deep-5"
        />
      </ActionContainer>
    </Container>
  );
}
