import {
  DragDropContext,
  Droppable as UIDropable,
  DroppableProvided,
  DroppableStateSnapshot,
  DraggableChildrenFn,
} from 'react-beautiful-dnd';
import { Draggable } from './components';
import { useDroppableController } from './controller';
import { PlaceholderProps } from './controller/types';
import {
  Container,
  ContentContainer,
  Placeholder,
  ActionContainer,
} from './styles';
import { DroppableViewProps, TableColumnWithKey } from './types';
import { ActionContext } from './context';
import { GridContainer } from '../../../../../../../../../../../containers';
import {
  SecondaryButton,
  PrimaryButton,
} from '../../../../../../../../../../Button';
import { Template } from './components/Draggable/components';
import { useCallback, useRef } from 'react';

function renderDraggable(template: TableColumnWithKey, index: number) {
  return <Draggable key={template.key} template={template} index={index} />;
}

function renderDroppable(
  templates: TableColumnWithKey[],
  placeholderProps?: PlaceholderProps
) {
  return function (
    provided: DroppableProvided,
    snapshot: DroppableStateSnapshot
  ) {
    return (
      <Container ref={provided.innerRef} {...provided.droppableProps}>
        {templates.map(renderDraggable)}
        {provided.placeholder}
        {placeholderProps && snapshot.isDraggingOver && (
          <Placeholder {...placeholderProps} />
        )}
      </Container>
    );
  };
}

export function Droppable({ columns }: DroppableViewProps) {
  const {
    handleDragEnd,
    handleDragStart,
    handleDragUpdate,
    placeholderProps,
    renderTemplates,
    onUpdateSelected,
    onSave,
    onClose,
  } = useDroppableController(columns);

  const getContainerForClose = useRef(
    () => document.getElementById('theme') as any
  );

  const renderClone = useCallback<DraggableChildrenFn>(
    (provided, snapshot, rubric) => (
      <Template
        key={renderTemplates[rubric.source.index].key}
        index={rubric.source.index}
        template={renderTemplates[rubric.source.index]}
        provided={provided}
        snapshot={snapshot}
      />
    ),
    [renderTemplates]
  );

  return (
    <GridContainer gridTemplateRows="minmax(0, 1fr) max-content">
      <ContentContainer id="dropable-container">
        <ActionContext.Provider value={onUpdateSelected}>
          <DragDropContext
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
            onDragUpdate={handleDragUpdate}
          >
            <UIDropable
              droppableId="droppable"
              getContainerForClone={getContainerForClose.current}
              renderClone={renderClone}
            >
              {renderDroppable(renderTemplates, placeholderProps)}
            </UIDropable>
          </DragDropContext>
        </ActionContext.Provider>
      </ContentContainer>
      <ActionContainer gridTemplateColumns="repeat(2, 1fr)" gap="20">
        <SecondaryButton text="Cancel" onClick={onClose} />
        <PrimaryButton text="Save" onClick={onSave} />
      </ActionContainer>
    </GridContainer>
  );
}
