import { gql } from '@apollo/client';

export const CreateTransactionScoringRule = gql`
  mutation CreateTransactionScoringRule(
    $body: BodyCreateTransactionScoringRule!
  ) {
    createTransactionScoringRule(body: $body)
      @rest(
        type: "CreateTransactionScoringRule"
        path: "/tm/scoring/rules"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "TransactionScoringRule") {
        id
        client_id
        created_at
        description
        execution_klass
        name
        params
        score_if_no_rules
        score_is_exact_value
        scoring_matrix_id
        updated_at
        weight
      }
      success
    }
  }
`;

export const UpdateTransactionScoringRule = gql`
  mutation UpdateTransactionScoringRule(
    $id: String!
    $body: BodyUpdateTransactionScoringRule!
  ) {
    updateTransactionScoringRule(id: $id, body: $body)
      @rest(
        type: "UpdateTransactionScoringRule"
        path: "/tm/scoring/rules/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "TransactionScoringRule") {
        id
        client_id
        created_at
        description
        execution_klass
        name
        params
        score_if_no_rules
        score_is_exact_value
        scoring_matrix_id
        updated_at
        weight
      }
      success
    }
  }
`;

export const DeleteTransactionScoringRule = gql`
  mutation DeleteTransactionScoringRule($id: String!) {
    deleteTransactionScoringRule(id: $id)
      @rest(
        type: "DeleteTransactionScoringRule"
        path: "/tm/scoring/rules/{args.id}"
        method: "DELETE"
      ) {
      success
    }
  }
`;
