import { PropsWithChildren } from 'react';
import { AIDLoader } from '../AIDLoader';
import { PermissionsContext } from './context';
import { usePermissionsProviderController } from './controller';

export function PermissionsProvider({ children }: PropsWithChildren<{}>) {
  const { context, loading } = usePermissionsProviderController();

  if (loading) {
    return <AIDLoader />;
  }

  return (
    <PermissionsContext.Provider value={context}>
      {children}
    </PermissionsContext.Provider>
  );
}
