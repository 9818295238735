import { getColor } from '@aid-module/ui';
import styled from '@emotion/styled/macro';
import { NavLink } from 'react-router-dom';
import { DefaultElement } from './components/LinkBody/styles';

export const Link = styled(NavLink)`
  text-decoration: none;

  &.active {
    & > div {
      & > span {
        font-weight: 500;
      }

      background: ${getColor('main-deep-2')};
    }

    & ${DefaultElement} {
      display: grid;
    }
  }

  &:hover {
    & > div {
      background-color: ${getColor('main-deep-2')};
    }
  }
`;

export const ExternalLink = styled.a`
  text-decoration: none;
`;
