import gql from 'graphql-tag';
import { CA_SEARCH_FRAGMENT } from '../../../fragment/checkup';

export const CaSearches = gql`
  ${CA_SEARCH_FRAGMENT}
  query CaSearches($ca_search_id: String!) {
    caSearches(ca_search_id: $ca_search_id)
      @rest(
        type: "ResponseCaSearches"
        path: "/checkups/client/ca/searches/{args.ca_search_id}"
      ) {
      data @type(name: "CaSearch") {
        ...CaSearchFragment
      }
      success
    }
  }
`;
