import gql from 'graphql-tag';
import {
  ACCOUNT_CLIENT_FRAGMENT,
  ACCOUNT_FRAGMENT,
} from '../../../fragment/admin/accounts';

export const AccountUpdate = gql`
  ${ACCOUNT_FRAGMENT}
  ${ACCOUNT_CLIENT_FRAGMENT}
  mutation AccountUpdate($id: String!, $body: BodyAccountUpdate) {
    updateAccount(id: $id, body: $body)
      @rest(
        type: "AccountUpdate"
        path: "/core/admin/accounts/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "Account") {
        accounts_clients @type(name: "AccountClient") {
          ...AccountClientFragment
        }
        ...AccountFragment
      }
      success
      errors
    }
  }
`;
