import { ContentLoader, GridContainer } from '@aid-module/ui';

export function Fallback() {
  return (
    <GridContainer gap="40">
      <GridContainer gap="20">
        <ContentLoader width="100%" height="26px">
          <rect rx="4" ry="4" width="100%" height="26px" />
        </ContentLoader>
        <ContentLoader width="100%" height="201.59px">
          <rect rx="4" ry="4" width="100%" height="201.59px" />
        </ContentLoader>
      </GridContainer>
      <GridContainer gap="40" gridTemplateColumns="1fr 1fr">
        <GridContainer gap="20">
          <ContentLoader width="100%" height="26px">
            <rect rx="4" ry="4" width="100%" height="26px" />
          </ContentLoader>
          <ContentLoader width="100%" height="501px">
            <rect rx="4" ry="4" width="100%" height="501px" />
          </ContentLoader>
        </GridContainer>
        <GridContainer gap="20">
          <ContentLoader width="100%" height="26px">
            <rect rx="4" ry="4" width="100%" height="26px" />
          </ContentLoader>
          <ContentLoader width="100%" height="501px">
            <rect rx="4" ry="4" width="100%" height="501px" />
          </ContentLoader>
        </GridContainer>
      </GridContainer>
    </GridContainer>
  );
}
