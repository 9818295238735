import { forwardRef, PropsWithChildren } from 'react';
import { StyledText } from './styles';
import { TextViewProps } from '../types';

export const Text = forwardRef<
  HTMLSpanElement,
  PropsWithChildren<TextViewProps>
>(function ({ text, children, ...props }, ref) {
  return (
    <StyledText ref={ref} {...props}>
      {text || children}
    </StyledText>
  );
});
