import { ClientAvatar, IconLoader } from '@aid-module/ui';
import { Menu } from './components';
import { useClientController } from './controller';
import { Container, ShadowContainer } from './styles';
import { AnimatePresence, motion } from 'framer-motion';
import { variants } from './constants';

export function Client() {
  const {
    client,
    isMenuOpened,
    onHoverImage,
    onHoverMenu,
    onLeaveImage,
    onLeaveMenu,
  } = useClientController();

  if (client) {
    return (
      <Container>
        <Container onMouseEnter={onHoverImage} onMouseLeave={onLeaveImage}>
          <ClientAvatar client={client} fallback={<IconLoader size={40} />} />
        </Container>
        <AnimatePresence>
          {isMenuOpened && (
            <ShadowContainer
              onMouseEnter={onHoverMenu}
              onMouseLeave={onLeaveMenu}
            >
              <motion.div
                variants={variants}
                initial="initial"
                animate="visible"
                exit="exit"
              >
                <Menu onClickItem={onLeaveMenu} client={client} />
              </motion.div>
            </ShadowContainer>
          )}
        </AnimatePresence>
      </Container>
    );
  }

  return null;
}
