import gql from 'graphql-tag';
import { CLIENT_FRAGMENT } from './Client';

export const ACCOUNT_CLIENT_FRAGMENT = gql`
  ${CLIENT_FRAGMENT}
  fragment AccountClientFragment on AccountClient {
    account_id
    client @type(name: "Client") {
      ...AccClientFragment
    }
    client_id
    created_at
    id
    position_name
    role
    updated_at
  }
`;
