import { useMemo } from 'react';
import { SubmissionCounterViewProps } from '../types';

export function useSubmissionCounterController(
  count: SubmissionCounterViewProps['count']
) {
  const text = useMemo(
    () => (count > 1 ? 'Verifications' : 'Verification'),
    []
  );

  return {
    text,
  };
}
