import { FlexContainer, getSpace } from '@aid-module/ui';
import styled from '@emotion/styled';
import { EmotionIconContainerViewProps } from './types';

export const IconContainer = styled.div<EmotionIconContainerViewProps>`
  width: 16px;
  height: 16px;
  margin-left: ${getSpace('8')};
  transform: rotate(90deg);

  ${({ open }) =>
    open &&
    `
    transform: rotate(270deg);
  `}
`;

export const TitleContainer = styled(FlexContainer)`
  overflow: hidden;
`;
