import gql from 'graphql-tag';
import { CLIENT_FRAGMENT } from '../../../fragment/admin/clients';
import { META_FRAGMENT } from '../../../fragment/Meta';

export const Clients = gql`
  ${CLIENT_FRAGMENT}
  ${META_FRAGMENT}

  query Clients($page: Int, $q: String) {
    clients(page: $page, per: 20, q: $q)
      @rest(type: "ClientsResponse", path: "/core/admin/clients?{args}") {
      data @type(name: "Client") {
        ...ClientFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const Client = gql`
  ${CLIENT_FRAGMENT}
  query Client($id: String!) {
    client(id: $id)
      @rest(type: "ClientResponse", path: "/core/admin/clients/{args.id}") {
      data @type(name: "Client") {
        ...ClientFragment
      }
      success
    }
  }
`;
