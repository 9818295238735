import { Link } from '../Link';
import { MultiplePathsLinkContainer } from '../MultiplePathsLinkContainer';
import { useUsersLinkController } from './controller';

export function UsersLink() {
  const { usersPaths, usersAccesiblePath } = useUsersLinkController();

  return (
    <MultiplePathsLinkContainer paths={usersPaths}>
      <Link to={usersAccesiblePath} icon="users" text="Users" />
    </MultiplePathsLinkContainer>
  );
}
