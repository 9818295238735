import { useFilterTemplatesContext } from '../../../../../../../../../../../../../../../../../../context';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

export function useButtonController() {
  const state = useWatch({ defaultValue: {} });
  const { data } = useFilterTemplatesContext();

  const disabled = useMemo(() => {
    const values = Object.values(state);
    let disabled = true;
    for (let i = 0, count = values.length; i < count; i++) {
      if (values[i]) {
        disabled = false;
        break;
      }
    }
    return disabled;
  }, [state]);

  return {
    disabled: disabled || data?.loading || (data?.templates?.length || 0) >= 50,
  };
}
