import gql from 'graphql-tag';
import { VERIFICATION_STEP_PASSING_FRAGMENT } from '../../../fragment/verification/VerificationStepPassing';
import { CUSTOMER_VERIFICATION_FRAGMENT } from '../../../fragment/verification/';

export const CustomerVerifications = gql`
  ${VERIFICATION_STEP_PASSING_FRAGMENT}
  query CustomerVerifications(
    $verification_flow_id: String
    $page: Int
    $retention_plan_id: String
  ) {
    customerVerifications(
      verification_flow_id: $verification_flow_id
      retention_plan_id: $retention_plan_id
      page: $page
      per: 20
    )
      @rest(
        type: "ResponseCustomerVerifications"
        path: "/core/client/customer_verifications?{args}"
      ) {
      data @type(name: "CustomerVerification") {
        case_id
        redirect_url
        cases @type(name: "Case") {
          id
        }
        created_at
        customer_id
        expired
        expires_at
        first_name
        id
        last_name
        pin_code
        profile_id
        profile_name
        profiles @type(name: "Profile") {
          avatar_id
          created_at
          display_name
          email
          id
          is_whitelisted
          kind
          phone
          updated_at
        }
        started_at
        status
        updated_at
        verification_flow_id
        verification_step_passings @type(name: "VerificationStepPassing") {
          ...VerificationStepPassingFragment
        }
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const CustomerVerificationOnBoarding = gql`
  ${CUSTOMER_VERIFICATION_FRAGMENT}
  query CustomerVerificationOnBoarding($id: String) {
    customerVerificationOnBoarding(id: $id)
      @rest(
        type: "ResponseCustomerVerifications"
        path: "/core/public/customer_verifications/{args.id}"
      ) {
      data @type(name: "CustomerVerification") {
        ...CustomerVerificationFragment
      }
      success
    }
  }
`;

export const CustomerVerificationUrl = gql`
  query CustomerVerificationUrl($id: String!) {
    customerVerificationUrl(id: $id)
      @rest(
        type: "ResponseCustomerVerificationUrl"
        path: "/core/client/customer_verifications/{args.id}/url"
      ) {
      data {
        url
      }
      success
    }
  }
`;
