import { ContentLoader, GridContainer } from '@aid-module/ui';

export function Fallback() {
  return (
    <GridContainer gap="20">
      <ContentLoader width="100%" height="78">
        <rect rx={8} ry={8} width="100%" height="78" />
      </ContentLoader>
      <ContentLoader width="100%" height="66">
        <rect rx={8} ry={8} width="100%" height="66" />
      </ContentLoader>
      <GridContainer gap="14">
        <ContentLoader width="100%" height="60.2">
          <rect rx={8} ry={8} width="100%" height="60.2" />
        </ContentLoader>
        <GridContainer gap="8">
          <ContentLoader width="100%" height="56.2">
            <rect rx={8} ry={8} width="100%" height="56.2" />
          </ContentLoader>
          <GridContainer gap="8">
            <ContentLoader width="100%" height="32.2">
              <rect rx={8} ry={8} width="100%" height="32.2" />
            </ContentLoader>
            <ContentLoader width="100%" height="32.2">
              <rect rx={8} ry={8} width="100%" height="32.2" />
            </ContentLoader>
            <ContentLoader width="100%" height="32.2">
              <rect rx={8} ry={8} width="100%" height="32.2" />
            </ContentLoader>
            <ContentLoader width="100%" height="32.2">
              <rect rx={8} ry={8} width="100%" height="32.2" />
            </ContentLoader>
            <ContentLoader width="100%" height="32.2">
              <rect rx={8} ry={8} width="100%" height="32.2" />
            </ContentLoader>
          </GridContainer>
        </GridContainer>
      </GridContainer>
    </GridContainer>
  );
}
