import gql from 'graphql-tag';

export const CASE_SECTION_DEVICE_INFO_FRAGMENT = gql`
  fragment CaseSectionDeviceInfoItemFragment on CaseSectionDeviceInfoItem {
    kind
    device_info @type(name: "DeviceInfoObject") {
      account_id
      browser_info @type(name: "BrowserInfo") {
        botProbability
        browserFullVersion
        browserMajorVersion
        browserName
        device
        os
        osVersion
        userAgent
      }
      city
      country_code
      created_at
      customer_id
      device_info_doubles_count
      form_submission_id
      id
      incognito
      ip
    }
    created_at
    file_name
    file_size
    form_submission_id
    id
    updated_at
    uploaded_document_id
  }
`;
