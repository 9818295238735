import { AddComment, Update } from './components';

export function Actions() {
  return (
    <>
      <Update />
      <AddComment />
    </>
  );
}
