import { Icon } from '@aid-module/ui';
import { PinnedViewProps } from './types';
import { usePinnedController } from './controller';

export function Pinned({ notification }: PinnedViewProps) {
  const { onClick, loading, icon } = usePinnedController(notification);

  return (
    <Icon
      onClick={onClick}
      disabled={loading}
      color={'main-deep-4'}
      glyph={icon}
    />
  );
}
