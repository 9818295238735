import gql from 'graphql-tag';
import { FORM_FRAGMENT, FORM_SCREEN_FRAGMENT } from '../../../fragment/form';

export const UpdateFavorites = gql`
  ${FORM_FRAGMENT}
  ${FORM_SCREEN_FRAGMENT}
  mutation UpdateFavorites($id: String!, $body: BodyUpdateFavorites!) {
    updateFavorites(id: $id, body: $body)
      @rest(
        type: "UpdateFavorites"
        path: "/core/client/forms/{args.id}/update_favorites"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "Form") {
        ...FormFragment
        form_screens @type(name: "FormScreen") {
          ...FormScreenFragment
        }
      }
      success
    }
  }
`;
