import gql from 'graphql-tag';

export const CreateInvite = gql`
  mutation CreateInvite($body: BodyCreateInvite!) {
    createInvite(body: $body)
      @rest(
        type: "CreateInvite"
        path: "/core/client/invites"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "Invite") {
        id
        first_name
        last_name
        client_id
        created_at
        email
        message
        expires_at
        status
        updated_at
        roles @type(name: "Role") {
          id
          name
        }
      }
      success
    }
  }
`;

export const UpdateInviteState = gql`
  mutation UpdateInviteState($id: String!, $body: BodyUpdateStateInvite!) {
    updateInviteState(id: $id, body: $body)
      @rest(
        type: "UpdateStateInvite"
        path: "/core/client/invites/{args.id}/change_status"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "Invite") {
        id
        first_name
        last_name
        client_id
        created_at
        email
        message
        expires_at
        status
        updated_at
        roles @type(name: "Role") {
          id
          name
        }
      }
      success
    }
  }
`;
