import { SubscriberFunction } from './types';

export class Observer {
  private subscribers: { [key: string]: SubscriberFunction<any> } = {};

  onUpdateObserverValue<T>(key: string, value: T) {
    if (this.subscribers[key]) {
      this.subscribers[key](value);
    }
  }

  subscribe(key: string, cb: SubscriberFunction<any>) {
    this.subscribers[key] = cb;
  }

  unsubscribe(key: string, cb: SubscriberFunction<any>) {
    if (this.subscribers[key]) {
      const subscribers = this.subscribers;
      delete subscribers[key];
      this.subscribers = subscribers;
    }
  }
}
