import gql from 'graphql-tag';

export const CASE_SUMMARY_FRAGMENT = gql`
  fragment CaseSummaryFragment on CaseSummary {
    id
    body
    case_id
    case_summary_prompt_id
    client_id
    created_at
    request
    updated_at
  }
`;
