import { ElementsController } from './ElementsController';
import { InitiateElements } from './types';

enum VisibilityOptions {
  CONTAINER_HOVER = 0x01,
  CONTENT_HOVER = 0x02,
}

enum VisibilityState {
  VISIBLE = 0x01,
  HIDDEN = 0x02,
}

export class HTMLController {
  protected elementsController: ElementsController;

  onUpdateOppened?: (next: boolean) => void;

  protected get containerStyle() {
    return this.elementsController.refs.containerRef.style;
  }

  private _canShow = true;

  public get canShow() {
    return this._canShow;
  }

  public set canShow(next: boolean) {
    if (this.isOpenned && !next) {
      this.isOpenned = false;
      this._canShow = next;
      return;
    } else if (!this._canShow && next) {
      this._canShow = next;
      if (
        (this.visibilityOptions & VisibilityOptions.CONTAINER_HOVER ||
          this.visibilityOptions & VisibilityOptions.CONTENT_HOVER) &&
        !this.isOpenned
      ) {
        this.isOpenned = true;
      }
      return;
    } else {
      this._canShow = next;
      return;
    }
  }

  private _isOpenned = false;

  protected get isOpenned() {
    return this._isOpenned;
  }

  private set isOpenned(next: boolean) {
    if (this.canShow) {
      if (this._isOpenned !== next) {
        this.visibility = next
          ? VisibilityState.VISIBLE
          : VisibilityState.HIDDEN;
        this._isOpenned = next;
      }
    }
  }

  private _visibilityOptions: number = 0x00;

  private get visibilityOptions() {
    return this._visibilityOptions;
  }

  private set visibilityOptions(next: number) {
    if (
      (next & VisibilityOptions.CONTAINER_HOVER ||
        next & VisibilityOptions.CONTENT_HOVER) &&
      !this.isOpenned
    ) {
      this.isOpenned = true;
    } else if (
      this.isOpenned &&
      !(next & VisibilityOptions.CONTAINER_HOVER) &&
      !(next & VisibilityOptions.CONTENT_HOVER)
    ) {
      this.isOpenned = false;
    }
    this._visibilityOptions = next;
  }

  private _visibility = VisibilityState.HIDDEN;

  get visibility() {
    return this._visibility;
  }

  set visibility(visibility: number) {
    this.elementsController.refs.containerRef.style.display =
      visibility & VisibilityState.VISIBLE ? 'flex' : 'none';
    if (visibility & VisibilityState.VISIBLE) {
      this.updateOptions(
        this.elementsController.initiateOptions,
        this.elementsController.containerOptions
      );
    }
  }

  constructor(refs: InitiateElements) {
    this.elementsController = new ElementsController(refs);
    this.setListeners();
  }

  private onContainerMouseEnter = () => {
    this.visibilityOptions |= VisibilityOptions.CONTAINER_HOVER;
  };

  private onContentMouseEnter = () => {
    this.visibilityOptions |= VisibilityOptions.CONTENT_HOVER;
  };

  private onContainerMouseLeave = () => {
    this.visibilityOptions &= ~VisibilityOptions.CONTAINER_HOVER;
  };

  private onContentMouseLeave = () => {
    this.visibilityOptions &= ~VisibilityOptions.CONTENT_HOVER;
  };

  private setListeners() {
    this.elementsController.refs.initiateRef.addEventListener(
      'mouseenter',
      this.onContainerMouseEnter
    );
    this.elementsController.refs.containerRef.addEventListener(
      'mouseenter',
      this.onContentMouseEnter
    );
    (
      this.elementsController.refs.checkedRef ||
      this.elementsController.refs.initiateRef
    ).addEventListener('mouseleave', this.onContainerMouseLeave);
    this.elementsController.refs.containerRef.addEventListener(
      'mouseleave',
      this.onContentMouseLeave
    );
  }

  destroy() {
    this.elementsController.refs.initiateRef.removeEventListener(
      'mouseenter',
      this.onContainerMouseEnter
    );
    this.elementsController.refs.containerRef.removeEventListener(
      'mouseenter',
      this.onContentMouseEnter
    );
    (
      this.elementsController.refs.checkedRef ||
      this.elementsController.refs.initiateRef
    ).removeEventListener('mouseleave', this.onContainerMouseLeave);
    this.elementsController.refs.containerRef.removeEventListener(
      'mouseleave',
      this.onContentMouseLeave
    );
    this.elementsController.destroy();
    (this.elementsController.refs as any) = undefined;
    (this.elementsController as any) = undefined;
  }

  protected updateOptions(
    _initiateOptions: DOMRect,
    _containerOptions: DOMRect
  ) {
    throw Error('need implement method');
  }
}
