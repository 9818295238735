import { DraggableViewProps } from './types';
import {
  DraggableProvided,
  DraggableStateSnapshot,
  Draggable as UIDraggable,
} from 'react-beautiful-dnd';
import { Template } from './components';

function renderDraggable(template: DraggableViewProps['template']) {
  return function (
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot
  ) {
    return (
      <Template
        key={template.id}
        template={template}
        provided={provided}
        snapshot={snapshot}
      />
    );
  };
}

export function Draggable({ template, index }: DraggableViewProps) {
  return (
    <UIDraggable draggableId={template.id} index={index}>
      {renderDraggable(template)}
    </UIDraggable>
  );
}
