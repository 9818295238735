import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';

const validationSchema = object().shape({
  name: string()
    .nullable()
    .max(35, 'Must be to 35 characters long')
    .required('Required'),
});

export const resolver = yupResolver(validationSchema);
