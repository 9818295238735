import { ApiCard, SelectOption } from '@aid-module/ui';
import { PropsWithChildren, createContext, useContext } from 'react';
import { useDictionaryMethodsContextController } from './controller';

const DictionaryMethodsContext = createContext<Record<string, SelectOption>>(
  {}
);

export function useDictionaryMethodsContext() {
  const context = useContext(DictionaryMethodsContext);

  return context;
}

export function DictionaryMethodsContextProvider({
  children,
  fallback,
}: PropsWithChildren<{ fallback: JSX.Element | null | undefined }>) {
  const { elements, loading, error } = useDictionaryMethodsContextController();
  return (
    <ApiCard loading={loading} error={error} fallback={fallback}>
      {elements && !loading && (
        <DictionaryMethodsContext.Provider value={elements}>
          {children}
        </DictionaryMethodsContext.Provider>
      )}
    </ApiCard>
  );
}
