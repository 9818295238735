import { useEffect, useLayoutEffect, useState } from 'react';
import { ClientController } from '../class';
import { useNavigate, useParams } from 'react-router-dom';
import NavigateLink from '../../client/NavigateLink';
import HandlerLink from '../../client/HandlerLink';
import { Ping } from '../../query';
import { ApiClientProviderViewProps } from '../types';
import { CustomErrorLink } from '../../client/ErrorLink';

const CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID;

export function useProviderController(
  showError: ApiClientProviderViewProps['showError']
) {
  const { client_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [destroy, setDestroy] = useState(false);
  const [timerOptions, setTimerOptions] = useState<
    { time: number } | undefined
  >(undefined);
  const [client, setClient] = useState(ClientController.appoloClient);
  const navigate = useNavigate();

  useEffect(() => {
    NavigateLink.updateNavigateFunction(navigate);
  }, [navigate]);

  useLayoutEffect(() => {
    if (showError) {
      CustomErrorLink.showError = showError;
    }
    HandlerLink.addHandlers();
    return () => {
      HandlerLink.removeHandlers();
    };
  }, []);

  useEffect(() => {
    ClientController.changeClientId(client_id);
  }, [client_id]);

  useEffect(() => {
    HandlerLink.needUpdateToken = function () {
      ClientController.appoloClient.query({
        query: Ping,
        fetchPolicy: 'no-cache',
      });
    };
    ClientController.onUpdateClient = (client) => {
      setClient(client);
    };
    ClientController.onUpdateLoading = (next) => {
      setLoading(next);
    };
    ClientController.onDestroy = () => {
      setDestroy(true);
      window.location.href = `${process.env.REACT_APP_API_HOST}/auth/logout?redirect_uri=${process.env.REACT_APP_API_HOST}/auth/login?redirect_uri=${window.location.href}&client_id=${CLIENT_ID}`;
    };
    ClientController.onUpdateTick = (type, time) => {
      if (type === 'tick') {
        setTimerOptions({ time });
      } else {
        setTimerOptions(undefined);
      }
    };
  }, []);

  return {
    client,
    loading,
    timerOptions,
    destroy,
  };
}
