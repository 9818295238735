import { gql } from '@apollo/client';
import { SUBMISSION_SCORING_FRAGMENT } from '../../../fragment/scoring';

export const UpdateScoringRule = gql`
  mutation UpdateScoringRule($id: String!, $body: BodyUpdateScoringRule!) {
    updateScoringRule(id: $id, body: $body)
      @rest(
        type: "UpdateScoringRule"
        path: "/scoring/rules/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "FieldScoring") {
        coefficient
        expression
        created_at
        form_field_id
        id
        kind
        name
        rule_select_values
        state
        updated_at
        value
      }
      success
    }
  }
`;

export const UpdateSubmissionScoring = gql`
  ${SUBMISSION_SCORING_FRAGMENT}
  mutation UpdateSubmissionScoring(
    $id: String!
    $body: BodyUpdateSubmissionScoring!
  ) {
    updateSubmissionScoring(id: $id, body: $body)
      @rest(
        type: "UpdateSubmissionScoring"
        path: "/scoring/submissions/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "SubmissionScoring") {
        ...SubmissionScoringFragment
      }
      success
    }
  }
`;

export const UpdateFieldSubmissionScoring = gql`
  ${SUBMISSION_SCORING_FRAGMENT}
  mutation UpdateFieldSubmissionScoring(
    $id: String!
    $body: BodyUpdateFieldSubmissionScoring!
  ) {
    updateFieldSubmissionScoring(id: $id, body: $body)
      @rest(
        type: "UpdateFieldSubmissionScoring"
        path: "/scoring/field_submissions/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "ScoringField") {
        audit_logs @type(name: "ScoringAuditLog") {
          ...ScoringAuditLogFragment
        }
        created_at
        field_submission_id
        id
        is_changed_manually
        name
        rule @type(name: "FieldScoring") {
          coefficient
          expression
          created_at
          form_field_id
          id
          kind
          name
          rule_select_values
          state
          updated_at
          value
        }
        updated_at
        value
      }
      success
    }
  }
`;

export const RecalculateSubmissionScoring = gql`
  ${SUBMISSION_SCORING_FRAGMENT}
  mutation RecalculateSubmissionScoring($id: String!) {
    recalculateSubmissionScoring(id: $id, body: ".")
      @rest(
        type: "RecalculateSubmissionScoring"
        path: "/scoring/submissions/{args.id}/recalculate"
        bodyKey: "body"
        method: "PUT"
        bodySerializer: "empty"
      ) {
      data @type(name: "SubmissionScoring") {
        ...SubmissionScoringFragment
      }
      success
    }
  }
`;

export const ResolveConflictsSubmissionScoring = gql`
  ${SUBMISSION_SCORING_FRAGMENT}
  mutation ResolveConflictsSubmissionScoring(
    $id: String!
    $body: BodyResolveConflictsScoring!
  ) {
    resolveConflictsSubmissionScoring(id: $id, body: $body)
      @rest(
        type: "ResolveConflictsSubmissionScoring"
        path: "/scoring/submissions/{args.id}/solve_conflict"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "SubmissionScoring") {
        ...SubmissionScoringFragment
      }
      success
    }
  }
`;
