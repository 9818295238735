import {
  Card,
  Collapsed,
  GridContainer,
  List,
  ListLoader,
  Text,
} from '@aid-module/ui';
import { useMediaMentionsController } from './controller';

export function MediaMentions() {
  const { hit, config } = useMediaMentionsController();

  if (config.length > 0) {
    return (
      <Collapsed openned={true} title="Adverse Media">
        <GridContainer gap="10">
          <Text text="Media Mentions" size={16} weight="Bold" />
          <Card background="main-deep-1" padding="XS">
            <List
              data={hit}
              config={config}
              fallback={<ListLoader count={config?.length} />}
            />
          </Card>
        </GridContainer>
      </Collapsed>
    );
  }

  return null;
}
