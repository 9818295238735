import gql from 'graphql-tag';

export const PricingClientTransactions = gql`
  query PricingClientTransactions($page: Int, $per: Int) {
    pricingClientTransactions(page: $page, per: $per)
      @rest(
        type: "ResponsePricingClientTransactions"
        path: "/billing/client/transactions?{args}"
      ) {
      data @type(name: "ClientTransaction") {
        id
        account_id
        amount
        client_id
        created_at
        kind
        status
        subscription_feature_id
        subscription_id
        updated_at
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;
