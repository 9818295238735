import { dissmiss, showPromiseToast, showToast } from '@aid-module/ui';
import {
  CreateExportRequest,
  DownloadDocument,
  ExportRequest,
} from '@aid-package/api';
import { TYPES } from '@aid-package/api';
import { useCallback, useEffect, useRef, useState } from 'react';
import { usePDFExportContextProviderModel } from '../model';
import { CodeType } from '../types';

export const API_HOST = process.env.API_HOST || process.env.REACT_APP_API_HOST;

export function usePDFExportContextProviderController() {
  const { templates, client } = usePDFExportContextProviderModel();
  const [isExporting, setIsExporting] = useState(false);
  const currentInterval = useRef<NodeJS.Timer>();
  const currentToast = useRef<number | string>();

  const startListener = useCallback(
    (id: string) =>
      new Promise<void>((resolve, reject) => {
        let time = 0;
        currentInterval.current = setInterval(async () => {
          if (++time === 18) {
            clearInterval(currentInterval.current);
            setIsExporting(false);
            reject('warning');
          }
          const res = await client.query<TYPES.ExportRequestQuery>({
            query: ExportRequest,
            variables: {
              id: id,
            },
            fetchPolicy: 'network-only',
          });
          if (
            res.data?.exportRequest?.data?.execution_status === 'failed' ||
            res.data?.exportRequest?.data?.export_sub_requests?.[0]
              ?.execution_status === 'failed'
          ) {
            clearInterval(currentInterval.current);
            setIsExporting(false);
            reject();
          }
          if (
            res.data?.exportRequest?.data?.execution_status === 'completed' &&
            res.data?.exportRequest?.data?.uploaded_document_id
          ) {
            clearInterval(currentInterval.current);
            const url = `${API_HOST}/documents/download/${res.data.exportRequest.data.uploaded_document_id}/download`;
            const blob = await client.query<
              TYPES.DownloadDocumentQuery,
              TYPES.DownloadDocumentQueryVariables
            >({
              query: DownloadDocument,
              variables: {
                url,
              },
            });
            if (blob?.data?.downloadDocument) {
              setIsExporting(false);
              const linkHref = URL.createObjectURL(
                blob?.data?.downloadDocument
              );
              const link = document.createElement('a');
              link.href = linkHref;
              link.setAttribute('download', '');
              document.body.appendChild(link);
              link.addEventListener('click', (e) => {
                e.stopPropagation();
              });
              link.click();
              link.remove();
              URL.revokeObjectURL(linkHref);
              resolve();
            } else {
              return reject();
            }
          }
        }, 5000);
      }),
    [client]
  );

  useEffect(() => {
    if (currentInterval.current) {
      clearInterval(currentInterval.current);
    }
    if (currentToast.current) {
      dissmiss(currentToast.current);
    }
    localStorage.removeItem('pdf_export');
  }, [client]);

  const sendCreate = useCallback(
    (
      template: TYPES.ExportTemplatesQuery['exportTemplates']['data'][0],
      filters: any,
      meta?: any
    ) => {
      return () =>
        new Promise<void>(async (resolve, reject) => {
          try {
            const request = await client.mutate<
              TYPES.CreateExportRequestMutation,
              TYPES.CreateExportRequestMutationVariables
            >({
              mutation: CreateExportRequest,
              variables: {
                body: {
                  export_request: {
                    export_template_id: template.id,
                    filters,
                    meta,
                  },
                },
              },
            });
            const id = request.data?.createExportRequest.data?.id;
            if (id) {
              try {
                await startListener(id);
                resolve();
              } catch (e) {
                setIsExporting(false);
                reject(e);
              }
            } else {
              setIsExporting(false);
              return reject();
            }
          } catch (e) {
            setIsExporting(false);
            return reject(e);
          }
        });
    },
    [client]
  );

  const downloadByCode = useCallback(
    (code: CodeType, filters: any, meta?: any) => {
      if (templates?.[code]) {
        setIsExporting((prev) => {
          if (!prev) {
            currentToast.current = showPromiseToast({
              promise: sendCreate(templates[code], filters, meta),
              defaultMessage: 'PDF is being generated, please wait...',
              successMessage:
                'PDF successfully created. It will be downloaded automatically',
              errorMessage: 'Failed to load document',
              warningMessage:
                'Looks like your report is being generated longer than expected. You can check Export Requests page later to find it',
            });
            return true;
          }
          return prev;
        });
      } else {
        showToast({ type: 'error', message: 'Not find template by code' });
      }
    },
    [templates, sendCreate]
  );

  return { downloadByCode, isExporting };
}
