import { gql } from '@apollo/client';

export const UpdateCaseScoringRule = gql`
  mutation UpdateCaseScoringRule(
    $id: String!
    $body: BodyUpdateCaseScoringRule!
  ) {
    updateCaseScoringRule(id: $id, body: $body)
    @rest(
      type: "UpdateCaseScoringRule"
      path: "/scoring/case_rules/{args.id}"
      bodyKey: "body"
      method: "PUT"
    ) {
      data @type(name: "CaseScoringRule") {
        coefficient
        enabled
        id
        subject_class
        subject_kind
      }
      success
    }
  }
`;

export const UploadCsvScoringRule = gql`
  mutation UploadCsvScoringRule($id: String!, $body: UploadCsvForScoringRule!) {
    uploadCsvScoringRule(id: $id, body: $body)
    @rest(
      type: "UploadCsvScoringRule"
      path: "/scoring/rules/{args.id}/expression_by_csv"
      bodyKey: "body"
      method: "PATCH"
    ) {
      data @type(name: "FieldScoring") {
        coefficient
        expression
        created_at
        form_field_id
        id
        kind
        name
        rule_select_values
        state
        updated_at
        value
      }
      success
    }
  }
`;

export const UpdateFormScoringModel = gql`
  mutation UpdateFormScoringModel(
    $id: String!
    $body: BodyUpdateScoringModel!
  ) {
    updateFormScoringModel(id: $id, body: $body)
    @rest(
      type: "UpdateFormScoringModel"
      path: "/scoring/models/{args.id}"
      bodyKey: "body"
      method: "PUT"
    ) {
      data @type(name: "FormScoringModel") {
        created_at
        form_configuration_id
        id
        name
        se_coefficient
        updated_at
        rules @type(name: "FieldScoring") {
          coefficient
          expression
          created_at
          form_field_id
          id
          kind
          name
          rule_select_values
          state
          updated_at
          value
        }
      }
      success
    }
  }
`;
