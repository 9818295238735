import { isMultiValue } from '../../Veriables/types';
import {
  VariablesStateParams,
  VariablesFilters,
} from '../../Veriables/controller/types';
import { ParseVariablesParams } from '../types';
import dayjs from 'dayjs';

function getSearch(search?: VariablesStateParams['q']) {
  if (search) {
    if (typeof search === 'string' || typeof search === 'boolean') {
      return { q: search };
    }
    return search;
  }
  return {};
}

function getDateInUTC(value: string) {
  if (dayjs(value).isValid()) {
    const utcDate = Date.parse(value);
    const offsetTimeZone = new Date().getTimezoneOffset() * 60000;
    return new Date(utcDate - offsetTimeZone).toISOString();
  } else {
    return '';
  }
}

export function parseFilters(variables: VariablesStateParams) {
  const { q, sort, filters, staticFilters, filterTemplate } = variables;
  if (filterTemplate) {
    const keys = Object.keys(filterTemplate?.filters || {}).reduce<
      Record<string, boolean>
    >((acc, key) => ({ ...acc, [key]: true }), {});
    const nextStaticFilters = Object.entries(
      staticFilters || {}
    ).reduce<VariablesFilters>((acc, filter) => {
      const [key, value] = filter;
      if (!keys[key]) {
        return { ...acc, [key]: value };
      }
      return acc;
    }, {});

    return {
      tab_registry_id: filterTemplate.id,
      ...nextStaticFilters,
      ...(sort || {}),
      ...getSearch(q),
    };
  }
  return {
    ...(staticFilters || {}),
    ...(filters || {}),
    ...(sort || {}),
    ...getSearch(q),
  };
}

export function defaultParseVariables(
  variables: VariablesStateParams,
  parseVariables?: ParseVariablesParams
) {
  const parsedObject = parseFilters(variables);
  return Object.entries(parsedObject).reduce<{
    [key: string]: string | boolean | string[];
  }>((acc, item) => {
    const [key, value] = item;
    const callback = parseVariables?.[key];
    if (callback) {
      const [parsedKey, parsedValue] = callback(value);
      return { ...acc, [parsedKey]: parsedValue };
    }
    if (!value || (Array.isArray(value) && value.length === 0)) {
      return acc;
    }
    if (typeof value === 'object') {
      if (isMultiValue(value)) {
        return { ...acc, [key]: value.map((option) => option.value) };
      }
      if (Array.isArray(value)) {
        return { ...acc, [key]: value };
      }
      return { ...acc, [key]: value.value };
    }
    if (
      typeof value === 'string' &&
      isNaN(Number(value)) &&
      dayjs(value).isValid()
    ) {
      return { ...acc, [key]: getDateInUTC(value) };
    }
    return { ...acc, [key]: value };
  }, {});
}
