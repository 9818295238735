import { GridContainer } from '../../../../../../../containers';
import { AutomaticTooltip } from '../../../../../../AutomaticTooltip';
import { AutomaticControllerTooltipInitiateView } from '../../../../../../AutomaticTooltip/types';
import { ActionButtonLoader } from '../../../../../../../shared';
import { ActionButton } from '../../../../../../Button';
import { FilterValues } from '../FilterValues';
import { useFiltersButtonController } from './controller';
import { FiltersButtonViewProps } from './types';

function ExampleButton({
  initiateRef,
  selected,
  toggleShowFilters,
  count,
}: AutomaticControllerTooltipInitiateView &
  FiltersButtonViewProps & { count?: number }) {
  return (
    <GridContainer gridTemplateRows="max-content" ref={initiateRef}>
      <ActionButton
        label="Filters"
        icon="filter"
        selected={selected}
        iconSide="left"
        onClick={toggleShowFilters}
        count={count}
        fallback={<ActionButtonLoader width={105.22} height={32.2} />}
      />
    </GridContainer>
  );
}

export function FiltersButton(props: FiltersButtonViewProps) {
  const { count, isVisible } = useFiltersButtonController();

  if (isVisible) {
    return (
      <AutomaticTooltip
        canShow={!props.selected && !!count}
        pattern="bottom,left,right,top"
        view={<ExampleButton {...props} count={count} />}
      >
        <FilterValues />
      </AutomaticTooltip>
    );
  }

  return null;
}
