import { object, string, boolean, array, number, ArraySchema } from 'yup';
import { UnionField } from './types';
import { SelectOption } from '@aid-module/ui';

export function getFieldValidationObjectByField(field: UnionField) {
  switch (field.type) {
    case 'string': {
      if (field?.validation?.pattern) {
        return string()
          .nullable()
          .test('pattern', function (this: any, value?: string | null) {
            if (
              typeof value === 'string' &&
              field?.validation?.pattern &&
              field.validation.pattern.test(value)
            ) {
              return true;
            }
            return this.createError({
              message: 'Value is not valid',
              type: 'pattern',
            });
          });
      }
      return string().nullable();
    }
    case 'array_dictionary':
    case 'array_options':
      return array().of(object()).nullable();
    case 'boolean':
      return boolean().nullable();
    case 'select_dictionary':
    case 'select_options':
    case 'select_static':
    case 'image':
      return object().nullable();
    case 'date':
      return string().nullable();
    case 'number': {
      const schema = number().nullable();
      if (typeof field?.validation?.min === 'number') {
        schema.min(
          field.validation.min,
          `Value cannot be less than ${field.validation.min}`
        );
      }
      if (typeof field?.validation?.max === 'number') {
        schema.min(
          field.validation.max,
          `Value cannot be more than ${field.validation.min}`
        );
      }
      return schema;
    }
    case 'cryptocurrency_symbol':
      return object().nullable();
  }
}

export function getValidationSchema(fields: UnionField[]) {
  const shapeObject = fields.reduce((acc, item) => {
    const { required } = item;
    const validationObjectByName = getFieldValidationObjectByField(item);

    if (!validationObjectByName) return acc;
    if (
      (item.type === 'array_dictionary' || item.type === 'array_options') &&
      required
    ) {
      return {
        ...acc,
        [item.code]: (validationObjectByName as ArraySchema<any>)
          .min(1, 'Required')
          .required('Required'),
      };
    }

    if (item.type === 'cryptocurrency_symbol') {
      return {
        ...acc,
        [item.code]: required
          ? validationObjectByName.required('Required')
          : validationObjectByName,
        blockchain: object()
          .nullable()
          .when(item.code, {
            is: (cryptocurrency_symbol: SelectOption) => {
              return cryptocurrency_symbol?.support_multiple_blockchains;
            },
            then: object().nullable().required(),
          }),
      };
    }

    return required
      ? { ...acc, [item.code]: validationObjectByName.required('Required') }
      : { ...acc, [item.code]: validationObjectByName };
  }, {});

  return object().shape(shapeObject);
}
