import { createContext, useContext } from 'react';
import { FeaturesContextParams } from './types';

export const FeaturesContext = createContext<FeaturesContextParams>({} as any);

export function useFeaturesContext() {
  const context = useContext(FeaturesContext);

  return context;
}
