import { gql } from '@apollo/client';

export const AITaskConfigs = gql`
  query AITaskConfigs(
    $page: Int
    $per: Int
    $configurable_entity_id: String
    $configurable_entity_type: String
    $target_processable_entity_type: String
  ) {
    aiTaskConfigs(
      page: $page
      per: $per
      configurable_entity_id: $configurable_entity_id
      configurable_entity_type: $configurable_entity_type
      target_processable_entity_type: $target_processable_entity_type
    )
      @rest(
        type: "ResponseAITaskConfigs"
        path: "/voodoo/ai_task_configs?{args}"
      ) {
      data @type(name: "AITaskConfig") {
        id
        kind
        client_id
        configurable_entity_id
        configurable_entity_reference_key
        configurable_entity_type
        created_at
        options
        target_processable_entity_type
        updated_at
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const AITaskConfig = gql`
  query AITaskConfig($id: String!) {
    aiTaskConfig(id: $id)
      @rest(
        type: "ResponseAITaskConfig"
        path: "/voodoo/ai_task_configs/{args.id}"
      ) {
      data @type(name: "AITaskConfig") {
        id
        kind
        client_id
        configurable_entity_id
        configurable_entity_reference_key
        configurable_entity_type
        created_at
        options
        target_processable_entity_type
        updated_at
      }
      success
    }
  }
`;
