import { useEffect, useState, useMemo } from 'react';
import { VariablesProvidersViewProps } from '../types';
import { VariablesStateParams } from './types';
import { Variables } from '../Variables';

export function useVariablesContextController(
  defaultVariables: VariablesProvidersViewProps['defaultVariables'],
  parseSort: VariablesProvidersViewProps['parseSort'],
  cacheKey?: VariablesProvidersViewProps['cacheKey']
) {
  const variablesRef = useMemo(() => {
    return Variables.init(defaultVariables, cacheKey);
  }, []);
  const [variables, setVariables] = useState<VariablesStateParams>(
    variablesRef.variables
  );

  useEffect(() => {
    variablesRef.subscribe('variables', setVariables);
  }, []);
  useEffect(() => {
    variablesRef.onUpdateDefaultVariables(defaultVariables);
  }, [defaultVariables]);

  return {
    variables,
    onChangeVariables: variablesRef.onChangeVariables,
    onRemoveFilter: variablesRef.removeFilter,
    parseSort,
    defaultVariables,
  };
}
