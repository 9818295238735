import { useMemo } from 'react';
import { useFeaturesContext } from '../../features';
import { CheckFeature } from '../types';
import { getAvailabel } from '../utils';

export function useCheckAvailableFeature(
  check: CheckFeature,
  strong?: boolean
) {
  const context = useFeaturesContext();

  const canAvailabel = useMemo(
    () =>
      context.features ? getAvailabel(check, context.features, strong) : false,
    [context, check]
  );

  return { canAvailabel };
}
