import { getColor } from '../../../../utils';
import { FlexContainer } from '../../../../containers';
import styled from '@emotion/styled';

export const Container = styled(FlexContainer)`
  cursor: pointer;
  width: 24px;
  height: 24px;
  border: 1px solid ${getColor('main-deep-4')};
  border-radius: 50%;
  background-color: ${getColor('white-primary')};
`;

export const DefaultLinkContainer = styled.div`
  a {
    gap: 0;
  }
`;
