import {
  ContentLoader,
  GridContainer,
  ProfileCardLoader,
  TabsLoader,
} from '@aid-module/ui';

export function Fallback() {
  return (
    <GridContainer gap="20">
      <ProfileCardLoader />
      <ContentLoader width="100%" height="74.2">
        <rect rx={8} ry={8} width="100%" height="74.2" />
      </ContentLoader>
      <ContentLoader width="100%" height="121.39">
        <rect rx={8} ry={8} width="100%" height="121.39" />
      </ContentLoader>
      <TabsLoader />
    </GridContainer>
  );
}
