import { FormCardModal } from '@aid-module/ui';
import { Body, Button } from './components';

export function NewClientModal() {
  return (
    <FormCardModal
      button={<Button />}
      body={<Body />}
      label="CREATE BUSINESS CLIENT"
      icon="client"
    />
  );
}
