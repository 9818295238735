export const CASE_STATUSES = {
  NEW: 'new',
  READY_TO_WORK: 'ready_to_work',
  IN_WORK: 'in_work',
  REQUESTED_FOR_INFORMATION: 'requested_for_information',
  NEEDS_UPDATE: 'needs_update',
  ACCEPTED: 'accepted',
  AUTO_APPROVED: 'auto_approved',
  REJECTED: 'rejected',
  ABANDONED: 'abandoned',
  ON_HOLD: 'on_hold',
  PRE_APPROVED_FOR_REVIEW: 'pre_approved_for_review',
  NO_RESPONSE: 'no_response',
  CLOSED: 'closed',
  PRE_REJECTED: 'pre_rejected',
};
