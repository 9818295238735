import { getColor, getSpace } from '@aid-module/ui';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

export const Container = styled.div`
  background: ${getColor('main-deep-1')};
  border: 1px solid ${getColor('main-deep-2')};
`;

export const Link = styled(NavLink)`
  display: grid;
  grid-template-columns: 16px 1fr;
  gap: ${getSpace('10')};
  background: ${getColor('main-deep-1')};
  padding: ${getSpace('14')};
  text-decoration: none;
  border-radius: 4px;
  border: 1px solid ${getColor('main-deep-2')};

  span {
    color: ${getColor('main-deep-4')};
  }

  svg {
    color: ${getColor('main-deep-4')};
  }

  &.active {
    border-color: ${getColor('main-deep-4')};
    background: ${getColor('main-deep-4')};
    span {
      color: ${getColor('white-primary')};
    }

    svg {
      color: ${getColor('white-primary')};
    }
  }
`;
