import gql from 'graphql-tag';
import { CASE_CONFIG_FRAGMENT } from '../../../fragment/caseConfig/CaseConfig';

export const CaseConfig = gql`
  ${CASE_CONFIG_FRAGMENT}
  query CaseConfig($id: String!) {
    caseConfig(id: $id)
      @rest(
        type: "ResponseCaseConfig"
        path: "/core/client/case_configs/{args.id}"
      ) {
      data @type(name: "CaseConfig") {
        ...CaseConfigFragment
      }
      success
    }
  }
`;
