import gql from 'graphql-tag';

export const ProhibitedCountries = gql`
  query ProhibitedCountries(
    $page: Int
    $per: Int
    $sort_field: String
    $sort_order: String
    $with_deleted: Boolean
    $q: String
  ) {
    prohibitedCountries(
      page: $page
      per: $per
      sort_field: $sort_field
      sort_order: $sort_order
      with_deleted: $with_deleted
      q: $q
    )
      @rest(
        type: "ResponseProhibitedCountries"
        path: "/core/client/prohibited_countries?{args}"
      ) {
      data @type(name: "ProhibitedCountry") {
        id
        deleted_at
        dictionary_item @type(name: "ProhibitedCountryDictionaryItem") {
          id
          name
          value
        }
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;
