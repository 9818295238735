import { DatePicker } from '@aid-module/ui';
import { useDateToController } from './controller';
import { DateToViewProps } from './types';

export function DateTo({ value, onChange, error }: DateToViewProps) {
  const { maxDate, minDate } = useDateToController();

  return (
    <DatePicker
      label="Date to"
      value={value}
      onChange={onChange}
      error={error}
      minDate={minDate}
      maxDate={maxDate}
    />
  );
}
