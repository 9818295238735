import { createContext, useContext } from 'react';
import { DropdownButtonContextProps } from './types';

export const DropdownButtonContext = createContext<DropdownButtonContextProps>({
  closeDropdown: () => {},
});

export function useDropdownButtonContext() {
  const context = useContext(DropdownButtonContext);

  return context;
}
