import uniqueId from 'lodash.uniqueid';
import { useRef, useState } from 'react';
import { RenderedModals } from './types';
import { ModalProps } from '../ModalController/types';
import { ModalController } from '../ModalController';

export function useConfirmModalProviderController() {
  const modals = useRef<RenderedModals>({});
  const [, reRender] = useState<{}>();

  const create = useRef((options: ModalProps) => {
    const uuid = uniqueId('modal_uniq_id');
    const controller = new ModalController(uuid, options);
    modals.current[uuid] = controller;
    reRender({});
    return uuid;
  });

  const update = useRef((uuid: string, options: ModalProps) => {
    if (modals.current[uuid]) {
      modals.current[uuid].updateProps(options);
    }
  });

  const show = useRef((uuid: string) => {
    if (modals.current[uuid]) {
      return modals.current[uuid].show();
    }
    return;
  });

  const remove = useRef((uuid: string) => {
    const currentModals = { ...modals.current };
    delete currentModals[uuid];
    modals.current = currentModals;
    reRender({});
  });

  const value = useRef({
    show: show.current,
    create: create.current,
    remove: remove.current,
    update: update.current,
  });

  return { value, modals: modals.current };
}
