import gql from 'graphql-tag';
import { CUSTOMER_FRAGMENT } from '../../fragment/cm';

export const UpdateCustomer = gql`
  ${CUSTOMER_FRAGMENT}
  mutation UpdateCustomer($id: String!, $body: BodyUpdateCustomer!) {
    updateCustomer(id: $id, body: $body)
      @rest(
        type: "UpdateCustomer"
        path: "/core/client/customers/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "Customer") {
        ...CustomerFragment
      }
      success
    }
  }
`;
