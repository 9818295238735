import { FormProvider } from 'react-hook-form';
import { GridContainer } from '@aid-module/ui';
import { useCreateFormController } from './controller';
import {
  CheckupKindsSelect,
  Form,
  Actions,
  Label,
  FillFormContent,
} from './components';
import { CheckUpsCreateViewProps } from './types';
import { ContentContainer } from './styles';

export function CreateForm({ name }: CheckUpsCreateViewProps) {
  const {
    formContext,
    isCodeExist,
    loading,
    isCodePicked,
    parsedSchema,
    checkupKindCode,
  } = useCreateFormController(name);

  return (
    <FormProvider {...formContext}>
      <GridContainer
        gridTemplateRows={
          isCodeExist ? 'max-content 1fr max-content' : '1fr max-content'
        }
      >
        {isCodeExist && <Label code={checkupKindCode} />}
        <ContentContainer
          gridTemplateColumns="1fr"
          gridTemplateRows={
            !isCodeExist ? 'max-content max-content 1fr' : '1fr'
          }
        >
          {!isCodeExist && (
            <>
              <CheckupKindsSelect />
              <FillFormContent isCodePicked={!!isCodePicked} />
            </>
          )}
          {isCodePicked && (
            <Form
              parsedSchema={parsedSchema}
              checkupKindCode={checkupKindCode}
              loading={loading}
            />
          )}
        </ContentContainer>
        <Actions name={name} parsedSchema={parsedSchema} />
      </GridContainer>
    </FormProvider>
  );
}
