import {
  Card,
  GridContainer,
  PrimaryButton,
  SecondaryButton,
  Text,
} from '@aid-module/ui';
import { useTimerLogoutController } from './controller';
import { Conteiner } from './styles';
import { TimerViewProps } from './types';

export function Timer({ parsedTime }: TimerViewProps) {
  const { onContinue, onLogout, stopEvent, loading } =
    useTimerLogoutController();

  return (
    <Conteiner
      justifyContent="center"
      alignItems="center"
      onMouseMove={stopEvent}
    >
      <Card
        background="main-deep-1"
        loaderBackground="main-deep-2"
        loaderForeground="main-deep-1"
      >
        <GridContainer gap="20" gridTemplateColumns="380px">
          <GridContainer gap="20" justifyContent="center" alignItems="center">
            <Text
              text="You are about to be logged off"
              size={24}
              align="center"
              color="main-deep-4"
              weight="VeryBig"
            />
            <GridContainer gridTemplateColumns="124px" justifyContent="center">
              <Text
                text={parsedTime}
                size={60}
                color="main-deep-4"
                weight="VeryBig"
              />
            </GridContainer>
            <Text
              text="If you wish to continue working, please press Continue. Otherwise, press Log Out"
              align="center"
            />
          </GridContainer>
          <GridContainer gridTemplateColumns="repeat(2, 1fr)" gap="20">
            <SecondaryButton text="Log Out" onClick={onLogout} />
            <PrimaryButton
              text="Continue"
              disabled={loading}
              onClick={onContinue}
            />
          </GridContainer>
        </GridContainer>
      </Card>
    </Conteiner>
  );
}
