import gql from 'graphql-tag';
import { CHECKUP_FRAGMENT } from '../../../fragment/admin/checkups';

export const Checkups = gql`
  ${CHECKUP_FRAGMENT}
  query Checkups($page: Int, $q: String, $price_min: Float, $price_max: Float) {
    checkups(
      page: $page
      per: 20
      q: $q
      price_min: $price_min
      price_max: $price_max
    ) @rest(type: "ResponseCheckups", path: "/core/admin/checkups?{args}") {
      data @type(name: "Checkup") {
        ...CheckupFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const Checkup = gql`
  ${CHECKUP_FRAGMENT}
  query Checkup($id: String!) {
    checkup(id: $id)
      @rest(type: "ResponseCheckup", path: "/core/admin/checkups/{args.id}") {
      data @type(name: "Checkup") {
        ...CheckupFragment
      }
      success
    }
  }
`;
