import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useExportRequestsModel } from '../model';

export function useExportRequestsController() {
  const { countDoc, loading } = useExportRequestsModel();
  const { client_id } = useParams();

  const href = useMemo(() => {
    return `/${client_id}/profile/export_requests`;
  }, [client_id])

  return { countDoc, href, loading };
}
