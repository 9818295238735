import gql from 'graphql-tag';

export const SLA_GENERAL_FRAGMENT = gql`
  fragment SlaGeneralFragment on SlaGeneral {
    assigned_to_id
    assigned_to_name
    case_assigned_at
    case_assigned_far_at
    case_assigned_far_to_case_confirmed
    case_assigned_to_fs_submitted
    case_confirmed_at
    case_confirmed_to_case_assigned
    case_confirmed_to_case_assigned_far
    case_confirmed_to_case_rfi_sent
    case_confirmed_to_fs_submitted
    case_id
    case_purpose
    case_rfi_sent_to_case_confirmed
    case_status
    fs_submitted_at
    id
  }
`;
