import gql from 'graphql-tag';

export const DEFAULT_CLIENT_FRAGMENT = gql`
  fragment DefaultClientFragment on Client {
    file_name
    file_size
    id
    logo_id
    name
  }
`;
