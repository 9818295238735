import { useWatch } from 'react-hook-form';

export function useDateToController() {
  const date_from = useWatch({ name: 'date_from' });

  return {
    maxDate: new Date(),
    minDate: date_from ? new Date(date_from) : undefined,
  };
}
