import loadable from '@loadable/component';
import { CollapsedLoader } from '../../shared';
import { LoadableFunctionalComponent } from '../../utils';
import { PropsWithChildren } from 'react';
import {
  CollapsedHeaderActionsViewProps,
  CollapsedHeaderViewProps,
  CollapsedComponentWithHeaderViewProps,
  CollapsedComponentWithoutHeaderViewProps,
  DefaultCollapsedViewProps,
  CollapsedComponentViewProps,
} from './component/types';

export const Collapsed = loadable(() => import('./component'), {
  resolveComponent: (components) => components.CollapsedComponent,
  fallback: <CollapsedLoader />,
}) as LoadableFunctionalComponent<
  PropsWithChildren<CollapsedComponentViewProps>
>;

export type {
  CollapsedHeaderActionsViewProps,
  CollapsedHeaderViewProps,
  CollapsedComponentWithHeaderViewProps,
  CollapsedComponentWithoutHeaderViewProps,
  DefaultCollapsedViewProps,
  CollapsedComponentViewProps,
};
