import { TYPES } from '@aid-package/api';

export const prepareTabRegistry = (
  tabRegistry: TYPES.CreateOrUpdateTabRegistry
): TYPES.CreateOrUpdateTabRegistry => {
  const newMeta =
    tabRegistry?.meta &&
    Object.entries(tabRegistry?.meta).reduce((acc, [key, value]) => {
      return Array.isArray(value) && value.length === 0
        ? { ...acc, [key]: null }
        : { ...acc, [key]: value };
    }, {});
  const newFilters =
    tabRegistry?.filters &&
    Object.entries(tabRegistry?.filters).reduce((acc, [key, value]) => {
      return Array.isArray(value) && value.length === 0
        ? { ...acc, [key]: null }
        : { ...acc, [key]: value };
    }, {});
  return { ...tabRegistry, meta: newMeta, filters: newFilters };
};
