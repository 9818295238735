import gql from 'graphql-tag';

export const DataRetentionConfigs = gql`
  query DataRetentionConfigs(
    $page: Int
    $sort_field: String
    $sort_order: String
  ) {
    dataRetentionConfigs(
      page: $page
      per: 20
      sort_field: $sort_field
      sort_order: $sort_order
    )
      @rest(
        type: "ResponseDataRetentionConfigs"
        path: "/core/client/data_retention_configs?{args}"
      ) {
      data @type(name: "DataRetentionConfig") {
        client_id
        created_at
        created_by @type(name: "Account") {
          avatar_id
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        data_retention_approve
        data_storage_period_days
        id
        updated_at
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const DataRetentionPlans = gql`
  query DataRetentionPlans(
    $page: Int
    $sort_field: String
    $sort_order: String
    $object_type: String
    $object_id: String
    $state: String
    $is_manual: Boolean
    $created_by_id: String
    $q: String
  ) {
    dataRetentionPlans(
      page: $page
      per: 20
      sort_field: $sort_field
      sort_order: $sort_order
      object_type: $object_type
      object_id: $object_id
      state: $state
      is_manual: $is_manual
      created_by_id: $created_by_id
      q: $q
    )
      @rest(
        type: "ResponseDataRetentionPlans"
        path: "/core/client/data_retention_plans?{args}"
      ) {
      data @type(name: "DataRetentionPlan") {
        id
        created_at
        data_retention_config @type(name: "DataRetentionConfig") {
          client_id
          created_at
          created_by @type(name: "Account") {
            avatar_id
            email
            file_name
            file_size
            full_name
            id
            phone
          }
          data_retention_approve
          data_storage_period_days
          id
          updated_at
        }
        data_retention_config_id
        data_retention_plan_items @type(name: "DataRetentionPlanItem") {
          created_at
          data_retention_plan_id
          id
          object_id
          object_type
          service_code
          source_service
          updated_at
        }
        updated_at
        soft_delete_date
        full_delete_date
        state
        is_manual
        created_by @type(name: "Account") {
          avatar_id
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        object_counts {
          Case
          Profile
          Customer
          CustomerVerification
          FormSubmission
          CustomerDocument
          Checkup
          Document
        }
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;
