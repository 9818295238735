import { AutomaticTooltip } from '../../../../../../AutomaticTooltip';
import { Text } from '../../../../../../Text';
import { PrimaryButton } from '../../../../../../Button';
import { CreateViewProps, ViewProps } from './types';

function View({
  initiateRef,
  disabled,
  createLabel,
  onClickCreate,
}: ViewProps) {
  return (
    <div ref={initiateRef}>
      <PrimaryButton
        disabled={disabled}
        icon="plus"
        text={createLabel || ''}
        onClick={onClickCreate}
      />
    </div>
  );
}

export function Create({
  canCreatable,
  createLabel,
  createDeniedText,
  onClickCreate,
  creatable,
  to,
}: CreateViewProps) {
  if (creatable && to) {
    return (
      <AutomaticTooltip
        canShow={!canCreatable}
        pattern="top,left,right,bottom"
        background="main-deep-3"
        view={
          <View
            disabled={!canCreatable}
            createLabel={createLabel}
            onClickCreate={onClickCreate}
          />
        }
      >
        <Text
          color="white-primary"
          text={
            createDeniedText ||
            'Feature is not available. Check your subscription and limits'
          }
          size={14}
        />
      </AutomaticTooltip>
    );
  }

  return null;
}
