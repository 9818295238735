import gql from 'graphql-tag';
import { IDENFY_RESULT_FRAGMENT } from '../IdenfyResult';
import { VERIFF_RESULT_FRAGMENT } from '../VeriffResult';

export const CASE_SECTION_IDENTITY_VERIFICATIONS_FRAGMENT = gql`
  ${IDENFY_RESULT_FRAGMENT}
  ${VERIFF_RESULT_FRAGMENT}
  fragment CaseSectionIdentityVerificationItemFragment on CaseSectionIdentityVerificationItem {
    created_at
    file_name
    file_size
    form_submission_id
    id
    updated_at
    uploaded_document_id
    kind
    idenfy_result @type(name: "IdenfyResult") {
      ...IdenfyResultFragment
    }
    veriff_result @type(name: "VeriffResult") {
      ...VeriffResultFragment
    }
  }
`;
