import { ExternalRefLink } from '../../../../../../../ExternalRefLink';
import { Icon } from '../../../../../../../Icon';
import { ContentContainer } from './styles';
import { useExternalRefItemController } from './controller';
import { ExternalRefItemViewProps } from './types';

export function ExternalRefItem({ externalRef }: ExternalRefItemViewProps) {
  const { iconByType } = useExternalRefItemController(externalRef?.external_entity_type);

  if (externalRef) {
    return (
      <ContentContainer
        gridTemplateColumns="max-content minmax(0, 1fr)"
        gap="12"
        alignItems="center"
        key={externalRef?.id}
      >
        <Icon color="main-deep-4" glyph={iconByType} />
        <ExternalRefLink
          weight="Bold"
          withoutIcon
          external_ref={externalRef}
          key={externalRef?.id}
          text={externalRef?.external_entity_name}
          size={12}
        />
      </ContentContainer>
    );
  }

  return null;
}
