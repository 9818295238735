import { gql } from '@apollo/client';

export const ReminderHistory = gql`
  query ReminderHistory($case_id: String!, $q: String, $page: Int) {
    reminderHistory(case_id: $case_id, page: $page, per: 20, q: $q)
      @rest(
        type: "ResponseReminderHistory"
        path: "/core/client/reminder_history?{args}"
      ) {
      data @type(name: "ReminderHistoryItem") {
        case_id
        comment
        created_at
        created_by_id
        id
        is_remindable
        remind_finish_at
        remind_start_at
        remind_cancel_at
        updated_at
        remind_is_finished
        created_by @type(name: "Account") {
          avatar_id
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        canceled_by @type(name: "Account") {
          avatar_id
          email
          file_name
          file_size
          full_name
          id
          phone
        }
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const CaseReminders = gql`
  query CaseReminders($case_id: String!, $q: String, $page: Int) {
    caseReminders(case_id: $case_id, page: $page, per: 20, q: $q)
      @rest(
        type: "ResponseCaseReminders"
        path: "/core/client/case_reminders?{args}"
      ) {
      data @type(name: "CaseReminder") {
        active
        canceled_at
        canceled_by @type(name: "Account") {
          email
          full_name
          id
          phone
        }
        canceled_by_id
        case_id
        completed_at
        created_at
        created_by @type(name: "Account") {
          email
          full_name
          id
          phone
        }
        created_by_id
        finished_at
        id
        name
        case_section_kind
        remind_is_finished
        reminder_kinds @type(name: "ReminderKind") {
          case_section_kind
          created_at
          id
          name
          repeatable
          repeatable_interval_days
          updated_at
        }
        repeatable
        repeatable_interval_days
        started_at
        updated_at
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const CaseReminderSettings = gql`
  query CaseReminderSettings(
    $active: Boolean
    $case_state: String
    $q: String
    $page: Int
  ) {
    caseReminderSettings(
      active: $active
      case_state: $case_state
      page: $page
      per: 20
      q: $q
    )
      @rest(
        type: "ResponseCaseReminderSettings"
        path: "/core/client/reminder_settings?{args}"
      ) {
      data @type(name: "ReminderSetting") {
        active
        case_state
        client_id
        id
        reminder_kind @type(name: "ReminderKind") {
          case_section_kind
          created_at
          id
          name
          repeatable
          repeatable_interval_days
          updated_at
        }
        reminder_kind_id
        score_rules {
          score_from
          score_to
        }
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const CaseReminderKinds = gql`
  query CaseReminderKinds(
    $case_section_kind: [String!]
    $q: String
    $page: Int
  ) {
    caseReminderKinds(
      case_section_kind: $case_section_kind
      page: $page
      per: 20
      q: $q
    )
      @rest(
        type: "ResponseCaseReminderKinds"
        path: "/core/client/reminder_kinds?{args}"
      ) {
      data @type(name: "ReminderKind") {
        case_section_kind
        created_at
        id
        name
        repeatable
        repeatable_interval_days
        updated_at
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;
