import { GridContainer } from '../../../containers';
import { Container } from '../../../components/Card/styles';
import { ContentLoader } from '../ContentLoader';
import { LoaderThemeProvider } from '../LoaderThemeProvider';

export function ProfileCardLoader() {
  return (
    <LoaderThemeProvider background="main-deep-2" foreground="main-deep-1">
      <Container padding="S" background="main-deep-1">
        <GridContainer gap="18" gridTemplateColumns="120px 1fr max-content">
          <ContentLoader width="120px" height="120px">
            <rect rx="8" ry="8" width="120px" height="120px" />
          </ContentLoader>
          <ContentLoader width="100%" height="120px">
            <rect rx="8" ry="8" width="100%" height="120px" />
          </ContentLoader>
        </GridContainer>
      </Container>
    </LoaderThemeProvider>
  );
}
