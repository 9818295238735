import gql from 'graphql-tag';

export const ProviderCredentialsUpdate = gql`
  mutation ProviderCredentialsUpdate(
    $id: String!
    $body: BodyCredentialsUpdate!
  ) {
    providerCredentialsUpdate(id: $id, body: $body)
      @rest(
        type: "ProviderCredentialsUpdate"
        path: "/checkups/admin/providers/{args.id}/credentials"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "ProviderCredentials") {
        id
        calculated_creds @type(name: "CalculatedCreds")
        creds @type(name: "Creds")
      }
      success
    }
  }
`;

export const ClientProviderCredentialsUpdate = gql`
  mutation ClientProviderCredentialsUpdate(
    $id: String!
    $body: BodyCredentialsUpdate!
  ) {
    clientProviderCredentialsUpdate(id: $id, body: $body)
      @rest(
        type: "ClientProviderCredentialsUpdate"
        path: "/checkups/admin/clients_providers/{args.id}/credentials"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "ClientProviderCredentials") {
        id
        calculated_creds @type(name: "CalculatedCreds")
        creds @type(name: "Creds")
      }
      success
    }
  }
`;

export const CheckupKindCredentialsUpdate = gql`
  mutation CheckupKindCredentialsUpdate(
    $id: String!
    $body: BodyCredentialsUpdate!
  ) {
    checkupKindCredentialsUpdate(id: $id, body: $body)
      @rest(
        type: "CheckupKindCredentialsUpdate"
        path: "/checkups/admin/checkup_kinds/{args.id}/credentials"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "CheckupKindCredentials") {
        id
        calculated_creds @type(name: "CalculatedCreds")
        creds @type(name: "Creds")
      }
      success
    }
  }
`;

export const ClientCheckupKindCredentialsUpdate = gql`
  mutation ClientCheckupKindCredentialsUpdate(
    $id: String!
    $body: BodyCredentialsUpdate!
  ) {
    clientCheckupKindCredentialsUpdate(id: $id, body: $body)
      @rest(
        type: "ClientCheckupKindCredentialsUpdate"
        path: "/checkups/admin/checkup_kinds_clients/{args.id}/credentials"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "ClientCheckupKindCredentials") {
        id
        calculated_creds @type(name: "CalculatedCreds")
        creds @type(name: "Creds")
      }
      success
    }
  }
`;
