import { gql } from '@apollo/client';

export const TransactionScoringRules = gql`
  query TransactionScoringRules(
    $per: Int
    $page: Int
    $scoring_matrix_id: String!
  ) {
    transactionScoringRules(
      per: $per
      page: $page
      scoring_matrix_id: $scoring_matrix_id
    )
      @rest(
        type: "ResponseTransactionScoringRules"
        path: "/tm/scoring/rules?{args}"
      ) {
      data @type(name: "TransactionScoringRule") {
        id
        client_id
        created_at
        description
        execution_klass
        name
        params
        score_if_no_rules
        score_is_exact_value
        scoring_matrix_id
        updated_at
        weight
      }
      meta {
        count
        page
        items
        pages
      }
      success
    }
  }
`;
