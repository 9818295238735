import { HTMLController } from './HTMLController';
import { InitiateElements, Pattern } from './types';

export class SupportController extends HTMLController {
  private pattern: Pattern;

  private supportConstants = {
    padding: 4,
  };

  constructor(pattern: Pattern | undefined, refs: InitiateElements) {
    super(refs);
    this.elementsController.onUpdateOptions = this.onUpdateOptions;
    this.pattern = pattern || 'top,left,right,bottom';
  }

  onUpdateOptions = (initiateOptions: DOMRect, containerOptions: DOMRect) => {
    if (this.isOpenned) {
      this.updateOptions(initiateOptions, containerOptions);
    }
  };

  private getHorisontalSizeForVerticalPosition(
    rect: DOMRect,
    containerRect: DOMRect
  ) {
    const width = Math.max(
      window.innerWidth,
      rect.left + window.scrollX + rect.width
    );
    const leftSideOffset = rect.left + window.scrollX;
    const rightSideOffset = Math.max(width - rect.right, 0);
    const halfRectWidth = rect.width / 2;
    const leftSideSize = leftSideOffset + halfRectWidth;
    const rightSideSize = rightSideOffset + halfRectWidth;
    const halfContent = containerRect.width / 2;
    const leftOffset = Math.max(
      Math.min(
        leftSideSize -
          halfContent -
          (halfContent - Math.min(rightSideSize, halfContent)),
        leftSideSize
      ),
      0
    );

    return {
      left: leftOffset,
    };
  }

  private getVerticalSizeForHorisontalPosition(
    rect: DOMRect,
    containerRect: DOMRect
  ) {
    const height = Math.max(window.innerHeight, rect.bottom);
    const bottomSideOffset = Math.max(
      height - rect.bottom - this.supportConstants.padding,
      0
    );
    const bottomOffset = Math.max(
      bottomSideOffset + Math.min(rect.bottom - containerRect.height, 0),
      0
    );

    return {
      bottom: bottomOffset,
    };
  }

  private top(
    initiateOptions: DOMRect,
    containerOptions: DOMRect,
    isLast?: boolean
  ) {
    if (initiateOptions.top >= containerOptions.height || isLast) {
      const horisontalStyles = this.getHorisontalSizeForVerticalPosition(
        this.elementsController.refs.initiateRef.getBoundingClientRect(),
        containerOptions
      );
      this.containerStyle.left = `${horisontalStyles.left}px`;
      const height = Math.max(window.innerHeight, initiateOptions.top);
      this.containerStyle.bottom = `${height - initiateOptions.top}px`;
      return true;
    }
    return false;
  }

  private left(
    initiateOptions: DOMRect,
    containerOptions: DOMRect,
    isLast?: boolean
  ) {
    if (initiateOptions.left >= containerOptions.width || isLast) {
      const verticalStyles = this.getVerticalSizeForHorisontalPosition(
        initiateOptions,
        containerOptions
      );
      this.containerStyle.bottom = `${verticalStyles.bottom}px`;
      const width = Math.max(window.innerWidth, initiateOptions.left);
      this.containerStyle.right = `${
        width - initiateOptions.left - window.scrollX
      }px`;
      return true;
    }
    return false;
  }

  private right(
    initiateOptions: DOMRect,
    containerOptions: DOMRect,
    isLast?: boolean
  ) {
    const width = Math.max(window.innerWidth, initiateOptions.right);
    const right = Math.max(width - initiateOptions.right - window.scrollX, 0);
    if (right >= containerOptions.width || isLast) {
      const verticalStyles = this.getVerticalSizeForHorisontalPosition(
        initiateOptions,
        containerOptions
      );
      this.containerStyle.bottom = `${verticalStyles.bottom}px`;
      this.containerStyle.left = `${
        initiateOptions.left + initiateOptions.width
      }px`;
      return true;
    }
    return false;
  }

  private bottom(
    initiateOptions: DOMRect,
    containerOptions: DOMRect,
    isLast?: boolean
  ) {
    const height = Math.max(window.innerHeight, initiateOptions.bottom);
    const bottom = Math.max(height - initiateOptions.bottom, 0);
    if (bottom >= containerOptions.height || isLast) {
      const horisontalStyles = this.getHorisontalSizeForVerticalPosition(
        this.elementsController.refs.initiateRef.getBoundingClientRect(),
        containerOptions
      );
      this.containerStyle.left = `${horisontalStyles.left}px`;
      this.containerStyle.top = `${
        initiateOptions.top + initiateOptions.height
      }px`;
      return true;
    }
    return false;
  }

  protected updateOptions(initiateOptions: DOMRect, containerOptions: DOMRect) {
    this.containerStyle.left = '';
    this.containerStyle.top = '';
    this.containerStyle.right = '';
    this.containerStyle.bottom = '';
    this.containerStyle.height = 'max-content';
    const patternArray = this.pattern.split(',') as (
      | 'top'
      | 'left'
      | 'right'
      | 'bottom'
    )[];
    for (let i = 0, count = patternArray.length; i < count; i++) {
      const options = this[patternArray[i]](
        initiateOptions,
        containerOptions,
        i === patternArray.length - 1
      );
      if (options) {
        break;
      }
    }
  }
}
