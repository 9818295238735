import styled from '@emotion/styled';

export const Container = styled.div`
  height: 100vh;
  width: 100%;

  & > div,
  & > div > div {
    height: 100vh;
    width: 100%;
  }
`;
