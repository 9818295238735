import { PropsWithChildren } from 'react';
import { AIDLoader } from '../AIDLoader';
import { RetentionContext } from './context';
import { useRetentionProviderController } from './controller';

export function RetentionProvider({ children }: PropsWithChildren<{}>) {
  const { retention, loading } = useRetentionProviderController();

  if (loading) {
    return <AIDLoader />;
  }

  return (
    <RetentionContext.Provider value={retention}>
      {children}
    </RetentionContext.Provider>
  );
}
