import { ContentLoader } from '../ContentLoader';

export function TabsLoader() {
  return (
    <ContentLoader width="100%" height="758px">
      <rect x="0" y="0" rx="3" ry="3" width="100%" height="38" />
      <rect x="0" y="58" rx="3" ry="3" width="100%" height="700" />
    </ContentLoader>
  );
}
