import { useMemo, useCallback } from 'react';
import { THEME_COLORS } from '@aid-module/ui';
import { useOnBoardingContentModel } from '../model';

export function useOnBoardingContentController() {
  const { stepStatus, customerVerification, loading } =
    useOnBoardingContentModel();

  const statusColor: keyof THEME_COLORS = useMemo(() => {
    switch (stepStatus) {
      case 'new':
        return 'warning-deep-4';
      case 'started':
        return 'main-deep-4';
      case 'completed':
        return 'success-deep-4';
      default:
        return 'warning-deep-4';
    }
  }, [stepStatus]);
  const redirectToFlow = useCallback(() => {
    if (customerVerification) {
      window.location.assign(
        `${process.env.REACT_APP_FORM_HOST}/form/verification_flows/${customerVerification?.verification_flow_id}?customer_verification_id=${customerVerification?.id}&is_onboarding=true`
      );
    }
  }, [customerVerification]);

  return {
    stepStatus,
    statusColor,
    redirectToFlow,
    loading,
    isExist: !!customerVerification,
  };
}
