import gql from 'graphql-tag';
import { CLIENT_FRAGMENT } from '../../../fragment/admin/clients';

export const ClientUpdate = gql`
  ${CLIENT_FRAGMENT}
  mutation ClientUpdate($id: String!, $body: BodyCreateOrUpdateClient!) {
    updateClient(id: $id, body: $body)
      @rest(
        type: "ClientUpdate"
        path: "/core/admin/clients/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "Client") {
        ...ClientFragment
      }
      success
    }
  }
`;

export const ClientCreate = gql`
  ${CLIENT_FRAGMENT}
  mutation ClientCreate($body: BodyCreateOrUpdateClient!) {
    createClient(body: $body)
      @rest(
        type: "ClientCreate"
        path: "/core/admin/clients"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "Client") {
        ...ClientFragment
      }
      success
    }
  }
`;
