import { ContentLoader, GridContainer } from '@aid-module/ui';
import { Container } from '../Notification/styles';
import { FallbackNotificationsViewProps } from './types';

function RenderItem(_: unknown, index: number) {
  return (
    <Container gap="6" key={index}>
      <ContentLoader width="100%" height="50.19px">
        <rect rx="8" ry="8" width="100%" height="50.19px" />
      </ContentLoader>
    </Container>
  );
}

export function FallbackNotifications({
  infinityRef,
  loadingCount,
}: FallbackNotificationsViewProps) {
  return !!loadingCount ? (
    <GridContainer
      ref={infinityRef}
      gridTemplateRows="1fr"
      justifyContent="center"
      alignItems="center"
    >
      {loadingCount ? Array.from({ length: loadingCount }, RenderItem) : null}
    </GridContainer>
  ) : null;
}
