import { DefaultLink } from '@aid-module/ui';
import { useToSettingsController } from './controller';

export function ToSettings() {
  const { href, close } = useToSettingsController();

  return (
    <DefaultLink
      lineHeight="1.3"
      onClick={close}
      text="Settings"
      weight="Medium"
      to={href}
      size={12}
    />
  );
}
