import gql from 'graphql-tag';

export const MarkAsActualAITaskResult = gql`
  mutation MarkAsActualAITaskResult(
    $id: String!
    $body: BodyMarkAsActualAITaskResult!
  ) {
    markAsActualAITaskResult(id: $id, body: $body)
      @rest(
        type: "MarkAsActualAITaskResult"
        path: "/voodoo/ai_task_results/{args.id}/mark_as_actual"
        method: "PUT"
        bodyKey: "body"
      ) {
      data @type(name: "AITaskResult") {
        id
        ai_task_id
        client_id
        comment
        created_at
        edited_at
        edited_by @type(name: "Account") {
          id
          email
          first_name
          full_name
          last_name
          region_code
          status
        }
        edited_by_id
        is_marked_as_actual
        is_marked_as_irrelevant
        status
        structured_body
        body
        updated_at
        llm_calls @type(name: "AITaskResultLLMCall") {
          response
          status
        }
        options
      }
      success
    }
  }
`;

export const MarkAsIrrelevantAITaskResult = gql`
  mutation MarkAsIrrelevantAITaskResult(
    $id: String!
    $body: BodyMarkAsIrrelevantAITaskResult!
  ) {
    markAsIrrelevantAITaskResult(id: $id, body: $body)
      @rest(
        type: "MarkAsIrrelevantAITaskResult"
        path: "/voodoo/ai_task_results/{args.id}/mark_as_irrelevant"
        method: "PUT"
        bodyKey: "body"
      ) {
      data @type(name: "AITaskResult") {
        id
        ai_task_id
        client_id
        comment
        created_at
        edited_at
        edited_by @type(name: "Account") {
          id
          email
          first_name
          full_name
          last_name
          region_code
          status
        }
        edited_by_id
        is_marked_as_actual
        is_marked_as_irrelevant
        status
        body
        structured_body
        updated_at
        llm_calls @type(name: "AITaskResultLLMCall") {
          response
          status
        }
        options
      }
      success
    }
  }
`;

export const UpdateAITaskResult = gql`
  mutation UpdateAITaskResult($id: String!, $body: BodyUpdateAITaskResult!) {
    updateAITaskResult(id: $id, body: $body)
      @rest(
        type: "UpdateAITaskResult"
        path: "/voodoo/ai_task_results/{args.id}"
        method: "PUT"
        bodyKey: "body"
      ) {
      data @type(name: "AITaskResult") {
        id
        ai_task_id
        client_id
        comment
        created_at
        edited_at
        edited_by @type(name: "Account") {
          id
          email
          first_name
          full_name
          last_name
          region_code
          status
        }
        edited_by_id
        is_marked_as_actual
        is_marked_as_irrelevant
        status
        body
        structured_body
        updated_at
        llm_calls @type(name: "AITaskResultLLMCall") {
          response
          status
        }
        options
      }
      success
    }
  }
`;
