import { getSpace } from '@aid-module/ui';
import styled from '@emotion/styled';
import { Container as ContentContainer } from '../Content/styles';
import { EmotionContainerViewProps } from './types';

export const Container = styled(ContentContainer)<EmotionContainerViewProps>`
  display: flex;
  align-items: center;

  ${({ isExist }) =>
    isExist
      ? `padding: ${getSpace('20')} ${getSpace('40')} ${getSpace('32')}`
      : `padding: 0 0 ${getSpace('32')} 0`};
`;
