import { gql } from '@apollo/client';

export const AITaskResults = gql`
  query AITaskResults($page: Int, $per: Int, $ai_task_id: String) {
    aiTaskResults(page: $page, per: $per, ai_task_id: $ai_task_id)
      @rest(
        type: "ResponseAITaskResults"
        path: "/voodoo/ai_task_results?{args}"
      ) {
      data @type(name: "AITaskResult") {
        id
        ai_task_id
        client_id
        comment
        created_at
        edited_at
        edited_by @type(name: "Account") {
          id
          email
          first_name
          full_name
          last_name
          region_code
          status
        }
        edited_by_id
        is_marked_as_actual
        is_marked_as_irrelevant
        status
        body
        structured_body
        updated_at
        llm_calls @type(name: "AITaskResultLLMCall") {
          response
          status
        }
        options
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const AITaskResult = gql`
  query AITaskResult($id: String!) {
    aiTaskResult(id: $id)
      @rest(
        type: "ResponseAITaskResult"
        path: "/voodoo/ai_task_results/{args.id}"
      ) {
      data @type(name: "AITaskResult") {
        id
        ai_task_id
        client_id
        comment
        created_at
        edited_at
        edited_by @type(name: "Account") {
          id
          email
          first_name
          full_name
          last_name
          region_code
          status
        }
        edited_by_id
        is_marked_as_actual
        is_marked_as_irrelevant
        status
        body
        structured_body
        updated_at
        llm_calls @type(name: "AITaskResultLLMCall") {
          response
          status
        }
        options
      }
      success
    }
  }
`;
