const ENV_POLTERGEIST = process.env.REACT_APP_POLTERGEIST || '';

export const poltergeists: Record<string, boolean> = ENV_POLTERGEIST.split(
  ','
).reduce((acc, id) => {
  const key = id.trim();
  if (key) {
    return {
      ...acc,
      [key]: true,
    };
  }
  return acc;
}, {});