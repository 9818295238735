import { GridContainer, PrimaryButton, Text } from '@aid-module/ui';
import { OnlineLight } from './components';
import { useProfileController } from './contoller';
import { ProfileCard } from './styles';

export function Profile() {
  const {
    email,
    fullName,
    phone,
    isCompletedStatus,
    isNewStatus,
    redirectToFlow,
    statusColor,
    isExist,
  } = useProfileController();

  return (
    <ProfileCard padding="S" background="main-deep-2">
      <GridContainer
        gridTemplateRows={`repeat(${isCompletedStatus ? 2 : 3}, max-content)`}
        gap="8"
      >
        <GridContainer
          justifyContent="space-between"
          gridTemplateColumns="repeat(2, max-content)"
        >
          <Text
            text={fullName}
            size={20}
            color="main-deep-4"
            weight="VeryBig"
          />
          {!isCompletedStatus && (
            <GridContainer
              gridTemplateColumns="repeat(2, max-content)"
              gap="8"
              alignItems="center"
            >
              <Text text="Onboarding status:" size={14} color="main-deep-3" />
              <OnlineLight color={statusColor} />
            </GridContainer>
          )}
        </GridContainer>
        <GridContainer
          gridTemplateColumns={`repeat(${phone ? 3 : 1}, max-content)`}
          gap="12"
          alignItems="center"
        >
          <Text
            text={email}
            size={14}
            color="main-deep-5"
            weight="Medium"
          />
          {phone && (
            <>
              <Text
                text="|"
                size={20}
                color="main-deep-3"
                weight="Light"
              />
              <Text
                text={phone}
                size={14}
                color="main-deep-5"
                weight="Medium"
              />
            </>
          )}
        </GridContainer>
        {!isCompletedStatus && (
          <PrimaryButton
            text={isNewStatus ? 'Start onboarding' : 'Continue'}
            onClick={redirectToFlow}
            disabled={!isExist}
          />
        )}
      </GridContainer>
    </ProfileCard>
  );
}
