import { ModalViewProps } from './types';
import { DefaultModal } from '../DefaultModal';
import { useModalController } from './controller';
import { cloneElement, PropsWithChildren, useMemo } from 'react';
import { ModalFallbackContainer } from '../FallbackContainer';
import { AIDLoader } from '@aid-package/context';

export function Modal({ body, button }: PropsWithChildren<ModalViewProps>) {
  const { isOpen, toggleOpen } = useModalController();

  const buttonComponent = useMemo(
    () => cloneElement(button, { onOpen: toggleOpen }),
    [button, toggleOpen]
  );

  const bodyComponent = useMemo(
    () => cloneElement(body, { onClose: toggleOpen }),
    [body, toggleOpen]
  );

  return (
    <>
      <DefaultModal
        isOpen={isOpen}
        onRequestClose={toggleOpen}
        fallback={
          isOpen ? (
            <ModalFallbackContainer>
              <AIDLoader />
            </ModalFallbackContainer>
          ) : undefined
        }
      >
        {isOpen && bodyComponent}
      </DefaultModal>
      {buttonComponent}
    </>
  );
}
