import gql from 'graphql-tag';

export const TRANSACTION_FRAGMENT = gql`
  fragment TransactionFragment on Transaction {
    id
    status
    kind
    feature @type(name: "FeatureTemplate") {
      id
      name
    }
    subscription @type(name: "TransactionSubscription") {
      id
      plan_name
    }
    created_at
    amount
  }
`;
