import axios from 'axios';
import Cookies from 'js-cookie';
import { NavigateFunction } from 'react-router-dom';
import { Data } from './types';

const AUTH_HOST = process.env.AUTH_HOST || process.env.REACT_APP_AUTH_HOST;
const AUTH_CLIENT_ID =
  process.env.AUTH_CLIENT_ID || process.env.REACT_APP_AUTH_CLIENT_ID;

export const CallbackOpenPathCookieName = 'callbackOpenPath';
export const TOKEN_DATA_KEYS = [
  'refresh_token',
  'access_token',
  'token',
  'expires_in',
  'scope',
  'last_refresh',
  'bfm_token',
];

export function goToAuthorizationHost() {
  if (!process.env.REACT_APP_NEW_AUTH) {
    Cookies.remove(CallbackOpenPathCookieName);
    Cookies.set(CallbackOpenPathCookieName, window.location.href);
  }
  return new Promise(() => {
    if (!process.env.REACT_APP_NEW_AUTH) {
      window.location.href = `${AUTH_HOST}/oauth/authorize?response_type=code&client_id=${AUTH_CLIENT_ID}&scope=read&redirect_uri=${window.location.origin}`;
    } else {
      window.location.replace(
        `${process.env.REACT_APP_API_HOST}/auth/logout?redirect_uri=${process.env.REACT_APP_API_HOST}/auth/login?redirect_uri=${window.location.origin}&client_id=912f3a10-95b5-4ea4-90c7-7bda8aa6d947`
      );
    }
  });
}

export function logOut() {
  TOKEN_DATA_KEYS.map((key) => localStorage.removeItem(key));
  goToAuthorizationHost();
}

export function getCurrentTokeData() {
  return TOKEN_DATA_KEYS.reduce<Data>((acc, key) => {
    return { ...acc, [key]: localStorage.getItem(key) };
  }, {});
}

export function setNewTokenData(data: Data = {}) {
  TOKEN_DATA_KEYS.forEach((key: string) => {
    if (data[key]) {
      localStorage.setItem(key, data[key]?.toString() || '');
    }
  });
}

let isRefreshingToken = false;

async function createToken() {
  const code = new URL(window.location.href).searchParams.get('code') || '';
  const bodyFormData = new FormData();
  bodyFormData.append('grant_type', 'authorization_code');
  bodyFormData.append('code', code);
  bodyFormData.append('redirect_uri', window.location.origin);
  bodyFormData.append('client_id', AUTH_CLIENT_ID || '');
  return await axios({
    method: 'post',
    url: `${AUTH_HOST}/oauth/token`,
    data: bodyFormData,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
    .then(function (response) {
      const { access_token, expires_in, refresh_token, scope, token_type } =
        response.data;
      const token = `${token_type} ${access_token}`;
      setNewTokenData({
        access_token,
        expires_in: Date.now() + expires_in * 1000,
        refresh_token,
        scope,
        token,
        last_refresh: Date.now(),
      });
      const url = Cookies.get(CallbackOpenPathCookieName) || '/define_client';
      Cookies.remove(CallbackOpenPathCookieName);
      window.location.href = url;
      return token;
    })
    .catch(async function () {
      return await goToAuthorizationHost();
    });
}

function checkNeedCreateToken() {
  const code = new URL(window.location.href).searchParams.get('code');
  return !!code;
}

function checkTokenActive() {
  const { expires_in, token } = getCurrentTokeData();
  return token && expires_in && expires_in - Date.now() > 0;
}

function needRefreshToken() {
  const { last_refresh } = getCurrentTokeData();
  if (isRefreshingToken) return false;
  return !last_refresh || Date.now() - last_refresh > 60000;
}

async function getRefreshToken(share_refresh_token?: string) {
  isRefreshingToken = true;
  const { refresh_token, token } = getCurrentTokeData();
  const bodyFormData = new FormData();
  bodyFormData.append('grant_type', 'refresh_token');
  bodyFormData.append('client_secret', '');
  bodyFormData.append(
    'refresh_token',
    share_refresh_token || refresh_token || ''
  );
  bodyFormData.append('scope', 'read');
  bodyFormData.append('client_id', AUTH_CLIENT_ID || '');
  bodyFormData.append('redirect_uri', window.location.origin);
  return await axios({
    method: 'post',
    url: `${AUTH_HOST}/oauth/token`,
    data: bodyFormData,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
    .then(function (response) {
      const {
        access_token,
        expires_in,
        refresh_token: new_refresh_token,
        scope,
        token_type,
      } = response.data;
      const new_token = `${token_type} ${access_token}`;
      setNewTokenData({
        access_token,
        expires_in: Date.now() + expires_in * 1000,
        refresh_token: new_refresh_token,
        scope,
        token: new_token,
        last_refresh: Date.now(),
      });
      isRefreshingToken = false;
      return new_token;
    })
    .catch(function () {
      isRefreshingToken = false;
      return token;
    });
}

function getShareToken() {
  const share_refresh_token = new URL(window.location.href).searchParams.get(
    'share_refresh_token'
  );
  return share_refresh_token;
}

export async function getToken(navigate?: NavigateFunction) {
  const share_refresh_token = getShareToken();

  if (!!share_refresh_token) {
    await getRefreshToken(share_refresh_token);
    if (navigate) {
      return navigate('/');
    } else {
      window.location.href = '/';
      return;
    }
  }
  if (checkNeedCreateToken()) {
    return createToken();
  }
  const { token } = getCurrentTokeData();
  if (checkTokenActive()) {
    if (needRefreshToken()) {
      return getRefreshToken();
    } else {
      return token;
    }
  } else {
    return await goToAuthorizationHost();
  }
}

export const instanceAxios = axios.create({
  baseURL: process.env.API_HOST || process.env.REACT_APP_API_HOST,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
  withCredentials: true,
});
