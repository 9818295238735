import { toast } from 'react-toastify';
import { ToastComponent } from './components';
import { ShowPromiseToastConfig, ShowToastConfig } from './types';

export function showToast({ type, ...props }: ShowToastConfig) {
  const Component = ToastComponent[type];
  toast(<Component {...props} />, {
    type,
    icon: false,
  });
}

export function showPromiseToast({
  promise,
  defaultMessage,
  errorMessage,
  successMessage,
  warningMessage,
}: ShowPromiseToastConfig) {
  const id = toast.loading(
    <ToastComponent.default message={defaultMessage} />,
    { icon: false }
  );
  promise()
    .then(() => {
      toast.update(id, {
        render() {
          return <ToastComponent.success message={successMessage} />;
        },
        type: 'success',
        isLoading: false,
        autoClose: 0,
        icon: false,
      });
    })
    .catch((data) => {
      toast.update(id, {
        render() {
          if (data === 'warning' && warningMessage) {
            return <ToastComponent.warning message={warningMessage} />;
          }
          return <ToastComponent.error message={errorMessage} />;
        },
        type: data === 'warning' ? 'warning' : 'error',
        isLoading: false,
        autoClose: 0,
        icon: false,
      });
    });
  return id;
}

export function dissmiss(id: string | number) {
  toast.dismiss(id);
}
