import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { LinkButtonViewProps } from '../types';

export function useLinkButtonController(path: LinkButtonViewProps['path']) {
  const { client_id } = useParams();

  const href = useMemo(() => `/${client_id}${path}`, [client_id]);

  return {
    href,
  };
}
