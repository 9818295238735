import { useCallback } from 'react';
import { useHitsContext } from '../../../../../../../context';

export function useAddCommentController() {
  const { setOpenCommentForm, openCommentForm } = useHitsContext();

  const onClick = useCallback(() => {
    setOpenCommentForm(true);
  }, [setOpenCommentForm]);

  return { onClick, disabled: openCommentForm };
}
