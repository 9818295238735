import { ContentLoader, GridContainer } from '@aid-module/ui';

export function Fallback() {
  return (
    <GridContainer gap="32">
      <GridContainer gap="20">
        <ContentLoader width="100%" height="36.4px">
          <rect rx="4" ry="4" width="100%" height="36.4px" />
        </ContentLoader>
        <ContentLoader width="100%" height="488.15px">
          <rect rx="6" ry="6" width="100%" height="488.15px" />
        </ContentLoader>
      </GridContainer>
      <GridContainer gap="20">
        <ContentLoader width="100%" height="31.2px">
          <rect rx="4" ry="4" width="100%" height="31.2px" />
        </ContentLoader>
        <ContentLoader width="100%" height="480px">
          <rect rx="4" ry="4" width="100%" height="480px" />
        </ContentLoader>
      </GridContainer>
    </GridContainer>
  );
}
