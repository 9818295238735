import { useMemo } from 'react';
import { useFilterTemplatesContext } from '../../../../../../../../../../../../../Table/context';

export function useFilterTemplatesController() {
  const { data } = useFilterTemplatesContext();

  const isEmpty = useMemo(
    () =>
      (data?.favorites?.length || 0) === 0 &&
      (data?.templates?.length || 0) === 0,
    [data]
  );

  return { isEmpty };
}
