import styled from '@emotion/styled';
import { GridContainer } from '../../../../../../../../../../../../../../../containers';
import {
  getColor,
  getSpace,
} from '../../../../../../../../../../../../../../../utils';

export const Container = styled(GridContainer)`
  padding: ${getSpace('8')} ${getSpace('32')} ${getSpace('8')} ${getSpace('14')};
  background: ${getColor('main-deep-1')};
  border-radius: 4px;
  position: relative;
  margin-bottom: ${getSpace('8')};
  position: relative;
`;

export const DragContainer = styled.div`
  position: absolute;
  right: 8px;
  top: 12px;
  bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 11px;
`;
