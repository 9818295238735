import gql from 'graphql-tag';
import { TRANSACTION_ENTITY_FRAGMENT } from './TransactionEntity';
import { TRANSACTION_INSTRUMENT_FRAGMENT } from './TransactionInstrument';

export const TRANSACTION_DATA_FRAGMENT = gql`
  ${TRANSACTION_ENTITY_FRAGMENT}
  ${TRANSACTION_INSTRUMENT_FRAGMENT}
  fragment TransactionDataFragment on TransactionData {
    amount
    client_ip
    comment
    currency
    fingerprint
    id
    received_amount
    received_currency
    receiver_entity @type(name: "TransactionEntity") {
      ...TransactionEntityFragment
    }
    receiver_entity_id
    receiver_entity_type
    receiver_instrument @type(name: "TransactionInstrument") {
      ...TransactionInstrumentFragment
    }
    rejection_reason_code
    rejection_reason_name
    receiver_source
    sender_entity @type(name: "TransactionEntity") {
      ...TransactionEntityFragment
    }
    sender_entity_id
    sender_entity_type
    sender_instrument @type(name: "TransactionInstrument") {
      ...TransactionInstrumentFragment
    }
    sender_source
    sent_amount
    sent_currency
    transaction_data_created_at
  }
`;
