import { createContext, useContext } from 'react';
import { ModalContextParams } from './types';

export const ModalContext = createContext<ModalContextParams>({
  close: () => {},
});

export function useModalContext() {
  
  const context = useContext(ModalContext);

  return context;
}
