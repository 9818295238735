import { LoadableFunctionalComponent } from '@aid-module/ui';
import loadable from '@loadable/component';
import { Fallback } from './components';

export const IdentityVerificationsRegister = loadable(
  () => import('./component'),
  {
    resolveComponent: (components) =>
      components.IdentityVerificationsRegisterComponent,
    fallback: <Fallback />,
  }
) as LoadableFunctionalComponent<{}>;
