import { createContext, useContext } from 'react';
import { TYPES } from '@aid-package/api';

export const RetentionContext = createContext<
  TYPES.RetentionMeQuery['retentionMe']['data']
>({} as any);

export function useRetentionContext() {
  const context = useContext(RetentionContext);

  return context;
}
