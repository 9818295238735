import { Image } from '../../../../../Image';
import styled from '@emotion/styled';

export const StyledImage = styled(Image)`
  overflow: hidden;
  object-fit: cover;

  height: 40px;
  width: 40px;
  border-radius: 4px;
`;
