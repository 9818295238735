import { PERMISSIONS } from '@aid-package/context';
import { Routes, Route } from 'react-router-dom';
import { ComplianceDashboard } from './СomplianceDashboard';
import { Dashboard } from './Dashboard';
import { VerificationDashboard } from './VerificationDashboard';
import { ProtectedRoute, ErrorPages } from '@aid-module/ui';
import { Metrics } from './Metrics';
import {
  SubmissionsDescription,
  SubmissionRegister,
} from './SubmissionsDashboard';
import { TMDashboard } from './TMDashboard';

export function StatsRouter() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute
            check={[
              PERMISSIONS.SEE_DASHBOARD,
              PERMISSIONS.SEE_DASHBOARD_VERIFICATION,
              PERMISSIONS.SEE_TM_DASHBOARD,
            ]}
          />
        }
      >
        <Route index element={<ErrorPages type="local" code={404} />} />
        <Route
          path="dashboard"
          element={
            <ProtectedRoute strong check={[PERMISSIONS.SEE_DASHBOARD]} />
          }
        >
          <Route index element={<Dashboard />} />
        </Route>
        <Route
          path="compliance_dashboard"
          element={<ProtectedRoute check={[PERMISSIONS.SEE_DASHBOARD]} />}
        >
          <Route index element={<ComplianceDashboard />} />
        </Route>
        <Route
          path="verification_dashboard"
          element={
            <ProtectedRoute check={[PERMISSIONS.SEE_DASHBOARD_VERIFICATION]} />
          }
        >
          <Route index element={<VerificationDashboard />} />
        </Route>
        <Route
          path="metrics"
          element={<ProtectedRoute check={[PERMISSIONS.SEE_DASHBOARD]} />}
        >
          <Route index element={<Metrics />} />
        </Route>
        <Route
          path="submissions"
          element={
            <ProtectedRoute check={[PERMISSIONS.SEE_SUBMISSIONS_STATISTICS]} />
          }
        >
          <Route index element={<SubmissionRegister />} />
          <Route path=":id" element={<SubmissionsDescription />} />
        </Route>
        <Route
          path="transactions"
          element={<ProtectedRoute check={PERMISSIONS.SEE_TM_DASHBOARD} />}
        >
          <Route index element={<TMDashboard />} />
        </Route>
        <Route path="*" element={<ErrorPages type="local" code={404} />} />
      </Route>
    </Routes>
  );
}
