export const TYPE_PLATE = {
  success: 'success',
  fail: 'fail',
  failed: 'failed',
  passed: 'passed',
  unprocessed: 'unprocessed',
  denied: 'DENIED',
  approved: 'APPROVED',
  suspected: 'SUSPECTED',
  expired: 'EXPIRED',
};
