import {
  ContentLoader,
  DefaultButtonLoader,
  GridContainer,
} from '@aid-module/ui';
import { FormLoader } from '../../../components';

export function Fallback() {
  return (
    <FormLoader>
      <GridContainer gap="8">
        <ContentLoader width="100%" height="18.2px">
          <rect rx={8} ry={8} width="100%" height="18.2px" />
        </ContentLoader>
        <DefaultButtonLoader />
      </GridContainer>
      <DefaultButtonLoader />
    </FormLoader>
  );
}
