import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { usePortalSideWidgetProviderContext } from '../../Hook';
import { PortalWidgetViewProps } from '../types';
import { WidgetOptionsState } from './types';

export function usePortalWidgetController(
  key: PortalWidgetViewProps['componentKey']
) {
  const { controller, containerName } = usePortalSideWidgetProviderContext();
  const [currentWidgetOptions, setCurrentWidgetOptions] =
    useState<WidgetOptionsState>({
      currentWidget: controller.widgetWindows?.[key],
      state: controller.widgetWindows?.[key]?.state || 'hidden',
    });

  const show = useCallback(() => {
    controller.show(key);
  }, [key]);

  const push = useCallback(() => {
    controller.push(key);
  }, [key]);

  useLayoutEffect(() => {
    setCurrentWidgetOptions((prev) => {
      if (!!prev.currentWidget && !controller.widgetWindows[key]) {
        return {
          ...prev,
          state: 'animation-end',
        };
      } else if (controller.widgetWindows?.[key]) {
        return {
          currentWidget: controller.widgetWindows[key],
          state: controller.widgetWindows[key].state,
        };
      }
      return prev;
    });
  }, [key, controller.widgetWindows]);

  const onTransitionEnd = useRef(() => {
    setCurrentWidgetOptions((prev) => {
      if (prev.state === 'animation-end') {
        return { currentWidget: undefined, state: 'hidden' };
      }
      return prev;
    });
  });

  return {
    show,
    push,
    widgetOptions: currentWidgetOptions,
    onTransitionEnd,
    containerName,
  };
}
