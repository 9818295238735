import gql from 'graphql-tag';
import { RISK_SCORING_TEMPLATE_FRAGMENT } from '../../../fragment/account/riskScoringTemplates';

export const RiskScoringTemplatesUpdate = gql`
  ${RISK_SCORING_TEMPLATE_FRAGMENT}
  mutation RiskScoringTemplatesUpdate(
    $id: String!
    $body: BodyCreateOrUpdateRiskScoringTemplate!
  ) {
    updateRiskScoringTemplate(id: $id, body: $body)
      @rest(
        type: "RiskScoringTemplatesUpdate"
        path: "/core/client/risk_scoring_items/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "RiskScoringTemplate") {
        ...RiskScoringTemplateFragment
      }
      success
    }
  }
`;

export const RiskScoringTemplateCreate = gql`
  ${RISK_SCORING_TEMPLATE_FRAGMENT}
  mutation RiskScoringTemplateCreate(
    $body: BodyCreateOrUpdateRiskScoringTemplate!
  ) {
    createRiskScoringTemplate(body: $body)
      @rest(
        type: "RiskScoringTemplateCreate"
        path: "/core/client/risk_scoring_items"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "RiskScoringTemplate") {
        ...RiskScoringTemplateFragment
      }
      success
    }
  }
`;

export const RiskScoringTemplateDelete = gql`
  mutation RiskScoringTemplateDelete($id: String!) {
    deleteRiskScoringTemplate(id: $id)
      @rest(
        type: "RiskScoringTemplatesUpdate"
        path: "/core/client/risk_scoring_items/{args.id}"
        method: "DELETE"
      ) {
      data {
        id
      }
      success
    }
  }
`;
