import { createContext, PropsWithChildren, useContext } from 'react';
import { usePDFExportContextProviderController } from './controller';
import { PDFExportContextParams } from './types';

const PDFExportContext = createContext<PDFExportContextParams>({
  downloadByCode: () => {},
  isExporting: false,
});

export function usePDFExportContext() {
  const context = useContext(PDFExportContext);

  return { context };
}

export function PDFExportContextProvider({ children }: PropsWithChildren<{}>) {
  const context = usePDFExportContextProviderController();
  return (
    <PDFExportContext.Provider value={context}>
      {children}
    </PDFExportContext.Provider>
  );
}
