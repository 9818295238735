import { AsyncSelect } from '../AsyncSelect';
import { useProfilesWithSubmissionSelectController } from './controller';
import { useComponents } from 'react-select-async-paginate';
import { Components } from './components';
import { Profiles, TYPES } from '@aid-package/api';
import { ProfilesSelectViewProps } from './types';
import { additional } from './constants';

export function ProfilesWithSubmissionSelect({
  label,
  onChange,
  placeholder,
}: ProfilesSelectViewProps) {
  const { reduceData } = useProfilesWithSubmissionSelectController();

  const selectComponents = useComponents(Components);

  return (
    <AsyncSelect<TYPES.ProfilesQuery['profiles']['data'][0]>
      menuPlacement="bottom"
      query={Profiles}
      onChange={onChange}
      additional={additional}
      components={selectComponents}
      reduceData={reduceData}
      placeholder={placeholder || 'Start enter profile name...'}
      label={label}
      isClearable
    />
  );
}
