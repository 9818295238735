import { AutomaticTooltip } from '../../../AutomaticTooltip';
import { Body, Button } from './components';
import { ExternalRefListViewProps } from './types';

export function ExternalRefList({
  externalRefs,
  count,
}: ExternalRefListViewProps) {
  if (!(count <= 0)) {
    return (
      <AutomaticTooltip
        view={<Button count={count} />}
        background="white-primary"
        border="main-deep-2"
        pattern="top,right,left,bottom"
        padding="12px 6px 12px 12px"
      >
        <Body externalRefs={externalRefs} />
      </AutomaticTooltip>
    );
  }

  return null;
}
