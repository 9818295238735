import { FEATURES, FeaturesParams } from '../../features/types';
import { CheckFeature } from '../types';

export function getAvailabelFromString(
  check: FEATURES,
  features?: FeaturesParams
) {
  return Boolean(features?.[check]);
}

function getAvailabelFromArray(
  check: Exclude<CheckFeature, FEATURES>,
  strong: boolean,
  features?: FeaturesParams
): boolean {
  let show = strong;

  for (let i = 0, length = check.length; i < length; i++) {
    const currentFeature = check[i];
    const value = Boolean(getAvailabelFromString(currentFeature, features));
    if (value === !strong) {
      show = !strong;
      break;
    }
  }

  return show;
}

export function getAvailabel(
  check: CheckFeature,
  features?: FeaturesParams,
  strong?: boolean
) {
  if (typeof check === 'string') {
    return Boolean(getAvailabelFromString(check, features));
  }
  return getAvailabelFromArray(check, Boolean(strong), features);
}
