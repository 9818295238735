export const DATA_RETENTION_PLAN_STATUSES = {
  CREATED: 'created',
  UPDATED: 'updated',
  SOFT_DELETED_COMPLETE: 'soft_deleted_complete',
  COMPLETED: 'completed',
  CANCELED: 'canceled',
  RESTORED: 'restored',
  READY_TO_DELETE: 'ready_to_delete',
};

export const DATA_RETENTION_PLAN_DISPLAY_STATUSES = {
  [DATA_RETENTION_PLAN_STATUSES.CREATED]: 'Active',
  [DATA_RETENTION_PLAN_STATUSES.UPDATED]: 'Updated',
  [DATA_RETENTION_PLAN_STATUSES.SOFT_DELETED_COMPLETE]: 'Archived',
  [DATA_RETENTION_PLAN_STATUSES.COMPLETED]: 'Completed',
  [DATA_RETENTION_PLAN_STATUSES.CANCELED]: 'Cancelled',
  [DATA_RETENTION_PLAN_STATUSES.RESTORED]: 'Restored',
};

export const DATA_RETENTION_PLAN_STATUSES_OPTIONS = Object.entries(DATA_RETENTION_PLAN_DISPLAY_STATUSES)
  .map(([value, label]) => ({
    value, label
}));
