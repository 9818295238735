import { gql } from '@apollo/client';

export const CreateTransactionQueue = gql`
  mutation CreateTransactionQueue($body: BodyCreateOrUpdateTransactionQueue!) {
    createTransactionQueue(body: $body)
      @rest(
        type: "CreateTransactionQueue"
        path: "/tm/workflow/queues"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "TransactionQueue") {
        id
        alert_deadline_days
        created_at
        description
        is_default
        name
        open_alerts_count
        role_ids
        rules @type(name: "Rule") {
          async
          code
          created_at
          deleted
          description
          enabled
          id
          name
          provider_id
          ruleset_id
          is_for_test
          settings
        }
      }
      success
      errors {
        name
      }
    }
  }
`;

export const UpdateTransactionQueue = gql`
  mutation UpdateTransactionQueue(
    $id: String!
    $body: BodyCreateOrUpdateTransactionQueue!
  ) {
    updateTransactionQueue(id: $id, body: $body)
      @rest(
        type: "UpdateTransactionQueue"
        path: "/tm/workflow/queues/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "TransactionQueue") {
        id
        alert_deadline_days
        created_at
        description
        is_default
        name
        open_alerts_count
        role_ids
        rules @type(name: "Rule") {
          async
          code
          created_at
          deleted
          description
          enabled
          id
          name
          provider_id
          ruleset_id
          is_for_test
          settings
        }
      }
      success
      errors {
        name
      }
    }
  }
`;

export const DeleteTransactionQueue = gql`
  mutation DeleteTransactionQueue($id: String!) {
    deleteTransactionQueue(id: $id)
      @rest(
        type: "DeleteTransactionQueue"
        path: "/tm/workflow/queues/{args.id}"
        method: "DELETE"
      ) {
      data {
        id
      }
      success
      errors {
        name
      }
    }
  }
`;
