import { GridContainer, Text } from '@aid-module/ui';

export function Logo() {
  return (
    <GridContainer justifyContent="center" gap="12">
      <GridContainer justifyContent="center">
        <svg
          width="57"
          height="24"
          viewBox="0 0 57 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M28.6505 19.0557L21.3868 6.05509C19.4658 2.85116 15.9841 0.694672 12.0221 0.694672C5.899 0.694672 0.976521 5.74702 0.976521 11.97C0.976521 18.1931 5.899 23.307 12.0221 23.307C12.0221 23.307 13.1026 23.307 13.4028 23.307C15.0236 23.307 16.3443 22.0131 16.3443 20.4728C16.3443 18.9324 15.0836 17.6385 13.5829 17.6385C13.3428 17.6385 12.0221 17.6385 12.0221 17.6385C8.96055 17.6385 6.4993 15.1124 6.4993 11.97C6.4993 8.82772 8.96055 6.30155 12.0221 6.30155C13.9431 6.30155 15.6239 7.28737 16.5844 8.82772L16.6444 8.88934L23.9081 21.8899C24.6885 23.2454 26.3693 23.6767 27.69 22.9373C29.0106 22.1364 29.4309 20.4112 28.6505 19.0557Z"
            fill="#1F3B8E"
          />
          <path
            d="M37.1751 22.9364C35.8544 23.7374 34.1736 23.2444 33.3932 21.8889L23.9084 4.94508C23.128 3.58957 23.6083 1.86437 24.9289 1.06339C26.2496 0.26241 27.9304 0.755322 28.7108 2.11083L38.2556 19.0547C38.976 20.4102 38.4958 22.1354 37.1751 22.9364Z"
            fill="url(#paint0_linear_2237_11396)"
          />
          <path
            d="M45.5188 0.693909H35.854C34.3532 0.693909 33.0926 1.9878 33.0926 3.52815C33.0926 5.0685 34.3532 6.3624 35.854 6.3624H45.3988C48.2802 6.54724 50.5614 9.0118 50.5614 12.0309C50.5614 14.9267 48.5804 17.2681 45.6989 17.6378C45.6989 17.6378 45.5188 17.6378 45.3387 17.6994C43.838 17.761 42.5774 18.9933 42.5774 20.5336C42.5774 22.074 43.838 23.3679 45.3387 23.3679C45.5188 23.3679 45.6389 23.3679 45.6389 23.3679C51.4018 22.9982 56.0241 18.1307 56.0241 12.0925C56.0241 5.9311 51.3418 1.00198 45.5188 0.693909Z"
            fill="#1F3B8E"
          />
          <defs>
            <linearGradient
              id="paint0_linear_2237_11396"
              x1="31.0614"
              y1="0.677612"
              x2="31.0614"
              y2="23.3222"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#1F3B8E" />
              <stop offset="1" stopColor="#8BDEEF" />
            </linearGradient>
          </defs>
        </svg>
      </GridContainer>
      <Text
        text="Compliance as a service"
        uppercase
        size={14}
        color="main-deep-4"
        weight="VeryBig"
      />
    </GridContainer>
  );
}
