import gql from 'graphql-tag';

export const UpdateStopWord = gql`
  mutation UpdateStopWord($id: String!, $body: RequestCreateOrUpdateStopWord!) {
    updateStopWord(id: $id, body: $body)
      @rest(
        type: "StopWordUpdate"
        path: "/pp/stop_words/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "ResponseStopWord") {
        id
        name
        created_at
      }
      success
    }
  }
`;

export const CreateStopWord = gql`
  mutation CreateStopWord($body: RequestCreateOrUpdateStopWord!) {
    createStopWord(body: $body)
      @rest(
        type: "StopWordCreate"
        path: "/pp/stop_words"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "ResponseStopWord") {
        id
        name
        created_at
      }
      success
    }
  }
`;

export const DeleteStopWord = gql`
  mutation DeleteStopWord($id: String!) {
    deleteStopWord(id: $id)
      @rest(
        type: "StopWordDelete"
        path: "/pp/stop_words/{args.id}"
        method: "DELETE"
      ) {
      success
    }
  }
`;
