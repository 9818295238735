import { OptionProps } from 'react-select';
import { GridContainer, FlexContainer } from '../../../containers';
import { SelectOption } from '../../Select';
import { Text } from '../../Text';
import { ClientAvatar } from '../../ClientAvatar';
import { OptionContainer, ProfileName } from './styles';
import { LinkButton } from './LinkButton';
import { SubmissionCounter } from './SubmissionCounter';
import { PERMISSIONS, useCheckPermission } from '@aid-package/context';

export function Option(props: OptionProps<SelectOption, false>) {
  const { canShow } = useCheckPermission(PERMISSIONS.SEE_PROFILE, true);

  return (
    <OptionContainer
      {...props.innerProps}
      onClick={!props.isSelected ? props.innerProps.onClick : undefined}
      isSelected={props.isSelected}
      justifyContent="space-between"
      alignItems="center"
    >
      <GridContainer gap="12" gridTemplateColumns="40px minmax(0, 1fr)">
        <ClientAvatar size="small" client={props.data.profile} />
        <GridContainer gap="4" alignItems="center">
          <FlexContainer alignItems="center" gap="8">
            <ProfileName
              color="main-deep-5"
              size={12}
              weight="Medium"
              text={props.data.profile.display_name || 'External profile'}
            />
            {props.data.profile.form_submissions?.length ? (
              <SubmissionCounter
                count={props.data.profile.form_submissions?.length}
              />
            ) : null}
          </FlexContainer>
          <GridContainer gridTemplateColumns="max-content 1fr" gap="4">
            <Text text="Email" color="main-deep-4" size={10} />
            <Text
              color="main-deep-5"
              size={10}
              text={props.data.profile.email || '-'}
            />
          </GridContainer>
        </GridContainer>
      </GridContainer>
      {canShow ? (
        <LinkButton path={`/profile/profiles/${props.data.profile.id}`} />
      ) : null}
    </OptionContainer>
  );
}

export const Components = {
  Option,
};
