import { gql } from '@apollo/client';
import { FORM_INVITATION_FRAGMENT } from '../../../fragment/form';

export const CreateFormInvitation = gql`
  ${FORM_INVITATION_FRAGMENT}
  mutation CreateFormInvitation($body: BodyCreateOrUpdateFormInvitation!) {
    createFormInvitation(body: $body)
      @rest(
        type: "CreateFormInvitation"
        path: "/core/client/form_invitations"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "FormInvitation") {
        ...FormInvitationFragment
      }
      success
    }
  }
`;

export const UpdateFormInvitation = gql`
  ${FORM_INVITATION_FRAGMENT}
  mutation UpdateFormInvitation(
    $id: String!
    $body: BodyCreateOrUpdateFormInvitation!
  ) {
    updateFormInvitation(id: $id, body: $body)
      @rest(
        type: "UpdateFormInvitation"
        path: "/core/client/form_invitations/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "FormInvitation") {
        ...FormInvitationFragment
      }
      success
    }
  }
`;
