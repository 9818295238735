import { gql } from '@apollo/client';

export const NarrativeTemplates = gql`
  query NarrativeTemplates($q: String, $page: Int) {
    narrativeTemplates(q: $q, page: $page, per: 20)
      @rest(
        type: "ResponseNarrativeTemplates"
        path: "/tm/workflow/narrative_templates?{args}"
      ) {
      data @type(name: "NarrativeTemplate") {
        id
        created_at
        kind
        name
        show_in_all_queues
        text
        updated_at
        workflow_queues @type(name: "TransactionQueue") {
          id
          name
        }
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const NarrativeTemplatesQueued = gql`
  query NarrativeTemplatesQueued(
    $case_id: String
    $alert_id: String
    $q: String
    $page: Int
  ) {
    narrativeTemplatesQueued(
      case_id: $case_id
      alert_id: $alert_id
      q: $q
      page: $page
      per: 20
    )
      @rest(
        type: "ResponseNarrativeTemplates"
        path: "/tm/workflow/narrative_templates/queued?{args}"
      ) {
      data @type(name: "NarrativeTemplate") {
        id
        created_at
        kind
        name
        show_in_all_queues
        text
        updated_at
        workflow_queues @type(name: "TransactionQueue") {
          id
          name
        }
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const Narratives = gql`
  query Narratives(
    $subject_id: String!
    $subject_type: String!
    $page: Int
    $per: Int
  ) {
    narratives(
      subject_id: $subject_id
      subject_type: $subject_type
      page: $page
      per: $per
    )
      @rest(
        type: "ResponseNarratives"
        path: "/tm/workflow/narratives?{args}"
      ) {
      data @type(name: "Narrative") {
        id
        created_at
        created_by @type(name: "Account") {
          email
          first_name
          id
          last_name
          avatar_id
        }
        state
        subject_id
        subject_type
        text
        updated_at
        workflow_narrative_template
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const NarrativeTemplate = gql`
  query NarrativeTemplate($id: String!) {
    narrativeTemplate(id: $id)
      @rest(
        type: "ResponseNarrativeTemplates"
        path: "/tm/workflow/narrative_templates/{args.id}"
      ) {
      data @type(name: "NarrativeTemplate") {
        id
        created_at
        kind
        name
        show_in_all_queues
        text
        updated_at
        workflow_queues @type(name: "WorkflowQueue") {
          id
          name
        }
      }
      success
    }
  }
`;
