import {
  FlexContainer,
  getColor,
  getSpace,
  GridContainer,
} from '@aid-module/ui';
import styled from '@emotion/styled';

export const Container = styled(GridContainer)`
  background: ${getColor('main-deep-1')};
`;

export const LogoContainer = styled(FlexContainer)`
  margin: ${getSpace('20')} 0 ${getSpace('60')};
`;
