import {
  Count,
  FlexContainer,
  GridContainer,
  Icon,
  IconLoader,
  Text,
} from '@aid-module/ui';
import { TYPES } from '@aid-package/api';
import {
  NotificationsFooter,
  Notification,
  ReadAll,
  FallbackNotifications,
  ToSettings,
} from './components';
import {
  Container,
  ContentContainer,
  EmptyContainer,
  NotificationsContainer,
  ScrollContainer,
} from './styles';
import { MenuViewProps } from './types';

function renderNotification(
  notification: TYPES.NotificationsQuery['notifications']['data'][0]
) {
  return <Notification key={notification.id} notification={notification} />;
}

export function Menu({
  isEmpty,
  notifications,
  loading,
  count,
  loadingCount,
  infinityRef,
}: MenuViewProps) {
  return (
    <Container border="main-deep-2" background="white-primary">
      <GridContainer gridTemplateRows="repeat(3, max-content)">
        <ContentContainer padding="XS" background="main-deep-1">
          <GridContainer
            gridTemplateColumns="1fr max-content"
            alignItems="center"
          >
            <GridContainer
              gridTemplateColumns="max-content max-content"
              gap="6"
            >
              <Text text="Notifications" size={14} weight="VeryBig" uppercase />
              {!!count && <Count count={count} size={10} />}
            </GridContainer>
            <FlexContainer gap="18" alignItems="center">
              <ToSettings />
              {!isEmpty && <ReadAll />}
            </FlexContainer>
          </GridContainer>
        </ContentContainer>
        <NotificationsContainer
          gridTemplateColumns="1fr"
          gridTemplateRows="repeat(2, max-content)"
          loading={loading}
        >
          <ScrollContainer flexDirection="column">
            {notifications?.map(renderNotification)}
            <FallbackNotifications
              infinityRef={infinityRef}
              loadingCount={loadingCount}
            />
          </ScrollContainer>
          <EmptyContainer
            isEmpty={isEmpty}
            gridTemplateColumns="1fr"
            justifyContent="center"
            gap="10"
          >
            <FlexContainer justifyContent="center">
              <Icon
                glyph="emptyList"
                size={32}
                fallback={<IconLoader size={32} />}
              />
            </FlexContainer>
            <Text
              text="You have no unread notifications"
              align="center"
              color="main-deep-4"
            />
          </EmptyContainer>
        </NotificationsContainer>
        <NotificationsFooter />
      </GridContainer>
    </Container>
  );
}
