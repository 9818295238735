import { LoadableFunctionalComponent } from '@aid-module/ui';
import loadable from '@loadable/component';
import { Fallback } from './components';
import { FormContainer } from './components/FormContainer';

export const BlacklistProfileCreate = loadable(() => import('./component'), {
  resolveComponent: (components) => components.BlacklistProfileCreateComponent,
  fallback: (
    <FormContainer isLoading>
      <Fallback />
    </FormContainer>
  ),
}) as LoadableFunctionalComponent<{}>;
