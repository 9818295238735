import {
  TabRegistries,
  useDeleteTabRegistryMutation,
  useUpdateTabRegistryMutation,
  TYPES,
} from '@aid-package/api';
import { useCallback } from 'react';
import { useFilterTemplatesContext } from '../../../../../../../../../../../../../../../../../../../../../../../Table/context';
import { showToast } from '../../../../../../../../../../../../../../../../../../../../../../../Toast';

export function useActionsModel() {
  const [deleteMutation, { loading: loadingDelete }] =
    useDeleteTabRegistryMutation({
      refetchQueries: [TabRegistries],
    });
  const [updateMutation, { loading: loadingUpdate }] =
    useUpdateTabRegistryMutation({
      refetchQueries: [TabRegistries],
    });
  const { data } = useFilterTemplatesContext();

  const deleteTagRegistery = useCallback(
    async (id: string) => {
      const { data } = await deleteMutation({
        variables: { id },
      });

      if (data?.deleteTabRegistry?.success) {
        showToast({
          type: 'success',
          message: 'Changes were successfully saved',
        });
        return true;
      }
      showToast({
        type: 'error',
        message: 'Something went wrong',
      });
      return;
    },
    [deleteMutation]
  );

  const updateTabRegistry = useCallback(
    async (id: string, tab_registry: TYPES.CreateOrUpdateTabRegistry) => {
      const { data } = await updateMutation({
        variables: { id, body: { tab_registry } },
      });

      if (data?.updateTabRegistry?.success) {
        showToast({
          type: 'success',
          message: 'Changes were successfully saved',
        });
        return true;
      }
      showToast({
        type: 'error',
        message: 'Something went wrong',
      });
      return;
    },
    [updateMutation]
  );

  return {
    deleteTagRegistery,
    loadingDelete,
    updateTabRegistry,
    loadingUpdate,
    favoriteLength: data?.favorites?.length || 0,
    loading: data?.loading,
  };
}
