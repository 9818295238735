import gql from 'graphql-tag';

export const PricingClientFeatureDictionary = gql`
  query PricingClientFeatureDictionary(
    $page: Int
    $per: Int
    $is_system: Boolean
  ) {
    pricingClientFeatureDictionary(
      page: $page
      per: $per
      is_system: $is_system
    )
      @rest(
        type: "ResponsePricingCLientFeatureDictionary"
        path: "/billing/client/feature_dictionaries?{args}"
      ) {
      data @type(name: "FeatureDictionary") {
        code
        created_at
        created_by_id
        id
        is_system
        name
        status
        updated_at
        feature_templates @type(name: "FeatureTemplate") {
          code
          created_at
          created_by_id
          description
          feature_dictionary_id
          id
          kind
          name
          status
          updated_at
          is_system
        }
      }
      success
    }
  }
`;
