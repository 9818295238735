import { FormProvider, Controller } from 'react-hook-form';
import { GridContainer, TextInput, ModalBodyViewProps } from '@aid-module/ui';
import { Actions } from './components';
import { useFormController } from './controller';
import { FormRenderProps } from './types';

const renderName = ({
  field: { value, onChange },
  fieldState: { error },
}: FormRenderProps<'name'>) => {
  return (
    <TextInput
      label="Name"
      placeholder="Enter name..."
      error={error}
      value={value}
      onChange={onChange}
    />
  );
};

export function Form(props: ModalBodyViewProps) {
  const formContext = useFormController();
  
  return (
    <FormProvider {...formContext}>
      <GridContainer gridTemplateRows="repeat(2, max-content)" gap="20">
        <Controller name="name" render={renderName} />
        <Actions {...props} />
      </GridContainer>
    </FormProvider>
  );
}
