export const TM_CASE_STATUSES = {
  OPEN: 'open',
  PRE_OPEN: 'pre_open',
  CLOSED: 'closed',
  PRE_CLOSED: 'pre_closed',
};

export const TM_CASE_DISPLAY_STATUSES = {
  [TM_CASE_STATUSES.OPEN]: 'Open',
  [TM_CASE_STATUSES.PRE_OPEN]: 'Pre-Open',
  [TM_CASE_STATUSES.CLOSED]: 'Closed',
  [TM_CASE_STATUSES.PRE_CLOSED]: 'Pre-Closed',
};
