import { Navigate, Route, Routes } from 'react-router-dom';
import { NotificationsRegister } from './components';
import { Notification } from './components';

export function NotificationRouter() {
  return (
    <Routes>
      <Route index element={<Navigate to="notifications" replace />} />
      <Route path="/notifications" element={<NotificationsRegister />} />
    </Routes>
  );
}

export { Notification };
