import { useToNotificationsController } from './controller';
import { Container, Label } from './styles';

export function ToNotifications() {
  const { toNotifications } = useToNotificationsController();

  return (
    <Container justifyContent="center" onClick={toNotifications}>
      <Label color="main-deep-4" size={12} text="View all notifications" />
    </Container>
  );
}
