import gql from 'graphql-tag';
import { CHECKUP_CONFIG_FRAGMENT } from '../../../fragment/checkup/CheckupConfig';

export const CheckupConfigs = gql`
  ${CHECKUP_CONFIG_FRAGMENT}
  query CheckupConfigs($audit_config_id: String, $page: Int, $q: String) {
    checkupConfigs(
      audit_config_id: $audit_config_id
      page: $page
      per: 20
      q: $q
    )
      @rest(
        type: "ResponseCheckupConfigs"
        path: "/checkups/client/checkup_configs?{args}"
      ) {
      data @type(name: "CheckupConfig") {
        ...CheckupConfigFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const CheckupConfig = gql`
  ${CHECKUP_CONFIG_FRAGMENT}
  query CheckupConfig($audit_config_id: String!, $id: String!) {
    checkupConfig(audit_config_id: $audit_config_id, id: $id)
      @rest(
        type: "ResponseCheckupConfig"
        path: "/checkups/client/checkup_configs/{args.id}?audit_config_id={args.audit_config_id}"
      ) {
      data @type(name: "CheckupConfig") {
        ...CheckupConfigFragment
      }
      success
    }
  }
`;
