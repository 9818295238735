import gql from 'graphql-tag';

export const Document = gql`
  query Document($id: String!, $clientId: String!, $path: String!) {
    document(id: $id, clientId: $clientId, path: $path)
      @rest(
        type: "ResponseDocument"
        path: "/documents/{args.path}/{args.id}?client_id={args.clientId}"
      ) {
      data @type(name: "Document") {
        file_size
        file_path
        file_name
        safe_download_url
        av_status
        id
      }
      errors
      success
    }
  }
`;

export const DownloadDocument = gql`
  query DownloadDocument($url: String!) {
    downloadDocument(url: $url) @rest(type: "ResponseReport", path: "")
  }
`;
