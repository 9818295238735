import { DATA_RETENTION_PLAN_STATUSES, THEME_COLORS } from '@aid-module/ui';
import { CASE_STATUSES } from './constants';

export function getCaseStatusColorByStatus(status?: string): keyof THEME_COLORS {
  switch (status) {
    case CASE_STATUSES.ACCEPTED:
    case CASE_STATUSES.AUTO_APPROVED:
      return 'success-deep-3';
    case CASE_STATUSES.NEEDS_UPDATE:
    case CASE_STATUSES.NO_RESPONSE:
      return 'gray-deep-3';
    case CASE_STATUSES.READY_TO_WORK:
    case CASE_STATUSES.REQUESTED_FOR_INFORMATION:
    case CASE_STATUSES.PRE_APPROVED_FOR_REVIEW:
    case CASE_STATUSES.NEW:
      return 'warning-deep-3';
    case CASE_STATUSES.REJECTED:
    case CASE_STATUSES.ABANDONED:
    case CASE_STATUSES.CLOSED:
    case CASE_STATUSES.PRE_REJECTED:
      return 'error-deep-4';
    default:
      return 'main-deep-4';
  }
}

export function getRetentionPlanStatusColorByStatus(status?: string | null): keyof THEME_COLORS {
  switch (status) {
    case DATA_RETENTION_PLAN_STATUSES.COMPLETED:
      return 'success-deep-4';
    case DATA_RETENTION_PLAN_STATUSES.RESTORED:
    case DATA_RETENTION_PLAN_STATUSES.CANCELED:
      return 'error-deep-3';
    case DATA_RETENTION_PLAN_STATUSES.SOFT_DELETED_COMPLETE:
      return 'warning-deep-5';
    default:
      return 'main-deep-4';
  }
}
