import gql from 'graphql-tag';

export const VERIFF_RESULT_FULL_FRAGMENT = gql`
  fragment VeriffResultFullFragment on VeriffResultFull {
    id
    person {
      gender
      idNumber
      lastName
      firstName
      dateOfBirth
      nationality
      yearOfBirth
      placeOfBirth
      pepSanctionMatch
      addresses {
        fullAddress
        parsedAddress {
          city
          unit
          state
          street
          country
          postcode
          houseNumber
        }
      }
    }
    reason
    status
    document {
      type
      number
      country
      validFrom
      validUntil
      placeOfIssue
      firstIssue
      issueNumber
      issuedBy
    }
    code
    reasonCode
    vendorData
    decisionTime
    acceptanceTime
    highRisk
    additionalVerifiedData {
      driversLicenseCategory {
        B
      }
      driversLicenseCategoryFrom {
        B
      }
      driversLicenseCategoryUntil {
        B
      }
    }
    riskLabels {
      label
      category
      sessionIds
    }
  }
`;
