import gql from 'graphql-tag';

export const HIT_FRAGMENT = gql`
  fragment HitFragment on Hit {
    doc @type(name: "HitDoc") {
      aka @type(name: "HitDocAka") {
        name
      }
      created_utc
      entity_type
      fields @type(name: "HitDocField") {
        name
        source
        value
        tag
      }
      id
      keywords
      last_updated_utc
      name
      source_notes
      sources
      types
      media @type(name: "HitDocMedia") {
        date
        pdf_url
        snippet
        title
        url
      }
    }
    is_whitelisted
    match_status
    match_types
    match_types_details @type(name: "HitMatchTypeDetail") {
      aml_types
      matching_name
      name_matches @type(name: "HitMatchNameMatch") {
        match_types
        query_term
      }
      secondary_matches
      sources
    }
    score
    risk_level
  }
`;
