import { Text } from '@aid-module/ui';
import { LabelViewProps } from './types';
import { useLabelController } from './controller';
import { Container } from './styles';

export function Label({ code }: LabelViewProps) {
  const { label } = useLabelController(code);

  return (
    <Container gridTemplateColumns="repeat(2, max-content)" gap="8">
      <Text
        text="Screening"
        size={16}
        color="main-deep-3"
        weight="Medium"
      />
      <Text
        text={label}
        size={16}
        color="main-deep-4"
        weight="VeryBig"
      />
    </Container>
  );
}
