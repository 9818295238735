import gql from 'graphql-tag';

export const CreateBlacklistedDevice = gql`
  mutation CreateBlacklistedDevice($body: CreateBlacklistedDeviceBody!) {
    createBlacklistedDevice(body: $body)
    @rest(
      type: "CreateBlacklistedDevice"
      path: "/core/client/blacklisted_device_infos"
      bodyKey: "body"
      method: "POST"
    ) {
      data @type(name: "BlacklistedDeviceInfo") {
        id
        device_info @type(name: "DeviceInfo") {
          id
          ip
          city
          country_code
          customer_id
          device_info_doubles_count
          form_submission_id
          incognito
          form_submission_id
          created_at
          browser_info {
            device
            os
            osVersion
            userAgent
            browserName
            browserMajorVersion
            browserFullVersion
          }
        }
        deleted_at
      }
      success
    }
  }
`;

export const DeleteBlacklistedDevice = gql`
  mutation DeleteBlacklistedDevice($id: String!) {
    deleteBlacklistedDevice(id: $id)
    @rest(
      type: "DeleteBlacklistedDevice"
      path: "/core/client/blacklisted_device_infos/{args.id}"
      method: "DELETE"
    ) {
      success
    }
  }
`;
