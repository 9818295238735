import { useMemo } from 'react';
import {
  useConfigContext,
  useVariablesContext,
} from '../../../../../../context';

export function useFiltersButtonModel() {
  const filtersConfig = useConfigContext();
  const context = useVariablesContext();

  const count = useMemo(
    () =>
      Object.entries(context?.variables?.filters || {}).reduce<number>(
        (acc, item) => {
          const [key, value] = item;
          if (value && filtersConfig?.[key]) {
            return ++acc;
          }
          return acc;
        },
        0
      ),
    [context?.variables?.filters, filtersConfig]
  );

  return { count, isVisible: !!filtersConfig };
}
