import { gql } from '@apollo/client';
import { CASE_SCORING_FRAGMENT } from '../../../fragment/scoring';
import { CASE_SECTION_EVALUATION_FRAGMENT } from '../../../fragment/scoring/CaseSectionEvaluationScoring';
import { CASE_SECTION_SUBJECT_EVALUATION_SCORING_FRAGMENT } from '../../../fragment/scoring/CaseSectionSubjectEvaluationScoring';

export const UpdateCaseScoring = gql`
  ${CASE_SCORING_FRAGMENT}
  mutation UpdateCaseScoring($id: String!, $body: BodyUpdateCaseScoring!) {
    updateCaseScoring(id: $id, body: $body)
      @rest(
        type: "UpdateCaseScoring"
        path: "/scoring/cases/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "CaseScoring") {
        ...CaseScoringFragment
      }
      success
    }
  }
`;

export const RecalculateCaseScoring = gql`
  ${CASE_SCORING_FRAGMENT}
  mutation RecalculateCaseScoring($id: String!) {
    recalculateCaseScoring(id: $id, body: ".")
      @rest(
        type: "RecalculateCaseScoring"
        path: "/scoring/cases/{args.id}/recalculate"
        bodyKey: "body"
        method: "PUT"
        bodySerializer: "empty"
      ) {
      data @type(name: "CaseScoring") {
        ...CaseScoringFragment
      }
      success
    }
  }
`;

export const ResolveConflictsCaseScoring = gql`
  ${CASE_SCORING_FRAGMENT}
  mutation ResolveConflictsCaseScoring(
    $id: String!
    $body: BodyResolveConflictsScoring!
  ) {
    resolveConflictsCaseScoring(id: $id, body: $body)
      @rest(
        type: "ResolveConflictsCaseScoring"
        path: "/scoring/cases/{args.id}/solve_conflict"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "CaseScoring") {
        ...CaseScoringFragment
      }
      success
    }
  }
`;

export const UpdateCaseSectionScoring = gql`
  ${CASE_SECTION_EVALUATION_FRAGMENT}
  mutation UpdateCaseSectionScoring(
    $id: String!
    $body: BodyUpdateCaseSectionScoring!
  ) {
    updateCaseSectionScoring(id: $id, body: $body)
      @rest(
        type: "UpdateCaseSectionScoring"
        path: "/scoring/case_sections/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "CaseSectionEvaluationScoring") {
        ...CaseSectionEvaluationScoringFragment
      }
      success
    }
  }
`;

export const RecalculateCaseSectionScoring = gql`
  ${CASE_SECTION_EVALUATION_FRAGMENT}
  mutation RecalculateCaseSectionScoring($id: String!) {
    recalculateCaseSectionScoring(id: $id, body: ".")
      @rest(
        type: "RecalculateCaseSectionScoring"
        path: "/scoring/case_sections/{args.id}/recalculate"
        bodyKey: "body"
        method: "PUT"
        bodySerializer: "empty"
      ) {
      data @type(name: "CaseSectionEvaluationScoring") {
        ...CaseSectionEvaluationScoringFragment
      }
      success
    }
  }
`;

export const ResolveConflictsCaseSectionScoring = gql`
  ${CASE_SECTION_EVALUATION_FRAGMENT}
  mutation ResolveConflictsCaseSectionScoring(
    $id: String!
    $body: BodyResolveConflictsScoring!
  ) {
    resolveConflictsCaseSectionScoring(id: $id, body: $body)
      @rest(
        type: "ResolveConflictsCaseSectionScoring"
        path: "/scoring/case_sections/{args.id}/solve_conflict"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "CaseSectionEvaluationScoring") {
        ...CaseSectionEvaluationScoringFragment
      }
      success
    }
  }
`;

export const UpdateCaseSectionSubjectScoring = gql`
  ${CASE_SECTION_SUBJECT_EVALUATION_SCORING_FRAGMENT}
  mutation UpdateCaseSectionSubjectScoring(
    $id: String!
    $body: BodyCreateOrUpdateCaseSectionSubjectScoring!
  ) {
    updateCaseSectionSubjectScoring(id: $id, body: $body)
      @rest(
        type: "UpdateCaseSectionScoring"
        path: "/scoring/case_section_subjects/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "CaseSectionSubjectEvaluationScoring") {
        ...CaseSectionSubjectEvaluationScoringFragment
      }
      success
    }
  }
`;

export const CreateCaseSectionSubjectScoring = gql`
  ${CASE_SECTION_SUBJECT_EVALUATION_SCORING_FRAGMENT}
  mutation CreateCaseSectionSubjectScoring(
    $body: BodyCreateOrUpdateCaseSectionSubjectScoring!
  ) {
    createCaseSectionSubjectScoring(body: $body)
      @rest(
        type: "CreateCaseSectionScoring"
        path: "/scoring/case_section_subjects"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "CaseSectionSubjectEvaluationScoring") {
        ...CaseSectionSubjectEvaluationScoringFragment
      }
      success
    }
  }
`;

export const DeleteCaseSectionSubjectScoring = gql`
  ${CASE_SECTION_SUBJECT_EVALUATION_SCORING_FRAGMENT}
  mutation DeleteCaseSectionSubjectScoring($id: String!) {
    deleteCaseSectionSubjectScoring(id: $id)
      @rest(
        type: "DeleteCaseSectionScoring"
        path: "/scoring/case_section_subjects/{args.id}"
        method: "DELETE"
      ) {
      data @type(name: "CaseSectionSubjectEvaluationScoring") {
        ...CaseSectionSubjectEvaluationScoringFragment
      }
      success
    }
  }
`;
