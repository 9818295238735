import {
  PERMISSIONS,
  useCheckPermission,
  useRetentionContext,
} from '@aid-package/context';
import { useMemo } from 'react';

export function useDataRetentionController() {
  const retention = useRetentionContext();
  const { canShow } = useCheckPermission(
    PERMISSIONS.MANAGE_DATA_RETENTION,
    true
  );

  const showModal = useMemo(() => {
    return !retention?.id;
  }, [retention]);

  return { showModal: showModal && canShow };
}
