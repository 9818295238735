import {
  Fallback,
  Number,
  SelectDictionary,
  String,
  Cryptocurrency,
  Profile,
  SelectOptions,
  Fuzziness,
  Boolean,
  Date,
  SelectStatic,
  Image,
} from './components';
import { FormViewProps } from './types';
import { FormContainer } from './styles';
import { UnionField } from '../../controller/types';

function RenderField(field: UnionField) {
  const { type } = field;

  switch (type) {
    case 'string':
      return <String field={field} />;
    case 'number':
      return <Number field={field} />;
    case 'select_dictionary':
    case 'array_dictionary':
      return <SelectDictionary field={field} />;
    case 'select_options':
    case 'array_options':
      return <SelectOptions field={field} />;
    case 'fuzziness':
      return <Fuzziness field={field} />;
    case 'boolean':
      return <Boolean field={field} />;
    case 'date':
      return <Date field={field} />;
    case 'select_static':
      return <SelectStatic field={field} />;
    case 'cryptocurrency_symbol':
      return <Cryptocurrency field={field} />;
    case 'image':
      return <Image field={field} />;
    default:
      return null;
  }
}

export function Form({
  parsedSchema,
  loading,
  checkupKindCode,
}: FormViewProps) {
  if (loading) {
    return <Fallback name={checkupKindCode} />;
  }

  return (
    <FormContainer
      gridTemplateRows={`repeat(${parsedSchema?.length + 1}, max-content)`}
      gap="20"
    >
      {parsedSchema.map(RenderField)}
      <Profile />
    </FormContainer>
  );
}
