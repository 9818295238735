import {
  ContentLoader,
  DefaultButtonLoader,
  GridContainer,
  InputLoader,
} from '@aid-module/ui';

export function Fallback() {
  return (
    <GridContainer
      gap="28"
      gridTemplateRows="max-conent"
      gridTemplateColumns="1fr"
    >
      <InputLoader />
      <ContentLoader width="100%" height="31.2px">
        <rect rx="4px" ry="4px" width="100%" height="31.2px" />
      </ContentLoader>
      <GridContainer gap="20">
        <ContentLoader width="100%" height="418.59px">
          <rect rx="4px" ry="4px" width="100%" height="418.59px" />
        </ContentLoader>
        <GridContainer gridTemplateColumns="414px" justifyContent="flex-end">
          <DefaultButtonLoader />
        </GridContainer>
      </GridContainer>
    </GridContainer>
  );
}
