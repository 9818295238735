import { useMemo } from 'react';
import { PERMISSIONS, useCheckPermission } from '@aid-package/context';
import { CheckupsClientExternalRefTypeResponse } from '../constants';
import { ExternalRefsViewProps } from '../types';

export function useExternalRefsController(
  externalRefs: ExternalRefsViewProps['externalRefs']
) {
  const { canShow: canSubmissions } = useCheckPermission(
    PERMISSIONS.SUBMISSIONS_SEE_OTHERS,
    true
  );
  const { canShow: canTM } = useCheckPermission(PERMISSIONS.SEE_TM, true);
  const { canShow: canCases } = useCheckPermission(
    PERMISSIONS.SEE_CM_CASES,
    true
  );
  const { canShow: canProfiles } = useCheckPermission(
    PERMISSIONS.MANAGE_PROFILE,
    true
  );

  const availableTypes: CheckupsClientExternalRefTypeResponse[] =
    useMemo(() => {
      const types: CheckupsClientExternalRefTypeResponse[] = [];
      if (canSubmissions)
        types.push(
          CheckupsClientExternalRefTypeResponse.SUBMISSION,
          CheckupsClientExternalRefTypeResponse.FORM_SUBMISSION
        );
      if (canTM) types.push(CheckupsClientExternalRefTypeResponse.EVENT);
      if (canProfiles)
        types.push(CheckupsClientExternalRefTypeResponse.PROFILE);
      if (canCases) types.push(CheckupsClientExternalRefTypeResponse.CASE);
      return types;
    }, [canSubmissions, canTM, canCases, canProfiles]);

  const filteredExternalRefs = useMemo(() => {
    return externalRefs?.filter((it) =>
      availableTypes.includes(
        it?.external_entity_type as CheckupsClientExternalRefTypeResponse
      )
    );
  }, [externalRefs, availableTypes]);

  const count = useMemo(
    () => filteredExternalRefs && filteredExternalRefs.length - 1,
    [filteredExternalRefs]
  );

  const externalRef = useMemo(() => {
    return filteredExternalRefs && filteredExternalRefs[0];
  }, [filteredExternalRefs]);

  return {
    count,
    externalRef,
    filteredExternalRefs,
  };
}
