import gql from 'graphql-tag';

export const ProviderCredentials = gql`
  query ProviderCredentials($id: String!) {
    providerCredentials(id: $id)
      @rest(
        type: "ResponseProviderCredentials"
        path: "/checkups/admin/providers/{args.id}/credentials"
      ) {
      data @type(name: "ProviderCredentials") {
        id
        calculated_creds @type(name: "CalculatedCreds")
        creds @type(name: "Creds")
      }
      success
    }
  }
`;

export const ClientProviderCredentials = gql`
  query ClientProviderCredentials($id: String!) {
    clientProviderCredentials(id: $id)
      @rest(
        type: "ResponseClientProviderCredentials"
        path: "/checkups/admin/clients_providers/{args.id}/credentials"
      ) {
      data @type(name: "ClientProviderCredentials") {
        id
        calculated_creds @type(name: "CalculatedCreds")
        creds @type(name: "Creds")
      }
      success
    }
  }
`;

export const CheckupKindCredentials = gql`
  query CheckupKindCredentials($id: String!) {
    checkupKindCredentials(id: $id)
      @rest(
        type: "ResponseCheckupKindCredentials"
        path: "/checkups/admin/checkup_kinds/{args.id}/credentials"
      ) {
      data @type(name: "CheckupKindCredentials") {
        id
        calculated_creds @type(name: "CalculatedCreds")
        creds @type(name: "Creds")
      }
      success
    }
  }
`;

export const ClientCheckupKindCredentials = gql`
  query ClientCheckupKindCredentials($id: String!) {
    clientCheckupKindCredentials(id: $id)
      @rest(
        type: "ResponseClientCheckupKindCredentials"
        path: "/checkups/admin/checkup_kinds_clients/{args.id}/credentials"
      ) {
      data @type(name: "ClientCheckupKindCredentials") {
        id
        calculated_creds @type(name: "CalculatedCreds")
        creds @type(name: "Creds")
      }
      success
    }
  }
`;
