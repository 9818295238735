import gql from 'graphql-tag';

export const ChangeStateCdsFormMapping = gql`
  mutation ChangeStateCdsFormMapping(
    $id: String!
    $body: BodyChangeStateCdsFormMapping!
  ) {
    changeStateCdsFormMapping(id: $id, body: $body)
      @rest(
        type: "ChangeStateCdsFormMapping"
        path: "/cds/form_mappings/{args.id}/change_status"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "CdsFormMapping") {
        created_at
        form_configuration_id
        id
        status
        updated_at
        entity_templates @type(name: "CdsEntityTemplate") {
          created_at
          entity_type
          form_mapping_id
          id
          kind
          updated_at
        }
        field_mappings @type(name: "CdsFieldMapping") {
          created_at
          entity_template_id
          form_field_id
          form_field_reference_key
          form_mapping_id
          id
          map_to_field_name
          map_to_table_name
          mapper_klass
          updated_at
        }
      }
      success
    }
  }
`;
