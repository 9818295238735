import { MultiValue } from 'react-select';
import { TagSelectOption } from '../../../TagsSelect/component/types';
import { SelectOption } from '../../../Select/component/types';
import {
  SortFilters,
  SortOrder,
  VariablesStateParams,
} from './controller/types';

export interface ParseSort {
  parse: (
    name: string,
    order: SortOrder,
    prevSort?: SortFilters
  ) => SortFilters | undefined;
  parseSelected: (name: string, sort?: SortFilters) => SortOrder | undefined;
}

export interface VariablesContextParams {
  variables: VariablesStateParams;
  onChangeVariables: (
    callback: (prev: VariablesStateParams) => VariablesStateParams
  ) => void;
  onRemoveFilter: (key: string) => void;
  parseSort?: ParseSort;
  defaultVariables?: VariablesStateParams;
}

export function isMultiValue(
  value:
    | SelectOption
    | MultiValue<SelectOption>
    | MultiValue<TagSelectOption>
    | string[]
    | string
    | boolean
    | null
    | undefined
): value is MultiValue<SelectOption> | MultiValue<TagSelectOption> {
  if (value && typeof value !== 'string' && typeof value !== 'boolean') {
    return Array.isArray(value) && typeof value[0] !== 'string';
  }
  return false;
}

export interface VariablesProvidersViewProps {
  defaultVariables?: VariablesStateParams;
  parseSort?: ParseSort;
  cacheKey?: string;
}
