import { MouseEvent, useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { SelectOption, useSideWidgetProviderContext } from '@aid-module/ui';
import { useCheckupKindContext } from '../../../../../../../context';
import { FormProps } from '../../../types';
import { parseValues } from './utils';
import { useActionsModel } from '../model';
import { ActionsPropsView } from '../types';

export function useActionsController(
  name: ActionsPropsView['name'],
  parsedSchema: ActionsPropsView['parsedSchema']
) {
  const { pop, close } = useSideWidgetProviderContext();
  const { checkupKind } = useCheckupKindContext();
  const { createCheckup, loading } = useActionsModel();
  const { formState, handleSubmit } = useFormContext();

  const checkupKindCode = useMemo(
    () => name || checkupKind?.code || '',
    [name, checkupKind?.code]
  );

  const onSubmit = useCallback(
    async (values: FormProps) => {
      const data = parseValues(parsedSchema, values);
      const checkup_profiles_attributes = values.profiles
        ? values.profiles.map((v: SelectOption) => ({
            profile_id: v.value,
            profile_display_name: v.label,
          }))
        : [];
      const body = {
        checkup_kind_code: checkupKindCode,
        form: data,
        checkup_profiles_attributes,
      };
      const res = await createCheckup(body);
      if (res) {
        close();
      }
    },
    [createCheckup, checkupKindCode, close, parsedSchema]
  );

  const onClickSubmit = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      handleSubmit(onSubmit)(e);
    },
    [handleSubmit, onSubmit]
  );

  const onClickBack = useCallback(() => {
    pop();
  }, [pop]);

  return {
    disabled: !formState.isDirty || loading,
    onClickSubmit,
    onClickBack,
    loading,
  };
}
