import { useEffect, useRef } from 'react';
import { SupportController } from '../class';
import { AutomaticControllerTooltip } from '../types';

export function useAutomaticTooltipController(
  pattern: AutomaticControllerTooltip['pattern'],
  canShow: NonNullable<AutomaticControllerTooltip['canShow']>
) {
  const initiateRef = useRef<HTMLDivElement>(null);
  const checkedRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const controller = useRef<SupportController>();
  const themeRef = useRef(document.getElementById('theme'));

  useEffect(() => {
    if (initiateRef.current && containerRef.current) {
      controller.current = new SupportController(pattern, {
        initiateRef: initiateRef.current,
        containerRef: containerRef.current,
        checkedRef: checkedRef.current || undefined,
      });
      controller.current.canShow = canShow;
    }
    return () => {
      controller.current?.destroy();
      controller.current = undefined;

    };
  }, []);

  useEffect(() => {
    if (controller.current) {
      controller.current.canShow = canShow;
    }
  }, [canShow]);

  return {
    initiateRef,
    containerRef,
    checkedRef,
    themeRef,
  };
}
