import {
  ApolloLink,
  Operation,
  NextLink,
  Observable,
  FetchResult,
} from '@apollo/client';
import { createOperation } from '@apollo/client/link/utils';
import { ResponseTransactionCase } from '../../api';
import {
  CreateTransactionCase,
  TransactionCaseClose,
  TransactionCaseOpen,
  UpdateTransactionCaseOrigin,
  UpdateTransactionResolution,
} from '../../mutation';

export class TMLink extends ApolloLink {
  constructor() {
    super();
  }

  mutationCaseOpen(operation: Operation, forward: NextLink) {
    return new Observable<
      FetchResult<{ transactionCaseOpen: ResponseTransactionCase }>
    >((observer) => {
      const context = operation.getContext();
      const { id } = operation.variables || { id: '' };
      const closeOpreation = createOperation(context, {
        query: TransactionCaseOpen,
        variables: {
          id,
          body: {
            state: 'open',
          },
        },
        operationName: 'TransactionCaseOpen',
        extensions: operation.extensions,
      });
      forward(closeOpreation).subscribe(
        (data: FetchResult<Record<string, ResponseTransactionCase>>) => {
          if (data.data?.transactionCaseOpen?.data?.id) {
            const caseResolutionOperation = createOperation(context, {
              query: UpdateTransactionResolution,
              variables: {
                id,
                body: {
                  resolution_ids: [],
                },
              },
              operationName: 'UpdateTransactionResolution',
              extensions: operation.extensions,
            });
            forward(caseResolutionOperation).subscribe(
              (data: FetchResult<Record<string, ResponseTransactionCase>>) => {
                if (data.data) {
                  const nextData = {
                    ...data,
                    data: {
                      ...data.data,
                      transactionCaseOpen:
                        data.data.updateTransactionResolution,
                    },
                  };
                  observer.next(nextData);
                  observer.complete();
                }
                observer.error(new Error('Something went wrong'));
              }
            );
          } else if (data.data) {
            const nextData = {
              ...data,
              data: {
                ...data.data,
                transactionCaseOpen: data.data.transactionCaseClose,
              },
            };
            observer.next(nextData);
            observer.complete();
          } else {
            observer.error(new Error('Something went wrong'));
          }
        }
      );
    });
  }

  mutationCaseClose(operation: Operation, forward: NextLink) {
    return new Observable<
      FetchResult<{ transactionCaseClose: ResponseTransactionCase }>
    >((observer) => {
      const context = operation.getContext();
      const {
        body: { resolution_id, closed_at },
        id,
      } = operation.variables || { body: {}, id: '' };
      const closeOpreation = createOperation(context, {
        query: TransactionCaseClose,
        variables: {
          id,
          body: {
            state: 'closed',
            closed_at,
          },
        },
        operationName: 'TransactionCaseClose',
        extensions: operation.extensions,
      });
      forward(closeOpreation).subscribe(
        (data: FetchResult<Record<string, ResponseTransactionCase>>) => {
          if (resolution_id && data.data?.transactionCaseClose?.data?.id) {
            const caseResolutionOperation = createOperation(context, {
              query: UpdateTransactionResolution,
              variables: {
                id,
                body: {
                  resolution_ids: [resolution_id],
                },
              },
              operationName: 'UpdateTransactionResolution',
              extensions: operation.extensions,
            });
            forward(caseResolutionOperation).subscribe(
              (data: FetchResult<Record<string, ResponseTransactionCase>>) => {
                if (data.data) {
                  const nextData = {
                    ...data,
                    data: {
                      ...data.data,
                      transactionCaseClose:
                        data.data.updateTransactionResolution,
                    },
                  };
                  observer.next(nextData);
                  observer.complete();
                }
                observer.error(new Error('Something went wrong'));
              }
            );
          } else if (data.data) {
            const nextData = {
              ...data,
              data: {
                ...data.data,
                transactionCaseClose: data.data.transactionCaseClose,
              },
            };
            observer.next(nextData);
            observer.complete();
          } else {
            observer.error(new Error('Something went wrong'));
          }
        }
      );
    });
  }

  mutationCreateCase(operation: Operation, forward: NextLink) {
    return new Observable<
      FetchResult<{ createTransactionCase: ResponseTransactionCase }>
    >((observer) => {
      const context = operation.getContext();
      const {
        body: {
          case: { case_origin_id, ...createCaseParams },
        },
      } = operation.variables || { body: {} };
      const createCaseOpreation = createOperation(context, {
        query: CreateTransactionCase,
        variables: {
          body: {
            case: createCaseParams,
          },
        },
        operationName: 'CreateTransactionCase',
        extensions: operation.extensions,
      });
      forward(createCaseOpreation).subscribe(
        (data: FetchResult<Record<string, ResponseTransactionCase>>) => {
          if (case_origin_id && data.data?.createTransactionCase?.data?.id) {
            const caseOriginOperation = createOperation(context, {
              query: UpdateTransactionCaseOrigin,
              variables: {
                id: data.data?.createTransactionCase?.data?.id,
                body: {
                  case_origin_ids: [case_origin_id],
                },
              },
              operationName: 'UpdateTransactionCaseOrigin',
              extensions: operation.extensions,
            });
            forward(caseOriginOperation).subscribe(
              (data: FetchResult<Record<string, ResponseTransactionCase>>) => {
                if (data.data) {
                  const nextData = {
                    ...data,
                    data: {
                      ...data.data,
                      createTransactionCase:
                        data.data.updateTransactionCaseOrigin,
                    },
                  };
                  observer.next(nextData);
                  observer.complete();
                }
                observer.error(new Error('Something went wrong'));
              }
            );
          } else if (data.data) {
            const nextData = {
              ...data,
              data: {
                ...data.data,
                createTransactionCase: data.data.createTransactionCase,
              },
            };
            observer.next(nextData);
            observer.complete();
          } else {
            observer.error(new Error('Something went wrong'));
          }
        }
      );
    });
  }

  request(operation: Operation, forward: NextLink) {
    if (operation.operationName === 'CreateTransactionCase') {
      return this.mutationCreateCase(operation, forward);
    } else if (operation.operationName === 'TransactionCaseClose') {
      return this.mutationCaseClose(operation, forward);
    } else if (operation.operationName === 'TransactionCaseOpen') {
      return this.mutationCaseOpen(operation, forward);
    }
    return forward(operation);
  }
}
