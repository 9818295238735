import { GridContainer, InputLoader } from '@aid-module/ui';
import { PropsWithChildren } from 'react';

export function Fallback({ children }: PropsWithChildren<{}>) {
  return (
    <GridContainer gap="20">
      <InputLoader />
      {children}
    </GridContainer>
  );
}
