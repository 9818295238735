import { useInfoHitCardModel, useInfoHitCardWithSearcModel } from '../model';

export function useInfoHitCardController() {
  const { caSearchId, hit, loading, checkup } = useInfoHitCardModel();

  return {
    hit,
    loading,
    caSearchId,
    checkup,
  };
}

export function useInfoHitWithSearchCardController(caSearchId: string) {
  const { hit, loading } = useInfoHitCardWithSearcModel(caSearchId);

  return {
    hit,
    loading,
  };
}
