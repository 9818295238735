import {
  LoadableFunctionalComponent,
  TableWithHeaderLoader,
} from '@aid-module/ui';
import loadable from '@loadable/component';

export const PricingPlansRegister = loadable(() => import('./component'), {
  resolveComponent: (components) => components.PricingPlansRegisterComponent,
  fallback: <TableWithHeaderLoader />,
}) as LoadableFunctionalComponent<{}>;
