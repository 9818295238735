import gql from 'graphql-tag';
import { CASE_SECTION_IDENTITY_VERIFICATIONS_FRAGMENT } from './CaseSectionIdentityVerificationItem';

export const IDENTITY_VERIFICATIONS_FRAGMENT = gql`
  ${CASE_SECTION_IDENTITY_VERIFICATIONS_FRAGMENT}
  fragment IdentityVerificationsFragment on IdentityVerification {
    case_section @type(name: "CaseSectionIdentityVerification") {
      case_section_items @type(name: "CaseSectionIdentityVerificationItem") {
        ...CaseSectionIdentityVerificationItemFragment
      }
      case_section_items_count
      created_at
      id
      kind
      state
      updated_at
    }
  }
`;
