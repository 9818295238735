import { useMemo } from 'react';
import { TmCaseStatusViewProps } from '../types';
import { getColorByStatus } from './utils';
import { TM_CASE_DISPLAY_STATUSES } from '../constants';

export function useTmCaseStatusController(
  status: TmCaseStatusViewProps['status']
) {
  const background = useMemo(() => getColorByStatus(status), [status]);

  const text = useMemo(
    () =>
      status
        ? TM_CASE_DISPLAY_STATUSES[status] || status.replace('_', '  ')
        : '',
    [status]
  );

  return {
    text,
    background,
  };
}
