import gql from 'graphql-tag';
import { CA_ACTUAL_SEARCH_REVISION_FRAGMENT } from '../Ca/caActualSearchRevisionData';
import { SERVICE_CHECKUP_KIND_FRAGMENT } from '../CheckupKind';

export const SERVICE_CHECKUP_FRAGMENT = gql`
  ${SERVICE_CHECKUP_KIND_FRAGMENT}
  ${CA_ACTUAL_SEARCH_REVISION_FRAGMENT}
  fragment ServiceCheckupFragment on ServiceCheckup {
    audit_status
    assigned_to @type(name: "Account") {
      id
      email
      first_name
      last_name
      full_name
    }
    ca_search_id
    checkup_attachments @type(name: "Attachment") {
      id
    }
    checkup_kind @type(name: "CheckupKind") {
      ...ServiceCheckupKindFragment
    }
    data_retention @type(name: "DataRetentionInfo") {
      full_delete_date
      period_start_date
      retention_plan_id
      retention_state
      soft_delete_date
    }
    checkup_kind_code
    checkup_profiles @type(name: "CheckupProfile") {
      checkup_id
      id
      profile_display_name
      profile_id
    }
    created_at
    display_name
    execution_status
    execution_time
    external_refs @type(name: "ExternalRef") {
      id
      external_entity_id
      external_entity_type
      external_system
    }
    id
    is_cached
    is_migrated
    last_screened_at
    meta
    migrated_data @type(name: "MigratedData") {
      instant_checkup_field_submissions
        @type(name: "InstantCheckupFieldSubmission") {
        id
        name
        value
      }
      raw_result
    }
    provider_status
    resolution
    veriff_result_id
  }
`;
