import { useCallback, useState } from 'react';
import { useFilterTemplatesContext } from '../../../../../../context/FilterTemplates';
import { FiltersViewProps } from '../types';
import { NavigationOptions } from './types';

export function useFiltersController(
  toggleShowFilters: FiltersViewProps['toggleShowFilters']
) {
  const [navigation, setNavigation] = useState<NavigationOptions>({
    type: 'filters',
  });
  const { code } = useFilterTemplatesContext();
  const navigateTo = useCallback((next: NavigationOptions) => {
    setNavigation(next);
  }, []);

  return {
    context: { navigation, navigateTo, toggleShowFilters },
    isFilterTemplatesExist: !!code,
  };
}
