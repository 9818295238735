import { SecondaryButton } from '../Button';
import { AutomaticTooltip } from '../AutomaticTooltip';
import { Text } from '../Text';
import { useAssignToController } from './controller';
import { AssignToViewProps, AssignToButtonViewProps } from './types';
import { useMemo } from 'react';

function View({ initiateRef, disabled, onClick }: AssignToButtonViewProps) {
  return (
    <div ref={initiateRef}>
      <SecondaryButton
        disabled={disabled}
        icon="users"
        text="Assign to"
        onClick={onClick}
      />
    </div>
  );
}

export function AssignTo({
  permissions,
  id,
  additionalUserSelect,
  withoutNote,
  selectLabel,
  assign,
  button: Button,
  disabledProp,
  disabledText,
}: AssignToViewProps) {
  const { disabled, onClick, tooltipText } = useAssignToController(
    permissions,
    id,
    additionalUserSelect,
    withoutNote,
    selectLabel,
    disabledProp,
    assign,
    disabledText
  );

  const currentView = useMemo(() => {
    if (Button) {
      return <Button onClick={onClick} disabled={disabled} />;
    }
    return <View onClick={onClick} disabled={disabled} />;
  }, [onClick, Button, disabled]);

  return (
    <AutomaticTooltip
      canShow={disabled}
      pattern="top,right,left,bottom"
      background="main-deep-3"
      border="main-deep-3"
      view={currentView}
    >
      <Text color="white-primary" text={tooltipText} size={14} />
    </AutomaticTooltip>
  );
}

export type { AssignToButtonViewProps };
