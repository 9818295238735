import gql from 'graphql-tag';
import { REGION_FRAGMENT } from '../../../fragment/admin/regions';

export const Regions = gql`
  ${REGION_FRAGMENT}
  query Regions($page: Int, $q: String, $enabled: Boolean) {
    regions(page: $page, per: 20, q: $q, enabled: $enabled)
      @rest(type: "ResponseRegions", path: "/core/admin/regions?{args}") {
      data @type(name: "Region") {
        ...RegionFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const Region = gql`
  ${REGION_FRAGMENT}
  query Region($id: String!) {
    region(id: $id)
      @rest(type: "ResponseRegion", path: "/core/admin/regions/{args.id}") {
      data @type(name: "Region") {
        ...RegionFragment
      }
      success
    }
  }
`;
