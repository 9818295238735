import { ApolloLink, NextLink, Operation } from '@apollo/client';

class HandlerLink extends ApolloLink {
  private throttleTimer?: NodeJS.Timer;
  private lastOperationTime: number = new Date().getTime();

  needUpdateToken: (() => void) | undefined;

  throttlemousemove() {
    if (!this.throttleTimer) {
      this.throttleTimer = setTimeout(() => {
        this.throttleTimer = undefined;
        if (new Date().getTime() - this.lastOperationTime > 150000) {
          this.needUpdateToken?.();
        }
      }, 1000);
    }
  }

  addHandlers() {
    window.addEventListener('mousemove', this.throttlemousemove.bind(this));
  }

  removeHandlers() {
    window.removeEventListener('mousemove', this.throttlemousemove.bind(this));
  }

  request(operation: Operation, forward?: NextLink) {
    this.lastOperationTime = new Date().getTime();
    return forward?.(operation) || null;
  }
}

export default new HandlerLink();
