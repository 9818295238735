import { useEffect, useRef } from 'react';
import { VariableSizeList } from 'react-window';

export function useVirtualizerProviderController() {
  const virtualizer = useRef<VariableSizeList>(null);

  useEffect(() => {
    return () => {
      (virtualizer as any).current = null;
    };
  }, []);

  return { virtualizer };
}
