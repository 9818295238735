import { Text, GridContainer } from '@aid-module/ui';
import styled from '@emotion/styled';
import { EmotionContainerViewProps } from './types';

export const ActionContainer = styled(GridContainer)<EmotionContainerViewProps>`
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    `
    cursor: default;
    opacity: 0.6;
  `}
`;

export const Label = styled(Text)`
  text-decoration-line: underline;
`;
