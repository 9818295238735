import gql from 'graphql-tag';

export const AVAILABLE_FEATURE_FRAGMENT = gql`
  fragment AvailableFeatureFragment on AvailableFeature {
    code
    enabled_by_default
    disables_features_codes
    disables_case_sections
    parent_feature @type(name: "AvailableParentFeature") {
      code
      enabled_by_default
    }
  }
`;
