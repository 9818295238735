import { Select } from '@aid-module/ui';
import { StatusOptions } from './constants';
import { CaseStatusSelectViewProps } from './types';

export function CaseStatusSelect({
  label,
  placeholder,
  isClearable,
  value,
  onChange,
  isMulti,
}: CaseStatusSelectViewProps) {
  return (
    <Select
      onChange={onChange}
      options={StatusOptions}
      label={label}
      placeholder={placeholder}
      value={value}
      isClearable={isClearable}
      isMulti={isMulti}
    />
  );
}
