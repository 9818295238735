import { css } from '@emotion/react';
import { getColor } from '../../utils';

export const APP_SCROLL = css`
  *::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
    margin: 10px;
    margin-bottom: 15px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${getColor('main-deep-2')};
    border-radius: 20px;
    border: 1px solid ${getColor('main-deep-2')};
  }
`;
