import { gql } from '@apollo/client';

export const CreatePricingPlan = gql`
  mutation CreatePricingPlan($body: CreateOrUpdatePricingPlanBody!) {
    createPricingPlan(body: $body)
      @rest(
        type: "CreatePricingPlan"
        path: "/billing/admin/plans"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "PricingPlan") {
        billing_period
        created_at
        description
        expired_at
        id
        is_actual_revision
        is_additional
        kind
        name
        onboarding_enabled
        preview_body
        price
        reference_key
        revision_number
        started_at
        status
        updated_at
        subscriptions_count
        features @type(name: "PricingPlanFeature") {
          feature_template @type(name: "FeatureTemplate") {
            code
            created_at
            created_by_id
            description
            feature_dictionary @type(name: "FeatureDictionary") {
              code
              created_at
              created_by_id
              id
              is_system
              name
              status
              updated_at
            }
            feature_dictionary_id
            id
            kind
            name
            status
            updated_at
            is_system
          }
          feature_template_id
          id
          limit_rules {
            price
            start_count
            finish_count
          }
          name
          plan_id
          updated_at
          validity_period
        }
      }
      success
    }
  }
`;

export const EditPricingPlan = gql`
  mutation EditPricingPlan(
    $id: String!
    $body: CreateOrUpdatePricingPlanBody!
  ) {
    editPricingPlan(id: $id, body: $body)
      @rest(
        type: "EditPricingPlan"
        path: "/billing/admin/plans/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "PricingPlan") {
        billing_period
        created_at
        description
        expired_at
        id
        is_actual_revision
        is_additional
        kind
        name
        onboarding_enabled
        preview_body
        price
        reference_key
        revision_number
        started_at
        status
        updated_at
        subscriptions_count
        features @type(name: "PricingPlanFeature") {
          feature_template @type(name: "FeatureTemplate") {
            code
            created_at
            created_by_id
            description
            feature_dictionary @type(name: "FeatureDictionary") {
              code
              created_at
              created_by_id
              id
              is_system
              name
              status
              updated_at
            }
            feature_dictionary_id
            id
            kind
            name
            status
            updated_at
            is_system
          }
          feature_template_id
          id
          limit_rules {
            price
            start_count
            finish_count
          }
          name
          plan_id
          updated_at
          validity_period
        }
      }
      success
    }
  }
`;

export const NewRevisionPricingPlan = gql`
  mutation NewRevisionPricingPlan(
    $id: String!
    $body: CreateOrUpdatePricingPlanBody!
  ) {
    newRevisionPricingPlan(id: $id, body: $body)
      @rest(
        type: "NewRevisionPricingPlan"
        path: "/billing/admin/plans/{args.id}/create_revision"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "PricingPlan") {
        billing_period
        created_at
        description
        expired_at
        id
        is_actual_revision
        is_additional
        kind
        name
        onboarding_enabled
        preview_body
        price
        reference_key
        revision_number
        started_at
        status
        updated_at
        subscriptions_count
        features @type(name: "PricingPlanFeature") {
          feature_template @type(name: "FeatureTemplate") {
            code
            created_at
            created_by_id
            description
            feature_dictionary @type(name: "FeatureDictionary") {
              code
              created_at
              created_by_id
              id
              is_system
              name
              status
              updated_at
            }
            feature_dictionary_id
            id
            kind
            name
            status
            updated_at
            is_system
          }
          feature_template_id
          id
          limit_rules {
            price
            start_count
            finish_count
          }
          name
          plan_id
          updated_at
          validity_period
        }
      }
      success
    }
  }
`;

export const DuplicatePricingPlan = gql`
  mutation DuplicatePricingPlan(
    $id: String!
    $body: DuplicatePricingPlanBody!
  ) {
    duplicatePricingPlan(id: $id, body: $body)
      @rest(
        type: "DuplicatePricingPlan"
        path: "/billing/admin/plans/{args.id}/duplicate"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "PricingPlan") {
        billing_period
        created_at
        description
        expired_at
        id
        is_actual_revision
        is_additional
        kind
        name
        onboarding_enabled
        preview_body
        price
        reference_key
        revision_number
        started_at
        status
        updated_at
        subscriptions_count
        features @type(name: "PricingPlanFeature") {
          feature_template @type(name: "FeatureTemplate") {
            code
            created_at
            created_by_id
            description
            feature_dictionary @type(name: "FeatureDictionary") {
              code
              created_at
              created_by_id
              id
              is_system
              name
              status
              updated_at
            }
            feature_dictionary_id
            id
            kind
            name
            status
            updated_at
            is_system
          }
          feature_template_id
          id
          limit_rules {
            price
            start_count
            finish_count
          }
          name
          plan_id
          updated_at
          validity_period
        }
      }
      success
    }
  }
`;

export const ChangeStatusPricingPlan = gql`
  mutation ChangeStatusPricingPlan(
    $id: String!
    $body: ChangeStatusPricingPlanBody!
  ) {
    changeStatusPricingPlan(id: $id, body: $body)
      @rest(
        type: "DuplicatePricingPlan"
        path: "/billing/admin/plans/{args.id}/change_status"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "PricingPlan") {
        billing_period
        created_at
        description
        expired_at
        id
        is_actual_revision
        is_additional
        kind
        name
        onboarding_enabled
        preview_body
        price
        reference_key
        revision_number
        started_at
        status
        updated_at
        subscriptions_count
        features @type(name: "PricingPlanFeature") {
          feature_template @type(name: "FeatureTemplate") {
            code
            created_at
            created_by_id
            description
            feature_dictionary @type(name: "FeatureDictionary") {
              code
              created_at
              created_by_id
              id
              is_system
              name
              status
              updated_at
            }
            feature_dictionary_id
            id
            kind
            name
            status
            updated_at
            is_system
          }
          feature_template_id
          id
          limit_rules {
            price
            start_count
            finish_count
          }
          name
          plan_id
          updated_at
          validity_period
        }
      }
      success
    }
  }
`;
