import { LoadableFunctionalComponent } from '@aid-module/ui';
import loadable from '@loadable/component';
import { Fallback } from './components';

export const IdentityVerificationDescription = loadable(
  () => import('./component'),
  {
    resolveComponent: (components) =>
      components.IdentityVerificationDescriptionComponent,
    fallback: <Fallback />,
  }
) as LoadableFunctionalComponent<{}>;
