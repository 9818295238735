import { getColor, getSpace } from '@aid-module/ui';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const Container = styled(Link)`
  width: 195px;
  padding: 0px ${getSpace('20')};
  text-decoration: none;
  display: block;
`;

export const ActionContainer = styled(Container)`
  cursor: pointer;
  margin: -1px 0px;

  &:hover {
    & > div {
      border-bottom: 1px solid ${getColor('main-deep-1')}!important;
    }
    background: ${getColor('main-deep-1')};
  }
`;
