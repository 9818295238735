import { Container } from './styles';
import { useFilterTemplateController } from './controller';
import { FilterTemplateViewProps } from './types';
import { Text } from '../../../../../../../../../../Text';

export function FilterTemplate({ filterTemplate }: FilterTemplateViewProps) {
  const { onClick, isActive } = useFilterTemplateController(filterTemplate);
  return (
    <Container isActive={isActive} onClick={onClick}>
      <Text
        text={filterTemplate.name}
        size={12}
        color={isActive ? 'white-primary' : 'main-deep-5'}
      />
    </Container>
  );
}
