import { ContentContainer } from './styles';
import { ToNotifications } from './components';

export function NotificationsFooter() {
  return (
    <ContentContainer background="main-deep-1">
      <ToNotifications />
    </ContentContainer>
  );
}
