import { useClientContext } from '@aid-package/context';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { AccountsParams } from './types';

export function useAccountsModel() {
  const { client_id } = useParams();
  const { client } = useClientContext();
  const accounts = useMemo(
    () =>
      client?.accounts_clients?.reduce<AccountsParams>(
        (acc, client) => {
          if (!client) {
            return acc;
          }
          if (client.client?.id === client_id) {
            return { ...acc, currentClient: client };
          }
          return { ...acc, clients: [...acc.clients, client] };
        },
        { currentClient: undefined, clients: [] }
      ),
    [client_id, client]
  );

  return { currentClient: accounts?.currentClient, clients: accounts?.clients };
}
