import { ContentLoader, GridContainer } from '@aid-module/ui';

export function FormFallback() {
  return (
    <ContentLoader width="100%" height="253.97px">
      <rect rx="8" ry="8" width="100%" height="253.97px" />
    </ContentLoader>
  );
}

export function MainFallback() {
  return (
    <GridContainer gap="12">
      <ContentLoader width="100%" height="32.2px">
        <rect rx="8" ry="8" width="100%" height="32.2px" />
      </ContentLoader>
      <FormFallback />
    </GridContainer>
  );
}
