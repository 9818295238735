import { CollapsedLoader, ContentLoader, GridContainer } from '@aid-module/ui';

export function Fallback() {
  return (
    <GridContainer gap="20">
      <ContentLoader width="100%" height="308px">
        <rect rx="8" ry="8" width="100%" height="308px" />
      </ContentLoader>
      <CollapsedLoader />
      <CollapsedLoader />
    </GridContainer>
  );
}
