import gql from 'graphql-tag';
import { TRANSACTION_DATA_FRAGMENT } from './TransactionData';

export const TRANSACTION_EVENT_FRAGMENT = gql`
  ${TRANSACTION_DATA_FRAGMENT}
  fragment TransactionEventFragment on TransactionEvent {
    aml_payment_check_id
    arival_external_id
    arival_external_no
    checkup_result_state
    link_to_bca
    created_at
    id
    kind
    status
    support_key
    routing_type
    subtype
    scoring_matrix_event_result @type(name: "ScoringMatrixEventResult") {
      created_at
      error_messages
      id
      score
      state
    }
    transaction_data @type(name: "TransactionData") {
      ...TransactionDataFragment
    }
    unit21_external_id
  }
`;
