import styled from '@emotion/styled';
import { getColor, getSpace } from '../../../../../../../../../utils';
import { GridContainer } from '../../../../../../../../../containers';

export const ContentContainer = styled.div`
  padding: ${getSpace('20')};
  overflow-y: auto;
  overflow-x: hidden;
`;

export const ActionContainer = styled(GridContainer)`
  padding: ${getSpace('20')};
  background: ${getColor('main-deep-1')};
`;
