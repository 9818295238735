import { GridContainer, getSpace, Card as UICard } from '@aid-module/ui';
import styled from '@emotion/styled';

export const Container = styled(GridContainer)`
  position: relative;
  padding: ${getSpace('14')} ${getSpace('20')};
`;

export const RelativeContainer = styled(GridContainer)`
  position: relative;
`;

export const Card = styled(UICard)`
  border-radius: 0px;
  padding: ${getSpace('10')} ${getSpace('20')};
`;

export const ContentContainer = styled(GridContainer)`
  overflow: auto;
`;
