import { DefaultButtonLoader, GridContainer } from '@aid-module/ui';
import { FormLoader } from '../../../components';

export function Fallback() {
  return (
    <FormLoader>
      <GridContainer gap="20" gridTemplateColumns="repeat(3, 1fr)">
        <DefaultButtonLoader />
        <DefaultButtonLoader />
        <DefaultButtonLoader />
      </GridContainer>
    </FormLoader>
  );
}
