import { TYPES } from '@aid-package/api';
import { UnionField } from './types';
import { firstChartUppercase } from '../../../../../../Checkup/component/components/Result/components/ShuftiproKYBResult/components/Result/components/utils';

function getRequiredFields(required?: string[]) {
  if (required) {
    const obj: Record<string, boolean> = {};
    for (let i = 0; i < required?.length; i++) {
      obj[required[i]] = true;
    }
    return obj;
  }
  return {};
}

function findFirstSchema(
  schema:
    | TYPES.ServiceCheckupKindQuery['serviceCheckupKind']['data']['fields_schema']
    | TYPES.ServiceCheckupKindByCodeQuery['serviceCheckupKindByCode']['data']['fields_schema']
): any {
  if (Array.isArray(schema.oneOf)) {
    return findFirstSchema(schema.oneOf[0]);
  }
  return schema;
}

function getTitle(code: string, title?: string) {
  if (title) {
    return title;
  }
  return firstChartUppercase(code.replaceAll('_', ' ')) || '';
}

function getStaticField(code: string, field: any): UnionField | undefined {
  const defaultField = { title: getTitle(code, field.title) };
  switch (code) {
    case 'blockchain':
      return {
        type: 'blockchain',
        code,
        ...defaultField,
      };
    case 'birth_year':
      return {
        type: 'select_static',
        static_name: 'years',
        code,
        ...defaultField,
      };
    case 'date_of_birth':
      return { type: 'date', code, ...defaultField };
    case 'fuzziness':
      return {
        type: 'fuzziness',
        code,
        ...defaultField,
      };
    case 'cryptocurrency_symbol':
      return {
        type: 'cryptocurrency_symbol',
        code,
        dictionary_name: field.dictionary_name,
        ...defaultField,
      };
    case 'drivers_license_back_image_id':
    case 'drivers_license_front_image_id':
    case 'face_image_id':
    case 'id_card_back_image_id':
    case 'id_card_front_image_id':
    case 'passport_image_id':
    case 'face_photo_id':
    case 'document_photo_back_id':
    case 'document_photo_front_id':
      return {
        type: 'image',
        code,
        ...defaultField,
      };
    default:
      return;
  }
}

function getFieldByType(
  code: string,
  type: 'string' | 'number' | 'boolean' | 'array',
  field: any
): UnionField | undefined {
  const defaultField = { title: getTitle(code, field.title) };
  switch (type) {
    case 'string': {
      if (field.dictionary_name) {
        return {
          type: 'select_dictionary',
          dictionary_name: field.dictionary_name,
          code,
          ...defaultField,
        };
      } else if (Array.isArray(field.enum)) {
        return {
          type: 'select_options',
          options: field.enum.map((item: string) => ({
            label: item,
            value: item,
          })),
          code,
          ...defaultField,
        };
      }
      return {
        type,
        code,
        ...defaultField,
        ...(field.pattern
          ? { validation: { pattern: new RegExp(field.pattern) } }
          : {}),
      };
    }
    case 'number':
      return {
        type,
        code,
        ...defaultField,
        ...(typeof field.minimum === 'number' ||
        typeof field.maximum === 'number'
          ? { validation: { min: field.minimum, max: field.maximum } }
          : {}),
      };
    case 'boolean':
      return {
        type,
        code,
        ...defaultField,
      };
    case 'array': {
      if (field.dictionary_name) {
        return {
          type: 'array_dictionary',
          dictionary_name: field.dictionary_name,
          code,
          ...defaultField,
        };
      }
      return {
        type: 'array_options',
        options:
          field.enum?.map((item: string) => ({
            label: item,
            value: item,
          })) || [],
        code,
        ...defaultField,
      };
    }
  }
}

function getField(code: string, field: any): UnionField | undefined {
  const staticField = getStaticField(code, field);
  if (staticField) {
    return staticField;
  } else if (field.type) {
    if (Array.isArray(field.type)) {
      let parsedField: UnionField | undefined = undefined;
      for (let i = 0; i < field.type.length; i++) {
        if (field.type[i] !== 'null') {
          parsedField = getFieldByType(code, field.type[i], field);
          break;
        }
      }
      return parsedField;
    } else {
      return getFieldByType(code, field.type, field);
    }
  } else if (field.oneOf) {
    let parsedField: UnionField | undefined = undefined;
    for (let i = 0; i < field.oneOf.length; i++) {
      const prop = field.oneOf[i];
      if (prop.type !== 'null' && !prop.deprecated) {
        parsedField = getFieldByType(code, prop.type, field);
        break;
      }
    }
    return parsedField;
  }
}

export function parseSchema(
  schema:
    | TYPES.ServiceCheckupKindQuery['serviceCheckupKind']['data']['fields_schema']
    | TYPES.ServiceCheckupKindByCodeQuery['serviceCheckupKindByCode']['data']['fields_schema']
) {
  const firstSchema = findFirstSchema(schema);
  const requiredFields = getRequiredFields(firstSchema.required);
  const properties = Object.entries(firstSchema.properties || {});

  const fields = [];
  for (let i = 0; i < properties.length; i++) {
    const [key, value] = properties[i];
    const field = getField(key, value);
    if (field) {
      field.required = requiredFields[field.code];
      fields.push(field);
    }
  }
  return fields;
}
