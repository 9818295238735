import { ContentLoader, GridContainer } from '@aid-module/ui';
import { Fallback as FormFallback } from '../../../../Context/ApiForm/components/Fallback';

export function TabsFallback() {
  return (
    <GridContainer gap="20">
      <ContentLoader width="100%" height="36.2">
        <rect rx={8} ry={8} width="100%" height="36.2" />
      </ContentLoader>
      <FormFallback />
    </GridContainer>
  );
}
