import { ProfileCardViewProps } from '../../types';
import { Text } from '../../../Text';
import { useDefaultNameController } from './constroller';
import { GridContainer } from '../../../../containers';
import { ExtendAccountType } from '../../types';

export function DefaultName<T extends ExtendAccountType>({
  account,
}: Pick<ProfileCardViewProps<T>, 'account'>) {
  const { name, subtitle } = useDefaultNameController(account);

  return (
    <GridContainer gap="6">
      <Text text={name || ''} size={24} uppercase weight="VeryBig" />
      <Text
        text={subtitle || ''}
        color="main-deep-4"
        size={14}
        uppercase
        weight="Medium"
      />
    </GridContainer>
  );
}
