import { DefaultButtonLoader, PrimaryButton } from '@aid-module/ui';
import { useActionController } from './controller';

export function Action() {
  const { onClick } = useActionController();

  return (
    <PrimaryButton
      fallback={<DefaultButtonLoader width={155.91} />}
      icon="plus"
      text="New Statistic"
      onClick={onClick}
    />
  );
}
