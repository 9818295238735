export interface PermissionsParams {
  [key: string]: boolean;
}

export interface PermissionsParentParams {
  [key: string]: PermissionsParams | undefined;
}

export interface PermissionsContextParams {
  role?: {
    [key: string]: boolean;
  };
  permissions?: PermissionsParentParams;
  client_id?: string;
}

export enum PERMISSIONS {
  CLIENT_MANAGE = 'permissions.client.client_manage_settings',
  CLIENT_UPDATE_PROTECTED_TAG = 'permissions.client.client_protected_tags_update',
  CLIENT_INVITE_USERS = 'permissions.client.client_invite_users',
  MANAGE_WATERMARK = 'permissions.client.manage_watermark',
  MANAGE_PROFILE = 'permissions.profiles.manage_profiles',
  MANAGE_BLACKLIST_PROFILES = 'permissions.profiles.manage_blacklisted_profiles',
  REFILL_BALANCE = 'permissions.client.client_refill_balance',
  SEE_DASHBOARD = 'permissions.client.see_dashboard',
  SEE_DASHBOARD_VERIFICATION = 'permissions.client.see_dashboard_verification',
  SEE_TM_DASHBOARD = 'permissions.client.see_dashboard_tm',
  SEE_BALANCE = 'permissions.client.client_see_balance',
  MANAGE_RESOLUTION_ITEMS = 'permissions.client.manage_resolution_items',
  MANAGE_CASE_REJECTION_REASONS = 'permissions.client.manage_case_rejection_reasons',
  SEE_TM = 'permissions.transaction_monitoring.see_tm',
  SEE_TM_CASES = 'permissions.transaction_monitoring.see_tm_cases',
  SEE_CM_CASES = 'permissions.case_management.see_cases',
  SEE_CM_COMMENTS = 'permissions.case_management.see_comments',
  MANAGE_CM_COMMENTS = 'permissions.case_management.manage_comments',
  SEE_CM_DOCUMENTS = 'permissions.case_management.see_supporting_documents',
  MANAGE_CM_DOCUMENTS = 'permissions.case_management.manage_supporting_documents',
  MANAGE_RFI_CASES = 'permissions.case_management.can_request_for_information_cases',
  MANAGE_PRE_REJECTED_CASES = 'permissions.case_management.can_pre_reject_cases',
  SEE_SHARED_CASES = 'permissions.case_management.see_shared_cases',
  SEE_APPROVED_CM_CASES = 'permissions.case_management.see_approved_cases',
  MANAGE_AI = 'permissions.case_management.manage_ai',
  SEE_AI = 'permissions.case_management.see_ai',
  USE_AI = 'permissions.case_management.use_ai',
  MANAGE_NAICS = 'permissions.case_management.manage_naics',
  MANAGE_TM = 'permissions.transaction_monitoring.manage_tm_settings',
  MANAGE_TM_CASES = 'permissions.transaction_monitoring.manage_tm_cases',
  MANAGE_TM_CASES_SET_PRE_OPEN_STATUS_TO_CASE = 'permissions.transaction_monitoring.set_pre_open_status_to_case',
  MANAGE_TM_CASES_SET_OPEN_STATUS_TO_CASE = 'permissions.transaction_monitoring.set_open_status_to_case',
  MANAGE_TM_CASES_SET_CLOSED_STATUS_TO_CASE = 'permissions.transaction_monitoring.set_closed_status_to_case',
  MANAGE_TM_CASES_SET_PRE_CLOSED_STATUS_TO_CASE = 'permissions.transaction_monitoring.set_pre_closed_status_to_case',
  SEE_MONITORING_ALERTS = 'permissions.transaction_monitoring.see_monitoring_alerts',
  MANAGE_MONITORING_ALERTS = 'permissions.transaction_monitoring.manage_monitoring_alerts',
  SEE_SCREENING_ALERTS = 'permissions.transaction_monitoring.see_screening_alerts',
  MANAGE_SCREENING_ALERTS = 'permissions.transaction_monitoring.manage_screening_alerts',
  TM_ASSIGN = 'permissions.transaction_monitoring.you_can_assign_investigations_and_alerts',
  MANAGE_CM_CASES = 'permissions.case_management.manage_cases',
  MANAGE_SHARED_CASES = 'permissions.case_management.manage_shared_cases',
  SEE_AML_CASES = 'permissions.case_management.see_aml',
  SEE_KYB_CASES = 'permissions.case_management.see_kyb',
  SEE_ELLIPTIC_WALLET_CASES = 'permissions.case_management.see_elliptic_wallet',
  REJECT_CM_CASES = 'permissions.case_management.can_reject_cases',
  APPROVE_CM_CASES = 'permissions.case_management.can_approve_cases',
  REJECT_CM_SECTIONS = 'permissions.case_management.can_reject_sections',
  APPROVE_CM_SECTIONS = 'permissions.case_management.can_approve_sections',
  AML_BUSINESS = 'permissions.screenings.aml_business_checkups_update_perform_instant',
  CA_AML_BUSINESS = 'permissions.screenings.ca_aml_business',
  AML_INDIVIDUAL = 'permissions.screenings.kyc_aml_background_checkups_perform_instant',
  CA_AML_INDIVIDUAL = 'permissions.screenings.ca_aml_individual',
  ELLIPTIC = 'permissions.screenings.elliptic_wallet_checkups_perform_instant',
  BUS_REGISTRY = 'permissions.screenings.know_your_business_checkups_perform_instant',
  AID_CHECKUPS_PERFORM = 'permissions.screenings.aid_checkups_perform_instant',
  CHECKUPS_PERFORM_INSTANT = 'permissions.screenings.checkups_perform_instant',
  UNIT_21_KYB = 'permissions.screenings.unit_21_entity_id_checkups_perform_instant',
  UNIT_21_KYB_DOC = 'permissions.screenings.unit_21_entity_doc_checkups_perform_instant',
  MAXMIND_GEO_IP = 'permissions.screenings.maxmind_geo_ip',
  UNIT_21_KYB_US = 'permissions.screenings.unit_21_entity_kyb_us_checkups_perform_instant',
  UNIT_21_KYB_WORLD = 'permissions.screenings.unit_21_entity_kyb_world_checkups_perform_instant',
  KYC_DOC_VERIFICATION = 'permissions.screenings.detailed_kyc_checkups_perform_instant',
  VERIFF_VERIFICATION = 'permissions.screenings.veriff_identity_verification_perform_instant',
  ADMIN = 'role.admin',
  FORMS_CREATE = 'permissions.forms.forms_create',
  FORMS_UPDATE = 'permissions.forms.forms_update',
  FORMS_PUBLISH = 'permissions.forms.forms_publish',
  SUBMISSIONS = 'permissions.submissions',
  SUBMISSIONS_SEE_OTHERS = 'permissions.submissions.submissions_see_others',
  SUBMISSIONS_UPDATE_OTHERS = 'permissions.submissions.submissions_update_others',
  SCORING_EDIT_MODEL = 'permissions.scoring.edit_scoring_model',
  SCORING_EDIT_SUBMISSION_EVALUATIONS = 'permissions.scoring.edit_scoring_submission_evaluations',
  MANAGE_ADDITIONAL_RISK_SCORING_TEMPLATES = 'permissions.client.manage_additional_risk_scoring_templates',
  MANAGE_ADDITIONAL_RISK_SCORING = 'permissions.case_management.manage_additional_risk_scoring',
  FINCEN_BUSINESS = 'permissions.screenings.fincen_business',
  FINCEN_INDIVIDUAL = 'permissions.screenings.fincen_person',
  FINCEN_LIST = 'permissions.screenings.fincen_list',
  BLACKLIST_BUSINESS = 'permissions.screenings.blacklist_business',
  BLACKLIST_INDIVIDUAL = 'permissions.screenings.blacklist_person',
  MANEGE_ENABLED_DISABLED_USER = 'permissions.client.manage_enabled_disabled_users',
  SEE_SUBMISSIONS_STATISTICS = 'permissions.submissions.see_submissions_statistics',
  MANAGE_PROHIBITED_COUNTRIES = 'permissions.client.manage_prohibited_countries',
  MANAGE_BLACKLISTED_DEVICE_INFO = 'permissions.client.manage_blacklisted_device_info',
  MANAGE_STOP_WORD_FINCEN = 'permissions.screenings.manage_stop_word_fincen',
  MANAGE_REQUIRED_ACTIONS_SOURCE = 'permissions.case_management.manage_required_action_source',
  MANAGE_REQUIRED_ACTIONS = 'permissions.case_management.manage_required_action',
  MANAGE_REMINDERS = 'permissions.case_management.manage_reminders',
  REMINDER_SETTINGS = 'permissions.client.reminder_settings',
  MANAGE_DICTIONARIES = 'permissions.client.manage_dictionaries',
  MANAGE_CUSTOMER_DOCUMENTS = 'permissions.client.manage_customer_documents',
  FORMS_MAPPINGS = 'permissions.forms.forms_mappings',
  SEE_CM_CASES_LIST = 'permissions.case_management.see_only_cases_list',
  SEE_PROFILES_LIST = 'permissions.profiles.see_only_profiles_list',
  SEE_PROFILE = 'permissions.profiles.see_profiles',
  SEE_PROFILE_CONFIDENTIAL = 'permissions.profiles.confidential_profiles',
  SEE_SUBMISSIONS_LIST = 'permissions.submissions.see_only_submissions_list',
  SEE_SUBMISSION = 'permissions.submissions.see_submissions',
  SEE_SUBMISSION_CONFIDENTIAL = 'permissions.submissions.confidential_submissions',
  CM_IDENTITY_VERIFICATIONS = 'permissions.case_management.identity_verifications',
  SEE_CUSTOMER_MESSAGES = 'permissions.client.see_customer_messages',
  MANAGE_CUSTOMER_MESSAGES = 'permissions.client.manage_customer_messages',
  MANAGE_EMAIL_TEMPLATES = 'permissions.client.manage_email_templates',
  SEE_CM_GEOIP = 'permissions.case_management.see_geo_ip_screenings',
  SEE_GLOBAL_SEARCH_HISTORY = 'permissions.client.see_search_history',
  MANAGE_TM_SCORING_RULES = 'permissions.transaction_monitoring.manage_scoring_rules',
  SEE_TM_SCORING_RESULTS = 'permissions.transaction_monitoring.see_scoring_rule_results',
  RUN_TM_TEST_MATRICES = 'permissions.transaction_monitoring.run_test_matrices',
  SEE_SHUFTIPRO_AML_INDIVIDUAL = 'permissions.screenings.aml_shuftipro_checkups_view',
  SEE_SHUFTIPRO_AML_BUSINESS = 'permissions.screenings.aml_business_shuftipro_checkups_view',
  MANAGE_CLIENT_SUBSCRIPTIONS = 'permissions.client.manage_subscriptions',
  SEE_CASE_RELATED_TRANSACTIONS = 'permissions.transaction_monitoring.see_shared_tm_transactions',
  FILL_SAR_REPORT = 'permissions.transaction_monitoring.fill_sar_reports',
  MANAGE_SAR_REPORT = 'permissions.transaction_monitoring.manage_sar_reports',
  MANAGE_EDD_REPORTS = 'permissions.case_management.manage_edd_reports',
  MANAGE_DATA_RETENTION = 'permissions.client.manage_data_retention',
}

export const PERMISSIONS_PROFILE = [
  PERMISSIONS.MANAGE_PROFILE,
  PERMISSIONS.SEE_PROFILE,
  PERMISSIONS.SEE_PROFILE_CONFIDENTIAL,
];

export const PERMISSIONS_PROFILES = [
  PERMISSIONS.MANAGE_PROFILE,
  PERMISSIONS.SEE_PROFILES_LIST,
  PERMISSIONS.SEE_PROFILE,
  PERMISSIONS.SEE_PROFILE_CONFIDENTIAL,
];

export const PERMISSIONS_CM_CASE = [
  PERMISSIONS.SEE_SHARED_CASES,
  PERMISSIONS.SEE_CM_CASES,
  PERMISSIONS.MANAGE_CM_CASES,
  PERMISSIONS.SEE_APPROVED_CM_CASES,
];

export const PERMISSIONS_CM_CASES = [
  PERMISSIONS.SEE_SHARED_CASES,
  PERMISSIONS.SEE_CM_CASES,
  PERMISSIONS.SEE_CM_CASES_LIST,
  PERMISSIONS.MANAGE_CM_CASES,
  PERMISSIONS.SEE_APPROVED_CM_CASES,
];

export const PERMISSIONS_SUBMISSION = [
  PERMISSIONS.SEE_SUBMISSION,
  PERMISSIONS.SEE_SUBMISSION_CONFIDENTIAL,
  PERMISSIONS.SUBMISSIONS_SEE_OTHERS,
  PERMISSIONS.SUBMISSIONS_UPDATE_OTHERS,
];

export const PERMISSIONS_SUBMISSIONS = [
  PERMISSIONS.SEE_SUBMISSIONS_LIST,
  PERMISSIONS.SEE_SUBMISSION,
  PERMISSIONS.SEE_SUBMISSION_CONFIDENTIAL,
  PERMISSIONS.SUBMISSIONS_SEE_OTHERS,
  PERMISSIONS.SUBMISSIONS_UPDATE_OTHERS,
];

export const GLOBAL_SEARCH_PERMISSIONS = [
  PERMISSIONS.CM_IDENTITY_VERIFICATIONS,
  PERMISSIONS.CHECKUPS_PERFORM_INSTANT,
  PERMISSIONS.SEE_TM,
  ...PERMISSIONS_CM_CASES,
  ...PERMISSIONS_PROFILES,
  ...PERMISSIONS_SUBMISSIONS,
];

export const PERMISSIONS_CHECKUPS = [
  PERMISSIONS.CLIENT_MANAGE,
  PERMISSIONS.CHECKUPS_PERFORM_INSTANT,
  PERMISSIONS.AML_BUSINESS,
  PERMISSIONS.AML_INDIVIDUAL,
  PERMISSIONS.BUS_REGISTRY,
  PERMISSIONS.ELLIPTIC,
  PERMISSIONS.UNIT_21_KYB,
  PERMISSIONS.UNIT_21_KYB_DOC,
  PERMISSIONS.UNIT_21_KYB_US,
  PERMISSIONS.UNIT_21_KYB_WORLD,
  PERMISSIONS.CA_AML_INDIVIDUAL,
  PERMISSIONS.CA_AML_BUSINESS,
  PERMISSIONS.FINCEN_LIST,
  PERMISSIONS.FINCEN_INDIVIDUAL,
  PERMISSIONS.FINCEN_BUSINESS,
  PERMISSIONS.BLACKLIST_BUSINESS,
  PERMISSIONS.BLACKLIST_INDIVIDUAL,
  PERMISSIONS.MANAGE_PROHIBITED_COUNTRIES,
  PERMISSIONS.MANAGE_BLACKLISTED_DEVICE_INFO,
  PERMISSIONS.MANAGE_STOP_WORD_FINCEN,
  PERMISSIONS.SEE_SHUFTIPRO_AML_BUSINESS,
  PERMISSIONS.SEE_SHUFTIPRO_AML_INDIVIDUAL,
];
