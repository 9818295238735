import gql from 'graphql-tag';
import { SCORING_AUDIT_LOG_FRAGMENT } from './ScoringAuditLog';

export const CASE_SECTION_SUBJECT_EVALUATION_SCORING_FRAGMENT = gql`
  ${SCORING_AUDIT_LOG_FRAGMENT}
  fragment CaseSectionSubjectEvaluationScoringFragment on CaseSectionSubjectEvaluationScoring {
    audit_logs @type(name: "ScoringAuditLog") {
      ...ScoringAuditLogFragment
    }
    coefficient
    case_id
    case_section_id
    case_section_subject_id
    case_section_subject_name
    created_at
    id
    is_changed_manually
    updated_at
    value
    value_enabled
  }
`;
