import gql from 'graphql-tag';
import { REGION_FRAGMENT } from '../../../fragment/admin/regions';

export const RegionUpdate = gql`
  ${REGION_FRAGMENT}
  mutation RegionUpdate($id: String!, $body: BodyRegionUpdate) {
    updateRegion(id: $id, body: $body)
      @rest(
        type: "RegionUpdate"
        path: "/core/admin/regions/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "Region") {
        ...RegionFragment
      }
      success
    }
  }
`;
