import { Controller, FormProvider } from 'react-hook-form';
import { useSelectController } from './controller';
import { FormRenderProps } from './types';
import { ProfileSelect } from './components';

function renderProfile({
  field: { value, onChange },
  fieldState: { error },
}: FormRenderProps<'profiles'>) {
  return <ProfileSelect error={error} value={value} onChange={onChange} />;
}

export function Select() {
  const { formContext } = useSelectController();

  return (
    <FormProvider {...formContext}>
      <Controller name="profiles" render={renderProfile} />
    </FormProvider>
  );
}
