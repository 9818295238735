import { getSpace } from '@aid-module/ui';
import styled from '@emotion/styled/macro';

export const ShadowContainer = styled.div`
  position: absolute;
  top: ${getSpace('40')};
  right: -20px;
  padding-top: ${getSpace('10')};
  z-index: 999999;
  width: 260px;
`;

export const Container = styled.div`
  position: relative;
  cursor: default;
`;
