import gql from 'graphql-tag';
import { VERIFICATION_FLOW_FRAGMENT } from '../../../fragment/verification';

export const VerificationFlowCreate = gql`
  ${VERIFICATION_FLOW_FRAGMENT}
  mutation VerificationFlowCreate($body: BodyCreateOrUpdateVerificationFlow!) {
    createVerificationFlow(body: $body)
      @rest(
        type: "VerificationFlowCreate"
        path: "/core/client/verification_flows"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "VerificationFlow") {
        ...VerificationFlowFragment
      }
      success
    }
  }
`;

export const VerificationFlowUpdate = gql`
  ${VERIFICATION_FLOW_FRAGMENT}
  mutation VerificationFlowUpdate(
    $id: String!
    $body: BodyCreateOrUpdateVerificationFlow!
  ) {
    updateVerificationFlow(id: $id, body: $body)
      @rest(
        type: "VerificationFlowUpdate"
        path: "/core/client/verification_flows/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "VerificationFlow") {
        ...VerificationFlowFragment
      }
      success
    }
  }
`;
