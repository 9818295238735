import { Select } from '@aid-module/ui';
import { BlockchainViewProps } from './types';
import { useBlockchainController } from './controller';

export function Blockchain({
  field: { value, onChange },
  fieldState: { error },
}: BlockchainViewProps) {
  const { isEnabledBlockchain, blockchainOptions } = useBlockchainController();

  if (!isEnabledBlockchain) {
    return null;
  }

  return (
    <Select
      label="Blockchain *"
      placeholder="Select blockchain..."
      error={error}
      value={value}
      onChange={onChange}
      options={blockchainOptions}
    />
  );
}

