import { useCallback, useMemo } from 'react';
import { useVariablesContext } from '../../../../../../../../../../../Table/context';
import { FilterTemplateViewProps } from '../types';
import { useDropdownButtonContext } from '../../../../../../../../../../../DropdownButton';

export function useFilterTemplateController(
  filterTemplate: FilterTemplateViewProps['filterTemplate']
) {
  const { onChangeVariables, variables } = useVariablesContext();
  const { closeDropdown } = useDropdownButtonContext();

  const onClick = useCallback(() => {
    onChangeVariables((prev) => ({
      ...prev,
      filters: undefined,
      q: prev?.q,
      filterTemplate:
        prev.filterTemplate?.id === filterTemplate.id
          ? undefined
          : filterTemplate,
    }));
    closeDropdown();
  }, [onChangeVariables, filterTemplate]);

  const isActive = useMemo(
    () => filterTemplate.id === variables.filterTemplate?.id,
    [variables.filterTemplate, filterTemplate]
  );

  return { onClick, isActive };
}
