import {
  ApolloLink,
  Operation,
  NextLink,
  Observable,
  FetchResult,
  gql,
} from '@apollo/client';
import axios, { AxiosResponse } from 'axios';
import { ResponseDocument } from '../../api';

const API_HOST = process.env.API_HOST || process.env.REACT_APP_API_HOST;

export class UploadLink extends ApolloLink {
  constructor() {
    super();
  }

  uploadFile(
    operation: Operation,
    file: File,
    kind: string,
    service: string,
    isPublic?: boolean
  ) {
    return new Observable<FetchResult<{ uploadDocument: ResponseDocument }>>(
      (observer) => {
        const context = operation.getContext();
        const formData = new FormData();
        const fileName = file.name || kind;
        formData.append('file_name', fileName);
        formData.append('kind', kind);
        formData.append('service_name', service);
        formData.append('file_size', file.size.toString());
        
        const client_id = context.headers['x-client-id'];
        axios({
          method: 'post',
          url: `${API_HOST}/documents/${
            isPublic ? 'public_upload' : `upload`
          }/safe${client_id ? `?client_id=${client_id}` : ''}`,
          data: formData,
          headers: context.headers,
          withCredentials: true,
        })
          .then((resSafe: AxiosResponse<ResponseDocument>) => {
            const resData = resSafe?.data as ResponseDocument;
            const { cache } = operation.getContext();
            cache.writeFragment({
              id: `Document:${resData.data?.id}`,
              fragment: gql`
                fragment CreatedFile on Document {
                  file_size
                  file_path
                  file_name
                  safe_upload_url
                  safe_download_url
                  av_status
                  id
                }
              `,
              data: { ...resData.data, __typename: 'Document' },
            });
            if (resData?.success && resData?.data?.safe_upload_url) {
              const uploadUrl = resData?.data?.safe_upload_url;
              let headers = {};
              if (uploadUrl.includes('x-amz-server-side-encryption')) {
                headers = {
                  'x-amz-server-side-encryption': 'AES256'
                }
              }
              fetch(uploadUrl, {
                method: 'PUT',
                body: file,
                headers
              })
                .then((resAWS: Response) => {
                  if (resAWS.status === 200) {
                    observer.next({
                      data: {
                        uploadDocument: {
                          ...resData,
                          errors: [],
                        },
                      },
                    });
                    observer.complete();
                  } else {
                    observer.error('Upload file error');
                  }
                })
                .catch((e) => {
                  observer.error(e);
                });
            } else {
              observer.error('Create safe file error');
            }
          })
          .catch((e) => {
            observer.error(e);
          });
      }
    );
  }

  uploadOperation(operation: Operation) {
    const {
      variables: { file, kind, isPublic, service = 'core' },
    } = operation;
    return this.uploadFile(operation, file, kind, service, isPublic);
  }

  request(operation: Operation, forward: NextLink) {
    switch (operation.operationName) {
      case 'UploadDocument':
        return this.uploadOperation(operation);
      default:
        return forward(operation);
    }
  }
}
