import { MouseEvent, useCallback, useMemo } from 'react';
import { useWatch, useFormContext } from 'react-hook-form';
import { FormProps } from '../../../types';
import { useActionsModel } from '../model';
import { convertLocalToUTCDate } from './utils';
import { useNavigate, useParams } from 'react-router-dom';
import { useSideWidgetProviderContext } from '@aid-module/ui';

export function useActionsController() {
  const { client_id } = useParams();
  const { close } = useSideWidgetProviderContext();
  const navigate = useNavigate();
  const { create, loading } = useActionsModel();
  const referenceKeys = useWatch({
    name: 'form_field_reference_keys',
    defaultValue: [],
  });

  const { handleSubmit } = useFormContext<FormProps>();

  const normalizeData = useCallback((values: FormProps) => {
    const {
      reference_key,
      form_field_reference_keys,
      date_from,
      date_to,
      submission_statuses,
      case_statuses,
      bank_tariff_names,
      ...any
    } = values;
    const caseStatuses = case_statuses?.map((item) => item?.value);
    const submissionStatuses = submission_statuses?.map((item) => item?.value);

    const params = {
      form_configuration_reference_key: reference_key.value,
      form_configuration_id: reference_key.id,
      form_field_reference_keys,
      bank_tariff_names: (bank_tariff_names || []).map((item) => item.value),
      date_from: date_from
        ? convertLocalToUTCDate(date_from).toString()
        : undefined,
      date_to: date_to ? convertLocalToUTCDate(date_to).toString() : undefined,
      submission_statuses: submissionStatuses,
      case_statuses: caseStatuses,
      ...any,
    };

    return params;
  }, []);

  const onSave = useCallback(
    async (values: FormProps) => {
      const normalizedData = normalizeData(values);
      const res = await create(normalizedData);
      if (res) {
        navigate(`/${client_id}/statistic/submissions/${res.id}`);
      }
    },
    [create, client_id, navigate]
  );

  const onSaveAsDraft = useCallback(
    async (values: FormProps) => {
      const normalizedData = normalizeData(values);
      const res = await create({ is_template: true, ...normalizedData });
      if (res) {
        close?.();
      }
    },
    [create, client_id, navigate]
  );

  const onClickSave = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      handleSubmit(onSave)(e);
    },
    [handleSubmit, onSave]
  );

  const onClickSaveAsDraft = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      handleSubmit(onSaveAsDraft)(e);
    },
    [handleSubmit, onSaveAsDraft]
  );

  const disabled = useMemo(
    () => !referenceKeys.length || loading,
    [referenceKeys, loading]
  );

  return { disabled, onClickSaveAsDraft, onClickSave };
}
