import { PropsWithChildren } from 'react';
import { GridContainer } from '../../../../containers';

import { MoreNotifications } from './components';
import { Container, ContentContainer, IconContainer } from './styles';
import { Icon } from '../../../Icon';
import { ClientAvatar } from './components';
import { ViewViewProps } from './types';
import { IconLoader } from '../../../../shared';

export function View({
  children,
  count,
  client,
  callback,
}: PropsWithChildren<ViewViewProps>) {
  return (
    <GridContainer gap="6">
      <Container
        gridTemplateColumns="64px minmax(0px, 1fr)"
        gridTemplateRows="1rf"
        alignItems="center"
        onClick={callback}
      >
        <IconContainer flex={1} justifyContent="center" alignItems="center">
          <Icon
            glyph="notificationBell"
            color="white-primary"
            size={24}
            fallback={<IconLoader size={24} />}
          />
        </IconContainer>
        <ContentContainer
          gridTemplateColumns="max-content 1fr"
          gap="12"
          alignItems="center"
        >
          <ClientAvatar client={client} />
          {children}
        </ContentContainer>
      </Container>
      <MoreNotifications count={count} callback={callback} />
    </GridContainer>
  );
}
