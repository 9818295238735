import gql from 'graphql-tag';

export const TRANSACTION_ALERT_FRAGMENT = gql`
  fragment TransactionAlertFragment on TransactionAlert {
    cases @type(name: "TransactionCase") {
      assigned_toes @type(name: "TransactionAccount") {
        accounts_clients @type(name: "TransactionAccountClient") {
          account_id
          client_id
          id
        }
        created_at
        email
        first_name
        id
        last_name
        phone
        status
      }
      closed_at
      created_at
      created_by @type(name: "TransactionAccount") {
        accounts_clients @type(name: "TransactionAccountClient") {
          account_id
          client_id
          id
        }
        created_at
        email
        first_name
        id
        last_name
        phone
        status
      }
      deadline_at
      description
      id
      report_case_url
      state
      support_key
      tags @type(name: "TransactionTag") {
        created_at
        description
        id
        name
        updated_at
      }
      title
      unit21_case_external_id
      unit21_external_id
    }
    is_from_scheduled_rule
    created_at
    data {
      title
      description
    }
    is_for_test

    deadline_at
    event_support_keys
    id
    kind
    disposition @type(name: "TransactionDisposition") {
      id
      name
    }
    last_action @type(name: "TransactionAlertAction") {
      account @type(name: "TransactionAccount") {
        accounts_clients @type(name: "TransactionAccountClient") {
          account_id
          client_id
          id
        }
        created_at
        email
        first_name
        id
        last_name
        phone
        status
      }
      created_at
      event
      id
      item_id
      item_type
      object_changes
    }
    state
    unit21_alert_external_id
    unit21_external_id
    unit21_url
  }
`;
