import { useMemo } from 'react';
import { usePermissionsContext } from '../../permissions';
import { Check } from '../types';
import { getShow } from '../utils';

export function useCheckPermission(check: Check, strong: boolean) {
  const context = usePermissionsContext();

  const canShow = useMemo(
    () => getShow(context, check, strong),
    [context, check, strong]
  );

  return { canShow };
}
