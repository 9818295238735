import { gql } from '@apollo/client';
import { CHECKUP_ATTACHMENT_FRAGMENT } from '../../../fragment/checkup/CheckupAttachment';

export const CreateCheckupAttachment = gql`
  ${CHECKUP_ATTACHMENT_FRAGMENT}
  mutation CreateCheckupAttachment(
    $body: BodyCreateOrUpdateCheckupAttachment!
  ) {
    createCheckupAttachment(body: $body)
      @rest(
        type: "CreateCheckupAttachment"
        path: "/checkups/client/checkup_attachments"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "CheckupAttachment") {
        ...CheckupAttachmentFragment
      }
      success
    }
  }
`;

export const UpdateCheckupAttachment = gql`
  ${CHECKUP_ATTACHMENT_FRAGMENT}
  mutation UpdateCheckupAttachment(
    $id: String!
    $body: BodyCreateOrUpdateCheckupAttachment!
  ) {
    updateCheckupAttachment(id: $id, body: $body)
      @rest(
        type: "UpdateCheckupAttachment"
        path: "/checkups/client/checkup_attachments/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "CheckupAttachment") {
        ...CheckupAttachmentFragment
      }
      success
    }
  }
`;

export const DeleteAttachement = gql`
  mutation DeleteAttachement($id: String!) {
    deleteAttachement(id: $id)
      @rest(
        type: "DeleteCheckupAttachment"
        path: "/checkups/client/checkup_attachments/{args.id}"
        method: "DELETE"
      ) {
      data @type(name: "CheckupAttachment") {
        id
      }
      success
    }
  }
`;
