import { ActionFallback } from '../../../../components/ActionFallback';
import { FormLoader } from '../../../components';

export function Fallback() {
  return (
    <FormLoader>
      <ActionFallback count={2} />
    </FormLoader>
  );
}
