import gql from 'graphql-tag';

export const Shortify = gql`
  mutation Shortify($body: BodyShortify!) {
    shortify(body: $body)
      @rest(
        type: "Shortify"
        path: "/core/links/shortify"
        bodyKey: "body"
        method: "POST"
      ) {
      expires_at
      short_link
    }
  }
`;
