import gql from 'graphql-tag';

export const PricingFeatureTemplates = gql`
  query PricingFeatureTemplates(
    $page: Int
    $per: Int
    $is_system: Boolean
    $sort_field: String
    $sort_order: String
  ) {
    pricingFeatureTemplates(
      page: $page
      per: $per
      is_system: $is_system
      sort_field: $sort_field
      sort_order: $sort_order
    )
      @rest(
        type: "ResponsePricingFeatureTemplates"
        path: "/billing/admin/feature_templates?{args}"
      ) {
      data @type(name: "FeatureTemplate") {
        code
        created_at
        created_by_id
        description
        feature_dictionary @type(name: "FeatureDictionary") {
          code
          created_at
          created_by_id
          id
          is_system
          name
          status
          updated_at
        }
        feature_dictionary_id
        id
        kind
        name
        status
        updated_at
        is_system
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const PricingFeatureDictionary = gql`
  query PricingFeatureDictionary(
    $page: Int
    $per: Int
    $is_system: Boolean
    $sort_field: String
    $sort_order: String
    $q: String
  ) {
    pricingFeatureDictionary(
      page: $page
      per: $per
      is_system: $is_system
      sort_field: $sort_field
      sort_order: $sort_order
      q: $q
    )
      @rest(
        type: "ResponsePricingFeatureDictionary"
        path: "/billing/admin/feature_dictionaries?{args}"
      ) {
      data @type(name: "FeatureDictionary") {
        code
        created_at
        created_by_id
        id
        is_system
        name
        status
        updated_at
        feature_templates @type(name: "FeatureTemplate") {
          code
          created_at
          created_by_id
          description
          feature_dictionary_id
          id
          kind
          name
          status
          updated_at
          is_system
        }
      }
      success
    }
  }
`;
