import { useMemo } from 'react';
import { IdentityVerificationStatusViewProps } from '../types';
import { getColorByStatus } from './utils';
import { IDENTITY_VERIFICATIONS_DISPLAY_STATUSES } from '../constants';

export function useIdentityVerificationStatusController(
  status: IdentityVerificationStatusViewProps['status']
) {
  const background = useMemo(() => getColorByStatus(status), []);

  const text = useMemo(() => {
    if (status) {
      return IDENTITY_VERIFICATIONS_DISPLAY_STATUSES[status];
    }

    return '';
  }, [status]);

  return {
    background,
    text,
  };
}
