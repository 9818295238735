import { gql } from '@apollo/client';

export const MyFeatures = gql`
  query MyFeatures {
    myFeatures
      @rest(
        type: "ResponseMyFeatures"
        path: "/billing/client/features/my_features"
      ) {
      data @type(name: "MyFeature") {
        available
        code
        current_value
        description
        feature_template_id
        is_system
        kind
        name
        status
      }
      success
    }
  }
`;
