import gql from 'graphql-tag';
import { FORM_TAG_FRAGMENT } from '../../../fragment/account/formTags';

export const FormTagUpdate = gql`
  ${FORM_TAG_FRAGMENT}
  mutation FormTagUpdate($id: String!, $body: BodyCreateOrUpdateFormTag!) {
    updateFormTag(id: $id, body: $body)
      @rest(
        type: "FormTagUpdate"
        path: "/core/client/form_tags/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "FormTag") {
        ...FormTagFragment
      }
      success
      errors {
        name
      }
    }
  }
`;

export const FormTagCreate = gql`
  ${FORM_TAG_FRAGMENT}
  mutation FormTagCreate($body: BodyCreateOrUpdateFormTag!) {
    createFormTag(body: $body)
      @rest(
        type: "FormTagCreate"
        path: "/core/client/form_tags"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "FormTag") {
        ...FormTagFragment
      }
      success
      errors {
        name
      }
    }
  }
`;
