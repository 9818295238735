import gql from 'graphql-tag';

export const Tags = gql`
  query Tags {
    tags @rest(type: "ResponseTags", path: "/core/client/forms/tags") {
      data
      success
    }
  }
`;
