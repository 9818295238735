import { OptionProps } from 'react-select';
import styled from '@emotion/styled';
import { FlexContainer } from '../../../containers';
import { getSpace } from '../../../utils';
import { SelectOption } from '../../Select';
import { Text } from '../../Text';

export const OptionContainer = styled(FlexContainer)<
  Pick<OptionProps<SelectOption, boolean>, 'isSelected'>
>`
  padding: ${getSpace('6')};
`;

export const SearchContainer = styled(FlexContainer)`
  margin-right: 10px;

  & > div {
    cursor: default;
  }
`;

export const ProfileName = styled(Text)`
  max-width: 200px;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
