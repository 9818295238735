import { gql } from '@apollo/client';

export const CreateTransactionCaseOrigin = gql`
  mutation CreateTransactionCaseOrigin(
    $body: BodyCreateTransactionCaseOrigin!
  ) {
    createTransactionCaseOrigin(body: $body)
      @rest(
        type: "CreateTransactioCaseOrigin"
        path: "/tm/case_origins"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "TransactionCaseOrigin") {
        id
        name
      }
      success
      errors {
        name
      }
    }
  }
`;
