import gql from 'graphql-tag';
import { ADMIN_STATS_FRAGMENT } from '../../../fragment/admin/stats';

export const AdminStats = gql`
  ${ADMIN_STATS_FRAGMENT}
  query AdminStats {
    adminStats @rest(type: "AdminStatsResponse", path: "/stats/admin") {
      data @type(name: "AdminStats") {
        ...AdminStatsFragment
      }
      success
    }
  }
`;
