import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export function useErrorActionsController() {
  const navigate = useNavigate();
  const { client_id } = useParams();

  const onGoBack = useCallback(() => navigate(-1), [navigate]);

  const onGoMainPage = useCallback(
    () => navigate(`/${client_id}/profile`),
    [client_id, navigate]
  );

  return { onGoBack, onGoMainPage };
}
