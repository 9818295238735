import { Controller, FormProvider } from 'react-hook-form';
import { ModalBodyViewProps } from '../../../../../../../../../../../../../../../../../../../../../../Modal/Modal/types';
import { Actions, Select } from './components';
import { useFormController } from './controller';
import { FormRenderProps } from './types';

function renderFilterTemplate({
  field: { value, onChange },
  fieldState: { error },
}: FormRenderProps<'filterTemplate'>) {
  return <Select value={value} onChange={onChange} error={error} />;
}

export function Form({ onClose }: ModalBodyViewProps) {
  const formContext = useFormController();

  return (
    <FormProvider {...formContext}>
      <Controller name="filterTemplate" render={renderFilterTemplate} />
      <Actions onClose={onClose} />
    </FormProvider>
  );
}
