import { Empty, GridContainer } from '@aid-module/ui';
import { useStructureController } from './controller';
import { Fallback, Screen } from './components';
import { TYPES } from '@aid-package/api';
import { StructureContext } from './context';
import { Container, PaddingContainer } from './styles';

function renderScreen(
  screen: TYPES.FormQuery['form']['data']['form_screens'][0]
) {
  return <Screen key={screen.id} screen={screen} />;
}

export function Structure() {
  const { isEmpty, loading, form, context, ref } = useStructureController();

  if (isEmpty) {
    return (
      <PaddingContainer>
        <Empty
          title="NO STRUCTURE YET"
          subtitle="Please select the form using the dropdown above
    whose answers you want to count"
        />
      </PaddingContainer>
    );
  }

  if (loading) {
    return (
      <Container>
        <Fallback />
      </Container>
    );
  }

  if (form) {
    return (
      <Container ref={ref}>
        <StructureContext.Provider value={context}>
          <GridContainer gap="12">
            {form.form_screens?.map(renderScreen)}
          </GridContainer>
        </StructureContext.Provider>
      </Container>
    );
  }

  return null;
}
