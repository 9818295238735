import gql from 'graphql-tag';

export const TRANSACTION_TAG_FRAGMENT = gql`
  fragment TransactionTagFragment on TransactionTag {
    created_at
    description
    id
    name
    updated_at
  }
`;
