import styled from '@emotion/styled';
import { getColor } from '../../../../../../../../../../../../../../../../../utils';
import { EmotionPlaceholderViewProps } from './types';

export const Container = styled.div`
  position: relative;
`;

export const Placeholder = styled.div<EmotionPlaceholderViewProps>`
  position: absolute;
  background: ${getColor('main-deep-1')};
  box-sizing: border-box;
  border-radius: 4px;
  top: ${({ clientY }) => clientY || 0}px;
  left: ${({ clientX }) => clientX || 0}px;
  height: ${({ clientHeight }) => clientHeight || 0}px;
  width: ${({ clientWidth }) => clientWidth || 0}px;
`;
