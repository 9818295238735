import { GridContainer } from '../../../../../../containers';
import { IconLoader } from '../../../../../../shared';
import { Icon } from '../../../../../Icon';
import { TextViewProps } from './types';
import { Text as UIText } from './styles';

export function Text({ label, icon }: TextViewProps) {
  return (
    <GridContainer
      gridTemplateColumns="12px minmax(max-content, 1fr)"
      gap="12"
      alignItems="center"
    >
      <Icon
        glyph={icon}
        size={16}
        fallback={<IconLoader size={16} />}
        color="main-deep-4"
      />
      <UIText
        text={label || '-'}
        size={14}
        weight="Medium"
        color="main-deep-4"
      />
    </GridContainer>
  );
}
