import { FEATURES, PERMISSIONS } from '@aid-package/context';
import { Routes, Route } from 'react-router-dom';
import { BlacklistProfilesRegister } from './BlacklistedProfiles';
import { ProfilesRegister, ProfileDescription } from './Profiles';
import { BlacklistProfileCreate } from './BlacklistedProfiles/create';
import { BlacklistProfileDelete } from './BlacklistedProfiles/delete';
import { Profile } from './Profile';
import { ProfileEdit } from './ProfileEdit';
import { ExportRequestsRegister } from './ExportRequests';
import {
  ProtectedRoute,
  ErrorPages,
  ProtectedFeaturesRoute,
} from '@aid-module/ui';

export function ProfilesRouter() {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<Profile />} />
        <Route path="/edit/:tab" element={<ProfileEdit />} />
        <Route
          path="profiles"
          element={
            <ProtectedRoute
              check={[
                PERMISSIONS.MANAGE_PROFILE,
                PERMISSIONS.SEE_PROFILE,
                PERMISSIONS.SEE_PROFILE_CONFIDENTIAL,
                PERMISSIONS.SEE_PROFILES_LIST,
              ]}
            />
          }
        >
          <Route index element={<ProfilesRegister />} />
          <Route path=":id" element={<ProfileDescription />} />
        </Route>
        <Route
          path="blacklist"
          element={
            <ProtectedRoute check={PERMISSIONS.MANAGE_BLACKLIST_PROFILES} />
          }
        >
          <Route index element={<BlacklistProfilesRegister />} />
          <Route
            path="form"
            element={
              <ProtectedFeaturesRoute check={FEATURES.CUSTOMIZATION_TOOLS} />
            }
          >
            <Route index element={<BlacklistProfileCreate />} />
          </Route>
          <Route
            path="form/:blacklisted_profile_id"
            element={<BlacklistProfileDelete />}
          />
        </Route>
        <Route path="export_requests">
          <Route index element={<ExportRequestsRegister />} />
        </Route>
        <Route path="*" element={<ErrorPages type="local" code={404} />} />
      </Route>
    </Routes>
  );
}
