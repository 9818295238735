import { AsyncSelect } from '@aid-module/ui';
import { ServiceCheckupKinds } from '@aid-package/api';
import { useCheckupSelectController } from './controller';
import { CheckupsViewProps } from './types';

export function CheckupsKindsSelect({
  value,
  onChange,
  error,
  disabled,
  isClearable,
}: CheckupsViewProps) {
  const { reduceData } = useCheckupSelectController();

  return (
    <AsyncSelect
      value={value}
      onChange={onChange}
      label="Screening type"
      error={error}
      isDisabled={disabled}
      query={ServiceCheckupKinds}
      reduceData={reduceData}
      isClearable={isClearable}
      menuPlacement="auto"
      placeholder="Select screening type..."
    />
  );
}
