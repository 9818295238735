import { CountriesIcons } from '../components/Icon/CountriesIcon/component/components/countries/types';

export const CountriesObject: {
  [key: string]: {
    label: string;
    value: string;
    flag: keyof typeof CountriesIcons;
  };
} = {
  af: { label: 'Afghanistan', value: 'af', flag: 'AF' },
  al: { label: 'Albania', value: 'al', flag: 'AL' },
  dz: { label: 'Algeria', value: 'dz', flag: 'DZ' },
  ad: { label: 'Andorra', value: 'ad', flag: 'AD' },
  ao: { label: 'Angola', value: 'ao', flag: 'AO' },
  ag: { label: 'Antigua And Barbuda', value: 'ag', flag: 'AG' },
  ar: { label: 'Argentina', value: 'ar', flag: 'AR' },
  am: { label: 'Armenia', value: 'am', flag: 'AM' },
  aw: { label: 'Aruba', value: 'aw', flag: 'AW' },
  au: { label: 'Australia', value: 'au', flag: 'AU' },
  at: { label: 'Austria', value: 'at', flag: 'AT' },
  az: { label: 'Azerbaijan', value: 'az', flag: 'AZ' },
  bs: { label: 'Bahamas', value: 'bs', flag: 'BS' },
  bh: { label: 'Bahrain', value: 'bh', flag: 'BH' },
  bd: { label: 'Bangladesh', value: 'bd', flag: 'BD' },
  bb: { label: 'Barbados', value: 'bb', flag: 'BB' },
  by: { label: 'Belarus', value: 'by', flag: 'BY' },
  be: { label: 'Belgium', value: 'be', flag: 'BE' },
  bz: { label: 'Belize', value: 'bz', flag: 'BZ' },
  bj: { label: 'Benin', value: 'bj', flag: 'BJ' },
  bo: { label: 'Bolivia', value: 'bo', flag: 'BO' },
  ba: { label: 'Bosnia And Herzegovina', value: 'ba', flag: 'BA' },
  bw: { label: 'Botswana', value: 'bw', flag: 'BW' },
  br: { label: 'Brazil', value: 'br', flag: 'BR' },
  bn: { label: 'Brunei Darussalam', value: 'bn', flag: 'BN' },
  bg: { label: 'Bulgaria', value: 'bg', flag: 'BG' },
  bf: { label: 'Burkina Faso', value: 'bf', flag: 'BF' },
  bi: { label: 'Burundi', value: 'bi', flag: 'BI' },
  kh: { label: 'Cambodia', value: 'kh', flag: 'KH' },
  cm: { label: 'Cameroon', value: 'cm', flag: 'CM' },
  ca: { label: 'Canada', value: 'ca', flag: 'CA' },
  cv: { label: 'Cape Verde', value: 'cv', flag: 'CV' },
  cf: { label: 'Central African Republic', value: 'cf', flag: 'CF' },
  td: { label: 'Chad', value: 'td', flag: 'TD' },
  cl: { label: 'Chile', value: 'cl', flag: 'CL' },
  cn: { label: 'China', value: 'cn', flag: 'CN' },
  co: { label: 'Colombia', value: 'co', flag: 'CO' },
  km: { label: 'Comoros', value: 'km', flag: 'KM' },
  cg: { label: 'Congo', value: 'cg', flag: 'CG' },
  cd: { label: 'Congo, Democratic Republic', value: 'cd', flag: 'CD' },
  ck: { label: 'Cook Islands', value: 'ck', flag: 'CK' },
  cr: { label: 'Costa Rica', value: 'cr', flag: 'CR' },
  ci: { label: "Cote D'Ivoire", value: 'ci', flag: 'CI' },
  hr: { label: 'Croatia', value: 'hr', flag: 'HR' },
  cu: { label: 'Cuba', value: 'cu', flag: 'CU' },
  cy: { label: 'Cyprus', value: 'cy', flag: 'CY' },
  cz: { label: 'Czech Republic', value: 'cz', flag: 'CZ' },
  dk: { label: 'Denmark', value: 'dk', flag: 'DK' },
  dj: { label: 'Djibouti', value: 'dj', flag: 'DJ' },
  dm: { label: 'Dominica', value: 'dm', flag: 'DM' },
  do: { label: 'Dominican Republic', value: 'do', flag: 'DO' },
  ec: { label: 'Ecuador', value: 'ec', flag: 'EC' },
  eg: { label: 'Egypt', value: 'eg', flag: 'EG' },
  sv: { label: 'El Salvador', value: 'sv', flag: 'SV' },
  gq: { label: 'Equatorial Guinea', value: 'gq', flag: 'GQ' },
  er: { label: 'Eritrea', value: 'er', flag: 'ER' },
  ee: { label: 'Estonia', value: 'ee', flag: 'EE' },
  et: { label: 'Ethiopia', value: 'et', flag: 'ET' },
  fk: { label: 'Falkland Islands (Malvinas)', value: 'fk', flag: 'FK' },
  fo: { label: 'Faroe Islands', value: 'fo', flag: 'FO' },
  fj: { label: 'Fiji', value: 'fj', flag: 'FJ' },
  fi: { label: 'Finland', value: 'fi', flag: 'FI' },
  fr: { label: 'France', value: 'fr', flag: 'FR' },
  pf: { label: 'French Polynesia', value: 'pf', flag: 'PF' },
  ga: { label: 'Gabon', value: 'ga', flag: 'GA' },
  gm: { label: 'Gambia', value: 'gm', flag: 'GM' },
  ge: { label: 'Georgia', value: 'ge', flag: 'GE' },
  de: { label: 'Germany', value: 'de', flag: 'DE' },
  gh: { label: 'Ghana', value: 'gh', flag: 'GH' },
  gr: { label: 'Greece', value: 'gr', flag: 'GR' },
  gl: { label: 'Greenland', value: 'gl', flag: 'GL' },
  gd: { label: 'Grenada', value: 'gd', flag: 'GD' },
  gu: { label: 'Guam', value: 'gu', flag: 'GU' },
  gt: { label: 'Guatemala', value: 'gt', flag: 'GT' },
  gg: { label: 'Guernsey', value: 'gg', flag: 'GG' },
  gn: { label: 'Guinea', value: 'gn', flag: 'GN' },
  gw: { label: 'Guinea-Bissau', value: 'gw', flag: 'GW' },
  ht: { label: 'Haiti', value: 'ht', flag: 'HT' },
  va: { label: 'Holy See (Vatican City State)', value: 'va', flag: 'VA' },
  hn: { label: 'Honduras', value: 'hn', flag: 'HN' },
  hu: { label: 'Hungary', value: 'hu', flag: 'HU' },
  is: { label: 'Iceland', value: 'is', flag: 'IS' },
  in: { label: 'India', value: 'in', flag: 'IN' },
  id: { label: 'Indonesia', value: 'id', flag: 'ID' },
  ir: { label: 'Iran, Islamic Republic Of', value: 'ir', flag: 'IR' },
  iq: { label: 'Iraq', value: 'iq', flag: 'IQ' },
  ie: { label: 'Ireland', value: 'ie', flag: 'IE' },
  im: { label: 'Isle Of Man', value: 'im', flag: 'IM' },
  il: { label: 'Israel', value: 'il', flag: 'IL' },
  it: { label: 'Italy', value: 'it', flag: 'IT' },
  jm: { label: 'Jamaica', value: 'jm', flag: 'JM' },
  jp: { label: 'Japan', value: 'jp', flag: 'JP' },
  je: { label: 'Jersey', value: 'je', flag: 'JE' },
  jo: { label: 'Jordan', value: 'jo', flag: 'JO' },
  kz: { label: 'Kazakhstan', value: 'kz', flag: 'KZ' },
  ke: { label: 'Kenya', value: 'ke', flag: 'KE' },
  kr: { label: 'Korea', value: 'kr', flag: 'KR' },
  kw: { label: 'Kuwait', value: 'kw', flag: 'KW' },
  kg: { label: 'Kyrgyzstan', value: 'kg', flag: 'KG' },
  la: { label: "Lao People's Democratic Republic", value: 'la', flag: 'LA' },
  lv: { label: 'Latvia', value: 'lv', flag: 'LV' },
  lb: { label: 'Lebanon', value: 'lb', flag: 'LB' },
  ls: { label: 'Lesotho', value: 'ls', flag: 'LS' },
  lr: { label: 'Liberia', value: 'lr', flag: 'LR' },
  ly: { label: 'Libyan Arab Jamahiriya', value: 'ly', flag: 'LY' },
  li: { label: 'Liechtenstein', value: 'li', flag: 'LI' },
  lt: { label: 'Lithuania', value: 'lt', flag: 'LT' },
  lu: { label: 'Luxembourg', value: 'lu', flag: 'LU' },
  mk: { label: 'Macedonia', value: 'mk', flag: 'MK' },
  mg: { label: 'Madagascar', value: 'mg', flag: 'MG' },
  mw: { label: 'Malawi', value: 'mw', flag: 'MW' },
  my: { label: 'Malaysia', value: 'my', flag: 'MY' },
  mv: { label: 'Maldives', value: 'mv', flag: 'MV' },
  ml: { label: 'Mali', value: 'ml', flag: 'ML' },
  mt: { label: 'Malta', value: 'mt', flag: 'MT' },
  mh: { label: 'Marshall Islands', value: 'mh', flag: 'MH' },
  mr: { label: 'Mauritania', value: 'mr', flag: 'MR' },
  mx: { label: 'Mexico', value: 'mx', flag: 'MX' },
  fm: { label: 'Micronesia, Federated States Of', value: 'fm', flag: 'FM' },
  md: { label: 'Moldova', value: 'md', flag: 'MD' },
  mc: { label: 'Monaco', value: 'mc', flag: 'MC' },
  mn: { label: 'Mongolia', value: 'mn', flag: 'MN' },
  me: { label: 'Montenegro', value: 'me', flag: 'ME' },
  ms: { label: 'Montserrat', value: 'ms', flag: 'MS' },
  ma: { label: 'Morocco', value: 'ma', flag: 'MA' },
  mz: { label: 'Mozambique', value: 'mz', flag: 'MZ' },
  mm: { label: 'Myanmar', value: 'mm', flag: 'MM' },
  na: { label: 'Namibia', value: 'na', flag: 'NA' },
  nr: { label: 'Nauru', value: 'nr', flag: 'NR' },
  np: { label: 'Nepal', value: 'np', flag: 'NP' },
  nl: { label: 'Netherlands', value: 'nl', flag: 'NL' },
  nz: { label: 'New Zealand', value: 'nz', flag: 'NZ' },
  ni: { label: 'Nicaragua', value: 'ni', flag: 'NI' },
  ne: { label: 'Niger', value: 'ne', flag: 'NE' },
  ng: { label: 'Nigeria', value: 'ng', flag: 'NG' },
  nu: { label: 'Niue', value: 'nu', flag: 'NU' },
  mp: { label: 'Northern Mariana Islands', value: 'mp', flag: 'MP' },
  no: { label: 'Norway', value: 'no', flag: 'NO' },
  om: { label: 'Oman', value: 'om', flag: 'OM' },
  pk: { label: 'Pakistan', value: 'pk', flag: 'PK' },
  pw: { label: 'Palau', value: 'pw', flag: 'PW' },
  ps: { label: 'Palestinian Territory, Occupied', value: 'ps', flag: 'PS' },
  pa: { label: 'Panama', value: 'pa', flag: 'PA' },
  pg: { label: 'Papua New Guinea', value: 'pg', flag: 'PG' },
  py: { label: 'Paraguay', value: 'py', flag: 'PY' },
  pe: { label: 'Peru', value: 'pe', flag: 'PE' },
  ph: { label: 'Philippines', value: 'ph', flag: 'PH' },
  pn: { label: 'Pitcairn', value: 'pn', flag: 'PN' },
  pl: { label: 'Poland', value: 'pl', flag: 'PL' },
  pt: { label: 'Portugal', value: 'pt', flag: 'PT' },
  pr: { label: 'Puerto Rico', value: 'pr', flag: 'PR' },
  ro: { label: 'Romania', value: 'ro', flag: 'RO' },
  ru: { label: 'Russian Federation', value: 'ru', flag: 'RU' },
  rw: { label: 'Rwanda', value: 'rw', flag: 'RW' },
  bl: { label: 'Saint Barthelemy', value: 'bl', flag: 'BL' },
  kn: { label: 'Saint Kitts And Nevis', value: 'kn', flag: 'KN' },
  lc: { label: 'Saint Lucia', value: 'lc', flag: 'LC' },
  vc: { label: 'Saint Vincent And Grenadines', value: 'vc', flag: 'VC' },
  ws: { label: 'Samoa', value: 'ws', flag: 'WS' },
  sm: { label: 'San Marino', value: 'sm', flag: 'SM' },
  st: { label: 'Sao Tome And Principe', value: 'st', flag: 'ST' },
  sa: { label: 'Saudi Arabia', value: 'sa', flag: 'SA' },
  sn: { label: 'Senegal', value: 'sn', flag: 'SN' },
  rs: { label: 'Serbia', value: 'rs', flag: 'RS' },
  sc: { label: 'Seychelles', value: 'sc', flag: 'SC' },
  sl: { label: 'Sierra Leone', value: 'sl', flag: 'SL' },
  sg: { label: 'Singapore', value: 'sg', flag: 'SG' },
  sk: { label: 'Slovakia', value: 'sk', flag: 'SK' },
  si: { label: 'Slovenia', value: 'si', flag: 'SI' },
  sb: { label: 'Solomon Islands', value: 'sb', flag: 'SB' },
  so: { label: 'Somalia', value: 'so', flag: 'SO' },
  za: { label: 'South Africa', value: 'za', flag: 'ZA' },
  es: { label: 'Spain', value: 'es', flag: 'ES' },
  lk: { label: 'Sri Lanka', value: 'lk', flag: 'LK' },
  sd: { label: 'Sudan', value: 'sd', flag: 'SD' },
  ch: { label: 'Switzerland', value: 'ch', flag: 'CH' },
  sy: { label: 'Syrian Arab Republic', value: 'sy', flag: 'SY' },
  tj: { label: 'Tajikistan', value: 'tj', flag: 'TJ' },
  tz: { label: 'Tanzania', value: 'tz', flag: 'TZ' },
  th: { label: 'Thailand', value: 'th', flag: 'TH' },
  tl: { label: 'Timor-Leste', value: 'tl', flag: 'TL' },
  tg: { label: 'Togo', value: 'tg', flag: 'TG' },
  tk: { label: 'Tokelau', value: 'tk', flag: 'TK' },
  to: { label: 'Tonga', value: 'to', flag: 'TO' },
  tt: { label: 'Trinidad And Tobago', value: 'tt', flag: 'TT' },
  tn: { label: 'Tunisia', value: 'tn', flag: 'TN' },
  tr: { label: 'Turkey', value: 'tr', flag: 'TR' },
  tm: { label: 'Turkmenistan', value: 'tm', flag: 'TM' },
  tc: { label: 'Turks And Caicos Islands', value: 'tc', flag: 'TC' },
  tv: { label: 'Tuvalu', value: 'tv', flag: 'TV' },
  ug: { label: 'Uganda', value: 'ug', flag: 'UG' },
  ua: { label: 'Ukraine', value: 'ua', flag: 'UA' },
  ae: { label: 'United Arab Emirates', value: 'ae', flag: 'AE' },
  gb: { label: 'United Kingdom', value: 'gb', flag: 'GB' },
  us: { label: 'United States', value: 'us', flag: 'US' },
  uz: { label: 'Uzbekistan', value: 'uz', flag: 'UZ' },
  vu: { label: 'Vanuatu', value: 'vu', flag: 'VU' },
  ve: { label: 'Venezuela', value: 've', flag: 'VE' },
  vn: { label: 'Viet Nam', value: 'vn', flag: 'VN' },
  ye: { label: 'Yemen', value: 'ye', flag: 'YE' },
  zm: { label: 'Zambia', value: 'zm', flag: 'ZM' },
  zw: { label: 'Zimbabwe', value: 'zw', flag: 'ZW' },
};

export const CountriesOptions = Object.values(CountriesObject);
