import gql from 'graphql-tag';
import { SCORING_AUDIT_LOG_FRAGMENT } from './ScoringAuditLog';

export const SUBMISSION_SCORING_FRAGMENT = gql`
  ${SCORING_AUDIT_LOG_FRAGMENT}
  fragment SubmissionScoringFragment on SubmissionScoring {
    audit_logs @type(name: "ScoringAuditLog") {
      ...ScoringAuditLogFragment
    }
    created_at
    coefficient
    field_submission_evaluations @type(name: "ScoringField") {
      audit_logs @type(name: "ScoringAuditLog") {
        ...ScoringAuditLogFragment
      }
      created_at
      field_submission_id
      id
      is_changed_manually
      name
      coefficient
      rule @type(name: "FieldScoring") {
        coefficient
        expression
        created_at
        form_field_id
        id
        kind
        name
        rule_select_values
        state
        updated_at
        value
      }
      updated_at
      value
    }
    form_name
    form_submission_id
    has_conflict
    id
    is_changed_manually
    last_problem_at
    last_problem_name
    name
    scoring_model @type(name: "ScoringModel") {
      created_at
      form_configuration_id
      id
      name
      updated_at
    }
    state
    updated_at
    value
  }
`;
