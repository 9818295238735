import { GridContainer, Text } from '@aid-module/ui';
import { Card } from '../shared.styles';
import { ScreenViewProps } from './types';
import { TYPES } from '@aid-package/api';
import { Group } from './components';

function renderGroup(
  group: NonNullable<
    TYPES.FormQuery['form']['data']['form_screens'][0]['form_groups']
  >[0]
) {
  return <Group key={group.id} group={group} />;
}

export function Screen({ screen }: ScreenViewProps) {
  if (!screen.options?.system) {
    return (
      <Card background="main-deep-1">
        <GridContainer gap="8">
          <Text size={14} weight="VeryBig" text={screen.name || '-'} />
          <GridContainer gap="12">
            {screen.form_groups?.map(renderGroup)}
          </GridContainer>
        </GridContainer>
      </Card>
    );
  }

  return null;
}
