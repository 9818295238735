import { ContentLoader, GridContainer } from '@aid-module/ui';
import { Container } from '../../component/styles';

export function Fallback() {
  return (
    <Container
      gap="32"
      gridTemplateRows="repeat(2, max-content)"
    >
      <ContentLoader width="100%" height="130.59">
        <rect rx="8" ry="8" width="100%" height="130.59" />
      </ContentLoader>
      <GridContainer gap="60">
        <GridContainer
          gap="40"
          gridTemplateColumns="repeat(2, minmax(200px, 1fr))"
          gridTemplateRows="max-content"
        >
          <ContentLoader width="100%" height="302.2">
            <rect rx="8" ry="8" width="100%" height="302.2" />
          </ContentLoader>
          <ContentLoader width="100%" height="302.2">
            <rect rx="8" ry="8" width="100%" height="302.2" />
          </ContentLoader>
        </GridContainer>
        <GridContainer
          gap="40"
          gridTemplateColumns="repeat(2, minmax(200px, 1fr))"
          gridTemplateRows="max-content"
        >
          <ContentLoader width="100%" height="302.2">
            <rect rx="8" ry="8" width="100%" height="302.2" />
          </ContentLoader>
          <ContentLoader width="100%" height="302.2">
            <rect rx="8" ry="8" width="100%" height="302.2" />
          </ContentLoader>
        </GridContainer>
      </GridContainer>
    </Container>
  );
}
