import { useCaSearchesQuery, TYPES } from '@aid-package/api';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useCheckupModel } from '../../../model';

export function useInfoHitCardWithSearcModel(caSearchId: string) {
  const { hit_id } = useParams();
  const { data, loading } = useCaSearchesQuery({
    variables: {
      ca_search_id: caSearchId,
    },
  });

  const hit = useMemo(() => {
    if (data?.caSearches.success) {
      return data.caSearches.data.actual_search_revision.payload.data?.hits?.find(
        (
          hit: NonNullable<
            TYPES.CaSearchesQuery['caSearches']['data']['actual_search_revision']['payload']['data']
          >['hits'][0]
        ) => hit?.doc?.id === hit_id
      );
    }
  }, [data]);

  return {
    hit,
    loading,
  };
}
export function useInfoHitCardModel() {
  const { id, hit_id } = useParams();
  const { checkup, loading } = useCheckupModel(id || '');

  const caSearchId = useMemo(
    () => checkup?.ca_search_id,
    [checkup?.ca_search_id]
  );

  const hit = useMemo(() => {
    if (checkup && !caSearchId) {
      return checkup.serialized_result?.data?.hits?.find(
        (
          hit: NonNullable<
            TYPES.ServiceCheckupQuery['serviceCheckup']['data']['serialized_result']['data']
          >['hits'][0]
        ) => hit?.doc?.id === hit_id
      );
    }
  }, [checkup, caSearchId]);

  return {
    hit,
    loading,
    caSearchId,
    checkup,
  };
}
