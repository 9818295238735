import loadable from '@loadable/component';
import { LoadableFunctionalComponent } from '@aid-module/ui';
import { FormContainer } from '../components';
import { Fallback } from './components';

export const ClientDictionaryCreate = loadable(() => import('./component'), {
  resolveComponent: (components) => components.ClientDictionaryCreateComponent,
  fallback: (
    <FormContainer label="Create Dictionary" icon="scheme" isLoading>
      <Fallback />
    </FormContainer>
  ),
}) as LoadableFunctionalComponent<{}>;
