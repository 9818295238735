import { useMemo } from 'react';
import { useClientProviderModel } from '../model';

export function useClientProviderController() {
  const { client, loading } = useClientProviderModel();

  const context = useMemo(
    () => ({
      client,
    }),
    [client]
  );

  return { context, loading };
}
