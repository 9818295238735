import { GridContainer, getColor, getSpace } from '@aid-module/ui';
import styled from '@emotion/styled';
import { EmotionSearchButtonViewProps } from './types';

export const Container = styled(GridContainer)`
  & input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none !important;

    &:focus {
      box-shadow: none;
    }
  }
`;

export const SearchButton = styled.div<EmotionSearchButtonViewProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${getColor('main-deep-4')};
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: ${getSpace('6')} ${getSpace('12')};
  border: 1px solid ${getColor('main-deep-4')};
  border-left: none;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    `
    cursor: default;
    user-select: none;
    background: ${getColor('gray-deep-2')};
    border-color: ${getColor('gray-deep-2')};
  `}
`;
