import gql from 'graphql-tag';

export const CheckupStats = gql`
  query CheckupStats(
    $checkup_kind_code: String
    $fincen_list_ids: String
    $q: String
    $resolutions: String
    $execution_statuses: String
    $audit_statuses: String
    $provider_statuses: String
    $external_ref_entity_types: String
    $created_at_from: String
    $created_at_to: String
    $last_screened_at_from: String
    $last_screened_at_to: String
    $case_status: String
    $cryptocurrency_symbol: String
    $blockchain: String
  ) {
    checkupStats(
      checkup_kind_code: $checkup_kind_code
      fincen_list_ids: $fincen_list_ids
      q: $q
      resolutions: $resolutions
      execution_statuses: $execution_statuses
      audit_statuses: $audit_statuses
      provider_statuses: $provider_statuses
      external_ref_entity_types: $external_ref_entity_types
      created_at_from: $created_at_from
      created_at_to: $created_at_to
      last_screened_at_from: $last_screened_at_from
      last_screened_at_to: $last_screened_at_to
      case_status: $case_status
      cryptocurrency_symbol: $cryptocurrency_symbol
      blockchain: $blockchain
    )
      @rest(
        type: "CheckupStatsResponse"
        path: "/checkups/client/checkups/stats?{args}"
      ) {
      data {
        audit_status {
          alarm
          all_checkups
          clean
          false_negative
          false_positive
          no_info
          warning
        }
        provider_status {
          all_checkups
          false_positive
          no_match
          potential_match
          true_positive
          true_positive_approve
          true_positive_reject
          unknown
          company_registry_match
          no_information
        }
      }
      success
    }
  }
`;
