import { getColor } from '../../../../../../../../utils';
import { useSvgController } from './controller';
import { SvgContainer } from './styles';
import { SvgViewProps } from './types';

export function Svg({ client }: SvgViewProps) {
  const { symbols, options } = useSvgController(client);

  return (
    <SvgContainer viewBox={`0 0 ${options.size} ${options.size}`}>
      <rect
        x="0"
        y="0"
        rx={options.border}
        ry={options.border}
        width={options.size}
        height={options.size}
        fill={getColor('main-deep-4')}
      ></rect>
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        alignmentBaseline="middle"
        dominantBaseline="middle"
        fontSize={options.fontSize}
        stroke={getColor('white-primary')}
        fill={getColor('main-deep-4')}
        dy=".1em"
      >
        {symbols}
      </text>
    </SvgContainer>
  );
}
