import { GridContainer } from '@aid-module/ui';
import {
  Accounts,
  Balance,
  Client,
  Notifications,
  ExportRequests,
  Search,
} from './components';
import { ActionsContainer, Container } from './styles';
import { useHeaderController } from './controller';

export function Header() {
  const { canShowGlobalSearch } = useHeaderController();

  return (
    <Container
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <GridContainer gridTemplateColumns="235px max-content" gap="60">
        <Accounts />
        <Balance />
      </GridContainer>
      <ActionsContainer
        alignItems="center"
        canShowGlobalSearch={canShowGlobalSearch}
        gap="32"
      >
        <ExportRequests />
        <Notifications />
        {canShowGlobalSearch && <Search />}
        <Client />
      </ActionsContainer>
    </Container>
  );
}
