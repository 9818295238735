import gql from 'graphql-tag';

export const CdsFormMappings = gql`
  query CdsFormMappings($page: Int, $per: Int, $statuses: [String!]) {
    cdsFormMappings(page: $page, per: $per, statuses: $statuses)
      @rest(
        type: "ResponseCdsFormMappings"
        path: "/cds/form_mappings?{args}"
      ) {
      data @type(name: "CdsFormMapping") {
        created_at
        form_configuration_id
        id
        status
        updated_at
      }
      meta {
        count
        page
        items
        pages
      }
      success
    }
  }
`;

export const CdsFormMapping = gql`
  query CdsFormMapping($id: String!) {
    cdsFormMapping(id: $id)
      @rest(
        type: "ResponseCdsFormMapping"
        path: "/cds/form_mappings/{args.id}"
      ) {
      data @type(name: "CdsFormMapping") {
        created_at
        form_configuration_id
        id
        status
        updated_at
        entity_templates @type(name: "CdsEntityTemplate") {
          created_at
          entity_type
          form_mapping_id
          id
          kind
          updated_at
        }
        field_mappings @type(name: "CdsFieldMapping") {
          created_at
          entity_template_id
          form_field_id
          form_field_reference_key
          form_mapping_id
          id
          map_to_field_name
          static_value
          map_to_table_name
          mapper_klass
          updated_at
        }
      }
      success
    }
  }
`;
