import { createContext, useContext } from 'react';

export const ActionContext = createContext<
  (index: number, isSelected: boolean) => void
>((index, isSelected) => {});

export function useActionContext() {
  const context = useContext(ActionContext);

  return context;
}
