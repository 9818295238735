import styled from '@emotion/styled';
import { getSpace } from '../../../utils';

export const Container = styled.div`
  margin: -32px;

  & > div > div {
    max-height: calc(90vh - calc(${getSpace('60')} * 2));
    overflow: auto;
  }
`;
