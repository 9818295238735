import gql from 'graphql-tag';
import { CHECKUP_ATTACHMENT_FRAGMENT } from '../../../fragment/checkup/CheckupAttachment';

export const CheckupAttachments = gql`
  ${CHECKUP_ATTACHMENT_FRAGMENT}
  query CheckupAttachments($checkup_id: String!, $page: Int!) {
    checkupAttachments(checkup_id: $checkup_id, page: $page, per: 20)
      @rest(
        type: "ResponseCheckupAttachments"
        path: "/checkups/client/checkup_attachments?{args}"
      ) {
      data @type(name: "CheckupAttachment") {
        ...CheckupAttachmentFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;
