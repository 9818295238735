import gql from 'graphql-tag';

export const TabRegistries = gql`
  query TabRegistries(
    $registry_type: String!
    $favorite: String
    $page: Int
    $per: Int
  ) {
    tabRegistries(
      registry_type: $registry_type
      favorite: $favorite
      page: $page
      per: $per
    )
      @rest(
        type: "ResponseTabRegistries"
        path: "/core/client/tab_registries?{args}"
      ) {
      data @type(name: "TabRegistry") {
        id
        name
        registry_type
        favorite
        position
        filters
        meta
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;