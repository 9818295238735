import gql from 'graphql-tag';

export const GlobalSearchEntries = gql`
  query GlobalSearchEntries(
    $page: Int
    $sort_field: String
    $sort_order: String
    $q: String
  ) {
    globalSearchEntries(
      page: $page
      per: 20
      sort_field: $sort_field
      sort_order: $sort_order
      q: $q
    )
      @rest(
        type: "ResponseGlobalSearchEntries"
        path: "/core/client/global_search_entries?{args}"
      ) {
      data @type(name: "GlobalSearchEntrie") {
        id
        client_id
        created_at
        search_query
        updated_at
        created_by @type(name: "Account") {
          id
          full_name
        }
        entry_count @type(name: "GlobalSearchEntrieCount") {
          tm
          case
          identityVerification
          checkup
          submission
          profile
        }
        filter @type(name: "GlobalSearchEntrieFilter") {
          type
          field
          header
          required
        }
      }
      meta {
        count
        page
        items
        pages
      }
      success
    }
  }
`;

export const GlobalSearchEntrie = gql`
  query GlobalSearchEntrie($id: String!) {
    globalSearchEntrie(id: $id)
      @rest(
        type: "ResponseGlobalSearchEntrie"
        path: "/core/client/global_search_entries/{args.id}"
      ) {
      data @type(name: "GlobalSearchEntrie") {
        id
        client_id
        created_at
        search_query
        updated_at
        created_by @type(name: "Account") {
          id
          full_name
        }
        entry_count @type(name: "GlobalSearchEntrieCount") {
          tm
          case
          identityVerification
          checkup
          submission
          profile
        }
        filter @type(name: "GlobalSearchEntrieFilter") {
          type
          field
          header
          required
        }
      }
      success
    }
  }
`;
