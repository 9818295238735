import { useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateFormat, showToast } from '@aid-module/ui';
import { TYPES } from '@aid-package/api';
import { useClientModel } from '../model';

export function useClientController(
  client: TYPES.DraftClientsQuery['draftClients']['data'][0]
) {
  const { getUrl, loading } = useClientModel();
  const navigate = useNavigate();
  const clientData = useMemo(() => {
    const steps =
      client?.customer_verification?.verification_step_passings || [];
    const stepsCount = steps?.length || 0;
    return {
      clientName: client?.name || 'Name client',
      expiresDate: DateFormat.formatDateTime(
        client?.customer_verification?.expires_at
      ),
      stepsCount,
      startedVF: steps.some((x) => x?.status !== 'new'),
      completedStepCount:
        steps.filter((x) => x?.status === 'completed').length || 0,
    };
  }, [client]);

  const onBoardingStatus = useMemo(() => {
    if (client?.status === 'draft') {
      if (!clientData.startedVF) {
        return 'new';
      }

      if (clientData.startedVF) {
        if (clientData.stepsCount > clientData.completedStepCount) {
          return 'started';
        }
        if (clientData.stepsCount === clientData.completedStepCount) {
          return 'on_review';
        }
      }
    }
    if (client?.status === 'accepted') {
      return 'accepted';
    }
    if (client?.status === 'rejected') {
      return 'rejected';
    }
    return 'new';
  }, [
    client,
    clientData?.startedVF,
    clientData?.stepsCount,
    clientData?.completedStepCount,
  ]);

  const infoText = useMemo(() => {
    switch (onBoardingStatus) {
      case 'new':
        return 'Complete the onboarding process to access the full functionality of the A.ID platform';
      case 'started':
        return 'Complete the onboarding process to access the full functionality of the A.ID platform';
      case 'on_review':
        return 'We have received your application. Please wait while our compliance team reviews the submitted information.';
      case 'accepted':
        return 'Well done! Your application was approved.';
      case 'rejected':
        return 'Unfortunately, we were not able to approve your application. You can fill a new application for an additional consideration.';
    }
  }, [onBoardingStatus]);

  const statusesMap = useMemo(
    () => ({
      isNew: onBoardingStatus === 'new',
      isStarted: onBoardingStatus === 'started',
      isAccepted: onBoardingStatus === 'accepted',
    }),
    [onBoardingStatus]
  );

  const redirectToFlow = useCallback(async () => {
    if (client?.id) {
      const url = await getUrl(client?.id);
      if (url) {
        window.location.assign(url);
      }
      return;
    }
    showToast({
      type: 'error',
      message: 'Something went wrong',
    });
  }, [client, getUrl]);

  const redirectToApp = useCallback(() => {
    navigate(`/`);
  }, [navigate]);

  return {
    loading,
    redirectToFlow,
    redirectToApp,
    ...clientData,
    ...statusesMap,
    infoText,
    onBoardingStatus,
  };
}
