import styled from '@emotion/styled';
import { getColor, getSpace } from '../../../utils';
import { GridContainer } from '../../../containers';

export const List = styled(GridContainer)`
  border-bottom: 1px solid ${getColor('main-deep-2')};
  padding: ${getSpace('12')} 0px;

  &:last-of-type {
    border-bottom: none;
  }
`;
