import { LoaderThemeProvider } from '../../../../shared';
import { ToastComponent } from '../ToastComponent';
import { ToastComponentProps } from '../types';

export function Error(props: ToastComponentProps) {
  return (
    <LoaderThemeProvider background="error-deep-3" foreground="error-deep-2">
      <ToastComponent icon="cross" color="error-deep-4" {...props} />
    </LoaderThemeProvider>
  );
}
