import { useCallback, useMemo } from 'react';
import { useLinkModel } from '../model';
import { LinkViewProps } from '../types';

export function useLinkController(
  value: LinkViewProps['value'],
  payloadData: LinkViewProps['payloadData']
) {
  const { read } = useLinkModel();

  const path = useMemo(() => {
    switch (payloadData.body_attributes?.[value]?.type) {
      case 'AccountsClient':
      case 'Account':
        return '/account/users/';
      case 'Case':
        return '/verification/cases/';
      case 'EddReport':
        return '/verification/edd_reports/';
      case 'Checkup':
        return '/checkup/instant_checkups/all/form/';
    }
  }, []);

  const onClick = useCallback(() => read(payloadData.notification_id), []);

  return {
    onClick,
    path,
  };
}
