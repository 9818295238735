import { useCallback } from 'react';
import { logOut } from '@aid-package/api';

export function useReLoginLinkController() {
  const onClick = useCallback(() => {
    logOut();
  }, []);

  return { onClick };
}
