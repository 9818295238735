import { Navigate, useLocation } from 'react-router-dom';

export function RedirectVerificationFlow() {
  const location = useLocation();

  return (
    <Navigate
      replace
      to={location.pathname.replace(
        'verification/verification_flows',
        'builder/verification_flows'
      )}
    />
  );
}
