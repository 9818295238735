import { useCheckPermission } from '@aid-package/context';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ToProfileViewProps } from '../types';
import { PERMISSIONS_PROFILE } from '@aid-package/context';

export function useToProfileController(id: ToProfileViewProps['profile_id']) {
  const { client_id } = useParams();

  const link = useMemo(
    () => id && `/${client_id}/profile/profiles/${id}`,
    [id, client_id]
  );

  const { canShow } = useCheckPermission(PERMISSIONS_PROFILE, false);

  return { link, canShow };
}
