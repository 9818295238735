import {
  StyledLoader,
  AidIconLoader,
  StyledPath,
  StyledAnimatedPath,
} from './styles';
import { useLoaderController } from './controller';

export function AIDLoader() {
  const { svgRef, onAnimationEnd } = useLoaderController();
  return (
    <StyledLoader>
      <AidIconLoader
        width="76"
        height="32"
        viewBox="0 0 76 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g ref={svgRef} className="animation">
          <StyledPath d="M17.5916 27.8C16.6916 27.8 15.4 27.8 15.4 27.8C9.31036 27.8 4.20001 22.8 4.20001 16.5C4.20001 10.2 9.00001 5.2 15.3 5.2C19.2 5.2 22.6998 6.90005 24.6998 10L25.0291 10.4688L35 27.9" />
          <StyledPath d="M35.0293 5.18745L47.9668 27.8437" />
          <StyledPath d="M48.0605 5.19995H61.2324C67.1324 5.59995 71.873 10.4938 71.873 16.5938C71.973 22.6937 66.2168 27.9375 60.9668 27.9375" />
          <StyledAnimatedPath
            id="first"
            d="M17.5916 27.8C16.6916 27.8 15.4 27.8 15.4 27.8C9.31036 27.8 4.20001 22.8 4.20001 16.5C4.20001 10.2 9.00001 5.2 15.3 5.2C19.2 5.2 22.6998 6.90005 24.6998 10L25.0291 10.4688L35 27.9"
          />
          <StyledAnimatedPath
            id="second"
            d="M35.0293 5.18745L47.9668 27.8437"
          />
          <StyledAnimatedPath
            id="third"
            d="M48.0605 5.19995H61.2324C67.1324 5.59995 71.873 10.4938 71.873 16.5938C71.973 22.6937 66.2168 27.9375 60.9668 27.9375"
            onAnimationEnd={onAnimationEnd}
          />
        </g>
      </AidIconLoader>
    </StyledLoader>
  );
}
