import gql from 'graphql-tag';
import { PROFILE_FRAGMENT } from '../../fragment/profiles/profiles';
import { CUSTOMER_FRAGMENT } from '../../fragment/cm';
import { ATTACHMENT_FRAGMENT } from '../../fragment/attachment';

export const CustomerDocuments = gql`
  ${ATTACHMENT_FRAGMENT}
  query CustomerDocuments(
    $page: Int
    $per: Int
    $q: String
    $expiry_date_before: String
    $expiry_date_after: String
    $document_type: String
    $blacklisted: Boolean
    $without_case_section_id: String
    $sort_field: String
    $sort_order: String
    $retention_plan_id: String
  ) {
    customerDocuments(
      page: $page
      per: $per
      q: $q
      expiry_date_before: $expiry_date_before
      expiry_date_after: $expiry_date_after
      document_type: $document_type
      blacklisted: $blacklisted
      without_case_section_id: $without_case_section_id
      sort_field: $sort_field
      sort_order: $sort_order
      retention_plan_id: $retention_plan_id
    )
      @rest(
        type: "ResponseCustomerDocuments"
        path: "/core/client/customer_documents?{args}"
      ) {
      data @type(name: "CustomerDocument") {
        id
        client_id
        veriff_result_id
        idenfy_result_id
        created_at
        updated_at
        date_of_birth
        date_of_issue
        expiry_date
        first_name
        last_name
        gender
        document_number
        document_type
        blacklisted
        country
        nationality
        attachments @type(name: "Attachment") {
          ...AttachmentFragment
        }
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const CustomerDocument = gql`
  ${PROFILE_FRAGMENT}
  ${CUSTOMER_FRAGMENT}
  ${ATTACHMENT_FRAGMENT}
  query CustomerDocument($id: String!) {
    customerDocument(id: $id)
      @rest(
        type: "ResponseCustomerDocument"
        path: "/core/client/customer_documents/{args.id}"
      ) {
      data @type(name: "CustomerDocument") {
        id
        client_id
        veriff_result_id
        idenfy_result_id
        created_at
        updated_at
        date_of_birth
        date_of_issue
        expiry_date
        first_name
        last_name
        gender
        document_number
        document_type
        blacklisted
        nationality
        country
        attachments @type(name: "Attachment") {
          ...AttachmentFragment
        }
        profiles @type(name: "Profile") {
          ...ProfileFragment
        }
        customers @type(name: "Customer") {
          ...CustomerFragment
        }
      }
      success
    }
  }
`;
