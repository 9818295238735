import styled from '@emotion/styled';
import { FlexContainer, GridContainer } from '../../../containers';

export const Container = styled(FlexContainer)`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const BulkContainer = styled(GridContainer)`
  width: max-content;
  position: absolute;
  margin: auto;
  bottom: 60px;
  left: 0;
  right: 0;
`;
