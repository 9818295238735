import {
  ContentLoader,
  DefaultButtonLoader,
  GridContainer,
  InputLoader,
  MediaLoader,
} from '@aid-module/ui';
import { Container } from '../Container';
import { FormContainer } from '../FormContainer';

export function Fallback() {
  return (
    <Container>
      <GridContainer gap="20">
        <ContentLoader width="100%" height="36.2px">
          <rect rx="3" ry="3" width="100%" height="36.2px" />
        </ContentLoader>
        <FormContainer label="Account settings" isLoading>
          <GridContainer gap="20">
            <MediaLoader />
            <GridContainer gap="14">
              <ContentLoader width="100%" height="18.2px">
                <rect rx="3" ry="3" width="100%" height="18.2px" />
              </ContentLoader>
              <ContentLoader width="100%" height="18.2px">
                <rect rx="3" ry="3" width="100%" height="18.2px" />
              </ContentLoader>
              <ContentLoader width="100%" height="18.2px">
                <rect rx="3" ry="3" width="100%" height="18.2px" />
              </ContentLoader>
              <ContentLoader width="100%" height="18.2px">
                <rect rx="3" ry="3" width="100%" height="18.2px" />
              </ContentLoader>
              <ContentLoader width="100%" height="18.2px">
                <rect rx="3" ry="3" width="100%" height="18.2px" />
              </ContentLoader>
              <ContentLoader width="100%" height="18.2px">
                <rect rx="3" ry="3" width="100%" height="18.2px" />
              </ContentLoader>
              <ContentLoader width="100%" height="18.2px">
                <rect rx="3" ry="3" width="100%" height="18.2px" />
              </ContentLoader>
            </GridContainer>
            <DefaultButtonLoader />
            <DefaultButtonLoader />
          </GridContainer>
        </FormContainer>
      </GridContainer>
    </Container>
  );
}
