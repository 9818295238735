import gql from 'graphql-tag';
import { BLACKLIST_REASON_FRAGMENT } from '../../../fragment/admin/blacklistReasons';
import { BLACKLISTED_PROFILE_FRAGMENT } from '../../../fragment/profiles/blacklistedProfiles';

export const BlacklistedProfiles = gql`
  ${BLACKLISTED_PROFILE_FRAGMENT}
  ${BLACKLIST_REASON_FRAGMENT}
  query BlacklistedProfiles(
    $page: Int
    $sort_field: String
    $sort_order: String
    $created_at_from: String
    $created_at_to: String
    $profile_kinds: String
    $blacklisted_reason_id: String
    $q: String
  ) {
    blacklistedProfiles(
      page: $page
      sort_field: $sort_field
      sort_order: $sort_order
      created_at_from: $created_at_from
      created_at_to: $created_at_to
      profile_kinds: $profile_kinds
      blacklisted_reason_id: $blacklisted_reason_id
      per: 20
      q: $q
    )
      @rest(
        type: "ResponseBlacklistedProfiles"
        path: "/core/client/blacklisted_profiles?{args}"
      ) {
      data @type(name: "BlacklistedProfile") {
        ...BlacklistedProfileFragment
        blacklist_reason @type(name: "BlacklistReason") {
          ...BlacklistReasonFragment
        }
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const BlacklistedProfile = gql`
  ${BLACKLISTED_PROFILE_FRAGMENT}
  ${BLACKLIST_REASON_FRAGMENT}
  query BlacklistedProfile($id: String!) {
    blacklistedProfile(id: $id)
      @rest(
        type: "ResponseBlacklistedProfile"
        path: "/core/client/blacklisted_profiles/{args.id}"
      ) {
      data @type(name: "BlacklistedProfile") {
        ...BlacklistedProfileFragment
        blacklist_reason @type(name: "BlacklistReason") {
          ...BlacklistReasonFragment
        }
        blacklisted_by @type(name: "Account") {
          avatar_id
          email
          file_name
          file_size
          full_name
          id
          phone
        }
      }
      success
    }
  }
`;
