import { Link } from '../Link';
import { MultiplePathsLinkContainer } from '../MultiplePathsLinkContainer';
import { useTransactionCasesLinkController } from './controller';

export function TransactionCasesLink() {
  const { transactionCasesPaths, mainPath } =
    useTransactionCasesLinkController();

  return (
    <MultiplePathsLinkContainer paths={transactionCasesPaths}>
      <Link to={mainPath} text="Investigations" />
    </MultiplePathsLinkContainer>
  );
}
