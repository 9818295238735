import gql from 'graphql-tag';

export const COMMENT_FRAGMENT = gql`
  fragment CommentFragment on Comment {
    account @type(name: "Account") {
      avatar_id
      created_at
      email
      first_name
      id
      last_name
      phone
      status
    }
    attachments @type(name: "Attachment") {
      account @type(name: "Account") {
        accounts_clients @type(name: "AccountClient") {
          account_id
          client_id
          id
        }
        created_at
        email
        first_name
        id
        last_name
        phone
        status
      }
      client_id
      created_at
      file_name
      file_size
      id
      kind
      subject_id
      subject_type
      updated_at
      uploaded_document_id
    }
    body
    created_at
    deleted
    documents
    can_see_all
    id
    state
    subject_id
    subject_type
    subject_entity_id
    subject_entity_type
    updated_at
  }
`;
