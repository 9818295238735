import {
  SelectOption,
  CheckupSearchHitMatchStatusResponse,
  CheckupStatus,
} from '@aid-module/ui';

export const IsWhitelistedOptions = [
  {
    label: 'Yes',
    value: 'Yes',
  },
  {
    label: 'No',
    value: 'No',
  },
];

export const matchStatusOptions: SelectOption[] = Object.values(
  CheckupSearchHitMatchStatusResponse
).map((key) => {
  const settings = CheckupStatus.getSettings(key);

  return {
    label: settings?.label || '',
    value: key,
  };
});
