import gql from 'graphql-tag';

export const ChangeAlertsStateBulk = gql`
  mutation ChangeAlertsStateBulk(
    $body: BodyChangeAlertsStateBulk!
  ) {
    changeAlertsStateBulk(body: $body)
    @rest(
      type: "ChangeAlertsStateBulk"
      path: "/tm/alerts/change_state/bulk"
      bodyKey: "body"
      method: "POST"
    ) {
      data @type(name: "TransactionAlert") {
        alert_no
        created_at
        data {
          title
          description
        }
        deadline_at
        event_support_keys
        id
        kind
        disposition @type(name: "TransactionDisposition") {
          id
          name
        }
        disposition_message
        total_flagged_amount
        disposition_updated_at
        profiles @type(name: "Profile") {
          display_name
          id
        }
        assigned_to @type(name: "Account") {
          id
          email
          first_name
          last_name
        }
        rules @type(name: "Rule") {
          async
          code
          created_at
          deleted
          description
          enabled
          id
          name
          provider_id
          ruleset_id
          is_for_test
          settings
        }
        is_for_test
        rule_name
        state
        unit21_alert_external_id
        unit21_external_id
        unit21_url
        updated_at
      }
      success
    }
  }
`;

export const LinkAlertsProfileLinkBulk = gql`
  mutation LinkAlertsProfileLinkBulk(
    $body: BodyLinkAlertsProfileLinkBulk!
  ) {
    linkAlertsProfileLinkBulk(body: $body)
    @rest(
      type: "LinkAlertsProfileLinkBulk"
      path: "/tm/alerts/link_profile/bulk"
      bodyKey: "body"
      method: "POST"
    ) {
      data @type(name: "TransactionAlert") {
        alert_no
        created_at
        data {
          title
          description
        }
        deadline_at
        event_support_keys
        id
        kind
        disposition @type(name: "TransactionDisposition") {
          id
          name
        }
        disposition_message
        total_flagged_amount
        disposition_updated_at
        profiles @type(name: "Profile") {
          display_name
          id
        }
        assigned_to @type(name: "Account") {
          id
          email
          first_name
          last_name
        }
        rules @type(name: "Rule") {
          async
          code
          created_at
          deleted
          description
          enabled
          id
          name
          provider_id
          ruleset_id
          is_for_test
          settings
        }
        is_for_test
        rule_name
        state
        unit21_alert_external_id
        unit21_external_id
        unit21_url
        updated_at
      }
      success
    }
  }
`;

export const AssignAlertsAccountBulk = gql`
  mutation AssignAlertsAccountBulk(
    $body: BodyAssignAlertsAccountBulk!
  ) {
    assignAlertsAccountBulk(body: $body)
    @rest(
      type: "AssignAlertsAccountBulk"
      path: "/tm/alerts/assign_account/bulk"
      bodyKey: "body"
      method: "POST"
    ) {
      data @type(name: "TransactionAlert") {
        alert_no
        created_at
        data {
          title
          description
        }
        deadline_at
        event_support_keys
        id
        kind
        disposition @type(name: "TransactionDisposition") {
          id
          name
        }
        disposition_message
        total_flagged_amount
        disposition_updated_at
        profiles @type(name: "Profile") {
          display_name
          id
        }
        assigned_to @type(name: "Account") {
          id
          email
          first_name
          last_name
        }
        rules @type(name: "Rule") {
          async
          code
          created_at
          deleted
          description
          enabled
          id
          name
          provider_id
          ruleset_id
          is_for_test
          settings
        }
        is_for_test
        rule_name
        state
        unit21_alert_external_id
        unit21_external_id
        unit21_url
        updated_at
      }
      success
    }
  }
`;

export const SetAlertsDispositionBulk = gql`
  mutation SetAlertsDispositionBulk(
    $body: BodySetAlertsDispositionBulk!
  ) {
    setAlertsDispositionBulk(body: $body)
    @rest(
      type: "SetAlertsDispositionBulk"
      path: "/tm/alerts/change_disposition/bulk"
      bodyKey: "body"
      method: "POST"
    ) {
      data @type(name: "TransactionAlert") {
        alert_no
        created_at
        data {
          title
          description
        }
        deadline_at
        event_support_keys
        id
        kind
        disposition @type(name: "TransactionDisposition") {
          id
          name
        }
        disposition_message
        total_flagged_amount
        disposition_updated_at
        profiles @type(name: "Profile") {
          display_name
          id
        }
        assigned_to @type(name: "Account") {
          id
          email
          first_name
          last_name
        }
        rules @type(name: "Rule") {
          async
          code
          created_at
          deleted
          description
          enabled
          id
          name
          provider_id
          ruleset_id
          is_for_test
          settings
        }
        is_for_test
        rule_name
        state
        unit21_alert_external_id
        unit21_external_id
        unit21_url
        updated_at
      }
      success
    }
  }
`;
