import { FlexContainer, GridContainer } from '../../../containers';
import { ContentLoader } from '../ContentLoader';
const startY = 0;
const yArray = Array.from({ length: 20 }, (_, index) => {
  const size = 52;
  return startY + index * size;
});

export function HeadLoader() {
  return (
    <ContentLoader width="100%" height="50.5">
      <rect x="0" y="0" rx="3" ry="3" width="100%" height="50.5px" />
    </ContentLoader>
  );
}

export function BodyLoader() {
  return (
    <ContentLoader width="100%" height={`${yArray[yArray.length - 1]}`}>
      {yArray.map((y) => (
        <rect key={y} x="0" y={y + 8} width="100%" height="44" />
      ))}
    </ContentLoader>
  );
}

export function TableLoader() {
  return (
    <GridContainer
      style={{ overflow: 'hidden', width: '100%' }}
      gridTemplateRows="max-content minmax(0, 1fr)"
      gridTemplateColumns="1fr"
    >
      <HeadLoader />
      <BodyLoader />
    </GridContainer>
  );
}
