import { TYPES } from '@aid-package/api';
import { createContext, useContext } from 'react';

export const HitContext = createContext<
  NonNullable<
    TYPES.ServiceCheckupQuery['serviceCheckup']['data']['serialized_result']['data']
  >['hits'][0]
>({} as any);

export function useHitContext() {
  const context = useContext(HitContext);

  return context;
}
