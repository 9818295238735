import gql from 'graphql-tag';

export const VERIFICATION_STEP_PASSING_FRAGMENT = gql`
  fragment VerificationStepPassingFragment on VerificationStepPassing {
    created_at
    customer_verification_id
    form_submission_id
    id
    idenfy_status
    status
    updated_at
    verification_step_id
  }
`;
