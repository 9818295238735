import gql from 'graphql-tag';

export const TariffNames = gql`
  query TariffNames($date_from: String, $date_to: String) {
    tariffNames(date_from: $date_from, date_to: $date_to)
      @rest(
        type: "ResponseTariffNames"
        path: "/stats/submission_field_presets/tariff_names?{args}"
      ) {
      data
      success
    }
  }
`;
