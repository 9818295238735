import { Text } from '../Text';
import { Container } from './styles';
import { CountViewProps } from './types';

export function Count({
  count = 0,
  background,
  color = 'white-primary',
  size = 10,
  padding = 'XS',
  border,
  ...props
}: CountViewProps) {
  return (
    <Container
      alignItems="center"
      justifyContent="center"
      background={background}
      padding={padding}
      border={border}
    >
      <Text color={color} size={size} {...props} text={count.toString()} />
    </Container>
  );
}

export const Tag = Count;
export type { CountViewProps };
