import { ButtonByType } from '../components';
import { ButtonViewProps } from '../types';

export function SuccessButton(props: ButtonViewProps) {
  return (
    <ButtonByType
      {...props}
      color="white-primary"
      background="success-deep-4"
    />
  );
}
