import { usePricingClientBalanceQuery } from '@aid-package/api';
import { useClientContext } from '@aid-package/context';
import { useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export function useBalanceModel() {
  const { data, loading } = usePricingClientBalanceQuery({
    fetchPolicy: 'network-only',
  });
  const { client_id } = useParams();
  const { client } = useClientContext();
  const navigate = useNavigate();

  const getNumber = useRef((string?: string | null) => {
    if (!string) {
      return 0;
    }
    const number = Number(string);
    if (isNaN(number)) {
      return 0;
    }
    return number;
  });

  const getString = useRef((number: number) => {
    const stringBalance = number.toFixed(2);
    const [leftSide, rightSide] = stringBalance.split('.');
    if (leftSide.length < 4) {
      return `$${leftSide}.${rightSide}`;
    }
    const reversedArray = leftSide.split('').reverse();
    const parsedLeftSide = reversedArray.reduce((acc, character, index) => {
      const count = index + 1;
      if (count % 3 === 0) {
        return `${reversedArray[index + 1] ? ',' : ''}${character}${acc}`;
      }
      return `${character}${acc}`;
    }, '');
    return `$${parsedLeftSide}.${rightSide}`;
  });

  const balanceAmount = useMemo(() => {
    const balance = data?.pricingClientBalance?.data;
    if (balance) {
      return getNumber.current(balance.balance);
    }
  }, [data]);

  const balanceText = useMemo(() => {
    const balance = data?.pricingClientBalance?.data;
    if (balance) {
      const frozen = getNumber.current(balance.balance);
      return getString.current(frozen);
    }
  }, [data]);

  const navigateToBiling = useRef((client_id: string) => {
    navigate(`/${client_id}/account/balance/form`);
  });

  return {
    balanceText,
    balanceAmount,
    loading,
    client,
    navigateToBiling,
    client_id,
  };
}
