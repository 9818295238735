import { useRef } from 'react';
import debounce from 'lodash.debounce';
import { useConfigContext, useVariablesContext } from '../../../../context';
import { SearchParams } from '../../../../../SearchSelect/component/types';

export function useHeaderTableModel() {
  const filtersConfig = useConfigContext();
  const context = useVariablesContext();

  const onChangeSearch = useRef(
    debounce((value?: string) => {
      context?.onChangeVariables((prev) => {
        if (value) {
          return {
            ...prev,
            q: value,
          };
        }

        delete prev.q;
        return { ...prev };
      });
    }, 1000)
  );

  const onChangeSelect = useRef((value?: SearchParams) => {
    context?.onChangeVariables((prev) => {
      if (value) {
        return {
          ...prev,
          q: value,
        };
      }

      delete prev.q;
      return { ...prev };
    });
  });

  return {
    onChangeSearch: onChangeSearch.current,
    onChangeSelect: onChangeSelect.current,
    filtersConfig,
  };
}
