import gql from 'graphql-tag';

export const USER_ACCOUNT_FRAGMENT = gql`
  fragment UserAccountFragment on Account {
    avatar_id
    created_at
    email
    extra_attributes {
      city
      company
      about_me
      birthday
    }
    file_name
    file_size
    first_name
    full_name
    id
    last_name
    phone
    region_code
    status
    updated_at
  }
`;
