import styled from '@emotion/styled';
import { GridContainer } from '../../containers';
import { Card } from '../Card';

export const Container = styled(Card)`
  display: grid;
  border-radius: 4px;
  min-height: 132px;
  height: calc(100% - 54px);
`;

export const ContentContainer = styled(GridContainer)`
  min-height: 100%;
`;
