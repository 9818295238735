import { Link } from '../Link';
import { MultiplePathsLinkContainer } from '../MultiplePathsLinkContainer';
import { useDashboardLinkController } from './controller';

export function DashboardLink() {
  const { dashboardsPaths, dashboardAccesiblePath } =
    useDashboardLinkController();

  return (
    <MultiplePathsLinkContainer paths={dashboardsPaths}>
      <Link to={dashboardAccesiblePath} icon="dashboard" text="Dashboard" />
    </MultiplePathsLinkContainer>
  );
}
