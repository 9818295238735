import { useCallback } from 'react';
import { useConfirmModal } from '../../../../../../../../../../../../../ConfirmModal';
import { ResolveType } from '../../../../../../../../../../../../../ConfirmModal/ModalController/types';
import { useNavigationContext } from '../../../../../../../context';

export function useEditFilterTemplateController() {
  const { toggleShowFilters } = useNavigationContext();

  const onConfirmCallback = useCallback(
    (type?: ResolveType) => {
      if (type === 'confirm') {
        toggleShowFilters();
      }
    },
    [toggleShowFilters]
  );

  const { show: onClickClose } = useConfirmModal({
    body: 'Are you sure you want to close filters  without saving the current changes?',
    type: 'error',
    cancelActionTitle: 'Cancel',
    confirmActionTitle: 'Yes, сlose',
    onConfirmCallback,
  });

  return {
    onClickClose,
  };
}
