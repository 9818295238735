import { Actions } from './components';
import { Form } from '../Form';

export function Filters() {
  return (
    <Form>
      <Actions />
    </Form>
  );
}
