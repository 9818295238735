import {
  ClientAvatar,
  fromNow,
  GridContainer,
  IconLoader,
  Text,
} from '@aid-module/ui';
import { Actions } from './components';
import { useNotificationController } from './controller';
import { Container } from './styles';
import { NotificationViewProps } from './types';
import { Notification as NotificationText } from '@aid-module/notification';

export function Notification({ notification }: NotificationViewProps) {
  const { onClick } = useNotificationController();
  return (
    <Container gridTemplateColumns="40px 1fr" gap="8" onClick={onClick}>
      <ClientAvatar
        client={notification.client}
        fallback={<IconLoader size={40} />}
      />
      <GridContainer gap="6">
        <NotificationText payloadData={notification.payload.data} />
        <Text
          text={fromNow(notification.created_at)}
          size={10}
          color="gray-deep-3"
        />
      </GridContainer>
      <Actions notification={notification} />
    </Container>
  );
}
