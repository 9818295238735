import { gql } from '@apollo/client';

export const TransactionBlacklistGroups = gql`
  query TransactionBlacklistGroups($subject_id: String, $subject_type: String,  $q: String, $page: Int) {
    transactionBlacklistGroups(subject_id: $subject_id, subject_type: $subject_type, page: $page, per: 20, q: $q)
    @rest(
      type: "ResponseTransactionBlacklistGroups"
      path: "/tm/blacklist/groups"
    ) {
      data @type(name: "TransactionBlacklistGroup") {
        id
        name
        description
        kind
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const TransactionBlacklistGroup = gql`
  query TransactionBlacklistGroup($id: String) {
    transactionBlacklistGroup(id: $id)
    @rest(
      type: "ResponseTransactionBlacklistGroup"
      path: "/tm/blacklist/groups/{args.id}"
    ) {
      data @type(name: "TransactionBlacklistGroup") {
        id
        name
        description
        kind
      }
      success
    }
  }
`;

export const TransactionBlacklistCommonItems = gql`
  query TransactionBlacklistCommonItems($blacklist_group_id: String, $kind: String,  $q: String, $page: Int) {
    transactionBlacklistCommonItems(blacklist_group_id: $blacklist_group_id, kind: $kind, page: $page, per: 20, q: $q)
    @rest(
      type: "ResponseTransactionBlacklistCommonItems"
      path: "/tm/blacklist/common_items?{args}"
    ) {
      data @type(name: "TransactionCommonItem") {
        id
        name
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;
