import { gql } from '@apollo/client';

export const RequiredActions = gql`
  query RequiredActions($case_id: String!, $state: String, $author_id: String, $q: String, $page: Int) {
    requiredActions(case_id: $case_id, state: $state, author_id: $author_id, page: $page, per: 20, q: $q)
    @rest(
      type: "ResponseRequiredActions"
      path: "/core/client/required_actions?{args}"
    ) {
      data @type(name: "RequiredActionItem") {
        id
        case_id
        state
        author @type(name: "Account") {
          avatar_id
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        meta
        required_action_source @type(name: "RequiredActionSource") {
          id
          code
          created_at
          deleted_at
          description
          name
          system
        }
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const RequiredActionsSources = gql`
  query RequiredActionsSources($q: String, $page: Int, $with_deleted: Boolean) {
    requiredActionsSources(page: $page, per: 20, q: $q, with_deleted: $with_deleted)
    @rest(
      type: "ResponseRequiredActionsSources"
      path: "/core/client/required_action_sources"
    ) {
      data @type(name: "RequiredActionSource") {
        id
        code
        created_at
        deleted_at
        description
        name
        system
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;
