import { useCallback, useEffect, useRef, useState } from 'react';
import { ModalController } from '../../../ModalController';
import { ModalViewProps } from '../types';

export function useModalController(controller: ModalViewProps['controller']) {
  const [options, setOptions] = useState<
    Pick<ModalController, 'open' | 'props'>
  >({
    open: controller.open,
    props: controller.props,
  });

  const onChangeStatus = useRef((open: boolean) => {
    setOptions((prev) => ({
      ...prev,
      open,
    }));
  });

  const onUpdateProps = useRef((props: ModalController['props']) => {
    setOptions((prev) => ({
      ...prev,
      props,
    }));
  });

  const init = useRef((controller: ModalViewProps['controller']) => {
    setOptions((prev) => {
      if (prev.open !== controller.open || prev.props !== controller.props) {
        return {
          open: controller.open,
          props: controller.props,
        };
      }
      return prev;
    });

    controller.onStatusChange = onChangeStatus.current;
    controller.onUpdateProps = onUpdateProps.current;
  });

  useEffect(() => {
    init.current(controller);

    return () => {
      controller.onStatusChange = undefined;
      controller.onUpdateProps = undefined;
    };
  }, [controller]);

  const onClickConfirm = useCallback(() => {
    controller.confirm('confirm');
  }, [controller]);

  const onClickCancel = useCallback(() => {
    controller.confirm('cancel');
  }, [controller]);

  const onRequestReject = useCallback(() => {
    controller.close();
  }, [controller]);

  return {
    close,
    onClickConfirm,
    isOpen: options.open,
    props: options.props,
    onClickCancel,
    onRequestReject,
  };
}
