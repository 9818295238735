import { useMemo } from 'react';
import { TmEntityStatusViewProps } from '../types';
import { getColorByStatus } from './utils';
import { TRANSACTION_ENTITY_DISPLAY_STATUSES } from '../constants';

export function useTmEntityStatusController(
  status: TmEntityStatusViewProps['status']
) {
  const background = useMemo(() => getColorByStatus(status), [status]);

  const text = useMemo(
    () =>
      status
        ? TRANSACTION_ENTITY_DISPLAY_STATUSES[status] ||
          status.replace('_', '  ')
        : '',
    [status]
  );

  return {
    text,
    background,
  };
}
