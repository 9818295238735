import { AssignToContext } from '../../context';
import { Body, Header } from './components';
import { Container } from './styles';
import { WidgetViewProps } from './types';

export function Widget(props: WidgetViewProps) {
  return (
    <AssignToContext.Provider value={props}>
      <Container gridTemplateRows="62px minmax(0, 1fr)">
        <Header />
        <Body />
      </Container>
    </AssignToContext.Provider>
  );
}
