import { PropsWithChildren } from 'react';
import { Icon } from '../../../../../../../Icon';
import { Text } from '../../../../../../../Text';
import { GridContainer } from '../../../../../../../../containers';
import { Container } from './styles';
import { SmallDefaultButtonViewProps } from '../../../../../../types';
import { useButtonController } from '../../../controller';
import { IconLoader } from '../../../../../../../../shared';

export function DefaultButtonComponent({
  icon,
  iconSize = 12,
  color,
  background,
  text,
  border,
  disabled,
  className,
  onClick,
  children,
}: PropsWithChildren<SmallDefaultButtonViewProps>) {
  const { textColor } = useButtonController(color, disabled);

  return (
    <GridContainer
      gridTemplateColumns={children ? '1fr max-content' : '1fr'}
      gap="1"
    >
      <Container
        className={className}
        disabled={disabled}
        background={background}
        withChildren={!!children}
        border={border}
        color={color}
        onClick={onClick}
      >
        {icon && (
          <Icon
            size={iconSize}
            color={textColor}
            glyph={icon}
            fallback={<IconLoader size={iconSize} />}
          />
        )}
        <Text color={textColor} text={text} />
      </Container>
      {children}
    </GridContainer>
  );
}
