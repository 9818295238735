import { DraggableViewProps } from './types';
import {
  DraggableProvided,
  DraggableStateSnapshot,
  Draggable as UIDraggable,
} from 'react-beautiful-dnd';
import { Template } from './components';

function renderDraggable(
  template: DraggableViewProps['template'],
  index: number
) {
  return function (
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot
  ) {
    return (
      <Template
        key={template.key}
        index={index}
        template={template}
        provided={provided}
        snapshot={snapshot}
      />
    );
  };
}

export function Draggable({ template, index }: DraggableViewProps) {
  return (
    <UIDraggable draggableId={template.key} index={index}>
      {renderDraggable(template, index)}
    </UIDraggable>
  );
}
