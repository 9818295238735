import styled from '@emotion/styled/macro';
import { EmotionContainerViewProps } from './types';
import {
  FlexContainer,
  GridContainer,
  Text,
  getColor,
  getSpace,
} from '@aid-module/ui';

export const DescriptionContainer = styled(FlexContainer)`
height: 100%:
`;

export const TitleContainer = styled(GridContainer)`
  padding: ${getSpace('20')};
`;

export const WrappedText = styled(Text)`
  overflow-wrap: anywhere;
`;

export const UnderlineText = styled(WrappedText)`
  text-decoration-line: underline;
`;


export const FooterText = styled(Text)``;

export const Container = styled(GridContainer)<EmotionContainerViewProps>`
  height: 100%;
  width: 100%;
  overflow: auto;
  position: relative;
  min-height: 500px;

  & > * {
    z-index: 1;
  }

  &.local {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom": 0;
    align-items: flex-start;

    :after {
      color: ${getColor('main-deep-2')};
      line-height: 470px;
      margin: 0 0 0 -90px;
    }

    & > ${DescriptionContainer} {
      flex-direction: row;
      justify-content: center;
      margin: -120px 0 0 3%;

      & > ${TitleContainer} {
        min-width: 720px;
        max-width: 746px;
        align-items: flex-start;
        padding: 0 85px 0 0;
      }
    }
  }
`;
