import { ContentLoader } from '../ContentLoader';

export function IconButtonLoader({ size }: { size?: number }) {
  return (
    <ContentLoader width={`${size || 22}px`} height={`${size || 22}px`}>
      <rect
        rx="4"
        ry="20"
        width={`${size || 22}px`}
        height={`${size || 22}px`}
      />
    </ContentLoader>
  );
}
