import { PropsWithChildren } from 'react';
import { StyledLink, LinkText } from '../shared.styles';
import { LinkViewProps } from '../types';
import { stopPropagation } from '../utils';

export function DefaultLink({
  to,
  target,
  onClick,
  size = 14,
  color = 'main-deep-4',
  children,
  ...props
}: PropsWithChildren<LinkViewProps>) {
  return (
    <StyledLink
      onClick={onClick || stopPropagation}
      target={target}
      to={to}
      rel="noopener noreferrer"
      withoutChildren={!children}
    >
      <LinkText {...props} size={size} color={color} />
      {children}
    </StyledLink>
  );
}
