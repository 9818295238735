import { ErrorActions, ErrorTemplate } from './components';
import { ErrorActionsViewProps } from './components/ErrorActions/types';
import { ErrorTemplateViewProps } from './components/ErrorTemplate/types';

export function Error404({
  type = 'global',
  hideBack = true,
  title = 'Page not found',
  description = 'such page does not exist or has been deleted',
}: Partial<Pick<ErrorTemplateViewProps, 'type' | 'title' | 'description'>> &
  ErrorActionsViewProps) {
  return (
    <ErrorTemplate
      type={type}
      title={title}
      description={description}
      code={404}
    >
      <ErrorActions hideBack={hideBack} />
    </ErrorTemplate>
  );
}
