import { LoaderThemeProvider } from '../../../../shared';
import { ToastComponent } from '../ToastComponent';
import { ToastComponentProps } from '../types';

export function Warning(props: ToastComponentProps) {
  return (
    <LoaderThemeProvider background="warning-deep-3" foreground="warning-deep-2">
      <ToastComponent icon="warning" color="warning-deep-4" {...props} />
    </LoaderThemeProvider>
  );
}
