import gql from 'graphql-tag';

export const CdsDictionary = gql`
  query CdsDictionary {
    cdsDictionary
      @rest(type: "ResponseCdsDictionary", path: "/cds/dictionaries/all") {
      data @type(name: "CdsDictionary") {
        entities {
          map_to_field_names
          map_to_table_name
        }
        entity_template_kinds
        entity_template_types
        mapper_klasses
      }
      success
    }
  }
`;
