import { Link } from '../Link';
import { MultiplePathsLinkContainer } from '../MultiplePathsLinkContainer';
import { useCaseManagementLinkController } from './controller';

export function CaseManagementLink() {
  const { caseManagementPaths, caseManagementAccesiblePath } =
    useCaseManagementLinkController();

  return (
    <MultiplePathsLinkContainer paths={caseManagementPaths}>
      <Link
        to={caseManagementAccesiblePath}
        icon="briefcase"
        text="Case Management"
      />
    </MultiplePathsLinkContainer>
  );
}
