import loadable from '@loadable/component';
import { DashboardCardLoader } from '../../shared';
import { DashboardCardComponentViewProps } from './component/types';
import { LoadableFunctionalComponent } from '../../utils';
export * from './component/types';

export const DashboardCard = loadable(() => import('./component'), {
  resolveComponent: (components) => components.DashboardCardComponent,
  fallback: <DashboardCardLoader />,
}) as LoadableFunctionalComponent<DashboardCardComponentViewProps>;
