import {
  DefaultButtonLoader,
  GridContainer,
  ListLoader,
  Text,
} from '@aid-module/ui';

export function Fallback() {
  return (
    <GridContainer gridTemplateColumns="1fr" gap="32">
      <Text
        text="Credentials"
        size={21}
        weight="Medium"
        color="main-deep-4"
        align="center"
        uppercase
      />
      <ListLoader />
      <DefaultButtonLoader />
    </GridContainer>
  );
}
