import { useMemo } from 'react';
import { useEditContext } from '../../../../context';

export function useBulkContainer() {
  const { selectedIds, clean } = useEditContext();

  const isSelected = useMemo(() => {
    return !!Object.keys(selectedIds).length;
  }, [selectedIds]);

  const selectedCount = useMemo(() => {
    return Object.keys(selectedIds).length;
  }, [selectedIds]);

  return { isSelected, selectedCount, clean };
}
