import { gql } from '@apollo/client';

export const TransactionProviders = gql`
  query TransactionProviders {
    transactionProviders
      @rest(type: "ResponseTransactionProviders", path: "/tm/providers") {
      data @type(name: "TransactionProvider") {
        settings
        client_id
        created_at
        enabled
        external
        id
        kind
      }
      success
    }
  }
`;
