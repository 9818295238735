import gql from 'graphql-tag';

export const CASE_CONFIG_FRAGMENT = gql`
  fragment CaseConfigFragment on CaseConfig {
    id
    form_configuration_ref
    verification_flow_id
    enabled_features_codes
    custom_steps @type(name: "CaseConfigCustomSteps") {
      id
      name
      custom_actions @type(name: "CaseConfigCustomActions") {
        id
        name
        kind
        mandatory
        repeatable
      }
    }
    auto_approve_config @type(name: "AutoApproveCaseConfig") {
      id
      auto_approve_rules @type(name: "AutoApproveRule") {
        id
        enabled_by_feature_code
        executor_class
        settings @type(name: "AutoApproveSettings") {
          auto_approve_limit
          reject_limit
          waiting_list_limit
        }
      }
      case_config_id
    }
    case_summary_config_id
    case_summary_config @type(name: "AISummaryConfig") {
      id
      client_id
      name
      body
      additional_requirements
      response_example
      created_at
      updated_at
    }
    case_question_configs @type(name: "CaseQuestionConfig") {
      id
      case_config_id
      client_id
      created_at
      question_body
      updated_at
    }
  }
`;
