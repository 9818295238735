import gql from 'graphql-tag';
import { USER_ACCOUNT_FRAGMENT } from './UserAccount';

export const USER_FRAGMENT = gql`
  ${USER_ACCOUNT_FRAGMENT}
  fragment UserFragment on User {
    account @type(name: "Account") {
      ...UserAccountFragment
    }
    account_id
    client_id
    created_at
    enabled
    id
    position_name
    role
    updated_at
  }
`;
