import {
  LoadableFunctionalComponent,
  TableWithHeaderLoader,
} from '@aid-module/ui';
import loadable from '@loadable/component';

export const PricingFeatureDictionariesRegister = loadable(
  () => import('./component'),
  {
    resolveComponent: (components) =>
      components.PricingFeatureDictionariesRegisterComponent,
    fallback: <TableWithHeaderLoader />,
  }
) as LoadableFunctionalComponent<{}>;
