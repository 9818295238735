import loadable from '@loadable/component';
import { LoadableFunctionalComponent } from '../../utils';
import { InputLoader } from '../../shared';
import { TagSelectComponentViewProps } from './component/types';
export * from './component/types';

export const TagsSelect = loadable(() => import('./component'), {
  resolveComponent: (components) => components.TagsSelectComponent,
  fallback: <InputLoader />,
}) as LoadableFunctionalComponent<TagSelectComponentViewProps>;
