import { PrimaryButton, SecondaryButton } from '../../../../components/Button';
import { useErrorActionsController } from './controller';
import { ActionsContainer } from './styles';
import { ErrorActionsViewProps } from './types';

export function ErrorActions({ hideBack }: ErrorActionsViewProps) {
  const { onGoBack, onGoMainPage } = useErrorActionsController();
  return (
    <ActionsContainer>
      {!hideBack && <SecondaryButton text="Go back" onClick={onGoBack} />}
      <PrimaryButton text="Go to main page" onClick={onGoMainPage} />
    </ActionsContainer>
  );
}
