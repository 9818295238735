import { PrimaryButton, SecondaryButton } from '../../../../../../../../../../../../../../Button';
import { Save } from './components';
import { useActionsController } from './controller';
import { ButtonContainer } from './styles';

export function Actions() {
  const { onClickClear, onClickSubmit } = useActionsController();
  return (
    <ButtonContainer gridTemplateColumns="repeat(3, 265px)" gap="14">
      <SecondaryButton icon="delete" text="Clear all" onClick={onClickClear} />
      <Save />
      <PrimaryButton icon="checkmark" text="Apply" onClick={onClickSubmit} />
    </ButtonContainer>
  );
}
