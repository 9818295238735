import gql from 'graphql-tag';

export const Permissions = gql`
  query Permissions {
    permissions
      @rest(type: "ResponsePermissions", path: "/core/client/permissions") {
      data @type(name: "AllPermissionParent") {
        created_at
        description
        id
        name
        permissions @type(name: "Permission") {
          code
          created_at
          description
          id
          name
          updated_at
        }
        updated_at
      }
      success
    }
  }
`;
