import { Icon, PrimaryButton, SecondaryButton } from '@aid-module/ui';
import { useSearchController } from './controller';
import { Container, IconContainer } from './styles';
import { SearchInput } from './components';

export function Search() {
  const { isOpen, onNavigateToSearchHistory, toggleOpen, canShowHistory } =
    useSearchController();

  if (isOpen) {
    return (
      <Container
        gridTemplateColumns={
          canShowHistory ? '1fr max-content max-content' : '1fr max-content'
        }
        gap="12"
        alignItems="center"
      >
        <SearchInput />
        {canShowHistory && (
          <PrimaryButton
            onClick={onNavigateToSearchHistory}
            text="View search history"
            icon="clockCircle"
          />
        )}
        <SecondaryButton text="Close" onClick={toggleOpen} />
      </Container>
    );
  }

  return (
    <IconContainer
      justifyContent="center"
      alignItems="center"
      onClick={toggleOpen}
    >
      <Icon glyph="searchDetail" size={24} color="main-deep-4" />
    </IconContainer>
  );
}
