import { LoadableFunctionalComponent } from '@aid-module/ui';
import loadable from '@loadable/component';
import { FormContainer } from '../../components';
import { Fallback } from './components';

export const FieldTemplateCreate = loadable(() => import('./component'), {
  resolveComponent: (components) => components.FieldTemplateCreateCompoent,
  fallback: (
    <FormContainer label="Create Field Template" icon="fieldTemplate">
      <Fallback />
    </FormContainer>
  ),
}) as LoadableFunctionalComponent<{}>;
