import { ActionButtonLoader } from '../../../../../../../shared';
import { ActionButton } from '../../../../../../Button';
import { useColumnsController } from './controller';
import { Container } from './styles';
import { ColumnsViewProps } from './types';

export function Columns({ columns }: ColumnsViewProps) {
  const { onClickButton } = useColumnsController(columns);
  return (
    <Container>
      <ActionButton
        label="Columns"
        icon="columns"
        iconSide="left"
        color="main-deep-4"
        onClick={onClickButton}
        fallback={<ActionButtonLoader width={123.42} height={32.2} />}
      />
    </Container>
  );
}
