import gql from 'graphql-tag';
import { CASE_SECTION_ITEM_FRAGMENT } from './caseSectionItem';

export const CASE_SECTION_FRAGMENT = gql`
  ${CASE_SECTION_ITEM_FRAGMENT}
  fragment CaseSectionFragment on CaseSection {
    case_section_items @type(name: "CaseSectionItem") {
      ...CaseSectionItemFragment
    }
    case_section_items_count
    created_at
    id
    kind
    state
    updated_at
  }
`;
