import { ErrorTemplateViewProps } from './components/ErrorTemplate/types';
import { ErrorTemplate } from './components';

export function Error500({ type }: Pick<ErrorTemplateViewProps, 'type'>) {
  return (
    <ErrorTemplate
      type={type}
      title="Internal Server Error"
      description="try again a bit later"
      code={500}
    />
  );
}
