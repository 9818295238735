import { getColor, getSpace, GridContainer } from '@aid-module/ui';
import styled from '@emotion/styled';

export const Container = styled(GridContainer)`
  position: relative;
  padding: ${getSpace('12')} ${getSpace('60')} ${getSpace('12')}
    ${getSpace('20')};
  border-bottom: 1px solid ${getColor('main-deep-1')};
  width: 100%;
  box-sizing: border-box;
`;
