import styled from '@emotion/styled';
import { getColor, getSpace, GridContainer } from '@aid-module/ui';

export const HeadlineContainer = styled(GridContainer)`
  background: ${getColor('main-deep-1')};
  padding: ${getSpace('10')} ${getSpace('20')};
`;

export const Container = styled(GridContainer)`
  padding: ${getSpace('12')} ${getSpace('20')};
`;
