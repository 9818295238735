import { ContentLoader } from '../ContentLoader';
import { CKEditorLoaderViewProps } from './types';

export function CKEditorLoader({
  width,
  height,
  radius,
}: CKEditorLoaderViewProps) {
  return (
    <ContentLoader width={width || '100%'} height={height || '244.27'}>
      <rect
        rx={radius || '8'}
        ry={radius || '8'}
        width={width || '100%'}
        height={height || '244.27'}
      />
    </ContentLoader>
  );
}
