import styled from '@emotion/styled';
import { FlexContainer } from '../../../containers';

export const Container = styled(FlexContainer)`
  min-width: 100%;
  height: 100%;

  & > div:not(:first-of-type) {
    margin-top: 20px;
  }
`;
