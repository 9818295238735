import gql from 'graphql-tag';

export const FORM_INVITATION_FRAGMENT = gql`
  fragment FormInvitationFragment on FormInvitation {
    base_form_submission_id
    client_id
    created_at
    expire_at
    form_invitation_fields @type(name: "FormInvitationField") {
      created_at
      form_field_id
      form_invitation_id
      id
      updated_at
    }
    id
    invite_type
    reference_key
    status
    updated_at
    userable_id
    userable_type
  }
`;
