import loadable from '@loadable/component';
import { TableLoader } from '../../../shared';
import { LoadableTableFunctionComponent } from './types';

export const ExtensionAsyncTableWithPaggination = loadable(
  () => import('./component'),
  {
    resolveComponent: (components) =>
      components.ExtensionAsyncTableWithPagginationComponent,
    fallback: <TableLoader />,
  }
) as LoadableTableFunctionComponent;
