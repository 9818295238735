import { TabRegistries, useChangePositionTabRegistryMutation, TYPES } from '@aid-package/api';
import { useCallback } from 'react';

export function useDroppableModel() {
  const [updateMutation] = useChangePositionTabRegistryMutation({
    refetchQueries: [TabRegistries],
  });

  const updateTabRegistry = useCallback(
    (id: string, body: TYPES.BodyChangePositionTabRegistry) => {
      updateMutation({
        variables: { id, body },
      });
    },
    [updateMutation]
  );

  return {
    updateTabRegistry,
  };
}
