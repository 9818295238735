import { Text } from '@aid-module/ui';
import { useCallback } from 'react';
import { Container } from './styles';
import { SubListViewProps } from './types';

export function SubList({ list }: SubListViewProps) {
  const renderListItem = useCallback(
    (item?: string | null, index?: number) => (
      <li key={index}>
        <Text text={item || '-'} />
      </li>
    ),
    []
  );

  return <Container>{list?.map(renderListItem)}</Container>;
}
