import { TYPES } from '@aid-package/api';
import { useMemo } from 'react';
import { useHitContext } from '../../../context';

export function useAdditionalInformationController() {
  const hit = useHitContext();

  const config = useMemo(() => {
    const configItems = hit?.doc?.fields?.map(
      (
        item: NonNullable<
          TYPES.ServiceCheckupQuery['serviceCheckup']['data']['serialized_result']['data']
        >['hits'][0]['doc']['fields'][0]
      ) => ({
        name: item?.name || '-',
        render: () => item?.value || '-',
      })
    );

    return [
      {
        name: 'Also Known As (A.K.A)',
        render: () =>
          hit?.doc?.aka
            ?.map(
              (
                item: NonNullable<
                  TYPES.ServiceCheckupQuery['serviceCheckup']['data']['serialized_result']['data']
                >['hits'][0]['doc']['aka'][0]
              ) => item?.name
            )
            .join(', ') || '-',
      },
      ...(configItems || []),
    ];
  }, []);

  return {
    hit,
    config,
  };
}
