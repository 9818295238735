import { useRef } from 'react';
import { Controller } from 'react-hook-form';
import { DatePicker } from '@aid-module/ui';
import { FormRenderProps } from '../../../../types';
import { DateViewProps } from './types';

export function Date({ field }: DateViewProps) {
  const renderDate = useRef(
    ({
      field: { value, onChange },
      fieldState: { error },
    }: FormRenderProps<string>) => {
      return (
        <DatePicker
          label={`${field.title}${field.required ? ' *' : ''}`}
          error={error}
          value={value}
          onChange={onChange}
        />
      );
    }
  );
  return <Controller name={field.code} render={renderDate.current} />;
}
