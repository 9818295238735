import { Text } from '../Text';
import { Tag } from '../Count';
import { TmCheckupResultStatusViewProps } from './types';
import { useTmCheckupResultStatusController } from './controller';
import {
  TRANSACTION_CHECKUP_RESULT_DISPLAY_STATUSES,
  TRANSACTION_CHECKUP_RESULT_STATUSES,
} from './constants';

function TmCheckupResultStatus({ status }: TmCheckupResultStatusViewProps) {
  const { background, text } = useTmCheckupResultStatusController(status);

  if (text) {
    return <Tag count={text} padding="S" size={11} background={background} />;
  }

  return <Text text="-" size={12} color="main-deep-4" />;
}

export {
  TmCheckupResultStatus,
  TRANSACTION_CHECKUP_RESULT_STATUSES,
  TRANSACTION_CHECKUP_RESULT_DISPLAY_STATUSES,
};
