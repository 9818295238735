import styled from '@emotion/styled';
import { GridContainerEmotionProps } from './types';
import { getSpace } from '../../utils';

export const GridContainer = styled.div<GridContainerEmotionProps>`
  display: grid;
  ${({ gridTemplateColumns }) =>
    gridTemplateColumns
      ? `
    grid-template-columns: ${gridTemplateColumns};
  `
      : ''}
  ${({ gridTemplateRows }) =>
    gridTemplateRows
      ? `
  grid-template-rows: ${gridTemplateRows};
  `
      : ''}
  ${({ alignItems }) =>
    alignItems
      ? `
  align-items: ${alignItems};
  `
      : ''}
  ${({ justifyContent }) =>
    justifyContent
      ? `
        justify-content: ${justifyContent};
    `
      : ''}
  ${({ gap }) =>
    gap
      ? `
  gap: ${getSpace(gap)};
  `
      : ''}
`;
