import gql from 'graphql-tag';

export const IDENFY_RAW_RESULT_CLIENT_IDENTITY_FRAGMENT = gql`
  fragment IdenfyRawResultClientIdentityDocument on IdenfyRawResultClientIdentityDocument {
    doc_address
    doc_country
    doc_date_of_birth
    doc_date_of_issue
    doc_document_number
    doc_document_type
    doc_expiry_date
    doc_license_categories
    doc_mothers_maiden_name
    doc_name
    doc_nationality
    doc_personal_code
    doc_sex
    doc_surname
    document_type
    manual_additional_step_status
    manual_address
    manual_country
    manual_date_of_birth
    manual_date_of_issue
    manual_document_number
    manual_document_type
    manual_expiry_date
    manual_mothers_maiden_name
    manual_mrz_string
    manual_name
    manual_nationality
    manual_personal_code
    manual_sex
    manual_surname
    manual_utility_address
    mrz_address
    mrz_country
    mrz_date_of_birth
    mrz_date_of_issue
    mrz_document_number
    mrz_document_subtype
    mrz_document_type
    mrz_expiry_date
    mrz_license_categories
    mrz_name
    mrz_nationality
    mrz_optional_data
    mrz_personal_code
    mrz_sex
    mrz_string
    mrz_surname
    org_address
    org_authority
    org_birth_place
    org_name
    org_nationality
    org_surname
    urjanet_utility_data
  }
`;
