import { gql } from '@apollo/client';

export const CreateTransactionScoringManualRule = gql`
  mutation CreateTransactionScoringManualRule(
    $body: BodyCreateTransactionScoringManualRule!
  ) {
    createTransactionScoringManualRule(body: $body)
      @rest(
        type: "CreateTransactionScoringRule"
        path: "/tm/scoring/manual_rules"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "TransactionScoringManualRule") {
        id
        client_id
        created_at
        execution_klass
        params
        rfi_suggested
        scoring_matrix_id
        supporting_docs_required
        transaction_stopped
        updated_at
      }
      success
    }
  }
`;

export const UpdateTransactionScoringManualRule = gql`
  mutation UpdateTransactionScoringManualRule(
    $id: String!
    $body: BodyUpdateTransactionScoringManualRule!
  ) {
    updateTransactionScoringManualRule(id: $id, body: $body)
      @rest(
        type: "UpdateTransactionScoringManualRule"
        path: "/tm/scoring/manual_rules/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "TransactionScoringManualRule") {
        id
        client_id
        created_at
        execution_klass
        params
        rfi_suggested
        scoring_matrix_id
        supporting_docs_required
        transaction_stopped
        updated_at
      }
      success
    }
  }
`;

export const DeleteTransactionScoringManualRule = gql`
  mutation DeleteTransactionScoringManualRule($id: String!) {
    deleteTransactionScoringManualRule(id: $id)
      @rest(
        type: "DeleteTransactionScoringManualRule"
        path: "/tm/scoring/manual_rules/{args.id}"
        method: "DELETE"
      ) {
      success
    }
  }
`;
