import { useCallback } from 'react';
import { showToast } from '@aid-module/ui';
import { TYPES, useCreateCheckupMutation } from '@aid-package/api';
import { useCheckupCreateConfigContext } from '../../../../../../../context';

export function useActionsModel() {
  const config = useCheckupCreateConfigContext();
  const [mutation, { loading }] = useCreateCheckupMutation({
    refetchQueries: config?.refetchQueries,
  });

  const createCheckup = useCallback(
    async (
      body: Omit<
        TYPES.RequestCheckupCreate,
        'assigned_to_id' | 'external_refs_attributes'
      >
    ) => {
      const { data } = await mutation({
        variables: {
          body: {
            ...body,
            external_refs_attributes: config?.external_refs_attributes,
            assigned_to_id: config?.assigned_to_id,
          },
        },
      });

      if (data?.createCheckup?.success) {
        showToast({
          type: 'success',
          message: 'Changes were successfully saved',
        });
        if (config?.callback) {
          return config.callback(data.createCheckup.data);
        }
        return data?.createCheckup;
      }
      showToast({
        type: 'error',
        message: 'Something went wrong',
      });
    },
    [mutation, config]
  );

  return { loading, createCheckup };
}
