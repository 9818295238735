import { Tag } from '../Count';
import { Text } from '../Text';
import { useIdentityVerificationStatusController } from './controller';
import { DataRetentionPlanStatusViewProps } from './types';
import {
  DATA_RETENTION_PLAN_DISPLAY_STATUSES,
  DATA_RETENTION_PLAN_STATUSES,
  DATA_RETENTION_PLAN_STATUSES_OPTIONS,
} from './constants';

function DataRetentionPlanStatus(
  { status }: DataRetentionPlanStatusViewProps) {
  const { background, text } = useIdentityVerificationStatusController(status);

  if (text) {
    return <Tag count={text} padding="S" size={11} background={background} />;
  }

  return <Text text="-" size={12} color="main-deep-4" />;
}

export {
  DataRetentionPlanStatus,
  DATA_RETENTION_PLAN_DISPLAY_STATUSES,
  DATA_RETENTION_PLAN_STATUSES,
  DATA_RETENTION_PLAN_STATUSES_OPTIONS,
};
