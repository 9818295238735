import gql from 'graphql-tag';
import { BLACKLIST_REASON_FRAGMENT } from '../../fragment/admin/blacklistReasons';
import { BLACKLISTED_PROFILE_FRAGMENT } from '../../fragment/profiles/blacklistedProfiles';
import { PROFILE_FRAGMENT } from '../../fragment/profiles/profiles';

export const AddToBlacklist = gql`
  ${BLACKLISTED_PROFILE_FRAGMENT}
  ${BLACKLIST_REASON_FRAGMENT}
  mutation AddToBlacklist($body: BodyAddToBlacklist!) {
    addToBlacklist(body: $body)
      @rest(
        type: "AddToBlacklist"
        path: "/core/client/blacklisted_profiles"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "BlacklistedProfile") {
        ...BlacklistedProfileFragment
        blacklist_reason @type(name: "BlacklistReason") {
          ...BlacklistReasonFragment
        }
        blacklisted_by @type(name: "Account") {
          avatar_id
          email
          file_name
          file_size
          full_name
          id
          phone
        }
      }
      success
    }
  }
`;

export const AddManyToBlacklist = gql`
  ${BLACKLISTED_PROFILE_FRAGMENT}
  ${BLACKLIST_REASON_FRAGMENT}
  mutation AddManyToBlacklist($body: BodyAddManyToBlacklist!) {
    addManyToBlacklist(body: $body)
      @rest(
        type: "AddManyToBlacklist"
        path: "/core/client/blacklisted_profiles/create_many"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "BlacklistedProfile") {
        ...BlacklistedProfileFragment
        blacklist_reason @type(name: "BlacklistReason") {
          ...BlacklistReasonFragment
        }
        blacklisted_by @type(name: "Account") {
          avatar_id
          email
          file_name
          file_size
          full_name
          id
          phone
        }
      }
      success
    }
  }
`;

export const AddToBlacklistWithCreateProfile = gql`
  ${BLACKLISTED_PROFILE_FRAGMENT}
  ${BLACKLIST_REASON_FRAGMENT}
  mutation AddToBlacklistWithCreateProfile(
    $body: BodyAddToBlacklistWithCreateProfile!
  ) {
    addToBlacklistWithCreateProfile(body: $body)
      @rest(
        type: "AddToBlacklistWithCreateProfile"
        path: "/core/client/blacklisted_profiles/create_with_profile"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "BlacklistedProfile") {
        ...BlacklistedProfileFragment
        blacklist_reason @type(name: "BlacklistReason") {
          ...BlacklistReasonFragment
        }
        blacklisted_by @type(name: "Account") {
          avatar_id
          email
          file_name
          file_size
          full_name
          id
          phone
        }
      }
      success
    }
  }
`;

export const DeleteBlacklistProfile = gql`
  mutation DeleteBlacklistProfile($id: String!) {
    deleteBlacklistProfile(id: $id)
      @rest(
        type: "BlacklistProfileUpdate"
        path: "/core/client/blacklisted_profiles/{args.id}"
        method: "DELETE"
      ) {
      data {
        profile {
          id
        }
        created_at
        id
      }
      success
    }
  }
`;

export const CreateProfile = gql`
  ${PROFILE_FRAGMENT}
  mutation CreateProfile($body: BodyCreateProfile!) {
    createProfile(body: $body)
      @rest(
        type: "ProfileCreate"
        path: "/core/client/profiles"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "Profile") {
        ...ProfileFragment
      }
      success
    }
  }
`;

export const UpdateProfile = gql`
  ${PROFILE_FRAGMENT}
  mutation UpdateProfile($id: String!, $body: BodyUpdateProfile!) {
    updateProfile(id: $id, body: $body)
      @rest(
        type: "ProfileUpdate"
        path: "/core/client/profiles/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "Profile") {
        ...ProfileFragment
      }
      success
    }
  }
`;

export const BlacklistProfilesReport = gql`
  mutation BlacklistProfilesReport {
    blacklistProfilesReport
      @rest(
        type: "ResponseBlacklistProfilesReport"
        path: "/core/client/blacklisted_profiles/to_csv"
        bodyKey: "body"
        method: "POST"
      )
  }
`;
