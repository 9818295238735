import { ContentLoader, GridContainer, TableLoader } from '@aid-module/ui';

export function Fallback() {
  return (
    <GridContainer gap="20">
      <ContentLoader width="100%" height="26">
        <rect rx="4" ry="4" width="100%" height="26" />
      </ContentLoader>
      <ContentLoader width="100%" height="301.58">
        <rect rx="4" ry="4" width="100%" height="301.58" />
      </ContentLoader>
      <ContentLoader width="100%" height="301.58">
        <rect rx="4" ry="4" width="100%" height="301.58" />
      </ContentLoader>
      <TableLoader />
    </GridContainer>
  );
}
