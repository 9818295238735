import styled from '@emotion/styled';
import { getColor, getSpace } from '../../../../utils';
import { FlexContainer, GridContainer } from '../../../../containers';
import { EmotionContainerViewProps } from './types';

export const Container = styled(GridContainer)<EmotionContainerViewProps>`
  border: 1px solid ${({ color }) => getColor(color)};
  border-radius: 8px;
  overflow: hidden;
  background: ${getColor('main-deep-1')};
`;

export const IconContainer = styled(FlexContainer)<EmotionContainerViewProps>`
  background: ${({ color }) => getColor(color)};
  height: 100%;
`;

export const ContentContainer = styled(GridContainer)`
  padding: ${getSpace('20')} ${getSpace('60')} ${getSpace('20')}
    ${getSpace('20')};
`;

export const DescriptionContainer = styled(GridContainer)`
  padding: ${getSpace('20')};
`;
