import gql from 'graphql-tag';

export const Invites = gql`
  query Invites($status: [String!], $per: Int, $page: Int, $q: String) {
    invites(status: $status, page: $page, per: $per, q: $q)
      @rest(type: "ResponseInvites", path: "/core/client/invites?{args}") {
      data @type(name: "Invite") {
        id
        first_name
        last_name
        client_id
        created_at
        email
        message
        expires_at
        status
        updated_at
        roles @type(name: "Role") {
          id
          name
        }
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const Invite = gql`
  query Invite($id: String!) {
    invite(id: $id)
      @rest(type: "ResponseInvite", path: "/core/client/invites/{args.id}") {
      data @type(name: "Invite") {
        id
        first_name
        last_name
        client_id
        created_at
        email
        message
        expires_at
        status
        updated_at
        roles @type(name: "Role") {
          id
          name
        }
      }
      success
    }
  }
`;
