import { gql } from '@apollo/client';

export const NotificationRead = gql`
  mutation NotificationRead($id: String!) {
    notificationRead(id: $id, body: ".")
      @rest(
        type: "NotificationRead"
        path: "/nh/notifications/{args.id}/read"
        bodyKey: "body"
        bodySerializer: "empty"
        method: "PATCH"
      ) {
      data @type(name: "Notification") {
        created_at
        id
        is_readed
        obj_id
        obj_meta {
          display_name
        }
        display_name
        obj_type
        payload {
          channel
          data {
            body
            client_id
            notification_id
            obj_id
            obj_meta {
              display_name
            }
            obj_type
            title
          }
        }
        state
        updated_at
      }
      success
    }
  }
`;

export const NotificationReadAll = gql`
  mutation NotificationReadAll {
    notificationReadAll(body: ".")
      @rest(
        type: "NotificationReadAll"
        path: "/nh/notifications/read_all"
        bodyKey: "body"
        bodySerializer: "empty"
        method: "PATCH"
      ) {
      success
    }
  }
`;

export const NotificationPinnedUpdate = gql`
  mutation NotificationPinnedUpdate(
    $id: String!
    $body: BodyNotificationPinnedUpdate!
  ) {
    notificationPinnedUpdate(id: $id, body: $body)
      @rest(
        type: "NotificationPinnedUpdate"
        path: "/nh/notifications/{args.id}/pinned"
        bodyKey: "body"
        method: "PATCH"
      ) {
      success
    }
  }
`;

export const NotificationSettingUpdate = gql`
  mutation NotificationSettingUpdate(
    $id: String!
    $body: NotificationSettingUpdate!
  ) {
    notificationSettingUpdate(id: $id, body: $body)
      @rest(
        type: "NotificationSettingUpdate"
        path: "/nh/account_client_settings/{args.id}"
        bodyKey: "body"
        method: "PATCH"
      ) {
      data @type(name: "NotificationSetting") {
        id
        email_enabled
        case_assignment_changed_enabled_email
        case_assignment_changed_enabled_ws
        case_state_changed_enabled_email
        case_state_changed_enabled_ws
        client_name
        client_id
        checkup_ca_match_status_callback_arrived_enabled_email
        checkup_ca_match_status_callback_arrived_enabled_ws
        idenfy_result_doc_expired_enabled_email
        idenfy_result_doc_expired_enabled_ws
        updated_at
        veriff_result_doc_expired_enabled_email
        veriff_result_doc_expired_enabled_ws
        ws_enabled
        case_reminder_executed_enabled_email
        case_reminder_executed_enabled_ws
        case_required_action_closed_enabled_email
        case_required_action_closed_enabled_ws
        case_required_action_opened_enabled_email
        case_required_action_opened_enabled_ws
        comment_mention_created_enabled_email
        comment_mention_created_enabled_ws
        edd_report_assigned_enabled_ws
        edd_report_assigned_enabled_email
        data_retention_config_updated_enabled_ws
        data_retention_config_updated_enabled_email
        data_retention_plan_updated_enabled_ws
        data_retention_plan_updated_enabled_email
      }
      success
    }
  }
`;
