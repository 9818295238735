import loadable from '@loadable/component';
import { LoadableFunctionalComponent } from '../../../utils';
import { PropsWithChildren } from 'react';
import { PortalSideWidgetHeaderViewProps } from './component/types';
import { Fallback } from './components';

export const PortalWidgetHeader = loadable(() => import('./component'), {
  resolveComponent: (components) => components.HeaderComponent,
  fallback: <Fallback />,
}) as LoadableFunctionalComponent<
  PropsWithChildren<PortalSideWidgetHeaderViewProps>
>;
