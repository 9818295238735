import { ContentLoader, GridContainer } from '@aid-module/ui';

export function TableLoader() {
  return (
    <GridContainer gap="8" style={{ overflow: 'hidden', width: '100%' }}>
      <ContentLoader width="100%" height="50.5">
        <rect x="0" y="0" rx="3" ry="3" width="100%" height="50.5px" />
      </ContentLoader>
      <ContentLoader width="100%" height="44">
        <rect x="0" y="0" rx="3" ry="3" width="100%" height="44" />
      </ContentLoader>
      <ContentLoader width="100%" height="44">
        <rect x="0" y="0" rx="3" ry="3" width="100%" height="44" />
      </ContentLoader>
      <ContentLoader width="100%" height="44">
        <rect x="0" y="0" rx="3" ry="3" width="100%" height="44" />
      </ContentLoader>
    </GridContainer>
  );
}

export function HeaderLoader() {
  return (
    <ContentLoader width="100%" height="38.2">
      <rect rx={8} ry={8} width="100%" height="38.2" />
    </ContentLoader>
  );
}

export function RegisterFallback() {
  return (
    <GridContainer gap="20">
      <HeaderLoader />
      <TableLoader />
    </GridContainer>
  );
}
