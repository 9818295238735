import gql from 'graphql-tag';
import { VERIFICATION_FLOW_FRAGMENT } from '../../../fragment/verification';

export const VerificationFlows = gql`
  ${VERIFICATION_FLOW_FRAGMENT}
  query VerificationFlows(
    $sort_field: String
    $sort_order: String
    $page: Int
    $q: String
    $enabled: Boolean
  ) {
    verificationFlows(
      sort_field: $sort_field
      sort_order: $sort_order
      page: $page
      per: 20
      q: $q
      enabled: $enabled
    )
      @rest(
        type: "ResponseVerificationFlows"
        path: "/core/client/verification_flows?{args}"
      ) {
      data @type(name: "VerificationFlow") {
        ...VerificationFlowFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const VerificationFlow = gql`
  ${VERIFICATION_FLOW_FRAGMENT}
  query VerificationFlow($id: String!) {
    verificationFlow(id: $id)
      @rest(
        type: "ResponseVerificationFlow"
        path: "/core/client/verification_flows/{args.id}"
      ) {
      data @type(name: "VerificationFlow") {
        ...VerificationFlowFragment
      }
      success
    }
  }
`;
