import styled from '@emotion/styled';
import { GridContainer } from '../../../../../../containers';
import { getSpace } from '../../../../../../utils';

export const Container = styled(GridContainer)`
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const ContentContainer = styled(GridContainer)`
  margin-right: ${getSpace('6')};
`;
