import { GridContainer } from '../../../../../../../../containers';
import { PrimaryButton, SecondaryButton } from '../../../../../../../Button';
import { DefaultBodyViewProps } from '../../types';
import { Container } from '../Container';

export function Scoring({
  title,
  body,
  onClickConfirm,
  onClickCancel,
  item,
  confirmActionTitle,
}: DefaultBodyViewProps) {
  return (
    <Container gap="32" title={title} body={body}>
      <GridContainer gap="20" gridTemplateColumns="repeat(2, 1fr)">
        <SecondaryButton
          text={
            item?.value
              ? `Keep the current value (${item.value})`
              : 'Keep the current value'
          }
          onClick={onClickCancel}
        />
        <PrimaryButton
          text={confirmActionTitle || 'Recalculate automatically'}
          onClick={onClickConfirm}
        />
      </GridContainer>
    </Container>
  );
}
