import { useReadAllController } from './controller';
import { ActionContainer, Label } from './styles';

export function ReadAll() {
  const { onClick, loading } = useReadAllController();
  return (
    <ActionContainer disabled={loading} onClick={onClick}>
      <Label text="Mark all as read" color="main-deep-4" size={12} weight="Medium" />
    </ActionContainer>
  );
}
