import gql from 'graphql-tag';

export const REGION_FRAGMENT = gql`
  fragment RegionFragment on Region {
    aws_region
    code
    enabled
    id
    name
  }
`;
