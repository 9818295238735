import { GridContainer, Icon, Text } from '@aid-module/ui';
import { NewClientModal } from './components';
import { NewClientCard, IconContainer } from './styles';

export function NewClient() {
  return (
    <NewClientCard
      padding="S"
      border="main-deep-2"
    >
      <GridContainer
        gridTemplateColumns="1fr max-content"
        alignItems="center"
        justifyContent="space-between"
        gap="12"
      >
        <GridContainer
          gridTemplateColumns="40px 400px"
          alignItems="center"
          gap="12"
        >
          <IconContainer justifyContent="center" alignItems="center">
            <Icon glyph="briefcase" size={20} color="main-deep-4" />
          </IconContainer>
          <Text
            text="If you are registering a company to use A.ID for the first time, you need to create a new business client."
            size={14}
            color="main-deep-5"
            weight="Medium"
          />
        </GridContainer>
        <NewClientModal />
      </GridContainer>
    </NewClientCard>
  );
}
