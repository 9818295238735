import { Header, Body } from './components';
import { CheckupCreateConfigContext, CheckupKindProvider } from './context';
import { CheckUpsCreateComponentViewProps } from './types';
import { Container } from './styles';

export function CheckupCreateWidget({
  name,
  config,
  position,
}: CheckUpsCreateComponentViewProps) {
  return (
    <CheckupCreateConfigContext.Provider value={config}>
      <CheckupKindProvider>
        <Container gridTemplateRows="max-content minmax(0, 1fr)">
          <Header position={position} />
          <Body name={name} />
        </Container>
      </CheckupKindProvider>
    </CheckupCreateConfigContext.Provider>
  );
}
