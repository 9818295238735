import { ErrorTemplateViewProps } from '../../types';
import { SvgText } from './styles';

export function DesktopSvg({ code }: Pick<ErrorTemplateViewProps, 'code'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
    >
      <g clipPath="url(#clip0_1122_1099)">
        <path
          d="M51.7188 56.25V75.3125C51.7188 76.6248 50.6879 77.6562 49.375 77.6562H30.625C29.3121 77.6562 28.2812 76.6248 28.2812 75.3125V56.25C28.2812 54.9371 29.3121 53.9062 30.625 53.9062H49.375C50.6879 53.9062 51.7188 54.9371 51.7188 56.25Z"
          fill="#DCE3F5"
        />
        <path
          d="M51.7188 56.25V75.3125C51.7188 76.6248 50.6879 77.6562 49.375 77.6562H40V53.9062H49.375C50.6879 53.9062 51.7188 54.9371 51.7188 56.25Z"
          fill="#5A79CB"
        />
        <path
          d="M61.0938 77.6562C61.0938 78.9685 60.0629 80 58.75 80H21.25C19.9371 80 18.9062 78.9685 18.9062 77.6562C18.9062 76.3434 19.9371 75.3125 21.25 75.3125H58.75C60.0629 75.3125 61.0938 76.3434 61.0938 77.6562Z"
          fill="#F6F8FF"
        />
        <path
          d="M61.0938 77.6562C61.0938 78.9685 60.0629 80 58.75 80H40V75.3125H58.75C60.0629 75.3125 61.0938 76.3434 61.0938 77.6562Z"
          fill="#DCE3F5"
        />
        <path
          d="M80 39.8439V58.5939C80 59.9061 78.9691 60.9376 77.6562 60.9376H2.34375C1.03088 60.9376 0 59.9061 0 58.5939V39.8439C0.474243 39.8176 42.6569 37.494 40 37.6405L80 39.8439Z"
          fill="#F6F8FF"
        />
        <path
          d="M80 39.8438V58.5938C80 59.906 78.9691 60.9375 77.6562 60.9375H40V37.6404L80 39.8438Z"
          fill="#DCE3F5"
        />
        <path
          d="M77.6562 0H2.34375C1.03088 0 0 1.03088 0 2.34375V39.8438H80V2.34375C80 1.03088 78.9691 0 77.6562 0Z"
          fill="#1F3B8E"
        />
        <path
          d="M77.6562 0H40V39.8438H80V2.34375C80 1.03088 78.9691 0 77.6562 0Z"
          fill="#15265A"
        />
        <path
          d="M47.0312 49.2188C47.0312 50.531 46.0004 51.5625 44.6875 51.5625H35.3125C33.9996 51.5625 32.9688 50.531 32.9688 49.2188C32.9688 47.9059 33.9996 46.875 35.3125 46.875H44.6875C46.0004 46.875 47.0312 47.9059 47.0312 49.2188Z"
          fill="#DCE3F5"
        />
        <path
          d="M47.0312 49.2188C47.0312 50.531 46.0004 51.5625 44.6875 51.5625H40V46.875H44.6875C46.0004 46.875 47.0312 47.9059 47.0312 49.2188Z"
          fill="#5A79CB"
        />
        <SvgText
          fill="white"
          alignmentBaseline="middle"
          textAnchor="middle"
          transform="translate(42,25)"
        >
          {code}
        </SvgText>
      </g>
    </svg>
  );
}
