import gql from 'graphql-tag';

export const SCORING_AUDIT_LOG_FRAGMENT = gql`
  fragment ScoringAuditLogFragment on ScoringAuditLog {
    author @type(name: "Account") {
      created_at
      email
      first_name
      id
      last_name
      status
    }
    coefficient
    comment
    created_at
    id
    value
  }
`;
