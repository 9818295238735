import { Card, GridContainer, List, ListLoader, Text } from '@aid-module/ui';
import { useAdditionalInformationController } from './controller';

export function AdditionalInformation() {
  const { hit, config } = useAdditionalInformationController();

  return (
    <GridContainer gap="10">
      <Text text="Additional Information" size={16} weight="Bold" />
      <Card background="main-deep-1" padding="XS">
        <List
          data={hit}
          config={config}
          fallback={<ListLoader count={config?.length} />}
        />
      </Card>
    </GridContainer>
  );
}
