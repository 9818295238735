import { Icon, Text } from '@aid-module/ui';
import { Container, DefaultElement, IconContainer } from './styles';
import { LinkBodyViewProps } from './types';

export function LinkBody({ icon, text }: LinkBodyViewProps) {
  return (
    <Container
      gridTemplateColumns="max-content 1fr"
      gap="8"
      alignItems="center"
    >
      <IconContainer alignItems="center" justifyContent="flex-end">
        {icon ? (
          <Icon glyph={icon} size={16} color="main-deep-4" />
        ) : (
          <DefaultElement />
        )}
      </IconContainer>
      <Text text={text} size={12} />
    </Container>
  );
}
