import gql from 'graphql-tag';
import { DEFAULT_CLIENT_FRAGMENT } from '../../DefaultClient';

export const FORM_TEMPLATE_FRAGMENT = gql`
  ${DEFAULT_CLIENT_FRAGMENT}
  fragment FormTemplateFragment on FormTemplate {
    id
    client_id
    code
    enabled
    name
    client @type(name: "Client") {
      ...DefaultClientFragment
    }
  }
`;
