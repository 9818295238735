import { useCallback } from 'react';
import { useConfirmModal } from '../../../../../../../../../../../../../../../../../../../../../../../ConfirmModal';
import { ResolveType } from '../../../../../../../../../../../../../../../../../../../../../../../ConfirmModal/ModalController/types';
import { useNavigationContext } from '../../../../../../../../../../../../../../../../../context';
import { useActionsModel } from '../model';
import { ActionsViewProps } from '../types';

export function useActionController(template: ActionsViewProps['template']) {
  const { navigateTo } = useNavigationContext();
  const {
    deleteTagRegistery,
    loadingDelete,
    updateTabRegistry,
    loadingUpdate,
    loading,
  } = useActionsModel();

  const onConfirmCallback = useCallback(
    (type?: ResolveType) => {
      if (type === 'confirm') {
        deleteTagRegistery(template.id);
      }
    },
    [deleteTagRegistery, template.id]
  );

  const { show: onClickDelete } = useConfirmModal({
    body: `Are you sure you want to delete filter template “${template.name}”?`,
    type: 'error',
    cancelActionTitle: 'Cancel',
    confirmActionTitle: 'Yes, Delete',
    onConfirmCallback,
  });

  const onClickEdit = useCallback(() => {
    navigateTo({
      type: 'filterTemplatesEdit',
      item: template,
    });
  }, [template, navigateTo]);

  const onClickToFavorite = useCallback(() => {
    const body = {
      ...template,
      favorite: false,
    };
    updateTabRegistry(template.id, body);
  }, [updateTabRegistry, template]);

  return {
    onClickEdit,
    onClickDelete,
    loadingDelete,
    onClickToFavorite,
    loadingUpdate,
    loading,
  };
}
