import styled from '@emotion/styled';
import { GridContainer } from '../../../../../containers';
import { NavLink } from 'react-router-dom';
import { getColor, getSpace } from '../../../../../utils';
import {
  EmotionFiltersContainerViewProps,
  EmotionFiltersHeaderContainerViewProps,
} from './types';

export const Container = styled(GridContainer)`
  min-width: 100%;
`;

export const FiltersContainer = styled(
  GridContainer
)<EmotionFiltersContainerViewProps>`
  grid-direction-rows: max-content;

  ${({ showFilters }) =>
    showFilters &&
    `
    grid-direction-rows: max-content max-content;
  `}

  padding-top: ${getSpace('6')};
`;

export const HeaderContainer = styled(GridContainer)`
  overflow: hidden;
  transition: all 500ms ease;
  padding-bottom: ${getSpace('12')};
  max-height: max-content;
`;

export const FiltersHeaderContainer = styled(
  GridContainer
)<EmotionFiltersHeaderContainerViewProps>`
  ${({ subHeader, ifExistFilters }) => `
    grid-template-columns: minmax(0, 1fr) ${subHeader ? 'max-content' : ''} ${
    ifExistFilters ? 'max-content' : ''
  };
  `}
`;

export const ButtonContainer = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid ${getColor('gray-deep-2')};
  text-decoration: none;

  & :hover {
    opacity: 0.5;
  }
`;
