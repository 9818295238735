import gql from 'graphql-tag';

export const CdsCableOnboardingFlows = gql`
  query CdsCableOnboardingFlows(
    $page: Int
    $per: Int
    $user_id: String
    $company_id: String
  ) {
    cdsCableOnboardingFlows(
      page: $page
      per: $per
      user_id: $user_id
      company_id: $company_id
    )
      @rest(
        type: "ResponseCdsCableOnboardingFlows"
        path: "/cds/cable/onboarding_flows?{args}"
      ) {
      data @type(name: "CdsCableOnboardingFlow") {
        id
        case_id
        client_id
        company_id
        created_at
        customer_verification_id
        flow_name
        outcome
        updated_at
        user_id
      }
      meta {
        count
        page
        items
        pages
      }
      success
    }
  }
`;
