import gql from 'graphql-tag';

export const VERIFICATION_FLOW_STEP_FRAGMENT = gql`
  fragment VerificationFlowStepFragment on VerificationFlowStep {
    auto_create_case
    created_at
    form_configuration_id
    form_configuration_reference_key
    form_name
    id
    kind
    link_idenfy_results_to_this_form
    link_identity_results_to_this_form
    name
    optional
    options
    position
    updated_at
    idenfy_locale
    veriff_locale
    link_veriff_results_to_this_form
    create_ca_checkup_after_idv
    create_fincen_checkup_after_idv
  }
`;
