import { useCallback, useRef, useState } from 'react';
import { DragStart, DragUpdate, DropResult } from 'react-beautiful-dnd';
import { DroppableViewProps, TableColumnWithKey } from '../types';
import { PlaceholderProps } from './types';
import { useColumnsContext } from '../../../../../../../../../../context';
import { useSideWidgetProviderContext } from '../../../../../../../../../../../SideWidget';

export function useDroppableController(
  template: DroppableViewProps['columns']
) {
  const { columnsRenderKeys, onChangeColumns } = useColumnsContext();
  const { close } = useSideWidgetProviderContext();
  const selectedItems = useRef<Record<string, boolean>>({});
  const [renderTemplates, setTemplates] = useState<TableColumnWithKey[]>(() => {
    if (columnsRenderKeys && columnsRenderKeys.length) {
      const currentObjectColumns = { ...template };
      let array = [];
      for (let i = 0, count = columnsRenderKeys.length; i < count; i++) {
        const object = currentObjectColumns[columnsRenderKeys[i]];
        if (object && !object.fixPosition) {
          array.push({ ...object, isSelected: true });
          selectedItems.current = {
            ...selectedItems.current,
            [object.key]: true,
          };
          delete currentObjectColumns[columnsRenderKeys[i]];
        }
      }
      return [
        ...array,
        ...Object.values(currentObjectColumns).reduce<TableColumnWithKey[]>(
          (acc, item) => {
            if (!item.fixPosition) {
              return [...acc, { ...item, isSelected: false }];
            }
            return acc;
          },
          []
        ),
      ];
    } else {
      return Object.values(template).reduce<TableColumnWithKey[]>(
        (acc, item) => {
          if (!item.fixPosition) {
            selectedItems.current = {
              ...selectedItems.current,
              [item.key]: true,
            };
            return [...acc, { ...item, isSelected: true }];
          }
          return acc;
        },
        []
      );
    }
  });

  const [placeholderProps, setPlaceholderProps] = useState<PlaceholderProps>();
  const getDraggedDomRef = useRef((draggableId: string) => {
    const domQuery = `[data-rbd-drag-handle-draggable-id='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    return draggedDOM;
  });

  const getClientY = useRef(
    (index: number, parent: Element, array: Element[]) => {
      const childrenHeight = array.slice(0, index).reduce((total, curr) => {
        const element = curr as HTMLElement;
        if (element) {
          const style =
            (element as any).currentStyle || window.getComputedStyle(element);
          const marginBottom = parseFloat(style.marginBottom);
          return (
            total +
            element.clientHeight +
            (style.display !== 'none' ? marginBottom : 0)
          );
        }
        return total;
      }, 0);

      return (
        parseFloat(window.getComputedStyle(parent).paddingTop) + childrenHeight
      );
    }
  );

  const handleDragStart = useCallback((event: DragStart) => {
    const draggedDOM = getDraggedDomRef.current(event.draggableId)
      ?.parentNode as Element;
    const element = document.getElementById('dropable-container');
    if (element && draggedDOM) {
      const { clientHeight, clientWidth } = draggedDOM;
      const parent = element.childNodes[0] as Element;
      if (parent) {
        const sourceIndex = event.source.index;
        var clientY = getClientY.current(
          sourceIndex,
          parent,
          Array.from(parent.children)
        );
        setPlaceholderProps({
          clientHeight,
          clientWidth,
          clientY,
          clientX: parseFloat(window.getComputedStyle(parent).paddingLeft),
        });
      }
    }
  }, []);

  const handleDragUpdate = useCallback((event: DragUpdate) => {
    if (!event.destination) {
      return;
    }

    const draggedDOM = getDraggedDomRef.current(event.draggableId)
      ?.parentNode as Element;
    const element = document.getElementById('dropable-container');
    if (draggedDOM && element) {
      const { clientHeight, clientWidth } = draggedDOM;
      const parent = element.childNodes[0] as Element;

      if (parent) {
        const destinationIndex = event.destination.index;
        const sourceIndex = event.source.index;

        const childrenArray = Array.from(parent.children);
        const movedItem = childrenArray[sourceIndex];
        childrenArray.splice(sourceIndex, 1);
        const updatedArray = [
          ...childrenArray.slice(0, destinationIndex),
          movedItem,
          ...childrenArray.slice(destinationIndex + 1),
        ];

        var clientY = getClientY.current(
          destinationIndex,
          parent,
          updatedArray
        );

        setPlaceholderProps({
          clientHeight,
          clientWidth,
          clientY,
          clientX: parseFloat(window.getComputedStyle(parent).paddingLeft),
        });
      }
    }
  }, []);

  const handleDragEnd = useCallback((result: DropResult) => {
    setPlaceholderProps(undefined);
    setTemplates((renderTemplates) => {
      if (!result.destination) {
        return renderTemplates;
      }
      const array = [...renderTemplates];
      const [removed] = array.splice(result.source.index, 1);
      array.splice(result.destination.index, 0, removed);
      return array;
    });
  }, []);

  const onSave = useCallback(() => {
    const arrayValues = Object.values(template);
    const nextArray = [
      ...(arrayValues[0].fixPosition ? [arrayValues[0].key] : []),
      ...renderTemplates.reduce<string[]>((acc, value) => {
        if (value.isSelected) {
          return [...acc, value.key];
        }
        return acc;
      }, []),
    ];
    onChangeColumns(nextArray);
    close();
  }, [onChangeColumns, renderTemplates, close, template]);

  const onClose = useCallback(() => {
    close();
  }, [close]);

  const onUpdateSelected = useCallback((index: number, isSelected: boolean) => {
    setTemplates((prev) => {
      const clone = [...prev];
      const item = clone[index];
      if (!isSelected && Object.keys(selectedItems.current).length === 1) {
        return prev;
      } else if (isSelected) {
        selectedItems.current = {
          ...selectedItems.current,
          [item.key]: isSelected,
        };
      } else {
        delete selectedItems.current[item.key];
      }
      clone[index] = { ...clone[index], isSelected };
      return clone;
    });
  }, []);

  return {
    handleDragEnd,
    handleDragStart,
    handleDragUpdate,
    placeholderProps,
    renderTemplates,
    onSave,
    onUpdateSelected,
    onClose,
  };
}
