import loadable from '@loadable/component';
import { LoadableFunctionalComponent } from '../../utils';
import { BooleanInputLoader } from '../../shared';
import { RadioComponentViewProps } from './component/types';
import { GridContainer } from '../../containers';
export * from './component/types';

export const Radio = loadable(() => import('./component'), {
  resolveComponent: (components) => components.RadioComponent,
  fallback: (
    <GridContainer>
      <BooleanInputLoader />
      <BooleanInputLoader />
      <BooleanInputLoader />
    </GridContainer>
  ),
}) as LoadableFunctionalComponent<RadioComponentViewProps>;
