import { gql } from '@apollo/client';

export const DraftClients = gql`
  query DraftClients {
    draftClients
      @rest(
        type: "ResponseDraftClients"
        path: "/core/customer/client_drafts"
      ) {
      data @type(name: "DraftClient") {
        id
        name
        status
        customer_verification {
          id
          expires_at
          verification_step_passings @type(name: "VerificationStepItem") {
            id
            kind
            form_configuration_id
            position
            status
          }
          verification_flow {
            id
            redirect_url
            reject_url
            verification_steps @type(name: "VerificationStepItem") {
              id
              kind
              form_configuration_id
              position
              status
            }
          }
        }
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const OnboardingUrl = gql`
  query OnboardingUrl($id: String!) {
    onboardingUrl(id: $id)
      @rest(
        type: "ResponseOnboardingUrl"
        path: "/core/customer/client_drafts/{args.id}/cv_url"
      ) {
      data {
        url
      }
      success
    }
  }
`;
