import { Icon, Text } from '@aid-module/ui';
import { Protected } from '@aid-package/context';
import { usePageNavigationItemController } from './controller';
import { Link } from './styles';
import { PageNavigationItemViewProps } from './types';

export function PageNavigationItem({ item }: PageNavigationItemViewProps) {
  const { client_id } = usePageNavigationItemController();

  return (
    <Protected check={item.check} strong={item.strong}>
      <Link to={`/${client_id}${item.path}`}>
        <Icon glyph={item.icon} />
        <Text uppercase weight="Medium" size={12} text={item.name} />
      </Link>
    </Protected>
  );
}
