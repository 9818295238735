import { gql } from '@apollo/client';

export const CreateTransactionActivityType = gql`
  mutation CreateTransactionActivityType(
    $body: BodyCreateTransactionActivityType!
  ) {
    createTransactionActivityType(body: $body)
      @rest(
        type: "UpdateTransactionCase"
        path: "/tm/activity_types"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "TransactionActivityType") {
        id
        name
      }
      success
      errors {
        name
      }
    }
  }
`;
