import {
  ContentLoader,
  CustomTableFallback,
  ListLoader,
  LoadableFunctionalComponent,
} from '@aid-module/ui';
import loadable from '@loadable/component';
import { CardContainer } from './components';

export const CheckUpsKindsCard = loadable(() => import('./component'), {
  resolveComponent: (components) => components.CheckUpsKindsCardComponent,
  fallback: (
    <CardContainer isLoading>
      <ListLoader count={4} />
      <ContentLoader width="100%" height="36.4">
        <rect rx="8" ry="8" width="100%" height="36.4" />
      </ContentLoader>
      <CustomTableFallback count={3} />
    </CardContainer>
  ),
}) as LoadableFunctionalComponent<{}>;
