import { Form } from '../Form';
import { Actions, Name } from './components';

export function EditFilterTemplate() {
  return (
    <Form supportChildren={<Name />}>
      <Actions />
    </Form>
  );
}
