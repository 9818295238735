import { SelectOption, SingleValue } from '@aid-module/ui';
import { TYPES } from '@aid-package/api';
import { useCallback } from 'react';
import { CryptocurrencySymbolViewProps } from '../types';
import { useFormContext } from 'react-hook-form';

export function useCryptocurrencySymbolController(
  onChange: CryptocurrencySymbolViewProps['onChange']
) {
  const { setValue } = useFormContext();

  const reduceData = useCallback(
    (
      item: TYPES.CryptoCurrencyDictionaryQuery['cryptoCurrencyDictionary']['data'][0]
    ) => ({
      label: item?.name || '',
      value: item?.value || '',
      support_multiple_blockchains: item?.support_multiple_blockchains || false,
      blockchainOptions:
        item?.blockchains?.map((b) => ({
          label: b?.name || '',
          value: b?.value || '',
        })) || [],
    }),
    []
  );

  const handlerChange = useCallback(
    (next: SingleValue<SelectOption>) => {
      setValue('blockchain', null);
      onChange(next);
    },
    [setValue, onChange]
  );

  return {
    reduceData,
    handlerChange,
  };
}
