import loadable from '@loadable/component';
import { LoadableFunctionalComponent } from '../../../utils';
import { IconLoader } from '../../../shared/Loaders';
import { IconViewProps } from '../types';
import { Icons } from './component/components/icons/types';
export { Icons } from './component/components/icons/types';

export const Icon = loadable(() => import('./component'), {
  resolveComponent: (components) => components.IconComponent,
  fallback: <IconLoader />,
}) as LoadableFunctionalComponent<IconViewProps<keyof typeof Icons>>;
