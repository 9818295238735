import gql from 'graphql-tag';

export const CreateAISummaryConfig = gql`
  mutation CreateAISummaryConfig($body: BodyCreateAISummaryConfig!) {
    createAISummaryConfig(body: $body)
      @rest(
        type: "CreateAISummaryConfig"
        path: "/core/client/ai/case_summary_configs"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "AISummaryConfig") {
        id
        client_id
        name
        body
        additional_requirements
        response_example
        created_at
        updated_at
      }
      success
    }
  }
`;

export const UpdateAISummaryConfig = gql`
  mutation UpdateAISummaryConfig(
    $id: String!
    $body: BodyUpdateAISummaryConfig!
  ) {
    updateAISummaryConfig(id: $id, body: $body)
      @rest(
        type: "UpdateAISummaryConfig"
        path: "/core/client/ai/case_summary_configs/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "AISummaryConfig") {
        id
        client_id
        name
        body
        additional_requirements
        response_example
        created_at
        updated_at
      }
      success
    }
  }
`;

export const DeleteAISummaryConfig = gql`
  mutation DeleteAISummaryConfig($id: String!) {
    deleteAISummaryConfig(id: $id)
      @rest(
        type: "DeleteAISummaryConfig"
        path: "/core/client/ai/case_summary_configs/{args.id}"
        method: "DELETE"
      ) {
      success
    }
  }
`;

export const MarkAsActualRevision = gql`
  mutation MarkAsActualRevision(
    $id: String!
    $body: BodyMarkAsActualRevision!
  ) {
    markAsActualRevision(id: $id, body: $body)
      @rest(
        type: "MarkAsActualRevision"
        path: "/core/client/ai/case_summary_revisions/{args.id}/mark_as_actual"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "AISummaryRevision") {
        id
        body
        case_summary_id
        client_id
        comment
        created_at
        edited_at
        is_marked_as_actual
        is_marked_as_irrelevant
        request_prompt
        status
        updated_at
      }
      success
    }
  }
`;

export const MarkAsIrrelevantRevision = gql`
  mutation MarkAsIrrelevantRevision(
    $id: String!
    $body: BodyMarkAsIrrelevantRevision!
  ) {
    markAsIrrelevantRevision(id: $id, body: $body)
      @rest(
        type: "MarkAsIrrelevantRevision"
        path: "/core/client/ai/case_summary_revisions/{args.id}/mark_as_irrelevant"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "AISummaryRevision") {
        id
        body
        case_summary_id
        client_id
        comment
        created_at
        edited_at
        is_marked_as_actual
        is_marked_as_irrelevant
        request_prompt
        status
        updated_at
      }
      success
    }
  }
`;

export const CreateAISummaryRevision = gql`
  mutation CreateAISummaryRevision($body: BodyCreateAISummaryRevision!) {
    createAISummaryRevision(body: $body)
      @rest(
        type: "CreateAISummaryRevision"
        path: "/core/client/ai/case_summary_revisions"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "AISummaryRevision") {
        id
        body
        case_summary_id
        client_id
        comment
        created_at
        edited_at
        is_marked_as_actual
        is_marked_as_irrelevant
        request_prompt
        status
        updated_at
      }
      success
    }
  }
`;
