import { useMemo, useRef, useState } from 'react';
import { useClientModel } from '../model';

export function useClientController() {
  const { client } = useClientModel();
  const [isHoverImage, setIsHoverImage] = useState(false);
  const [isHoverMenu, setIsHoverMenu] = useState(false);

  const onEvent = useRef(
    (value: boolean, callback: (bool: boolean) => void) => () => {
      callback(value);
    }
  );

  const onHoverImage = useMemo(
    () => onEvent.current(true, setIsHoverImage),
    []
  );

  const onHoverMenu = useMemo(() => onEvent.current(true, setIsHoverMenu), []);

  const onLeaveImage = useMemo(
    () => onEvent.current(false, setIsHoverImage),
    []
  );

  const onLeaveMenu = useMemo(() => onEvent.current(false, setIsHoverMenu), []);

  const isMenuOpened = useMemo(
    () => isHoverImage || isHoverMenu,
    [isHoverImage, isHoverMenu]
  );

  return {
    client,
    isMenuOpened,
    onHoverImage,
    onHoverMenu,
    onLeaveImage,
    onLeaveMenu,
  };
}
