import {
  DefaultButtonLoader,
  LoadableFunctionalComponent,
} from '@aid-module/ui';
import loadable from '@loadable/component';
import { ExportPdfViewProps } from './component/types';

export const ExportCasePdf = loadable(() => import('./component'), {
  resolveComponent: (components) => components.ExportCasePdfComponent,
  fallback: <DefaultButtonLoader />,
}) as LoadableFunctionalComponent<ExportPdfViewProps>;
