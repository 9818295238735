import gql from 'graphql-tag';
import { AUDIT_FRAGMENT } from '../../fragment/audit';
import { AUDIT_CONFIG_FRAGMENT } from '../../fragment/checkup';

export const CreateAudit = gql`
  ${AUDIT_FRAGMENT}
  ${AUDIT_CONFIG_FRAGMENT}
  mutation CreateAudit($body: BodyCreateAudit!) {
    createAudit(body: $body)
      @rest(
        type: "CreateAudit"
        path: "/checkups/client/audits"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "Audit") {
        ...AuditFragment
        audit_config @type(name: "AuditConfig") {
          ...AuditConfigFragment
        }
      }
      success
    }
  }
`;

export const ChangeAuditStatus = gql`
  ${AUDIT_FRAGMENT}
  ${AUDIT_CONFIG_FRAGMENT}
  mutation ChangeAuditStatus($id: String!, $body: BodyChangeAuditStatus!) {
    changeAuditStatus(id: $id, body: $body)
      @rest(
        type: "ChangeAuditStatus"
        path: "/checkups/client/audits/{args.id}/change_audit_status"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "Audit") {
        ...AuditFragment
        audit_config @type(name: "AuditConfig") {
          ...AuditConfigFragment
        }
      }
      success
    }
  }
`;
