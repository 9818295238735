import gql from 'graphql-tag';

export const CASE_SHARING_FRAGMENT = gql`
  fragment CaseSharingFragment on CaseSharing {
    case_id
    id
    shared_to @type(name: "CaseSharingTo") {
      avatar_id
      email
      file_name
      file_size
      full_name
      id
      phone
    }
    options {
      transactions {
        enabled
        filters {
          bank_entity_id
          bank_entity_name
        }
      }
    }
  }
`;

export const CASE_ROLE_SHARING_FRAGMENT = gql`
  fragment CaseRoleSharingFragment on CaseRoleSharing {
    case_id
    id
    role @type(name: "CaseRole") {
      client_id
      created_at
      id
      name
      permissions_count
      updated_at
    }
    options {
      transactions {
        enabled
        filters {
          bank_entity_id
          bank_entity_name
        }
      }
    }
  }
`;
