import { GridContainer } from '../../../../../../../../../containers';
import { Container } from './styles';
import { ComponentByType, HeaderByType } from './components';

export function NavigationView() {
  return (
    <Container
      border="main-deep-1"
      background="main-deep-1"
      loaderForeground="main-deep-1"
      loaderBackground="main-deep-2"
    >
      <GridContainer
        gridTemplateColumns="1fr"
        gridTemplateRows="max-content"
        gap="18"
      >
        <HeaderByType />
        <ComponentByType />
      </GridContainer>
    </Container>
  );
}
