import gql from 'graphql-tag';

export const CHECKUP_FRAGMENT = gql`
  fragment CheckupFragment on Checkup {
    checkup_category_id
    code
    created_at
    description
    enabled
    id
    name
    price
    requires_only_hidden_fields
    updated_at
  }
`;
