import { gql } from '@apollo/client';

export const AITasks = gql`
  query AITasks(
    $page: Int
    $per: Int
    $ai_task_config_id: String
    $processable_entity_type: String
    $processable_entity_id: String
    $status: String
    $kinds: String!
  ) {
    aiTasks(
      page: $page
      per: $per
      ai_task_config_id: $ai_task_config_id
      processable_entity_type: $processable_entity_type
      processable_entity_id: $processable_entity_id
      status: $status
      kinds: $kinds
    ) @rest(type: "ResponseAITasks", path: "/voodoo/ai_tasks?{args}") {
      data @type(name: "AITask") {
        id
        ai_task_config_id
        body
        structured_body
        created_by @type(name: "Account") {
          id
          first_name
          full_name
          last_name
          avatar_id
          email
          phone
        }
        client_id
        created_at
        current_ai_task_result_id
        kind
        options
        processable_entity_id
        processable_entity_type
        status
        updated_at
        current_ai_task_result @type(name: "AITaskResult") {
          id
          ai_task_id
          client_id
          comment
          created_at
          edited_at
          edited_by @type(name: "Account") {
            id
            email
            first_name
            full_name
            last_name
            region_code
            status
          }
          edited_by_id
          is_marked_as_actual
          is_marked_as_irrelevant
          status
          body
          updated_at
          llm_calls @type(name: "AITaskResultLLMCall") {
            response
            status
          }
          options
        }
        ai_task_results @type(name: "AITaskReuslt") {
          id
          ai_task_id
          client_id
          comment
          created_at
          edited_at
          edited_by @type(name: "Account") {
            id
            email
            first_name
            full_name
            last_name
            region_code
            status
          }
          edited_by_id
          is_marked_as_actual
          is_marked_as_irrelevant
          status
          body
          structured_body
          updated_at
          llm_calls @type(name: "AITaskResultLLMCall") {
            response
            status
          }
          options
        }
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const AITask = gql`
  query AITask($id: String!) {
    aiTask(id: $id)
      @rest(type: "ResponseAITask", path: "/voodoo/ai_tasks/{args.id}") {
      data @type(name: "AITask") {
        id
        ai_task_config_id
        body
        structured_body
        created_by @type(name: "Account") {
          id
          first_name
          full_name
          last_name
          avatar_id
          email
          phone
        }
        client_id
        created_at
        current_ai_task_result_id
        kind
        options
        processable_entity_id
        processable_entity_type
        source_entities @type(name: "AITaskSourceEntity") {
          ai_task_id
          client_id
          created_at
          id
          options
          source_entity_id
          source_entity_type
          external_entity_data
          status
          updated_at
        }
        status
        updated_at
        current_ai_task_result @type(name: "AITaskReuslt") {
          id
          ai_task_id
          client_id
          comment
          created_at
          edited_at
          edited_by @type(name: "Account") {
            id
            email
            first_name
            full_name
            last_name
            region_code
            status
          }
          edited_by_id
          is_marked_as_actual
          is_marked_as_irrelevant
          status
          body
          structured_body
          updated_at
          llm_calls @type(name: "AITaskResultLLMCall") {
            response
            status
          }
          options
        }
        ai_task_results @type(name: "AITaskReuslt") {
          id
          ai_task_id
          client_id
          comment
          created_at
          edited_at
          edited_by @type(name: "Account") {
            id
            email
            first_name
            full_name
            last_name
            region_code
            status
          }
          edited_by_id
          is_marked_as_actual
          is_marked_as_irrelevant
          status
          body
          updated_at
          llm_calls @type(name: "AITaskResultLLMCall") {
            response
            status
          }
          options
        }
      }
      success
    }
  }
`;
