import { getColor } from '@aid-module/ui';
import styled from '@emotion/styled';
import { DefaultElement } from '../Link/components/LinkBody/styles';
import { EmotionContainerViewProps } from './types';

export const Container = styled.div<EmotionContainerViewProps>`
  ${({ isActive }) =>
    isActive &&
    `
    & > a > div  {
      & > span {
        font-weight: 500;
      }

      background: ${getColor('main-deep-2')};
    }

    & ${DefaultElement} {
      display: grid;
    }
  `}
`;
