import { Link as NavLink, ExternalLink } from './styles';
import { LinkViewProps } from './types';
import { LinkBody } from './components';

export function Link({ to, target, external, icon, text }: LinkViewProps) {
  if (external) {
    return (
      <ExternalLink rel="noopener noreferrer" href={to} target={target}>
        <LinkBody icon={icon} text={text} />
      </ExternalLink>
    );
  }

  return (
    <NavLink to={to} target={target}>
      <LinkBody icon={icon} text={text} />
    </NavLink>
  );
}
