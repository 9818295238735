import { Controller } from 'react-hook-form';
import { TextInput } from '../../../../../../../../../../../../../../TextInput';
import { Container } from './styles';

function renderName({
  field: { value, onChange },
  fieldState: { error },
}: any) {
  return (
    <TextInput
      value={value}
      onChange={onChange}
      error={error}
      label="Filter Template Name"
      placeholder="Enter Filter Template Name..."
    />
  );
}

export function Name() {
  return (
    <Container>
      <Controller
        name="filterTemplateNameSupport"
        rules={{
          required: 'Required',
          maxLength: { value: 35, message: 'Must be to 35 characters long' },
        }}
        render={renderName}
      />
    </Container>
  );
}
