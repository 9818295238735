import { FlexContainer, Text } from '@aid-module/ui';
import styled from '@emotion/styled';

export const Container = styled(FlexContainer)`
  cursor: pointer;
`;

export const Label = styled(Text)`
  text-decoration-line: underline;
`;
