import { Notifications, useNotificationReadMutation } from '@aid-package/api';
import { useCallback } from 'react';

export function useLinkModel() {
  const [mutation] = useNotificationReadMutation({
    refetchQueries: [Notifications],
  });

  const read = useCallback(
    (id: string) => {
      mutation({ variables: { id } });
    },
    [mutation]
  );

  return { read };
}
