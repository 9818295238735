import { FlexContainer, getColor } from '@aid-module/ui';
import styled from '@emotion/styled/macro';
import { EmotionContainerViewProps } from './types';

export const HintContainer = styled(FlexContainer)`
  position: absolute;
  left: -80px;
  display: none;
`;

export const Container = styled(FlexContainer)<EmotionContainerViewProps>`
  position: relative;
  cursor: pointer;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  border: 1px solid transparent;
  &:hover {
    border: 1px solid ${getColor('gray-deep-3')};
    & > ${HintContainer} {
      display: flex;
    }
  }

  ${({ disabled }) =>
    disabled &&
    `
    cursor: default;
    opacity: 0.6;
  `}
`;

export const Icon = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${getColor('main-deep-4')};
`;
