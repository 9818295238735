import gql from 'graphql-tag';

export const CdsCableRiskAssessments = gql`
  query CdsCableRiskAssessments(
    $page: Int
    $per: Int
    $user_id: String
    $company_id: String
  ) {
    cdsCableRiskAssessments(
      page: $page
      per: $per
      user_id: $user_id
      company_id: $company_id
    )
      @rest(
        type: "ResponseCdsCableRiskAssessments"
        path: "/cds/cable/risk_assessments?{args}"
      ) {
      data @type(name: "CdsCableRiskAssessment") {
        id
        assessment_type
        case_id
        client_id
        company_id
        created_at
        risk_band
        risk_rating
        updated_at
        user_id
      }
      meta {
        count
        page
        items
        pages
      }
      success
    }
  }
`;
