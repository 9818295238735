import { Card } from './styles';
import { PropsWithChildren } from 'react';

export function ActionContainer({ children }: PropsWithChildren<{}>) {
  return (
    <Card
      background="main-deep-1"
      loaderForeground="main-deep-1"
      loaderBackground="main-deep-2"
    >
      {children}
    </Card>
  );
}
