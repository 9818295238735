import { gql } from '@apollo/client';
import {
  TRANSACTION_CASE_FRAGMENT,
  TRANSACTION_CHECKUP_RESULT_FRAGMENT,
  TRANSACTION_EVENT_FRAGMENT,
} from '../../../fragment/tm';
import { TRANSACTION_ENTITY_FRAGMENT } from '../../../fragment/tm/TransactionEntity';
import { TRANSACTION_INSTRUMENT_FRAGMENT } from '../../../fragment/tm/TransactionInstrument';

export const CloseManyAlerts = gql`
  ${TRANSACTION_EVENT_FRAGMENT}
  ${TRANSACTION_CHECKUP_RESULT_FRAGMENT}
  mutation CloseManyAlerts($body: BodyCloseManyAlerts!) {
    closeManyAlerts(body: $body)
      @rest(
        type: "CloseManyAlerts"
        path: "/tm/alerts/close_many"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "TransactionEvent") {
        ...TransactionEventFragment
        checkup_result @type(name: "TransactionCheckupResult") {
          ...TransactionCheckupResultFragment
        }
        rulesets @type(name: "RuleSet") {
          can_sync
          client_id
          created_at
          deleted
          enabled
          id
          name
          rules_expression
        }
      }
      success
    }
  }
`;

export const ChangeAlertState = gql`
  mutation ChangeAlertState($id: String!, $body: BodyChangeAlertState!) {
    changeAlertState(id: $id, body: $body)
      @rest(
        type: "ChangeAlertState"
        path: "/tm/alerts/{args.id}/change_state"
        bodyKey: "body"
        method: "PATCH"
      ) {
      data @type(name: "TransactionAlert") {
        id
        state
      }
      success
    }
  }
`;

export const UpdateAlert = gql`
  mutation UpdateAlert($id: String!, $body: BodyUpdateAlert!) {
    updateAlert(id: $id, body: $body)
      @rest(
        type: "UpdateAlert"
        path: "/tm/alerts/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "TransactionAlert") {
        attachments @type(name: "Attachment") {
          account @type(name: "Account") {
            accounts_clients @type(name: "AccountClient") {
              account_id
              client_id
              id
            }
            created_at
            email
            first_name
            id
            last_name
            phone
            status
          }
          client_id
          created_at
          file_name
          file_size
          id
          kind
          subject_id
          subject_type
          updated_at
          uploaded_document_id
        }
        id
        state
        deadline_at
        tags @type(name: "TransactionAlertTag") {
          id
          name
          description
          created_at
          updated_at
        }
      }
      success
    }
  }
`;

export const AssignAlert = gql`
  mutation AssignAlert($id: String!, $body: BodyAssignAlert!) {
    assignAlert(id: $id, body: $body)
      @rest(
        type: "AssignAlert"
        path: "/tm/alerts/{args.id}/assign_account"
        bodyKey: "body"
        method: "PATCH"
      ) {
      data @type(name: "TransactionAlert") {
        id
        assigned_to @type(name: "Account") {
          id
          email
          first_name
          last_name
        }
      }
      success
    }
  }
`;

export const LinkProfileAlert = gql`
  mutation LinkProfileAlert($id: String!, $body: BodyLinkProfileAlert!) {
    linkProfileAlert(id: $id, body: $body)
      @rest(
        type: "LinkProfileAlert"
        path: "/tm/alerts/{args.id}/link_profile"
        bodyKey: "body"
        method: "PATCH"
      ) {
      data @type(name: "TransactionAlert") {
        id
        profiles @type(name: "Profile") {
          display_name
          id
        }
      }
      success
    }
  }
`;

export const UnlinkProfileAlert = gql`
  mutation UnlinkProfileAlert($id: String!, $body: BodyLinkProfileAlert!) {
    unlinkProfileAlert(id: $id, body: $body)
      @rest(
        type: "LinkProfileAlert"
        path: "/tm/alerts/{args.id}/unlink_profile"
        bodyKey: "body"
        method: "PATCH"
      ) {
      data @type(name: "TransactionAlert") {
        id
        profiles @type(name: "Profile") {
          display_name
          id
        }
      }
      success
    }
  }
`;

export const ChangeDispositionAlert = gql`
  mutation ChangeDispositionAlert(
    $id: String!
    $body: BodyChangeDispositionAlert!
  ) {
    changeDispositionAlert(id: $id, body: $body)
      @rest(
        type: "ChangeDispositionAlert"
        path: "/tm/alerts/{args.id}/change_disposition"
        bodyKey: "body"
        method: "PATCH"
      ) {
      data @type(name: "TransactionAlert") {
        id
        disposition @type(name: "TransactionDisposition") {
          id
          name
        }
        disposition_message
        disposition_updated_at
      }
      success
    }
  }
`;

export const LinkToCaseAlert = gql`
  ${TRANSACTION_CASE_FRAGMENT}
  mutation LinkToCaseAlert($id: String!, $body: BodyLinkToCaseAlert!) {
    linkToCaseAlert(id: $id, body: $body)
      @rest(
        type: "LinkToCaseAlert"
        path: "/tm/alerts/{args.id}/link_to_case"
        bodyKey: "body"
        method: "PATCH"
      ) {
      data @type(name: "TransactionAlert") {
        id
        cases @type(name: "TransactionCase") {
          ...TransactionCaseFragment
        }
      }
      success
    }
  }
`;

export const CreateTransactionCase = gql`
  ${TRANSACTION_CASE_FRAGMENT}
  ${TRANSACTION_EVENT_FRAGMENT}
  ${TRANSACTION_ENTITY_FRAGMENT}
  ${TRANSACTION_INSTRUMENT_FRAGMENT}
  ${TRANSACTION_CHECKUP_RESULT_FRAGMENT}
  mutation CreateTransactionCase($body: BodyCreateTransactionCase!) {
    createTransactionCase(body: $body)
      @rest(
        type: "CreateTransactionCase"
        path: "/tm/cases"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "TransactionCase") {
        ...TransactionCaseFragment
        alerts @type(name: "TransactionAlert") {
          created_at
          data {
            title
            description
          }
          deadline_at
          event_support_keys
          id
          kind
          state
          unit21_alert_external_id
          unit21_external_id
          unit21_url
        }
        attachments @type(name: "Attachment") {
          account @type(name: "Account") {
            created_at
            email
            first_name
            id
            last_name
            phone
            status
          }
          client_id
          created_at
          file_name
          file_size
          id
          kind
          subject_id
          subject_type
          updated_at
          uploaded_document_id
        }
        events @type(name: "TransactionEvent") {
          ...TransactionEventFragment
          checkup_result @type(name: "TransactionCheckupResult") {
            ...TransactionCheckupResultFragment
          }
          rulesets @type(name: "RuleSet") {
            can_sync
            client_id
            created_at
            deleted
            enabled
            id
            name
            rules_expression
          }
        }
        entities @type(name: "TransactionEntity") {
          ...TransactionEntityFragment
        }
        instruments @type(name: "TransactionInstrument") {
          ...TransactionInstrumentFragment
        }
      }
      success
    }
  }
`;

export const UpdateTransactionCase = gql`
  ${TRANSACTION_CASE_FRAGMENT}
  ${TRANSACTION_EVENT_FRAGMENT}
  ${TRANSACTION_ENTITY_FRAGMENT}
  ${TRANSACTION_INSTRUMENT_FRAGMENT}
  ${TRANSACTION_CHECKUP_RESULT_FRAGMENT}
  mutation UpdateTransactionCase(
    $id: String!
    $body: BodyUpdateTransactionCase!
  ) {
    updateTransactionCase(id: $id, body: $body)
      @rest(
        type: "UpdateTransactionCase"
        path: "/tm/cases/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "TransactionCase") {
        ...TransactionCaseFragment
        alerts @type(name: "TransactionAlert") {
          created_at
          data {
            title
            description
          }
          deadline_at
          event_support_keys
          id
          kind
          state
          unit21_alert_external_id
          unit21_external_id
          unit21_url
        }
        attachments @type(name: "Attachment") {
          account @type(name: "Account") {
            created_at
            email
            first_name
            id
            last_name
            phone
            status
          }
          client_id
          created_at
          file_name
          file_size
          id
          kind
          subject_id
          subject_type
          updated_at
          uploaded_document_id
        }
        events @type(name: "TransactionEvent") {
          ...TransactionEventFragment
          checkup_result @type(name: "TransactionCheckupResult") {
            ...TransactionCheckupResultFragment
          }
          rulesets @type(name: "RuleSet") {
            can_sync
            client_id
            created_at
            deleted
            enabled
            id
            name
            rules_expression
          }
        }
        entities @type(name: "TransactionEntity") {
          ...TransactionEntityFragment
        }
        instruments @type(name: "TransactionInstrument") {
          ...TransactionInstrumentFragment
        }
      }
      success
    }
  }
`;

export const TransactionCaseOpen = gql`
  ${TRANSACTION_CASE_FRAGMENT}
  ${TRANSACTION_EVENT_FRAGMENT}
  ${TRANSACTION_ENTITY_FRAGMENT}
  ${TRANSACTION_INSTRUMENT_FRAGMENT}
  ${TRANSACTION_CHECKUP_RESULT_FRAGMENT}
  mutation TransactionCaseOpen($id: String!, $body: BodyTransactionCaseOpen!) {
    transactionCaseOpen(id: $id, body: $body)
      @rest(
        type: "TransactionCaseOpen"
        path: "/tm/cases/{args.id}/change_state"
        bodyKey: "body"
        method: "PATCH"
      ) {
      data @type(name: "TransactionCase") {
        ...TransactionCaseFragment
        alerts @type(name: "TransactionAlert") {
          created_at
          data {
            title
            description
          }
          deadline_at
          event_support_keys
          id
          kind
          state
          unit21_alert_external_id
          unit21_external_id
          unit21_url
        }
        attachments @type(name: "Attachment") {
          account @type(name: "Account") {
            created_at
            email
            first_name
            id
            last_name
            phone
            status
          }
          client_id
          created_at
          file_name
          file_size
          id
          kind
          subject_id
          subject_type
          updated_at
          uploaded_document_id
        }
        events @type(name: "TransactionEvent") {
          ...TransactionEventFragment
          checkup_result @type(name: "TransactionCheckupResult") {
            ...TransactionCheckupResultFragment
          }
          rulesets @type(name: "RuleSet") {
            can_sync
            client_id
            created_at
            deleted
            enabled
            id
            name
            rules_expression
          }
        }
        entities @type(name: "TransactionEntity") {
          ...TransactionEntityFragment
        }
        instruments @type(name: "TransactionInstrument") {
          ...TransactionInstrumentFragment
        }
      }
      success
    }
  }
`;

export const TransactionCaseClose = gql`
  ${TRANSACTION_CASE_FRAGMENT}
  ${TRANSACTION_EVENT_FRAGMENT}
  ${TRANSACTION_ENTITY_FRAGMENT}
  ${TRANSACTION_INSTRUMENT_FRAGMENT}
  ${TRANSACTION_CHECKUP_RESULT_FRAGMENT}
  mutation TransactionCaseClose(
    $id: String!
    $body: BodyTransactionCaseClose!
  ) {
    transactionCaseClose(id: $id, body: $body)
      @rest(
        type: "TransactionCaseClose"
        path: "/tm/cases/{args.id}/change_state"
        bodyKey: "body"
        method: "PATCH"
      ) {
      data @type(name: "TransactionCase") {
        ...TransactionCaseFragment
        alerts @type(name: "TransactionAlert") {
          created_at
          data {
            title
            description
          }
          deadline_at
          event_support_keys
          id
          kind
          state
          unit21_alert_external_id
          unit21_external_id
          unit21_url
        }
        attachments @type(name: "Attachment") {
          account @type(name: "Account") {
            created_at
            email
            first_name
            id
            last_name
            phone
            status
          }
          client_id
          created_at
          file_name
          file_size
          id
          kind
          subject_id
          subject_type
          updated_at
          uploaded_document_id
        }
        events @type(name: "TransactionEvent") {
          ...TransactionEventFragment
          checkup_result @type(name: "TransactionCheckupResult") {
            ...TransactionCheckupResultFragment
          }
          rulesets @type(name: "RuleSet") {
            can_sync
            client_id
            created_at
            deleted
            enabled
            id
            name
            rules_expression
          }
        }
        entities @type(name: "TransactionEntity") {
          ...TransactionEntityFragment
        }
        instruments @type(name: "TransactionInstrument") {
          ...TransactionInstrumentFragment
        }
      }
      success
    }
  }
`;

export const TransactionCaseChangeState = gql`
  ${TRANSACTION_CASE_FRAGMENT}
  ${TRANSACTION_EVENT_FRAGMENT}
  ${TRANSACTION_ENTITY_FRAGMENT}
  ${TRANSACTION_INSTRUMENT_FRAGMENT}
  ${TRANSACTION_CHECKUP_RESULT_FRAGMENT}
  mutation TransactionCaseChangeState(
    $id: String!
    $body: BodyTransactionCaseChangeState!
  ) {
    transactionCaseChangeState(id: $id, body: $body)
      @rest(
        type: "TransactionCaseChangeState"
        path: "/tm/cases/{args.id}/change_state"
        bodyKey: "body"
        method: "PATCH"
      ) {
      data @type(name: "TransactionCase") {
        ...TransactionCaseFragment
        alerts @type(name: "TransactionAlert") {
          created_at
          data {
            title
            description
          }
          deadline_at
          event_support_keys
          id
          kind
          state
          unit21_alert_external_id
          unit21_external_id
          unit21_url
        }
        attachments @type(name: "Attachment") {
          account @type(name: "Account") {
            created_at
            email
            first_name
            id
            last_name
            phone
            status
          }
          client_id
          created_at
          file_name
          file_size
          id
          kind
          subject_id
          subject_type
          updated_at
          uploaded_document_id
        }
        events @type(name: "TransactionEvent") {
          ...TransactionEventFragment
          checkup_result @type(name: "TransactionCheckupResult") {
            ...TransactionCheckupResultFragment
          }
          rulesets @type(name: "RuleSet") {
            can_sync
            client_id
            created_at
            deleted
            enabled
            id
            name
            rules_expression
          }
        }
        entities @type(name: "TransactionEntity") {
          ...TransactionEntityFragment
        }
        instruments @type(name: "TransactionInstrument") {
          ...TransactionInstrumentFragment
        }
      }
      success
    }
  }
`;

export const UpdateTransactionActivityType = gql`
  ${TRANSACTION_CASE_FRAGMENT}
  ${TRANSACTION_EVENT_FRAGMENT}
  ${TRANSACTION_ENTITY_FRAGMENT}
  ${TRANSACTION_INSTRUMENT_FRAGMENT}
  ${TRANSACTION_CHECKUP_RESULT_FRAGMENT}
  mutation UpdateTransactionActivityType(
    $id: String!
    $body: BodyUpdateTransactionActivityType!
  ) {
    updateTransactionActivityType(id: $id, body: $body)
      @rest(
        type: "UpdateTransactionActivityType"
        path: "/tm/cases/{args.id}/change_activity_type"
        bodyKey: "body"
        method: "PATCH"
      ) {
      data @type(name: "TransactionCase") {
        ...TransactionCaseFragment
        alerts @type(name: "TransactionAlert") {
          created_at
          data {
            title
            description
          }
          deadline_at
          event_support_keys
          id
          kind
          state
          unit21_alert_external_id
          unit21_external_id
          unit21_url
        }
        attachments @type(name: "Attachment") {
          account @type(name: "Account") {
            created_at
            email
            first_name
            id
            last_name
            phone
            status
          }
          client_id
          created_at
          file_name
          file_size
          id
          kind
          subject_id
          subject_type
          updated_at
          uploaded_document_id
        }
        events @type(name: "TransactionEvent") {
          ...TransactionEventFragment
          checkup_result @type(name: "TransactionCheckupResult") {
            ...TransactionCheckupResultFragment
          }
          rulesets @type(name: "RuleSet") {
            can_sync
            client_id
            created_at
            deleted
            enabled
            id
            name
            rules_expression
          }
        }
        entities @type(name: "TransactionEntity") {
          ...TransactionEntityFragment
        }
        instruments @type(name: "TransactionInstrument") {
          ...TransactionInstrumentFragment
        }
      }
      success
    }
  }
`;

export const UpdateTransactionCaseOrigin = gql`
  ${TRANSACTION_CASE_FRAGMENT}
  ${TRANSACTION_EVENT_FRAGMENT}
  ${TRANSACTION_ENTITY_FRAGMENT}
  ${TRANSACTION_INSTRUMENT_FRAGMENT}
  ${TRANSACTION_CHECKUP_RESULT_FRAGMENT}
  mutation UpdateTransactionCaseOrigin(
    $id: String!
    $body: BodyUpdateTransactionCaseOrigin!
  ) {
    updateTransactionCaseOrigin(id: $id, body: $body)
      @rest(
        type: "UpdateTransactionCaseOrigin"
        path: "/tm/cases/{args.id}/change_case_origin"
        bodyKey: "body"
        method: "PATCH"
      ) {
      data @type(name: "TransactionCase") {
        ...TransactionCaseFragment
        alerts @type(name: "TransactionAlert") {
          created_at
          data {
            title
            description
          }
          deadline_at
          event_support_keys
          id
          kind
          state
          unit21_alert_external_id
          unit21_external_id
          unit21_url
        }
        attachments @type(name: "Attachment") {
          account @type(name: "Account") {
            created_at
            email
            first_name
            id
            last_name
            phone
            status
          }
          client_id
          created_at
          file_name
          file_size
          id
          kind
          subject_id
          subject_type
          updated_at
          uploaded_document_id
        }
        events @type(name: "TransactionEvent") {
          ...TransactionEventFragment
          checkup_result @type(name: "TransactionCheckupResult") {
            ...TransactionCheckupResultFragment
          }
          rulesets @type(name: "RuleSet") {
            can_sync
            client_id
            created_at
            deleted
            enabled
            id
            name
            rules_expression
          }
        }
        entities @type(name: "TransactionEntity") {
          ...TransactionEntityFragment
        }
        instruments @type(name: "TransactionInstrument") {
          ...TransactionInstrumentFragment
        }
      }
      success
    }
  }
`;

export const UpdateTransactionResolution = gql`
  ${TRANSACTION_CASE_FRAGMENT}
  ${TRANSACTION_EVENT_FRAGMENT}
  ${TRANSACTION_ENTITY_FRAGMENT}
  ${TRANSACTION_INSTRUMENT_FRAGMENT}
  ${TRANSACTION_CHECKUP_RESULT_FRAGMENT}
  mutation UpdateTransactionResolution(
    $id: String!
    $body: BodyUpdateTransactionResolution!
  ) {
    updateTransactionResolution(id: $id, body: $body)
      @rest(
        type: "UpdateTransactionResolution"
        path: "/tm/cases/{args.id}/change_resolution"
        bodyKey: "body"
        method: "PATCH"
      ) {
      data @type(name: "TransactionCase") {
        ...TransactionCaseFragment
        alerts @type(name: "TransactionAlert") {
          created_at
          data {
            title
            description
          }
          deadline_at
          event_support_keys
          id
          kind
          state
          unit21_alert_external_id
          unit21_external_id
          unit21_url
        }
        attachments @type(name: "Attachment") {
          account @type(name: "Account") {
            created_at
            email
            first_name
            id
            last_name
            phone
            status
          }
          client_id
          created_at
          file_name
          file_size
          id
          kind
          subject_id
          subject_type
          updated_at
          uploaded_document_id
        }
        events @type(name: "TransactionEvent") {
          ...TransactionEventFragment
          checkup_result @type(name: "TransactionCheckupResult") {
            ...TransactionCheckupResultFragment
          }
          rulesets @type(name: "RuleSet") {
            can_sync
            client_id
            created_at
            deleted
            enabled
            id
            name
            rules_expression
          }
        }
        entities @type(name: "TransactionEntity") {
          ...TransactionEntityFragment
        }
        instruments @type(name: "TransactionInstrument") {
          ...TransactionInstrumentFragment
        }
      }
      success
    }
  }
`;

export const LinkExternalRefTransactionCase = gql`
  mutation LinkExternalRefTransactionCase(
    $body: BodyLinkExternalRefTransactionCase!
  ) {
    linkExternalRefTransactionCase(body: $body)
      @rest(
        type: "LinkExternalRefTransactionCase"
        path: "/tm/external_refs"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "TransactionCaseExternalRef") {
        id
        external_entity_id
        external_entity_type
        external_system
        subject_id
        subject_type
      }
      success
    }
  }
`;

export const UnlinkExternalRefTransactionCase = gql`
  mutation UnlinkExternalRefTransactionCase($id: String!) {
    unlinkExternalRefTransactionCase(id: $id)
      @rest(
        type: "UnlinkExternalRefTransactionCase"
        path: "/tm/external_refs/{args.id}"
        method: "DELETE"
      ) {
      data @type(name: "TransactionCaseExternalRef") {
        id
        external_entity_id
        external_entity_type
        external_system
        subject_id
        subject_type
      }
      success
    }
  }
`;
