import { useMemo } from 'react';
import { LabelViewProps } from '../types';

export function useLabelController(
  code: LabelViewProps['code']
) {
  const label = useMemo(() => {
    switch (code) {
      case 'comply_advantage_aml_business':
        return 'AML for business';
      case 'comply_advantage_aml_common':
        return 'AML - using Comply Advantage';
      case 'unit_21_entity_id':
        return 'Unit21 Entity Verification';
      case 'elliptic_wallet':
        return 'Elliptic Wallet';
      case 'maxmind_geo_ip':
        return 'Maxmind GeoIP';
      case 'shuftipro_kyb':
        return 'Shuftipro KYB';
      case 'comply_advantage_aml':
        return 'AML for individual';
      case 'fincen_business':
        return 'Fincen 314(a) - business';
      case 'fincen_person':
        return 'Fincen 314(a) - person';
      case 'unit_21_entity_doc':
        return 'Unit21 Entity Doc Verification';
      case 'blacklisted_profiles_person':
        return 'A.ID Black Lists Profiles - person';
      case 'blacklisted_profiles_business':
        return 'A.ID Black Lists Profiles - business';
      case 'unit_21_kyb':
        return 'Unit21 KYB';
      case 'veriff_identity_verification':
        return 'Veriff Identity Verification';
      case 'blacklisted_device_infos':
        return 'A.ID Blacklisted Device Info';
      case 'prohibited_countries':
        return 'A.ID Prohibited Countries';
    }
  }, [code]);

  return { label }
}
