import { useCallback, useState } from 'react';
import { useSearchModel } from '../model';
import { PERMISSIONS, useCheckPermission } from '@aid-package/context';

export function useSearchController() {
  const { onNavigateToSearchHistory } = useSearchModel();
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen]);

  const { canShow: canShowHistory } = useCheckPermission(
    PERMISSIONS.SEE_GLOBAL_SEARCH_HISTORY,
    false
  );

  return {
    isOpen,
    toggleOpen,
    onNavigateToSearchHistory,
    canShowHistory,
  };
}
