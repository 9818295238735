import gql from 'graphql-tag';

export const ClientDictionaries = gql`
  query ClientDictionaries(
    $page: Int
    $per: Int
    $q: String
    $case_state: String
    $active: Boolean
  ) {
    clientDictionaries(
      page: $page
      per: $per
      q: $q
      case_state: $case_state
      active: $active
    )
    @rest(
      type: "ResponseClientDictionaries"
      path: "/core/client/client_dictionaries?{args}"
    ) {
      data @type(name: "ClientDictionaryItem") {
        id
        created_at
        updated_at
        name
        code
        client_dictionary_items @type(name: "ClientDictionaryItemsAttribute") {
          id
          name
          value
          deleted
        }
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const ClientDictionary = gql`
  query ClientDictionary(
    $id: String!
  ) {
    clientDictionary(
      id: $id
    )
    @rest(
      type: "ResponseClientDictionary"
      path: "/core/client/client_dictionaries/{args.id}"
    ) {
      data @type(name: "ClientDictionaryItem") {
        id
        created_at
        updated_at
        name
        code
        client_dictionary_items @type(name: "ClientDictionaryItemsAttribute") {
          id
          name
          value
          deleted
        }
      }
      success
    }
  }
`;

