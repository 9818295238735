import { Text } from '../Text';
import { CopyViewProps } from './types';
import { ClipBoard } from './components';

export function Copy({ label, ...props }: CopyViewProps) {
  if (label) {
    return <ClipBoard {...props} label={label} />;
  }
  return <Text label={label} {...props} />;
}
