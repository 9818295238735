import { gql } from '@apollo/client';

export const TransactionScoringManualRules = gql`
  query TransactionScoringManualRules(
    $per: Int
    $page: Int
    $scoring_matrix_id: String!
  ) {
    transactionScoringManualRules(
      per: $per
      page: $page
      scoring_matrix_id: $scoring_matrix_id
    )
      @rest(
        type: "ResponseTransactionScoringManualRules"
        path: "/tm/scoring/manual_rules?{args}"
      ) {
      data @type(name: "TransactionScoringManualRule") {
        id
        client_id
        created_at
        execution_klass
        params
        rfi_suggested
        scoring_matrix_id
        supporting_docs_required
        transaction_stopped
        updated_at
      }
      meta {
        count
        page
        items
        pages
      }
      success
    }
  }
`;
