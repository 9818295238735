import { THEME_COLORS } from '../../../shared/colors/types';
import { DATA_RETENTION_PLAN_STATUSES } from '../constants';

export function getColorByStatus(status?: string | null): keyof THEME_COLORS {
  switch (status) {
    case DATA_RETENTION_PLAN_STATUSES.COMPLETED:
      return 'success-deep-4';
    case DATA_RETENTION_PLAN_STATUSES.RESTORED:
    case DATA_RETENTION_PLAN_STATUSES.CANCELED:
      return 'error-deep-3';
    case DATA_RETENTION_PLAN_STATUSES.SOFT_DELETED_COMPLETE:
      return 'warning-deep-5';
    default:
      return 'main-deep-4';
  }
}
