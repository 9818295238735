import { useMemo } from 'react';
import { ModalBodyViewProps } from '../../../../../../../../../../../../../../../../../../Modal/Modal/types';
import { Tabs } from '../../../../../../../../../../../../../../../../../../Tabs';
import { Create, Update } from './components';
import { FiltersValuesContext } from './context';
import { useBodyController } from './controller';

export function Body({ onClose }: ModalBodyViewProps) {
  const { formValues, rawFilterValues, canUpdate } = useBodyController();
  const config = useMemo(
    () => [
      { label: 'Create', children: <Create onClose={onClose} /> },
      { label: 'Save as', children: <Update onClose={onClose} /> },
    ],
    [onClose]
  );

  if (canUpdate) {
    return (
      <FiltersValuesContext.Provider
        value={{ filters: formValues, rawFilterValues }}
      >
        <Tabs>{config}</Tabs>
      </FiltersValuesContext.Provider>
    );
  }

  return (
    <FiltersValuesContext.Provider
      value={{ filters: formValues, rawFilterValues }}
    >
      <Create onClose={onClose} />
    </FiltersValuesContext.Provider>
  );
}
