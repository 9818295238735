import { PropsWithChildren } from 'react';
import { FormCard, GridContainer, FormCardViewProps } from '@aid-module/ui';

export function FormContainer({
  isLoading,
  children,
  label,
}: PropsWithChildren<Pick<FormCardViewProps, 'isLoading' | 'label'>>) {
  return (
    <GridContainer gridTemplateColumns="510px" justifyContent="center">
      <FormCard label={label} icon="tags" isLoading={isLoading}>
        {children}
      </FormCard>
    </GridContainer>
  );
}
