import { Text } from '../../../../../../Text';
import { Container } from './styles';
import { FilterValueViewProps } from './types';

export function FilterValue({ label, value }: FilterValueViewProps) {
  return (
    <Container
      gap="4"
      gridTemplateColumns="minmax(0, auto)"
      alignItems="flex-start"
    >
      <Text color="main-deep-3" size={11}>
        {`${label}: `}
        <Text text={value} size={11} />
      </Text>
    </Container>
  );
}
