import styled from '@emotion/styled';
import { getColor, getSpace } from '../../utils';
import { Card as UICard } from '../Card';

export const Container = styled.div`
  display: none;
  position: absolute;
  z-index: 1000;
  max-width: 392px;
  width: max-content;
  height: max-content;
  padding: ${getSpace('4')};
  justify-content: center;

  & *::-webkit-scrollbar {
    height: 4px;
    width: 4px;
    padding: 10px;
  }
  & *::-webkit-scrollbar-thumb {
    background-color: ${getColor('main-deep-2')};
    border-radius: 20px;
    border: 1px solid ${getColor('main-deep-2')};
    scroll-margin: 0px;
    scroll-padding: 0px;
  }
  & *::-webkit-scrollbar-track {
    background: ${getColor('main-deep-1')};
    margin: 2px;
    padding: 2px;
    border-radius: 20px;
  }
`;

export const Card = styled(UICard)<{ customPadding?: string }>`
  padding: ${({ customPadding }) =>
    customPadding ? customPadding : getSpace('12')};
  border-radius: 4px;
`;
