import { gql } from '@apollo/client';
import { CA_SEARCH_FRAGMENT } from '../../../fragment/checkup';
import { CA_ACTUAL_SEARCH_REVISION_FRAGMENT } from '../../../fragment/checkup/Ca/caActualSearchRevisionData';

export const UpdateRiskLevel = gql`
  ${CA_SEARCH_FRAGMENT}
  mutation UpdateRiskLevel(
    $ca_search_id: String!
    $body: BodyUpdateRiskLevel!
  ) {
    updateRiskLevel(ca_search_id: $ca_search_id, body: $body)
      @rest(
        type: "UpdateRiskLevel"
        path: "/checkups/client/ca/searches/{args.ca_search_id}"
        bodyKey: "body"
        method: "PATCH"
      ) {
      data @type(name: "CaSearch") {
        ...CaSearchFragment
      }
      success
    }
  }
`;

export const UpdateMonitoring = gql`
  ${CA_ACTUAL_SEARCH_REVISION_FRAGMENT}
  mutation UpdateMonitoring(
    $ca_search_id: String!
    $body: BodyUpdateMonitoring!
  ) {
    updateMonitoring(ca_search_id: $ca_search_id, body: $body)
      @rest(
        type: "UpdateMonitoring"
        path: "/checkups/client/ca/searches/{args.ca_search_id}/monitoring"
        bodyKey: "body"
        method: "PATCH"
      ) {
      data @type(name: "ResponseUpdateMonitoring") {
        data @type(name: "CaActualSearchRevisionPayload") {
          ...CaActualSearchRevisionData
        }
      }
      success
    }
  }
`;

export const UpdateHits = gql`
  ${CA_ACTUAL_SEARCH_REVISION_FRAGMENT}
  mutation UpdateHits($ca_search_id: String!, $body: BodyUpdateHits!) {
    updateHits(ca_search_id: $ca_search_id, body: $body)
      @rest(
        type: "UpdateHits"
        path: "/checkups/client/ca/searches/{args.ca_search_id}/hits"
        bodyKey: "body"
        method: "PATCH"
      ) {
      data @type(name: "ResponseUpdateHits") {
        data @type(name: "CaActualSearchRevisionPayload") {
          ...CaActualSearchRevisionData
        }
      }
      success
    }
  }
`;
