import {
  Notifications,
  useNotificationPinnedUpdateMutation,
} from '@aid-package/api';
import { useCallback } from 'react';

export function usePinnedModel() {
  const [mutation, { loading }] = useNotificationPinnedUpdateMutation({
    refetchQueries: [Notifications],
    awaitRefetchQueries: true,
  });

  const read = useCallback(
    (id: string, pinned: boolean) => {
      mutation({
        variables: {
          id,
          body: {
            pinned: !pinned,
          },
        },
      });
    },
    [mutation]
  );

  return { read, loading };
}
