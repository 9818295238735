import loadable from '@loadable/component';
import { TextAreaLoader } from '../../shared';
import { TextAreaViewProps } from './component/types';
import { LoadableFunctionalComponent } from '../../utils';
import { RefAttributes } from 'react';

export const TextArea = loadable(() => import('./component'), {
  resolveComponent: (components) => components.TextArea,
  fallback: <TextAreaLoader />,
}) as LoadableFunctionalComponent<
  TextAreaViewProps & RefAttributes<HTMLTextAreaElement>
>;
