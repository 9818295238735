import gql from 'graphql-tag';
import { CASE_CONFIG_FRAGMENT } from '../caseConfig/CaseConfig';
import { VERIFICATION_FLOW_STEP_FRAGMENT } from './VerificationFlowStep';

export const VERIFICATION_FLOW_FRAGMENT = gql`
  ${VERIFICATION_FLOW_STEP_FRAGMENT}
  ${CASE_CONFIG_FRAGMENT}
  fragment VerificationFlowFragment on VerificationFlow {
    auto_create_case_for_all_forms
    case_config @type(name: "CaseConfig") {
      ...CaseConfigFragment
    }
    client_id
    create_ca_checkup_for_idenfy_results
    create_fincen_checkup_for_idenfy_results
    created_at
    customer_verifications_count
    id
    link_idenfy_result_to_all_cases
    link_identity_result_to_all_cases
    name
    redirect_url
    reject_url
    updated_at
    options
    enabled
    verification_steps @type(name: "VerificationFlowStep") {
      ...VerificationFlowStepFragment
    }
  }
`;
