import { GridContainer } from '../../containers';
import { Count } from '../Count';
import { Text } from '../Text';
import { Border, LabelContainer } from './styles';
import { ContentSeparatorViewProps } from './types';

export function ContentSeparator({
  count,
  label,
  countSize = 20,
}: ContentSeparatorViewProps) {
  return (
    <GridContainer
      gridTemplateColumns="max-content 1fr"
      gap="14"
      alignItems="center"
    >
      <LabelContainer count={count} gap="14">
        <Text text={label} size={24} weight="VeryBig" uppercase />
        {count ? <Count count={count} size={countSize} /> : null}
      </LabelContainer>
      <Border />
    </GridContainer>
  );
}
