import { WidgetWindow } from './types';

export class PortalWidgetController {
  widgetWindows: Record<string, WidgetWindow> = {};
  widgetWindowsLength = 0;
  currentWindow?: WidgetWindow;
  onUpdate?: () => void;

  animated: boolean = false;

  show(key: string) {
    if (!this.widgetWindows[key]) {
      const widget: WidgetWindow = { key, position: 0, state: 'visible' };
      this.widgetWindows = {
        [widget.key]: widget,
      };
      this.currentWindow = widget;
      this.widgetWindowsLength += 1;
      this.onContainerUpdate();
    }
  }

  push(key: string) {
    if (!this.widgetWindows[key]) {
      if (this.currentWindow) {
        this.currentWindow.state = 'background';
      }
      const widget: WidgetWindow = {
        key,
        position: this.currentWindow ? this.currentWindow.position + 1 : 0,
        state: 'visible',
        prev: this.currentWindow,
      };
      this.widgetWindows = {
        ...this.widgetWindows,
        [widget.key]: widget,
      };
      this.currentWindow = widget;
      this.widgetWindowsLength += 1;
      this.onContainerUpdate();
    }
  }

  pop() {
    if (this.currentWindow) {
      const currentWindow = this.currentWindow;
      this.currentWindow = currentWindow.prev;
      if (this.currentWindow) {
        this.currentWindow.state = 'visible';
      }
      delete this.widgetWindows[currentWindow.key];
      this.widgetWindows = { ...this.widgetWindows };
      this.widgetWindowsLength -= 1;
      this.onContainerUpdate();
    }
  }

  hide() {
    this.widgetWindows = {};
    this.currentWindow = undefined;
    this.widgetWindowsLength = 0;
    this.onContainerUpdate();
  }

  onContainerUpdate() {
    this.onUpdate?.();
  }
}
