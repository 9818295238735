import { dayjs } from '@aid-module/ui';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function useExternalLinkController() {
  const { search } = useLocation();

  const href = useMemo(() => {
    const href = new URLSearchParams(search).get('href');
    if (href) {
      const decodedHref = decodeURIComponent(href);
      if (decodedHref?.search(/^http(s)?:\/\//) === 0) {
        return decodedHref;
      }
      return decodedHref ? `https://${decodedHref}` : undefined;
    }
    return;
  }, [search]);

  const copyrightText = useMemo(
    () => `© ${dayjs().year()} A.ID Europe UAB. All rights reserved`,
    []
  );

  return {
    href,
    copyrightText,
  };
}
