import { useFormQuery } from '@aid-package/api';

export function useStructureModel(id: string) {
  const { data, loading } = useFormQuery({
    variables: {
      id,
    },
    skip: !id,
  });

  return { form: data?.form.data, loading };
}
