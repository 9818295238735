import {
  LoadableFunctionalComponent,
  TableWithoutSubHeader,
} from '@aid-module/ui';
import loadable from '@loadable/component';

export const ProvidersRegister = loadable(() => import('./component'), {
  resolveComponent: (components) => components.ProvidersRegisterComponent,
  fallback: <TableWithoutSubHeader />,
}) as LoadableFunctionalComponent<{}>;
