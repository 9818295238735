import { EditFilterTemplate, Union } from './components';
import { useHeaderByTypeController } from './controller';

export function HeaderByType() {
  const { type } = useHeaderByTypeController();

  switch (type) {
    case 'filters':
    case 'filterTemplates':
      return <Union />;
    case 'filterTemplatesEdit':
      return <EditFilterTemplate />;
  }
}
