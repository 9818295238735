import { LoadableFunctionalComponent } from '@aid-module/ui';
import loadable from '@loadable/component';
import { FormContainer } from '../components';
import { Fallback } from './components';

export const InviteDescription = loadable(() => import('./component'), {
  resolveComponent: (components) => components.Component,
  fallback: (
    <FormContainer isLoading label="User Invite">
      <Fallback />
    </FormContainer>
  ),
}) as LoadableFunctionalComponent<{}>;
