import { gql } from '@apollo/client';

export const TransactionDictionaries = gql`
  query TransactionDictionaries($kind: String!, $rule_code: String) {
    transactionDictionaries(kind: $kind, rule_code: $rule_code)
      @rest(
        type: "ResponseTransactionDictionaries"
        path: "/tm/dictionaries?{args}"
      ) {
      data
      success
    }
  }
`;
