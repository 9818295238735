import { PropsWithChildren } from 'react';
import {
  FormCard,
  GridContainer,
  Text,
  FormCardViewProps,
} from '@aid-module/ui';

export function CardContainer({
  children,
  isLoading,
}: PropsWithChildren<Pick<FormCardViewProps, 'isLoading'>>) {
  return (
    <GridContainer gridTemplateColumns="500px" justifyContent="center">
      <FormCard
        label="Blacklisted Device Info"
        icon="blacklist"
        isLoading={isLoading}
      >
        <GridContainer gap="32">
          <Text
            text="Description"
            size={20}
            weight="VeryBig"
            color="main-deep-4"
            align="center"
            uppercase
          />
          {children}
        </GridContainer>
      </FormCard>
    </GridContainer>
  );
}
