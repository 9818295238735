import { ContentLoader, GridContainer, TabsLoader } from '@aid-module/ui';

export function Fallback() {
  return (
    <GridContainer gap="12">
      <ContentLoader width="100%" height="32.2">
        <rect rx={8} ry={8} width="100%" height="32.2" />
      </ContentLoader>
      <GridContainer gap="20">
        <TabsLoader />
        <ContentLoader width="100%" height="74.2">
          <rect rx={8} ry={8} width="100%" height="74.2" />
        </ContentLoader>
      </GridContainer>
    </GridContainer>
  );
}
