import gql from 'graphql-tag';
import { DEFAULT_CLIENT_FRAGMENT } from '../../DefaultClient';

export const PROFILE_FRAGMENT = gql`
  ${DEFAULT_CLIENT_FRAGMENT}
  fragment ProfileFragment on Profile {
    client @type(name: "Client") {
      ...DefaultClientFragment
    }
    avatar_id
    blacklisted_profile_id
    created_at
    created_by
    display_name
    email
    id
    is_whitelisted
    kind
    phone
    profile_info_items_count
    data_retention @type(name: "DataRetentionInfo") {
      full_delete_date
      period_start_date
      retention_plan_id
      retention_state
      soft_delete_date
    }
    form_submissions @type(name: "Submission") {
      id
    }
    edd_reports @type(name: "EDDReport") {
      assigned_to_id
      case_ids
      client_id
      completed_at
      created_at
      description
      form_configuration_id
      form_submission_id
      id
      main_profile_id
      main_profile @type(name: "Profile") {
        avatar_id
        created_at
        display_name
        email
        id
        is_whitelisted
        kind
        phone
        updated_at
      }
      profile_kind
      status
      updated_at
    }
    updated_at
  }
`;
