import { MouseEvent, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { ModalBodyViewProps } from '../../../../../../../../../../../../../../../../../../../../../../../../../Modal/Modal/types';
import { useFilterTemplatesContext } from '../../../../../../../../../../../../../../../../../../../../../../../../context';
import { useFiltersValuesContext } from '../../../../../../../context';
import { FormProps } from '../../../types';
import { useActionsModel } from '../model';

export function useActionsController(onClose: ModalBodyViewProps['onClose']) {
  const { handleSubmit } = useFormContext<FormProps>();
  const { filters, rawFilterValues } = useFiltersValuesContext();
  const { updateTabRegistry, loading } = useActionsModel();
  const { code } = useFilterTemplatesContext();

  const onSubmit = useCallback(
    async (values: FormProps) => {
      const params = {
        filters,
        meta: rawFilterValues,
        registry_type: code || '',
        name: values.filterTemplate.label,
        position: values.filterTemplate.position,
      };
      const res = await updateTabRegistry(values.filterTemplate.value, params);
      if (res) {
        onClose?.();
      }
    },
    [updateTabRegistry, onClose, filters, code]
  );

  const onClickSubmit = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      handleSubmit(onSubmit)(e);
    },
    [handleSubmit, onSubmit]
  );

  return {
    onClickSubmit,
    loading,
  };
}
