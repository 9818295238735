import { FormCardModal } from '../../../../../../../../../../../../../../../../Modal';
import { Body, Button } from './components';

export function Save() {
  return (
    <FormCardModal
      label="FILTER TEMPLATE"
      icon="clipBoard"
      body={<Body />}
      button={<Button />}
    />
  );
}
