import { gql } from '@apollo/client';

export const CreateDraftClient = gql`
  mutation CreateDraftClient($body: CreateDraftBody!) {
    createDraftClient(body: $body)
    @rest(
      type: "DraftClientResponse"
      path: "/core/customer/client_drafts"
      bodyKey: "body"
      method: "POST"
    ) {
      data @type(name: "DraftClient") {
        id,
        name,
        status
        customer_verification {
          id
          expires_at
          verification_step_passings @type(name: "VerificationStepItem") {
            id
            kind
            form_configuration_id
            position
            status
          }
          verification_flow {
            id
            redirect_url
            reject_url
            verification_steps @type(name: "VerificationStepItem") {
              id
              kind
              form_configuration_id
              position
              status
            }
          }
        }
      }
      success
    }
  }
`;
