import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { CheckupStatus } from '@aid-module/ui';
import { useCheckupContext } from '../../../../../../../../../context';
import { useHitsContext } from '../../../context';
import { HitViewProps } from '../types';

export function useHitController(
  hit: HitViewProps['hit'],
  asLink: HitViewProps['asLink']
) {
  const checkup = useCheckupContext();
  const { touchHitId, checkedHitIds, visibleIds } = useHitsContext();
  const { client_id } = useParams();

  const card = useMemo(() => {
    return {
      name: hit?.doc?.name || '',
      matched: hit?.doc?.types.map((item) => item?.toUpperCase()) || [],
      entity_type: hit?.doc?.entity_type || '',
      relevance:
        hit?.match_types.map((item) =>
          item?.replace(/_/g, ' ').toUpperCase()
        ) || [],
    };
  }, [hit]);

  const matchStatusSetting = useMemo(
    () => CheckupStatus.getSettings(hit.match_status),
    [hit.match_status]
  );

  const href = useMemo(() => {
    if (asLink && checkup) {
      return `/${client_id}/checkup/instant_checkups/${checkup.checkup_kind_code}/form/${checkup.id}/info/${hit.doc?.id}`;
    }

    return null;
  }, [hit.doc, asLink]);

  const onChange = useCallback(
    () => touchHitId(hit?.doc?.id || ''),
    [touchHitId]
  );

  const checkboxValue = useMemo(
    () => checkedHitIds.includes(hit?.doc?.id || ''),
    [checkedHitIds, hit?.doc?.id]
  );

  const styles = useMemo(
    () => ({
      ...(hit.doc?.id && !visibleIds[hit.doc.id] ? { display: 'none' } : {}),
    }),
    [visibleIds, hit.doc?.id]
  );

  return {
    card,
    matchStatusSetting,
    checkboxValue,
    href,
    onChange,
    styles,
  };
}
