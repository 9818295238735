import gql from 'graphql-tag';
import { INSTANT_CHECKUP_FRAGMENT } from '../../../fragment/admin/instantCheckups';

export const InstantCheckupUpdate = gql`
  ${INSTANT_CHECKUP_FRAGMENT}
  mutation InstantCheckupUpdate($id: String!, $body: BodyUpdateInstantCheckup!) {
    updateInstantCheckup(id: $id, body: $body)
      @rest(
        type: "InstantCheckupUpdate"
        path: "/core/admin/instant_checkups/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "InstantCheckup") {
        ...InstantCheckupFragment
      }
      success
    }
  }
`;
