import {
  GridContainer,
  InputLoader,
  LoadableFunctionalComponent,
} from '@aid-module/ui';
import loadable from '@loadable/component';
import { PropsWithChildren } from 'react';
import { FormViewProps } from './component/types';

export function FormLoader({ children }: PropsWithChildren<{}>) {
  return (
    <GridContainer gap="20">
      <InputLoader />
      {children}
    </GridContainer>
  );
}

export const Form = loadable(() => import('./component'), {
  resolveComponent: (components) => components.FormComponent,
  fallback: <FormLoader />,
}) as LoadableFunctionalComponent<PropsWithChildren<FormViewProps>>;
