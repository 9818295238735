import { TYPES } from '@aid-package/api';
import { useMemo } from 'react';
import {
  CheckupKindClientCodeResponse,
  CheckupsClientExecutionStatusResponse,
} from '../constants';

export function useCheckStatus(
  checkup: TYPES.ServiceCheckupQuery['serviceCheckup']['data']
) {
  const isProviderStatus = useMemo(() => {
    if (
      checkup.checkup_kind.code ===
        CheckupKindClientCodeResponse.FINCEN_PERSON ||
      checkup.checkup_kind.code ===
        CheckupKindClientCodeResponse.FINCEN_BUSINESS
    ) {
      return true;
    }
    if (
      (checkup.checkup_kind.code ===
        CheckupKindClientCodeResponse.COMPLY_ADVANTAGE_AML ||
        checkup.checkup_kind.code ===
          CheckupKindClientCodeResponse.COMPLY_ADVANTAGE_AML_COMMON ||
        checkup.checkup_kind.code ===
          CheckupKindClientCodeResponse.COMPLY_ADVANTAGE_AML_BUSINESS ||
        checkup.checkup_kind.code ===
          CheckupKindClientCodeResponse.SHUFTIPRO_KYB) &&
      checkup.execution_status !==
        CheckupsClientExecutionStatusResponse.FAILED &&
      checkup.execution_status !==
        CheckupsClientExecutionStatusResponse.IN_PROGRESS &&
      !!checkup.provider_status
    ) {
      return true;
    }
    return false;
  }, [checkup]);

  return { isProviderStatus };
}
