import { useTabRegistriesQuery } from '@aid-package/api';

export function useFilterTemplatesProviderModel(code?: string) {
  const { data: dataFavorites, loading: loadingFavorites } =
    useTabRegistriesQuery({
      variables: {
        registry_type: code || '',
        favorite: 'true',
      },
      skip: !code,
    });
  const { data: dataTemplates, loading: loadingTemplates } =
    useTabRegistriesQuery({
      variables: {
        registry_type: code || '',
        favorite: 'false',
        per: 50,
      },
      skip: !code,
    });

  return {
    favorites: dataFavorites?.tabRegistries?.data,
    templates: dataTemplates?.tabRegistries?.data,
    loading: loadingFavorites || loadingTemplates,
  };
}
