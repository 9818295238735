import { CommentProvider } from '@aid-module/ui';
import { Checkup } from '../../../Checkup';
import { useCheckupDescriptionController } from './controller';

export function CheckupDescription() {
  const { id } = useCheckupDescriptionController();
  if (id) {
    return (
      <CommentProvider>
        <Checkup checkupId={id} />
      </CommentProvider>
    );
  }

  return null;
}
