import { GridContainer } from '@aid-module/ui';
import styled from '@emotion/styled';

export const Container = styled(GridContainer)`
  margin-left: 114px;
  button {
    span {
      font-size: 11px;
    }
  }
`;
