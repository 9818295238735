import { GridContainer } from '../../../containers';
import { useCallback } from 'react';
import { RowContainer } from './styles';
import { FallbackViewProps } from './types';
import { ContentLoader } from '../ContentLoader';

export function CustomTableFallback({ count, infinityRef }: FallbackViewProps) {
  const renderItem = useCallback(
    (_: unknown, index: number) => (
      <RowContainer key={index}>
        <ContentLoader width="100%" height="49px">
          <rect width="100%" height="49px" />
        </ContentLoader>
      </RowContainer>
    ),
    []
  );

  return !!count ? (
    <GridContainer ref={infinityRef} gap="8">
      {count ? Array.from({ length: count }, renderItem) : null}
    </GridContainer>
  ) : null;
}
