import styled from '@emotion/styled';

export const Container = styled.div`
  position: relative;
  height: 16px;
  width: 16px;
`;

export const ActionContainer = styled.div`
  display: flex;
  position: absolute;
  top: -14px;
  left: -20px;
  right: -20px;
  bottom: -14px;
  justify-content: center;
  align-items: center;
`;
