import { gql } from '@apollo/client';

export const AIDictAll = gql`
  query AIDictAll {
    aiDictAll @rest(type: "ResponseAIDict", path: "/voodoo/dicts/all") {
      data @type(name: "AIDict") {
        task_kinds @type(name: "AIDictTaskKind") {
          code
          display_name
          options @type(name: "AIDictOptions") {
            schema
          }
          config_options @type(name: "AIDictOptions") {
            schema
          }
          task_options @type(name: "AIDictOptions") {
            schema
          }
        }
        processable_entities @type(name: "AIDictEntity") {
          code
          display_name
        }
        configurable_entities @type(name: "AIDictEntity") {
          code
          display_name
        }
        source_entity_types @type(name: "AIDictEntity") {
          code
          display_name
        }
      }
      success
    }
  }
`;

export const AIDictForTask = gql`
  query AIDictForTask {
    aiDictForTask
      @rest(type: "ResponseAIDict", path: "/voodoo/dicts/for_task") {
      data @type(name: "AIDict") {
        task_kinds @type(name: "AIDictTaskKind") {
          code
          display_name
          options @type(name: "AIDictOptions") {
            schema
          }
          config_options @type(name: "AIDictOptions") {
            schema
          }
          task_options @type(name: "AIDictOptions") {
            schema
          }
        }
        processable_entities @type(name: "AIDictEntity") {
          code
          display_name
        }
      }
      success
    }
  }
`;

export const AIDictForConfig = gql`
  query AIDictForConfig {
    aiDictForConfig
      @rest(type: "ResponseAIDict", path: "/voodoo/dicts/for_config") {
      data @type(name: "AIDict") {
        task_kinds @type(name: "AIDictTaskKind") {
          code
          display_name
          options @type(name: "AIDictOptions") {
            schema
          }
          config_options @type(name: "AIDictOptions") {
            schema
          }
          task_options @type(name: "AIDictOptions") {
            schema
          }
        }
        configurable_entities @type(name: "AIDictEntity") {
          code
          display_name
        }
      }
      success
    }
  }
`;
