import { useMemo } from 'react';
import { SelectOption } from '../../../../../../../../../../../../../../../../../../../../../../../../../Select/component/types';
import { useFilterTemplatesContext } from '../../../../../../../../../../../../../../../../../../../../../../../../../Table/context';

export function useSelectController() {
  const { data } = useFilterTemplatesContext();

  const options = useMemo(() => {
    return [...(data?.favorites || []), ...(data?.templates || [])].reduce<
      SelectOption[]
    >((acc, template) => {
      return [
        ...acc,
        {
          label: template.name,
          value: template.id,
          position: template.position,
        },
      ];
    }, []);
  }, [data]);

  return { options };
}
