import { GridContainer, SideWidgenProvider, Text } from '@aid-module/ui';
import { Tab } from '../../controller/types';
import { useHeaderController } from './controller';
import { TabContainer } from './styles';
import { HeaderViewProps } from './types';
import { Action } from './components';

export function Header({ value, onChange }: HeaderViewProps) {
  const { onChangeTab } = useHeaderController(onChange);

  return (
    <SideWidgenProvider>
      <GridContainer
        gridTemplateColumns="max-content max-content"
        gap="20"
        alignItems="center"
        justifyContent="space-between"
      >
        <Text
          text="Verification Statistics"
          size={20}
          uppercase
          weight="Giant"
        />
        <GridContainer
          gap="12"
          gridTemplateColumns="repeat(2, max-content)"
          alignItems="end"
        >
          <GridContainer
            gridTemplateColumns="max-content max-content"
            gridTemplateRows="26px"
          >
            <TabContainer
              selected={value === Tab.STATISTICS}
              onClick={onChangeTab(Tab.STATISTICS)}
            >
              <Text text="Statistics" color="main-deep-4" size={16} />
            </TabContainer>
            <TabContainer
              selected={value === Tab.TEMPLATES}
              onClick={onChangeTab(Tab.TEMPLATES)}
            >
              <Text text="Templates" color="main-deep-4" size={16} />
            </TabContainer>
          </GridContainer>
          <Action />
        </GridContainer>
      </GridContainer>
    </SideWidgenProvider>
  );
}
