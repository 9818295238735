import { MouseEvent, useCallback, useMemo } from 'react';
import { usePinnedModel } from '../model';
import { PinnedViewProps } from '../types';
import { Icons } from '@aid-module/ui';

export function usePinnedController(
  notification: PinnedViewProps['notification']
) {
  const { read, loading } = usePinnedModel();

  const onClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (!loading) {
        read(notification.id, notification.pinned);
      }
    },
    [read, notification, loading]
  );

  const icon = useMemo<keyof typeof Icons>(() => {
    if (loading) {
      return 'spinner';
    }

    if (notification.pinned) {
      return 'filledPinned';
    }

    return 'pinned';
  }, [notification.pinned, loading]);

  return { onClick, loading, icon };
}
