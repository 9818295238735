import { ContentLoader, GridContainer } from '@aid-module/ui';

export function Fallback() {
  return (
    <GridContainer gap="20">
      <ContentLoader width="100%" height="26">
        <rect rx="4" ry="4" width="100%" height="26" />
      </ContentLoader>
      <ContentLoader width="100%" height="446.56">
        <rect rx="4" ry="4" width="100%" height="446.56" />
      </ContentLoader>
    </GridContainer>
  );
}
