import gql from 'graphql-tag';
import { CASE_REPORT_FRAGMENT } from '../../../fragment/pdf';

export const CaseReport = gql`
  ${CASE_REPORT_FRAGMENT}
  query CaseReport($id: String!) {
    caseReport(id: $id)
      @rest(
        type: "CaseReportResponse"
        path: "/core/client/cases/{args.id}/report/pdf"
      ) {
      data @type(name: "CaseReport") {
        ...CaseReportFragment
      }
      success
    }
  }
`;
