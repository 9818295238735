import { createContext, useContext } from 'react';
import { NavigationContextParams } from './types';

export const NavigationContext = createContext<NavigationContextParams>({
  navigation: {
    type: 'filters',
  },
  navigateTo() {},
  toggleShowFilters() {},
});

export function useNavigationContext() {
  const context = useContext(NavigationContext);

  return context;
}
