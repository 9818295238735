import gql from 'graphql-tag';
import { CASE_SECTION_FRAGMENT } from '../caseSection';

export const LEGAL_ENTITY_REGISTERS_FRAGMENT = gql`
  ${CASE_SECTION_FRAGMENT}
  fragment LegalEntityRegistersFragment on LegalEntityRegisters {
    case_section @type(name: "CaseSection") {
      ...CaseSectionFragment
    }
    instant_checkup_results @type(name: "AMLInstantCheckupResult") {
      ...AMLInstantCheckupResultFragment
    }
  }
`;
