import { ContentLoader } from '../../../../../../../shared';
import { TextInput } from '../../../../../../TextInput';
import { useSearchInputController } from './controller';
import { SearchInputViewProps } from './types';

export function SearchInput({
  onChangeSearch,
  searchable,
}: SearchInputViewProps) {
  const { value, onChange } = useSearchInputController(onChangeSearch);

  if (searchable) {
    return (
      <TextInput
        placeholder="Search..."
        value={value}
        onChange={onChange}
        fallback={
          <ContentLoader width="100%" height="32.2px">
            <rect x="0" y="0" rx="3" ry="3" width="100%" height="32" />
          </ContentLoader>
        }
      />
    );
  }

  return null;
}
