import gql from 'graphql-tag';

export const CreateAITask = gql`
  mutation CreateAITask($body: BodyCreateAITask!) {
    createAITask(body: $body)
      @rest(
        type: "CreateAITask"
        path: "/voodoo/ai_tasks"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "AITask") {
        id
        ai_task_config_id
        body
        structured_body
        created_by @type(name: "Account") {
          id
          first_name
          last_name
          full_name
          avatar_id
          email
          phone
        }
        client_id
        created_at
        current_ai_task_result_id
        kind
        options
        processable_entity_id
        processable_entity_type
        source_entities @type(name: "AITaskSourceEntity") {
          ai_task_id
          client_id
          created_at
          id
          options
          source_entity_id
          source_entity_type
          status
          updated_at
        }
        status
        updated_at
        current_ai_task_result @type(name: "AITaskResult") {
          id
          ai_task_id
          client_id
          comment
          created_at
          edited_at
          edited_by @type(name: "Account") {
            id
            email
            first_name
            full_name
            last_name
            region_code
            status
          }
          edited_by_id
          is_marked_as_actual
          is_marked_as_irrelevant
          status
          body
          updated_at
          llm_calls @type(name: "AITaskResultLLMCall") {
            response
            status
          }
          options
        }
        ai_task_results @type(name: "AITaskReuslt") {
          id
          ai_task_id
          client_id
          comment
          created_at
          edited_at
          edited_by @type(name: "Account") {
            id
            email
            first_name
            full_name
            last_name
            region_code
            status
          }
          edited_by_id
          is_marked_as_actual
          is_marked_as_irrelevant
          status
          body
          updated_at
          llm_calls @type(name: "AITaskResultLLMCall") {
            response
            status
          }
          options
        }
      }
      success
    }
  }
`;

export const UpdateAITask = gql`
  mutation UpdateAITask($id: String!, $body: BodyUpdateAITask!) {
    updateAITask(id: $id, body: $body)
      @rest(
        type: "UpdateAITask"
        path: "/voodoo/ai_tasks/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "AITask") {
        id
        ai_task_config_id
        body
        structured_body
        created_by @type(name: "Account") {
          id
          first_name
          last_name
          full_name
          avatar_id
          email
          phone
        }
        client_id
        created_at
        current_ai_task_result_id
        kind
        options
        processable_entity_id
        processable_entity_type
        source_entities @type(name: "AITaskSourceEntity") {
          ai_task_id
          client_id
          created_at
          id
          options
          source_entity_id
          source_entity_type
          status
          updated_at
        }
        status
        updated_at
        current_ai_task_result @type(name: "AITaskReuslt") {
          id
          ai_task_id
          client_id
          comment
          created_at
          edited_at
          edited_by @type(name: "Account") {
            id
            email
            first_name
            full_name
            last_name
            region_code
            status
          }
          edited_by_id
          is_marked_as_actual
          is_marked_as_irrelevant
          status
          body
          updated_at
          llm_calls @type(name: "AITaskResultLLMCall") {
            response
            status
          }
          options
        }
        ai_task_results @type(name: "AITaskReuslt") {
          id
          ai_task_id
          client_id
          comment
          created_at
          edited_at
          edited_by @type(name: "Account") {
            id
            email
            first_name
            full_name
            last_name
            region_code
            status
          }
          edited_by_id
          is_marked_as_actual
          is_marked_as_irrelevant
          status
          body
          updated_at
          llm_calls @type(name: "AITaskResultLLMCall") {
            response
            status
          }
          options
        }
      }
      success
    }
  }
`;

export const DeleteAITask = gql`
  mutation DeleteAITask($id: String!) {
    deleteAITask(id: $id)
      @rest(
        type: "DeleteAITask"
        path: "voodoo/ai_tasks/{args.id}"
        method: "DELETE"
      ) {
      success
    }
  }
`;
