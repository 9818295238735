import { GridContainer } from '../../../containers';
import { DefaultButtonComponent } from '../../Button/components/ButtonByType/components/components/DefaultButton/component';
import { ErrorTemplate } from '../components';
import { ErrorTemplateViewProps } from '../components/ErrorTemplate/types';
import { useErrorBundleController } from './controller';

export function ErrorBundle({
  type = 'local',
}: Partial<Pick<ErrorTemplateViewProps, 'type'>>) {
  const { reloadPage } = useErrorBundleController();
  return (
    <ErrorTemplate
      type={type}
      title="The application has been updated"
      description="please reload the page"
      customIcon={
        <svg
          width="81"
          height="80"
          viewBox="0 0 81 80"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M79.1668 12V74.6667C79.1668 75.7276 78.7454 76.745 77.9953 77.4951C77.2451 78.2453 76.2277 78.6667 75.1668 78.6667H5.8335C4.77263 78.6667 3.75521 78.2453 3.00507 77.4951C2.25492 76.745 1.8335 75.7276 1.8335 74.6667V12L3.16683 10.6667H77.8335L79.1668 12Z"
            fill="#DCE3F5"
          />
          <path
            d="M79.1668 5.33331V12H1.8335V5.33331C1.8335 4.27245 2.25492 3.25503 3.00507 2.50489C3.75521 1.75474 4.77263 1.33331 5.8335 1.33331H75.1668C76.2277 1.33331 77.2451 1.75474 77.9953 2.50489C78.7454 3.25503 79.1668 4.27245 79.1668 5.33331V5.33331Z"
            fill="#15265A"
          />
          <path
            d="M40.4999 58.4C48.011 58.4 54.0999 52.3111 54.0999 44.8C54.0999 37.2889 48.011 31.2 40.4999 31.2C32.9888 31.2 26.8999 37.2889 26.8999 44.8C26.8999 52.3111 32.9888 58.4 40.4999 58.4Z"
            fill="#5A79CB"
          />
          <path
            d="M8.50016 7.99998H7.16683C6.81321 7.99998 6.47407 7.8595 6.22402 7.60946C5.97397 7.35941 5.8335 7.02027 5.8335 6.66665C5.8335 6.31302 5.97397 5.97389 6.22402 5.72384C6.47407 5.47379 6.81321 5.33331 7.16683 5.33331H8.50016C8.85379 5.33331 9.19292 5.47379 9.44297 5.72384C9.69302 5.97389 9.8335 6.31302 9.8335 6.66665C9.8335 7.02027 9.69302 7.35941 9.44297 7.60946C9.19292 7.8595 8.85379 7.99998 8.50016 7.99998Z"
            fill="#F6F8FF"
          />
          <path
            d="M15.1667 7.99998H13.8333C13.4797 7.99998 13.1406 7.8595 12.8905 7.60946C12.6405 7.35941 12.5 7.02027 12.5 6.66665C12.5 6.31302 12.6405 5.97389 12.8905 5.72384C13.1406 5.47379 13.4797 5.33331 13.8333 5.33331H15.1667C15.5203 5.33331 15.8594 5.47379 16.1095 5.72384C16.3595 5.97389 16.5 6.31302 16.5 6.66665C16.5 7.02027 16.3595 7.35941 16.1095 7.60946C15.8594 7.8595 15.5203 7.99998 15.1667 7.99998Z"
            fill="#DCE3F5"
          />
          <path
            d="M21.8332 7.99998H20.4998C20.1462 7.99998 19.8071 7.8595 19.557 7.60946C19.307 7.35941 19.1665 7.02027 19.1665 6.66665C19.1665 6.31302 19.307 5.97389 19.557 5.72384C19.8071 5.47379 20.1462 5.33331 20.4998 5.33331H21.8332C22.1868 5.33331 22.5259 5.47379 22.776 5.72384C23.026 5.97389 23.1665 6.31302 23.1665 6.66665C23.1665 7.02027 23.026 7.35941 22.776 7.60946C22.5259 7.8595 22.1868 7.99998 21.8332 7.99998Z"
            fill="white"
          />
          <path
            d="M73.8333 7.99998H65.8333C65.4797 7.99998 65.1406 7.8595 64.8905 7.60946C64.6405 7.35941 64.5 7.02027 64.5 6.66665C64.5 6.31302 64.6405 5.97389 64.8905 5.72384C65.1406 5.47379 65.4797 5.33331 65.8333 5.33331H73.8333C74.187 5.33331 74.5261 5.47379 74.7761 5.72384C75.0262 5.97389 75.1667 6.31302 75.1667 6.66665C75.1667 7.02027 75.0262 7.35941 74.7761 7.60946C74.5261 7.8595 74.187 7.99998 73.8333 7.99998Z"
            fill="#F6F8FF"
          />
          <path
            d="M17.3247 46.8487L20.9188 43.2489C21.137 43.0226 21.2578 42.7195 21.2551 42.4049C21.2523 42.0902 21.1263 41.7893 20.9042 41.5668C20.6821 41.3443 20.3816 41.2181 20.0675 41.2154C19.7534 41.2127 19.4507 41.3336 19.2248 41.5522L17.7656 43.0125C18.178 37.736 20.4111 32.7678 24.0816 28.9604C27.7522 25.1531 32.6314 22.7439 37.8819 22.1464C43.1324 21.549 48.4268 22.8004 52.8567 25.686C57.2865 28.5716 60.5754 32.9114 62.1591 37.9608C62.2063 38.1111 62.2826 38.2507 62.3837 38.3715C62.4847 38.4922 62.6085 38.5919 62.748 38.6648C62.8876 38.7377 63.04 38.7823 63.1968 38.7962C63.3535 38.81 63.5114 38.7928 63.6615 38.7455C63.8116 38.6983 63.9509 38.6218 64.0715 38.5206C64.1921 38.4194 64.2916 38.2954 64.3644 38.1557C64.4372 38.016 64.4817 37.8633 64.4956 37.7063C64.5094 37.5493 64.4922 37.3911 64.445 37.2408C62.6864 31.6303 59.023 26.8131 54.0899 23.6243C49.1569 20.4356 43.2652 19.0763 37.4365 19.7822C31.6077 20.4881 26.2094 23.2147 22.1773 27.4892C18.1453 31.7637 15.7339 37.3167 15.3611 43.1853L13.7305 41.5522C13.5046 41.3336 13.202 41.2127 12.8878 41.2154C12.5737 41.2181 12.2732 41.3443 12.0511 41.5668C11.829 41.7893 11.703 42.0902 11.7002 42.4049C11.6975 42.7195 11.8183 43.0226 12.0365 43.2489L15.6306 46.8487C15.8553 47.0737 16.16 47.2 16.4777 47.2C16.7953 47.2 17.1 47.0737 17.3247 46.8487V46.8487Z"
            fill="#1F3B8E"
          />
          <path
            d="M68.9637 46.3512L65.3696 42.7513C65.1449 42.5264 64.8402 42.4 64.5225 42.4C64.2049 42.4 63.9002 42.5264 63.6755 42.7513L60.0814 46.3512C59.8631 46.5775 59.7424 46.8806 59.7451 47.1952C59.7479 47.5098 59.8738 47.8108 60.096 48.0333C60.3181 48.2557 60.6186 48.3819 60.9327 48.3847C61.2468 48.3874 61.5495 48.2665 61.7754 48.0479L63.2346 46.5876C62.8222 51.864 60.5891 56.8322 56.9186 60.6396C53.248 64.447 48.3688 66.8561 43.1183 67.4536C37.8678 68.0511 32.5734 66.7996 28.1435 63.914C23.7137 61.0284 20.4248 56.6886 18.8411 51.6393C18.7457 51.3357 18.5339 51.0824 18.2522 50.9353C17.9704 50.7881 17.6418 50.7591 17.3387 50.8545C17.0356 50.95 16.7827 51.1622 16.6358 51.4444C16.4889 51.7265 16.4599 52.0556 16.5552 52.3592C18.3138 57.9697 21.9772 62.787 26.9103 65.9757C31.8433 69.1645 37.7349 70.5238 43.5637 69.8179C49.3925 69.1119 54.7908 66.3854 58.8229 62.1108C62.8549 57.8363 65.2663 52.2834 65.6391 46.4148L67.2697 48.0479C67.4956 48.2665 67.7982 48.3874 68.1124 48.3847C68.4265 48.3819 68.727 48.2557 68.9491 48.0333C69.1712 47.8108 69.2972 47.5098 69.3 47.1952C69.3027 46.8806 69.1819 46.5775 68.9637 46.3512V46.3512Z"
            fill="#1F3B8E"
          />
          <path
            d="M37.4051 52C37.132 51.9999 36.8702 51.8915 36.6772 51.6986L33.5888 48.6129C33.4013 48.4189 33.2975 48.1591 33.2998 47.8894C33.3022 47.6197 33.4105 47.3617 33.6013 47.171C33.7922 46.9803 34.0504 46.8721 34.3203 46.8698C34.5903 46.8675 34.8503 46.9711 35.0445 47.1585L37.2939 49.4059L45.8468 38.0114C45.9279 37.9033 46.0295 37.8123 46.1458 37.7435C46.2621 37.6747 46.3909 37.6294 46.5247 37.6103C46.6586 37.5912 46.7949 37.5986 46.9258 37.6322C47.0568 37.6657 47.1799 37.7246 47.288 37.8057C47.3962 37.8867 47.4873 37.9883 47.5562 38.1045C47.625 38.2207 47.6703 38.3494 47.6895 38.4831C47.7086 38.6168 47.7011 38.753 47.6676 38.8838C47.634 39.0147 47.575 39.1376 47.4939 39.2457L38.2286 51.5885C38.1404 51.7069 38.0277 51.8049 37.8982 51.8759C37.7687 51.9469 37.6255 51.9892 37.4781 52H37.4051Z"
            fill="white"
          />
        </svg>
      }
    >
      <GridContainer gridTemplateColumns="240px">
        <DefaultButtonComponent onClick={reloadPage} text="Reload" />
      </GridContainer>
    </ErrorTemplate>
  );
}
