import { gql } from '@apollo/client';

export const TransactionScoringMatrices = gql`
  query TransactionScoringMatrices(
    $per: Int
    $page: Int
    $is_test: Boolean
    $enabled: Boolean
  ) {
    transactionScoringMatrices(
      per: $per
      page: $page
      is_test: $is_test
      enabled: $enabled
    )
      @rest(
        type: "ResponseTransactionScoringMatrices"
        path: "/tm/scoring/matrices?{args}"
      ) {
      data @type(name: "TransactionScoringMatrix") {
        id
        client_channel
        client_type
        created_at
        description
        enabled
        name
        updated_at
        is_test
      }
      meta {
        count
        page
        items
        pages
      }
      success
    }
  }
`;

export const TransactionScoringMatrix = gql`
  query TransactionScoringMatrix($id: String!) {
    transactionScoringMatrix(id: $id)
      @rest(
        type: "ResponseTransactionScoringMatrix"
        path: "/tm/scoring/matrices/{args.id}"
      ) {
      data @type(name: "TransactionScoringMatrix") {
        id
        client_channel
        client_type
        created_at
        description
        enabled
        name
        updated_at
        is_test
      }
      success
    }
  }
`;

export const EventResultMatrices = gql`
  query EventResultMatrices($event_id: String!) {
    eventResultMatrices(event_id: $event_id)
      @rest(
        type: "ResponseEventResultMatrices"
        path: "/tm/scoring/matrix_event_results?{args}"
      ) {
      data @type(name: "EventResultMatrix") {
        created_at
        error_messages
        id
        is_test
        score
        scoring_matrix @type(name: "TransactionScoringMatrix") {
          id
          client_channel
          client_type
          created_at
          description
          enabled
          name
          updated_at
          is_test
        }
        state
      }
      success
    }
  }
`;

export const ResultMatrix = gql`
  query ResultMatrix($id: String!) {
    resultMatrix(id: $id)
      @rest(
        type: "ResponseResultMatrix"
        path: "/tm/scoring/matrix_event_results/{args.id}"
      ) {
      data @type(name: "ResultMatrix") {
        created_at
        error_messages
        id
        is_test
        score
        scoring_dependent_rule_results
          @type(name: "ScoringDependentRuleResult") {
          created_at
          error_messages
          event_id
          id
          is_changed
          is_test
          score
          scoring_dependent_rule
            @type(name: "TransactionScoringDependentRule") {
            id
            client_id
            created_at
            if_scoring_rule_id
            if_scoring_rule_score
            scoring_matrix_id
            then_scoring_rule_id
            then_scoring_rule_score
            updated_at
          }
          scoring_dependent_rule_id
          scoring_matrix_id
          success
          updated_at
        }
        scoring_manual_rule_results @type(name: "ScoringManualRuleResult") {
          created_at
          entity_id
          error_messages
          event_id
          id
          is_test
          is_triggered
          rfi_suggested
          scoring_manual_rule @type(name: "TransactionScoringManualRule") {
            id
            client_id
            created_at
            execution_klass
            params
            rfi_suggested
            scoring_matrix_id
            supporting_docs_required
            transaction_stopped
            updated_at
          }
          scoring_manual_rule_id
          scoring_matrix_id
          success
          supporting_docs_required
          transaction_stopped
          updated_at
        }
        scoring_rule_results @type(name: "ScoringRuleResult") {
          created_at
          entity_id
          error_messages
          event_id
          id
          is_test
          score
          scoring_matrix_id
          scoring_rule @type(name: "TransactionScoringRule") {
            id
            client_id
            created_at
            description
            execution_klass
            name
            params
            score_if_no_rules
            score_is_exact_value
            scoring_matrix_id
            updated_at
            weight
          }
          success
          target_value
          updated_at
        }
        scoring_score_rule_results @type(name: "ScoringScoreRuleResult") {
          created_at
          entity_id
          error_messages
          event_id
          id
          is_test
          is_triggered
          rfi_suggested
          scoring_score_rule @type(name: "TransactionScoringScoreRule") {
            id
            client_id
            created_at
            params {
              less_than
              greater_than
              equal_to
              condition
              color
            }
            rfi_suggested
            scoring_matrix_id
            supporting_docs_required
            transaction_stopped
            updated_at
          }
          scoring_score_rule_id
          scoring_matrix_id
          success
          supporting_docs_required
          transaction_stopped
          updated_at
        }
        state
      }
      success
    }
  }
`;
