import styled from '@emotion/styled';
import { getColor, getSpace } from '@aid-module/ui';
import { EmotionTabContainerViewProps } from './types';

export const TabContainer = styled.div<EmotionTabContainerViewProps>`
  cursor: pointer;
  width: 200px;
  display: flex;
  justify-content: center;
  padding-bottom: ${getSpace('8')}px;
  border-bottom: 1px solid ${getColor('main-deep-2')};

  ${({ selected }) =>
    selected
      ? `
        padding-bottom: ${getSpace('6')}px;
        border-bottom: 3px solid ${getColor('main-deep-4')};

        & span > {
            font-weight: 500;
        }
      `
      : ''}
`;
