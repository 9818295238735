import { GridContainer, Icon, IconLoader, Text } from '@aid-module/ui';
import { useBreadcrumbItemController } from './controller';
import { Breadcrumb, Container } from './styles';
import { BreadcrumbItemViewProps } from './types';

export function BreadcrumbItem({ name, path }: BreadcrumbItemViewProps) {
  const { onClickBreadcrumb } = useBreadcrumbItemController(path);

  return (
    <Container
      alignItems="center"
      gridTemplateColumns="max-content 20px"
      gap="14"
    >
      <Breadcrumb onClick={onClickBreadcrumb}>
        <Text color="main-deep-3" text={name} size={12} />
      </Breadcrumb>
      <GridContainer>
        <Icon
          glyph="arrowRight"
          size={10}
          color="main-deep-2"
          fallback={<IconLoader size={10} />}
        />
      </GridContainer>
    </Container>
  );
}
