import { gql } from '@apollo/client';

export const ExecuteAdditional = gql`
  mutation ExecuteAdditional($body: ExecuteAdditionalBody!) {
    executeAdditional(body: $body)
      @rest(
        type: "ExecuteAdditional"
        path: "/tm/rules/execute_additional"
        bodyKey: "body"
        method: "POST"
      ) {
      data
      success
    }
  }
`;
