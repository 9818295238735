import { useFormController } from './controller';
import { FormProvider, Controller } from 'react-hook-form';
import { PropsWithChildren, ReactNode, useCallback } from 'react';
import { GridContainer } from '../../../../../../../../../../../../../containers';
import { FormFilterConfig } from './controller/types';

export function Form({
  children,
  supportChildren,
}: PropsWithChildren<{ supportChildren?: ReactNode }>) {
  const { formContext, filterInputs } = useFormController();

  const renderField = useCallback(
    (config: FormFilterConfig[0]) => (
      <GridContainer gridTemplateRows="max-content" key={config.name}>
        <Controller name={config.name} render={config.render} />
      </GridContainer>
    ),
    []
  );

  return (
    <FormProvider {...formContext}>
      {supportChildren}
      <GridContainer
        gridTemplateColumns="repeat(4, 1fr)"
        gap="20"
        gridTemplateRows="max-content"
      >
        {filterInputs.map(renderField)}
      </GridContainer>
      {children}
    </FormProvider>
  );
}
