import { THEME_COLORS } from '../../../shared/colors/types';
import { TRANSACTION_CHECKUP_RESULT_STATUSES } from '../constants';
import { TmCheckupResultStatusViewProps } from '../types';

export function getColorByStatus(
  status?: TmCheckupResultStatusViewProps['status']
): keyof THEME_COLORS {
  switch (status) {
    case TRANSACTION_CHECKUP_RESULT_STATUSES.NEW:
    case TRANSACTION_CHECKUP_RESULT_STATUSES.DRAFT:
      return 'warning-deep-4';
    case TRANSACTION_CHECKUP_RESULT_STATUSES.FAILED:
    case TRANSACTION_CHECKUP_RESULT_STATUSES.REJECTED:
      return 'error-deep-3';
    case TRANSACTION_CHECKUP_RESULT_STATUSES.ACCEPTED:
    case TRANSACTION_CHECKUP_RESULT_STATUSES.SUCCESS:
    case TRANSACTION_CHECKUP_RESULT_STATUSES.COMPLETED:
      return 'success-deep-4';
    default:
      return 'warning-deep-4';
  }
}
