import { Logo } from './components';
import { HeaderContainer } from './styles';

export function Header() {
  return (
    <HeaderContainer
      justifyContent="center"
      alignItems="center"
    >
      <Logo />
    </HeaderContainer>
  );
}
