import { GridContainer } from '../../../containers';
import { ContentLoader } from '../ContentLoader';
import { LinkLoader } from '../LinkLoader';

export function ProfilesTableInfoLoader() {
  return (
    <GridContainer
      gap="6"
      alignItems="center"
      gridTemplateColumns="1fr max-content"
      gridTemplateRows="24px"
    >
      <LinkLoader height={21} />
      <ContentLoader width="24px" height="24px">
        <rect x="0" y="0" rx="3" ry="3" width="24px" height="24px" />
      </ContentLoader>
    </GridContainer>
  );
}
