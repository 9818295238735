import { PrimaryButton } from '@aid-module/ui';
import { useActionController } from './controller';

export function Update() {
  const { disabled, onClickSubmit } = useActionController();

  return (
    <PrimaryButton
      text="Update Results"
      disabled={disabled}
      onClick={onClickSubmit}
    />
  );
}
