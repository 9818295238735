import { Empty, GridContainer } from '@aid-module/ui';
import { useCallback } from 'react';
import { ChangeStatusForm } from '../ChangeStatusForm';
import { Hit } from '../Hit';
import { HitViewProps } from '../Hit/types';
import { HitsListViewProps } from './types';

export function HitsList({ results, searchId }: HitsListViewProps) {
  const renderHit = useCallback(
    (item: HitViewProps['hit'], index: number) => (
      <Hit key={index} searchId={searchId} asLink={true} hit={item} />
    ),
    [searchId]
  );

  if (results.length > 0) {
    return (
      <GridContainer gap="20">
        <ChangeStatusForm />
        <GridContainer gridTemplateColumns="1fr 1fr" gap="20">
          {results.map(renderHit)}
        </GridContainer>
      </GridContainer>
    );
  }

  return <Empty />;
}
