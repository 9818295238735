import { ContentLoader, GridContainer } from '@aid-module/ui';

export function Fallback() {
  return (
    <GridContainer gap="8">
      <ContentLoader width="100%" height="16px">
        <rect rx="6" ry="6" width="100%" height="16px" />
      </ContentLoader>
      <ContentLoader width="100%" height="358px">
        <rect rx="6" ry="6" width="100%" height="358px" />
      </ContentLoader>
      <ContentLoader width="100%" height="16px">
        <rect rx="6" ry="6" width="100%" height="16px" />
      </ContentLoader>
      <ContentLoader width="100%" height="358px">
        <rect rx="6" ry="6" width="100%" height="358px" />
      </ContentLoader>
    </GridContainer>
  );
}
