import gql from 'graphql-tag';

export const SERVICE_CHECKUP_KIND_FRAGMENT = gql`
  fragment ServiceCheckupKindFragment on CheckupKind {
    code
    description
    id
    name
    provider_id
  }
`;
