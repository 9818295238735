import { Icon } from '../../../../../../../../../../../../Icon';
import { GridContainer } from '../../../../../../../../../../../../../containers';
import { useUnionController } from './controller';
import { Label } from './styles';

export function Union() {
  const {
    type,
    navigateToFilterTemplates,
    navigateToFilters,
    toggleShowFilters,
  } = useUnionController();

  return (
    <GridContainer gridTemplateColumns="1fr max-content" gap="32">
      <GridContainer gridTemplateColumns="repeat(2, max-content)" gap="32">
        <Label
          text="Filters"
          isActive={type === 'filters'}
          color={type === 'filters' ? 'main-deep-4' : 'main-deep-3'}
          weight="VeryBig"
          onClick={navigateToFilters}
          uppercase
        />
        <Label
          text="Filter Templates"
          isActive={type === 'filterTemplates'}
          color={type === 'filterTemplates' ? 'main-deep-4' : 'main-deep-3'}
          weight="VeryBig"
          onClick={navigateToFilterTemplates}
          uppercase
        />
      </GridContainer>
      <Icon glyph="cross" color="main-deep-4" onClick={toggleShowFilters} />
    </GridContainer>
  );
}
