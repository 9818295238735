import { gql } from '@apollo/client';

export const TransactionEventRfiItems = gql`
  query TransactionEventRfiItems($event_id: String!, $page: Int, $per: Int) {
    transactionEventRfiItems(event_id: $event_id, page: $page, per: $per)
      @rest(type: "ResponseEventRfiItems", path: "/tm/rfi_items?{args}") {
      data @type(name: "EventRfiItem") {
        id
        payment_order_id
        document_ids
        bca_comment
        state
        source
        fields @type(name: "EventRfiItemField") {
          code
          value
          display_name
          value_display_name
        }
        created_at
        updated_at
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;
