import { toast } from 'react-toastify';
import { View } from './components';
import { NotificationShowToast } from './types';
import { PropsWithChildren } from 'react';

class NotificationClass {
  private id: undefined | string | number;
  private count: number = 0;
  onClose = () => {
    this.id = undefined;
    this.count = 0;
  };

  showMessage({
    client,
    callback,
    children,
  }: PropsWithChildren<NotificationShowToast>) {
    this.count = this.count + 1;
    if (this.id) {
      const count = this.count;
      toast.update(this.id, {
        render: (
          <View
            client={client}
            children={children}
            callback={callback}
            count={count}
          />
        ),
        onClose: this.onClose,
        className: 'notification notification_stack',
      });
    } else {
      this.id = toast(
        <View client={client} callback={callback} children={children} />,
        {
          icon: false,
          onClose: this.onClose,
          className: 'notification',
        }
      );
    }
  }
}

export const Notification = new NotificationClass();
