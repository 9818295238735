import { TYPES } from '@aid-package/api';
import { ToProfile } from '../../../../../ToProfile';
import { BodyViewProps } from './types';
import { ClientAvatar } from '../../../../../ClientAvatar';
import { Container, ContentContainer } from './styles';

function renderProfile(profile: TYPES.Profile) {
  return (
    <ContentContainer
      gridTemplateColumns="max-content minmax(0, 1fr)"
      gap="12"
      alignItems="center"
      key={profile.id}
    >
      <ClientAvatar client={profile} size="little" />
      <ToProfile
        key={profile.id}
        profile_id={profile.id}
        text={profile.display_name || profile.email}
        withoutIcon
        size={12}
      />
    </ContentContainer>
  );
}

export function Body({ profiles }: BodyViewProps) {
  return <Container gap="12">{profiles.map(renderProfile)}</Container>;
}
