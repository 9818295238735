import { Text } from '../../../../../../../../../../../../../../Text';
import { Container, DragContainer } from './styles';
import { TemplateViewProps } from './types';
import { Checkbox } from '../../../../../../../../../../../../../../Checkbox';
import { useCallback } from 'react';
import { useActionContext } from '../../../../context';
import { ContentLoader } from '../../../../../../../../../../../../../../../shared';

export function Template({ template, provided, index }: TemplateViewProps) {
  const update = useActionContext();

  const onChange = useCallback(
    (next?: boolean) => {
      update(index, !!next);
    },
    [update, index]
  );

  return (
    <Container
      ref={provided.innerRef}
      {...provided.draggableProps}
      gridTemplateColumns="max-content 1fr"
      gap="8"
      alignItems="center"
    >
      <DragContainer {...provided.dragHandleProps}>
        <svg
          width="11"
          height="18"
          viewBox="0 0 11 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1923_39635)">
            <path
              d="M1.81818 4.21055C2.82234 4.21055 3.63636 3.42509 3.63636 2.45617C3.63636 1.48725 2.82234 0.701782 1.81818 0.701782C0.814028 0.701782 0 1.48725 0 2.45617C0 3.42509 0.814028 4.21055 1.81818 4.21055Z"
              fill="#DCE3F5"
            />
            <path
              d="M1.81818 10.7193C2.82234 10.7193 3.63636 9.93388 3.63636 8.96496C3.63636 7.99604 2.82234 7.21057 1.81818 7.21057C0.814028 7.21057 0 7.99604 0 8.96496C0 9.93388 0.814028 10.7193 1.81818 10.7193Z"
              fill="#DCE3F5"
            />
            <path
              d="M1.81818 17.2281C2.82234 17.2281 3.63636 16.4427 3.63636 15.4737C3.63636 14.5048 2.82234 13.7194 1.81818 13.7194C0.814028 13.7194 0 14.5048 0 15.4737C0 16.4427 0.814028 17.2281 1.81818 17.2281Z"
              fill="#DCE3F5"
            />
            <path
              d="M8.45453 4.21086C9.45869 4.21086 10.2727 3.42539 10.2727 2.45647C10.2727 1.48755 9.45869 0.702087 8.45453 0.702087C7.45038 0.702087 6.63635 1.48755 6.63635 2.45647C6.63635 3.42539 7.45038 4.21086 8.45453 4.21086Z"
              fill="#DCE3F5"
            />
            <path
              d="M8.45453 10.7196C9.45869 10.7196 10.2727 9.93418 10.2727 8.96526C10.2727 7.99634 9.45869 7.21088 8.45453 7.21088C7.45038 7.21088 6.63635 7.99634 6.63635 8.96526C6.63635 9.93418 7.45038 10.7196 8.45453 10.7196Z"
              fill="#DCE3F5"
            />
            <path
              d="M8.45453 17.2284C9.45869 17.2284 10.2727 16.443 10.2727 15.4741C10.2727 14.5051 9.45869 13.7197 8.45453 13.7197C7.45038 13.7197 6.63635 14.5051 6.63635 15.4741C6.63635 16.443 7.45038 17.2284 8.45453 17.2284Z"
              fill="#DCE3F5"
            />
          </g>
          <defs>
            <clipPath id="clip0_1923_39635">
              <rect width="11" height="18" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </DragContainer>
      <Checkbox
        value={template.isSelected}
        onChange={onChange}
        fallback={
          <ContentLoader width="16" height="16">
            <rect rx="4" ry="4" width="16" height="16" />
          </ContentLoader>
        }
      />
      <Text
        text={template.supportHeader || template.header}
        weight="Medium"
        color="main-deep-5"
      />
    </Container>
  );
}
