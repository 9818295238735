import gql from 'graphql-tag';
import { CHECKUP_FRAGMENT } from '../../../fragment/admin/checkups';

export const CheckupUpdate = gql`
  ${CHECKUP_FRAGMENT}
  mutation CheckupUpdate($id: String!, $body: BodyUpdateCheckup!) {
    updateCheckup(id: $id, body: $body)
      @rest(
        type: "CheckupUpdate"
        path: "/core/admin/checkups/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "Checkup") {
        ...CheckupFragment
      }
      success
    }
  }
`;
