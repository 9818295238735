import { gql } from '@apollo/client';
import { CHECKUP_CONFIG_FRAGMENT } from '../../../fragment/checkup/CheckupConfig';

export const CreateCheckupConfig = gql`
  ${CHECKUP_CONFIG_FRAGMENT}
  mutation CreateCheckupConfig($body: BodyCreateOrUpdateCheckupConfig!) {
    createCheckupConfig(body: $body)
      @rest(
        type: "CreateCheckupConfig"
        path: "/checkups/client/checkup_configs/"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "CheckupConfig") {
        ...CheckupConfigFragment
      }
      success
    }
  }
`;

export const UpdateCheckupConfig = gql`
  ${CHECKUP_CONFIG_FRAGMENT}
  mutation UpdateCheckupConfig(
    $id: String!
    $body: BodyCreateOrUpdateCheckupConfig!
  ) {
    updateCheckupConfig(id: $id, body: $body)
      @rest(
        type: "UpdateCheckupConfig"
        path: "/checkups/client/checkup_configs/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "CheckupConfig") {
        ...CheckupConfigFragment
      }
      success
    }
  }
`;

export const ChangeStatusCheckupConfig = gql`
  ${CHECKUP_CONFIG_FRAGMENT}
  mutation ChangeStatusCheckupConfig(
    $id: String!
    $body: BodyChangeStatusCheckupConfig!
  ) {
    changeStatusCheckupConfig(id: $id, body: $body)
      @rest(
        type: "UpdateCheckupConfig"
        path: "/checkups/client/checkup_configs/{args.id}/change_status"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "CheckupConfig") {
        ...CheckupConfigFragment
      }
      success
    }
  }
`;

export const DeleteCheckupConfig = gql`
  mutation DeleteCheckupConfig($id: String!) {
    deleteCheckupConfig(id: $id)
      @rest(
        type: "DeleteCheckupConfig"
        path: "/checkups/client/checkup_configs/{args.id}"
        method: "DELETE"
      ) {
      data {
        id
      }
      success
    }
  }
`;
