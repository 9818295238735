import gql from 'graphql-tag';

export const CHECKUP_ATTACHMENT_FRAGMENT = gql`
  fragment CheckupAttachmentFragment on CheckupAttachment {
    account @type(name: "Account") {
      email
      first_name
      id
      last_name
    }
    checkup_id
    description
    created_at
    created_by_id
    document_id
    file_name
    file_size
    id
  }
`;
