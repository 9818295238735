import { FlexContainer, GridContainer } from '../../containers';
import { PropsWithChildren } from 'react';
import { Icon } from '../Icon';
import { IconLoader } from '../../shared';
import { Text } from '../Text';
import { Container } from './styles';
import { FormCardViewProps } from './types';
export * from './types';

export function FormCard({
  label,
  icon,
  maxWidth,
  children,
  isLoading,
  className,
}: PropsWithChildren<FormCardViewProps>) {
  return (
    <Container
      className={className}
      maxWidth={maxWidth}
      padding="XL"
      border="main-deep-2"
      background="main-deep-1"
      loaderBackground="main-deep-2"
      loaderForeground="main-deep-1"
    >
      <GridContainer
        gap="20"
        gridTemplateRows="max-content"
        alignItems="center"
      >
        <GridContainer
          gap="32"
          gridTemplateRows="max-content"
          justifyContent="center"
        >
          <Text
            text={label}
            size={32}
            weight="Giant"
            color="main-deep-4"
            align="center"
            uppercase
          />
          <FlexContainer justifyContent="center">
            {isLoading ? (
              <IconLoader size={40} />
            ) : (
              <Icon
                glyph={icon}
                size={40}
                color="main-deep-4"
                fallback={<IconLoader size={40} />}
              />
            )}
          </FlexContainer>
        </GridContainer>
        {children}
      </GridContainer>
    </Container>
  );
}
