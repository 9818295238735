import {
  ContentLoader,
  GridContainer,
  Icon,
  IconLoader,
  Text,
} from '@aid-module/ui';
import { useBalanceController } from './controller';

export function Balance() {
  const { balanceText, loading } = useBalanceController();

  return (
    <GridContainer
      gridTemplateColumns="32px max-content"
      alignItems="center"
      gap="14"
    >
      <Icon glyph="purse" size={32} fallback={<IconLoader size={32} />} />
      <GridContainer alignItems="center" gridTemplateRows="18.2px 13px">
        {loading ? (
          <ContentLoader width="100px" height="18.2px">
            <rect x="0" y="0" rx="3" ry="3" width="100%" height="18.2px" />
          </ContentLoader>
        ) : (
          <Text text={balanceText || '$0'} color="main-deep-4" weight="Bold" />
        )}
        <Text text="Balance" size={10} color="main-deep-3" />
      </GridContainer>
    </GridContainer>
  );
}
