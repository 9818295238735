import { gql } from '@apollo/client';

export const CreateRuleSet = gql`
  mutation CreateRuleSet($body: BodyCreateOrUpdateTransactionRuleSet!) {
    createRuleSet(body: $body)
      @rest(
        type: "CreateRuleSet"
        path: "/tm/rulesets"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "RuleSet") {
        alert_deadline_period
        can_sync
        client_id
        created_at
        deleted
        enabled
        id
        name
        rules @type(name: "Rule") {
          async
          code
          created_at
          deleted
          description
          enabled
          id
          name
          is_for_test
          provider_id
          provider @type(name: "RuleProvider") {
            client_id
            created_at
            enabled
            external
            id
            kind
          }
          ruleset_id
          settings
        }
        rules_expression
        rules_expression_is_valid {
          errors
          success
        }
      }
      success
    }
  }
`;

export const UpdateRuleSet = gql`
  mutation UpdateRuleSet(
    $id: String!
    $body: BodyCreateOrUpdateTransactionRuleSet!
  ) {
    updateRuleSet(id: $id, body: $body)
      @rest(
        type: "UpdateRuleSet"
        path: "/tm/rulesets/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "RuleSet") {
        alert_deadline_period
        can_sync
        client_id
        created_at
        deleted
        enabled
        id
        name
        rules @type(name: "Rule") {
          async
          code
          created_at
          deleted
          description
          enabled
          id
          name
          is_for_test
          provider_id
          rule_importance @type(name: "TransactionRuleImportance") {
            id
            name
            client_id
            code
            created_at
          }
          provider @type(name: "RuleProvider") {
            client_id
            created_at
            enabled
            external
            id
            kind
          }
          ruleset_id
          settings
        }
        rules_expression
        rules_expression_is_valid {
          errors
          success
        }
      }
      success
    }
  }
`;

export const ToggleEnableRuleSet = gql`
  mutation ToggleEnableRuleSet(
    $id: String!
    $type: String!
    $body: BodyCreateOrUpdateTransactionRuleSet!
  ) {
    toggleEnableRuleSet(id: $id, type: $type, body: $body)
      @rest(
        type: "UpdateRuleSet"
        path: "/tm/rulesets/{args.id}/{args.type}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "RuleSet") {
        alert_deadline_period
        can_sync
        client_id
        created_at
        deleted
        enabled
        id
        name
        rules @type(name: "Rule") {
          async
          code
          created_at
          deleted
          description
          enabled
          id
          name
          provider_id
          is_for_test
          provider @type(name: "RuleProvider") {
            client_id
            created_at
            enabled
            external
            id
            kind
          }
          ruleset_id
          settings
        }
        rules_expression
        rules_expression_is_valid {
          errors
          success
        }
      }
      success
    }
  }
`;
