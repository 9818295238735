import { AsyncSelect } from '@aid-module/ui';
import { Profiles } from '@aid-package/api';
import { useProfileSelectController } from './controller';
import { ProfileSelectViewProps } from './types';

export function ProfileSelect(props: ProfileSelectViewProps) {
  const { reduceData, additional } = useProfileSelectController();
  return (
    <AsyncSelect
      {...props}
      placeholder="Select Profiles..."
      label="Link profiles"
      query={Profiles}
      additional={additional}
      reduceData={reduceData}
      isMulti
    />
  );
}
