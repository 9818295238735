import gql from 'graphql-tag';
import {
  CASE_DESCRIPTION_FRAGMENT,
  CASE_ROLE_SHARING_FRAGMENT,
  CASE_SHARING_FRAGMENT,
} from '../../fragment/cm';
import {
  CASE_SECTION_FRAGMENT,
  CASE_SECTION_ITEM_FRAGMENT,
} from '../../fragment/pdf/case/caseSection';

export const AssignCase = gql`
  ${CASE_DESCRIPTION_FRAGMENT}
  mutation AssignCase($id: String!, $body: BodyAssignCase!) {
    assignCase(id: $id, body: $body)
      @rest(
        type: "AssignCase"
        path: "/core/client/cases/{args.id}/assign"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "Case") {
        ...CaseDescriptionFragment
      }
      success
    }
  }
`;

export const CreateCase = gql`
  ${CASE_DESCRIPTION_FRAGMENT}
  mutation CreateCase($body: BodyCreateCase!) {
    createCase(body: $body)
      @rest(
        type: "CreateCase"
        path: "/core/client/cases"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "Case") {
        ...CaseDescriptionFragment
      }
      success
    }
  }
`;

export const ChangeReminder = gql`
  ${CASE_DESCRIPTION_FRAGMENT}
  mutation ChangeReminder($id: String!, $body: BodyChangeReminder!) {
    changeReminder(id: $id, body: $body)
      @rest(
        type: "ChangeReminder"
        path: "/core/client/cases/{args.id}/change_reminder"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "Case") {
        ...CaseDescriptionFragment
      }
      success
    }
  }
`;

export const ChangeState = gql`
  ${CASE_DESCRIPTION_FRAGMENT}
  mutation ChangeState($id: String!, $body: BodyChangeState!) {
    changeState(id: $id, body: $body)
      @rest(
        type: "ChangeReminder"
        path: "/core/client/cases/{args.id}/change_state"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "Case") {
        ...CaseDescriptionFragment
      }
      success
    }
  }
`;

export const SectionChangeState = gql`
  ${CASE_SECTION_FRAGMENT}
  ${CASE_SECTION_ITEM_FRAGMENT}
  mutation SectionChangeState($id: String!, $body: BodySectionChangeState!) {
    sectionChangeState(id: $id, body: $body)
      @rest(
        type: "SectionChangeState"
        path: "/core/client/case_sections/{args.id}/change_state"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "CaseSection") {
        ...CaseSectionFragment
        case_section_items @type(name: "CaseSectionItem") {
          ...CaseSectionItemFragment
        }
      }
      success
    }
  }
`;

export const ShareCase = gql`
  ${CASE_SHARING_FRAGMENT}
  mutation ShareCase($body: BodyShareCase!) {
    shareCase(body: $body)
      @rest(
        type: "ShareCase"
        path: "/core/client/case_sharings"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "CaseSharing") {
        ...CaseSharingFragment
      }
      success
    }
  }
`;

export const DeleteCaseSectionItem = gql`
  ${CASE_SECTION_ITEM_FRAGMENT}
  mutation DeleteCaseSectionItem($id: String!) {
    deleteCaseSectionItem(id: $id)
      @rest(
        type: "DeleteCaseSectionItem"
        path: "/core/client/case_section_items/{args.id}"
        bodyKey: "body"
        method: "DELETE"
      ) {
      data @type(name: "CaseSectionItem") {
        ...CaseSectionItemFragment
      }
      success
    }
  }
`;

export const CreateCaseSectionItem = gql`
  ${CASE_SECTION_ITEM_FRAGMENT}
  mutation CreateCaseSectionItem($body: BodyCreateCaseSectionItem) {
    createCaseSectionItem(body: $body)
      @rest(
        type: "CreateCaseSectionItem"
        path: "/core/client/case_section_items"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "CaseSectionItem") {
        ...CaseSectionItemFragment
      }
      success
    }
  }
`;

export const DeleteSharedCase = gql`
  ${CASE_SHARING_FRAGMENT}
  mutation DeleteSharedCase($id: String!) {
    deleteSharedCase(id: $id)
      @rest(
        type: "DeleteSharedCase"
        path: "/core/client/case_sharings/{args.id}"
        method: "DELETE"
      ) {
      data @type(name: "CaseSharing") {
        ...CaseSharingFragment
      }
      success
    }
  }
`;

export const UpdateCaseSectionItem = gql`
  ${CASE_SECTION_ITEM_FRAGMENT}
  mutation UpdateCaseSectionItem(
    $id: String!
    $body: BodyUpdateCaseSectionItem!
  ) {
    updateCaseSectionItem(id: $id, body: $body)
      @rest(
        type: "UpdateCaseSectionItem"
        path: "/core/client/case_section_items/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "CaseSectionItem") {
        ...CaseSectionItemFragment
      }
      success
    }
  }
`;

export const UpdateCaseSectionItemNAICS = gql`
  ${CASE_SECTION_ITEM_FRAGMENT}
  mutation UpdateCaseSectionItemNAICS(
    $id: String!
    $body: BodyUpdateCaseSectionItemNAICS!
  ) {
    updateCaseSectionItemNAICS(id: $id, body: $body)
      @rest(
        type: "DeleteCaseSectionItemNAICS"
        path: "/core/client/case_section_items/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "CaseSectionItem") {
        ...CaseSectionItemFragment
      }
      success
    }
  }
`;

export const ShareRoleCase = gql`
  ${CASE_ROLE_SHARING_FRAGMENT}
  mutation ShareRoleCase($body: BodyShareRoleCase!) {
    shareRoleCase(body: $body)
      @rest(
        type: "ShareRoleCase"
        path: "/core/client/case_role_sharings"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "CaseRoleSharing") {
        ...CaseRoleSharingFragment
      }
      success
    }
  }
`;

export const CreateCaseSectionTab = gql`
  ${CASE_SECTION_FRAGMENT}
  mutation CreateCaseSectionTab($body: BodyCreateOrUpdateCaseSectionTab!) {
    createCaseSectionTab(body: $body)
      @rest(
        type: "CreateCaseSectionTab"
        path: "/core/client/case_section_tabs"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "CaseSection") {
        ...CaseSectionFragment
      }
      success
    }
  }
`;

export const DeleteShareRoleCase = gql`
  ${CASE_ROLE_SHARING_FRAGMENT}
  mutation DeleteShareRoleCase($id: String!) {
    deleteSharedRoleCase(id: $id)
      @rest(
        type: "DeleteSharedRoleCase"
        path: "/core/client/case_role_sharings/{args.id}"
        method: "DELETE"
      ) {
      data @type(name: "CaseRoleSharing") {
        ...CaseRoleSharingFragment
      }
      success
    }
  }
`;

export const UpdateCaseSectionTab = gql`
  ${CASE_SECTION_FRAGMENT}
  mutation UpdateCaseSectionTab(
    $id: String!
    $body: BodyCreateOrUpdateCaseSectionTab!
  ) {
    updateCaseSectionTab(id: $id, body: $body)
      @rest(
        type: "UpdateCaseSectionTab"
        path: "/core/client/case_section_tabs/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "CaseSection") {
        ...CaseSectionFragment
      }
      success
    }
  }
`;

export const UploadCaseSectionDocument = gql`
  ${CASE_SECTION_FRAGMENT}
  mutation UploadCaseSectionDocument(
    $id: String!
    $body: BodyUploadCaseSectionDocument!
  ) {
    uploadCaseSectionDocument(id: $id, body: $body)
      @rest(
        type: "UploadCaseSectionDocument"
        path: "/core/client/case_sections/{args.id}/upload_document"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "CaseSection") {
        ...CaseSectionFragment
      }
      success
    }
  }
`;

export const LinkToProfile = gql`
  mutation LinkToProfile($id: String!, $body: BodyLinkOrUnlinkProfile!) {
    linkToProfile(id: $id, body: $body)
      @rest(
        type: "LinkToProfile"
        path: "/core/client/cases/{args.id}/link_to_profile"
        bodyKey: "body"
        method: "PUT"
      ) {
      success
    }
  }
`;

export const UnlinkFromProfile = gql`
  mutation UnlinkFromProfile($id: String!, $body: BodyLinkOrUnlinkProfile!) {
    unlinkFromProfile(id: $id, body: $body)
      @rest(
        type: "UnlinkFromProfile"
        path: "/core/client/cases/{args.id}/unlink_from_profile"
        bodyKey: "body"
        method: "PUT"
      ) {
      success
    }
  }
`;

export const ChangeWhitelisted = gql`
  ${CASE_DESCRIPTION_FRAGMENT}
  mutation ChangeWhitelisted($id: String!, $body: BodyChangeWhitelisted!) {
    changeWhitelisted(id: $id, body: $body)
      @rest(
        type: "ChangeWhitelisted"
        path: "/core/client/cases/{args.id}/change_is_whitelisted"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "Case") {
        ...CaseDescriptionFragment
      }
      success
    }
  }
`;

export const FailQuestionnaireItem = gql`
  ${CASE_SECTION_FRAGMENT}
  mutation FailQuestionnaireItem(
    $id: String!
    $body: BodyFailOrUnfailQuestionnaireItem!
  ) {
    failQuestionnaireItem(id: $id, body: $body)
      @rest(
        type: "FailQuestionnaireItem"
        path: "/core/client/case_sections/{args.id}/fail_questionnaire_item"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "CaseSection") {
        ...CaseSectionFragment
        failed_questionnaire_items @type(name: "FailedQuestionnaireItem") {
          id
          field_submission_id
        }
      }
      success
    }
  }
`;

export const UnfailQuestionnaireItem = gql`
  ${CASE_SECTION_FRAGMENT}
  mutation UnfailQuestionnaireItem(
    $id: String!
    $body: BodyFailOrUnfailQuestionnaireItem!
  ) {
    unfailQuestionnaireItem(id: $id, body: $body)
      @rest(
        type: "UnfailQuestionnaireItem"
        path: "/core/client/case_sections/{args.id}/unfail_questionnaire_item"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "CaseSection") {
        ...CaseSectionFragment
        failed_questionnaire_items @type(name: "FailedQuestionnaireItem") {
          id
          field_submission_id
        }
      }
      success
    }
  }
`;

export const CreateCaseCustomStepActionEvaluation = gql`
  mutation CreateCaseCustomStepActionEvaluation(
    $body: BodyCreateCaseCustomStepActionEvaluation!
  ) {
    createCaseCustomStepActionEvaluation(body: $body)
      @rest(
        type: "CreateCaseCustomStepActionEvaluationResponse"
        path: "/core/client/case_custom_step_action_evaluations"
        bodyKey: "body"
        method: "POST"
      ) {
      data
      success
      errors
    }
  }
`;
