import { gql } from '@apollo/client';

export const AddPPFincenList = gql`
  mutation AddPPFincenList($body: BodyAddPPFincenList!) {
    addPPFincenList(body: $body)
      @rest(
        type: "AddPPFincenList"
        path: "/pp/fincen/lists"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "PPFincenList") {
        id
        created_at
        created_by @type(name: "Account") {
          email
          first_name
          id
          last_name
        }
        fincen_business_records_count
        fincen_personal_records_count
        kind
        last_mass_screened_at
        last_screened_at
        last_screened_by @type(name: "Account") {
          email
          first_name
          id
          last_name
        }
        last_screened_by_id
        last_screened_mass_screening_id
        uploaded_document_id
      }
      success
    }
  }
`;

export const RunPPMassScreening = gql`
  mutation RunPPMassScreening($body: BodyRunPPMassScreening!) {
    runPPMassScreening(body: $body)
      @rest(
        type: "RunPPMassScreening"
        path: "/pp/local_mass_screenings"
        bodyKey: "body"
        method: "POST"
      ) {
      success
    }
  }
`;
