import { GridContainer } from '../../../../../../../containers';
import { ContentLoader } from '../../../../../../../shared';
import { AdditionalTemplate, FilterTemplate } from './components';
import { FilterTemplateViewProps } from './components/FilterTemplate/types';
import { useFilterTemplatesController } from './controller';
import { FilterTemplatesViewProps } from './types';

function renderTemplate(template: FilterTemplateViewProps['filterTemplate']) {
  return <FilterTemplate key={template.id} filterTemplate={template} />;
}

export function FilterTemplates({ showFilters }: FilterTemplatesViewProps) {
  const { isFilterTemplatesExist, loading, renderTemplates } =
    useFilterTemplatesController();

  if (!isFilterTemplatesExist || showFilters) {
    return null;
  }

  if (loading) {
    return (
      <ContentLoader width="100%" height="32.2px">
        <rect x="0" y="0" rx="3" ry="3" width="100%" height="32.2px" />
      </ContentLoader>
    );
  }

  if (!renderTemplates.favorites.length && !renderTemplates.templates.length) {
    return null;
  }

  return (
    <GridContainer
      gridTemplateColumns="repeat(auto-fit, minmax(0px, max-content))"
      gap="8"
    >
      {renderTemplates.favorites.map(renderTemplate)}
      {renderTemplates.templates.length ? (
        <AdditionalTemplate templates={renderTemplates.templates} />
      ) : null}
    </GridContainer>
  );
}
