import { useMemo } from 'react';
import { ExtendAccountType } from '../../../../ProfileCard/types';
import { ContactsViewProps } from '../types';

export function useContactsController<T extends ExtendAccountType>(
  account: ContactsViewProps<T>['account']
) {
  const email = useMemo(() => {
    if (
      account.__typename === 'Account' ||
      account.__typename === 'Profile' ||
      account.__typename === 'Customer'
    ) {
      return account.email;
    } else if (account.__typename === 'User') {
      return account.account.email;
    }
    return;
  }, [account]);

  const phone = useMemo(() => {
    if (
      account.__typename === 'Account' ||
      account.__typename === 'Profile' ||
      account.__typename === 'Customer'
    ) {
      return account.phone;
    } else if (account.__typename === 'User') {
      return account.account.phone;
    }
    return;
  }, [account]);

  return { email, phone, id: account.id };
}
