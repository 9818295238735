import { dayjs } from '@aid-module/ui';

export const years = Array.from({ length: 100 }, (_, index) => {
  const year = dayjs().subtract(index, 'years').year().toString();

  return {
    label: year,
    value: year,
  };
});
