import gql from 'graphql-tag';
import { USER_FRAGMENT } from '../../../fragment/account/users';

export const UserRolesUpdate = gql`
  ${USER_FRAGMENT}
  mutation UserRolesUpdate($id: String!, $body: BodyUpdateUserRoles!) {
    updateUserRoles(id: $id, body: $body)
      @rest(
        type: "UserRolesUpdate"
        path: "/core/client/accounts_clients/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "User") {
        ...UserFragment
        roles @type(name: "Role") {
          id
          name
          permissions_count
          client_id
          created_at
          updated_at
        }
      }
      success
    }
  }
`;

export const ToggleEnabledUser = gql`
  ${USER_FRAGMENT}
  mutation ToggleEnabledUser($id: String!, $body: BodyToggleEnabledUser!) {
    toogleEnabledUser(id: $id, body: $body)
      @rest(
        type: "ToggleEnabledUser"
        path: "/core/client/accounts_clients/{args.id}/change_enabled"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "User") {
        ...UserFragment
        roles @type(name: "Role") {
          id
          name
          permissions_count
          client_id
          created_at
          updated_at
        }
      }
      success
    }
  }
`;
