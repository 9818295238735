import { PrimaryButton, SecondaryButton } from '../../../../../../../../../../../../../../Button';
import { useActionsController } from './controller';
import { ButtonContainer } from './styles';

export function Actions() {
  const { onClickClose, onClickSubmit, disabled } = useActionsController();
  return (
    <ButtonContainer gridTemplateColumns="repeat(2, 265px)" gap="14">
      <SecondaryButton icon="cross" text="Cancel" onClick={onClickClose} />
      <PrimaryButton
        icon="checkmark"
        text="Apply"
        onClick={onClickSubmit}
        disabled={disabled}
      />
    </ButtonContainer>
  );
}
