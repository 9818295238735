import {
  Notifications,
  useNotificationReadAllMutation,
} from '@aid-package/api';

export function useReadAllModel() {
  const [mutation, { loading }] = useNotificationReadAllMutation({
    refetchQueries: [Notifications],
  });

  return { readAll: mutation, loading };
}
