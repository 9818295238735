import gql from 'graphql-tag';

export const ChangeStateRequiredAction = gql`
  mutation ChangeStateRequiredAction(
    $id: String!
    $body: ChangeStateRequiredActionBody!
  ) {
    changeStateRequiredAction(id: $id, body: $body)
      @rest(
        type: "ChangeStateRequiredAction"
        path: "/core/client/required_actions/{args.id}/change_state"
        bodyKey: "body"
        method: "PATCH"
      ) {
      data @type(name: "RequiredActionItem") {
        id
        case_id
        state
        meta
        author @type(name: "Account") {
          avatar_id
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        required_action_source @type(name: "RequiredActionSource") {
          id
          code
          created_at
          deleted_at
          description
          name
          system
        }
      }
      success
    }
  }
`;

export const CreateRequiredAction = gql`
  mutation CreateRequiredAction($body: CreateRequiredActionBody!) {
    createRequiredAction(body: $body)
      @rest(
        type: "CreateRequiredAction"
        path: "/core/client/required_actions"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "RequiredActionItem") {
        id
        case_id
        state
        author @type(name: "Account") {
          avatar_id
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        meta
        required_action_source @type(name: "RequiredActionSource") {
          id
          code
          created_at
          deleted_at
          description
          name
          system
        }
      }
      success
    }
  }
`;
