import { useCallback } from 'react';
import { useNavigationContext } from '../../../../../../../context';

export function useUnionController() {
  const { navigation, navigateTo, toggleShowFilters } = useNavigationContext();

  const navigateToFilters = useCallback(() => {
    navigateTo({ type: 'filters' });
  }, []);

  const navigateToFilterTemplates = useCallback(() => {
    navigateTo({ type: 'filterTemplates' });
  }, []);

  return {
    type: navigation.type,
    navigateToFilters,
    navigateToFilterTemplates,
    toggleShowFilters,
  };
}
