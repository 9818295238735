import { useMemo, useRef } from 'react';
import { SvgViewProps } from '../types';

export function useSvgController(client: SvgViewProps['client']) {
  const parseCharacter = useRef((string: string) => {
    return string.toUpperCase();
  });

  const getName = useRef((array: string[]) => {
    let name = '';
    if (array.length === 1) {
      const first = array[0];
      return parseCharacter.current(first.slice(0, 2));
    }
    for (let i = 0, count = array.length; i < count; i++) {
      if (name.length === 2) {
        break;
      }
      const string = array[i];
      if (string[0]) {
        name += string[0].toUpperCase();
        continue;
      }
      continue;
    }
    return name.trim() || '?';
  });

  const symbols = useMemo(() => {
    if (client && client.name) {
      const splitName = client.name.split(' ');
      return getName.current(splitName);
    }
    return '?';
  }, [client]);

  const options = useMemo(
    () => ({
      size: 40,
      fontSize: 20,
      border: 6,
    }),
    []
  );

  return { symbols, options };
}
