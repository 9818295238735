import gql from 'graphql-tag';
import { PROVIDER_FRAGMENT } from '../providers';

export const CLIENT_PROVIDER_FRAGMENT = gql`
  ${PROVIDER_FRAGMENT}
  fragment ClientProviderFragment on ClientProvider {
    provider @type(name: "Provider") {
      ...ProviderFragment
    }
    enabled
    id
  }
`;
