import { gql } from '@apollo/client';

export const TransactionFilterGroup = gql`
  query TransactionFilterGroup($id: String) {
    transactionFilterGroup(id: $id)
    @rest(
      type: "ResponseTransactionFilterGroup"
      path: "/tm/filter/groups/{args.id}"
    ) {
      data
      success
    }
  }
`;