import gql from 'graphql-tag';

export const ROLE_PERMISSIONS_FRAGMENT = gql`
  fragment RolePermissionsFragment on Role {
    id
    name
    permissions_count
    accounts_count
    client_id
    created_at
    updated_at
    permissions @type(name: "Permission") {
      code
      created_at
      description
      id
      name
      updated_at
    }
  }
`;
