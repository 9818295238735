import { gql } from '@apollo/client';

export const TransactionDispositions = gql`
  query TransactionDispositions(
    $order_by_created_at: String
    $alert_id: String
    $page: Int
    $per: Int
    $q: String
  ) {
    transactionDispositions(
      order_by_created_at: $order_by_created_at
      alert_id: $alert_id
      page: $page
      per: $per
      q: $q
    )
      @rest(
        type: "ResponseTransactionDispositions"
        path: "/tm/dispositions?{args}"
      ) {
      data @type(name: "TransactionDisposition") {
        id
        name
        created_at
        updated_at
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;
