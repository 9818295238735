import { useCallback } from 'react';
import { VariablesStateParams } from '../../../../../../../../../../context/controller/types';
import { useVariablesContext } from '../../../../../../../../../../context';

export function useFormModel() {
  const { variables, onChangeVariables } = useVariablesContext();

  const clear = useCallback(() => {
    onChangeVariables((prev) => ({
      ...prev,
      filters: undefined,
    }));
  }, [onChangeVariables]);

  const set = useCallback(
    (filters: VariablesStateParams['filters']) => {
      if (filters) {
        const parseFilters = Object.entries(filters).reduce((acc, item) => {
          const [key, value] = item;
          if (!value) {
            return acc;
          }
          if (Array.isArray(value) && !value.length) {
            return acc;
          }
          return {
            ...acc,
            [key]: value,
          };
        }, {});
        return onChangeVariables((prev) => ({
          ...prev,
          filters: parseFilters,
        }));
      }

      return onChangeVariables((prev) => ({
        ...prev,
        filters,
      }));
    },
    [onChangeVariables]
  );

  return { clear, set, variables };
}
