import { gql } from '@apollo/client';
import { TRANSACTION_TAG_FRAGMENT } from '../../../fragment/tm/TransactionTag';

export const CreateTransactionTag = gql`
  ${TRANSACTION_TAG_FRAGMENT}
  mutation CreateTransactionTag($body: BodyCreateOrUpdateTransactionTag!) {
    createTransactionTag(body: $body)
      @rest(
        type: "UpdateTransactionCase"
        path: "/tm/tags"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "TransactionTag") {
        ...TransactionTagFragment
      }
      success
      errors {
        name
      }
    }
  }
`;

export const UpdateTransactionTag = gql`
  ${TRANSACTION_TAG_FRAGMENT}
  mutation UpdateTransactionTag(
    $id: String!
    $body: BodyCreateOrUpdateTransactionTag!
  ) {
    updateTransactionTag(id: $id, body: $body)
      @rest(
        type: "UpdateTransactionCase"
        path: "/tm/tags/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "TransactionTag") {
        ...TransactionTagFragment
      }
      success
      errors {
        name
      }
    }
  }
`;

export const DeleteTransactionTag = gql`
  mutation DeleteTransactionTag($id: String!) {
    deleteTransactionTag(id: $id)
      @rest(
        type: "DeleteTransactionTag"
        path: "/tm/tags/{args.id}"
        method: "DELETE"
      ) {
      data {
        id
      }
      success
    }
  }
`;
