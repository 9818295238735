import { GridContainer } from '../../../../../../../../../containers';
import { Text } from '../../../../../../../../Text';
import { Icon } from '../../../../../../../../Icon';
import { Container } from './styles';
import { Form } from './components';
import { useDefaultFiltersController } from './controller';

export function DefaultFilters() {
  const { toggleShowFilters } = useDefaultFiltersController();
  return (
    <Container
      border="main-deep-1"
      background="main-deep-1"
      loaderForeground="main-deep-1"
      loaderBackground="main-deep-2"
    >
      <GridContainer
        gridTemplateColumns="1fr"
        gridTemplateRows="max-content"
        gap="20"
      >
        <GridContainer gridTemplateColumns="1fr max-content" gap="20">
          <Text text={'Filters'} size={14} weight={'Bold'} />
          <Icon glyph="cross" color="main-deep-4" onClick={toggleShowFilters} />
        </GridContainer>
        <Form />
      </GridContainer>
    </Container>
  );
}
