import { PropsWithChildren } from 'react';
import { SideWidgenProvider } from '../../SideWidget';
import { CommentContextProvider } from './components/Comments';

export function CommentProvider({ children }: PropsWithChildren) {
  return (
    <SideWidgenProvider>
      <CommentContextProvider>{children}</CommentContextProvider>
    </SideWidgenProvider>
  );
}
