import { gql } from '@apollo/client';

export const CreateNarrativeTemplate = gql`
  mutation CreateNarrativeTemplate(
    $body: BodyCreateOrUpdateNarrativeTemplate!
  ) {
    createNarrativeTemplate(body: $body)
      @rest(
        type: "CreateNarrativeTemplate"
        path: "/tm/workflow/narrative_templates"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "NarrativeTemplate") {
        id
        created_at
        kind
        name
        show_in_all_queues
        text
        updated_at
        workflow_queues @type(name: "WorkflowQueue") {
          id
          name
        }
      }
      success
    }
  }
`;

export const UpdateNarrativeTemplate = gql`
  mutation UpdateNarrativeTemplate(
    $id: String!
    $body: BodyCreateOrUpdateNarrativeTemplate!
  ) {
    updateNarrativeTemplate(id: $id, body: $body)
      @rest(
        type: "UpdateNarrativeTemplate"
        path: "/tm/workflow/narrative_templates/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "NarrativeTemplate") {
        id
        created_at
        kind
        name
        show_in_all_queues
        text
        updated_at
        workflow_queues @type(name: "WorkflowQueue") {
          id
          name
        }
      }
      success
    }
  }
`;

export const DeleteNarrativeTemplate = gql`
  mutation DeleteNarrativeTemplate($id: String!) {
    deleteNarrativeTemplate(id: $id)
      @rest(
        type: "DeleteNarrativeTemplate"
        path: "/tm/workflow/narrative_templates/{args.id}"
        method: "DELETE"
      ) {
      data @type(name: "NarrativeTemplate") {
        id
        created_at
        kind
        name
        show_in_all_queues
        text
        updated_at
        workflow_queues @type(name: "WorkflowQueue") {
          id
          name
        }
      }
      success
    }
  }
`;

export const CreateNarrative = gql`
  mutation CreateNarrative($body: BodyCreateOrUpdateNarrative!) {
    createNarrative(body: $body)
      @rest(
        type: "CreateNarrative"
        path: "/tm/workflow/narratives"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "Narrative") {
        id
        created_at
        created_by @type(name: "Account") {
          email
          first_name
          id
          last_name
          avatar_id
        }
        state
        subject_id
        subject_type
        text
        updated_at
        workflow_narrative_template
      }
      success
    }
  }
`;

export const UpdateNarrative = gql`
  mutation UpdateNarrative($id: String!, $body: BodyCreateOrUpdateNarrative!) {
    updateNarrative(id: $id, body: $body)
      @rest(
        type: "UpdateNarrative"
        path: "/tm/workflow/narratives/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "Narrative") {
        id
        created_at
        created_by @type(name: "Account") {
          email
          first_name
          id
          last_name
          avatar_id
        }
        state
        subject_id
        subject_type
        text
        updated_at
        workflow_narrative_template
      }
      success
    }
  }
`;

export const ChangeNarrativeState = gql`
  mutation ChangeNarrativeState(
    $id: String!
    $body: BodyChangeNarrativeState!
  ) {
    changeNarrativeState(id: $id, body: $body)
      @rest(
        type: "ChangeNarrativeState"
        path: "/tm/workflow/narratives/{args.id}/change_state"
        bodyKey: "body"
        method: "PATCH"
      ) {
      data @type(name: "Narrative") {
        id
        created_at
        created_by @type(name: "Account") {
          email
          first_name
          id
          last_name
          avatar_id
        }
        state
        subject_id
        subject_type
        text
        updated_at
        workflow_narrative_template
      }
      success
    }
  }
`;

export const DeleteNarrative = gql`
  mutation DeleteNarrative($id: String!) {
    deleteNarrative(id: $id)
      @rest(
        type: "DeleteNarrativeTemplate"
        path: "/tm/workflow/narratives/{args.id}"
        method: "DELETE"
      ) {
      data @type(name: "Narrative") {
        id
        created_at
        created_by @type(name: "Account") {
          email
          first_name
          id
          last_name
          avatar_id
        }
        state
        subject_id
        subject_type
        text
        updated_at
        workflow_narrative_template
      }
      success
    }
  }
`;
