import { TYPES } from '@aid-package/api';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  CASE_DISPLAY_STATUSES,
  SelectOption,
  SingleValue,
  VERIFICATION_DISPLAY_STATUSES,
} from '@aid-module/ui';

export function useAccountSelectController() {
  const { reset } = useFormContext();
  const reduceData = useCallback(
    (
      template: TYPES.SubmissionsStatsPresetsQuery['submissionsStatsPresets']['data'][0]
    ) => ({
      label: template?.name || '',
      value: template?.id || '',
      item: template,
    }),
    []
  );

  const additional = useMemo(
    () => ({
      is_template: true,
    }),
    []
  );

  const handlerChange = useCallback(
    (value: SingleValue<SelectOption>) => {
      const caseStatuses = value?.item?.case_statuses?.map((item: string) => ({
        label: CASE_DISPLAY_STATUSES[item],
        value: item,
      }));

      const submissionStatuses = value?.item?.submission_statuses?.map(
        (item: string) => ({
          label: VERIFICATION_DISPLAY_STATUSES[item],
          value: item,
        })
      );

      const tariffNames = value?.item?.bank_tariff_names?.map(
        (item: string) => ({
          label: item,
          value: item,
        })
      );

      const formOption = {
        label: value?.item.form_configuration_name,
        value: value?.item.form_configuration_reference_key,
        id: value?.item.form_configuration_id,
        default: true,
      };

      const formValues = {
        name: value?.item.name,
        date_to: value?.item.date_to,
        date_from: value?.item.date_from,
        score_from: value?.item.score_from,
        score_to: value?.item.score_to,
        case_statuses: caseStatuses,
        submission_statuses: submissionStatuses,
        bank_tariff_names: tariffNames,
        reference_key: value ? formOption : null,
        form_field_reference_keys: value?.item.form_field_reference_keys,
      };

      reset(formValues);
    },
    [reset]
  );

  return {
    reduceData,
    additional,
    handlerChange,
  };
}
