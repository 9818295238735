import { Container, Title } from './styles';
import { TitleLinkViewProps } from './types';

export function TitleLink({ text, to }: TitleLinkViewProps) {
  return (
    <Container>
      <Title text={text} size={9} lineHeight="9px" weight="Bold" />
    </Container>
  );
}
