import gql from 'graphql-tag';
import { IDENFY_ATTACHMENT_FRAGMENT } from '../IdenfyAttachment';
import { IDENFY_RAW_RESULT_FULL_FRAGMENT } from '../IdenfyRawResultFull';

export const IDENFY_RESULT_FRAGMENT = gql`
  ${IDENFY_ATTACHMENT_FRAGMENT}
  ${IDENFY_RAW_RESULT_FULL_FRAGMENT}
  fragment IdenfyResultFragment on IdenfyResult {
    attachments @type(name: "IdenfyAttachment") {
      ...IdenfyAttachmentFragment
    }
    created_at
    id
    raw_result_full @type(name: "IdenfyRawResultFull") {
      ...IdenfyRawResultFullFragment
    }
    raw_result_short @type(name: "IdenfyRawResultShort") {
      clientId
      scanRef
      status
    }
    scan_ref
    status
    updated_at
    verification_step_passing_id
  }
`;
