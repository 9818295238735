import { gql } from '@apollo/client';

export const TransactionScoringScoreRules = gql`
  query TransactionScoringScoreRules(
    $per: Int
    $page: Int
    $scoring_matrix_id: String!
  ) {
    transactionScoringScoreRules(
      per: $per
      page: $page
      scoring_matrix_id: $scoring_matrix_id
    )
      @rest(
        type: "ResponseTransactionScoringScoreRules"
        path: "/tm/scoring/score_rules?{args}"
      ) {
      data @type(name: "TransactionScoringScoreRule") {
        id
        client_id
        created_at
        params {
          less_than
          greater_than
          equal_to
          condition
          color
        }
        rfi_suggested
        scoring_matrix_id
        supporting_docs_required
        transaction_stopped
        updated_at
      }
      meta {
        count
        page
        items
        pages
      }
      success
    }
  }
`;
