import gql from 'graphql-tag';

export const StopWords = gql`
  query StopWords($page: Int, $q: String) {
    stopWords(page: $page, per: 20, q: $q)
      @rest(type: "ResponseStopWords", path: "/pp/stop_words?{args}") {
      data @type(name: "StopWord") {
        id
        created_at
        name
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const StopWord = gql`
  query StopWord($id: String!) {
    stopWord(id: $id)
      @rest(type: "ResponseStopWord", path: "/pp/stop_words/{args.id}") {
      data @type(name: "StopWord") {
        id
        created_at
        name
      }
      success
    }
  }
`;
