import gql from 'graphql-tag';
import { CHECKUP_KIND_FRAGMENT } from '../../../fragment/admin/checkupKinds';

export const CheckupKinds = gql`
  ${CHECKUP_KIND_FRAGMENT}
  query CheckupKinds($page: Int, $q: String) {
    checkupKinds(page: $page, per: 20, q: $q)
      @rest(
        type: "ResponseCheckupKinds"
        path: "/checkups/admin/checkup_kinds?{args}"
      ) {
      data @type(name: "CheckupKind") {
        ...CheckupKindFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const CheckupKind = gql`
  ${CHECKUP_KIND_FRAGMENT}
  query CheckupKind($id: String!) {
    checkupKind(id: $id)
      @rest(
        type: "ResponseCheckupKind"
        path: "/checkups/admin/checkup_kinds/{args.id}"
      ) {
      data @type(name: "CheckupKind") {
        ...CheckupKindFragment
      }
      success
    }
  }
`;
