import { getColor } from '@aid-module/ui';
import styled from '@emotion/styled';
import { OnlineLightViewProps } from './types';

export const Light = styled.div`
  width: 16px;
  height: 16px;
  background-color: ${({ color }: OnlineLightViewProps) => getColor(color)};
  border-radius: 50%;
  animation-name: light;
  animation-timing-function: linear;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  
  @keyframes light {
    50% {
      opacity: 0;
    }
  }
`;
