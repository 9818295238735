import { Text } from '../../../../Text';
import { Icon } from '../../../../Icon';
import { SecondaryButton } from '../../../../Button';
import { ContentContainer } from './styles';
import { useBulkContainer } from './controller';
import { DefaultBulkComponentViewProps } from './types';

export function DefaultBulkComponent({
  onClick,
  title,
}: DefaultBulkComponentViewProps) {
  const { isSelected, selectedCount, clean } = useBulkContainer();

  if (!isSelected) {
    return null;
  }

  return (
    <ContentContainer
      gridTemplateColumns="repeat(3, max-content)"
      alignItems="center"
      gap="12"
    >
      <Text
        text={`${selectedCount} selected`}
        weight="Medium"
        size={14}
        color="white-primary"
      />
      <SecondaryButton
        icon="pencil"
        text={title || 'Edit items'}
        onClick={onClick}
      />
      <Icon glyph="cross" color="white-primary" size={16} onClick={clean} />
    </ContentContainer>
  );
}
