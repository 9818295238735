import { ProviderDelegate } from './types';

export class LocalStorageProvider implements ProviderDelegate {
  static SAVED_KEY = 'saved_columns';

  private saved: string[] | undefined;
  private key: string;

  constructor(key: string) {
    this.key = key;
    const storage = localStorage.getItem(
      `${LocalStorageProvider.SAVED_KEY}_${key}`
    );
    if (storage) {
      this.saved = JSON.parse(storage);
    }
  }

  saveValueByKey(next: string[] | undefined): void {
    localStorage.setItem(
      `${LocalStorageProvider.SAVED_KEY}_${this.key}`,
      JSON.stringify(next)
    );
    this.saved = next;
  }

  getSavedByKey(): string[] | undefined {
    return this.saved;
  }

  static init(key: string) {
    return new LocalStorageProvider(key);
  }
}
