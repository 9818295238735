import gql from 'graphql-tag';

export const RfiIndividual = gql`
  query RfiIndividual(
    $role_ids: [String!]
    $account_ids: [String!]
    $form_configuration_ids: [String!]
    $form_configuration_reference_keys: [String!]
    $date_from: String
    $date_to: String
  ) {
    rfiIndividual(
      role_ids: $role_ids
      account_ids: $account_ids
      form_configuration_ids: $form_configuration_ids
      form_configuration_reference_keys: $form_configuration_reference_keys
      date_from: $date_from
      date_to: $date_to
    )
      @rest(
        type: "ResponseApprovalIndividual"
        path: "/stats/client/rfi/individual?{args}"
      ) {
      data {
        individual_response_speed
        individual_rfi_rate
      }
      success
    }
  }
`;

export const RfiGeneral = gql`
  query RfiGeneral(
    $form_configuration_ids: [String!]
    $form_configuration_reference_keys: [String!]
    $date_from: String
    $date_to: String
  ) {
    rfiGeneral(
      form_configuration_ids: $form_configuration_ids
      form_configuration_reference_keys: $form_configuration_reference_keys
      date_from: $date_from
      date_to: $date_to
    )
      @rest(
        type: "ResponseApprovalGeneral"
        path: "/stats/client/rfi/general?{args}"
      ) {
      data {
        total_rfi
        total_rfi_rate
      }
      success
    }
  }
`;
