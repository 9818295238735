import { Navigate, Routes, Route, useParams } from 'react-router-dom';
import { ErrorPages } from '@aid-module/ui';

function Redirect({
  getUrl,
}: {
  getUrl: (params: Readonly<Record<string, string | undefined>>) => string;
}) {
  const params = useParams();
  return <Navigate to={getUrl(params)} replace />;
}

export function RedirectFromOldUrls() {
  const redirectPaths = [
    {
      path: '',
      getUrl: (params: Readonly<Record<string, string | undefined>>): string =>
        `/${params['client_id']}/profile`,
    },
    {
      path: '/management/balance',
      getUrl: (params: Readonly<Record<string, string | undefined>>): string =>
        `/${params['client_id']}/account/balance`,
    },
    {
      path: '/management/balance/form',
      getUrl: (params: Readonly<Record<string, string | undefined>>): string =>
        `/${params['client_id']}/account/balance/form`,
    },
    {
      path: '/instant_checkups/clients_checkups/form/:id',
      getUrl: (params: Readonly<Record<string, string | undefined>>): string =>
        `/${params['client_id']}/checkup/instant_checkups/all/form/${params['id']}`,
    },
    {
      path: '/instant_checkups/clients_checkups/form/:id/info/:hit_id',
      getUrl: (params: Readonly<Record<string, string | undefined>>): string =>
        `/${params['client_id']}/checkup/instant_checkups/all/form/${params['id']}/info/${params['hit_id']}`,
    },
    {
      path: '/instant_checkups/comply_advantage_aml/form/:id',
      getUrl: (params: Readonly<Record<string, string | undefined>>): string =>
        `/${params['client_id']}/checkup/instant_checkups/comply_advantage_aml/form/${params['id']}`,
    },
    {
      path: '/instant_checkups/comply_advantage_aml/:id/info/:hit_id',
      getUrl: (params: Readonly<Record<string, string | undefined>>): string =>
        `/${params['client_id']}/checkup/instant_checkups/comply_advantage_aml/form/${params['id']}/info/${params['hit_id']}`,
    },
    {
      path: '/instant_checkups/comply_advantage_aml_business/form/:id',
      getUrl: (params: Readonly<Record<string, string | undefined>>): string =>
        `/${params['client_id']}/checkup/instant_checkups/comply_advantage_aml_business/form/${params['id']}`,
    },
    {
      path: '/instant_checkups/comply_advantage_aml_business/form/:id/info/:hit_id',
      getUrl: (params: Readonly<Record<string, string | undefined>>): string =>
        `/${params['client_id']}/checkup/instant_checkups/comply_advantage_aml_business/form/${params['id']}/info/${params['hit_id']}`,
    },
    {
      path: '/tm/monitoring/:id',
      getUrl: (params: Readonly<Record<string, string | undefined>>): string =>
        `/${params['client_id']}/transaction/monitoring/${params['id']}`,
    },
    {
      path: '/tm/cases/:id',
      getUrl: (params: Readonly<Record<string, string | undefined>>): string =>
        `/${params['client_id']}/transaction/investigations/form/${params['id']}`,
    },
    {
      path: '/cm/:id',
      getUrl: (params: Readonly<Record<string, string | undefined>>): string =>
        `/${params['client_id']}/verification/cases/${params['id']}`,
    },
    {
      path: '/registers/applications/:id',
      getUrl: (params: Readonly<Record<string, string | undefined>>): string =>
        `/${params['client_id']}/verification/verifications/${params['id']}`,
    },
  ];

  return (
    <Routes>
      {redirectPaths.map((it, key) => (
        <Route
          key={key}
          path={it.path}
          element={<Redirect getUrl={it.getUrl} />}
        />
      ))}
      <Route path="*" element={<ErrorPages type="local" code={404} />} />
    </Routes>
  );
}
