import gql from 'graphql-tag';

export const ATTACHMENT_FRAGMENT = gql`
  fragment AttachmentFragment on Attachment {
    id
    client_id
    file_name
    file_size
    kind
    subject_id
    subject_type
    updated_at
    uploaded_document_id
  }
`;
