import { GridContainer } from '../../../../../../../../../../../../../containers';
import { Empty } from '../../../../../../../../../../../../Empty';
import { Additional, Favorite } from './components';
import { useFilterTemplatesController } from './controller';

export function FilterTemplates() {
  const { isEmpty } = useFilterTemplatesController();

  if (isEmpty) {
    return (
      <GridContainer gridTemplateColumns="1fr" gridTemplateRows="260px">
        <Empty
          title="NO FILTER TEMPLATES YET"
          subtitle="Save your frequently used filters selection as a filter template."
          type="white"
        />
      </GridContainer>
    );
  }
  return (
    <GridContainer
      gridTemplateColumns="repeat(2, 1fr)"
      gridTemplateRows="260px"
      gap="20"
    >
      <Favorite />
      <Additional />
    </GridContainer>
  );
}
