import {
  DefaultButtonLoader,
  FormCard,
  GridContainer,
  InputLoader,
  MediaLoader,
} from '@aid-module/ui';

export function Fallback() {
  return (
    <GridContainer gridTemplateColumns="510px" justifyContent="center">
      <FormCard label="Create Case" icon="create">
        <InputLoader />
        <InputLoader />
        <InputLoader />
        <InputLoader />
        <InputLoader />
        <InputLoader />
        <MediaLoader />
        <DefaultButtonLoader />
      </FormCard>
    </GridContainer>
  );
}
