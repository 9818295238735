import { gql } from '@apollo/client';

export const TransactionResolutions = gql`
  query TransactionResolutions($page: Int, $q: String) {
    transactionResolutions(page: $page, per: 20, q: $q)
      @rest(
        type: "ResponseTransactionResolutions"
        path: "/tm/resolutions?{args}"
      ) {
      data @type(name: "TransactionResolution") {
        id
        name
        color
        created_at
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;
