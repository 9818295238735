import styled from '@emotion/styled';
import { GridContainer } from '../../../../../../../../../containers';
import { getColor } from '../../../../../../../../../utils';

export const Container = styled(GridContainer)`
  width: 420px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  border: 1px solid ${getColor('main-deep-2')};
  background: ${getColor('white-primary')};
`;

