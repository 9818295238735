import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { IconsCacheContextParams } from './types';
import uniqueId from 'lodash.uniqueid';
import { useIconsCacheContextProviderController } from './controller';

const IconsCacheContext = createContext<IconsCacheContextParams>({
  use() {},
  clear() {},
});

export function useIconsCacheContext(name: string) {
  const options = useRef({ key: Number(uniqueId()), name });
  const [isFirst, setIsFirst] = useState(false);
  const { use, clear } = useContext(IconsCacheContext);

  useEffect(() => {
    use(options.current.name, options.current.key, (next) =>
      setIsFirst(next || false)
    );
    return () => {
      clear(options.current.name, options.current.key);
    };
  }, []);

  return { isFirst };
}

export function IconsCacheContextProvider({ children }: PropsWithChildren<{}>) {
  const value = useIconsCacheContextProviderController();

  return (
    <IconsCacheContext.Provider value={value}>
      {children}
    </IconsCacheContext.Provider>
  );
}
