import { AlertStatusViewProps } from './types';
import { useAlertStatusController } from './controller';
import { Text } from '../Text';
import { Tag } from '../Count';
import { ALERT_STATUSES, ALERT_DISPLAY_STATUSES } from './constants';

function AlertStatus({ status, size = 11 }: AlertStatusViewProps) {
  const { background, text } = useAlertStatusController(status);

  if (text) {
    return <Tag count={text} padding="S" size={size} background={background} />;
  }

  return <Text text="-" size={12} color="main-deep-4" />;
}

export { AlertStatus, ALERT_DISPLAY_STATUSES, ALERT_STATUSES };
