import { GridContainer } from '../../containers';
import { stopPropagation } from '../../utils';
import { ExternalRefLink } from '../ExternalRefLink';
import { Text } from '../Text';
import { useExternalRefsController } from './controller';
import { ExternalRefsViewProps } from './types';
import { ExternalRefList } from './components';

export function ExternalRefs({ externalRefs }: ExternalRefsViewProps) {
  const { count, externalRef, filteredExternalRefs } =
    useExternalRefsController(externalRefs);

  if (externalRefs && externalRef) {
    return (
      <GridContainer
        onClick={stopPropagation}
        gridTemplateColumns="1fr max-content"
        gridTemplateRows="24px"
        gap="6"
        alignItems="center"
      >
        <ExternalRefLink
          weight="Bold"
          size={12}
          withoutIcon
          external_ref={externalRef}
          text={externalRef.external_entity_name}
        />
        <ExternalRefList
          externalRefs={filteredExternalRefs}
          count={count || 0}
        />
      </GridContainer>
    );
  }

  return <Text size={12} text="-" color="main-deep-5" />;
}
