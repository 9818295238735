import gql from 'graphql-tag';

export const PPFincenList = gql`
  query PPFincenList(
    $kind: String
    $with_checkups_counters: Boolean
    $with_statuses: String
    $page: Int
  ) {
    pPFincenList(
      kind: $kind
      with_checkups_counters: $with_checkups_counters
      with_statuses: $with_statuses
      page: $page
      per: 20
    )
      @rest(
        type: "ResponsePPFincenList"
        path: "/pp/fincen/lists?{args}"
      ) {
      data @type(name: "PPFincenList") {
        id
        created_at
        created_by @type(name: "Account") {
          email
          first_name
          id
          last_name
        }
        fincen_business_records_count
        fincen_personal_records_count
        kind
        last_mass_screened_at
        last_screened_at
        last_screened_by @type(name: "Account") {
          email
          first_name
          id
          last_name
        }
        last_screened_by_id
        last_screened_mass_screening_id
        uploaded_document_id
        mass_screenings_count
        no_match_screenings_count
        potential_match_screenings_count
        true_positive_screenings_count
        false_positive_screenings_count
        unknown_screenings_count
      }
      meta {
        count
        page
        items
        pages
      }
      success
    }
  }
`;

export const PPFincenListItem = gql`
  query PPFincenListItem(
    $id: String
  ) {
    pPFincenListItem(
      id: $id
    )
      @rest(
        type: "ResponsePPFincenListItem"
        path: "/pp/fincen/lists/{args.id}"
      ) {
      data @type(name: "PPFincenList") {
        id
        created_at
        created_by @type(name: "Account") {
          email
          first_name
          id
          last_name
        }
        fincen_business_records_count
        fincen_personal_records_count
        kind
        last_mass_screened_at
        last_screened_at
        last_screened_by @type(name: "Account") {
          email
          first_name
          id
          last_name
        }
        last_screened_by_id
        last_screened_mass_screening_id
        uploaded_document_id
        mass_screenings_count
        no_match_screenings_count
        potential_match_screenings_count
        true_positive_screenings_count
        false_positive_screenings_count
        unknown_screenings_count
      }
      success
    }
  }
`;

export const PPFincenBusinessRecords = gql`
  query PPFincenBusinessRecords(
    $fincen_list_id: String!
    $q: String
    $page: Int
  ) {
    pPFincenBusinessRecords(
      fincen_list_id: $fincen_list_id
      q: $q
      page: $page
      per: 20
    )
      @rest(
        type: "ResponsePPFincenBusinessRecords"
        path: "/pp/fincen/business_records?{args}"
      ) {
      data @type(name: "PPFincenBusinessRecord") {
        business_name
        city
        country
        dba_name
        id
        incorporated
        number
        number_type
        phone
        state
        street
        tracking_number
        zip
      }
      meta {
        count
        page
        items
        pages
      }
      success
    }
  }
`;

export const PPFincenPersonalRecords = gql`
  query PPFincenPersonalRecords(
    $fincen_list_id: String!
    $q: String
    $page: Int
  ) {
    pPFincenPersonalRecords(
      fincen_list_id: $fincen_list_id
      q: $q
      page: $page
      per: 20
    )
      @rest(
        type: "ResponsePPFincenPersonalRecords"
        path: "/pp/fincen/personal_records?{args}"
      ) {
      data @type(name: "PPFincenPersonalRecord") {
        alias_first_name
        alias_last_name
        alias_middle_name
        alias_suffix
        city
        country
        dob
        first_name
        id
        last_name
        middle_name
        number
        number_type
        phone
        state
        street
        suffix
        tracking_number
        zip
      }
      meta {
        count
        page
        items
        pages
      }
      success
    }
  }
`;