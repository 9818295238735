import { PropsWithChildren } from 'react';
import { CloseButton } from './components';
import { StyledToastContainer } from './styles';

export function ToastifyProvider({ children }: PropsWithChildren<{}>) {
  return (
    <>
      {children}
      <StyledToastContainer closeButton={CloseButton} />
    </>
  );
}
