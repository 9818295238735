import { useCheckPermission } from '@aid-package/context';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { DashboardsNavigationItems } from '../../../../../../Breadcrumbs/constants';

export function useDashboardLinkController() {
  const { client_id } = useParams();

  const { canShow: canQuickView } = useCheckPermission(
    DashboardsNavigationItems[0].check,
    DashboardsNavigationItems[0].strong || false
  );
  const { canShow: canComplianceView } = useCheckPermission(
    DashboardsNavigationItems[1].check,
    DashboardsNavigationItems[0].strong || false
  );
  const { canShow: canStatistics } = useCheckPermission(
    DashboardsNavigationItems[2].check,
    DashboardsNavigationItems[0].strong || false
  );

  const dashboardAccesiblePath = useMemo(() => {
    const dashboardCanShows = [canQuickView, canComplianceView, canStatistics];
    const firstAccesiblePathIndex = dashboardCanShows.indexOf(true);

    const firstAccesiblePath =
      DashboardsNavigationItems[firstAccesiblePathIndex]?.path;

    return `/${client_id}${firstAccesiblePath}`;
  }, [canQuickView, canComplianceView, canStatistics, client_id]);
  const dashboardsPaths = useMemo(() => {
    return DashboardsNavigationItems.map((item) => `/${client_id}${item.path}`);
  }, [client_id]);

  return {
    dashboardAccesiblePath,
    dashboardsPaths,
  };
}
