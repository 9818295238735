import gql from 'graphql-tag';
import { INSTANT_CHECKUP_FRAGMENT } from '../../../fragment/admin/instantCheckups';

export const InstantCheckups = gql`
  ${INSTANT_CHECKUP_FRAGMENT}
  query InstantCheckups(
    $page: Int
    $q: String
    $price_min: Float
    $price_max: Float
  ) {
    instantCheckups(
      page: $page
      per: 20
      q: $q
      price_min: $price_min
      price_max: $price_max
    )
      @rest(
        type: "ResponseInstantCheckups"
        path: "/core/admin/instant_checkups?{args}"
      ) {
      data @type(name: "InstantCheckup") {
        ...InstantCheckupFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const InstantCheckup = gql`
  ${INSTANT_CHECKUP_FRAGMENT}
  query InstantCheckup($id: String!) {
    instantCheckup(id: $id)
      @rest(
        type: "ResponseInstantCheckup"
        path: "/core/admin/instant_checkups/{args.id}"
      ) {
      data @type(name: "InstantCheckup") {
        ...InstantCheckupFragment
      }
      success
    }
  }
`;
