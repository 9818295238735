import { ToastComponentProps } from '../types';
import { ToastComponent } from '../ToastComponent';
import { PropsWithChildren } from 'react';
import { GridContainer } from '../../../../containers';

import { MoreNotifications } from './components';

export function Notification(props: PropsWithChildren<ToastComponentProps>) {
  return (
    <GridContainer gap="6">
      <ToastComponent icon="notificationBell" color="main-deep-4" {...props} />
      <MoreNotifications callback={props.callback} count={props.count} />
    </GridContainer>
  );
}
