import gql from 'graphql-tag';
import { PROFILE_FRAGMENT } from '../../../fragment/profiles/profiles';

export const Profiles = gql`
  ${PROFILE_FRAGMENT}
  query Profiles(
    $page: Int
    $kind: String
    $created_at_from: String
    $created_at_to: String
    $form_submission_id: String
    $sort_field: String
    $sort_order: String
    $case_id: String
    $with_form_submissions: Boolean
    $retention_plan_id: String
    $q: String
    $per: Int = 20
  ) {
    profiles(
      page: $page
      per: $per
      kind: $kind
      sort_field: $sort_field
      case_id: $case_id
      form_submission_id: $form_submission_id
      sort_order: $sort_order
      created_at_from: $created_at_from
      created_at_to: $created_at_to
      with_form_submissions: $with_form_submissions
      retention_plan_id: $retention_plan_id
      q: $q
    ) @rest(type: "ResponseProfiles", path: "/core/client/profiles?{args}") {
      data @type(name: "Profile") {
        ...ProfileFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const Profile = gql`
  ${PROFILE_FRAGMENT}
  query Profile($id: String!) {
    profile(id: $id)
      @rest(type: "ResponseProfile", path: "/core/client/profiles/{args.id}") {
      data @type(name: "Profile") {
        ...ProfileFragment
        total_score
      }
      success
    }
  }
`;

export const ProfileRelations = gql`
  ${PROFILE_FRAGMENT}
  query ProfileRelations($page: Int, $id: String!) {
    profileRelations(page: $page, per: 20, id: $id)
      @rest(
        type: "ResponseProfileRelations"
        path: "/core/client/profiles/{args.id}/relations?page={args.page}&per={args.per}"
      ) {
      data @type(name: "ProfileRelation") {
        created_at
        id
        kind
        profile @type(name: "Profile") {
          ...ProfileFragment
        }
        profile_id
        related_profile @type(name: "Profile") {
          ...ProfileFragment
        }
        related_profile_id
        updated_at
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;
