import gql from 'graphql-tag';

export const AUDIT_CONFIG_FRAGMENT = gql`
  fragment AuditConfigFragment on AuditConfig {
    audits_count
    auto_create_audit
    checkup_configs_count
    description
    external_source_id
    external_source_ref
    external_source_type
    id
    is_actual_revision
    auto_create_audit
    launch_config @type(name: "AuditLaunchConfig") {
      id
      ongoing_enabled
      ongoing_ends_at
      ongoing_period
      ongoing_starts_at
    }
    name
    reference_key
    updated_at
  }
`;
