import { useCallback, useState } from 'react';
import { useVariablesContext } from '../../../../../../../Table/context';
import { SearchInputViewProps } from '../types';

export function useSearchInputController(
  onChangeValue: SearchInputViewProps['onChangeSearch']
) {
  const { variables } = useVariablesContext();
  const [value, setValue] = useState<string | undefined>(
    typeof variables.q === 'string' ? variables.q : ''
  );

  const onChange = useCallback(
    (value?: string) => {
      setValue(value);
      onChangeValue(value);
    },
    [onChangeValue]
  );

  return { value, onChange };
}
