import { Icon, Text } from '@aid-module/ui';
import { useMenuController } from './controller';
import {
  ContentContainer,
  HeaderContainer,
  ActionContainer,
  ActionContentContainer,
  ActionContentContainerWithPadding,
} from './styles';
import { MenuViewProps } from './types';

export function Menu({ client, onClickItem }: MenuViewProps) {
  const { hrefAccountSettings, onLogout, onNewLogout } =
    useMenuController(onClickItem);
  return (
    <ContentContainer>
      <HeaderContainer>
        <Text text={client.full_name || ''} weight="Bold" uppercase />
      </HeaderContainer>
      <ActionContainer to={hrefAccountSettings} onClick={onClickItem}>
        <ActionContentContainer
          gridTemplateColumns="max-content 1fr"
          gap="10"
          alignItems="center"
        >
          <Icon glyph="settings" color="main-deep-4" />
          <Text text="My Account" weight="Medium" color="main-deep-4" />
        </ActionContentContainer>
      </ActionContainer>
      {!process.env.REACT_APP_NEW_AUTH ? (
        <ActionContainer to="/" onClick={onLogout}>
          <ActionContentContainer
            gridTemplateColumns="max-content 1fr"
            gap="10"
            alignItems="center"
          >
            <Icon glyph="logOut" color="main-deep-4" />
            <Text text="Log Out" weight="Medium" color="main-deep-4" />
          </ActionContentContainer>
        </ActionContainer>
      ) : (
        <ActionContentContainerWithPadding
          gridTemplateColumns="max-content 1fr"
          gap="10"
          onClick={onNewLogout}
          alignItems="center"
        >
          <Icon glyph="logOut" color="main-deep-4" />
          <Text text="Log Out" weight="Medium" color="main-deep-4" />
        </ActionContentContainerWithPadding>
      )}
    </ContentContainer>
  );
}
