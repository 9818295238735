import styled from '@emotion/styled';
import { getColor } from '../../../../../../utils';
import { GridContainer } from '../../../../../../containers';

export const Container = styled(GridContainer)`
  position: absolute;
  top: 5%;
`;

export const SvgPath = styled.path`
  fill: none;
  stroke: ${getColor('main-deep-4')};
  stroke-width: 7.5;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-dashoffset: 175;
`;
