import { Routes, Route, Navigate } from 'react-router-dom';
import { ProtectedRoute, ErrorPages } from '@aid-module/ui';
import { GLOBAL_SEARCH_PERMISSIONS, PERMISSIONS } from '@aid-package/context';
import { SearchDescription, SearchRegister } from './components';

export function SearchRouter() {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<Navigate to="search_entries" replace />} />
        <Route
          path="search_entries"
          element={
            <ProtectedRoute check={[PERMISSIONS.SEE_GLOBAL_SEARCH_HISTORY]} />
          }
        >
          <Route index element={<SearchRegister />} />
        </Route>
        <Route
          path="search_results"
          element={<ProtectedRoute check={GLOBAL_SEARCH_PERMISSIONS} />}
        >
          <Route path=":id" element={<SearchDescription />} />
        </Route>
        <Route path="*" element={<ErrorPages type="local" code={404} />} />
      </Route>
    </Routes>
  );
}
