import styled from '@emotion/styled';
import { GridContainer } from '@aid-module/ui';

export const MainContainer = styled(GridContainer)`
  height: 100%;
`;

export const OnBoardingGridContainer = styled(GridContainer)`
  max-height: 100%;
  overflow: auto;
`
