import { Icon } from '../../../Icon';
import { DefaultExternalLink } from '../DefaultExternalLink';
import { ExternalLinkViewProps } from '../types';

export function ExternalLink({
  size = 14,
  color = 'main-deep-4',
  ...props
}: ExternalLinkViewProps) {
  return (
    <DefaultExternalLink {...props}>
      <Icon glyph="link" size={size} color={color} />
    </DefaultExternalLink>
  );
}
