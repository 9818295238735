import gql from 'graphql-tag';

export const Dictionary = gql`
  query Dictionary(
    $dictionary_name: String!
    $order_col: String!
    $order_dir: String!
    $q: String
  ) {
    dictionary(
      dictionary_name: $dictionary_name
      order_col: $order_col
      order_dir: $order_dir
      q: $q
    )
    @rest(
      type: "ResponseDictionaryItems"
      path: "/core/public/dictionaries?{args}"
    ) {
      data {
        id
        name
        value
      }
      success
    }
  }
`;

export const CryptoCurrencyDictionary = gql`
  query CryptoCurrencyDictionary(
    $dictionary_name: String!
    $order_col: String!
    $order_dir: String!
    $q: String
  ) {
    cryptoCurrencyDictionary(
      dictionary_name: $dictionary_name
      order_col: $order_col
      order_dir: $order_dir
      q: $q
    )
    @rest(
      type: "ResponseDictionaryItems"
      path: "/core/public/dictionaries/elliptic_dictionaries?{args}"
    ) {
      data {
        id
        name
        value
        support_multiple_blockchains
        blockchains {
          name
          value
        }
      }
      success
    }
  }
`;

