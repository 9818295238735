import { PropsWithChildren } from 'react';
import { AIDLoader } from '../AIDLoader';
import { FeaturesContext } from './context';
import { useFeaturesProviderController } from './controller';

export function FeaturesProvider({ children }: PropsWithChildren<{}>) {
  const { context, loading } = useFeaturesProviderController();

  if (loading) {
    return <AIDLoader />;
  }

  return (
    <FeaturesContext.Provider value={context}>
      {children}
    </FeaturesContext.Provider>
  );
}
