import { gql } from '@apollo/client';

export const TransactionActivityTypes = gql`
  query TransactionActivityTypes($page: Int, $q: String) {
    transactionActivityTypes(page: $page, per: 20, q: $q)
      @rest(
        type: "ResponseTransactionActivityTypes"
        path: "/tm/activity_types?{args}"
      ) {
      data @type(name: "TransactionActivityType") {
        id
        name
        created_at
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;
