import uniqueId from 'lodash.uniqueid';
import { useReducer, useRef } from 'react';
import { SideWidgetState } from './types';

export function useCommentProviderController() {
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const components = useRef<SideWidgetState[] | undefined>(undefined);

  const findWindowIndex = useRef((key?: string) => {
    if (!key || !components.current) {
      return;
    } else {
      let index = undefined;
      for (let i = 0, count = components.current.length; i < count; i++) {
        if (components.current[i].key === key) {
          index = i;
          break;
        }
      }
      return index;
    }
  });

  const show = useRef((children: JSX.Element, key?: string) => {
    const id = uniqueId('widget');
    const index = findWindowIndex.current(key);
    if (typeof index === 'undefined') {
      components.current = [
        {
          key: key || id,
          position: 0,
          children,
        },
      ];
      forceUpdate();
    }
  });

  const push = useRef((children: JSX.Element, key?: string) => {
    const id = uniqueId('widget');
    const index = findWindowIndex.current(key);
    if (typeof index === 'undefined') {
      components.current = [
        { key: key || id, position: components.current?.length || 0, children },
        ...(components.current || []),
      ];
      forceUpdate();
    }
  });

  const pop = useRef(() => {
    if (components.current && components.current?.length < 2) {
      components.current = undefined;
    } else {
      components.current?.shift();
    }
    forceUpdate();
  });

  const close = useRef(() => {
    components.current = undefined;
    forceUpdate();
  });

  const value = useRef({
    show: show.current,
    close: close.current,
    push: push.current,
    pop: pop.current,
  });

  return { value, components };
}
