import gql from 'graphql-tag';

export const ComplienceRejectReasons = gql`
  query ComplienceRejectReasons(
    $form_configuration_reference_keys: [String!]
    $date_from: String
    $date_to: String
  ) {
    complienceRejectReasons(
      form_configuration_reference_keys: $form_configuration_reference_keys
      date_from: $date_from
      date_to: $date_to
    )
      @rest(
        type: "ResponseComplienceRejectReasons"
        path: "/stats/client/reject_reasons/general?{args}"
      ) {
      data {
        top5 {
          name
          count
          percent
        }
      }
      success
    }
  }
`;
