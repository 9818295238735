import { useCallback } from 'react';
import { useAccountsController } from './controller';
import { Client, ClientHeader } from './components';
import { Dropdown, DropdownLoader } from '@aid-module/ui';
import { TYPES } from '@aid-package/api';
import { DropdownContentContainer } from './styles';

export function Accounts() {
  const { currentClient, clients } = useAccountsController();

  const renderClient = useCallback(
    (client: NonNullable<TYPES.MeQuery['me']['data']['accounts_clients']>[0]) =>
      client && <Client key={client.id} client={client} />,
    []
  );

  if (currentClient && clients) {
    return (
      <>
        {clients.length > 0 ? (
          <Dropdown
            header={<ClientHeader client={currentClient} />}
            fallback={<DropdownLoader height={40} />}
          >
            <DropdownContentContainer>
              {clients.map(renderClient)}
            </DropdownContentContainer>
          </Dropdown>
        ) : (
          <Client client={currentClient} />
        )}
      </>
    );
  }

  return null;
}
