import { MouseEvent, useCallback, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useNavigationContext } from '../../../../../../../../../context';
import { parseFilters } from '../../../../Filters/components/Action/components/Save/components/Body/controller/utils';
import { useActionsModel } from '../model';

export function useActionsController() {
  const { loading, updateTabRegistry } = useActionsModel();
  const { navigation, navigateTo } = useNavigationContext();
  const { handleSubmit } = useFormContext();
  const state = useWatch<any>({ defaultValue: {} });

  const disabled = useMemo(() => {
    const { filterTemplateNameSupport, ...anyState } = state;
    const values = Object.values(anyState);
    let disabled = true;
    for (let i = 0, count = values.length; i < count; i++) {
      if (values[i]) {
        disabled = false;
        break;
      }
    }
    return disabled;
  }, [state]);

  const onClickClose = useCallback(() => {
    navigateTo({ type: 'filterTemplates' });
  }, [navigateTo]);

  const onSubmit = useCallback(
    async (variables: any) => {
      if (navigation.type === 'filterTemplatesEdit') {
        const { filterTemplateNameSupport, ...filters } = variables;
        const body = {
          ...navigation.item,
          name: filterTemplateNameSupport?.trim(),
          filters: parseFilters(filters),
          meta: filters,
        };
        const res = await updateTabRegistry(navigation.item.id, body);
        if (res) {
          navigateTo({ type: 'filterTemplates' });
        }
      }
    },
    [updateTabRegistry, navigation, navigateTo]
  );

  const onClickSubmit = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      handleSubmit(onSubmit)(e);
    },
    [handleSubmit, onSubmit]
  );

  return {
    onClickClose,
    onClickSubmit,
    disabled: disabled || loading,
  };
}
