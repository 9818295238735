import gql from 'graphql-tag';

export const CreateTabRegistry = gql`
  mutation CreateTabRegistry($body: BodyCreateOrUpdateTabRegistry!) {
    createTabRegistry(body: $body)
      @rest(
        type: "TabRegistryCreate"
        path: "/core/client/tab_registries"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "TabRegistry") {
        id
        name
        registry_type
        favorite
        position
        filters
        meta
      }
      success
      errors {
        name
      }
    }
  }
`;

export const UpdateTabRegistry = gql`
  mutation UpdateTabRegistry(
    $id: String!
    $body: BodyCreateOrUpdateTabRegistry!
  ) {
    updateTabRegistry(id: $id, body: $body)
      @rest(
        type: "TabRegistryUpdate"
        path: "/core/client/tab_registries/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "TabRegistry") {
        id
        name
        registry_type
        favorite
        position
        filters
        meta
      }
      errors {
        name
      }
      success
    }
  }
`;

export const ChangePositionTabRegistry = gql`
  mutation ChangePositionTabRegistry(
    $id: String!
    $body: BodyChangePositionTabRegistry!
  ) {
    changePositionTabRegistry(id: $id, body: $body)
      @rest(
        type: "TabRegistryChangePosition"
        path: "/core/client/tab_registries/{args.id}/change_position"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "TabRegistry") {
        id
        name
        registry_type
        favorite
        position
        filters
        meta
      }
      errors {
        name
      }
      success
    }
  }
`;

export const DeleteTabRegistry = gql`
  mutation DeleteTabRegistry($id: String!) {
    deleteTabRegistry(id: $id)
      @rest(
        type: "TabRegistryDelete"
        path: "/core/client/tab_registries/{args.id}"
        bodyKey: "body"
        method: "DELETE"
      ) {
      success
    }
  }
`;
