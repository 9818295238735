import gql from 'graphql-tag';

export const CreateAITaskConfig = gql`
  mutation CreateAITaskConfig($body: BodyCreateAITaskConfig!) {
    createAITaskConfig(body: $body)
      @rest(
        type: "CreateAITaskConfig"
        path: "/voodoo/ai_task_configs"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "AITaskConfig") {
        id
        client_id
        configurable_entity_id
        configurable_entity_reference_key
        configurable_entity_type
        created_at
        options
        target_processable_entity_type
        updated_at
      }
      success
    }
  }
`;

export const UpdateAITaskConfig = gql`
  mutation UpdateAITaskConfig($id: String!, $body: BodyUpdateAITaskConfig!) {
    updateAITaskConfig(id: $id, body: $body)
      @rest(
        type: "UpdateAITaskConfig"
        path: "/voodoo/ai_task_configs/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "AITaskConfig") {
        id
        client_id
        configurable_entity_id
        configurable_entity_reference_key
        configurable_entity_type
        created_at
        options
        target_processable_entity_type
        updated_at
      }
      success
    }
  }
`;

export const DeleteAITaskConfig = gql`
  mutation DeleteAITaskConfig($id: String!) {
    deleteAITaskConfig(id: $id)
      @rest(
        type: "DeleteAITask"
        path: "/voodoo/ai_task_configs/{args.id}"
        method: "DELETE"
      ) {
      success
    }
  }
`;
