import styled from '@emotion/styled';
import { getColor, getSpace } from '../../utils';
import { PADDINGS } from './constants';
import { EmotionCardViewProps } from './types';

export const Container = styled.div<EmotionCardViewProps>`
  padding: ${getSpace('20')} ${getSpace('18')};
  border: 1px solid ${getColor('white-primary')};
  border-radius: ${({ borderRadius }) => borderRadius}px;
  background-color: ${getColor('white-primary')};

  ${({ background }) =>
    background &&
    `
    background-color: ${getColor(background)};
    border-color: ${getColor(background)};
  `}

  ${({ border }) =>
    border &&
    `
      border-color: ${getColor(border)};
  `}

  ${({ padding }) =>
    padding &&
    `
      padding: ${getSpace(PADDINGS[padding].y)} ${getSpace(
      PADDINGS[padding].x
    )};
  `}
`;
