import { PropsWithChildren } from 'react';
import { ContentLoader } from '../ContentLoader';
import { CollapsedLoaderViewProps } from './types';

export function CollapsedLoader({
  openned,
  children,
}: PropsWithChildren<CollapsedLoaderViewProps>) {
  return (
    <>
      <ContentLoader width="100%" height="31.2">
        <rect rx="4" ry="4" width="100%" height="31.2" />
      </ContentLoader>
      {openned &&
        (children || (
          <ContentLoader width="100%" height="800">
            <rect rx="4" ry="4" y="20" width="100%" height="780" />
          </ContentLoader>
        ))}
    </>
  );
}
