import { PERMISSIONS, Protected } from '@aid-package/context';
import { Navigate, useLocation } from 'react-router-dom';

// TODO
export function RedirectForms() {
  const location = useLocation();

  return (
    <Navigate
      replace
      to={location.pathname.replace('builder/form', 'builder/forms/form')}
    />
  );
}

export function RedirectVerification() {
  const location = useLocation();

  return (
    <Navigate
      replace
      to={location.pathname.replace(
        'builder/verifications',
        'builder/forms/verifications'
      )}
    />
  );
}

export function RedirectFormMappings() {
  return (
    <Protected
      check={[
        PERMISSIONS.FORMS_CREATE,
        PERMISSIONS.FORMS_UPDATE,
        PERMISSIONS.FORMS_PUBLISH,
      ]}
      empty={<Navigate replace to="exports" />}
    >
      <Navigate replace to="mappings" />
    </Protected>
  );
}
