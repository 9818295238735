import { GridContainer, InputLoader, BooleanInputLoader } from '@aid-module/ui';
import { ActionFallback } from '../../../../components/ActionFallback';

export function Fallback() {
  return (
    <GridContainer gap="20" gridTemplateColumns="1fr">
      <InputLoader />
      <InputLoader />
      <InputLoader />
      <BooleanInputLoader />
      <ActionFallback count={2} />
    </GridContainer>
  );
}
