import { useMemo } from 'react';
import { AlertStatusViewProps } from '../types';
import { getColorByStatus } from './utils';
import { ALERT_DISPLAY_STATUSES } from '../constants';

export function useAlertStatusController(
  status: AlertStatusViewProps['status']
) {
  const background = useMemo(() => getColorByStatus(status), [status]);

  const text = useMemo(
    () =>
      status ? ALERT_DISPLAY_STATUSES[status] || status.replace('_', '  ') : '',
    [status]
  );

  return {
    text,
    background,
  };
}
