import { CopyViewProps } from '../../types';
import { useClipBoardController } from './controller';
import { Icon } from '../../../../../../../Icon';
import { Text } from '../../../Text';
import { Container, CopyIconContainer } from './styles';
import { ContentLoader, IconLoader } from '../../../../../../../../shared';
import { Copy } from '../../../../../../../Copy';
import { stopPropagation } from '../../../../../../../../utils';
import { GridContainer } from '../../../../../../../../containers';

export function ClipBoard({ copyType, label, ...props }: CopyViewProps) {
  const { onCopy } = useClipBoardController(copyType);

  return (
    <GridContainer gridTemplateRows="18.2px" onClick={stopPropagation}>
      <Copy
        text={label}
        onCopy={onCopy}
        fallback={
          <ContentLoader width="100%" height="18.2">
            <rect rx="4" ry="4" width="100%" height="18.2" />
          </ContentLoader>
        }
      >
        <Container alignItems="center" gap="8">
          <Text label={label} {...props} />
          <CopyIconContainer>
            <Icon
              glyph="clipBoard"
              size={16}
              color="main-deep-4"
              fallback={<IconLoader size={16} />}
            />
          </CopyIconContainer>
        </Container>
      </Copy>
    </GridContainer>
  );
}
