import { ContentLoader, GridContainer } from '@aid-module/ui';
import { MainFallback } from '../../../components';

export function Fallback() {
  return (
    <GridContainer gap="12">
      <MainFallback />
      <ContentLoader width="100%" height="800px">
        <rect rx="8" ry="8" width="100%" height="8000px" />
      </ContentLoader>
    </GridContainer>
  );
}
