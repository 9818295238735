import styled from '@emotion/styled';
import { GridContainer } from '../../../../../../../../containers';
import { getSpace, getColor } from '../../../../../../../../utils';

export const Container = styled(GridContainer)`
  position: relative;
  padding: ${getSpace('40')} ${getSpace('60')};
  box-shadow: 0px 4px 8px #e3dee9;
  border-radius: 8px;
  border: 1px solid ${getColor('main-deep-2')};
  background-color: ${getColor('main-deep-1')};
  max-width: 478px;
  min-width: 318px;

  @media only screen and (max-width: 1000px) {
    max-width: calc(60vw - 120px);
  }
`;
