import { ContentLoader, GridContainer, TabsLoader } from '@aid-module/ui';

export function Fallback() {
  return (
    <GridContainer gap="20">
      <ContentLoader width="100%" height="54.2">
        <rect rx="8" ry="8" width="100%" height="54.2" />
      </ContentLoader>
      <TabsLoader />
    </GridContainer>
  );
}
