import { PropsWithChildren } from 'react';
import { LoaderThemeContainer } from './styles';
import { LoaderThemeProviderViewProps } from './types';

export function LoaderThemeProvider({
  children,
  ...props
}: PropsWithChildren<LoaderThemeProviderViewProps>) {
  return <LoaderThemeContainer {...props}>{children}</LoaderThemeContainer>;
}
