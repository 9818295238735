import { GridContainer } from '../../../../../../../../../../../../../../../../../../../../../../../../../containers';
import { PrimaryButton, SecondaryButton } from '../../../../../../../../../../../../../../../../../../../../../../../../Button';
import { ModalBodyViewProps } from '../../../../../../../../../../../../../../../../../../../../../../../../Modal/Modal/types';
import { useActionsController } from './controller';

export function Actions({ onClose }: ModalBodyViewProps) {
  const { onClickSubmit, loading } = useActionsController(onClose);

  return (
    <GridContainer gridTemplateColumns="repeat(2, 1fr)" gap="20">
      <SecondaryButton text="Cancel" onClick={onClose} disabled={loading} />
      <PrimaryButton text="Submit" onClick={onClickSubmit} disabled={loading} />
    </GridContainer>
  );
}
