import gql from 'graphql-tag';

export const CreateTransactionFilterGroup = gql`
  mutation CreateTransactionFilterGroup($body: CreateTransactionFilterGroupBody!) {
    createTransactionFilterGroup(body: $body)
    @rest(
      type: "CreateTransactionFilterGroup"
      path: "/tm/filter/groups"
      bodyKey: "body"
      method: "POST"
    ) {
      data
      success
    }
  }
`;

export const UpdateTransactionFilterGroup = gql`
  mutation UpdateTransactionFilterGroup(
    $id: String!
    $body: UpdateTransactionFilterGroupBody!
  ) {
    updateTransactionFilterGroup(id: $id, body: $body)
    @rest(
      type: "UpdateTransactionFilterGroup"
      path: "/tm/filter/groups/{args.id}"
      bodyKey: "body"
      method: "PUT"
    ) {
      data
      success
    }
  }
`;

export const DeleteTransactionFilterGroup = gql`
  mutation DeleteTransactionFilterGroup($id: String!) {
    deleteTransactionFilterGroup(id: $id)
    @rest(
      type: "DeleteTransactionFilterGroup"
      path: "/tm/filter/groups/{args.id}"
      method: "DELETE"
    ) {
      data
      success
    }
  }
`;
