import { gql } from '@apollo/client';

export const TransactionScoringDependentRules = gql`
  query TransactionScoringDependentRules(
    $per: Int
    $page: Int
    $scoring_matrix_id: String!
  ) {
    transactionScoringDependentRules(
      per: $per
      page: $page
      scoring_matrix_id: $scoring_matrix_id
    )
      @rest(
        type: "ResponseTransactionScoringDependentRules"
        path: "/tm/scoring/dependent_rules?{args}"
      ) {
      data @type(name: "TransactionScoringDependentRule") {
        id
        client_id
        created_at
        if_scoring_rule_id
        if_scoring_rule_score
        scoring_matrix_id
        then_scoring_rule_id
        then_scoring_rule_score
        updated_at
      }
      meta {
        count
        page
        items
        pages
      }
      success
    }
  }
`;
