import { TYPES } from '@aid-package/api/';
import { THEME_COLORS } from '../../../shared/colors/types';
import {
  CheckupsClientAuditStatusResponse,
  CheckupsClientExecutionStatusResponse,
  CheckupsClientProviderStatusResponse,
} from '../constants';

export const LABELS_SETTINGS_BY_STATUS: Record<
  string,
  [string, keyof THEME_COLORS]
> = {
  [CheckupsClientExecutionStatusResponse.IN_PROGRESS]: [
    'In progress',
    'main-deep-4',
  ],
  [CheckupsClientExecutionStatusResponse.FAILED]: [
    'Execution failed',
    'error-deep-4',
  ],
  [CheckupsClientProviderStatusResponse.TRUE_POSITIVE]: [
    'True Positive',
    'error-deep-4',
  ],
  [CheckupsClientProviderStatusResponse.TRUE_POSITIVE_APPROVE]: [
    'True Positive Approve',
    'success-deep-4',
  ],
  [CheckupsClientProviderStatusResponse.TRUE_POSITIVE_REJECT]: [
    'True Positive Reject',
    'error-deep-4',
  ],
  [CheckupsClientProviderStatusResponse.NO_MATCH]: [
    'No Match',
    'success-deep-4',
  ],
  [CheckupsClientProviderStatusResponse.FALSE_POSITIVE]: [
    'False Positive',
    'success-deep-4',
  ],
  [CheckupsClientProviderStatusResponse.UNKNOW]: ['Unknown', 'gray-deep-4'],
  [CheckupsClientProviderStatusResponse.POTENTIAL_MATCH]: [
    'Potential Match',
    'warning-deep-4',
  ],
  [CheckupsClientAuditStatusResponse.NO_INFO]: [
    'No Information',
    'gray-deep-4',
  ],
  [CheckupsClientAuditStatusResponse.ALARM]: [
    'True Matches or Alerts',
    'error-deep-4',
  ],
  [CheckupsClientAuditStatusResponse.CLEAN]: [
    'No Matches or Alerts',
    'success-deep-4',
  ],
  [CheckupsClientAuditStatusResponse.FALSE_NEGATIVE]: [
    'False negative',
    'error-deep-4',
  ],
  [CheckupsClientAuditStatusResponse.WARNING]: ['Warning', 'warning-deep-4'],
  [CheckupsClientProviderStatusResponse.COMPANY_REGISTRY_MATCH]: [
    'Company registry match',
    'success-deep-4',
  ],
};

export function getSettings(status?: string) {
  const array =
    LABELS_SETTINGS_BY_STATUS[
      status || CheckupsClientAuditStatusResponse.NO_INFO
    ] || LABELS_SETTINGS_BY_STATUS[CheckupsClientAuditStatusResponse.NO_INFO];
  return { label: array[0], color: array[1] };
}

function isPPScreening(
  data?:
    | TYPES.ServiceCheckupsQuery['serviceCheckups']['data'][0]
    | TYPES.ServiceCheckupQuery['serviceCheckup']['data']
    | NonNullable<
        TYPES.AuditQuery['audit']['data']['audits_checkups'][0]
      >['checkup']
    | TYPES.PpScreeningDetailsQuery['pPScreeningDetails']['data']
): data is TYPES.PpScreeningDetailsQuery['pPScreeningDetails']['data'] {
  return data ? data.__typename === 'PPScreeningRecord' : false;
}

export function getTitleSettingsForStatus(
  data?:
    | TYPES.ServiceCheckupsQuery['serviceCheckups']['data'][0]
    | TYPES.ServiceCheckupQuery['serviceCheckup']['data']
    | NonNullable<
        TYPES.AuditQuery['audit']['data']['audits_checkups'][0]
      >['checkup']
    | TYPES.PpScreeningDetailsQuery['pPScreeningDetails']['data']
) {
  if (isPPScreening(data)) {
    return getSettings(data.checkup_provider_status || 'no_info');
  } else if (
    data?.execution_status === CheckupsClientExecutionStatusResponse.FAILED ||
    data?.execution_status === CheckupsClientExecutionStatusResponse.IN_PROGRESS
  ) {
    return getSettings(data.execution_status);
  } else if (!!data?.provider_status) {
    return getSettings(data.provider_status);
  } else if (data?.audit_status) {
    return getSettings(data?.audit_status);
  }

  return getSettings('no_info');
}
