import { ExternalLink, GridContainer } from '@aid-module/ui';
import { TYPES } from '@aid-package/api';
import { useMemo } from 'react';
import { useHitContext } from '../../../context';

export function useMediaMentionsController() {
  const hit = useHitContext();

  const config = useMemo(
    () =>
      hit.doc?.media?.map(
        (
          item: NonNullable<
            TYPES.ServiceCheckupQuery['serviceCheckup']['data']['serialized_result']['data']
          >['hits'][0]['doc']['media'][0],
          index: number
        ) => ({
          name: item?.title || `${index}`,
          nameComponent: (
            <GridContainer gridTemplateColumns="minmax(0px, 500px)">
              <ExternalLink
                target="_blank"
                size={16}
                text={item?.title}
                to={item?.url}
              />
            </GridContainer>
          ),
          render: () => item?.snippet,
        })
      ) || [],
    [hit.doc?.media]
  );

  return {
    hit,
    config,
  };
}
