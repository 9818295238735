import {
  ContentLoader,
  DefaultButtonLoader,
  GridContainer,
} from '@aid-module/ui';

export function Fallback() {
  return (
    <GridContainer gap="24" gridTemplateRows="max-content 1fr">
      <ContentLoader width="100%" height="145.98">
        <rect rx="8" ry="8" width="100%" height="145.98" />
      </ContentLoader>
      <GridContainer gap="20">
        <ContentLoader width="100%" height="36.2">
          <rect rx="8" ry="8" width="100%" height="36.2" />
        </ContentLoader>
        <ContentLoader width="100%" height="145.98">
          <rect rx="8" ry="8" width="100%" height="145.98" />
        </ContentLoader>
        <ContentLoader width="100%" height="145.98">
          <rect rx="8" ry="8" width="100%" height="145.98" />
        </ContentLoader>
        <ContentLoader width="100%" height="145.98">
          <rect rx="8" ry="8" width="100%" height="145.98" />
        </ContentLoader>
        <GridContainer
          gridTemplateColumns="repeat(2, 180px)"
          gap="20"
          justifyContent="flex-end"
        >
          <DefaultButtonLoader />
          <DefaultButtonLoader />
        </GridContainer>
      </GridContainer>
    </GridContainer>
  );
}
