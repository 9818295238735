import { IconLoader } from '../../../../shared/Loaders';
import {
  AutomaticControllerTooltipInitiateView,
  AutomaticTooltip,
} from '../../../AutomaticTooltip';
import { Text } from '../../../Text';
import { InfoTooltipViewProps } from './types';
import { Container } from './styles';
import { Icon } from '../../../Icon';

function TooltipButton({
  initiateRef,
}: AutomaticControllerTooltipInitiateView) {
  return (
    <Container ref={initiateRef}>
      <Icon
        size={14}
        glyph="info"
        color="main-deep-4"
        fallback={<IconLoader size={14} />}
      />
    </Container>
  );
}

export function InfoTooltip({ information }: InfoTooltipViewProps) {
  return (
    <AutomaticTooltip
      pattern="top,right,left,bottom"
      view={<TooltipButton />}
      background="white-primary"
    >
      <Text text={information} />
    </AutomaticTooltip>
  );
}
