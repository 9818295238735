import { AsyncSelect } from '@aid-module/ui';
import { CryptocurrencySymbolViewProps } from './types';
import { CryptoCurrencyDictionary } from '@aid-package/api';
import { useCryptocurrencySymbolController } from './controller';
import { useMemo } from 'react';

export function CryptocurrencySymbol({
  value,
  onChange,
  error,
  field,
}: CryptocurrencySymbolViewProps) {
  const { reduceData, handlerChange } =
    useCryptocurrencySymbolController(onChange);

  const additional = useMemo(
    () => ({
      dictionary_name: field.dictionary_name,
      order_dir: 'asc',
      order_col: 'name',
    }),
    [field.dictionary_name]
  );

  return (
    <AsyncSelect
      isSearchable
      query={CryptoCurrencyDictionary}
      label={`${field.title} ${field.required ? ' *' : ''}`}
      placeholder={`Select ${field.title}...`}
      additional={additional}
      value={value}
      error={error}
      onChange={handlerChange}
      reduceData={reduceData}
    />
  );
}
