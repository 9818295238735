import { ContentLoader } from '../ContentLoader';
import { ListLoaderViewProps } from './types';
import { List as UIList } from './styles';
import { useMemo } from 'react';
import { GridContainer } from '../../../containers';

export function ListInputLoader() {
  return (
    <UIList gap="8" gridTemplateColumns="minmax(max-content, auto) 1fr">
      <ContentLoader width="100px" height="18.2">
        <rect x="0" y="0" rx="8" ry="8" width="100%" height="18.2" />
      </ContentLoader>
      <ContentLoader width="100%" height="18.2">
        <rect x="0" y="0" rx="8" ry="8" width="100%" height="18.2" />
      </ContentLoader>
    </UIList>
  );
}

export function ListLoader({ count = 3 }: ListLoaderViewProps) {
  const listArray = useMemo(() => {
    return Array.from({ length: count }, (_, i) => <ListInputLoader key={i} />);
  }, [count]);
  return <GridContainer>{listArray}</GridContainer>;
}
