import { SharedResult } from '../SharedResult';
import {
  useInfoHitCardController,
  useInfoHitWithSearchCardController,
} from './controller';

export function SharedResultWithSearch({
  caSearchId,
  checkupId,
}: {
  caSearchId: string;
  checkupId?: string;
}) {
  const { hit, loading } = useInfoHitWithSearchCardController(caSearchId);

  return <SharedResult checkupId={checkupId} loading={loading} hit={hit} />;
}

export function InfoHitCard() {
  const { hit, loading, caSearchId, checkup } = useInfoHitCardController();

  if (!!caSearchId) {
    return (
      <SharedResultWithSearch checkupId={checkup?.id} caSearchId={caSearchId} />
    );
  } else {
    return <SharedResult checkupId={checkup?.id} loading={loading} hit={hit} />;
  }
}
