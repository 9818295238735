import {
  ApolloLink,
  Operation,
  NextLink,
  Observable,
  FetchResult,
} from '@apollo/client';
import { createOperation } from '@apollo/client/link/utils';
import {
  ResponseCaseScoring,
  ResponseSubmissionScoring,
  SubmissionScoring as TypeSubmissionScoring,
} from '../../api';
import { SubmissionScoring } from '../../query';

export class ScoringLink extends ApolloLink {
  constructor() {
    super();
  }

  requestSubmissions(parentOperation: Operation, forward: NextLink) {
    const context = parentOperation.getContext();
    return Promise.all<TypeSubmissionScoring>(
      (parentOperation.variables.submission_ids || []).map((id: string) => {
        return new Promise((res) => {
          const operation = createOperation(context, {
            query: SubmissionScoring,
            variables: {
              id,
            },
            operationName: 'SubmissionScoring',
            extensions: parentOperation.extensions,
          });
          forward(operation).subscribe(
            (data: FetchResult<Record<string, ResponseSubmissionScoring>>) => {
              res(data.data?.submissionScoring?.data as TypeSubmissionScoring);
            }
          );
        });
      })
    );
  }

  requestCase(operation: Operation, forward: NextLink) {
    return new Observable<FetchResult<{ caseScoring: ResponseCaseScoring }>>(
      (observer) => {
        this.requestSubmissions(operation, forward)
          .then((submissions) => {
            forward(operation).subscribe(
              (data: FetchResult<Record<string, ResponseCaseScoring>>) => {
                if (data.data) {
                  const nextData = {
                    ...data,
                    data: {
                      caseScoring: {
                        ...data.data.caseScoring,
                        data: {
                          ...data.data.caseScoring.data,
                          submissions,
                        },
                      },
                    },
                  };
                  observer.next(nextData);
                  observer.complete();
                }
                observer.error(new Error('Something went wrong'));
              }
            );
          })
          .catch((e) => {
            observer.error(e);
          });
      }
    );
  }

  request(operation: Operation, forward: NextLink) {
    if (operation.operationName === 'CaseScoring') {
      return this.requestCase(operation, forward);
    }
    return forward(operation);
  }
}
