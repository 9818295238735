import { useCallback } from 'react';
import { showToast } from '../../../../../../../../Toast';
import { CopyViewProps } from '../../../types';

export function useClipBoardController(copyType: CopyViewProps['copyType']) {
  const onCopy = useCallback(() => {
    showToast({
      type: 'success',
      message: `${copyType} was copied to clipboard`,
    });
  }, [copyType]);

  return { onCopy };
}
