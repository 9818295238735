import {
  SelectOption,
  SelectViewProps,
} from '../../../../../../../../../../../../../../../../../../../../../../../../Select/component/types';
import { useSelectController } from './controller';
import { Select as UISelect } from '../../../../../../../../../../../../../../../../../../../../../../../../Select';

export function Select(props: SelectViewProps<false, SelectOption>) {
  const { options } = useSelectController();
  return (
    <UISelect
      {...props}
      label="Filter Template"
      placeholder="Select filter template..."
      menuPlacement="top"
      options={options}
    />
  );
}
