import { useCallback } from 'react';
import { TYPES } from '@aid-package/api';
import { DateFormat } from '../../../utils';

export function useProfilesWithSubmissionSelectController() {
  const reduceData = useCallback(
    (data: TYPES.ProfilesQuery['profiles']['data'][0]) => ({
      label: `${data.display_name || 'External user'} (${DateFormat.formatDate(
        data.created_at
      )})`,
      value: data.id,
      profile: data,
    }),
    []
  );

  return { reduceData };
}
