import { showToast } from '@aid-module/ui';
import { useCreateCommentBulkMutation } from '@aid-package/api';
import { useCallback } from 'react';
import { useCheckupContext } from '../../../../../../../../../../../../../../../context';
import { useHitsContext } from '../../../../../../../../../context';

export function useActionModel() {
  const [mutation, { loading }] = useCreateCommentBulkMutation();
  const { id: checkup_id } = useCheckupContext();
  const { checkedHitIds } = useHitsContext();

  const createComment = useCallback(
    async (comment: string) => {
      const commentsBody = checkedHitIds.map((hit) => ({
        subject_type: 'Checkup',
        subject_id: checkup_id,
        subject_entity_id: hit,
        subject_entity_type: 'ComplyAdvantage',
        body: comment,
        state: 'published',
      }));

      const { data } = await mutation({
        variables: {
          type: '/core/client/comments/bulk_create',
          body: {
            comments: commentsBody,
          },
        },
      });

      if (data?.createCommentBulk?.success) {
        showToast({
          type: 'success',
          message: 'Changes were successfully saved',
        });

        return true;
      }

      showToast({
        type: 'error',
        message: 'Something went wrong',
      });

      return;
    },
    [alert, mutation, checkedHitIds]
  );

  return { loading, createComment };
}
