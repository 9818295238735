import gql from 'graphql-tag';

export const IDENFY_ATTACHMENT_FRAGMENT = gql`
  fragment IdenfyAttachmentFragment on IdenfyAttachment {
    account_id
    additional_data @type(name: "IdenfyAttachmentAdditionalData") {
      idenfy_kind
    }
    client_id
    created_at
    file_name
    file_size
    id
    kind
    subject_id
    subject_type
    updated_at
    uploaded_document_id
  }
`;
