import gql from 'graphql-tag';

export const UpdateAccountSettings = gql`
  mutation UpdateAccountSettings($body: BodyAccountSettings) {
    updateAccountSettings(body: $body)
      @rest(
        type: "AccountSettingsUpdate"
        path: "/nh/account_settings/current"
        bodyKey: "body"
        method: "PATCH"
      ) {
      data @type(name: "AccountSettings") {
        email_enabled
        id
        updated_at
        ws_enabled
      }
      success
    }
  }
`;
