import styled from '@emotion/styled';
import { GridContainer } from '../../containers';
import { getColor } from '../../utils';
import { EmotionLabelContainerViewProps } from './types';

export const Border = styled.div`
  border-bottom: 1px solid ${getColor('main-deep-2')};
`;

export const LabelContainer = styled(
  GridContainer
)<EmotionLabelContainerViewProps>`
  ${({ count }) =>
    count
      ? `
grid-template-columns: max-content max-content;
`
      : `grid-template-columns: max-content;`}
`;
