import gql from 'graphql-tag';

export const CreateTransactionBlacklistGroup = gql`
  mutation CreateTransactionBlacklistGroup($body: CreateTransactionBlacklistGroupBody!) {
    createTransactionBlacklistGroup(body: $body)
    @rest(
      type: "CreateTransactionBlacklistGroup"
      path: "/tm/blacklist/groups"
      bodyKey: "body"
      method: "POST"
    ) {
      data @type(name: "TransactionBlacklistGroup") {
        id
        name
        description
        kind
      }
      success
    }
  }
`;

export const UpdateTransactionBlacklistGroupBody = gql`
  mutation UpdateTransactionBlacklistGroupBody(
    $id: String!
    $body: UpdateTransactionBlacklistGroupBody!
  ) {
    updateTransactionBlacklistGroupBody(id: $id, body: $body)
    @rest(
      type: "UpdateTransactionBlacklistGroupBody"
      path: "/tm/blacklist/groups/{args.id}"
      bodyKey: "body"
      method: "PUT"
    ) {
      data @type(name: "TransactionBlacklistGroup") {
        id
        name
        description
        kind
      }
      success
    }
  }
`;

export const DeleteTransactionBlacklistGroup = gql`
  mutation DeleteTransactionBlacklistGroup($id: String!) {
    deleteTransactionBlacklistGroup(id: $id)
    @rest(
      type: "DeleteTransactionBlacklistGroup"
      path: "/tm/blacklist/groups/{args.id}"
      method: "DELETE"
    ) {
      data {
        id
      }
      success
    }
  }
`;
