import { ContentLoader } from '@aid-module/ui';
import { ClientsContainer } from '../../styles';

export function Fallback() {
  return (
    <ClientsContainer gap="20">
      <ContentLoader width="100%" height="152.59px">
        <rect rx="4" ry="4" width="100%" height="152.59px" />
      </ContentLoader>

      <ContentLoader width="100%" height="152.59px">
        <rect rx="4" ry="4" width="100%" height="152.59px" />
      </ContentLoader>

      <ContentLoader width="100%" height="152.59px">
        <rect rx="4" ry="4" width="100%" height="152.59px" />
      </ContentLoader>
    </ClientsContainer>
  );
}
