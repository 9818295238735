import { gql } from '@apollo/client';
import { TRANSACTION_ACCOUNT_FRAGMENT } from '../../../fragment/tm/TransactionAccount';

export const TransactionAccounts = gql`
  ${TRANSACTION_ACCOUNT_FRAGMENT}
  query TransactionAccounts(
    $has_permission: String
    $assigned_to_a_case: Boolean
  ) {
    transactionAccounts(
      has_permission: $has_permission
      assigned_to_a_case: $assigned_to_a_case
    ) @rest(type: "ResponseTransactionAccounts", path: "/tm/accounts?{args}") {
      data @type(name: "TransactionAccount") {
        ...TransactionAccountFragment
      }
      success
    }
  }
`;

export const TransactionAccount = gql`
  ${TRANSACTION_ACCOUNT_FRAGMENT}
  query TransactionAccount($id: String!) {
    transactionAccount(id: $id)
      @rest(
        type: "ResponseTransactionAccount"
        path: "/tm/accounts/{args.id}"
      ) {
      data @type(name: "TransactionAccount") {
        ...TransactionAccountFragment
      }
      success
    }
  }
`;
