import { ClientHeaderViewProps } from './types';
import { ContentContainer, Container, Label } from '../shared.style';
import {
  ClientAvatar,
  GridContainer,
  Icon,
  IconLoader,
  Text,
} from '@aid-module/ui';
import { IconContainer, TitleContainer } from './styles';

export function ClientHeader({ client, open }: ClientHeaderViewProps) {
  return (
    <Container>
      <ContentContainer
        gridTemplateColumns="40px 1fr"
        gap="8"
        alignItems="center"
      >
        <ClientAvatar client={client} fallback={<IconLoader size={40} />} />
        <GridContainer
          alignItems="center"
          gridTemplateRows="20.8px 13px"
          gap="2"
        >
          <TitleContainer alignItems="center">
            <Label
              text={client.client?.name}
              size={16}
              weight="Bold"
              color="main-deep-4"
            />
            <IconContainer open={open}>
              <Icon glyph="arrowRight" size={16} color="main-deep-4" />
            </IconContainer>
          </TitleContainer>
          <Text text="Client" size={10} color="main-deep-3" />
        </GridContainer>
      </ContentContainer>
    </Container>
  );
}
