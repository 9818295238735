import {
  HeaderContainer,
  Container,
  FiltersContainer,
  FiltersHeaderContainer,
  FilterContainer,
} from './styles';
import { HeaderViewProps } from './types';
import { useHeaderTableController } from './controller';
import { forwardRef } from 'react';
import {
  Columns,
  Create,
  Filters,
  FiltersButton,
  FilterTemplates,
  SearchInput,
} from './components';
import { Text } from '../../../../Text';
import { GridContainer } from '../../../../../containers';

export const Header = forwardRef<HTMLDivElement, HeaderViewProps<any>>(
  function render(
    {
      label,
      searchable,
      creatable,
      createLabel,
      createRef,
      canCreatable = true,
      createDeniedText,
      subHeader,
      subLabel,
      search: Search,
      columns,
      canShowColumns,
    },
    ref
  ) {
    const {
      onChangeSearch,
      showFilters,
      toggleShowFilters,
      to,
      onChangeSelect,
      onClickCreate,
      filtersConfig,
    } = useHeaderTableController(createRef);

    return (
      <Container>
        {(label || subLabel) && (
          <HeaderContainer gap="20" ref={ref}>
            {label && (
              <GridContainer
                gap="8"
                gridTemplateColumns="max-content max-content"
                alignItems="center"
                justifyContent="space-between"
              >
                <Text text={label} size={26} uppercase weight="VeryBig" />
                <Create
                  createDeniedText={createDeniedText}
                  onClickCreate={onClickCreate}
                  createLabel={createLabel || ''}
                  canCreatable={canCreatable}
                  creatable={creatable}
                  to={to}
                />
              </GridContainer>
            )}
            {subLabel && subLabel}
          </HeaderContainer>
        )}
        {(filtersConfig ||
          subHeader ||
          Search ||
          searchable ||
          canShowColumns) && (
          <FilterContainer gap="18">
            <FiltersContainer
              gridTemplateColumns="1fr"
              showFilters={showFilters}
              gap="20"
            >
              <FiltersHeaderContainer
                gap="12"
                subHeader={subHeader}
                ifExistFilters={!!filtersConfig}
                canShowColumns={canShowColumns}
                gridTemplateRows="32.2px"
              >
                <GridContainer
                  gridTemplateRows="max-content"
                  gridTemplateColumns="370px"
                >
                  {Search ? (
                    <Search onChange={onChangeSelect} />
                  ) : (
                    <SearchInput
                      searchable={searchable}
                      onChangeSearch={onChangeSearch}
                    />
                  )}
                </GridContainer>
                {subHeader}
                {canShowColumns && <Columns columns={columns} />}
                {filtersConfig && (
                  <FiltersButton
                    selected={showFilters}
                    toggleShowFilters={toggleShowFilters}
                  />
                )}
              </FiltersHeaderContainer>
              {showFilters && filtersConfig && (
                <Filters toggleShowFilters={toggleShowFilters} />
              )}
            </FiltersContainer>
            <FilterTemplates showFilters={showFilters} />
          </FilterContainer>
        )}
      </Container>
    );
  }
);

export { Columns } from './components';
