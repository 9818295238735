import { Controller, FormProvider } from 'react-hook-form';
import { ModalBodyViewProps } from '../../../../../../../../../../../../../../../../../../../../../../Modal/Modal/types';
import { TextInput } from '../../../../../../../../../../../../../../../../../../../../../../TextInput';
import { Actions } from './components';
import { useFormController } from './controller';
import { FormRenderProps } from './types';

function renderName({
  field: { value, onChange },
  fieldState: { error },
}: FormRenderProps<'name'>) {
  return (
    <TextInput
      label="Filter Template Name"
      placeholder="Enter filter template name..."
      value={value}
      onChange={onChange}
      error={error}
    />
  );
}

export function Form({ onClose }: ModalBodyViewProps) {
  const formContext = useFormController();

  return (
    <FormProvider {...formContext}>
      <Controller
       name="name" 
       render={renderName}
       rules={{ required: 'Required', maxLength: { value: 35, message: 'name must be at most 35 characters' } }}
      />
      <Actions onClose={onClose} />
    </FormProvider>
  );
}
