import gql from 'graphql-tag';
import { FORM_CONFIGURATION_FRAGMENT } from '../../../fragment/submission/FormConfiguration';

export const SubmissionsStatsPresets = gql`
  query SubmissionsStatsPresets(
    $page: Int
    $per: Int
    $reference_key: String
    $q: String
    $sort_field: String
    $sort_order: String
    $is_template: Boolean
  ) {
    submissionsStatsPresets(
      page: $page
      per: $per
      reference_key: $reference_key
      q: $q
      sort_field: $sort_field
      sort_order: $sort_order
      is_template: $is_template
    )
      @rest(
        type: "ResponseSubmissionsStatsPresets"
        path: "/stats/submission_field_presets?{args}"
      ) {
      data @type(name: "SubmissionsStatsPreset") {
        form_configuration_name
        created_by_name
        client_id
        created_at
        created_by_id
        date_from
        date_to
        form_configuration_reference_key
        form_field_reference_keys
        score_from
        score_to
        bank_tariff_name
        bank_tariff_names
        id
        name
        updated_at
        is_template
        case_status
        submission_status
        case_statuses
        submission_statuses
        form_configuration_id
        form_configuration_name
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const SubmissionsStatsPreset = gql`
  ${FORM_CONFIGURATION_FRAGMENT}
  query SubmissionsStatsPreset($id: String!) {
    submissionsStatsPreset(id: $id)
      @rest(
        type: "ResponseSubmissionsStatsPreset"
        path: "/stats/submission_field_presets/{args.id}"
      ) {
      data @type(name: "SubmissionsStatsPreset") {
        form_configuration_name
        created_by_name
        client_id
        created_at
        created_by_id
        date_from
        date_to
        form_configuration_reference_key
        form_field_reference_keys
        form_configuration_id
        case_status
        submission_status
        case_statuses
        submission_statuses
        score_from
        score_to
        bank_tariff_name
        bank_tariff_names
        id
        name
        updated_at
        is_template
        target_submissions @type(name: "Submission") {
          id
          form_configuration @type(name: "FormConfiguration") {
            ...FormConfigurationFragment
          }
          profile_display_name
          created_at
        }
      }
      success
    }
  }
`;
