import { ExternalRefsViewProps } from '../../../../types';
import { ExternalRefItem } from './components';
import { BodyViewProps } from './types';
import { Container } from './styles';

function renderExternalRef(
  externalRef: NonNullable<ExternalRefsViewProps['externalRefs']>[0]
) {
  return <ExternalRefItem externalRef={externalRef} />;
}

export function Body({ externalRefs }: BodyViewProps) {
  return <Container gap="12">{externalRefs?.map(renderExternalRef)}</Container>;
}
