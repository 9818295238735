import { LoadableFunctionalComponent } from '@aid-module/ui';
import loadable from '@loadable/component';
import { FormContainer } from '../../../components';
import { Fallback } from './components';

export const ClientProviderUpdate = loadable(() => import('./component'), {
  resolveComponent: (components) => components.ClientProviderUpdateComponent,
  fallback: (
    <FormContainer label="Client Provider" icon="kycDocVerification" isLoading>
      <Fallback />
    </FormContainer>
  ),
}) as LoadableFunctionalComponent<{}>;
