import { Link } from '../Link';
import { MultiplePathsLinkContainer } from '../MultiplePathsLinkContainer';
import { useRemindersLinkController } from './controller';

export function RemindersLink() {
  const { remindersPaths, mainPath } = useRemindersLinkController();

  return (
    <MultiplePathsLinkContainer paths={remindersPaths}>
      <Link to={mainPath} text="Reminders" />
    </MultiplePathsLinkContainer>
  );
}
