import { useRef } from 'react';
import { ListOnScrollProps } from 'react-window';
import { getSpace } from '../../../../utils';

export function useHeaderTableController() {
  const ref = useRef<HTMLDivElement>(null);

  const onScroll = useRef((scroll: ListOnScrollProps) => {
    requestAnimationFrame(() => {
      if (ref.current) {
        const height = ref.current.scrollHeight;
        if (!ref.current.style.maxHeight) {
          ref.current.style.maxHeight = `${height - 12}px`;
        }
        if (
          scroll.scrollOffset > height * 2.5 &&
          ref.current.style.maxHeight !== '0px'
        ) {
          ref.current.style.paddingBottom = '0px';
          ref.current.style.maxHeight = '0px';
        } else if (
          scroll.scrollOffset < height &&
          ref.current.style.maxHeight === '0px'
        ) {
          ref.current.style.paddingBottom = getSpace('12');
          ref.current.style.maxHeight = `${height}px`;
        }
      }
    });
  });

  return {
    ref,
    onScroll,
  };
}
