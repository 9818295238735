import styled from '@emotion/styled';
import { Text } from '../../../../../../../../../../../../Text';
import { EmotionLabelViewProps } from './types';

export const Label = styled(Text)<EmotionLabelViewProps>`
  cursor: pointer;
  ${({ isActive }) =>
    isActive &&
    `
    cursor: default;
    text-decoration: underline;
    text-underline-offset: 6px;
    text-decoration-thickness: 2px;
    `}
`;
