import { VariablesProviders } from '@aid-module/ui';
import { Header, ByTab } from './components';
import { useVerificationDashboardController } from './controller';
import { Container } from './styles';

export function SubmissionRegister() {
  const { tab, setTab } = useVerificationDashboardController();

  return (
    <VariablesProviders>
      <Container gap="20" gridTemplateRows="max-content">
        <Header value={tab} onChange={setTab} />
        <ByTab tab={tab} />
      </Container>
    </VariablesProviders>
  );
}
