import { ErrorTemplate } from './components';
import { ErrorTemplateViewProps } from './components/ErrorTemplate/types';

export function ErrorCritical({
  description,
  type = 'local',
}: Pick<ErrorTemplateViewProps, 'description'> &
  Partial<Pick<ErrorTemplateViewProps, 'type'>>) {
  return (
    <ErrorTemplate
      type={type}
      labelColor="error-deep-4"
      title="Critical error"
      description={description}
      customIcon={
        <svg
          width="81"
          height="80"
          viewBox="0 0 81 80"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M79.8134 22.745L57.755 0.686563C57.3155 0.247031 56.7194 0 56.0978 0H24.9022C24.2806 0 23.6845 0.247031 23.245 0.686563L1.18656 22.745C0.747031 23.1845 0.5 23.7806 0.5 24.4022V55.5977C0.5 56.2192 0.747031 56.8153 1.18656 57.2548L23.245 79.3133C23.6845 79.7528 24.2806 79.9998 24.9022 79.9998H56.0977C56.7192 79.9998 57.3153 79.7528 57.7548 79.3133L79.8133 57.2548C80.2528 56.8153 80.4998 56.2192 80.4998 55.5977V24.4022C80.5 23.7806 80.253 23.1845 79.8134 22.745Z"
            fill="#B80929"
          />
          <path
            d="M80.5 24.4016V55.5984C80.5 56.2187 80.2531 56.8156 79.8141 57.2547L57.7547 79.3141C57.3156 79.7531 56.7187 80 56.0984 80H40.5V0H56.0984C56.7187 0 57.3156 0.246875 57.7547 0.685937L79.8141 22.7453C80.2531 23.1844 80.5 23.7812 80.5 24.4016Z"
            fill="#6E0518"
          />
          <path
            d="M37.5578 16.5625H43.4418C44.8056 16.5625 45.8814 17.7222 45.779 19.0822L43.7801 45.6447C43.6881 46.8675 42.6692 47.8125 41.4429 47.8125H39.5565C38.3303 47.8125 37.3114 46.8673 37.2193 45.6447L35.2206 19.0822C35.1184 17.7222 36.194 16.5625 37.5578 16.5625Z"
            fill="white"
          />
          <path
            d="M45.7797 19.0828L43.7797 45.6453C43.6891 46.8672 42.6688 47.8125 41.4438 47.8125H40.5V16.5625H43.4422C44.8062 16.5625 45.8813 17.7219 45.7797 19.0828Z"
            fill="white"
          />
          <path
            d="M35.8203 58.7422V58.7578C35.8203 61.3423 37.9155 63.4375 40.5 63.4375C43.0845 63.4375 45.1797 61.3423 45.1797 58.7578V58.7422C45.1797 56.1577 43.0845 54.0625 40.5 54.0625C37.9155 54.0625 35.8203 56.1577 35.8203 58.7422Z"
            fill="white"
          />
          <path
            d="M45.1797 58.7422V58.7578C45.1797 60.05 44.6562 61.2203 43.8094 62.0672C42.9625 62.9141 41.7922 63.4375 40.5 63.4375V54.0625C43.0844 54.0625 45.1797 56.1578 45.1797 58.7422Z"
            fill="white"
          />
        </svg>
      }
    />
  );
}
