import { Icon } from '@aid-module/ui';
import { EnabledViewProps } from './types';

export function EnabledIcon({ enabled }: EnabledViewProps) {
  if (enabled) {
    return <Icon glyph="checkmark" color="success-deep-4" />;
  }

  return <Icon glyph="cross" color="error-deep-4" />;
}
