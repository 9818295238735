import { useMemo } from 'react';
import { ButtonViewProps } from '../../../../types';

export function useButtonController(
  color: ButtonViewProps['color'],
  disabled: ButtonViewProps['disabled']
) {
  const textColor = useMemo(() => {
    if (disabled) return 'gray-deep-2';

    return color || 'white-primary';
  }, [disabled, color]);

  return {
    textColor,
  };
}
