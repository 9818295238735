import { useMemo } from 'react';

export function useVerificationsController() {
  const { form, name } = useMemo(() => {
    const urlParams = new URLSearchParams(location.search);
    const form = urlParams.get('form');
    const name = urlParams.get('name');
    return { form, name };
  }, [location]);

  const label = useMemo(() => `Results for "${name}"`, [name]);

  return {
    label,
    form,
  };
}
