import { Collapsed, CommentProvider, GridContainer } from '@aid-module/ui';
import { Hit } from '../Result/components/HitsResult/components/Hits/components';
import {
  AdditionalInformation,
  EntityInformation,
  Fallback,
  MediaMentions,
} from './components';
import { HitContext } from './context';
import { SharedResultViewProps } from './types';

export function SharedResult({
  hit,
  loading,
  checkupId,
}: SharedResultViewProps) {
  if (loading) {
    return <Fallback />;
  }

  if (hit) {
    return (
      <CommentProvider>
        <HitContext.Provider value={hit}>
          <GridContainer gap="20">
            <Hit checkupId={checkupId} hit={hit} />
            <Collapsed openned={true} title={'Entity Information'}>
              <GridContainer
                alignItems="start"
                gap="20"
                gridTemplateColumns="1fr 1fr"
              >
                <EntityInformation />
                <AdditionalInformation />
              </GridContainer>
            </Collapsed>
            <MediaMentions />
          </GridContainer>
        </HitContext.Provider>
      </CommentProvider>
    );
  }

  return null;
}
