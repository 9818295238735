import { useEffect, useReducer, useRef } from 'react';
import { PortalWidgetController } from './class';

export function usePortalSideWidgetProviderController(containerName: string) {
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const controller = useRef(new PortalWidgetController());

  useEffect(() => {
    function onUpdate() {
      forceUpdate();
    }
    controller.current.onUpdate = onUpdate;

    return function () {
      controller.current.onUpdate = undefined;
    };
  }, []);

  const value = {
    controller: controller.current,
    containerName,
  };

  return { value };
}
