import styled from '@emotion/styled';
import { FlexContainer, GridContainer } from '../../../../../../containers';
import { getColor } from '../../../../../../utils';

export const Container = styled(GridContainer)`
  background: ${getColor('main-deep-1')};
  border: 1px solid ${getColor('main-deep-2')};
  border-radius: 8px;
  cursor: pointer;
`;

export const IconContainer = styled(FlexContainer)`
  background: ${getColor('main-deep-2')};
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  height: 100%;
`;
