import loadable from '@loadable/component';
import { LoadableFunctionalComponent } from '../../utils';
import { ImageLoader } from '../../shared';
import { DocumentComponentViewProps } from './component/types';
export * from './component/types';

export const Document = loadable(() => import('./component'), {
  resolveComponent: (components) => components.DocumentComponent,
  fallback: <ImageLoader />,
}) as LoadableFunctionalComponent<DocumentComponentViewProps>;
