import { ButtonByType } from '../components';
import { ButtonViewProps } from '../types';

export function SuccessSecondaryButton(props: ButtonViewProps) {
  return (
    <ButtonByType
      {...props}
      color="success-deep-4"
      background="white-primary"
      border="success-deep-4"
    />
  );
}
