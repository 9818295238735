import { useMemo } from 'react';
import { useClientContext } from '@aid-package/context';
import { useOnBoardingContext } from '../../../context';

export function useProfileModel() {
  const { client } = useClientContext();
  const { stepStatus, redirectToFlow, statusColor, isExist } = useOnBoardingContext();

  const statuses = useMemo(() => {
    return {
      isNewStatus: stepStatus === 'new',
      isCompletedStatus: stepStatus === 'completed',
    };
  }, [stepStatus]);

  return {
    fullName: client?.full_name || 'Customer',
    email: client?.email,
    phone: client?.phone,
    ...statuses,
    redirectToFlow,
    isExist,
    statusColor,
  };
}
