import { createContext, useContext } from 'react';
import { PositionContextParams } from './types';

export const PositionContext = createContext<PositionContextParams>({
  startMove() {},
});

export function usePositionContext() {
  const context = useContext(PositionContext);

  return context;
}
