export enum CaseExternalRefTypeResponse {
  CASE = 'Case',
  SUBMISSION = 'Submission',
  FORM_SUBMISSION = 'FormSubmission',
  EVENT = 'Event',
  IDENFY_RESULT = 'IdenfyResult',
  PROFILE = 'Profile',
  CHECKUP = 'Checkup',
  CUSTOMER = 'Customer',
}
