import { LoadableFunctionalComponent } from '@aid-module/ui';
import loadable from '@loadable/component';
import { FormContainer } from '../components';
import { Fallback } from './components';

export const CaseRejectReasonUpdate = loadable(() => import('./component'), {
  resolveComponent: (components) => components.CaseRejectReasonUpdateComponent,
  fallback: (
    <FormContainer
      label="Update case rejection reason"
      icon="checkup"
      isLoading
    >
      <Fallback />
    </FormContainer>
  ),
}) as LoadableFunctionalComponent<{}>;
