import gql from 'graphql-tag';

export const TRANSACTION_ACCOUNT_FRAGMENT = gql`
  fragment TransactionAccountFragment on TransactionAccount {
    accounts_clients @type(name: "TransactionAccountClient") {
      account_id
      client_id
      id
    }
    created_at
    email
    first_name
    id
    last_name
    phone
    status
  }
`;
