import { ClientController } from '@aid-package/api';
import { MouseEvent, useCallback, useState } from 'react';

export function useTimerLogoutController() {
  const [loading, setLoading] = useState(false);

  const onContinue = useCallback(() => {
    setLoading(true);
    ClientController.continueSession();
  }, []);

  const onLogout = useCallback(() => {
    ClientController.stopWSSWorker();
    window.location.href = `${process.env.REACT_APP_API_HOST}/auth/logout?redirect_uri=${process.env.REACT_APP_API_HOST}/auth/login?redirect_uri=${window.location.href}&client_id=912f3a10-95b5-4ea4-90c7-7bda8aa6d947`;
  }, []);

  const stopEvent = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
  }, []);

  return { onContinue, onLogout, stopEvent, loading };
}
