import { ContentLoader } from '../ContentLoader';
import { TinyButtonLoaderViewProps } from './types';

export function TinyButtonLoader({ width, height }: TinyButtonLoaderViewProps) {
  return (
    <ContentLoader
      width={width ? `${width}px` : '100%'}
      height={`${height || 24.3}px`}
    >
      <rect
        width={width ? `${width}px` : '100%'}
        height={`${height || 24.3}px`}
        rx="4"
      />
    </ContentLoader>
  );
}
