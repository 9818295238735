import { AutomaticTooltip } from '../../../AutomaticTooltip';
import { Body, Button } from './components';
import { ProfilesViewProps } from './types';

export function Profiles({ profiles, count }: ProfilesViewProps) {
  if (!(count <= 0)) {
    return (
      <AutomaticTooltip
        view={<Button count={count} />}
        background="white-primary"
        border="main-deep-2"
        pattern="top,right,left,bottom"
        padding="12px 6px 12px 12px"
      >
        <Body profiles={profiles} />
      </AutomaticTooltip>
    );
  }

  return null;
}
