import { VariablesStateParams } from '../../controller/types';
import { ProviderDelegate } from './types';

export class SessionStorageProvider implements ProviderDelegate {
  static SAVED_SESSIONSTORAGE_KEY = 'saved_filters';

  private saved: VariablesStateParams;
  private key: string;

  constructor(key: string) {
    this.key = key;
    this.saved = JSON.parse(
      sessionStorage.getItem(
        `${SessionStorageProvider.SAVED_SESSIONSTORAGE_KEY}_${key}`
      ) || '{}'
    );
  }

  //TODO
  private getNextFilters(next: VariablesStateParams) {
    const { q, staticFilters, ...anyFilters } = next;
    return {
      ...anyFilters,
      staticFilters: staticFilters
        ? {
            ...staticFilters,
            ...(staticFilters.mass_screening_id
              ? { mass_screening_id: undefined }
              : {}),
            ...(staticFilters.fincen_list_ids
              ? { fincen_list_ids: undefined }
              : {}),
            ...(staticFilters.form_ref ? { form_ref: undefined } : {}),
          }
        : undefined,
    };
  }

  saveValueByKey(next: VariablesStateParams): void {
    sessionStorage.setItem(
      `${SessionStorageProvider.SAVED_SESSIONSTORAGE_KEY}_${this.key}`,
      JSON.stringify(this.getNextFilters(next))
    );
    this.saved = next;
  }

  getSavedByKey(): VariablesStateParams | undefined {
    return this.saved;
  }

  static init(key: string) {
    return new SessionStorageProvider(key);
  }
}
