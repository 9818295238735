import { PropsWithChildren, createContext, useContext } from 'react';
import { PagginationContextType, PagginationProviderViewProps } from './types';
import { usePagginationProviderController } from './controller';

const PagginationContext = createContext<PagginationContextType<any>>({
  queryParams: {
    page: 1,
    per: 20,
  },
  data: {} as any,
  loading: true,
  changePage(next: number) {
    throw new Error('Function not implemented.');
  },
  changePerPage(next: number) {
    throw new Error('Function not implemented.');
  },
  clean() {
    throw new Error('Function not implemented.');
  },
});

export function usePagginationContext<T>() {
  const context = useContext(PagginationContext) as PagginationContextType<T>;
  return context;
}

export function PagginationProvider<T>({
  children,
  query,
  parseVariables,
}: PropsWithChildren<PagginationProviderViewProps>) {
  const context = usePagginationProviderController<T>(query, parseVariables);

  return (
    <PagginationContext.Provider value={context as PagginationContextType<T>}>
      {children}
    </PagginationContext.Provider>
  );
}
