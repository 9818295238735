import { ButtonViewProps } from '../types';
import { ButtonByType } from '../components';

export function SecondaryButton(props: ButtonViewProps) {
  return (
    <ButtonByType
      {...props}
      color="main-deep-4"
      background="white-primary"
      border="main-deep-4"
    />
  );
}
