import { MouseEvent, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { ModalBodyViewProps } from '@aid-module/ui';
import { useActionsModel } from '../model';
import { FormProps } from '../../../types';

export function useActionsController(onClose: ModalBodyViewProps['onClose']) {
  const { createDraftClient, loading } = useActionsModel(onClose);
  const { formState, handleSubmit } = useFormContext<FormProps>();

  const onSubmit = useCallback((values: FormProps) => {
    createDraftClient({ client_draft: values });
  }, [createDraftClient]);

  const onClickSubmit = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      handleSubmit(onSubmit)(e);
    },
    [handleSubmit, onSubmit]
  );

  return { onClickSubmit, disabled: !formState.isDirty || loading };
}
