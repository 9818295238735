import { PERMISSIONS } from '@aid-package/context';

export const CheckupKindPermissions: Record<string, string> = {
  aid_email_regexp: PERMISSIONS.AID_CHECKUPS_PERFORM,
  comply_advantage_aml: PERMISSIONS.CA_AML_INDIVIDUAL,
  comply_advantage_aml_business: PERMISSIONS.CA_AML_BUSINESS,
  unit_21_entity_id: PERMISSIONS.UNIT_21_KYB,
  unit_21_entity_doc: PERMISSIONS.UNIT_21_KYB_DOC,
  unit_21_kyb: PERMISSIONS.UNIT_21_KYB_DOC,
  elliptic_wallet: PERMISSIONS.ELLIPTIC,
  maxmind_geo_ip: PERMISSIONS.MAXMIND_GEO_IP,
  shuftipro_kyb: PERMISSIONS.BUS_REGISTRY,
  shuftipro_aml: PERMISSIONS.AML_INDIVIDUAL,
  shuftipro_aml_business: PERMISSIONS.AML_BUSINESS,
  fincen_person: PERMISSIONS.FINCEN_BUSINESS,
  fincen_business: PERMISSIONS.FINCEN_INDIVIDUAL,
};
