import { FiltersViewProps } from './types';
import { useFiltersController } from './controller';
import { NavigationContext } from './context';
import { DefaultFilters, NavigationView } from './components';

export function Filters({ toggleShowFilters }: FiltersViewProps) {
  const { context, isFilterTemplatesExist } =
    useFiltersController(toggleShowFilters);

  return (
    <NavigationContext.Provider value={context}>
      {isFilterTemplatesExist ? <NavigationView /> : <DefaultFilters />}
    </NavigationContext.Provider>
  );
}
