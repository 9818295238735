import gql from 'graphql-tag';
import { AUDIT_CONFIG_FRAGMENT } from '../../../fragment/checkup';
import { CHECKUP_CONFIG_FRAGMENT } from '../../../fragment/checkup/CheckupConfig';

export const AuditConfigs = gql`
  ${AUDIT_CONFIG_FRAGMENT}
  query AuditConfigs(
    $all_revisions: Boolean
    $external_source_ref: String
    $external_source_id: String
    $page: Int
    $q: String
  ) {
    auditConfigs(
      all_revisions: $all_revisions
      external_source_ref: $external_source_ref
      external_source_id: $external_source_id
      page: $page
      per: 20
      q: $q
    )
      @rest(
        type: "ResponseAuditConfigs"
        path: "/checkups/client/audit_configs?{args}"
      ) {
      data @type(name: "AuditConfig") {
        ...AuditConfigFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const AuditConfig = gql`
  ${AUDIT_CONFIG_FRAGMENT}
  ${CHECKUP_CONFIG_FRAGMENT}
  query AuditConfig($id: String!) {
    auditConfig(id: $id)
      @rest(
        type: "ResponseAuditConfig"
        path: "/checkups/client/audit_configs/{args.id}"
      ) {
      data @type(name: "AuditConfig") {
        ...AuditConfigFragment
        account @type(name: "Account") {
          email
          first_name
          id
          last_name
        }
        checkup_configs @type(name: "CheckupConfig") {
          ...CheckupConfigFragment
        }
      }
      success
    }
  }
`;
