import { Filters, FilterTemplates, EditFilterTemplate } from './components';
import { useComponentByTypeController } from './controller';

export function ComponentByType() {
  const { type } = useComponentByTypeController();
  switch (type) {
    case 'filters':
      return <Filters />;
    case 'filterTemplates':
      return <FilterTemplates />;
    case 'filterTemplatesEdit':
      return <EditFilterTemplate />;
    default:
      return null;
  }
}
