import styled from '@emotion/styled';
import { getColor } from '../../../../../../utils';

export const SvgText = styled.text`
  font-family: Manrope;
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 24px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: ${getColor('main-deep-1')};
`;
