import { PropsWithChildren } from 'react';
import loadable from '@loadable/component';
import { DropdownLoader } from '../../shared';
import { LoadableFunctionalComponent } from '../../utils';
import { DropdownButtonComponentViewProps } from './component/types';

export const DropdownButton = loadable(() => import('./component'), {
  resolveComponent: (components) => components.DropdownButtonComponent,
  fallback: <DropdownLoader height={32.2} />,
}) as LoadableFunctionalComponent<
  PropsWithChildren<DropdownButtonComponentViewProps>
>;

export * from './component/context';
export type { DropdownButtonViewProps } from './component/types';
