import gql from 'graphql-tag';

export const TRANSLATION_FRAGMENT = gql`
  fragment TranslationFragment on Translation {
    ibm_order @type(name: "TranslationOrder") {
      id
      document_id
    }
    translated_order @type(name: "TranslationOrder") {
      id
      document_id
    }
    addition @type(name: "TranslationAddition") {
      name
    }
    client_id
    created_at
    field_submission_id
    id
    order_kind
    source_document_file_name
    source_document_file_path
    source_document_file_size
    source_document_id
    source_kind
    source_lang
    source_text
    state
    target_document_file_name
    target_document_file_path
    target_document_file_size
    target_document_id
    target_lang
    target_text
    updated_at
  }
`;
