import gql from 'graphql-tag';
import { ACCOUNT_STATS_FRAGMENT } from '../../../fragment/account/stats';

export const AccountStats = gql`
  ${ACCOUNT_STATS_FRAGMENT}
  query AccountStats($client_id: String) {
    accountStats(client_id: $client_id)
      @rest(type: "ResponseAccountStats", path: "/stats/client?{args}") {
      data @type(name: "AccountStats") {
        ...AccountStatsFragment
      }
      success
    }
  }
`;
