export const TRANSACTION_STATUSES = {
  COMPLETED: 'completed',
  FAILED: 'failed',
  PENDING: 'pending',
  REJECTED: 'rejected',
  OFAC_CHECKING: 'ofac_checking',
};

export const TRANSACTION_DISPLAY_STATUSES = {
  [TRANSACTION_STATUSES.COMPLETED]: 'Completed',
  [TRANSACTION_STATUSES.FAILED]: 'Failed',
  [TRANSACTION_STATUSES.PENDING]: 'Pending',
  [TRANSACTION_STATUSES.OFAC_CHECKING]: 'Ofac Checking',
  [TRANSACTION_STATUSES.REJECTED]: 'Rejected',
};
