import gql from 'graphql-tag';
import { USER_ACCOUNT_FRAGMENT } from '../account/users';
import { CUSTOMER_FRAGMENT } from './Customer';
import { ACCOUNT_FRAGMENT } from '../admin/accounts';

export const CASE_FRAGMENT = gql`
  ${USER_ACCOUNT_FRAGMENT}
  ${CUSTOMER_FRAGMENT}
  ${ACCOUNT_FRAGMENT}
  fragment CaseFragment on Case {
    account @type(name: "Account") {
      avatar_id
      email
      file_name
      file_size
      full_name
      id
      phone
    }
    assigned_to @type(name: "Account") {
      email
      file_name
      file_size
      full_name
      id
      phone
    }
    data_retention @type(name: "DataRetentionInfo") {
      full_delete_date
      period_start_date
      retention_plan_id
      retention_state
      soft_delete_date
    }
    approved_at
    audit_repeat_interval
    auto_audit_state
    case_sections_count
    case_sharings_count
    created_at
    entity_type
    external_id
    last_submission_completed_at
    customer @type(name: "Customer") {
      ...CustomerFragment
    }
    id
    is_ongoing
    is_remindable
    is_whitelisted
    profile_display_name
    purpose
    score
    scoring_conflicts_count
    state
    updated_at
    open_required_action_count
    bank_tariff_name
    status_onboarding_fee
    total_onboarding_fee
    profiles @type(name: "Profile") {
      avatar_id
      created_at
      display_name
      email
      id
      is_whitelisted
      kind
      phone
      updated_at
    }
    edd_reports @type(name: "EDDReport") {
      assigned_to_id
      case_ids
      client_id
      completed_at
      created_at
      description
      form_configuration_id
      form_submission_id
      id
      main_profile_id
      main_profile @type(name: "Profile") {
        avatar_id
        created_at
        display_name
        email
        id
        is_whitelisted
        kind
        phone
        updated_at
      }
      profile_kind
      status
      updated_at
    }
  }
`;
