import { ContentLoader, GridContainer } from '@aid-module/ui';

export function Fallback() {
  return (
    <GridContainer gap="20">
      <ContentLoader width="100%" height="69.2">
        <rect rx="8" ry="8" width="100%" height="69.2" />
      </ContentLoader>
      <ContentLoader width="100%" height="26">
        <rect rx="8" ry="8" width="100%" height="26" />
      </ContentLoader>
      <GridContainer gridTemplateColumns="repeat(4, 1fr)" gap="20">
        <ContentLoader width="100%" height="200">
          <rect rx="8" ry="8" width="100%" height="200" />
        </ContentLoader>
        <ContentLoader width="100%" height="200">
          <rect rx="8" ry="8" width="100%" height="200" />
        </ContentLoader>
        <ContentLoader width="100%" height="200">
          <rect rx="8" ry="8" width="100%" height="200" />
        </ContentLoader>
        <ContentLoader width="100%" height="200">
          <rect rx="8" ry="8" width="100%" height="200" />
        </ContentLoader>
      </GridContainer>
      <ContentLoader width="100%" height="26">
        <rect rx="8" ry="8" width="100%" height="26" />
      </ContentLoader>
      <ContentLoader width="100%" height="494.95">
        <rect rx="8" ry="8" width="100%" height="494.95" />
      </ContentLoader>
      <GridContainer alignItems="start" gap="20" gridTemplateColumns="1fr 1fr">
        <GridContainer gap="20">
          <ContentLoader width="100%" height="26">
            <rect rx="8" ry="8" width="100%" height="26" />
          </ContentLoader>
          <ContentLoader width="100%" height="683.73">
            <rect rx="8" ry="8" width="100%" height="683.73" />
          </ContentLoader>
        </GridContainer>
        <GridContainer gap="20">
          <ContentLoader width="100%" height="26">
            <rect rx="8" ry="8" width="100%" height="26" />
          </ContentLoader>
          <ContentLoader width="100%" height="2589.74">
            <rect rx="8" ry="8" width="100%" height="2589.74" />
          </ContentLoader>
        </GridContainer>
      </GridContainer>
    </GridContainer>
  );
}
