import { DefaultButtonLoader, GridContainer, InputLoader } from '@aid-module/ui';

export function Fallback() {
  return (
    <GridContainer gap="20">
      <InputLoader />
      <InputLoader />
      <InputLoader />
      <GridContainer gap="20" gridTemplateColumns="1fr 1fr 1fr">
        <DefaultButtonLoader />
        <DefaultButtonLoader />
        <DefaultButtonLoader />
      </GridContainer>
    </GridContainer>
  );
}
