import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  gql,
} from '@apollo/client';
import { MeQuery, PermissionsQuery } from '../../api';
import { createNewClient, getNewInmemoryCache } from '../../client';
import { Me, MyFeatures, MyPermissions, Ping, RetentionMe } from '../../query';
import axios from 'axios';
import {
  SessionWorker,
  ResponseWorkerMessages,
  ResponseMessageTypes,
} from 'fe-auth-worker';

const API_HOST = process.env.REACT_APP_API_HOST || process.env.API_HOST;
const WSS_PATH = process.env.REACT_APP_WSS_HOST;
const CLOSE_WSS = process.env.REACT_APP_CLOSE_WSS;
const CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID;

type ClientIcons = {
  ids: Record<string, boolean>;
  array: string[];
};

interface Subscription {
  closed: boolean;
  unsubscribe(): void;
}

class ClientControllerClass {
  appoloClient: ApolloClient<NormalizedCacheObject> = createNewClient();
  client_id?: string;
  private client_icons?: string[];
  private subscription?: Subscription;
  $me?: MeQuery | undefined;

  private wssWorker?: SessionWorker;

  get me() {
    return this.$me;
  }

  set me(next: MeQuery | undefined) {
    this.$me = next;
    this.client_icons = this.getClientIcons(next);
  }

  onUpdateClient?: (client: ApolloClient<NormalizedCacheObject>) => void;

  onUpdateLoading?: (loading: boolean) => void;

  onUpdateTick?: (type: 'tick' | 'close', time: number) => void;

  onDestroy?: () => void;

  constructor(client_id?: string) {
    this.client_id = client_id;
    this.initWatchers();
  }

  initWatchers() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    const watcher = this.appoloClient.watchQuery<MeQuery>({
      query: Me,
    });
    this.subscription = watcher.subscribe((data) => {
      this.me = data.data;
      this.initWorker();
      this.subscription?.unsubscribe();
      this.subscription = undefined;
    });
  }

  private async initWorker() {
    if (!this.wssWorker && !CLOSE_WSS && WSS_PATH) {
      const res = await axios.get(`${API_HOST}/auth/ws_credentials`, {
        withCredentials: true,
      });
      if (res.data?.success && res.data?.data?.session_token) {
        this.wssWorker = new SessionWorker();
        this.wssWorker.onMessage = (msg: ResponseWorkerMessages) => {
          if (
            msg.source === 'session-worker' &&
            msg.type === ResponseMessageTypes.UPDATE_TIME
          ) {
            this.onUpdateTick?.(
              msg.message?.time === undefined ? 'close' : 'tick',
              msg.message?.time || 0
            );
          } else if (
            msg.source === 'session-worker' &&
            msg.type === ResponseMessageTypes.FINISH
          ) {
            window.location.href = `${API_HOST}/auth/login?redirect_uri=${window.location.href}`;
          } else if (
            msg.source === 'session-worker' &&
            msg.type === ResponseMessageTypes.NEED_PING
          ) {
            this.appoloClient.query({
              query: Ping,
              fetchPolicy: 'no-cache',
            });
          } else if (
            msg.source === 'session-worker' &&
            msg.type === ResponseMessageTypes.NEED_UPDATE_USER
          ) {
            this.appoloClient.refetchQueries({ include: [Me] });
          }
        };
        this.wssWorker.connect({
          session_token: res.data.data.session_token,
          path: `${WSS_PATH}/ws`,
          client_id: CLIENT_ID || '',
        });
      } else {
        this.appoloClient.stop();
        this.appoloClient.resetStore();
        this.onDestroy?.();
      }
    }
  }

  async continueSession() {
    await this.appoloClient.query({
      query: Ping,
      fetchPolicy: 'no-cache',
    });
    this.wssWorker?.refreshTime();
  }

  stopWSSWorker() {
    this.wssWorker?.destroy();
  }

  private getClientIcons(next?: MeQuery) {
    if (next) {
      const ids = (next.me.data.accounts_clients || []).reduce<ClientIcons>(
        (acc, accountClient) => {
          const id = accountClient?.client?.logo_id;
          if (id && !acc.ids[id]) {
            return {
              ids: { ...acc.ids, [id]: true },
              array: [...acc.array, id],
            };
          }
          return acc;
        },
        { ids: {}, array: [] }
      );

      if (next.me.data.avatar_id && !ids.ids[next.me.data.avatar_id]) {
        ids.array.push(next.me.data.avatar_id);
      }

      return ids.array;
    }
    return [];
  }

  private updateNewQueryCache(
    prevClient: ApolloClient<NormalizedCacheObject>,
    nextCache: InMemoryCache
  ) {
    if (this.me) {
      nextCache.writeQuery({
        query: Me,
        data: this.me,
      });
    }
    if (this.client_icons && this.client_icons.length) {
      this.client_icons.forEach((id) => {
        const doc = prevClient.readFragment({
          id: `Document:${id}`,
          fragment: gql`
            fragment DocumentFragment on Document {
              file_size
              file_path
              file_name
              safe_download_url
              av_status
              id
            }
          `,
        });
        nextCache.writeFragment({
          id: `Document:${id}`,
          fragment: gql`
            fragment DocumentFragment on Document {
              file_size
              file_path
              file_name
              safe_download_url
              av_status
              id
            }
          `,
          data: doc,
        });
      });
    }
  }

  private async generateNewAppoloClient() {
    return new Promise<ApolloClient<NormalizedCacheObject>>(async (res) => {
      this.appoloClient.stop();
      const prevClient = this.appoloClient;
      const inmemoryCache = getNewInmemoryCache();
      this.updateNewQueryCache(prevClient, inmemoryCache);
      const newClient = createNewClient(inmemoryCache);
      const permissions = await newClient.query({
        query: MyPermissions,
      });
      if (permissions.data?.myPermissions?.data) {
        const clientPermissions: PermissionsQuery['permissions']['data'][0] =
          permissions.data.myPermissions.data.find(
            (
              premissionGroup: NonNullable<
                PermissionsQuery['permissions']['data'][0]
              >
            ) => premissionGroup.name === 'Client'
          );
        if (clientPermissions) {
          const dataRetensionPermission = clientPermissions.permissions?.find(
            (permission) => permission?.code === 'manage_data_retention'
          );
          if (dataRetensionPermission) {
            await newClient.query({
              query: RetentionMe,
            });
          }
        }
      }
      await newClient.query({
        query: MyFeatures,
      });
      prevClient.clearStore();
      res(newClient);
    });
  }

  async changeClientId(client_id?: string) {
    if (
      client_id === 'define_client' ||
      this.client_id === 'define_client' ||
      !this.me?.me?.data?.accounts_clients?.find(
        (it) => it?.client?.id === this.client_id
      )
    ) {
      this.client_id = client_id;
    } else if (this.client_id !== client_id) {
      this.onUpdateLoading?.(true);
      this.client_id = client_id;
      this.appoloClient = await this.generateNewAppoloClient();
      this.onUpdateClient?.(this.appoloClient);
      this.onUpdateLoading?.(false);
    }
  }
}

export const ClientController = new ClientControllerClass();
