import {
  GLOBAL_SEARCH_PERMISSIONS,
  useCheckPermission,
} from '@aid-package/context';

export function useHeaderController() {
  const { canShow: canShowGlobalSearch } = useCheckPermission(
    GLOBAL_SEARCH_PERMISSIONS,
    false
  );

  return {
    canShowGlobalSearch,
  };
}
