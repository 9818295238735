import gql from 'graphql-tag';

export const TRANSACTION_INSTRUMENT_FRAGMENT = gql`
  fragment TransactionInstrumentFragment on TransactionInstrument {
    account_no
    address
    arival_external_id
    bank_address
    bank_city
    bank_country
    bank_name
    bank_postal_code
    city
    country
    created_at
    currency
    email
    entity_id
    entity_name
    source
    id
    im_bank_name
    instructing_bank_name
    kind
    postal_code
    routing_no
    routing_type
    sender_bank_name
    status
    unit21_external_id
    unit21_instrument_external_id
  }
`;
