import { ButtonByType } from '../components';
import { ButtonViewProps } from '../types';
import { PropsWithChildren } from 'react';

export function PrimaryButton(props: PropsWithChildren<ButtonViewProps>) {
  return (
    <ButtonByType {...props} color="white-primary" background="main-deep-4">
      {props.children}
    </ButtonByType>
  );
}
