import { FlexContainer, getColor, getSpace } from '@aid-module/ui';
import styled from '@emotion/styled';

export const Container = styled(FlexContainer)`
  position: relative;
`;

export const IconContainer = styled(FlexContainer)`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${getColor('main-deep-1')};
  }
`;

export const NotEmpty = styled(FlexContainer)`
  top: 8px;
  right: 10px;
  position: absolute;
  border: 1px solid ${getColor('white-primary')};
  background: ${getColor('error-deep-3')};
  width: 6px;
  height: 6px;
  border-radius: 50%;
`;

export const NotificationContainer = styled.div`
  position: absolute;
  top: calc(100% + ${getSpace('10')});
  right: -20px;
  z-index: 31;
  width: 400px;
`;
