import { gql } from '@apollo/client';
import { EDD_REPORT_FRAGMENT } from '../../fragment/edd/eddReport';

export const CreateEDDReport = gql`
  ${EDD_REPORT_FRAGMENT}
  mutation CreateEDDReport($body: BodyCreateEDDReport!) {
    createEDDReport(body: $body)
      @rest(
        type: "EDDReportCreate"
        path: "/core/client/edd_reports"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "EDDReport") {
        ...EDDReportFragment
      }
      success
    }
  }
`;

export const UnlinkFromCaseEDDReport = gql`
  mutation UnlinkFromCaseEDDReport(
    $id: String!
    $body: BodyLinkOrUnlinkToEDDReport!
  ) {
    unlinkFromCaseEDDReport(id: $id, body: $body)
      @rest(
        type: "UnlinkFromCaseEDDReport"
        path: "/core/client/edd_reports/{args.id}/unlink_from_case"
        bodyKey: "body"
        method: "PUT"
      ) {
      success
    }
  }
`;

export const LinkToCaseEDDReport = gql`
  mutation LinkToCaseEDDReport(
    $id: String!
    $body: BodyLinkOrUnlinkToEDDReport!
  ) {
    linkToCaseEDDReport(id: $id, body: $body)
      @rest(
        type: "LinkToCaseEDDReport"
        path: "/core/client/edd_reports/{args.id}/link_to_case"
        bodyKey: "body"
        method: "PUT"
      ) {
      success
    }
  }
`;

export const LinkToProfileEDDReport = gql`
  mutation LinkToProfileEDDReport(
    $id: String!
    $body: BodyLinkOrUnlinkToProfileEDDReport!
  ) {
    linkToProfileEDDReport(id: $id, body: $body)
      @rest(
        type: "LinkToProfileEDDReport"
        path: "/core/client/edd_reports/{args.id}/link_to_profile"
        bodyKey: "body"
        method: "PUT"
      ) {
      success
    }
  }
`;

export const UnlinkFromProfileEDDReport = gql`
  mutation UnlinkFromProfileEDDReport(
    $id: String!
    $body: BodyLinkOrUnlinkToProfileEDDReport!
  ) {
    unlinkFromProfileEDDReport(id: $id, body: $body)
      @rest(
        type: "UnlinkFormProfileEDDReport"
        path: "/core/client/edd_reports/{args.id}/unlink_from_profile"
        bodyKey: "body"
        method: "PUT"
      ) {
      success
    }
  }
`;

export const AssignToEDDReport = gql`
  mutation AssignToEDDReport($id: String!, $body: BodyAssignToEDDReport!) {
    assignToEDDReport(id: $id, body: $body)
      @rest(
        type: "AssignToEDDReport"
        path: "/core/client/edd_reports/{args.id}/assign"
        bodyKey: "body"
        method: "PUT"
      ) {
      success
    }
  }
`;

export const CreateEDDReportDocument = gql`
  mutation CreateEDDReportDocument(
    $body: BodyCreateOrUpdateEDDReportDocument!
  ) {
    createEDDReportDocument(body: $body)
      @rest(
        type: "EDDReportCreateDocument"
        path: "/core/client/edd_report_documents"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "EDDReport") {
        id
        edd_report_id
        created_at
        update_at
        description
        uploaded_document_id
      }
      success
    }
  }
`;

export const UpdateEDDReportDocument = gql`
  mutation UpdateEDDReportDocument(
    $id: String!
    $body: BodyCreateOrUpdateEDDReportDocument!
  ) {
    updateEDDReportDocument(id: $id, body: $body)
      @rest(
        type: "EDDReportUpdateDocument"
        path: "/core/client/edd_report_documents/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "EDDReport") {
        id
        edd_report_id
        created_at
        update_at
        description
        uploaded_document_id
      }
      success
    }
  }
`;

export const DeleteEDDReportDocument = gql`
  mutation DeleteEDDReportDocument($id: String!) {
    deleteEDDReportDocument(id: $id)
      @rest(
        type: "EDDReportDeleteDocument"
        path: "/core/client/edd_report_documents/{args.id}"
        method: "DELETE"
      ) {
      success
    }
  }
`;
