import gql from 'graphql-tag';

export const CdsCableAddresses = gql`
  query CdsCableAddresses(
    $page: Int
    $per: Int
    $user_id: String
    $company_id: String
  ) {
    cdsCableAddresses(
      page: $page
      per: $per
      user_id: $user_id
      company_id: $company_id
    )
      @rest(
        type: "ResponseCdsCableAddresses"
        path: "/cds/cable/addresses?{args}"
      ) {
      data @type(name: "CdsCableAddress") {
        id
        address_lines
        address_type
        city
        client_id
        company_id
        country
        created_at
        entity_template_id
        postal_code
        state
        submission_index
        updated_at
        user_id
      }
      meta {
        count
        page
        items
        pages
      }
      success
    }
  }
`;
