import { Copyright } from './components';
import { Container } from './styles';

export function Footer() {
  return (
    <Container
      justifyContent="center"
      alignItems="center"
    >
      <Copyright />
    </Container>
  );
}
