import { Link } from '../Link';
import { MultiplePathsLinkContainer } from '../MultiplePathsLinkContainer';
import { useTMSettingsLinkController } from './controller';

export function TMSettingsLink() {
  const { tmSettingsPaths, mainPath } = useTMSettingsLinkController();

  return (
    <MultiplePathsLinkContainer paths={tmSettingsPaths}>
      <Link to={mainPath} text="Settings" />
    </MultiplePathsLinkContainer>
  );
}
