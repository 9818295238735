import gql from 'graphql-tag';

export const CreateProhibitedCountry = gql`
  mutation CreateProhibitedCountry($body: BodyCreateProhibitedCountry!) {
    createProhibitedCountry(body: $body)
      @rest(
        type: "CreateProhibitedCountry"
        path: "/core/client/prohibited_countries"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "ProhibitedCountry") {
        id
        deleted_at
        dictionary_item @type(name: "ProhibitedCountryDictionaryItem") {
          id
          name
          value
        }
      }
      success
    }
  }
`;

export const DeleteProhibitedCountry = gql`
  mutation DeleteProhibitedCountry($id: String!) {
    deleteProhibitedCountry(id: $id)
      @rest(
        type: "DeleteProhibitedCountry"
        path: "/core/client/prohibited_countries/{args.id}"
        method: "DELETE"
      ) {
      success
    }
  }
`;
