import { useCallback, useEffect, useRef, useState } from 'react';
import { DragStart, DragUpdate, DropResult } from 'react-beautiful-dnd';
import { useDroppableModel } from '../model';
import { DroppableViewProps } from '../types';
import { PlaceholderProps } from './types';

export function useDroppableController(
  template: DroppableViewProps['templates']
) {
  const [renderTemplates, setTemplates] = useState(template);
  const [placeholderProps, setPlaceholderProps] = useState<PlaceholderProps>();
  const { updateTabRegistry } = useDroppableModel();
  const getDraggedDomRef = useRef((draggableId: string) => {
    const domQuery = `[data-rbd-drag-handle-draggable-id='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    return draggedDOM;
  });

  useEffect(() => {
    setTemplates(template);
  }, [template]);

  const getClientY = useRef(
    (index: number, parent: Element, array: Element[]) => {
      const childrenHeight = array.slice(0, index).reduce((total, curr) => {
        const element = curr as HTMLElement;
        if (element) {
          const style =
            (element as any).currentStyle || window.getComputedStyle(element);
          const marginBottom = parseFloat(style.marginBottom);
          return (
            total +
            element.clientHeight +
            (style.display !== 'none' ? marginBottom : 0)
          );
        }
        return total;
      }, 0);

      return (
        parseFloat(window.getComputedStyle(parent).paddingTop) + childrenHeight
      );
    }
  );

  const handleDragStart = useCallback((event: DragStart) => {
    const draggedDOM = getDraggedDomRef.current(event.draggableId)
      ?.parentNode as Element;
    if (draggedDOM) {
      const { clientHeight, clientWidth } = draggedDOM;
      const parent = draggedDOM?.parentNode as Element;
      if (parent) {
        const sourceIndex = event.source.index;
        var clientY = getClientY.current(
          sourceIndex,
          parent,
          Array.from(parent.children)
        );
        setPlaceholderProps({
          clientHeight,
          clientWidth,
          clientY,
          clientX: parseFloat(window.getComputedStyle(parent).paddingLeft),
        });
      }
    }
  }, []);

  const handleDragUpdate = useCallback((event: DragUpdate) => {
    if (!event.destination) {
      return;
    }

    const draggedDOM = getDraggedDomRef.current(event.draggableId)
      ?.parentNode as Element;
    if (draggedDOM) {
      const { clientHeight, clientWidth } = draggedDOM;
      const parent = draggedDOM?.parentNode as Element;

      if (parent) {
        const destinationIndex = event.destination.index;
        const sourceIndex = event.source.index;

        const childrenArray = Array.from(parent.children);
        const movedItem = childrenArray[sourceIndex];
        childrenArray.splice(sourceIndex, 1);
        const updatedArray = [
          ...childrenArray.slice(0, destinationIndex),
          movedItem,
          ...childrenArray.slice(destinationIndex + 1),
        ];

        var clientY = getClientY.current(
          destinationIndex,
          parent,
          updatedArray
        );

        setPlaceholderProps({
          clientHeight,
          clientWidth,
          clientY,
          clientX: parseFloat(window.getComputedStyle(parent).paddingLeft),
        });
      }
    }
  }, []);

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      setPlaceholderProps(undefined);
      setTemplates((renderTemplates) => {
        if (!result.destination) {
          return renderTemplates;
        }
        const array = [...renderTemplates];
        const [removed] = array.splice(result.source.index, 1);
        array.splice(result.destination.index, 0, removed);
        const body = {
          position: result.destination.index,
        };
        updateTabRegistry(removed.id, body);
        return array;
      });
    },
    [updateTabRegistry]
  );

  return {
    handleDragEnd,
    handleDragStart,
    handleDragUpdate,
    placeholderProps,
    renderTemplates,
  };
}
