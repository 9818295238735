import dayjs from 'dayjs';
import { useMemo } from 'react';
import { isMultiValue, useConfigContext } from '../../../../../../context';
import { useFilterValuesModel } from '../model';
import { FilterValuesProps } from './types';
import { DateFormat } from '../../../../../../../../utils';

export function useFilterValuesController() {
  const { filters } = useFilterValuesModel();
  const filtersConfig = useConfigContext();

  const selectedFilters = useMemo(
    () =>
      Object.entries(filters || {}).reduce<FilterValuesProps[]>((acc, item) => {
        const [key, value] = item;
        if (!value || !filtersConfig?.[key]) {
          return acc;
        }
        if (typeof value === 'object') {
          if (isMultiValue(value)) {
            return [
              ...acc,
              {
                label: filtersConfig?.[key].label || '-',
                value: value.map((option) => option.label).join(', '),
                key,
              },
            ];
          } else if (Array.isArray(value)) {
            return [
              ...acc,
              {
                label: filtersConfig?.[key].label || '-',
                value: value.join(', '),
                key,
              },
            ];
          }
          return [
            ...acc,
            {
              label: filtersConfig?.[key].label || '-',
              value: value.label,
              key,
            },
          ];
        }
        if (
          typeof value === 'string' &&
          isNaN(Number(value)) &&
          dayjs(value).isValid()
        ) {
          return [
            ...acc,
            {
              label: filtersConfig?.[key].label || '-',
              value: DateFormat.formatDate(value),
              key,
            },
          ];
        }
        return [
          ...acc,
          {
            label: filtersConfig?.[key].label || '-',
            value: value.toString(),
            key,
          },
        ];
      }, []),
    [filters]
  );

  return { selectedFilters };
}
