import { MouseEvent, useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useConfigContext } from '../../../../../../../../../../context';
import { useNavigationContext } from '../../../../../context';
import { useFormModel } from '../model';
import { FieldWithName } from './types';

export function useFormController() {
  const filtersConfig = useConfigContext();
  const { toggleShowFilters } = useNavigationContext();
  const { variables, set, clear } = useFormModel();
  const defaultValues = useMemo(
    () =>
      Object.keys(filtersConfig || {}).reduce((acc, key) => {
        return {
          ...acc,
          [key]: variables?.filters?.[key] || null,
        };
      }, {}),
    [variables]
  );
  const { handleSubmit, ...formContext } = useForm({ defaultValues });

  useEffect(() => {
    formContext.reset(defaultValues);
  }, [defaultValues]);

  const onClickClear = useCallback(() => {
    clear();
    toggleShowFilters();
  }, [toggleShowFilters, clear]);

  const onSubmit = useCallback(
    (variables: any) => {
      set(variables);
      toggleShowFilters();
    },
    [set, toggleShowFilters]
  );

  const onClickSubmit = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      handleSubmit(onSubmit)(e);
    },
    [handleSubmit, onSubmit]
  );

  const filterInputs = useMemo(
    () =>
      Object.entries(filtersConfig || {}).reduce<FieldWithName[]>(
        (acc, item) => {
          const [key, config] = item;
          return [...acc, { ...config, name: key }];
        },
        []
      ),
    []
  );

  return {
    formContext: { ...formContext, handleSubmit },
    onClickClear,
    toggleShowFilters,
    onClickSubmit,
    filterInputs,
  };
}
