import gql from 'graphql-tag';

export const IDENFY_RAW_RESULT_CLIENT_FRAGMENT = gql`
  fragment IdenfyRawResultClientFragment on IdenfyRawResultClient {
    country
    date_of_birth
    date_of_issue
    default_country
    document_number
    document_type
    expiry_date
    name
    nationality
    personal_id_number
    sex
    surname
    utility_address
    videocallsession
  }
`;
