import {
  TableWithHeaderLoader,
  LoadableFunctionalComponent,
} from '@aid-module/ui';
import loadable from '@loadable/component';

export const ProhibitedCountriesRegister = loadable(
  () => import('./component'),
  {
    resolveComponent: (components) =>
      components.ProhibitedCountriesRegisterComponent,
    fallback: <TableWithHeaderLoader />,
  }
) as LoadableFunctionalComponent<{}>;
