import { GridContainer, Text } from '@aid-module/ui';
import { Card } from '../../../shared.styles';
import { GroupViewProps } from './types';
import { TYPES } from '@aid-package/api';
import { Field } from './components';

function renderField(
  field: NonNullable<
    NonNullable<
      TYPES.FormQuery['form']['data']['form_screens'][0]['form_groups']
    >[0]['form_fields']
  >[0]
) {
  return <Field key={field.id} field={field} />;
}

export function Group({ group }: GroupViewProps) {
  if (!group.options?.system) {
    return (
      <Card background="main-deep-2">
        <GridContainer gap="8">
          <Text
            text={group.name || '-'}
            size={14}
            weight="VeryBig"
            color="main-deep-4"
          />
          {group.form_fields?.map(renderField)}
        </GridContainer>
      </Card>
    );
  }
  return null;
}
