import { LoadableFunctionalComponent } from '@aid-module/ui';
import loadable from '@loadable/component';
import { PropsWithChildren } from 'react';
import { Fallback } from '../Fallback';
import { FormViewProps } from './component/types';

export const Form = loadable(() => import('./component'), {
  resolveComponent: (components) => components.FormComponent,
  fallback: <Fallback />,
}) as LoadableFunctionalComponent<PropsWithChildren<FormViewProps>>;
