import { PropsWithChildren } from 'react';
import { Header, Footer } from './components';
import { MainContainer, OnBoardingGridContainer } from './styles';

export function OnBoardingContainer({ children }: PropsWithChildren) {
  return (
    <MainContainer
      justifyContent="center"
      gridTemplateRows="1fr max-content"
    >
      <OnBoardingGridContainer
        gridTemplateRows="repeat(2, max-content)"
        gap="24"
      >
        <Header />
        {children}
      </OnBoardingGridContainer>
      <Footer />
    </MainContainer>
  );
}
