import { useMemo } from 'react';
import { StatusTagViewProps } from './types';
import { Tag } from '../Count';
import { Text } from '../Text';

export function StatusTag({ config, status }: StatusTagViewProps) {
  const configItem = useMemo(() => config[status], [config, status]);

  if (configItem) {
    return (
      <Tag
        count={configItem.label}
        padding="S"
        size={11}
        background={configItem.color}
      />
    );
  }

  return <Text text="-" size={12} color="main-deep-4" />;
}

export type { StatusTagConfig, StatusTagConfigItem } from './types';
