import { useMemo } from 'react';
import { PagginationOptions } from '../controller/types';
import { defaultParseVariables } from './utils';
import { useVariablesContext } from '../../Veriables';
import { useQuery } from '@aid-package/api';
import { Data, Variables } from './types';
import { PagginationProviderViewProps } from '../types';

export function usePaginationContextModel<T>(
  query: PagginationProviderViewProps['query'],
  parseVariables: PagginationProviderViewProps['parseVariables'],
  pagginationOptions: PagginationOptions
) {
  const { variables } = useVariablesContext();
  const queryVariables = useMemo(
    () => defaultParseVariables(variables, parseVariables),
    [variables, parseVariables]
  );

  const { data, loading, refetch, updateQuery, networkStatus } = useQuery<
    Data<T>,
    Variables
  >(query, {
    fetchPolicy: 'network-only',
    variables: {
      ...pagginationOptions,
      ...(queryVariables || {}),
    },
    notifyOnNetworkStatusChange: true,
  });

  const responseData = useMemo(() => {
    if (data && networkStatus === 7) {
      return Object.values(data)[0];
    }
    return;
  }, [data]);

  return {
    data: responseData,
    loading: loading || networkStatus !== 7,
    refetch,
    updateQuery,
  };
}
