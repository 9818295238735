import { Tag } from '../Count';
import { Text } from '../Text';
import { useCaseStatusController } from './controller';
import { CaseStatusViewProps } from './types';
import {
  CASE_STATUSES,
  CASE_DISPLAY_SCREENING_STATUSES,
  CASE_SCREENING_STATUSES,
  CASE_DISPLAY_STATUSES,
} from './constants';

function CaseStatus({ status, isAudit, isWhitelisted }: CaseStatusViewProps) {
  const { background, text } = useCaseStatusController(
    status,
    isAudit,
    isWhitelisted
  );

  if (text) {
    return <Tag count={text} padding="S" size={11} background={background} />;
  }

  return <Text text="-" size={12} color="main-deep-4" />;
}

export {
  CaseStatus,
  CASE_STATUSES,
  CASE_DISPLAY_SCREENING_STATUSES,
  CASE_SCREENING_STATUSES,
  CASE_DISPLAY_STATUSES,
};
