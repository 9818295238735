import gql from 'graphql-tag';

export const CreateFieldsPreset = gql`
  mutation CreateFieldsPreset($body: BodyCreateOrUpdateFieldsPreset!) {
    createFieldsPreset(body: $body)
      @rest(
        type: "CreateFieldsPreset"
        path: "/core/client/field_presets"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "FieldsPreset") {
        id
        name
        meta
        fields_presets_json
      }
      success
    }
  }
`;

export const UpdateFieldsPreset = gql`
  mutation UpdateFieldsPreset(
    $id: String!
    $body: BodyCreateOrUpdateFieldsPreset!
  ) {
    updateFieldsPreset(id: $id, body: $body)
      @rest(
        type: "UpdateFieldsPreset"
        path: "/core/client/field_presets/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "FieldsPreset") {
        id
        name
        meta
        fields_presets_json
      }
      success
    }
  }
`;

export const DeleteFieldsPreset = gql`
  mutation DeleteFieldsPreset($id: String!) {
    deleteFieldsPreset(id: $id)
      @rest(
        type: "DeleteFieldsPreset"
        path: "/core/client/field_presets/{args.id}"
        method: "DELETE"
      ) {
      success
    }
  }
`;
