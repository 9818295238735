import gql from 'graphql-tag';
import { RESOLUTION_FRAGMENT } from '../../../fragment/account/resolutions';

export const Resolutions = gql`
  ${RESOLUTION_FRAGMENT}
  query Resolutions($page: Int, $q: String) {
    resolutions(page: $page, per: 20, q: $q)
      @rest(
        type: "ResponseResolutions"
        path: "/core/client/resolution_items?{args}"
      ) {
      data @type(name: "Resolution") {
        ...ResolutionFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const Resolution = gql`
  ${RESOLUTION_FRAGMENT}
  query Resolution($id: String!) {
    resolution(id: $id)
      @rest(
        type: "ResponseResolution"
        path: "/core/client/resolution_items?{args.id}"
      ) {
      data @type(name: "Resolution") {
        ...ResolutionFragment
      }
      success
    }
  }
`;
