import { FormCard, GridContainer, FormCardViewProps } from '@aid-module/ui';
import { PropsWithChildren } from 'react';

export function FormContainer({
  isLoading,
  children,
}: PropsWithChildren<Pick<FormCardViewProps, 'isLoading'>>) {
  return (
    <GridContainer gridTemplateColumns="510px" justifyContent="center">
      <FormCard label="deposit" icon="purse" isLoading={isLoading}>
        {children}
      </FormCard>
    </GridContainer>
  );
}
