import { GridContainer } from '../../containers';
import { Card } from '../Card';
import { Icon } from '../Icon';
import { Text } from '../Text';
import { usePlateController } from './controller';
import { PlateViewProps } from './types';

export function Plate({ type }: PlateViewProps) {
  const { glyph, colors, text } = usePlateController(type);

  return (
    <Card padding="XS" border={colors?.general} background={colors.background}>
      <GridContainer
        gridTemplateColumns="min-content 1fr"
        alignItems="center"
        gap="20"
      >
        <Icon glyph={glyph} color={colors.general} size={20} />
        <Text text={text} color={colors.general} size={24} weight={'Medium'} />
      </GridContainer>
    </Card>
  );
}
