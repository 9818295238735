import gql from 'graphql-tag';
import {
  FORM_FRAGMENT,
  FORM_INVITATION_FRAGMENT,
  FORM_SCREEN_FRAGMENT,
} from '../../fragment/form';
import { CASE_CONFIG_FRAGMENT } from '../../fragment/caseConfig/CaseConfig';

export const Forms = gql`
  ${FORM_FRAGMENT}
  query Forms(
    $enabled: Boolean
    $linked_form_configuration_id: String
    $linked_form_submission_id: String
    $tags: [String!]
    $order: String
    $page: Int
    $q: String
    $use_dictionary_with_id: String
    $purpose_kind: String
  ) {
    forms(
      enabled: $enabled
      linked_form_configuration_id: $linked_form_configuration_id
      linked_form_submission_id: $linked_form_submission_id
      tags: $tags
      purpose_kind: $purpose_kind
      order: $order
      page: $page
      per: 20
      q: $q
      use_dictionary_with_id: $use_dictionary_with_id
    ) @rest(type: "ResponseForms", path: "/core/client/forms?{args}") {
      data @type(name: "Form") {
        ...FormFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const Form = gql`
  ${FORM_FRAGMENT}
  ${FORM_SCREEN_FRAGMENT}
  ${CASE_CONFIG_FRAGMENT}
  query Form($id: String!) {
    form(id: $id)
      @rest(type: "ResponseForm", path: "/core/client/forms/{args.id}") {
      data @type(name: "Form") {
        ...FormFragment
        form_screens @type(name: "FormScreen") {
          ...FormScreenFragment
        }
        case_config @type(name: "CaseConfig") {
          ...CaseConfigFragment
        }
      }
      success
    }
  }
`;

export const FormInvitations = gql`
  ${FORM_INVITATION_FRAGMENT}
  query FormInvitations($base_form_submission_id: String!, $page: Int) {
    formInvitations(
      base_form_submission_id: $base_form_submission_id
      page: $page
      per: 20
    )
      @rest(
        type: "ResponseFormInvitations"
        path: "/core/client/form_invitations?{args}"
      ) {
      data @type(name: "FormInvitation") {
        ...FormInvitationFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;
