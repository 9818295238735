import {
  ErrorHandler,
  ErrorLink as ApolloErrorLink,
} from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';

export class CustomErrorLink extends ApolloErrorLink {
  static showError?: (string: string) => void;
}

function customOnError(errorHandler: ErrorHandler): CustomErrorLink {
  return new CustomErrorLink(errorHandler);
}

function getMessageByCode(code: string) {
  switch (code) {
    case 'AB001':
      return 'Not enough money for this action. Please, top up your balance';
    case 'AB002':
      return 'You’re out of limit. Please check Subscriptions page to see the details of your tariff';
    case 'AB003':
      return 'This action is not included in your tariff. Please check Subscriptions page to see the details of your tariff';
    case 'AB004':
      return 'You don’t have subscriptions. Please check Subscriptions page';
    case 'AB005':
      return 'Subscription is inactive. Please check Subscriptions page';
    case 'PE001':
      return 'You don’t have enough money to charge the subscription fee';
    default:
      return;
  }
}

export const ErrorLink = customOnError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      Sentry.captureMessage(`[GraphQL error]: ${path}`, {
        level: 'warning',
        extra: {
          message,
          locations,
          path,
        },
      });
    });
  }
  if (networkError) {
    const apiError = (<unknown>networkError) as Response;
    if (apiError) {
      try {
        apiError.json().then((body) => {
          const error_code = body.error_code;
          if (error_code) {
            const message = getMessageByCode(error_code);
            if (message) {
              CustomErrorLink.showError?.(message);
            }
          }
          const error = body.error;
          if (error === 'PE001') {
            const message = getMessageByCode(error);
            if (message) {
              CustomErrorLink.showError?.(message);
            }
          }
        });
      } catch (e) {}
    }
    if (apiError.url) {
      Sentry.captureMessage(`[Network error]: ${apiError.status}`, {
        level: 'error',
        extra: {
          url: apiError.url || 'Unknown',
          body: apiError.body,
        },
      });
    }
  }
});
