import gql from 'graphql-tag';

export const AiSummaryConfigs = gql`
  query AiSummaryConfigs($page: Int, $per: Int) {
    aiSummaryConfigs(page: $page, per: $per)
      @rest(
        type: "ResponseAiSummaryConfigs"
        path: "/core/client/ai/case_summary_configs?{args}"
      ) {
      data @type(name: "AISummaryConfig") {
        id
        client_id
        name
        body
        additional_requirements
        response_example
        created_at
        updated_at
      }
      meta {
        count
        page
        items
        pages
      }
      success
    }
  }
`;

export const AiSummaryConfig = gql`
  query AiSummaryConfig($id: String!) {
    aiSummaryConfig(id: $id)
      @rest(
        type: "ResponseAiSummaryConfig"
        path: "/core/client/ai/case_summary_configs/{args.id}"
      ) {
      data @type(name: "AISummaryConfig") {
        id
        client_id
        name
        body
        additional_requirements
        response_example
        created_at
        updated_at
      }
      success
    }
  }
`;

export const AiSummaries = gql`
  query AiSummaries($page: Int, $per: Int, $case_id: String) {
    aiSummaries(page: $page, per: $per, case_id: $case_id)
      @rest(
        type: "ResponseAiSummaries"
        path: "/core/client/ai/case_summaries?{args}"
      ) {
      data @type(name: "AISummary") {
        id
        body
        case_id
        case_summary_config_id
        client_id
        created_at
        current_revision @type(name: "AISummaryRevision") {
          id
          body
          case_summary_id
          client_id
          comment
          created_at
          edited_at
          is_marked_as_actual
          is_marked_as_irrelevant
          request_prompt
          status
          updated_at
          edited_by @type(name: "Account") {
            id
            avatar_id
            email
            file_name
            file_size
            full_name
            phone
          }
        }
        revisions_count
        updated_at
        used_sources
      }
      meta {
        count
        page
        items
        pages
      }
      success
    }
  }
`;

export const AiSummary = gql`
  query AiSummary($id: String!) {
    aiSummary(id: $id)
      @rest(
        type: "ResponseAiSummary"
        path: "/core/client/ai/case_summaries/{args.id}"
      ) {
      data @type(name: "AISummary") {
        id
        body
        case_id
        case_summary_config_id
        client_id
        created_at
        current_revision @type(name: "AISummaryRevision") {
          id
          body
          case_summary_id
          client_id
          comment
          created_at
          edited_at
          is_marked_as_actual
          is_marked_as_irrelevant
          request_prompt
          status
          updated_at
          edited_by @type(name: "Account") {
            id
            avatar_id
            email
            file_name
            file_size
            full_name
            phone
          }
        }
        revisions_count
        updated_at
        used_sources
      }
      success
    }
  }
`;

export const AiSummaryRevisions = gql`
  query AiSummaryRevisions($page: Int, $per: Int, $case_summary_id: String!) {
    aiSummaryRevisions(
      page: $page
      per: $per
      case_summary_id: $case_summary_id
    )
      @rest(
        type: "ResponseAiSummaryRevisions"
        path: "/core/client/ai/case_summary_revisions?{args}"
      ) {
      data @type(name: "AISummaryRevision") {
        id
        body
        case_summary_id
        client_id
        comment
        created_at
        edited_at
        is_marked_as_actual
        is_marked_as_irrelevant
        request_prompt
        status
        updated_at
        edited_by @type(name: "Account") {
          id
          avatar_id
          email
          file_name
          file_size
          full_name
          phone
        }
      }
      meta {
        count
        page
        items
        pages
      }
      success
    }
  }
`;
