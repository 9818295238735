import styled from '@emotion/styled';
import { FlexContainer } from '../../../../containers';

export const ActionsContainer = styled(FlexContainer)`
  & > div {
    margin-right: 20px;
    width: 240px;
    height: 40px;
    border-width: 1px;
  }

  & > div:last-child {
    margin-right: 0;
  }
`;
