import gql from 'graphql-tag';
import { COMMENT_FRAGMENT } from '../../fragment/comment';

export const CreateComment = gql`
  ${COMMENT_FRAGMENT}
  mutation CreateComment(
    $type: CommentType!
    $body: BodyCreateOrUpdateComment!
  ) {
    createComment(type: $type, body: $body)
      @rest(
        type: "CommentCreate"
        path: "{args.type}"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "Comment") {
        ...CommentFragment
      }
      success
    }
  }
`;

export const CreateCommentBulk = gql`
  ${COMMENT_FRAGMENT}
  mutation CreateCommentBulk(
    $type: CommentType!
    $body: BodyCreateCommentBulk!
  ) {
    createCommentBulk(type: $type, body: $body)
      @rest(
        type: "CommentCreateBulk"
        path: "{args.type}"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "Comment") {
        ...CommentFragment
      }
      success
    }
  }
`;

export const UpdateComment = gql`
  ${COMMENT_FRAGMENT}
  mutation UpdateComment(
    $type: CommentType!
    $id: String!
    $body: BodyCreateOrUpdateComment!
  ) {
    updateComment(type: $type, id: $id, body: $body)
      @rest(
        type: "CommentUpdate"
        path: "{args.type}/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "Comment") {
        ...CommentFragment
      }
      success
    }
  }
`;
