import { ContentLoader, GridContainer } from '@aid-module/ui';

export function Fallback() {
  return (
    <GridContainer gap="20">
      <ContentLoader width="100%" height="26">
        <rect rx="8" ry="8" width="100%" height="26" />
      </ContentLoader>
      <ContentLoader width="100%" height="472.16">
        <rect rx="8" ry="8" width="100%" height="472.16" />
      </ContentLoader>
      <ContentLoader width="100%" height="400">
        <rect rx="8" ry="8" width="100%" height="400" />
      </ContentLoader>
    </GridContainer>
  );
}
