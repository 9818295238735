import gql from 'graphql-tag';

export const UploadDocument = gql`
  mutation UploadDocument(
    $file: Upload!
    $kind: String!
    $service: String
    $isPublic: Boolean
  ) {
    uploadDocument(
      file: $file
      kind: $kind
      service: $service
      isPublic: $isPublic
    ) {
      data @type(name: "Document") {
        file_size
        file_path
        file_name
        safe_upload_url
        safe_download_url
        av_status
        id
      }
      errors
      success
    }
  }
`;
