import gql from 'graphql-tag';

export const CreateSession = gql`
  mutation CreateSession($body: BodyCreateSession!) {
    createSession(body: $body)
      @rest(
        type: "CreateSession"
        path: "/billing/client/transactions"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "Session") {
        id
      }
      success
      errors
    }
  }
`;
