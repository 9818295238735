import gql from 'graphql-tag';

export const META_FRAGMENT = gql`
  fragment MetaFragment on Meta {
    count
    page
    items
    pages
  }
`;
