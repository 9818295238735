import { Tag } from '../Count';
import { Text } from '../Text';
import { useVerificationStatusController } from './controller';
import { VerificationStatusViewProps } from './types';
import {
  VERIFICATION_STATUSES,
  VERIFICATION_DISPLAY_STATUSES,
} from './constants';

function VerificationStatus({ status }: VerificationStatusViewProps) {
  const { background, text } = useVerificationStatusController(status);

  if (text) {
    return <Tag count={text} padding="S" size={11} background={background} />;
  }

  return <Text text="-" size={12} color="main-deep-4" />;
}

export {
  VerificationStatus,
  VERIFICATION_STATUSES,
  VERIFICATION_DISPLAY_STATUSES,
};
