import gql from 'graphql-tag';
import {
  CASE_FRAGMENT,
  CASE_DESCRIPTION_FRAGMENT,
  CASE_SHARING_FRAGMENT,
  CASE_ROLE_SHARING_FRAGMENT,
  CASE_SUMMARY_FRAGMENT,
} from '../../fragment/cm';
import {
  CASE_SECTION_FRAGMENT,
  CASE_SECTION_ITEM_FRAGMENT,
} from '../../fragment/pdf/case/caseSection';

export const Cases = gql`
  ${CASE_FRAGMENT}
  query Cases(
    $reminder_interval_days: Int
    $ids: [String!]
    $from_date: String
    $to_date: String
    $from_submission_date: String
    $to_submission_date: String
    $from_approved_date: String
    $to_approved_date: String
    $state: [String!]
    $auto_audit_state: [String!]
    $score_more_then: String
    $score_less_then: String
    $form_submission_id: String
    $search_id: String
    $search_by_field: String
    $tab_registry_id: String
    $case_sharings: Boolean
    $is_shared: String
    $remind_finish: Boolean
    $assigned_to_id: String
    $profile_id: String
    $sort_field: String
    $sort_order: String
    $form_configuration_reference_keys: [String!]
    $required_action_source_ids: [String!]
    $entity_type: String
    $profile_kinds: String
    $open_required_actions_exist: Boolean
    $bank_tariff_name: String
    $status_onboarding_fee: String
    $retention_plan_id: String
    $page: Int
    $per: Int = 20
    $q: String
  ) {
    cases(
      reminder_interval_days: $reminder_interval_days
      ids: $ids
      from_date: $from_date
      to_date: $to_date
      from_submission_date: $from_submission_date
      to_submission_date: $to_submission_date
      from_approved_date: $from_approved_date
      to_approved_date: $to_approved_date
      search_id: $search_id
      state: $state
      profile_id: $profile_id
      assigned_to_id: $assigned_to_id
      auto_audit_state: $auto_audit_state
      score_more_then: $score_more_then
      score_less_then: $score_less_then
      case_sharings: $case_sharings
      tab_registry_id: $tab_registry_id
      form_submission_id: $form_submission_id
      is_shared: $is_shared
      remind_finish: $remind_finish
      sort_field: $sort_field
      sort_order: $sort_order
      search_by_field: $search_by_field
      form_configuration_reference_keys: $form_configuration_reference_keys
      required_action_source_ids: $required_action_source_ids
      entity_type: $entity_type
      profile_kinds: $profile_kinds
      open_required_actions_exist: $open_required_actions_exist
      bank_tariff_name: $bank_tariff_name
      status_onboarding_fee: $status_onboarding_fee
      retention_plan_id: $retention_plan_id
      page: $page
      per: $per
      q: $q
    ) @rest(type: "ResponseCases", path: "/core/client/cases?{args}") {
      data @type(name: "Case") {
        ...CaseFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const Case = gql`
  ${CASE_DESCRIPTION_FRAGMENT}
  query Case($id: String!) {
    case(id: $id)
      @rest(type: "ResponseCase", path: "/core/client/cases/{args.id}") {
      data @type(name: "Case") {
        ...CaseDescriptionFragment
      }
      success
    }
  }
`;

export const CaseSections = gql`
  query CaseSections($case_id: String!) {
    caseSections(case_id: $case_id)
      @rest(
        type: "ResponseCaseSections"
        path: "/core/client/case_sections?{args}"
      ) {
      data @type(name: "CaseSection") {
        case_section_items_count
        created_at
        id
        section_info {
          is_always_exists
          is_section_approvable
          is_section_approved_by_default
          is_tabs_enabled
          kind
        }
        is_tabs_enabled
        kind
        state
        updated_at
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const CaseSectionTabs = gql`
  query CaseSectionTabs($case_section_id: String!) {
    caseSectionTabs(case_section_id: $case_section_id)
      @rest(
        type: "ResponseCaseSectionTabs"
        path: "/core/client/case_section_tabs?{args}"
      ) {
      data @type(name: "CaseSection") {
        case_section_id
        case_section_items_count
        created_at
        id
        name
        updated_at
      }
      success
    }
  }
`;

export const CaseSection = gql`
  ${CASE_SECTION_FRAGMENT}
  ${CASE_SECTION_ITEM_FRAGMENT}
  query CaseSection($id: String!) {
    caseSection(id: $id)
      @rest(
        type: "ResponseCaseSection"
        path: "/core/client/case_sections/{args.id}"
      ) {
      data @type(name: "CaseSection") {
        ...CaseSectionFragment
        section_info {
          is_always_exists
          is_section_approvable
          is_section_approved_by_default
          is_tabs_enabled
          kind
        }
        case_section_items @type(name: "CaseSectionItem") {
          ...CaseSectionItemFragment
        }
        failed_questionnaire_items @type(name: "FailedQuestionnaireItem") {
          id
          field_submission_id
        }
      }
      success
    }
  }
`;

export const CaseSectionIdenfyResult = gql`
  ${CASE_SECTION_FRAGMENT}
  query CaseSectionIdenfyResult($id: String!) {
    caseSection(id: $id)
      @rest(
        type: "ResponseCaseSections"
        path: "/core/client/case_sections/{args.id}"
      ) {
      data @type(name: "CaseSection") {
        ...CaseSectionFragment
      }
      success
    }
  }
`;

export const CaseSharings = gql`
  ${CASE_SHARING_FRAGMENT}
  query CaseSharings($case_id: String) {
    caseSharings(case_id: $case_id)
      @rest(
        type: "ResponseCaseSharings"
        path: "/core/client/case_sharings?per=1000&{args}"
      ) {
      data @type(name: "CaseSharing") {
        ...CaseSharingFragment
      }
      success
    }
  }
`;

export const CaseRoleSharings = gql`
  ${CASE_ROLE_SHARING_FRAGMENT}
  query CaseRoleSharings($case_id: String) {
    caseRolesSharings(case_id: $case_id)
      @rest(
        type: "ResponseCaseSections"
        path: "/core/client/case_role_sharings?per=1000&{args}"
      ) {
      data @type(name: "CaseRoleSharing") {
        ...CaseRoleSharingFragment
      }
      success
    }
  }
`;

export const CaseSectionTab = gql`
  ${CASE_SECTION_FRAGMENT}
  query CaseSectionTab(
    $case_section_tab_id: String!
    $case_section_id: String!
  ) {
    caseSectionTab(
      case_section_tab_id: $case_section_tab_id
      case_section_id: $case_section_id
    )
      @rest(
        type: "ResponseCaseSectionTab"
        path: "/core/client/case_section_tabs/{args.case_section_tab_id}?case_section_id={args.case_section_id}"
      ) {
      data @type(name: "CaseSection") {
        case_section_items @type(name: "CaseSectionItem") {
          ...CaseSectionItemFragment
          audit_id
          case_section_tab_id
          checkup_id
          created_by_id
          description
        }
        case_section_items_count
        created_at
        id
        kind
        state
        updated_at
      }
      success
    }
  }
`;

export const CaseRejectionReason = gql`
  query CaseRejectionReason($page: Int, $q: String) {
    caseRejectionReasons(page: $page, per: 20, q: $q)
      @rest(
        type: "ResponseCaseRejectionReasons"
        path: "/core/client/case_rejection_reasons?{args}"
      ) {
      data @type(name: "RejectionReason") {
        code
        id
        name
      }
      meta {
        count
        page
        items
        pages
      }
      success
    }
  }
`;

export const DividedSearch = gql`
  query DividedSearch($q: String) {
    dividedSearch(q: $q)
      @rest(
        type: "ResponseDividedSearch"
        path: "/core/client/cases/divided_search?{args}"
      ) {
      data @type(name: "CaseDividedSearch") {
        ca_client_ref {
          ca_search_ref
          customer {
            email
            full_name
          }
          id
          main_profile {
            avatar_id
            created_at
            display_name
            email
            is_whitelisted
            kind
            phone
            updated_at
          }
          main_profile_id
          profile_display_name
          purpose
        }
        ca_client_ref_count
        customer_email {
          ca_search_ref
          customer {
            email
            full_name
          }
          id
          main_profile {
            avatar_id
            created_at
            display_name
            email
            is_whitelisted
            kind
            phone
            updated_at
          }
          profiles {
            id
            display_name
            email
          }
          main_profile_id
          profile_display_name
          purpose
        }
        by_id_count
        ids {
          ca_search_ref
          customer {
            email
            full_name
          }
          id
          main_profile {
            avatar_id
            created_at
            display_name
            email
            is_whitelisted
            kind
            phone
            updated_at
          }
          profiles {
            id
            display_name
            email
          }
          main_profile_id
          profile_display_name
          purpose
        }
        customer_email_count
        customer_full_name {
          ca_search_ref
          customer {
            email
            full_name
          }
          id
          main_profile {
            avatar_id
            created_at
            display_name
            email
            is_whitelisted
            kind
            phone
            updated_at
          }
          profiles {
            id
            display_name
            email
          }
          main_profile_id
          profile_display_name
          purpose
        }
        customer_full_name_count
        profile_email {
          ca_search_ref
          customer {
            email
            full_name
          }
          id
          main_profile {
            avatar_id
            created_at
            display_name
            email
            is_whitelisted
            kind
            phone
            updated_at
          }
          profiles {
            id
            display_name
            email
          }
          main_profile_id
          profile_display_name
          purpose
        }
        profile_email_count
        profile_full_name {
          ca_search_ref
          customer {
            email
            full_name
          }
          id
          main_profile {
            avatar_id
            created_at
            display_name
            email
            is_whitelisted
            kind
            phone
            updated_at
          }
          profiles {
            id
            display_name
            email
          }
          main_profile_id
          profile_display_name
          purpose
        }
        profile_full_name_count
        purpose {
          ca_search_ref
          customer {
            email
            full_name
          }
          id
          main_profile {
            avatar_id
            created_at
            display_name
            email
            is_whitelisted
            kind
            phone
            updated_at
          }
          main_profile_id
          profile_display_name
          purpose
        }
        purpose_count
      }
      success
    }
  }
`;

export const CasesReport = gql`
  query CasesReport(
    $ids: [String!]
    $from_date: String
    $to_date: String
    $state: [String!]
    $auto_audit_state: [String!]
    $score_more_then: String
    $score_less_then: String
    $form_submission_id: String
    $is_shared: String
    $assigned_to_id: String
    $profile_id: String
    $form_configuration_reference_keys: [String!]
  ) {
    casesReport(
      ids: $ids
      from_date: $from_date
      to_date: $to_date
      state: $state
      profile_id: $profile_id
      assigned_to_id: $assigned_to_id
      auto_audit_state: $auto_audit_state
      score_more_then: $score_more_then
      score_less_then: $score_less_then
      form_submission_id: $form_submission_id
      is_shared: $is_shared
      form_configuration_reference_keys: $form_configuration_reference_keys
    ) @rest(type: "ResponseReport", path: "/core/client/cases/to_csv?{args}")
  }
`;

export const CaseSummaries = gql`
  ${CASE_SUMMARY_FRAGMENT}
  query CaseSummaries($case_id: String!, $page: Int, $per: Int) {
    caseSummaries(case_id: $case_id, page: $page, per: $per)
      @rest(
        type: "ResponseCaseSummaries"
        path: "/core/client/case_summaries?{args}"
      ) {
      data @type(name: "CaseSummary") {
        ...CaseSummaryFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;
