import { gql } from '@apollo/client';

export const CreateEmailTemplate = gql`
  mutation CreateEmailTemplate($body: BodyCreateOrUpdateEmailTemplate!) {
    createEmailTemplate(body: $body)
      @rest(
        type: "CreateEmailTemplate"
        path: "/core/client/email_templates"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "EmailTemplate") {
        id
        name
        subject
        body
        created_at
        updated_at
        account @type(name: "Account") {
          id
          full_name
        }
        template_fields @type(name: "EmailTemplateField") {
          field
          type
          header
          required
        }
      }
      success
    }
  }
`;

export const UpdateEmailTemplate = gql`
  mutation UpdateEmailTemplate(
    $id: String!
    $body: BodyCreateOrUpdateEmailTemplate!
  ) {
    updateEmailTemplate(id: $id, body: $body)
      @rest(
        type: "UpdateEmailTemplate"
        path: "/core/client/email_templates/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "EmailTemplate") {
        id
        name
        subject
        body
        created_at
        updated_at
        account @type(name: "Account") {
          id
          full_name
        }
        template_fields @type(name: "EmailTemplateField") {
          field
          type
          header
          required
        }
      }
      success
    }
  }
`;

export const DeleteEmailTemplate = gql`
  mutation DeleteEmailTemplate($id: String!) {
    deleteEmailTemplate(id: $id)
      @rest(
        type: "DeleteEmailTemplate"
        path: "/core/client/email_templates/{args.id}"
        method: "DELETE"
      ) {
      data @type(name: "EmailTemplate") {
        id
        name
        subject
        body
        created_at
        updated_at
        account @type(name: "Account") {
          id
          full_name
        }
        template_fields @type(name: "EmailTemplateField") {
          field
          type
          header
          required
        }
      }
      success
    }
  }
`;
