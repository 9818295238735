import loadable from '@loadable/component';
import { LoadableFunctionalComponent } from '../../utils';
import { BooleanInputLoader } from '../../shared';
import { ToggleComponentViewProps } from './component/types';
export * from './component/types';

export const Toggle = loadable(() => import('./component'), {
  resolveComponent: (components) => components.ToggleComponent,
  fallback: <BooleanInputLoader />,
}) as LoadableFunctionalComponent<ToggleComponentViewProps>;
