import {
  GridContainer,
  getColor,
  getSpace,
  FlexContainer,
} from '@aid-module/ui';
import styled from '@emotion/styled/macro';

export const IconContainer = styled(FlexContainer)`
  min-width: 10px;
  min-height: 16px;
`;

export const DefaultElement = styled.div`
  display: none;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: ${getColor('main-deep-4')};
`;

export const Container = styled(GridContainer)`
  padding: ${getSpace('6')} ${getSpace('6')};
  border-radius: 4px;

  &:hover {
    background: ${getColor('main-deep-1')};
  }
`;
