import { ContentLoader } from '../ContentLoader';
import { LinkLoaderViewProps } from './types';

export function LinkLoader({ height = 23 }: LinkLoaderViewProps) {
  return (
    <ContentLoader width="100%" height={`${height}`}>
      <rect x="0" y="0" rx="3" ry="3" width="100%" height={`${height}`} />
    </ContentLoader>
  );
}
