import { Text } from '@aid-module/ui';
import { useCallback } from 'react';
import { NotificationText } from './components';
import { useNotificationController } from './controller';
import { Container } from './styles';
import { NotificationViewProps } from './types';

export function Notification({
  payloadData,
  size = 12,
  height,
  withoutLinks = false,
}: NotificationViewProps) {
  const { words, hasDynamic } = useNotificationController(payloadData);

  const renderNotificationText = useCallback(
    (item: { type: string; value: string }, index: number) => (
      <NotificationText
        key={index}
        size={size}
        payloadData={payloadData}
        type={item.type}
        value={item.value}
        withoutLinks={withoutLinks}
      />
    ),
    [payloadData]
  );

  return (
    <Container height={height}>
      {hasDynamic ? (
        words.map(renderNotificationText)
      ) : (
        <Text text={payloadData.body} size={size} color="main-deep-4" />
      )}
    </Container>
  );
}
