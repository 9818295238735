import { DefaultLink as UIDefaultLink } from '@aid-module/ui';
import styled from '@emotion/styled';

interface ContainerEmotionViewProps {
  height?: string;
}

export const Container = styled.div<ContainerEmotionViewProps>`
  & > * {
    display: inline !important;
  }

  ${({ height }) =>
    height &&
    `
    height: ${height};
  `}
`;

export const DefaultLink = styled(UIDefaultLink)`
  text-decoration: none;
`;
