import { Icon } from '../../../../../Icon';
import { IconLoader } from '../../../../../../shared';
import { Text } from '../../../../../Text';
import { MoreNotificationsViewProps } from './types';
import { Container, IconContainer } from './styles';
import { GridContainer } from '../../../../../../containers';

export function MoreNotifications({
  count,
  callback,
}: MoreNotificationsViewProps) {
  if (count && count > 1) {
    return (
      <Container
        gridTemplateRows="50px"
        gridTemplateColumns="64px minmax(0px, 1fr)"
        alignItems="center"
        onClick={callback}
      >
        <IconContainer flex={1} justifyContent="center" alignItems="center">
          <Icon
            glyph="notificationBell"
            color="main-deep-4"
            size={24}
            fallback={<IconLoader size={24} />}
          />
        </IconContainer>
        <GridContainer
          justifyContent="center"
          gap="4"
          gridTemplateColumns="max-content max-content"
        >
          <Text text="You’ve received" />
          <Text
            weight="Medium"
            color="main-deep-4"
            text={`${count} new notifications`}
          />
        </GridContainer>
      </Container>
    );
  }
  return null;
}
