import { THEME_COLORS } from '../../../shared/colors/types';
import { VERIFICATION_STATUSES } from '../constants';

export function getColorByStatus(status?: string | null): keyof THEME_COLORS {
  switch (status) {
    case VERIFICATION_STATUSES.COMPLETED:
    case VERIFICATION_STATUSES.CLEAN:
      return 'success-deep-4';
    case VERIFICATION_STATUSES.IN_PROGRESS:
    case VERIFICATION_STATUSES.DRAFT:
    case VERIFICATION_STATUSES.NO_INFO:
      return 'gray-deep-3';
    case VERIFICATION_STATUSES.WARNING:
      return 'warning-deep-4';
    case VERIFICATION_STATUSES.FAILED:
    case VERIFICATION_STATUSES.UNPROCESSED:
      return 'error-deep-3';
    default:
      return 'main-deep-3';
  }
}
