import { GridContainer } from '../../../../../../../../containers';
import { SecondaryButton, ErrorButton } from '../../../../../../../Button';
import { DefaultBodyViewProps } from '../../types';
import { Container } from '../Container';

export function Error({
  title,
  body,
  onClickConfirm,
  onClickCancel,
  cancelActionTitle,
  confirmActionTitle,
}: DefaultBodyViewProps) {
  return (
    <Container gap="32" title={title} body={body}>
      <GridContainer gap="20" gridTemplateColumns="repeat(2, 1fr)">
        <SecondaryButton
          text={cancelActionTitle || 'CANCEL'}
          onClick={onClickCancel}
        />
        <ErrorButton
          text={confirmActionTitle || 'OK'}
          onClick={onClickConfirm}
        />
      </GridContainer>
    </Container>
  );
}
