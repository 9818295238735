import { SelectOption } from '@aid-module/ui';

export const riskLevelOptions: SelectOption[] = [
  {
    label: 'Medium risk',
    value: 'medium',
  },
  {
    label: 'Low risk',
    value: 'low',
  },
  {
    label: 'High risk',
    value: 'high',
  },
  {
    label: 'Unknown',
    value: 'unknown',
  },
];
