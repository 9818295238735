import gql from 'graphql-tag';

export const PricingPlans = gql`
  query PricingPlans(
    $kind: String
    $onboarding_enabled: Boolean
    $billing_period: String
    $q: String
    $status: String
    $price_more_then: String
    $price_less_then: String
    $sort_field: String
    $sort_order: String
    $page: Int
    $per: Int
  ) {
    pricingPlans(
      kind: $kind
      onboarding_enabled: $onboarding_enabled
      billing_period: $billing_period
      q: $q
      status: $status
      price_more_then: $price_more_then
      price_less_then: $price_less_then
      sort_field: $sort_field
      sort_order: $sort_order
      page: $page
      per: $per
    ) @rest(type: "ResponsePricingPlans", path: "/billing/admin/plans?{args}") {
      data @type(name: "PricingPlan") {
        billing_period
        created_at
        description
        expired_at
        id
        is_actual_revision
        is_recommended
        is_additional
        kind
        name
        onboarding_enabled
        preview_body
        price
        reference_key
        revision_number
        started_at
        status
        updated_at
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const PricingPlan = gql`
  query PricingPlan($id: String!) {
    pricingPlan(id: $id)
      @rest(
        type: "ResponsePricingPlan"
        path: "/billing/admin/plans/{args.id}"
      ) {
      data @type(name: "PricingPlan") {
        billing_period
        created_at
        description
        expired_at
        id
        is_actual_revision
        is_recommended
        is_additional
        kind
        name
        onboarding_enabled
        preview_body
        price
        reference_key
        revision_number
        started_at
        status
        updated_at
        subscriptions_count
        features @type(name: "PricingPlanFeature") {
          feature_template @type(name: "FeatureTemplate") {
            code
            created_at
            created_by_id
            description
            feature_dictionary @type(name: "FeatureDictionary") {
              code
              created_at
              created_by_id
              id
              is_system
              name
              status
              updated_at
            }
            feature_dictionary_id
            id
            kind
            name
            status
            updated_at
            is_system
          }
          feature_template_id
          id
          limit_rules {
            price
            start_count
            finish_count
          }
          name
          plan_id
          updated_at
          validity_period
        }
      }
      success
    }
  }
`;
