import { GridContainer } from '../../../../../../../../../../../../../../../containers';
import { Count } from '../../../../../../../../../../../../../../Count';
import { Empty } from '../../../../../../../../../../../../../../Empty';
import { Text } from '../../../../../../../../../../../../../../Text';
import { Card, ContentContainer } from '../shared.styles';
import { Template } from './components';
import { TemplateViewProps } from './components/Template/types';
import { useAdditionalController } from './controller';

function renderTemplate(template: TemplateViewProps['template']) {
  return <Template key={template.id} template={template} />;
}

export function Additional() {
  const { templates } = useAdditionalController();
  return (
    <Card>
      <GridContainer
        gridTemplateColumns="1fr"
        gridTemplateRows="max-content 1fr"
        gap="14"
      >
        <GridContainer gridTemplateColumns="repeat(2, max-content)" gap="8">
          <Text
            text="ADDITIONAL"
            size={14}
            color="main-deep-4"
            weight="VeryBig"
            uppercase
          />
          <Count count={templates.length} />
        </GridContainer>
        <ContentContainer
          gridTemplateColumns="1fr"
          gridTemplateRows="max-content"
          overflow="auto"
        >
          {templates.length ? (
            <GridContainer gridTemplateColumns="1fr" gap="8">
              {templates.map(renderTemplate)}
            </GridContainer>
          ) : (
            <GridContainer gridTemplateColumns="1fr" gridTemplateRows="200px">
              <Empty
                title="NO ADDITIONAL FILTERS"
                subtitle="Save your frequently used filters selection as a filter template."
                withoutBorder
              />
            </GridContainer>
          )}
        </ContentContainer>
      </GridContainer>
    </Card>
  );
}
