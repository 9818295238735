export const IDENTITY_VERIFICATIONS_STATUSES = {
  APPROVED: 'approved',
  DENIED: 'denied',
  EXPIRED: 'expired',
  FAIL: 'fail',
  FAILED: 'failed',
  PASSED: 'passed',
  SUCCESS: 'success',
  SUSPECTED: 'suspected',
  UNPROCESSED: 'unprocessed',
  ABBADONED: 'abandoned',
  ACTIVE: 'active',
  DECLINED: 'declined',
};

export const IDENTITY_VERIFICATIONS_DISPLAY_STATUSES = {
  [IDENTITY_VERIFICATIONS_STATUSES.APPROVED]: 'Approved',
  [IDENTITY_VERIFICATIONS_STATUSES.DENIED]: 'Denied',
  [IDENTITY_VERIFICATIONS_STATUSES.EXPIRED]: 'Expired',
  [IDENTITY_VERIFICATIONS_STATUSES.FAIL]: 'Fail',
  [IDENTITY_VERIFICATIONS_STATUSES.FAILED]: 'Failed',
  [IDENTITY_VERIFICATIONS_STATUSES.PASSED]: 'Passed',
  [IDENTITY_VERIFICATIONS_STATUSES.SUCCESS]: 'Success',
  [IDENTITY_VERIFICATIONS_STATUSES.SUSPECTED]: 'Suspected',
  [IDENTITY_VERIFICATIONS_STATUSES.UNPROCESSED]: 'Unprocessed',
  [IDENTITY_VERIFICATIONS_STATUSES.ABBADONED]: 'Abandoned',
  [IDENTITY_VERIFICATIONS_STATUSES.ACTIVE]: 'Active',
  [IDENTITY_VERIFICATIONS_STATUSES.DECLINED]: 'Declined',
};
