import { useRef } from 'react';
import { CommentOption } from './types';
import { useSideWidgetProviderContext } from '../../../../../SideWidget';
import { Comment } from '../../../../View';

export function useCommentProviderController() {
  const { show, close } = useSideWidgetProviderContext();

  const showComment = useRef((options: CommentOption) => {
    show(
      <Comment options={options} />,
      `${options.subject_type}.${options.subject_id}.${
        options.subject_entity_type || '-'
      }.${options.subject_entity_id || '-'}.${options.event_id}`
    );
  });

  const closeComment = useRef(() => {
    close();
  });

  const value = useRef({
    showComment: showComment.current,
    closeComment: closeComment.current,
  });

  return { value };
}
