import { GridContainer, Text, TextArea } from '@aid-module/ui';
import { Controller, FormProvider } from 'react-hook-form';
import { Actions } from './components';
import { useFormController } from './controller';
import { FormRenderProps } from './types';
import { ContentContainer } from './styles';
import { ContentSeparator } from '../../../../styles';

function renderComment({
  field: { value, onChange },
  fieldState: { error },
}: FormRenderProps<'comment'>) {
  return (
    <TextArea
      placeholder="Enter your comment"
      value={value}
      onChange={onChange}
      error={error}
    />
  );
}

export function Form() {
  const formContext = useFormController();

  return (
    <FormProvider {...formContext}>
      <ContentSeparator />
      <ContentContainer gap="12">
        <Text
          text="COMMENT FOR SELECTED RESULTS"
          size={12}
          color="gray-deep-3"
          weight="Bold"
        />
        <Controller name="comment" render={renderComment} />
      </ContentContainer>
      <Actions />
    </FormProvider>
  );
}
