import {
  ContentLoader,
  GridContainer,
  ProfileCardLoader,
} from '@aid-module/ui';
import { TabsFallback } from '../TabsFallback';

export function Fallback() {
  return (
    <GridContainer gap="20">
      <ProfileCardLoader />
      <ContentLoader width="100%" height="68">
        <rect rx={8} ry={8} width="100%" height="68" />
      </ContentLoader>
      <ContentLoader width="100%" height="66.2">
        <rect rx={8} ry={8} width="100%" height="66.2" />
      </ContentLoader>
      <TabsFallback />
    </GridContainer>
  );
}
