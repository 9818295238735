import styled from '@emotion/styled';
import { PREFIX } from '../../utils';
import { LoaderThemeProviderViewProps } from './types';
import { getColor } from '../../../utils';

export const LoaderThemeContainer = styled.div<LoaderThemeProviderViewProps>`
  display: block;
  ${({ background, foreground }) => `
    ${PREFIX.LOADER + 'background'}: ${getColor(background || 'main-deep-1')};
    ${PREFIX.LOADER + 'foreground'}: ${getColor(foreground || 'main-deep-2')};
  `}
`;
