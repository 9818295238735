export const CASE_SUBMISSION_STATUSES = {
  COMPLETED: 'completed',
  DRAFT: 'draft',
};

export const CASE_DISPLAY_SUBMISSION_STATUSES = {
  [CASE_SUBMISSION_STATUSES.COMPLETED]: 'Completed',
  [CASE_SUBMISSION_STATUSES.DRAFT]: 'Draft',
};

export const SubmissionStatusOptions = Object.values(
  CASE_SUBMISSION_STATUSES
).map((value) => ({
  label: CASE_DISPLAY_SUBMISSION_STATUSES[value],
  value: value,
}));
