import { PropsWithChildren, createContext, useContext } from 'react';
import { EditContextParams, EditProviderViewProps } from './types';
import { useEditProviderController } from './controller';

const EditContext = createContext<EditContextParams>({
  selectedIds: {},
  onChangeSelected(id, value) {},
  onSelectAll(next) {},
  clean() {},
});

export function useEditContext() {
  const context = useContext(EditContext);

  return context;
}

export function EditProvider({
  children,
  bulkConfig,
}: PropsWithChildren<EditProviderViewProps>) {
  const context = useEditProviderController(bulkConfig);

  return (
    <EditContext.Provider value={context}>{children}</EditContext.Provider>
  );
}
