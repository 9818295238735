import gql from 'graphql-tag';

export const MassScreening = gql`
  query MassScreening(
    $fincen_list_id: String
    $page: Int
    $q: String
    $status: String
    $per: Int
  ) {
    massScreening(
      fincen_list_id: $fincen_list_id
      page: $page
      per: $per
      status: $status
      q: $q
    )
      @rest(
        type: "ResponseMassScreening"
        path: "/pp/local_mass_screenings?{args}"
      ) {
      data @type(name: "MassScreening") {
        id
        account @type(name: "Account") {
          email
          first_name
          id
          last_name
        }
        created_at
        case_statuses
        display_name
        fincen_list_id
        fuzziness
        is_local
        kind
        first_date_of_loopback_period_for_cases
        first_date_of_loopback_period_for_tm
        loopback_period_for_cases
        loopback_period_for_tm
        loopback_period_date_for_cases
        loopback_period_date_for_tm
        passive_profile_screenings_count
        status
        updated_at
      }
      meta {
        count
        page
        items
        pages
      }
      success
    }
  }
`;

export const MassScreeningDetail = gql`
  query MassScreeningDetail($id: String!) {
    massScreeningDetail(id: $id)
      @rest(
        type: "ResponseMassScreening"
        path: "/pp/local_mass_screenings/{args.id}"
      ) {
      data @type(name: "MassScreening") {
        id
        account @type(name: "Account") {
          email
          first_name
          id
          last_name
        }
        created_at
        case_statuses
        display_name
        fincen_list_id
        fuzziness
        is_local
        kind
        estimated_passive_profiles_count
        estimated_progress
        case_related_profiles_count
        tm_related_profiles_count
        first_date_of_loopback_period_for_cases
        first_date_of_loopback_period_for_tm
        loopback_period_for_cases
        loopback_period_for_tm
        loopback_period_date_for_cases
        loopback_period_date_for_tm
        passive_profile_screenings_count
        status
        updated_at
        current_status {
          passive_profile_screenings_count
          created_checkups
          finished_checkups
          not_ready_checkups
          by_checkup_status
          by_provider_status
        }
      }
      success
    }
  }
`;
