import { ApolloProvider } from '@apollo/client';
import { PropsWithChildren, createContext, useContext } from 'react';
import { onboardingClient } from '../client';
import { useProviderController } from './controller';
import { AIDLoader } from './AIDLoader';
import { ApiClientProviderViewProps } from './types';

const ApolloClientControllerContext = createContext<{
  loading: boolean;
  timerOptions?: { time: number };
}>({
  loading: false,
  timerOptions: undefined,
});

export function useApolloClientControllerContext() {
  const context = useContext(ApolloClientControllerContext);

  return context;
}

export function ApiClientProvider({
  children,
  showError,
}: PropsWithChildren<ApiClientProviderViewProps>) {
  const { client, destroy, ...context } = useProviderController(showError);

  if (destroy) {
    return <AIDLoader />;
  }

  return (
    <ApolloProvider client={client}>
      <ApolloClientControllerContext.Provider value={context}>
        {children}
      </ApolloClientControllerContext.Provider>
    </ApolloProvider>
  );
}

export function OnboardingApiClientProvider({
  children,
}: PropsWithChildren<{}>) {
  return <ApolloProvider client={onboardingClient}>{children}</ApolloProvider>;
}
