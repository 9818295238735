import { ContentLoader } from '../ContentLoader';

export function TextAreaLoader() {
  return (
    <ContentLoader width="100%" height="71.98">
      <rect x="14" y="0" rx="3" ry="3" width="100%" height="15.59" />
      <rect x="0" y="21.59" rx="3" ry="3" width="100%" height="50.39" />
    </ContentLoader>
  );
}
