import gql from 'graphql-tag';
import { CHECKUP_KIND_FRAGMENT } from '../checkupKinds';

export const CLIENT_CHECKUP_KIND_FRAGMENT = gql`
  ${CHECKUP_KIND_FRAGMENT}
  fragment ClientCheckupKindFragment on ClientCheckupKind {
    id
    client_id
    enabled
    checkup_kind @type(name: "CheckupKind") {
      ...CheckupKindFragment
    }
  }
`;
