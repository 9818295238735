import gql from 'graphql-tag';

export const PPScreening = gql`
  query PPScreening(
    $checkup_provider_statuses: String
    $mass_screening_id: String
    $origin_checkup_provider_statuses: String
    $page: Int
    $q: String
  ) {
    pPScreening(
      checkup_provider_statuses: $checkup_provider_statuses
      mass_screening_id: $mass_screening_id
      origin_checkup_provider_statuses: $origin_checkup_provider_statuses
      page: $page
      q: $q
      per: 20
    )
      @rest(
        type: "ResponsePPScreening"
        path: "/pp/passive_profile_screenings?{args}"
      ) {
      data @type(name: "PPScreeningRecord") {
        id
        checkup_id
        checkup_provider_status
        checkup_status
        created_at
        display_name
        fincen_list_id
        fincen_list_kind
        kind

        origin_checkup_provider_status
        previous_checkup_provider_status
        previous_checkup_status
        updated_at
        passive_profile_id
        status_changed_by @type(name: "Account") {
          id
          email
          first_name
          last_name
          full_name
        }
        matched_external_refs @type(name: "ExternalRef") {
          id
          external_entity_id
          external_entity_type
          external_system
        }
        passive_profile @type(name: "PassiveProfile") {
          id
          display_name
          passive_profile_external_refs @type(name: "PPExternalRef") {
            id
            created_at
            external_ref @type(name: "ExternalRef") {
              id
              external_entity_id
              external_entity_type
              external_system
            }
          }
        }
      }
      meta {
        count
        page
        items
        pages
      }
      success
    }
  }
`;

export const PPScreeningReport = gql`
  query PPScreeningReport(
    $checkup_provider_statuses: String
    $mass_screening_id: String
    $q: String
  ) {
    pPScreeningReport(
      checkup_provider_statuses: $checkup_provider_statuses
      mass_screening_id: $mass_screening_id
      q: $q
    )
      @rest(
        type: "ResponseReport"
        path: "/pp/passive_profile_screenings/to_csv?{args}"
      )
  }
`;

export const PPScreeningDetails = gql`
  query PPScreeningDetails($id: String!) {
    pPScreeningDetails(id: $id)
      @rest(
        type: "ResponsePPScreening"
        path: "/pp/passive_profile_screenings/{args.id}"
      ) {
      data @type(name: "PPScreeningRecord") {
        id
        checkup_id
        checkup_provider_status
        checkup_status
        created_at
        display_name
        fincen_list_id
        fincen_list_kind
        kind
        previous_checkup_provider_status
        previous_checkup_status
        updated_at
        comment
        passive_profile_id
        matched_external_refs @type(name: "ExternalRef") {
          id
          external_entity_id
          external_entity_type
          external_system
        }
        passive_profile @type(name: "PassiveProfile") {
          id
          display_name
          passive_profile_external_refs @type(name: "PPExternalRef") {
            id
            created_at
            external_ref @type(name: "ExternalRef") {
              id
              external_entity_id
              external_entity_type
              external_system
            }
          }
        }
        results
        checkup_form
      }
      success
    }
  }
`;
