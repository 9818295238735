import {
  TableWithHeaderLoader,
  LoadableFunctionalComponent,
} from '@aid-module/ui';
import loadable from '@loadable/component';

export const ReminderTemplatesRegister = loadable(() => import('./component'), {
  resolveComponent: (components) =>
    components.ReminderTemplatesRegisterComponent,
  fallback: <TableWithHeaderLoader />,
}) as LoadableFunctionalComponent<{}>;
