export enum CheckupKindClientCodeResponse {
  AID_EMAIL_REGEXP = 'aid_email_regexp',
  COMPLY_ADVANTAGE_AML = 'comply_advantage_aml',
  COMPLY_ADVANTAGE_AML_BUSINESS = 'comply_advantage_aml_business',
  COMPLY_ADVANTAGE_AML_COMMON = 'comply_advantage_aml_common',
  UNIT_21_ENTITY_ID = 'unit_21_entity_id',
  UNIT_21_ENTITY_DOC = 'unit_21_entity_doc',
  UNIT_21_KYB = 'unit_21_kyb',
  ELLIPTIC_WALLET = 'elliptic_wallet',
  MAXMIND_GEO_IP = 'maxmind_geo_ip',
  SHUFTIPRO_KYB = 'shuftipro_kyb',
  SHUFTIPRO_AML = 'shuftipro_aml',
  SHUFTIPRO_AML_BUSINESS = 'shuftipro_aml_business',
  FINCEN_PERSON = 'fincen_person',
  FINCEN_BUSINESS = 'fincen_business',
  AID_BLACK_LIST_PERSON = 'blacklisted_profiles_person',
  AID_BLACK_LIST_BUSINESS = 'blacklisted_profiles_business',
  VERIFF = 'veriff_identity_verification',
  AID_PROHIBITED_COUNTRIES = 'prohibited_countries',
  AID_BLACKLISTED_DEVICE_INFOS = 'blacklisted_device_infos',
}

export enum CheckupsClientExecutionStatusResponse {
  FAILED = 'failed',
  NEW = 'new',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
}

export enum CheckupsClientAuditStatusResponse {
  NO_INFO = 'no_info',
  CLEAN = 'clean',
  WARNING = 'warning',
  ALARM = 'alarm',
  FALSE_POSITIVE = 'false_positive',
  FALSE_NEGATIVE = 'false_negative',
}

export enum CheckupsClientProviderStatusResponse {
  UNKNOW = 'unknown',
  NO_MATCH = 'no_match',
  POTENTIAL_MATCH = 'potential_match',
  FALSE_POSITIVE = 'false_positive',
  TRUE_POSITIVE = 'true_positive',
  TRUE_POSITIVE_APPROVE = 'true_positive_approve',
  TRUE_POSITIVE_REJECT = 'true_positive_reject',
}

export enum CheckupSearchHitMatchStatusResponse {
  FALSE_POSITIVE = 'false_positive',
  NO_MATCH = 'no_match',
  POTENTIAL_MATCH = 'potential_match',
  TRUE_POSITIVE = 'true_positive',
  UNKNOWN = 'unknown',
}
