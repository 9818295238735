import gql from 'graphql-tag';

export const AvgAssignment = gql`
  query AvgAssignment(
    $role_ids: [String!]
    $account_ids: [String!]
    $form_configuration_ids: [String!]
    $form_configuration_reference_keys: [String!]
    $date_from: String
    $date_to: String
  ) {
    avgAssignment(
      role_ids: $role_ids
      account_ids: $account_ids
      form_configuration_ids: $form_configuration_ids
      form_configuration_reference_keys: $form_configuration_reference_keys
      date_from: $date_from
      date_to: $date_to
    )
      @rest(
        type: "ResponseAvgAssignment"
        path: "/stats/client/processing/avg_assignment?{args}"
      ) {
      data {
        avg_assignment
      }
      success
    }
  }
`;
