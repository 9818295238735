import { showToast } from '@aid-module/ui';
import { CaSearches, useUpdateHitsMutation } from '@aid-package/api';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSearchDataContext } from '../../../../../../../../../../AMLResult/context';
import { useHitsContext } from '../../../../../../../context';
import { FormProps } from '../../../../../types';

export function useActionModel() {
  const { reset } = useFormContext();
  const { searchData } = useSearchDataContext();
  const { checkedHitIds, setOpenForm, clearHitIds } = useHitsContext();
  const [mutation, { loading }] = useUpdateHitsMutation({
    refetchQueries: [CaSearches],
  });

  const updateHits = useCallback(
    async (values: FormProps) => {
      const { data } = await mutation({
        variables: {
          ca_search_id: searchData?.id || '',
          body: {
            entities: checkedHitIds,
            is_whitelisted: values.is_whitelisted?.value === 'Yes',
            match_status: values.match_status?.value,
            risk_level: values.risk_level?.value,
          },
        },
      });

      if (data?.updateHits?.success) {
        setOpenForm(false);
        clearHitIds();
        reset();
        return showToast({
          type: 'success',
          message: 'Changes were successfully saved',
        });
      }

      showToast({
        type: 'error',
        message: 'Something went wrong',
      });
    },
    [mutation, checkedHitIds, searchData?.id]
  );

  return { loading, updateHits };
}
