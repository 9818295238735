import gql from 'graphql-tag';

export const CASE_REJECT_REASON_FRAGMENT = gql`
  fragment CaseRejectReasonFragment on CaseRejectReason {
    id
    created_at
    name
    updated_at
  }
`;
