import { gql } from '@apollo/client';
import {
  TRANSACTION_CASE_FRAGMENT,
  TRANSACTION_CHECKUP_RESULT_FRAGMENT,
  TRANSACTION_EVENT_FRAGMENT,
} from '../../../fragment/tm';
import { TRANSACTION_ACCOUNT_FRAGMENT } from '../../../fragment/tm/TransactionAccount';
import { TRANSACTION_ENTITY_FRAGMENT } from '../../../fragment/tm/TransactionEntity';
import { TRANSACTION_INSTRUMENT_FRAGMENT } from '../../../fragment/tm/TransactionInstrument';

export const TransactionCases = gql`
  ${TRANSACTION_CASE_FRAGMENT}
  query TransactionCases(
    $profile_id: String
    $created_at_from: String
    $created_at_to: String
    $deadline_at_from: String
    $sender_entity_id: String
    $receiver_entity_id: String
    $sort_field: String
    $sort_order: String
    $deadline_at_to: String
    $closed_at_from: String
    $total_amount_from: String
    $total_amount_to: String
    $closed_at_to: String
    $state: String
    $account_id: String
    $tag_names: String
    $resolution_ids: String
    $is_for_test: Boolean
    $page: Int
    $q: String
    $per: Int
  ) {
    transactionCases(
      profile_id: $profile_id
      created_at_from: $created_at_from
      created_at_to: $created_at_to
      deadline_at_from: $deadline_at_from
      deadline_at_to: $deadline_at_to
      closed_at_from: $closed_at_from
      sender_entity_id: $sender_entity_id
      receiver_entity_id: $receiver_entity_id
      closed_at_to: $closed_at_to
      is_for_test: $is_for_test
      state: $state
      total_amount_from: $total_amount_from
      total_amount_to: $total_amount_to
      account_id: $account_id
      sort_field: $sort_field
      sort_order: $sort_order
      tag_names: $tag_names
      resolution_ids: $resolution_ids
      page: $page
      per: $per
      q: $q
    ) @rest(type: "ResponseTransactionCases", path: "/tm/cases?{args}") {
      data @type(name: "TransactionCase") {
        ...TransactionCaseFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const TransactionCasesQueued = gql`
  ${TRANSACTION_CASE_FRAGMENT}
  query TransactionCasesQueued(
    $profile_id: String
    $created_at_from: String
    $created_at_to: String
    $deadline_at_from: String
    $sort_field: String
    $sender_entity_id: String
    $receiver_entity_id: String
    $sort_order: String
    $deadline_at_to: String
    $closed_at_from: String
    $total_amount_from: String
    $total_amount_to: String
    $closed_at_to: String
    $state: String
    $account_id: String
    $tag_names: String
    $is_for_test: Boolean
    $page: Int
    $q: String
  ) {
    transactionCasesQueued(
      profile_id: $profile_id
      created_at_from: $created_at_from
      created_at_to: $created_at_to
      deadline_at_from: $deadline_at_from
      sender_entity_id: $sender_entity_id
      receiver_entity_id: $receiver_entity_id
      deadline_at_to: $deadline_at_to
      closed_at_from: $closed_at_from
      closed_at_to: $closed_at_to
      is_for_test: $is_for_test
      state: $state
      total_amount_from: $total_amount_from
      total_amount_to: $total_amount_to
      account_id: $account_id
      sort_field: $sort_field
      sort_order: $sort_order
      tag_names: $tag_names
      page: $page
      per: 20
      q: $q
    ) @rest(type: "ResponseTransactionCases", path: "/tm/cases/queued?{args}") {
      data @type(name: "TransactionCase") {
        ...TransactionCaseFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const TransactionCase = gql`
  ${TRANSACTION_CASE_FRAGMENT}
  ${TRANSACTION_EVENT_FRAGMENT}
  ${TRANSACTION_ENTITY_FRAGMENT}
  ${TRANSACTION_INSTRUMENT_FRAGMENT}
  ${TRANSACTION_CHECKUP_RESULT_FRAGMENT}
  query TransactionCase($id: String) {
    transactionCase(id: $id)
      @rest(type: "ResponseTransactionCase", path: "/tm/cases/{args.id}") {
      data @type(name: "TransactionCase") {
        ...TransactionCaseFragment
        workflow_queues @type(name: "TransactionQueue") {
          id
          alert_deadline_days
          created_at
          description
          is_default
          name
          open_alerts_count
          role_ids
        }
        alerts @type(name: "TransactionAlert") {
          created_at
          data {
            title
            description
          }
          deadline_at
          event_support_keys
          id
          kind
          is_for_test
          is_from_scheduled_rule
          disposition @type(name: "TransactionDisposition") {
            id
            name
          }
          disposition_updated_at
          total_flagged_amount
          profiles @type(name: "Profile") {
            display_name
            id
          }
          assigned_to @type(name: "Account") {
            id
            email
            first_name
            last_name
            full_name
          }
          rules @type(name: "Rule") {
            async
            code
            created_at
            deleted
            description
            enabled
            id
            name
            is_for_test
            provider_id
            ruleset_id
            settings
          }
          rule_name
          state
          unit21_alert_external_id
          unit21_external_id
          unit21_url
          updated_at
        }
        attachments @type(name: "Attachment") {
          account @type(name: "Account") {
            created_at
            email
            first_name
            id
            last_name
            phone
            status
          }
          client_id
          created_at
          file_name
          file_size
          id
          kind
          subject_id
          subject_type
          updated_at
          uploaded_document_id
        }
        events @type(name: "TransactionEvent") {
          ...TransactionEventFragment
          checkup_result @type(name: "TransactionCheckupResult") {
            ...TransactionCheckupResultFragment
          }
          rulesets @type(name: "RuleSet") {
            can_sync
            client_id
            created_at
            deleted
            enabled
            id
            name
            rules_expression
          }
        }
        entities @type(name: "TransactionEntity") {
          ...TransactionEntityFragment
        }
        instruments @type(name: "TransactionInstrument") {
          ...TransactionInstrumentFragment
        }
      }
      success
    }
  }
`;

export const TransactionCaseVerions = gql`
  ${TRANSACTION_ACCOUNT_FRAGMENT}
  query TransactionCaseVerions($id: String) {
    transactionCaseVersions(id: $id)
      @rest(
        type: "ResponseTransactionCaseVerions"
        path: "/tm/cases/{args.id}/versions"
      ) {
      data @type(name: "TransactionCaseVersions") {
        account @type(name: "TransactionAccount") {
          ...TransactionAccountFragment
        }
        created_at
        event
        id
        item_id
        item_type
        object_changes
      }
      success
    }
  }
`;

export const TransactionCaseExternalRefs = gql`
  query TransactionCaseExternalRefs(
    $subject_id: String!
    $subject_type: String!
    $external_entity_type: String
  ) {
    transactionCaseExternalRefs(
      subject_id: $subject_id
      subject_type: $subject_type
      external_entity_type: $external_entity_type
    )
      @rest(
        type: "ResponseTransactionCaseExternalRefs"
        path: "/tm/external_refs?{args}"
      ) {
      data @type(name: "TransactionCaseExternalRef") {
        id
        external_entity_id
        external_entity_type
        external_system
        subject_id
        subject_type
      }
      success
    }
  }
`;
