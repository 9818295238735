import {
  DefaultButtonLoader,
  LoadableFunctionalComponent,
} from '@aid-module/ui';
import loadable from '@loadable/component';
import { ExportSubmissionPdfViewProps } from './component/types';

export const ExportSubmissionPdf = loadable(() => import('./component'), {
  resolveComponent: (components) => components.ExportSubmissionPdfComponent,
  fallback: <DefaultButtonLoader />,
}) as LoadableFunctionalComponent<ExportSubmissionPdfViewProps>;
