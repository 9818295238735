import { ContentLoader } from '../ContentLoader';
import { SmallButtonLoaderViewProps } from './types';

export function SmallButtonLoader({
  width,
  height,
}: SmallButtonLoaderViewProps) {
  return (
    <ContentLoader
      width={width ? `${width}px` : '100%'}
      height={`${height || 32.2}px`}
    >
      <rect
        width={width ? `${width}px` : '100%'}
        height={`${height || 32.2}px`}
        rx={height ? height / 2 : '16.1'}
      />
    </ContentLoader>
  );
}
