import { useServiceCheckupQuery } from '@aid-package/api';

export function useCheckupModel(checkupId: string) {
  const { data, loading, error } = useServiceCheckupQuery({
    variables: {
      id: checkupId,
    },
  });

  return {
    checkup: data?.serviceCheckup.data,
    loading,
    error,
  };
}
