import gql from 'graphql-tag';
import { TRANSACTION_ENTITY_FRAGMENT } from '../../../fragment/tm/TransactionEntity';
import { TRANSACTION_INSTRUMENT_FRAGMENT } from '../../../fragment/tm/TransactionInstrument';

export const TransactionInstruments = gql`
  ${TRANSACTION_INSTRUMENT_FRAGMENT}
  query TransactionInstruments(
    $entity_id: String
    $page: Int
    $per: Int
    $q: String
    $source: String
  ) {
    transactionInstruments(
      entity_id: $entity_id
      page: $page
      per: $per
      q: $q
      source: $source
    )
      @rest(
        type: "ResponseTransactionInstruments"
        path: "/tm/instruments?{args}"
      ) {
      data @type(name: "TransactionInstrument") {
        ...TransactionInstrumentFragment
      }
      meta {
        count
        page
        items
        pages
      }
      success
    }
  }
`;

export const TransactionInstrument = gql`
  ${TRANSACTION_INSTRUMENT_FRAGMENT}
  ${TRANSACTION_ENTITY_FRAGMENT}
  query TransactionInstrument($id: String!) {
    transactionInstrument(id: $id)
      @rest(
        type: "ResponseTransactionInstrument"
        path: "/tm/instruments/{args.id}"
      ) {
      data @type(name: "TransactionInstrument") {
        ...TransactionInstrumentFragment
        entity @type(name: "TransactionEntity") {
          ...TransactionEntityFragment
        }
      }
      success
    }
  }
`;
