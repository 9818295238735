import {
  RetentionMe,
  useCreateDataRetentionConfigMutation,
} from '@aid-package/api';
import { useCallback } from 'react';
import { showToast } from '@aid-module/ui';

export function useActionsModel() {
  const [mutation, { loading }] = useCreateDataRetentionConfigMutation({
    refetchQueries: [{ query: RetentionMe }],
    awaitRefetchQueries: true,
  });

  const createDataRetentionConfig = useCallback(
    async (data_storage_period_days?: string) => {
      const numberValue = Number(data_storage_period_days);
      const { data } = await mutation({
        variables: {
          body: {
            data_retention_config: {
              data_retention_approve: false,
              data_storage_period_days: isNaN(numberValue) ? null : numberValue,
            },
          },
        },
      });
      if (data?.createDataRetentionConfig?.success) {
        showToast({
          type: 'success',
          message: 'Changes were successfully saved',
        });
        return true;
      }
      showToast({
        type: 'error',
        message: 'Something went wrong',
      });
    },
    [mutation]
  );

  return { createDataRetentionConfig, loading };
}
