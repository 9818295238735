import styled from '@emotion/styled/macro';
import { getSpace, getColor } from '../../../../../../../../../../../utils';
import { EmotionContainerProps } from './types';

export const Container = styled.div<EmotionContainerProps>`
  padding: ${getSpace('8')} ${getSpace('12')};
  cursor: pointer;

  ${({ isActive }) =>
    isActive &&
    `
    background: ${getColor('main-deep-4')}!important;
  `}
`;
