import { Tag } from '../Count';
import { useCheckupStatusController } from './controller';
import { getSettings } from './controller/utils';
import { CheckupStatusViewProps } from './types';
import { useCheckStatus } from './controller/useCheckStatus';
import {
  CheckupKindClientCodeResponse,
  CheckupsClientExecutionStatusResponse,
  CheckupsClientAuditStatusResponse,
  CheckupsClientProviderStatusResponse,
  CheckupSearchHitMatchStatusResponse,
  CheckupsShuftiProKybProviderStatusResponse,
} from './constants';

function CheckupStatus({ checkup, size = 11 }: CheckupStatusViewProps) {
  const { label, color } = useCheckupStatusController(checkup);

  return <Tag count={label} padding="S" size={size} background={color} />;
}

CheckupStatus.getSettings = getSettings;
CheckupStatus.useCheckStatus = useCheckStatus;

export {
  CheckupStatus,
  CheckupKindClientCodeResponse,
  CheckupsClientExecutionStatusResponse,
  CheckupsClientAuditStatusResponse,
  CheckupsClientProviderStatusResponse,
  CheckupSearchHitMatchStatusResponse,
  CheckupsShuftiProKybProviderStatusResponse,
};
