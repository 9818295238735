import { CustomerMessageStatusViewProps } from './types';
import { useCustomerMessageStatusController } from './controller';
import { Text } from '../Text';
import { Tag } from '../Count';
import {
  CUSTOMER_MESSAGE_STATUSES,
  CUSTOMER_MESSAGE_DISPLAY_STATUSES,
} from './constants';

function CustomerMessageStatus({ status }: CustomerMessageStatusViewProps) {
  const { background, text } = useCustomerMessageStatusController(status);

  if (text) {
    return <Tag count={text} padding="S" size={11} background={background} />;
  }

  return <Text text="-" size={11} color="main-deep-4" />;
}

export {
  CustomerMessageStatus,
  CUSTOMER_MESSAGE_STATUSES,
  CUSTOMER_MESSAGE_DISPLAY_STATUSES,
};
