import { GridContainer } from '../../../../../../../../../containers';
import { AutomaticTooltip } from '../../../../../../../../AutomaticTooltip';
import { FilterValue } from '../../../FilterValue';
import { FilterValueViewProps } from '../../../FilterValue/types';
import { Button } from './components';
import { useFilterTemplateController } from './controller';
import { FilterTemplateViewProps } from './types';

function renderValue(item: FilterValueViewProps) {
  return <FilterValue {...item} key={item.itemKey} />;
}

export function FilterTemplate({ filterTemplate }: FilterTemplateViewProps) {
  const { onClick, isActive, selectedFilters } =
    useFilterTemplateController(filterTemplate);
  return (
    <AutomaticTooltip
      pattern="top,right,left,bottom"
      canShow={selectedFilters.length > 0}
      view={
        <Button
          name={filterTemplate.name}
          onClick={onClick}
          isActive={isActive}
        />
      }
    >
      <GridContainer gap="4">{selectedFilters.map(renderValue)}</GridContainer>
    </AutomaticTooltip>
  );
}
