import { PropsWithChildren } from 'react';
import { CommentContext } from './context';
import { useCommentProviderController } from './controller';

export function CommentContextProvider({ children }: PropsWithChildren) {
  const { value } = useCommentProviderController();
  return (
    <CommentContext.Provider value={value.current}>
      {children}
    </CommentContext.Provider>
  );
}
