import { GridContainer, ContentLoader } from '@aid-module/ui';
import { ClientsContainer } from '../Clients/styles';

export function Fallback() {
  return (
    <GridContainer
      gridTemplateColumns="800px"
      justifyContent="center"
      alignItems="start"
      gap="20"
      gridTemplateRows="repeat(4, max-content)"
    >
      <ContentLoader width="100%" height="102px">
        <rect rx="4" ry="4" width="100%" height="102px" />
      </ContentLoader>

      <ClientsContainer gap="20">
        <ContentLoader width="100%" height="152.59px">
          <rect rx="4" ry="4" width="100%" height="152.59px" />
        </ContentLoader>

        <ContentLoader width="100%" height="152.59px">
          <rect rx="4" ry="4" width="100%" height="152.59px" />
        </ContentLoader>

        <ContentLoader width="100%" height="152.59px">
          <rect rx="4" ry="4" width="100%" height="152.59px" />
        </ContentLoader>
      </ClientsContainer>

      <ContentLoader width="100%" height="82px">
        <rect rx="4" ry="4" width="100%" height="82px" />
      </ContentLoader>

      <ContentLoader width="100%" height="18.2px">
        <rect rx="4" ry="4" width="100%" height="18.2px" />
      </ContentLoader>
    </GridContainer>
  );
}
