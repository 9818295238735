import { DefaultButtonLoader, GridContainer, ListLoader } from '@aid-module/ui';

export function Fallback() {
  return (
    <GridContainer gap="20">
      <ListLoader count={7} />
      <DefaultButtonLoader />
    </GridContainer>
  );
}
