import { useCallback } from 'react';
import { TYPES } from '@aid-package/api';
import {
  getShow,
  usePermissionsContext,
  getAvailabelFromString,
  CheckFeature,
  useFeaturesContext,
  FEATURES,
} from '@aid-package/context';
import { useCheckupKindContext } from '../../../../../../../context';
import { CheckupKindValue } from '../../../../../../../context/types';
import { CheckupKindPermissions } from './constants';

export function useCheckupKindSelectController() {
  const { checkupKind, setCheckupKind } = useCheckupKindContext();
  const permissions = usePermissionsContext();
  const { features } = useFeaturesContext();

  const onChange = useCallback(
    (value: CheckupKindValue | null) => {
      setCheckupKind(value);
    },
    [setCheckupKind]
  );

  const reduceData = useCallback(
    (
      checkup: TYPES.ServiceCheckupKindsQuery['serviceCheckupKinds']['data'][0]
    ) => {
      const check = CheckupKindPermissions[checkup.code];
      const canAvailabel = getAvailabelFromString(
        checkup.code as FEATURES,
        features
      );
      if (check) {
        const canShow = getShow(permissions, check, false);

        if (canShow && canAvailabel) {
          return {
            label: checkup.name,
            value: checkup.id,
            code: checkup.code,
          };
        }
        return;
      }
      if (canAvailabel) {
        return {
          label: checkup.name,
          value: checkup.id,
          code: checkup.code,
        };
      }

      return;
    },
    [permissions]
  );

  return { reduceData, checkupKind, onChange };
}
