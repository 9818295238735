import gql from 'graphql-tag';
import { FORM_TEMPLATE_FRAGMENT } from '../../../fragment/admin/formTemplates';

export const ToggleFormTemplate = gql`
  ${FORM_TEMPLATE_FRAGMENT}
  mutation ToggleFormTemplate($id: String!, $body: BodyToggleFormTemplate) {
    toggleFormTemplate(id: $id, body: $body)
      @rest(
        type: "ToggleFormTemplate"
        path: "/core/admin/whitelabel_templates/{args.id}/toggle"
        bodyKey: "body"
        method: "PATCH"
      ) {
      data @type(name: "FormTemplate") {
        ...FormTemplateFragment
      }
      success
    }
  }
`;
