import { Modal } from '../Modal';
import { FormCardModalViewProps } from './types';
import { Container } from './styles';
import { FormCard } from '../../FormCard';
import { ModalBodyViewProps } from '../Modal/types';
import { cloneElement, useMemo } from 'react';

function RenderBody({
  onClose,
  body,
  label,
  icon,
  maxWidth,
  className,
}: ModalBodyViewProps & Omit<FormCardModalViewProps, 'button'>) {
  const bodyComponent = useMemo(
    () => cloneElement(body, { onClose }),
    [body, onClose]
  );

  return (
    <Container className={className}>
      <FormCard label={label} icon={icon} maxWidth={maxWidth}>
        {bodyComponent}
      </FormCard>
    </Container>
  );
}

export function FormCardModal({ button, ...props }: FormCardModalViewProps) {
  return <Modal body={<RenderBody {...props} />} button={button} />;
}
