import { useRetentionMeQuery } from '@aid-package/api';
import { useCheckPermission } from '../../protected';
import { PERMISSIONS } from '../../permissions';

export function useRetentionProviderModel() {
  const { canShow } = useCheckPermission(
    PERMISSIONS.MANAGE_DATA_RETENTION,
    true
  );
  const { loading, data } = useRetentionMeQuery({
    fetchPolicy: 'cache-first',
    skip: !canShow,
  });

  return { retention: data?.retentionMe?.data, loading };
}
