import { Card, Checkbox, GridContainer, Tag, Text } from '@aid-module/ui';
import { Comments } from './components';
import { useHitController } from './controller';
import {
  Container,
  Label,
  CapitalizeTag,
  TagsContainer,
  BorderContainer,
} from './styles';
import { HitViewProps } from './types';

function renderRelevance(it?: string, index?: number) {
  return (
    <Tag
      count={it || '-'}
      size={11}
      padding="S"
      key={index}
      weight="Medium"
      background="error-deep-1"
      color="error-deep-4"
    />
  );
}

function renderMatched(it?: string, index?: number) {
  return (
    <Tag
      count={it || '-'}
      size={11}
      padding="S"
      key={index}
      weight="Medium"
      background="error-deep-1"
      color="error-deep-4"
    />
  );
}

export function Hit({ hit, searchId, asLink, checkupId }: HitViewProps) {
  const { card, matchStatusSetting, checkboxValue, href, onChange, styles } =
    useHitController(hit, asLink);

  if (asLink && href) {
    return (
      <Container
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        style={styles}
      >
        <Card
          background={hit.is_whitelisted ? 'success-deep-2' : 'main-deep-1'}
          padding="XS"
        >
          <GridContainer gap="12">
            <BorderContainer
              alignItems="center"
              gap="10"
              gridTemplateColumns="24px minmax(0, max-content) max-content"
              paddingBottom="14"
            >
              {!!searchId && (
                <Checkbox onChange={onChange} value={checkboxValue} size={24} />
              )}
              <Label text={card.name} weight="VeryBig" color="main-deep-5" />
              <CapitalizeTag
                count={card.entity_type}
                weight="Medium"
                padding="S"
                size={12}
              />
            </BorderContainer>
            <BorderContainer
              gridTemplateColumns="114px max-content"
              paddingBottom="12"
            >
              <Text
                text="Match status"
                weight="Medium"
                color="main-deep-3"
                lineHeight="24px"
              />
              <Text
                lineHeight="24px"
                text={matchStatusSetting?.label}
                color={matchStatusSetting?.color}
                weight="Medium"
              />
            </BorderContainer>
            <BorderContainer
              gridTemplateColumns="114px max-content"
              paddingBottom="12"
            >
              <Text
                text="Risk level"
                weight="Medium"
                color="main-deep-3"
                lineHeight="24px"
              />
              <Text
                weight="Medium"
                lineHeight="24px"
                text={hit?.risk_level || '-'}
              />
            </BorderContainer>
            <BorderContainer
              gridTemplateColumns="114px max-content"
              paddingBottom="12"
            >
              <Text
                text="Whitelisted"
                weight="Medium"
                color="main-deep-3"
                lineHeight="24px"
              />
              <Text
                weight="Medium"
                lineHeight="24px"
                text={hit.is_whitelisted ? 'Yes' : 'No'}
                color={hit.is_whitelisted ? 'success-deep-5' : 'error-deep-4'}
              />
            </BorderContainer>
            <BorderContainer gridTemplateColumns="114px 1fr" paddingBottom="12">
              <Text
                text="Relevance"
                weight="Medium"
                color="main-deep-3"
                lineHeight="24px"
              />
              <TagsContainer>
                {card.relevance.map(renderRelevance)}
              </TagsContainer>
            </BorderContainer>
            <BorderContainer gridTemplateColumns="114px 1fr" paddingBottom="12">
              <Text
                text="Matched"
                weight="Medium"
                color="main-deep-3"
                lineHeight="24px"
              />
              <TagsContainer>{card.matched.map(renderMatched)}</TagsContainer>
            </BorderContainer>
            <Comments checkupId={checkupId} hit={hit} />
          </GridContainer>
        </Card>
      </Container>
    );
  }

  return (
    <div style={styles}>
      <Card
        background={hit.is_whitelisted ? 'success-deep-2' : 'main-deep-1'}
        padding="XS"
      >
        <GridContainer gap="12">
          <BorderContainer
            alignItems="center"
            gap="10"
            gridTemplateColumns="1fr max-content"
            paddingBottom="14"
          >
            <GridContainer
              gridTemplateColumns="minmax(0, max-content) max-content"
              alignItems="center"
              gap="10"
            >
              <Label text={card.name} weight="VeryBig" color="main-deep-5" />
              <CapitalizeTag
                count={card.entity_type}
                weight="Medium"
                padding="S"
                size={12}
              />
            </GridContainer>
            <Comments checkupId={checkupId} hit={hit} />
          </BorderContainer>
          <BorderContainer
            gridTemplateColumns="114px max-content"
            paddingBottom="12"
          >
            <Text
              text="Match status"
              weight="Medium"
              color="main-deep-3"
              lineHeight="24px"
            />
            <Text
              lineHeight="24px"
              text={matchStatusSetting?.label}
              color={matchStatusSetting?.color}
              weight="Medium"
            />
          </BorderContainer>
          <BorderContainer
            gridTemplateColumns="114px max-content"
            paddingBottom="12"
          >
            <Text
              text="Risk level"
              weight="Medium"
              color="main-deep-3"
              lineHeight="24px"
            />
            <Text
              weight="Medium"
              lineHeight="24px"
              text={hit?.risk_level || '-'}
            />
          </BorderContainer>
          <BorderContainer
            gridTemplateColumns="114px max-content"
            paddingBottom="12"
          >
            <Text
              text="Whitelisted"
              weight="Medium"
              color="main-deep-3"
              lineHeight="24px"
            />
            <Text
              weight="Medium"
              lineHeight="24px"
              text={hit.is_whitelisted ? 'Yes' : 'No'}
              color={hit.is_whitelisted ? 'success-deep-5' : 'error-deep-4'}
            />
          </BorderContainer>
          <BorderContainer gridTemplateColumns="114px 1fr" paddingBottom="12">
            <Text
              text="Relevance"
              weight="Medium"
              color="main-deep-3"
              lineHeight="24px"
            />
            <TagsContainer>{card.relevance.map(renderRelevance)}</TagsContainer>
          </BorderContainer>
          <GridContainer gridTemplateColumns="114px 1fr">
            <Text
              text="Matched"
              weight="Medium"
              color="main-deep-3"
              lineHeight="24px"
            />
            <TagsContainer>{card.matched.map(renderMatched)}</TagsContainer>
          </GridContainer>
        </GridContainer>
      </Card>
    </div>
  );
}
