import { useCallback } from 'react';
import { Tab } from '../../../controller/types';

import { HeaderViewProps } from '../types';

export function useHeaderController(onChange: HeaderViewProps['onChange']) {
  const onChangeTab = useCallback((tab: Tab) => () => onChange(tab), []);

  return { onChangeTab };
}
