import { useCallback, useRef } from 'react';

export function useLoaderController() {
  const svgRef = useRef<SVGGElement>(null);

  const onAnimationEnd = useCallback(() => {
    if (svgRef.current) {
      requestAnimationFrame(() => {
        svgRef.current?.classList?.remove('animation');
        requestAnimationFrame(() => {
          svgRef.current?.classList?.add('animation');
        });
      });
    }
  }, []);

  return { svgRef, onAnimationEnd };
}
