import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Text } from '../Text';
import { EmotionTextProps } from '../types';
import { EmotionLinkTextProps } from './types';

export function getDefaultLinkTextProps({ size = 14 }: EmotionLinkTextProps) {
  return `
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-decoration-line: underline;
      text-underline-offset: 2px;
      ${size ? `line-height: ${size + 9}px;` : ''};
    `;
}

const DefaultProps = `
  cursor: pointer;
  display: grid;
  text-decoration: none;
  grid-template-columns: minmax(0px, 1fr) max-content;
  gap: 4px;
  align-items: center;
`;

export const StyledLink = styled(Link)<{ withoutChildren?: boolean }>`
  ${DefaultProps}
  ${({ withoutChildren }) =>
    withoutChildren ? `grid-template-columns: minmax(0px, 1fr);` : ''}
`;

export const ExternalStyledLink = styled.a`
  ${DefaultProps}
`;

export const LinkText = styled(Text)<EmotionTextProps>`
  ${({ size }) => getDefaultLinkTextProps({ size })}
`;
