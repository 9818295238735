import { gql } from '@apollo/client';

export const CreateCustomerMessage = gql`
  mutation CreateCustomerMessage($body: BodyCreateOrUpdateCustomerMessage!) {
    createCustomerMessage(body: $body)
      @rest(
        type: "CreateCustomerMessage"
        path: "/core/client/customer_messages"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "CustomerMessage") {
        id
        body
        client_id
        created_at
        name
        state
        email
        subject
        updated_at
        account @type(name: "Account") {
          id
          full_name
        }
        header
        sent_at
      }
      success
    }
  }
`;

export const CreateAndSendCustomerMessage = gql`
  mutation CreateAndSendCustomerMessage(
    $body: BodyCreateOrUpdateCustomerMessage!
  ) {
    createAndSendCustomerMessage(body: $body)
      @rest(
        type: "CreateAndSendCustomerMessage"
        path: "/core/client/customer_messages/create_and_send"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "CustomerMessage") {
        id
        body
        client_id
        created_at
        name
        state
        email
        subject
        updated_at
        account @type(name: "Account") {
          id
          full_name
        }
        header
        sent_at
      }
      success
    }
  }
`;

export const UpdateCustomerMessage = gql`
  mutation UpdateCustomerMessage(
    $id: String!
    $body: BodyCreateOrUpdateCustomerMessage!
  ) {
    updateCustomerMessage(id: $id, body: $body)
      @rest(
        type: "UpdateCustomerMessage"
        path: "/core/client/customer_messages/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "CustomerMessage") {
        id
        body
        client_id
        created_at
        name
        state
        email
        subject
        updated_at
        account @type(name: "Account") {
          id
          full_name
        }
        header
        sent_at
      }
      success
    }
  }
`;

export const UpdateAndSendCustomerMessage = gql`
  mutation UpdateAndSendCustomerMessage(
    $id: String!
    $body: BodyCreateOrUpdateCustomerMessage!
  ) {
    updateAndSendCustomerMessage(id: $id, body: $body)
      @rest(
        type: "UpdateAndSendCustomerMessage"
        path: "/core/client/customer_messages/{args.id}/update_and_send"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "CustomerMessage") {
        id
        body
        client_id
        created_at
        name
        state
        email
        subject
        updated_at
        account @type(name: "Account") {
          id
          full_name
        }
        header
        sent_at
      }
      success
    }
  }
`;

export const DeleteCustomerMessage = gql`
  mutation DeleteCustomerMessage($id: String!) {
    deleteCustomerMessage(id: $id)
      @rest(
        type: "DeleteCustomerMessage"
        path: "/core/client/customer_messages/{args.id}"
        method: "DELETE"
      ) {
      data @type(name: "CustomerMessage") {
        id
        body
        client_id
        created_at
        name
        state
        email
        subject
        updated_at
        account @type(name: "Account") {
          id
          full_name
        }
        header
        sent_at
      }
      success
    }
  }
`;

export const SendCustomerMessage = gql`
  mutation SendCustomerMessage($body: BodySendCustomerMessage!) {
    sendCustomerMessage(body: $body)
      @rest(
        type: "SendCustomerMessage"
        path: "/core/client/customer_messages/send_message"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "CustomerMessage") {
        id
        body
        client_id
        created_at
        name
        state
        email
        subject
        updated_at
        account @type(name: "Account") {
          id
          full_name
        }
        header
        sent_at
      }
      success
    }
  }
`;
