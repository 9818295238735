import gql from 'graphql-tag';
import { CASE_REJECT_REASON_FRAGMENT } from '../../../fragment/account/caseRejectReasons';

export const UpdateCaseRejectReason = gql`
  ${CASE_REJECT_REASON_FRAGMENT}
  mutation UpdateCaseRejectReason(
    $id: String!
    $body: BodyCreateOrUpdateCaseRejectReason!
  ) {
    updateCaseRejectReason(id: $id, body: $body)
      @rest(
        type: "CaseRejectReasonUpdate"
        path: "/core/client/case_rejection_reasons/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "CaseRejectReason") {
        ...CaseRejectReasonFragment
      }
      success
    }
  }
`;

export const CreateCaseRejectReason = gql`
  ${CASE_REJECT_REASON_FRAGMENT}
  mutation CreateCaseRejectReason($body: BodyCreateOrUpdateCaseRejectReason!) {
    createCaseRejectReason(body: $body)
      @rest(
        type: "CaseRejectReasonCreate"
        path: "/core/client/case_rejection_reasons"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "CaseRejectReason") {
        ...CaseRejectReasonFragment
      }
      success
    }
  }
`;

export const DeleteCaseRejectReason = gql`
  mutation DeleteCaseRejectReason($id: String!) {
    deleteCaseRejectReason(id: $id)
      @rest(
        type: "CaseRejectReasonDelete"
        path: "/core/client/case_rejection_reasons/{args.id}"
        method: "DELETE"
      ) {
      success
    }
  }
`;
