import { TmEventStatusViewProps } from './types';
import { useTmEventStatusController } from './controller';
import { Text } from '../Text';
import { Tag } from '../Count';
import {
  TRANSACTION_STATUSES,
  TRANSACTION_DISPLAY_STATUSES,
} from './constants';

function TmEventStatus({ status }: TmEventStatusViewProps) {
  const { background, text } = useTmEventStatusController(status);

  if (text) {
    return <Tag count={text} padding="S" size={11} background={background} />;
  }

  return <Text text="-" size={12} color="main-deep-4" />;
}

export { TmEventStatus, TRANSACTION_STATUSES, TRANSACTION_DISPLAY_STATUSES };
