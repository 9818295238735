import { ButtonViewProps } from '../types';
import { ButtonByType } from '../components';

export function WarningButton(props: ButtonViewProps) {
  return (
    <ButtonByType
      {...props}
      color="white-primary"
      background="warning-deep-4"
    />
  );
}
