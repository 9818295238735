import { Container } from '../shared.styles';
import { Text } from '../../../../../../../../Text';
import { Icon } from '../../../../../../../../Icon';
import { DropdownButtonViewProps } from '../../../../../../../../DropdownButton/component/types';
import { FilterTemplate } from './components';
import { TYPES } from '@aid-package/api';
import { AdditionalTemplatesViewProps } from './types';
import { DropdownButton } from '../../../../../../../../DropdownButton';

function Button({ toggleOpen, open }: DropdownButtonViewProps) {
  return (
    <Container
      gridTemplateColumns="minmax(0, auto) max-content"
      alignItems="center"
      gap="8"
      onClick={toggleOpen}
      isActive={!!open}
    >
      <Text
        text="Additional templates"
        color={open ? 'white-primary' : 'main-deep-4'}
        weight="Medium"
      />
      <Icon
        size={14}
        glyph={open ? 'arrowUp' : 'arrowDown'}
        color="main-deep-2"
        fill="main-deep-4"
      />
    </Container>
  );
}

function renderTemplate(
  filterTemplate: TYPES.TabRegistriesQuery['tabRegistries']['data'][0]
) {
  return (
    <FilterTemplate key={filterTemplate.id} filterTemplate={filterTemplate} />
  );
}

export function AdditionalTemplate({
  templates,
}: AdditionalTemplatesViewProps) {
  return (
    <DropdownButton button={<Button />}>
      {templates.map(renderTemplate)}
    </DropdownButton>
  );
}
