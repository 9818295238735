import { LoadableFunctionalComponent } from '@aid-module/ui';
import loadable from '@loadable/component';
import { FormContainer } from '../components';
import { Fallback } from './components';

export const ResolutionUpdate = loadable(() => import('./component'), {
  resolveComponent: (components) => components.ResolutionUpdateComponent,
  fallback: (
    <FormContainer
      label="Update resolution for screenings"
      icon="checkup"
      isLoading
    >
      <Fallback />
    </FormContainer>
  ),
}) as LoadableFunctionalComponent<{}>;
