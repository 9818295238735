import gql from 'graphql-tag';

export const Me = gql`
  query Me {
    me @rest(type: "ResponseMe", path: "/core/accounts/me") {
      data @type(name: "Account") {
        accounts_clients @type(name: "AccountClient") {
          id
          position_name
          role
          client @type(name: "Client") {
            file_name
            file_size
            id
            logo_id
            name
            custom_domain
            retention_plan_config_id
          }
        }
        extra_attributes @type(name: "UserExtraAttributes") {
          about_me
          birthday
          city
          company
          country_code
        }
        avatar_id
        email
        file_name
        file_size
        first_name
        full_name
        id
        last_name
        onboarding_verification_id
        onboarding_verification_status
        phone
        region_code
        role
        status
      }
      success
    }
  }
`;

export const RetentionMe = gql`
  query RetentionMe {
    retentionMe
      @rest(
        type: "ResponseRetentionMe"
        path: "/core/client/data_retention_configs/me"
      ) {
      data @type(name: "DataRetentionConfig") {
        client_id
        created_at
        created_by @type(name: "Account") {
          avatar_id
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        data_retention_approve
        data_storage_period_days
        id
        updated_at
      }
      success
    }
  }
`;
