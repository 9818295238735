import { SelectOption } from '../../components';
import { DateFormatConfig } from './types';

export const DATE_FORMAT_CONFIGS: DateFormatConfig = {
  US: {
    dateTime: 'MM.DD.YYYY hh:mm A',
    date: 'MM.DD.YYYY',
  },
  EU: {
    dateTime: 'DD.MM.YYYY HH:mm',
    date: 'DD.MM.YYYY',
  },
};

export const DateFormatSelectOptions: SelectOption[] = [
  {
    label: 'US',
    value: 'US',
  },
  {
    label: 'EU',
    value: 'EU',
  },
];
