import { KeyboardEvent, useCallback, useState } from 'react';
import { useSearchInputModel } from '../model';
import { FEATURES, useCheckAvailableFeature } from '@aid-package/context';

export function useSearchInputController() {
  const { createSearchEntrie, loading } = useSearchInputModel();
  const [value, setValue] = useState<string | undefined>();
  const onChange = useCallback((value?: string) => setValue(value), [setValue]);
  const { canAvailabel } = useCheckAvailableFeature(FEATURES.GLOBAL_SEARCH);

  const handlerSubmit = useCallback(() => {
    if (value && !loading) {
      createSearchEntrie(value);
    }
  }, [value, loading, createSearchEntrie]);

  const onPressEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handlerSubmit();
      }
    },
    [handlerSubmit]
  );

  return {
    value,
    disabled: !value || loading || !canAvailabel,
    loading,
    canAvailabel,
    onChange,
    onPressEnter,
    handlerSubmit,
  };
}
