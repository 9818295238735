import { showToast, Notification as NotificationToast } from '@aid-module/ui';
import {
  Case,
  ServiceCheckup,
  useNotificationConfigLazyQuery,
  useNotificationSubscriptionConfigLazyQuery,
  TYPES,
  Me,
} from '@aid-package/api';
import { Centrifuge } from 'centrifuge';
import { useEffect, useRef } from 'react';
import { Notification } from '@aid-module/notification';

export function useWSModel(callback: () => void, refetch: () => void) {
  const currentConnect = useRef<Centrifuge>();
  const channel = useRef<string>();
  const [loadConfig, { loading, client }] = useNotificationConfigLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [loadSubscriptionConfig, { loading: loadingSubscriptionConfig }] = useNotificationSubscriptionConfigLazyQuery({
    fetchPolicy: 'network-only',
  });

  const showNotificationToast = useRef(
    async (
      data: TYPES.NotificationsQuery['notifications']['data'][0]['payload']['data']
    ) => {
      const me = client.readQuery<TYPES.MeQuery>({
        query: Me,
      });
      const accountClients = me?.me.data.accounts_clients;
      const accountClient = accountClients?.find(
        (account) => account?.client?.id === data.client_id
      );
      NotificationToast.showMessage({
        client: accountClient?.client ? accountClient?.client : undefined,
        children: <Notification size={14} withoutLinks payloadData={data} />,
        callback,
      });
      refetch();
      if (
        data.notification_type === 'case_status_changes' ||
        data.notification_type === 'assignments'
      ) {
        const caseData = client.readQuery({
          query: Case,
          variables: { id: data.obj_id },
        });
        if (caseData) {
          const res = await client.query({
            query: Case,
            variables: { id: data.obj_id },
            fetchPolicy: 'network-only',
          });
          client.writeQuery({
            query: Case,
            variables: { id: data.obj_id },
            data: res.data,
          });
        }
      } else if (data.notification_type === 'checkup') {
        const checkupData = client.readQuery({
          query: ServiceCheckup,
          variables: { id: data.obj_id },
        });
        if (checkupData) {
          const res = await client.query({
            query: ServiceCheckup,
            variables: { id: data.obj_id },
            fetchPolicy: 'network-only',
          });
          client.writeQuery({
            query: Case,
            variables: { id: data.obj_id },
            data: res.data,
          });
        }
      }
    }
  );

  const getToken = useRef(async () => {
    const { data } = await loadConfig();
    if (data?.notificationConfig?.data) {
      channel.current = data.notificationConfig.data.channel;
    }
    return data?.notificationConfig?.data?.token || '';
  });

  const getSubscriptionToken = useRef(async () => {
    const { data } = await loadSubscriptionConfig();
    return data?.notificationSubscriptionConfig?.data?.token || '';
  });

  const connect = useRef(() => {
    if (currentConnect.current) {
      currentConnect.current = undefined;
    }
    const centrifuge = new Centrifuge(
      `${process.env.REACT_APP_CENTRIFUGE_URL}?cf_protocol_version=v2`,
      {
        getToken: getToken.current,
        minReconnectDelay: 1000,
      }
    );
    currentConnect.current = centrifuge;

    const onDisconnect = () => {
      const subscriptions = Object.values(centrifuge.subscriptions());
      if (subscriptions.length) {
        subscriptions.forEach((subscription) => {
          centrifuge.removeSubscription(subscription);
        });
      }
    };

    centrifuge.on('error', onDisconnect);
    centrifuge.on('disconnected', onDisconnect);

    centrifuge.on('connected', () => {
      if (channel.current) {
        const currentSubscription = centrifuge.getSubscription(channel.current);
        if (currentSubscription) {
          currentSubscription.removeAllListeners();
          currentSubscription.on('publication', (data) =>
            showNotificationToast.current(data.data)
          );
          if (currentSubscription.state !== 'subscribed') {
            currentSubscription.subscribe();
          }
        } else {
          onDisconnect();
          const sub = centrifuge.newSubscription(channel.current, {
            getToken: getSubscriptionToken.current,
            minResubscribeDelay: 1000,
          });
          sub.on('publication', (data) =>
            showNotificationToast.current(data.data)
          );
          sub.subscribe();
        }
      } else {
        showToast({
          type: 'error',
          message: 'Cannot connect to websoket. Please, try reload page.',
        });
      }
    });
    centrifuge.connect();
    return;
  });

  useEffect(() => {
    connect.current();

    return () => {
      currentConnect.current?.disconnect();
    };
  }, []);

  return { loading: loading || loadingSubscriptionConfig };
}
