import { VariablesStateParams } from '../controller/types';
import { SessionStorageProvider } from './provider';
import { ProviderDelegate } from './provider/types';

export class Cache {
  provider: ProviderDelegate;

  get variables() {
    return this.provider.getSavedByKey();
  }

  constructor(key: string) {
    this.provider = SessionStorageProvider.init(key);
  }

  save(next: VariablesStateParams) {
    this.provider.saveValueByKey(next);
  }

  static init(key: string) {
    return new Cache(key);
  }
}
