import dayjs from 'dayjs';
import { isMultiValue } from '../../../../../../../../../../../../../../../../../../context';

function getDateInUTC(value: string) {
  if (dayjs(value).isValid()) {
    const utcDate = Date.parse(value);
    const offsetTimeZone = new Date().getTimezoneOffset() * 60000;
    return new Date(utcDate - offsetTimeZone);
  } else {
    return '';
  }
}

export function parseFilters(filtersValues: Record<string, any>) {
  return Object.entries(filtersValues || {}).reduce<{
    [key: string]: string | boolean | string[];
  }>((acc, item) => {
    const [key, value] = item;
    if (!value) {
      return acc;
    }
    if (typeof value === 'object') {
      if (isMultiValue(value)) {
        return { ...acc, [key]: value.map((option) => option.value) };
      }
      if (Array.isArray(value)) {
        return { ...acc, [key]: value };
      }
      return { ...acc, [key]: value.value };
    }
    if (
      typeof value === 'string' &&
      isNaN(Number(value)) &&
      dayjs(value).isValid()
    ) {
      return { ...acc, [key]: getDateInUTC(value) };
    }
    return { ...acc, [key]: value };
  }, {});
}
