import {
  LoadableFunctionalComponent,
  TableWithoutSubHeader,
} from '@aid-module/ui';
import loadable from '@loadable/component';

export const BlacklistReasonsRegister = loadable(() => import('./component'), {
  resolveComponent: (components) =>
    components.BlacklistReasonsRegisterComponent,
  fallback: <TableWithoutSubHeader />,
}) as LoadableFunctionalComponent<{}>;
