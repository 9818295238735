import { GridContainer, Text } from '@aid-module/ui';
import { PropsWithChildren } from 'react';

export function DescriptionContainer({ children }: PropsWithChildren<{}>) {
  return (
    <GridContainer gridTemplateColumns="1fr" gap="32">
      <Text
        text="Description"
        size={21}
        weight="Medium"
        color="main-deep-4"
        align="center"
        uppercase
      />
      {children}
    </GridContainer>
  );
}
