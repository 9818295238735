import { ExtendAccountType } from '../../types';
import { Text } from '../../../Text';
import { useContactsController } from './controller';
import { Container } from './styles';
import { ContactsViewProps } from './types';
import { Copy } from './components';

export function Contacts<T extends ExtendAccountType>({
  account,
}: ContactsViewProps<T>) {
  const { email, phone } = useContactsController(account);

  return (
    <Container gap="14" gridTemplateRows="repeat(4, max-content)">
      <Text text="Contacts" uppercase size={14} weight="VeryBig" />
      <Copy copyType="Email" icon="email" label={email || ''} />
      <Copy copyType="Phone" icon="phone" label={phone || ''} />
      <Copy copyType="UUID" icon="id" label={account.id || ''} />
    </Container>
  );
}
