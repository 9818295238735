import { PERMISSIONS, Check } from '@aid-package/context';
import { CheckupKindClientCodeResponse } from '../../../../../../../../constants';

export const CheckupKindPermissions: Record<string, Check> = {
  [CheckupKindClientCodeResponse.AID_EMAIL_REGEXP]:
    PERMISSIONS.AID_CHECKUPS_PERFORM,
  [CheckupKindClientCodeResponse.COMPLY_ADVANTAGE_AML]:
    PERMISSIONS.CA_AML_INDIVIDUAL,
  [CheckupKindClientCodeResponse.COMPLY_ADVANTAGE_AML_BUSINESS]:
    PERMISSIONS.CA_AML_BUSINESS,
  [CheckupKindClientCodeResponse.UNIT_21_ENTITY_ID]: PERMISSIONS.UNIT_21_KYB,
  [CheckupKindClientCodeResponse.UNIT_21_ENTITY_DOC]:
    PERMISSIONS.UNIT_21_KYB_DOC,
  [CheckupKindClientCodeResponse.UNIT_21_KYB]: PERMISSIONS.UNIT_21_KYB_DOC,
  [CheckupKindClientCodeResponse.ELLIPTIC_WALLET]: PERMISSIONS.ELLIPTIC,
  [CheckupKindClientCodeResponse.MAXMIND_GEO_IP]: PERMISSIONS.MAXMIND_GEO_IP,
  [CheckupKindClientCodeResponse.SHUFTIPRO_KYB]: PERMISSIONS.BUS_REGISTRY,
  [CheckupKindClientCodeResponse.SHUFTIPRO_AML]: PERMISSIONS.AML_INDIVIDUAL,
  [CheckupKindClientCodeResponse.SHUFTIPRO_AML_BUSINESS]:
    PERMISSIONS.AML_BUSINESS,
  [CheckupKindClientCodeResponse.FINCEN_BUSINESS]: PERMISSIONS.FINCEN_BUSINESS,
  [CheckupKindClientCodeResponse.FINCEN_PERSON]: PERMISSIONS.FINCEN_INDIVIDUAL,
  [CheckupKindClientCodeResponse.AID_BLACK_LIST_PERSON]:
    PERMISSIONS.FINCEN_INDIVIDUAL,
  [CheckupKindClientCodeResponse.AID_BLACK_LIST_BUSINESS]:
    PERMISSIONS.FINCEN_BUSINESS,
};
