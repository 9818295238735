import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { EmailsNavigationItems } from '../../../../../../Breadcrumbs/constants';
import { useCheckPermission } from '@aid-package/context';

export function useEmailsLinkController() {
  const { client_id } = useParams();

  const { canShow: canLetters } = useCheckPermission(
    EmailsNavigationItems[0].check,
    EmailsNavigationItems[0].strong || false
  );
  const { canShow: canTemplates } = useCheckPermission(
    EmailsNavigationItems[1].check,
    EmailsNavigationItems[0].strong || false
  );

  const emailAccesiblePath = useMemo(() => {
    const emailCanShows = [canLetters, canTemplates];
    const firstAccesiblePathIndex = emailCanShows.indexOf(true);

    const firstAccesiblePath =
      EmailsNavigationItems[firstAccesiblePathIndex]?.path;

    return `/${client_id}${firstAccesiblePath}`;
  }, [canLetters, canTemplates, client_id]);

  const emailsPaths = useMemo(() => {
    return EmailsNavigationItems.map((item) => `/${client_id}${item.path}`);
  }, [client_id]);

  return {
    emailsPaths,
    emailAccesiblePath,
  };
}
