import styled from '@emotion/styled';
import { getColor, getSpace } from '../../../../../../../../utils';
import { EmotionButtonViewProps } from '../../../../../../types';

export const Container = styled.button<EmotionButtonViewProps>`
  display: flex;
  column-gap: 8px;
  align-items: center;
  justify-content: center;
  border: 1px solid ${getColor('main-deep-4')};
  padding: ${getSpace('6')} ${getSpace('24')};
  border-radius: 4px;
  background-color: ${getColor('main-deep-4')};
  cursor: pointer;
  text-align: center;
  transition: box-shadow 0.1s;

  ${({ background }) =>
    background &&
    `
    background-color: ${getColor(background)};
    border-color: ${getColor(background)};
  `}

  ${({ border }) =>
    border &&
    `
      border-color: ${getColor(border)};
  `}

  ${({ withChildren }) =>
    withChildren &&
    `
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
  `}


  ${({ disabled }) =>
    disabled &&
    `
      background-color: ${getColor('gray-deep-1')};
      border-color: ${getColor('gray-deep-1')};
      cursor: not-allowed;
  `}

  ${({ disabled }) =>
    !disabled &&
    `
      &:hover {
        box-shadow: 2px 2px 2px ${getColor('gray-deep-2')};
      }

      &:active {
        box-shadow: inset 2px 2px 2px rgb(41 80 187 / 20%);
      }
    `}
`;
