import gql from 'graphql-tag';
import { CASE_SECTION_EVALUATION_FRAGMENT } from './CaseSectionEvaluationScoring';
import { SCORING_AUDIT_LOG_FRAGMENT } from './ScoringAuditLog';

export const CASE_SCORING_FRAGMENT = gql`
  ${SCORING_AUDIT_LOG_FRAGMENT}
  ${CASE_SECTION_EVALUATION_FRAGMENT}
  fragment CaseScoringFragment on CaseScoring {
    audit_logs @type(name: "ScoringAuditLog") {
      ...ScoringAuditLogFragment
    }
    case_id
    case_section_evaluations @type(name: "CaseSectionEvaluationScoring") {
      ...CaseSectionEvaluationScoringFragment
    }
    coefficient
    created_at
    has_conflict
    id
    is_changed_manually
    updated_at
    value
  }
`;
