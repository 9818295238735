import gql from 'graphql-tag';

export const EDDReportsStats = gql`
  query EDDReportsStats(
    $case_ids: [String!]
    $assigned_to_id: String
    $form_configuration_id: String
    $status: String
    $main_profile_id: String
    $form_submission_id: String
    $from_created_at: String
    $to_created_at: String
    $completed_at_from: String
    $to_completed_at: String
    $profile_kinds: [String!]
    $page: Int
    $per: Int
    $q: String
  ) {
    eddReportsStats(
      case_ids: $case_ids
      assigned_to_id: $assigned_to_id
      form_configuration_id: $form_configuration_id
      status: $status
      main_profile_id: $main_profile_id
      form_submission_id: $form_submission_id
      from_created_at: $from_created_at
      to_created_at: $to_created_at
      completed_at_from: $completed_at_from
      to_completed_at: $to_completed_at
      profile_kinds: $profile_kinds
      page: $page
      per: $per
      q: $q
    )
      @rest(
        type: "ResponseEDDReportsStats"
        path: "/core/client/edd_reports/stats?{args}"
      ) {
      data {
        status @type(name: "EDDReportsStatsStatuses") {
          new
          completed
          all
        }
      }
      success
    }
  }
`;
