import { TmCaseStatusViewProps } from './types';
import { useTmCaseStatusController } from './controller';
import { Text } from '../Text';
import { Tag } from '../Count';
import { TM_CASE_STATUSES, TM_CASE_DISPLAY_STATUSES } from './constants';

function TmCaseStatus({ status }: TmCaseStatusViewProps) {
  const { background, text } = useTmCaseStatusController(status);

  if (text) {
    return <Tag count={text} padding="S" size={11} background={background} />;
  }

  return <Text text="-" size={12} color="main-deep-4" />;
}

export { TmCaseStatus, TM_CASE_STATUSES, TM_CASE_DISPLAY_STATUSES };
