import { Select } from '@aid-module/ui';
import { SubmissionStatusOptions } from './constants';
import {  SubmissionStatusSelectViewProps } from './types';

export function  SubmissionStatusSelect({
  label,
  placeholder,
  isClearable,
  value,
  onChange,
  isMulti,
}:  SubmissionStatusSelectViewProps) {
  return (
    <Select
      onChange={onChange}
      options={ SubmissionStatusOptions}
      label={label}
      placeholder={placeholder}
      value={value}
      isClearable={isClearable}
      isMulti={isMulti}
    />
  );
}
