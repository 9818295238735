import { createContext, PropsWithChildren, useContext } from 'react';
import { useSearchDataProviderController } from './controller';
import { SearchDataContextParams } from './types';

export const SearchDataContext = createContext<SearchDataContextParams>({
  loading: false,
  searchData: {} as any,
});

export function useSearchDataContext() {
  const context = useContext(SearchDataContext);

  return context;
}

export function SearchDataProvider({ children }: PropsWithChildren<{}>) {
  const context = useSearchDataProviderController();

  return (
    <SearchDataContext.Provider value={context}>
      {children}
    </SearchDataContext.Provider>
  );
}
