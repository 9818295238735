import { THEME_COLORS } from '../../../shared/colors/types';
import { CASE_SCREENING_STATUSES, CASE_STATUSES } from '../constants';

export function getColorByStatus(status?: string | null): keyof THEME_COLORS {
  switch (status) {
    case CASE_STATUSES.ACCEPTED:
    case CASE_STATUSES.AUTO_APPROVED:
    case CASE_SCREENING_STATUSES.COMPLETED:
    case CASE_SCREENING_STATUSES.CLEAN:
      return 'success-deep-4';
    case CASE_STATUSES.NO_RESPONSE:
    case CASE_SCREENING_STATUSES.IN_PROGRESS:
    case CASE_SCREENING_STATUSES.NO_INFO:
      return 'gray-deep-4';
    case CASE_STATUSES.READY_TO_WORK:
    case CASE_STATUSES.NEW:
    case CASE_STATUSES.REQUESTED_FOR_INFORMATION:
    case CASE_STATUSES.PRE_APPROVED_FOR_REVIEW:
    case CASE_SCREENING_STATUSES.WARNING:
      return 'warning-deep-4';
    case CASE_STATUSES.REJECTED:
    case CASE_STATUSES.ABANDONED:
    case CASE_STATUSES.CLOSED:
    case CASE_STATUSES.PRE_REJECTED:
    case CASE_SCREENING_STATUSES.FAILED:
      return 'error-deep-3';
    default:
      return 'main-deep-4';
  }
}
