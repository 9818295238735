import { PropsWithChildren } from 'react';
import {
  Container,
  DescriptionContainer,
  TitleContainer,
  FooterText,
} from './styles';
import { ErrorTemplateViewProps } from './types';
import { Text } from '../../../Text';
import { DesktopSvg, LogoSvg } from './components';
import { Icon } from '../../../Icon';
import { FlexContainer, GridContainer } from '../../../../containers';
import { useErrorTemplateController } from './controller';

export function ErrorTemplate({
  type,
  title,
  withoutLogo,
  icon,
  labelColor = 'main-deep-4',
  descriptionColor = 'main-deep-4',
  description,
  code,
  children,
  customIcon,
}: PropsWithChildren<ErrorTemplateViewProps>) {
  const { copyrightText } = useErrorTemplateController();
  return (
    <Container
      flex={1}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      className={`error ${type}`}
      code={code}
    >
      {type === 'global' && !withoutLogo && <LogoSvg />}
      <DescriptionContainer gap="40" flexDirection="column" alignItems="center">
        {customIcon}
        {icon && <Icon glyph={icon} size={200} />}
        {!icon && !customIcon && <DesktopSvg code={code} />}
        <GridContainer gap="40">
          <TitleContainer gap="12" flexDirection="column" alignItems="center">
            <GridContainer gridTemplateColumns="minmax(min-content, 800px)">
              <Text
                text={title}
                size={32}
                lineHeight="1.5"
                color={labelColor}
                uppercase
                weight="Bold"
                align="center"
              />
            </GridContainer>
            <Text
              text={description}
              size={20}
              lineHeight="1.3"
              uppercase
              weight="Medium"
              color={descriptionColor}
            />
          </TitleContainer>
          <FlexContainer justifyContent="center">{children}</FlexContainer>
        </GridContainer>
      </DescriptionContainer>
      {type === 'global' && (
        <FooterText size={12} color="main-deep-4" text={copyrightText} />
      )}
    </Container>
  );
}
