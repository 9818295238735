import { useApolloClientControllerContext } from '@aid-package/api';
import { Conteiner } from './styles';
import { AIDLoader } from '@aid-package/context';

export function Loader() {
  const { loading } = useApolloClientControllerContext();

  if (loading) {
    return (
      <Conteiner>
        <AIDLoader />
      </Conteiner>
    );
  }

  return null;
}
