import { createContext, useContext } from 'react';
import { PermissionsContextParams } from './types';

export const PermissionsContext = createContext<PermissionsContextParams>({});

export function usePermissionsContext() {
  const context = useContext(PermissionsContext);

  return context;
}
