import { gql } from '@apollo/client';

export const ChangeStatusPPScreening = gql`
  mutation ChangeStatusPPScreening(
    $id: String!
    $body: BodyChangeStatusPPScreening!
  ) {
    changeStatusPPScreening(id: $id, body: $body)
      @rest(
        type: "ChangeStatusPPScreening"
        path: "/pp/passive_profile_screenings/{args.id}/change_status"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "PPScreeningRecord") {
        id
        checkup_id
        checkup_provider_status
        checkup_status
        created_at
        display_name
        fincen_list_id
        fincen_list_kind
        kind
        previous_checkup_provider_status
        previous_checkup_status
        updated_at
        comment
        passive_profile_id
        passive_profile @type(name: "PassiveProfile") {
          id
          display_name
          passive_profile_external_refs @type(name: "PPExternalRef") {
            id
            created_at
            external_ref @type(name: "ExternalRef") {
              id
              external_entity_id
              external_entity_type
              external_system
            }
          }
        }
        results
      }
      success
    }
  }
`;
