import gql from 'graphql-tag';

export const FORM_TAG_FRAGMENT = gql`
  fragment FormTagFragment on FormTag {
    id
    kind
    name
    client_id
    created_at
    updated_at
  }
`;
