import { PropsWithChildren } from 'react';
import { PortalSideWidget } from './context';
import { GridContainer } from '../../../containers';
import { PortalSideWidgetProviderController } from './types';
import { usePortalSideWidgetProviderController } from './controller';

export function PortalSideWidgetProvider({
  children,
  containerName,
}: PropsWithChildren<PortalSideWidgetProviderController>) {
  const { value } = usePortalSideWidgetProviderController(containerName);

  return (
    <PortalSideWidget.Provider value={value}>
      {children}
      <GridContainer id={containerName} />
    </PortalSideWidget.Provider>
  );
}
