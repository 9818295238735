import { useMemo } from 'react';
import { useDictionaryMethodsContext } from '../../../../../context';

export function useClientMethodsSelectController() {
  const elements = useDictionaryMethodsContext();

  const options = useMemo(() => Object.values(elements), [elements]);

  return { options };
}
