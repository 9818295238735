import { createContext, PropsWithChildren, useContext } from 'react';
import { useColumnsContextController } from './controller';
import { ColumnsContextParams, ColumnsProvidersViewProps } from './types';

export const ColumnsContext = createContext<ColumnsContextParams>({
  columnsRenderKeys: undefined,
  onChangeColumns(next) {},
});

export function useColumnsContext() {
  const context = useContext(ColumnsContext);

  return context;
}

export function ColumnsProviders({
  children,
  columnsKey,
}: PropsWithChildren<ColumnsProvidersViewProps>) {
  const context = useColumnsContextController(columnsKey);

  return (
    <ColumnsContext.Provider value={context}>
      {children}
    </ColumnsContext.Provider>
  );
}
