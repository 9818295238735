import { GridContainer } from '@aid-module/ui';
import { Profile, Clients, NewClient, Fallback, ReLoginLink } from './components';
import { useOnBoardingContentController } from './contoller';
import { OnBoardingContext } from './context';

export function OnBoardingContent() {
  const { loading, ...context } = useOnBoardingContentController();

  if (loading) {
    return <Fallback />;
  }

  return (
    <GridContainer
      gridTemplateColumns="800px"
      justifyContent="center"
      alignItems="start"
      gridTemplateRows="repeat(4, max-content)"
    >
      <OnBoardingContext.Provider value={context}>
        <Profile />
        <Clients />
        <NewClient />
        <ReLoginLink />
      </OnBoardingContext.Provider>
    </GridContainer>
  );
}
