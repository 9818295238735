import { Cache } from '../Cache';
import { Observer } from './Observer';
import { SubscriberFunction } from './types';

export class Columns extends Observer {
  private _columns?: string[];
  private cache?: Cache;

  get columns() {
    return this._columns;
  }

  set columns(next: string[] | undefined) {
    this._columns = next;
    this.cache?.save(this._columns);
    this.onUpdateObserverValue('columns', this._columns);
  }

  constructor(columnsKey?: string) {
    super();
    if (columnsKey) {
      this.cache = Cache.init(columnsKey);
      this._columns = this.cache.columns;
    }
  }

  static init(columnsKey?: string) {
    return new Columns(columnsKey);
  }

  subscribe(key: 'columns', cb: SubscriberFunction<string[] | undefined>): void;
  subscribe(key: 'columns', cb: SubscriberFunction<any>): void {
    super.subscribe(key, cb);
  }

  onChangeColumns = (
    columns:
      | ((prev: string[] | undefined) => string[] | undefined)
      | string[]
      | undefined
  ) => {
    if (typeof columns === 'function') {
      this.columns = columns(this.columns);
    } else {
      this.columns = columns;
    }
  };
}
