import { useCustomerVerificationOnBoardingQuery } from '@aid-package/api';
import { useClientContext } from '@aid-package/context';
import { useMemo } from 'react';

export function useOnBoardingContentModel() {
  const { client } = useClientContext();

  const status = useMemo(
    () => client?.onboarding_verification_status || 'new',
    [client?.onboarding_verification_status]
  );

  const { data, loading } = useCustomerVerificationOnBoardingQuery({
    variables: { id: client?.onboarding_verification_id || '' },
    skip: status === 'completed',
  });

  const verificationData = useMemo(() => {
    const steps =
      data?.customerVerificationOnBoarding?.data.verification_step_passings ||
      [];
    const stepsCount = steps?.length || 0;
    return {
      stepsCount,
      startedVF: steps.some((x) => x?.status !== 'new'),
      completedStepCount:
        steps.filter((x) => x?.status === 'completed').length || 0,
    };
  }, [data?.customerVerificationOnBoarding?.data.verification_step_passings]);

  const stepStatus = useMemo(() => {
    if (client?.onboarding_verification_status === 'new') {
      if (!verificationData?.startedVF) {
        return 'new';
      }

      if (verificationData?.startedVF) {
        if (
          verificationData?.stepsCount > verificationData?.completedStepCount
        ) {
          return 'started';
        }
      }
    }

    if (client?.onboarding_verification_status === 'started') {
      if (verificationData?.startedVF) {
        if (
          verificationData?.stepsCount > verificationData?.completedStepCount
        ) {
          return 'started';
        }
        if (
          verificationData?.stepsCount === verificationData?.completedStepCount
        ) {
          return 'on_review';
        }
      }
    }

    if (
      client?.onboarding_verification_status === 'completed' &&
      verificationData?.stepsCount === verificationData?.completedStepCount
    ) {
      return 'completed';
    }
    if (client?.onboarding_verification_status === 'rejected') {
      return 'rejected';
    }
    return 'new';
  }, [
    client,
    verificationData?.startedVF,
    verificationData?.stepsCount,
    verificationData?.completedStepCount,
  ]);

  return {
    customerVerification: data?.customerVerificationOnBoarding?.data,
    loading,
    stepStatus: stepStatus || status,
  };
}
