import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useFilterTemplatesContext } from '../../../../../../../../../../../../../../../../../../context';
import { parseFilters } from './utils';

export function useBodyController() {
  const { getValues } = useFormContext();
  const { data } = useFilterTemplatesContext();

  const canUpdate = useMemo(
    () => !!(data?.favorites?.length || data?.templates?.length),
    [data]
  );

  return {
    formValues: parseFilters(getValues()),
    rawFilterValues: getValues(),
    canUpdate,
  };
}
