import gql from 'graphql-tag';

export const PotentialConnections = gql`
  query PotentialConnections(
    $case_id: String
    $search_term: String
    $first_name: String
    $last_name: String
    $business_name: String
    $page: Int
    $q: String
  ) {
    potentialConnections(
      case_id: $case_id
      search_term: $search_term
      first_name: $first_name
      last_name: $last_name
      business_name: $business_name
      page: $page
      per: 20
      q: $q
    )
      @rest(
        type: "ResponsePotentialConnections"
        path: "/pp/passive_profiles/potential_connections?{args}"
      ) {
      data @type(name: "PotentialConnection") {
        id
        created_at
        updated_at
        display_name
        source_type
        source_id
        passive_profile_screenings_count
        passive_profile_fields_count
        fields
        external_refs_counters @type(name: "ExternalRefsCounters") {
          Case
          Checkup
          Customer
          Profile
          Event
          FormSubmission
        }
        passive_profile_external_refs @type(name: "PassiveProfileExternalRef") {
          id
          created_at
          updated_at
          external_ref_id
          external_ref @type(name: "ExternalRef") {
            id
            external_entity_id
            external_system
            external_entity_type
            external_entity_name
          }
        }
      }
      success
    }
  }
`;

export const PpExternalRefs = gql`
  query PpExternalRefs(
    $external_entity_type: String
    $external_entity_id: String
    $passive_profile_id: String
  ) {
    ppExternalRefs(
      external_entity_type: $external_entity_type
      external_entity_id: $external_entity_id
      passive_profile_id: $passive_profile_id
    ) @rest(type: "ResponsePpExternalRefs", path: "/pp/external_refs?{args}") {
      data @type(name: "ExternalRef") {
        id
        external_entity_id
        external_system
        external_entity_type
        external_entity_name
      }
      success
    }
  }
`;
