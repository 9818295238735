import gql from 'graphql-tag';
import { TRANSACTION_ACCOUNT_FRAGMENT } from './TransactionAccount';
import { TRANSACTION_DATA_FRAGMENT } from './TransactionData';

export const TRANSACTION_CASE_FRAGMENT = gql`
  ${TRANSACTION_DATA_FRAGMENT}
  ${TRANSACTION_ACCOUNT_FRAGMENT}
  fragment TransactionCaseFragment on TransactionCase {
    assigned_toes @type(name: "TransactionAccount") {
      ...TransactionAccountFragment
    }
    activity_types @type(name: "TransactionActivityType") {
      id
      name
    }
    case_origins @type(name: "TransactionCaseOrigin") {
      id
      name
    }
    resolutions @type(name: "TransactionResolution") {
      id
      name
      color
    }
    closed_at
    created_at
    created_by @type(name: "TransactionAccount") {
      ...TransactionAccountFragment
    }
    deadline_at
    description
    id
    report_case_url
    state
    case_no
    support_key
    is_for_test
    total_flagged_amount
    tags @type(name: "TransactionTag") {
      created_at
      description
      id
      name
      updated_at
    }
    title
    unit21_case_external_id
    unit21_external_id
  }
`;
