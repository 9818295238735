import { GridContainer, Text, PrimaryButton } from '@aid-module/ui';
import { TYPES } from '@aid-package/api';
import { useClientController } from './controller';
import { ClientCard, ClientContentCard, ClientButton, StatusContainer } from './styles';

export function Client(
  client: TYPES.DraftClientsQuery['draftClients']['data'][0]
) {
  const {
    clientName,
    infoText,
    redirectToFlow,
    redirectToApp,
    expiresDate,
    isNew,
    isStarted,
    isAccepted,
  } = useClientController(client);

  return (
    <ClientCard padding="S" background="main-deep-1">
      <GridContainer
        gridTemplateRows="repeat(2, max-content)"
        gap="12"
      >
        <GridContainer
          gap="4"
          gridTemplateRows="repeat(2, max-content)"
        >
          <GridContainer
            gridTemplateColumns="repeat(2, max-content)"
            alignItems="center"
            gap="12"
          >
            <Text
              text={clientName}
              size={16}
              color="main-deep-4"
              weight="VeryBig"
            />
            {isStarted && (
              <StatusContainer>
                <Text
                  text="Processing"
                  color="white-primary"
                  size={11}
                  weight="Medium"
                />
              </StatusContainer>
            )}
          </GridContainer>
          <Text
            text={expiresDate}
            size={12}
            color="gray-deep-4"
            weight="Medium"
          />
        </GridContainer>
        <ClientContentCard
          border={isStarted ? 'warning-deep-2' : 'main-deep-2'}
          background={isStarted ? 'warning-deep-1' : 'main-deep-2'}
        >
          <GridContainer
            gridTemplateColumns="repeat(2, max-content)"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text
              text={infoText}
              size={14}
              color="main-deep-5"
              weight="Medium"
            />
            {isStarted && (
              <ClientButton
                text="Continue"
                onClick={redirectToFlow}
              />
            )}
            {isNew && (
              <PrimaryButton text="Start" onClick={redirectToFlow} />
            )}
            {isAccepted && (
              <PrimaryButton text="Go to application" onClick={redirectToApp} />
            )}
          </GridContainer>
        </ClientContentCard>
      </GridContainer>
    </ClientCard>
  );
}
