import { Tab } from '../../controller/types';
import {
  SubmissionsStatisticsRegister,
  SubmissionsStatisticsTemplatesRegister,
} from './components';

import { ByTypeViewProps } from './type';

export function ByTab({ tab }: ByTypeViewProps) {
  if (tab === Tab.STATISTICS) {
    return <SubmissionsStatisticsRegister />;
  }
  return <SubmissionsStatisticsTemplatesRegister />;
}
