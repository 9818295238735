import { Text, Empty } from '@aid-module/ui';
import { FillFormViewProps } from './types';
import { HeadlineContainer, Container } from './styles';

export function FillFormContent({ isCodePicked }: FillFormViewProps) {

  if (isCodePicked) {
    return (
      <HeadlineContainer>
        <Text
          text="FILL THE FORM"
          size={14}
          color="main-deep-4"
          weight="VeryBig"
        />
      </HeadlineContainer>
    );
  }

  return (
    <>
      <HeadlineContainer>
        <Text
          text="FILL THE FORM"
          size={14}
          color="main-deep-4"
          weight="VeryBig"
        />
      </HeadlineContainer>
      <Container>
        <Empty
          title="NO FORM YET"
          subtitle="Please choose the screening type to display the form."
        />
      </Container>
    </>
  );
}
