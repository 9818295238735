import { createContext, PropsWithChildren, useContext } from 'react';
import { useFilterTemplatesProviderController } from './controller';
import {
  FilterTemplatesContextParams,
  FilterTemplatesProviderViewProps,
} from './types';

export const FilterTemplatesContext =
  createContext<FilterTemplatesContextParams>({
    code: undefined,
  });

export function useFilterTemplatesContext() {
  const context = useContext(FilterTemplatesContext);

  return context;
}

export function FilterTemplatesProvider({
  code,
  children,
}: PropsWithChildren<FilterTemplatesProviderViewProps>) {
  const context = useFilterTemplatesProviderController(code);

  return (
    <FilterTemplatesContext.Provider value={context}>
      {children}
    </FilterTemplatesContext.Provider>
  );
}
