import { PropsWithChildren } from 'react';
import { SideWidget } from './context';
import { useCommentProviderController } from './controller';
import { AnimatePresence } from 'framer-motion';
import { PositionContainer, View, Header, ActionContainer } from './components';
import { SideWidgetState } from './controller/types';

function renderComponent(options: SideWidgetState, i: number) {
  return (
    <View
      state={i === 0 ? 'visible' : 'stack'}
      index={i}
      position={options.position}
      key={options.key}
    >
      {options.children}
    </View>
  );
}

function SideWidgenProvider({ children }: PropsWithChildren) {
  const { value, components } = useCommentProviderController();

  return (
    <SideWidget.Provider value={value.current}>
      {children}
      <PositionContainer>
        <AnimatePresence>
          {components.current && components.current.map(renderComponent)}
        </AnimatePresence>
      </PositionContainer>
    </SideWidget.Provider>
  );
}

SideWidgenProvider.Header = Header;
SideWidgenProvider.ActionContainer = ActionContainer;

export { SideWidgenProvider };
