import { PrimaryButton } from '@aid-module/ui';
import { useActionsController } from './controller';

export function Actions() {
  const { onClickSubmit, disabled } = useActionsController();

  return (
    <PrimaryButton text="Confirm" disabled={disabled} onClick={onClickSubmit} />
  );
}
