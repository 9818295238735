import gql from 'graphql-tag';

export const FORM_AUDIT_FRAGMENT = gql`
  fragment FormAuditFragment on FormAudit {
    comment
    created_at
    id
    status
    updated_at
  }
`;
