import { SecondaryButton } from '@aid-module/ui';
import { useAddCommentController } from './controller';

export function AddComment() {
  const { onClick, disabled } = useAddCommentController();

  return (
    <SecondaryButton
      disabled={disabled}
      onClick={onClick}
      text="Add comment"
      icon="comments"
    />
  );
}
