import gql from 'graphql-tag';
import {
  ROLE_FRAGMENT,
  ROLE_PERMISSIONS_FRAGMENT,
} from '../../../fragment/account/roles';

export const Roles = gql`
  ${ROLE_FRAGMENT}
  query Roles(
    $page: Int
    $q: String
    $sort_field: String
    $sort_order: String
  ) {
    roles(
      page: $page
      per: 20
      q: $q
      sort_field: $sort_field
      sort_order: $sort_order
    ) @rest(type: "ResponseRoles", path: "/core/client/roles?{args}") {
      data @type(name: "Role") {
        ...RoleFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const Role = gql`
  ${ROLE_PERMISSIONS_FRAGMENT}
  query Role($id: String!) {
    role(id: $id)
      @rest(type: "ResponseRole", path: "/core/client/roles/{args.id}") {
      data @type(name: "Role") {
        ...RolePermissionsFragment
      }
      success
    }
  }
`;
