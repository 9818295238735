import gql from 'graphql-tag';
import { FORM_TAG_FRAGMENT } from '../../../fragment/account/formTags';

export const FormTags = gql`
  ${FORM_TAG_FRAGMENT}
  query FormTags($page: Int, $kind: String, $q: String) {
    formTags(page: $page, per: 20, kind: $kind, q: $q)
      @rest(type: "ResponseFormTags", path: "/core/client/form_tags?{args}") {
      data @type(name: "FormTag") {
        ...FormTagFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const FormTag = gql`
  ${FORM_TAG_FRAGMENT}
  query FormTag($id: String!) {
    formTag(id: $id)
      @rest(type: "ResponseFormTag", path: "/core/client/form_tags/{args.id}") {
      data @type(name: "FormTag") {
        ...FormTagFragment
      }
      success
    }
  }
`;
