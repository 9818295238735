import { createContext, useContext } from 'react';
import { ClientContextParams } from './types';

export const ClientContext = createContext<ClientContextParams>({
  client: null,
});

export function useClientContext() {
  const context = useContext(ClientContext);

  return context;
}
