import { Icon } from '../../../../../../../../../../../../Icon';
import { GridContainer } from '../../../../../../../../../../../../../containers';
import { useEditFilterTemplateController } from './controller';
import { Text } from '../../../../../../../../../../../../Text';

export function EditFilterTemplate() {
  const { onClickClose } = useEditFilterTemplateController();

  return (
    <GridContainer gridTemplateColumns="1fr max-content" gap="32">
      <Text
        text="EDIT Filter Template"
        color="main-deep-4"
        weight="VeryBig"
        uppercase
      />
      <Icon glyph="cross" color="main-deep-4" onClick={onClickClose} />
    </GridContainer>
  );
}
