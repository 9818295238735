import { DefaultButtonLoader } from '@aid-module/ui';
import { ArrayButtons } from './components';
import { ActionFallbackViewProps } from './types';

export function ActionFallback({ count }: ActionFallbackViewProps) {
  if (count === 1) {
    return <DefaultButtonLoader />;
  }

  return <ArrayButtons count={count} />;
}
