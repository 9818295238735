import gql from 'graphql-tag';
import { CASE_REJECT_REASON_FRAGMENT } from '../../../fragment/account/caseRejectReasons';

export const CaseRejectReasons = gql`
  ${CASE_REJECT_REASON_FRAGMENT}
  query CaseRejectReasons($page: Int, $q: String) {
    caseRejectReasons(page: $page, per: 20, q: $q)
      @rest(
        type: "ResponseCaseRejectReasons"
        path: "/core/client/case_rejection_reasons?{args}"
      ) {
      data @type(name: "CaseRejectReason") {
        ...CaseRejectReasonFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const CaseRejectReason = gql`
  ${CASE_REJECT_REASON_FRAGMENT}
  query CaseRejectReason($id: String!) {
    caseRejectReason(id: $id)
      @rest(
        type: "ResponseCaseRejectReason"
        path: "/core/client/case_rejection_reasons?{args.id}"
      ) {
      data @type(name: "CaseRejectReason") {
        ...CaseRejectReasonFragment
      }
      success
    }
  }
`;
