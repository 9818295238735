import { Svg } from './components';
import { useClientAvatarController } from './controller';
import { ClientAvatarComponentViewProps } from './types';
import { StyledImage } from './styles';
import { ImageLoader } from '../../../../../../shared';

export function ClientAvatar({ client }: ClientAvatarComponentViewProps) {
  const { src, options } = useClientAvatarController(client);

  if (src) {
    return (
      <StyledImage
        src={src}
        alt={client?.name || src}
        fallback={
          <ImageLoader
            height={options.size}
            width={options.size}
            radius={options.border}
          />
        }
      >
        <Svg client={client} />
      </StyledImage>
    );
  }

  return <Svg client={client} />;
}
