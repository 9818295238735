import { gql } from '@apollo/client';

export const CreateExportRequest = gql`
  mutation CreateExportRequest($body: ExportRequestBody!) {
    createExportRequest(body: $body)
      @rest(
        type: "CreateExportRequest"
        path: "/pressman/export_requests/create_and_execute"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "ExportRequest") {
        id
        created_at
        data
        end_at
        execution_errors
        execution_status
        export_template @type(name: "ExportTemplate") {
          code
          data_builder_klass
          executor_klass
          filter_contract_klass
          id
          name
        }
        filters
        start_at
        uploaded_document_id
      }
      success
    }
  }
`;

export const SignDownloadExportRequest = gql`
  mutation SignDownloadExportRequest($id: String! $body: Any) {
    signDownloadExportRequest(id: $id, body: $body)
      @rest(
        type: "CreateExportRequest"
        path: "/pressman/export_requests/{args.id}/set_download_at"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "ExportRequest") {
        id
        created_at
        data
        end_at
        execution_errors
        execution_status
        export_template @type(name: "ExportTemplate") {
          code
          data_builder_klass
          executor_klass
          filter_contract_klass
          id
          name
        }
        filters
        start_at
        uploaded_document_id
      }
      success
    }
  }
`;

