import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useWatch, useFormContext } from 'react-hook-form';
import { useStructureModel } from '../model';

export function useStructureController() {
  const ref = useRef<HTMLDivElement>(null);
  const selectedForm = useWatch({ name: 'reference_key' });
  const referenceKeys: string[] = useWatch({
    name: 'form_field_reference_keys',
  });
  const { setValue } = useFormContext();

  useEffect(() => {
    if (!selectedForm?.default) {
      setValue('form_field_reference_keys', []);
      ref.current?.scrollTo({ top: 0 });
    }
  }, [selectedForm]);

  const selected = useMemo(
    () =>
      referenceKeys?.reduce<Record<string, boolean>>(
        (acc, key) => ({ ...acc, [key]: true }),
        {}
      ),
    [referenceKeys]
  );

  const onSelect = useCallback(
    (id: string) => {
      if (selected[id]) {
        delete selected[id];
        return setValue('form_field_reference_keys', Object.keys(selected));
      }
      const next = { ...selected, [id]: true };
      return setValue('form_field_reference_keys', Object.keys(next));
    },
    [setValue, selected]
  );

  const id = useMemo(() => selectedForm?.id, [selectedForm?.id]);

  const { form, loading } = useStructureModel(id);

  return {
    isEmpty: !form && !loading,
    form,
    loading,
    ref,
    context: { selected, onSelect },
  };
}
