import { Link, useParams } from 'react-router-dom';

export function Logo() {
  const { client_id } = useParams();
  return (
    <Link to={`/${client_id}/profile`}>
      <svg
        width="80"
        height="33"
        viewBox="0 0 80 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M40.2152 26.2014L29.8197 8.32566C27.0705 3.92025 22.0876 0.955078 16.4173 0.955078C7.65423 0.955078 0.609375 7.90206 0.609375 16.4587C0.609375 25.0154 7.65423 32.0471 16.4173 32.0471C16.4173 32.0471 17.9638 32.0471 18.3933 32.0471C20.713 32.0471 22.6031 30.2679 22.6031 28.15C22.6031 26.032 20.7989 24.2529 18.6511 24.2529C18.3074 24.2529 16.4173 24.2529 16.4173 24.2529C12.0358 24.2529 8.51336 20.7794 8.51336 16.4587C8.51336 12.138 12.0358 8.66453 16.4173 8.66453C19.1666 8.66453 21.5721 10.02 22.9467 12.138L23.0326 12.2227L33.4281 30.0985C34.545 31.9623 36.9505 32.5554 38.8406 31.5387C40.7307 30.4374 41.3321 28.0652 40.2152 26.2014Z"
          fill="#1F3B8E"
        />
        <path
          d="M52.4153 31.5374C50.5252 32.6388 48.1196 31.961 47.0028 30.0972L33.4285 6.7994C32.3117 4.93558 32.999 2.56344 34.8891 1.46209C36.7791 0.360738 39.1847 1.03849 40.3016 2.90232L53.9617 26.2001C54.9927 28.0639 54.3054 30.4361 52.4153 31.5374Z"
          fill="url(#paint0_linear_3686_41311)"
        />
        <path
          d="M64.3562 0.954102H50.5243C48.3764 0.954102 46.5723 2.73321 46.5723 4.85119C46.5723 6.96917 48.3764 8.74828 50.5243 8.74828H64.1844C68.3082 9.00243 71.5729 12.3912 71.5729 16.5425C71.5729 20.5243 68.7378 23.7436 64.614 24.2519C64.614 24.2519 64.3562 24.2519 64.0985 24.3366C61.9507 24.4213 60.1465 26.1157 60.1465 28.2337C60.1465 30.3517 61.9507 32.1308 64.0985 32.1308C64.3562 32.1308 64.528 32.1308 64.528 32.1308C72.7757 31.6225 79.391 24.9297 79.391 16.6272C79.391 8.15524 72.6898 1.3777 64.3562 0.954102Z"
          fill="#1F3B8E"
        />
        <defs>
          <linearGradient
            id="paint0_linear_3686_41311"
            x1="43.6656"
            y1="0.931641"
            x2="43.6656"
            y2="32.0679"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1F3B8E" />
            <stop offset="1" stopColor="#8BDEEF" />
          </linearGradient>
        </defs>
      </svg>
    </Link>
  );
}
