import { Text } from '../../../../../../../Text';
import { GridContainer } from '../../../../../../../../containers';
import { PropsWithChildren } from 'react';
import { ModalProps } from '../../../../../../ModalController/types';
import { GridContainerEmotionProps } from '../../../../../../../../containers/GridContainer/types';
import { Container as UIContainer } from './styles';

export function Container({
  children,
  title,
  body,
  ...props
}: PropsWithChildren<
  GridContainerEmotionProps & Pick<ModalProps, 'title' | 'body'>
>) {
  return (
    <UIContainer {...props}>
      <GridContainer gap="24">
        {title && (
          <Text
            text={title}
            weight="Giant"
            size={28}
            lineHeight="130%"
            color="main-deep-4"
            align="center"
          />
        )}
        {body && (
          <Text
            size={16}
            weight="VeryBig"
            lineHeight="146%"
            text={body}
            align="center"
          />
        )}
      </GridContainer>
      {children}
    </UIContainer>
  );
}
