import { gql } from '@apollo/client';

export const TransactionRuleSets = gql`
  query TransactionRuleSets {
    transactionRuleSets
      @rest(type: "ResponseTransactionRuleSets", path: "/tm/rulesets") {
      data @type(name: "RuleSet") {
        can_sync
        client_id
        created_at
        deleted
        enabled
        id
        name
        alert_deadline_period
        rules_expression
      }
      success
    }
  }
`;

export const TransactionRuleSet = gql`
  query TransactionRuleSet($id: String!) {
    transactionRuleSet(id: $id)
      @rest(
        type: "ResponseTransactionRuleSet"
        path: "/tm/rulesets/{args.id}"
      ) {
      data @type(name: "RuleSet") {
        can_sync
        client_id
        created_at
        deleted
        enabled
        id
        name
        alert_deadline_period
        rules @type(name: "Rule") {
          async
          code
          created_at
          deleted
          description
          enabled
          rule_importance @type(name: "TransactionRuleImportance") {
            id
            name
            client_id
            code
            created_at
          }
          id
          name
          provider_id
          is_for_test
          provider @type(name: "RuleProvider") {
            client_id
            created_at
            enabled
            external
            id
            kind
          }
          ruleset_id
          settings
        }
        rules_expression
        rules_expression_is_valid {
          errors
          success
        }
      }
      success
    }
  }
`;

export const TransactionRuleImportance = gql`
  query TransactionRuleImportance {
    transactionRuleImportance
      @rest(
        type: "ResponseTransactionImportance"
        path: "/tm/rule_importance"
      ) {
      data @type(name: "TransactionRuleImportance") {
        id
        name
        client_id
        created_at
        code
      }
      success
    }
  }
`;
