import { MouseEvent, useEffect, useRef } from 'react';

export function usePositionContainerController() {
  const ref = useRef<HTMLDivElement>(null);
  const currentTranslate = useRef({ x: 40, y: 70 });
  const canMove = useRef(false);
  const startMoveOptions = useRef<{ x: number; y: number } | undefined>(
    undefined
  );
  const dragRef = useRef<HTMLDivElement | undefined>(undefined);

  const startMove = useRef((e: MouseEvent<HTMLDivElement>) => {
    if (!e.button && e.target instanceof HTMLDivElement) {
      canMove.current = true;
      dragRef.current = e.target;
      startMoveOptions.current = { x: e.clientX, y: e.clientY };
      dragRef.current.style.cursor = 'grabbing';
      e.stopPropagation();
      e.preventDefault();
    }
  });

  useEffect(() => {
    const onMouseMove = (e: Event) => {
      if (canMove.current && startMoveOptions.current && ref.current) {
        const currentX = (e as any).clientX;
        const currentY = (e as any).clientY;
        const x = currentX - startMoveOptions.current.x;
        const y = currentY - startMoveOptions.current.y;
        startMoveOptions.current = { x: currentX, y: currentY };
        const nextX = currentTranslate.current.x - x;
        const nextY = currentTranslate.current.y + y;
        const firstChild = ref.current.children[0];
        const maxBottom = dragRef.current?.clientHeight || 250;
        if (x < 0 && window.innerWidth - nextX <= firstChild.clientWidth / 2) {
          return;
        } else if (x > 0 && nextX <= 0) {
          return;
        } else if (y > 0 && window.innerHeight - nextY <= maxBottom) {
          return;
        } else if (y < 0 && nextY <= 0) {
          return;
        }
        ref.current.style.right = `${nextX}px`;
        ref.current.style.top = `${nextY}px`;
        currentTranslate.current = { x: nextX, y: nextY };
        e.stopPropagation();
        e.preventDefault();
      }
    };

    const onMouseUp = () => {
      canMove.current = false;
      startMoveOptions.current = undefined;
      if (dragRef.current) {
        dragRef.current.style.cursor = 'grab';
        dragRef.current = undefined;
      }
    };
    window.addEventListener('mousemove', onMouseMove, true);
    window.addEventListener('mouseup', onMouseUp, true);
    return () => {
      window.removeEventListener('mousemove', onMouseMove, true);
      window.removeEventListener('mouseup', onMouseUp, true);
    };
  }, []);

  return {
    ref,
    startMove: startMove.current,
  };
}
