import { DefaultButtonLoader, GridContainer } from '@aid-module/ui';
import { Fallback as UIFallback } from '../../../components';

export function Fallback() {
  return (
    <UIFallback>
      <GridContainer gap="20" gridTemplateColumns="repeat(3, 1fr)">
        <DefaultButtonLoader />
        <DefaultButtonLoader />
        <DefaultButtonLoader />
      </GridContainer>
    </UIFallback>
  );
}
