import gql from 'graphql-tag';
import { ACCOUNT_FRAGMENT } from '../../fragment/admin/accounts';
import { CUSTOMER_FRAGMENT } from '../../fragment/cm';
import { SUBMISSION_FRAGMENT } from '../../fragment/submission';
import { FORM_CONFIGURATION_FRAGMENT } from '../../fragment/submission/FormConfiguration';

export const Submissions = gql`
  ${SUBMISSION_FRAGMENT}
  query Submissions(
    $profile_id: String
    $sort_field: String
    $sort_order: String
    $audit_status: AuditStatus
    $created_after: String
    $created_before: String
    $linked_with_submission_id: String
    $doubles_for_device_info_id: String
    $case_id: String
    $status: SubmissionStatus
    $form_ref: String
    $form_id: String
    $is_abandoned: String
    $retention_plan_id: String
    $page: Int
    $per: Int = 20
    $q: String
  ) {
    submissions(
      profile_id: $profile_id
      sort_field: $sort_field
      sort_order: $sort_order
      case_id: $case_id
      linked_with_submission_id: $linked_with_submission_id
      doubles_for_device_info_id: $doubles_for_device_info_id
      audit_status: $audit_status
      created_after: $created_after
      created_before: $created_before
      status: $status
      form_ref: $form_ref
      form_id: $form_id
      is_abandoned: $is_abandoned
      retention_plan_id: $retention_plan_id
      page: $page
      per: $per
      q: $q
    )
      @rest(
        type: "ResponseSubmissions"
        path: "/core/client/submissions?{args}"
      ) {
      data @type(name: "Submission") {
        ...SubmissionFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const Revisions = gql`
  ${ACCOUNT_FRAGMENT}
  ${CUSTOMER_FRAGMENT}
  ${FORM_CONFIGURATION_FRAGMENT}
  query Revisions($id: String!, $page: Int) {
    revisions(id: $id, page: $page, per: 20)
      @rest(
        type: "ResponseRevisions"
        path: "/core/client/submissions/{args.id}/revisions?page={args.page}&per={args.per}"
      ) {
      data @type(name: "Submission") {
        audit_status
        created_at
        account @type(name: "Account") {
          ...AccountFragment
        }
        customer @type(name: "Customer") {
          ...CustomerFragment
        }
        form_configuration @type(name: "FormConfiguration") {
          ...FormConfigurationFragment
        }
        form_invitation_id
        id
        is_actual_revision
        profile_display_name
        reference_key
        revision_number
        status
        ubt_submission_ref
        updated_at
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const Submission = gql`
  ${SUBMISSION_FRAGMENT}
  query Submission($id: String!) {
    submission(id: $id)
      @rest(
        type: "ResponseSubmission"
        path: "/core/client/submissions/{args.id}"
      ) {
      data @type(name: "Submission") {
        ...SubmissionFragment
        field_submissions @type(name: "FieldSubmission") {
          business_passport_id
          created_at
          file_name
          file_size
          field_update_claims_count
          form_field @type(name: "FormField") {
            created_at
            description
            field_template @type(name: "FormFieldTemplate") {
              code
              common
              created_at
              defaults
              field_select_values
              id
              kind
              name
              options
              updated_at
            }
            form_group @type(name: "FormGroup") {
              created_at
              form_screen_id
              id
              name
              options
              position
              repeatable
              tags
              updated_at
            }
            field_template_id
            id
            name
            options
            position
            protected_tags
            reference_key
            tags
            updated_at
          }
          form_group_index
          form_group_subindex
          form_invitation_id
          id
          personal_passport_id
          updated_at
          uploaded_document_id
          value
        }
      }
      success
    }
  }
`;
export const SubmissionUrl = gql`
  query SubmissionUrl($id: String!) {
    submissionUrl(id: $id)
      @rest(
        type: "ResponseSubmissionUrl"
        path: "/core/client/submissions/{args.id}/url"
      ) {
      data @type(name: "SubmissionUrl") {
        url
      }
      success
    }
  }
`;

export const SubmissionReport = gql`
  query SubmissionReport($id: String!) {
    submissionReport(id: $id)
      @rest(
        type: "ResponseReport"
        path: "/core/client/submissions/{args.id}/report"
      )
  }
`;

export const SubmissionsReport = gql`
  query SubmissionsReport(
    $profile_id: String
    $audit_status: AuditStatus
    $created_after: String
    $created_before: String
    $linked_with_submission_id: String
    $doubles_for_device_info_id: String
    $case_id: String
    $status: SubmissionStatus
    $form_ref: String
    $is_abandoned: String
  ) {
    submissionsReport(
      profile_id: $profile_id
      case_id: $case_id
      linked_with_submission_id: $linked_with_submission_id
      doubles_for_device_info_id: $doubles_for_device_info_id
      audit_status: $audit_status
      created_after: $created_after
      created_before: $created_before
      status: $status
      form_ref: $form_ref
      is_abandoned: $is_abandoned
    )
      @rest(
        type: "ResponseReport"
        path: "/core/client/submissions/to_csv?{args}"
      )
  }
`;
