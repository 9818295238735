import { InitiateElements } from './types';

export class ElementsController {
  public onUpdateOptions?: (
    initiateOptions: DOMRect,
    containerOptions: DOMRect
  ) => void;

  refs: InitiateElements;

  public get initiateOptions() {
    const el = this.refs.checkedRef || this.refs.initiateRef;
    return el.getBoundingClientRect();
  }

  public get containerOptions() {
    return this.refs.containerRef.getBoundingClientRect();
  }

  constructor(refs: InitiateElements) {
    this.refs = refs;
    this.initEventsListeners();
  }

  private onResizeWindow = () => {
    this.onUpdateOptions?.(this.initiateOptions, this.containerOptions);
  };

  private onScroll = () => {
    this.onUpdateOptions?.(this.initiateOptions, this.containerOptions);
  };

  private initEventsListeners() {
    document.addEventListener('resize', this.onResizeWindow, true);
    document.addEventListener('scroll', this.onScroll, true);
  }

  public destroy() {
    document.removeEventListener('resize', this.onResizeWindow, true);
    document.removeEventListener('scroll', this.onScroll, true);
    this.onUpdateOptions = undefined;
  }
}
