import { ContentLoader, GridContainer } from '@aid-module/ui';

export function Fallback() {
  return (
    <GridContainer gap="20">
      <ContentLoader width="100%" height="26px">
        <rect rx="4" ry="4" width="100%" height="26px" />
      </ContentLoader>
      <GridContainer gap="60">
        <ContentLoader width="100%" height="418px">
          <rect rx="4" ry="4" width="100%" height="418px" />
        </ContentLoader>
        <GridContainer
          gap="60"
          gridTemplateColumns="1fr 1fr"
          gridTemplateRows="max-content"
        >
          <GridContainer gap="32" gridTemplateRows="32.2px 302px">
            <ContentLoader width="100%" height="26px">
              <rect rx="4" ry="4" width="100%" height="26px" />
            </ContentLoader>
            <ContentLoader width="100%" height="313px">
              <rect rx="4" ry="4" width="100%" height="313px" />
            </ContentLoader>
          </GridContainer>
          <GridContainer gap="32" gridTemplateRows="32.2px 344.2px">
            <ContentLoader width="100%" height="26px">
              <rect rx="4" ry="4" width="100%" height="26px" />
            </ContentLoader>
            <ContentLoader width="100%" height="344.2px">
              <rect rx="4" ry="4" width="100%" height="344.2px" />
            </ContentLoader>
          </GridContainer>
        </GridContainer>
      </GridContainer>
    </GridContainer>
  );
}
