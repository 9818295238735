import { Link } from '../Link';
import { MultiplePathsLinkContainer } from '../MultiplePathsLinkContainer';
import { useTransactionAlertsLinkController } from './controller';

export function TransactionAlertsLink() {
  const { transactionAlertsPaths, alertsAccesiblePath } =
    useTransactionAlertsLinkController();

  return (
    <MultiplePathsLinkContainer paths={transactionAlertsPaths}>
      <Link to={alertsAccesiblePath} text="Alerts" />
    </MultiplePathsLinkContainer>
  );
}
