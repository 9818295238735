import { useMemo } from 'react';
import { DataRetentionPlanStatusViewProps } from '../types';
import { getColorByStatus } from './utils';
import { DATA_RETENTION_PLAN_DISPLAY_STATUSES, DATA_RETENTION_PLAN_STATUSES } from '../constants';

export function useIdentityVerificationStatusController(
  status: DataRetentionPlanStatusViewProps['status']
) {
  const background = useMemo(() => getColorByStatus(status), []);

  const text = useMemo(() => {
    if (status && status === DATA_RETENTION_PLAN_STATUSES.READY_TO_DELETE) {
      return 'Ready to delete';
    }

    if (status) {
      return DATA_RETENTION_PLAN_DISPLAY_STATUSES[status];
    }

    return '';
  }, [status]);

  return {
    background,
    text,
  };
}
