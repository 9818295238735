import {
  Card as UICard,
  getSpace,
  THEME_COLORS,
  getColor,
} from '@aid-module/ui';
import styled from '@emotion/styled';

export const Card = styled(UICard)`
  padding: ${getSpace('8')} ${getSpace('12')};
`;

export const Button = styled.div<{ color: keyof THEME_COLORS }>`
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ color }) => getColor(color)};
`;
