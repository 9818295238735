import { useExportTemplatesQuery, TYPES } from '@aid-package/api';
import { useMemo } from 'react';

export function usePDFExportContextProviderModel() {
  const { data, loading, error, client } = useExportTemplatesQuery();

  const templates = useMemo(() => {
    if (data?.exportTemplates?.data) {
      return data.exportTemplates.data.reduce<
        Record<string, TYPES.ExportTemplatesQuery['exportTemplates']['data'][0]>
      >(
        (acc, template) => ({
          ...acc,
          [template.code]: template,
        }),
        {}
      );
    }
  }, [data?.exportTemplates?.data]);

  return {
    templates,
    loading,
    error,
    client,
  };
}
