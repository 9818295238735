import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { CryptoCurrencyValue } from '../../../../../../../types';

export function useBlockchainController() {
  const { watch } = useFormContext();
  const cryptocurrencySymbolValue: CryptoCurrencyValue = watch(
    'cryptocurrency_symbol'
  );

  const isEnabledBlockchain = useMemo(
    () => cryptocurrencySymbolValue?.support_multiple_blockchains,
    [cryptocurrencySymbolValue?.support_multiple_blockchains]
  );

  const blockchainOptions = useMemo(() => {
    return cryptocurrencySymbolValue?.blockchainOptions || [];
  }, [cryptocurrencySymbolValue?.blockchainOptions]);

  return { isEnabledBlockchain, blockchainOptions };
}
