import { GridContainer } from '@aid-module/ui';
import styled from '@emotion/styled';

export const ContentContainer = styled(GridContainer)`
  & label > span {
    max-height: 31.19px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
`;
