import { SelectOption, SingleValue } from '@aid-module/ui';
import { TYPES } from '@aid-package/api';
import { useCallback } from 'react';

export function useSelectController() {
  const reduceData = useCallback(
    (item: any) => ({
      label: item?.name || '',
      value: item?.value || '',
    }),
    []
  );

  return {
    reduceData,
  };
}
