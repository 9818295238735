import { Toggle } from '@aid-module/ui';
import { useEnabledController } from './controller';
import { EnabledViewProps } from './types';

export function Enabled({ id, enabled, mutation }: EnabledViewProps) {
  const { onChange, loading } = useEnabledController(id, mutation);
  return (
    <Toggle
      label="Enabled"
      value={enabled}
      onChange={onChange}
      disabled={loading}
    />
  );
}
