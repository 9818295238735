import gql from 'graphql-tag';

export const CHECKUP_KIND_FRAGMENT = gql`
  fragment CheckupKindFragment on CheckupKind {
    code
    description
    executor_klass
    fields_contract_klass
    id
    name
    provider_id
    required_creds
  }
`;
