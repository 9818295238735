import gql from 'graphql-tag';
import { HIT_FRAGMENT } from './hit';

export const CA_ACTUAL_SEARCH_REVISION_FRAGMENT = gql`
  ${HIT_FRAGMENT}
  fragment CaActualSearchRevisionData on CaActualSearchRevisionData {
    assignee @type(name: "CaActualSearchRevisionAssignee") {
      created_at
      email
      id
      name
      phone
      user_is_active
    }
    assignee_id
    blacklist_hits @type(name: "BlacklistHit") {
      ...HitFragment
    }
    client_ref
    created_at
    filters @type(name: "CaActualSearchRevisionFilters") {
      country_codes
      entity_type
      exact_match
      fuzziness
      remove_deceased
      types
    }
    hits @type(name: "Hit") {
      ...HitFragment
    }
    id
    labels
    limit
    match_status
    offset
    ref
    risk_level
    search_term
    searcher @type(name: "CaActualSearchRevisionDataSearcher") {
      created_at
      email
      id
      name
      phone
      user_is_active
    }
    searcher_id
    share_url
    submitted_term
    tags
    total_blacklist_hits
    total_hits
    total_matches
    updated_at
  }
`;
