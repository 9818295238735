import styled from '@emotion/styled';
import { getColor, GridContainer } from '@aid-module/ui';

export const Container = styled(GridContainer)`
  width: 440px;
  border: 1px solid ${getColor('main-deep-2')};
  background: ${getColor('white-primary')};
  border-radius: 8px;
  overflow: hidden;
`;
