import styled from '@emotion/styled';
import { getColor } from '../../../../utils';
import { GridContainer } from '../../../../containers';

export const Container = styled(GridContainer)`
  width: 420px;
  border: 1px solid ${getColor('main-deep-2')};
  background: ${getColor('white-primary')};
  border-radius: 8px;
  overflow: hidden;
`;
