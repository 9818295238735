import gql from 'graphql-tag';

export const PricingClientBalance = gql`
  query PricingClientBalance {
    pricingClientBalance
      @rest(
        type: "ResponsePricingClientBalance"
        path: "/billing/client/clients/balance"
      ) {
      data {
        balance
      }
      success
    }
  }
`;
