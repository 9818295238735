import gql from 'graphql-tag';

export const ADMIN_STATS_FRAGMENT = gql`
  fragment AdminStatsFragment on AdminStats {
    accounts {
      count
      last_added_at
    }
    checkups {
      count
      last_added_at
    }
    clients {
      count
      last_added_at
    }
    field_templates {
      count
      last_added_at
    }
    instant_checkups {
      count
      last_added_at
    }
    regions {
      count
      last_added_at
    }
  }
`;
