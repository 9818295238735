import { LoadableFunctionalComponent } from '@aid-module/ui';
import loadable from '@loadable/component';
import { FormContainer } from '../components';
import { Fallback } from './components';

export const TagUpdate = loadable(() => import('./component'), {
  resolveComponent: (components) => components.TagUpdateComponent,
  fallback: (
    <FormContainer isLoading label="Update Form Tag">
      <Fallback />
    </FormContainer>
  ),
}) as LoadableFunctionalComponent<{}>;
