import loadable from '@loadable/component';
import { DropdownLoader } from '../../shared';
import { DropdownContainerViewProps } from './component/types';
import { LoadableFunctionalComponent } from '../../utils';
import { PropsWithChildren } from 'react';

export const Dropdown = loadable(() => import('./component'), {
  resolveComponent: (components) => components.DropdownComponent,
  fallback: <DropdownLoader />,
}) as LoadableFunctionalComponent<
  PropsWithChildren<DropdownContainerViewProps>
>;
