import styled from '@emotion/styled';
import { GridContainer } from '../../../../../../../../../../../containers';
import { Text } from '../../../../../../../../../../Text';
import { getColor, getSpace } from '../../../../../../../../../../../utils';
import { EmotionContainerProps } from '../../types';

export const Container = styled(GridContainer)<EmotionContainerProps>`
  padding: ${getSpace('6')} ${getSpace('14')};
  background: ${getColor('main-deep-1')};
  border: 1px solid ${getColor('main-deep-2')};
  border-radius: 4px;
  cursor: pointer;

  ${({ isActive }) =>
    isActive &&
    `
    background: ${getColor('main-deep-4')};
    border: 1px solid ${getColor('main-deep-4')};
  `}
`;

export const Label = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
`;
