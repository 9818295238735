import { PropsWithChildren } from 'react';
import { Container } from './styles';
import { PositionContext } from './context';
import { usePositionContainerController } from './controller';

export function PositionContainer({ children }: PropsWithChildren<{}>) {
  const { ref, ...context } = usePositionContainerController();
  return (
    <PositionContext.Provider value={context}>
      <Container ref={ref}>{children}</Container>
    </PositionContext.Provider>
  );
}
