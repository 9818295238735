import { gql } from '@apollo/client';

export const CreateDisposition = gql`
  mutation CreateDisposition($body: BodyCreateDisposition!) {
    createDisposition(body: $body)
      @rest(
        type: "CreateDisposition"
        path: "/tm/dispositions"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "TransactionDisposition") {
        id
        name
        created_at
        updated_at
      }
      success
    }
  }
`;

export const DeleteDisposition = gql`
  mutation DeleteDisposition($id: String!) {
    deleteDisposition(id: $id)
    @rest(
      type: "DeleteDisposition"
      path: "/tm/dispositions/{args.id}"
      method: "DELETE"
    ) {
      success
    }
  }
`;
