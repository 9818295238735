import gql from 'graphql-tag';
import { TRANSACTION_ENTITY_FRAGMENT } from '../../../fragment/tm/TransactionEntity';

export const TransactionEntities = gql`
  ${TRANSACTION_ENTITY_FRAGMENT}
  query TransactionEntities(
    $page: Int
    $per: Int
    $q: String
    $source: String
  ) {
    transactionEntities(page: $page, per: $per, q: $q, source: $source)
      @rest(type: "ResponseTransactionEntity", path: "/tm/entities?{args}") {
      data @type(name: "TransactionEntity") {
        ...TransactionEntityFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const TransactionEntity = gql`
  ${TRANSACTION_ENTITY_FRAGMENT}
  query TransactionEntity($id: String!) {
    transactionEntity(id: $id)
      @rest(type: "ResponseTransactionEntity", path: "/tm/entities/{args.id}") {
      data @type(name: "TransactionEntity") {
        ...TransactionEntityFragment
      }
      success
    }
  }
`;
