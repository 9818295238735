import { THEME_COLORS } from '../../../shared/colors/types';
import { IDENTITY_VERIFICATIONS_STATUSES } from '../constants';

export function getColorByStatus(status?: string | null): keyof THEME_COLORS {
  switch (status) {
    case IDENTITY_VERIFICATIONS_STATUSES.SUCCESS:
    case IDENTITY_VERIFICATIONS_STATUSES.PASSED:
    case IDENTITY_VERIFICATIONS_STATUSES.APPROVED:
    case IDENTITY_VERIFICATIONS_STATUSES.ACTIVE:
      return 'success-deep-4';
    case IDENTITY_VERIFICATIONS_STATUSES.DECLINED:
    case IDENTITY_VERIFICATIONS_STATUSES.FAIL:
    case IDENTITY_VERIFICATIONS_STATUSES.FAILED:
    case IDENTITY_VERIFICATIONS_STATUSES.UNPROCESSED:
    case IDENTITY_VERIFICATIONS_STATUSES.DENIED:
    case IDENTITY_VERIFICATIONS_STATUSES.SUSPECTED:
    case IDENTITY_VERIFICATIONS_STATUSES.EXPIRED:
      return 'error-deep-3';
    case IDENTITY_VERIFICATIONS_STATUSES.ABBADONED:
      return 'warning-deep-3';
    default:
      return 'error-deep-3';
  }
}
