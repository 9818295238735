import { AIDLoader } from '@aid-package/context';
import { Fragment, PropsWithChildren, useMemo } from 'react';
import { ErrorPages } from '../ErrorPages';
import { ApiCardViewProps } from './types';

export function ApiCard({
  loading,
  error,
  fallback,
  Container: PropsContainer,
  children,
}: PropsWithChildren<ApiCardViewProps>) {
  const Container = useMemo(() => PropsContainer || Fragment, [PropsContainer]);

  if (error?.networkError?.status === 404) {
    return <ErrorPages type="local" code={404} />;
  } else if (error?.networkError?.status === 403) {
    return <ErrorPages type="local" code={403} />;
  } else if (error) {
    return <ErrorPages type="local" code={500} />;
  }

  return (
    <Container>{loading ? fallback || <AIDLoader /> : children}</Container>
  );
}
