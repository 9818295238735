import {
  Card,
  GridContainer,
  List,
  ListLoader,
  Text,
  ListConfig,
} from '@aid-module/ui';
import { TYPES } from '@aid-package/api';
import { SubList } from './components';
import { useEntityInformationController } from './controller';

const config: ListConfig<
  NonNullable<
    TYPES.ServiceCheckupQuery['serviceCheckup']['data']['serialized_result']['data']
  >['hits'][0]
>[] = [
  {
    name: 'Name',
    render: (data) => data.doc?.name,
  },
  {
    name: 'Sources',
    render: (data) => <SubList list={data?.doc?.sources} />,
  },
  {
    name: 'Listing Type',
    render: (data) => <SubList list={data?.doc?.types} />,
  },
];

export function EntityInformation() {
  const { hit } = useEntityInformationController();

  return (
    <GridContainer gap="10">
      <Text text="Key Information" size={16} weight="Bold" />
      <Card background="main-deep-1" padding="XS">
        <List
          data={hit}
          config={config}
          fallback={<ListLoader count={config.length} />}
        />
      </Card>
    </GridContainer>
  );
}
