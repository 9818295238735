import { useCallback } from 'react';
import { useReadAllModel } from '../model';

export function useReadAllController() {
  const { readAll, loading } = useReadAllModel();
  const onClick = useCallback(() => {
    if (!loading) {
      readAll();
    }
  }, [readAll, loading]);

  return { onClick, loading };
}
