import { useLayoutEffect, useRef, useState } from 'react';
import { SelectStaticViewProps } from '../types';
import { SelectOption } from '@aid-module/ui';
import { STATIC_OPTIONS } from '../static';

export function useSelectController(
  code: SelectStaticViewProps['field']['static_name']
) {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<SelectOption[]>([]);

  const loadOptions = useRef(async () => {
    try {
      const options = STATIC_OPTIONS[code];
      setOptions(options);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  });

  useLayoutEffect(() => {
    loadOptions.current();
  }, []);

  return { loading, options };
}
