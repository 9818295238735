import { LoadableFunctionalComponent } from '@aid-module/ui';
import loadable from '@loadable/component';
import { FormContainer } from '../../components';
import { Fallback } from './components';

export const ClientUpdate = loadable(() => import('./component'), {
  resolveComponent: (components) => components.ClientUpdateComponent,
  fallback: (
    <FormContainer label="Update Client" icon="client">
      <Fallback />
    </FormContainer>
  ),
}) as LoadableFunctionalComponent<{}>;
