import { useFormContext } from 'react-hook-form';
import { FormProps } from '../../../types';
import { MouseEvent, useCallback } from 'react';
import { useActionsModel } from '../model';

export function useActionsController() {
  const { createDataRetentionConfig, loading } = useActionsModel();
  const { handleSubmit } = useFormContext<FormProps>();

  const onSubmit = useCallback(
    async (values: FormProps) => {
      await createDataRetentionConfig(values.data_storage_period_days.value);
    },
    [createDataRetentionConfig]
  );

  const onClickSubmit = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      handleSubmit(onSubmit)(e);
    },
    [handleSubmit, onSubmit]
  );

  return {
    disabled: loading,
    onClickSubmit,
  };
}
