import { THEME_COLORS } from '../../../shared/colors/types';
import { TRANSACTION_INSTRUMENT_STATUSES } from '../constants';
import { TmInstrumentStatusViewProps } from '../types';

export function getColorByStatus(
  status?: TmInstrumentStatusViewProps['status']
): keyof THEME_COLORS {
  switch (status) {
    case TRANSACTION_INSTRUMENT_STATUSES.ACTIVE:
      return 'success-deep-4';
    case TRANSACTION_INSTRUMENT_STATUSES.INACTIVE:
      return 'error-deep-3';
    default:
      return 'warning-deep-4';
  }
}
