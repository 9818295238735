import {
  TableWithHeaderLoader,
  LoadableFunctionalComponent,
} from '@aid-module/ui';
import loadable from '@loadable/component';

export const InvitessRegister = loadable(() => import('./component'), {
  resolveComponent: (components) => components.Component,
  fallback: <TableWithHeaderLoader />,
}) as LoadableFunctionalComponent<{}>;
