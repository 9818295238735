import {
  DashboardCardLoader,
  GridContainer,
  ContentLoader,
} from '@aid-module/ui';

export function Fallback() {
  return (
    <GridContainer gap="60">
      <GridContainer gap="40">
        <ContentLoader width="100%" height="31px">
          <rect rx="4px" ry="4px" width="100%" height="31px" />
        </ContentLoader>
        <GridContainer gridTemplateColumns="repeat(3, 1fr)" gap="60">
          <DashboardCardLoader />
          <DashboardCardLoader />
        </GridContainer>
      </GridContainer>
      <GridContainer gap="40">
        <ContentLoader width="100%" height="31px">
          <rect rx="4px" ry="4px" width="100%" height="31px" />
        </ContentLoader>
        <GridContainer gridTemplateColumns="repeat(3, 1fr)" gap="60">
          <DashboardCardLoader />
          <DashboardCardLoader />
          <DashboardCardLoader />
          <DashboardCardLoader />
          <DashboardCardLoader />
          <DashboardCardLoader />
        </GridContainer>
      </GridContainer>
    </GridContainer>
  );
}
