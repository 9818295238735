import { showToast } from '@aid-module/ui';
import {
  useCasesLazyQuery,
  useCreateGlobalSearchEntrieMutation,
  useIdentityVerificationsLazyQuery,
  useProfilesLazyQuery,
  useServiceCheckupsLazyQuery,
  useSubmissionsLazyQuery,
  useTransactionEventsLazyQuery,
} from '@aid-package/api';
import {
  PERMISSIONS,
  PERMISSIONS_CM_CASES,
  PERMISSIONS_PROFILES,
  PERMISSIONS_SUBMISSIONS,
  useCheckPermission,
} from '@aid-package/context';
import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export function useSearchInputModel() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { client_id } = useParams();
  const [mutation] = useCreateGlobalSearchEntrieMutation();
  const { canShow: canShowTransactions } = useCheckPermission(
    [PERMISSIONS.SEE_TM],
    false
  );

  const { canShow: canShowCases } = useCheckPermission(
    PERMISSIONS_CM_CASES,
    false
  );

  const { canShow: canShowIdentityVerifications } = useCheckPermission(
    [
      PERMISSIONS.CM_IDENTITY_VERIFICATIONS,
      PERMISSIONS.MANAGE_CM_CASES,
      PERMISSIONS.SEE_APPROVED_CM_CASES,
    ],
    false
  );

  const { canShow: canShowCheckups } = useCheckPermission(
    [PERMISSIONS.CHECKUPS_PERFORM_INSTANT],
    false
  );

  const { canShow: canShowSubmissions } = useCheckPermission(
    PERMISSIONS_SUBMISSIONS,
    false
  );

  const { canShow: canShowProfiles } = useCheckPermission(
    PERMISSIONS_PROFILES,
    false
  );

  const [loadTransactions] = useTransactionEventsLazyQuery();
  const [loadCases] = useCasesLazyQuery();
  const [loadIdentityVerifications] = useIdentityVerificationsLazyQuery();
  const [loadCheckups] = useServiceCheckupsLazyQuery();
  const [loadSubmissions] = useSubmissionsLazyQuery();
  const [loadProfiles] = useProfilesLazyQuery();

  const getTransactionsCount = useCallback(
    async (q: string) => {
      if (canShowTransactions) {
        const { data } = await loadTransactions({
          variables: { q, per: 1 },
        });

        return {
          tm: data?.transactionEvents.meta?.count,
        };
      }

      return {
        tm: 0,
      };
    },
    [loadTransactions, canShowTransactions]
  );

  const getCasesCount = useCallback(
    async (q: string) => {
      if (canShowCases) {
        const { data } = await loadCases({
          variables: { q, per: 1 },
        });

        return {
          case: data?.cases.meta?.count,
        };
      }

      return {
        case: 0,
      };
    },
    [canShowCases, loadCases]
  );

  const getIdentityVerificationsCount = useCallback(
    async (q: string) => {
      if (canShowIdentityVerifications) {
        const { data } = await loadIdentityVerifications({
          variables: { q, per: 1 },
        });

        return {
          identityVerification: data?.identityVerifications.meta?.count,
        };
      }

      return {
        identityVerification: 0,
      };
    },
    [canShowIdentityVerifications, loadIdentityVerifications]
  );

  const getCheckupsCount = useCallback(
    async (q: string) => {
      if (canShowCheckups) {
        const { data } = await loadCheckups({
          variables: { q, per: 1 },
        });

        return { checkup: data?.serviceCheckups.meta?.count };
      }

      return {
        checkup: 0,
      };
    },
    [canShowCheckups, loadCheckups]
  );

  const getSubmissionsCount = useCallback(
    async (q: string) => {
      if (canShowSubmissions) {
        const { data } = await loadSubmissions({
          variables: { q, per: 1 },
        });

        return { submission: data?.submissions.meta?.count };
      }

      return {
        submission: 0,
      };
    },
    [canShowSubmissions, loadSubmissions]
  );

  const getProfilesCount = useCallback(
    async (q: string) => {
      if (canShowProfiles) {
        const { data } = await loadProfiles({
          variables: { q, per: 1 },
        });

        return { profile: data?.profiles.meta?.count };
      }

      return {
        profile: 0,
      };
    },
    [canShowProfiles, loadProfiles]
  );

  const createSearchEntrie = useCallback(
    async (q: string) => {
      setLoading(true);

      const entriesCount = await Promise.all([
        getTransactionsCount(q),
        getCasesCount(q),
        getIdentityVerificationsCount(q),
        getCheckupsCount(q),
        getSubmissionsCount(q),
        getProfilesCount(q),
      ]).then((values) =>
        values.reduce((acc, item) => {
          return {
            ...acc,
            ...item,
          };
        }, {})
      );

      const { data } = await mutation({
        variables: {
          body: {
            global_search_entry: {
              search_query: q,
              entry_count: entriesCount,
            },
          },
        },
      });

      if (data?.createGlobalSearchEntrie?.success) {
        setLoading(false);
        return navigate(
          `/${client_id}/search/search_results/${data?.createGlobalSearchEntrie.data.id}`
        );
      }

      showToast({
        type: 'error',
        message: 'Something went wrong',
      });

      setLoading(false);
    },
    [
      setLoading,
      getTransactionsCount,
      getCasesCount,
      getIdentityVerificationsCount,
      getCheckupsCount,
      getSubmissionsCount,
      getProfilesCount,
      mutation,
      client_id,
      navigate,
    ]
  );

  return {
    createSearchEntrie,
    loading,
  };
}
