import gql from 'graphql-tag';

export const RISK_SCORING_TEMPLATE_FRAGMENT = gql`
  fragment RiskScoringTemplateFragment on RiskScoringTemplate {
    client_id
    created_at
    enabled
    id
    name
    updated_at
    value
  }
`;
