import { getColor } from '@aid-module/ui';
import styled from '@emotion/styled';

export const DropdownContentContainer = styled.div`
  & > a:not(:last-of-type) {
    & > div {
      border-bottom: 1px solid ${getColor('main-deep-2')};
    }
  }

  & > a:first-of-type {
    & > div {
      border-top: 1px solid ${getColor('main-deep-2')};

      &:hover {
        border-top: 1px solid ${getColor('main-deep-1')};
      }
    }
  }
`;
