import { useCallback } from 'react';
import { useModalContext } from '../../../../../context';

export function useNotificationController() {
  const { close } = useModalContext();

  const onClick = useCallback(() => {
    close();
  }, [close]);

  return {
    onClick,
  };
}
