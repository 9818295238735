import { useCallback, useEffect, useState } from 'react';
import { EditProviderViewProps } from '../types';

export function useEditProviderController(
  bulkConfig: EditProviderViewProps['bulkConfig']
) {
  const [selectedIds, setSelectedIds] = useState<Record<string, boolean>>({});

  const onChangeSelected = useCallback((id: string, value: boolean) => {
    setSelectedIds((prev) => {
      if (value) {
        return {
          ...prev,
          [id]: value,
        };
      }
      const next = { ...prev };
      delete next[id];
      return next;
    });
  }, []);

  useEffect(() => {}, []);

  const onSelectAll = useCallback(
    (
      next:
        | Record<string, boolean>
        | ((prev: Record<string, boolean>) => Record<string, boolean>)
    ) => {
      setSelectedIds(next);
    },
    []
  );

  const clean = useCallback(() => {
    setSelectedIds({});
  }, []);

  return { selectedIds, bulkConfig, onChangeSelected, clean, onSelectAll };
}
