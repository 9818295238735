import { gql } from '@apollo/client';

export const SubscribeOnPlan = gql`
  mutation SubscribeOnPlan($body: SubscribeOnPlanBody!) {
    subscribeOnPlan(body: $body)
      @rest(
        type: "SubscribeOnPlan"
        path: "/billing/client/subscriptions"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "PlanSubscription") {
        client_id
        created_at
        deleted_at
        finished_at
        id
        my_features @type(name: "PlanSubscriptionFeature") {
          available
          created_at
          current_value
          description
          feature_template @type(name: "FeatureTemplate") {
            code
            created_at
            created_by_id
            description
            feature_dictionary @type(name: "FeatureDictionary") {
              code
              created_at
              created_by_id
              id
              is_system
              name
              status
              updated_at
            }
            feature_dictionary_id
            id
            kind
            name
            status
            updated_at
            is_system
          }
          feature_template_id
          finished_at
          id
          limit_rules {
            price
            start_count
            finish_count
          }
          name
          plan_id
          started_at
          updated_at
          validity_period
        }
        plan @type(name: "PricingPlan") {
          billing_period
          created_at
          description
          expired_at
          id
          is_actual_revision
          is_additional
          is_recommended
          kind
          name
          onboarding_enabled
          preview_body
          price
          reference_key
          revision_number
          started_at
          status
          subscriptions_count
          updated_at
        }
        plan_id
        started_at
        status
        updated_at
      }
      success
      error
    }
  }
`;
