import styled from '@emotion/styled';
import { GridContainer } from '../../../../containers';
import { getColor, getSpace } from '../../../../utils';

export const Container = styled(GridContainer)`
  margin: calc(-1 * ${getSpace('20')}) 0;
  padding: ${getSpace('20')} 0 ${getSpace('20')} ${getSpace('32')};
  border-left: 1px solid ${getColor('main-deep-2')};
  width: 369px;
  overflow: hidden;
`;
