import gql from 'graphql-tag';
import { DEFAULT_CLIENT_FRAGMENT } from '../../DefaultClient';
import { ACCOUNT_FRAGMENT } from '../accounts';

export const CLIENT_FRAGMENT = gql`
  ${DEFAULT_CLIENT_FRAGMENT}
  ${ACCOUNT_FRAGMENT}
  fragment ClientFragment on Client {
    ...DefaultClientFragment
    accounts @type(name: "Account") {
      ...AccountFragment
    }
    accounts_count
    application_key
    balance
    created_at
    customers_count
    default
    description
    oauth_domain
    oauth_id
    oauth_scopes
    oauth_secret
    reference_key
    swift_code
    updated_at
    use_on_registration
  }
`;
