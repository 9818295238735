import { useMemo } from 'react';
import { THEME_COLORS } from '../../../shared';
import { EmptyViewProps } from '../types';

export function useEmptyController(type: EmptyViewProps['type'] = 'default') {
  const colors = useMemo<
    Record<
      'background' | 'border' | 'loaderForeground' | 'loaderBackground',
      keyof THEME_COLORS
    >
  >(
    () => ({
      background: type === 'default' ? 'main-deep-1' : 'white-primary',
      border: 'main-deep-2',
      loaderBackground: type === 'default' ? 'main-deep-2' : 'main-deep-1',
      loaderForeground: type === 'default' ? 'main-deep-1' : 'main-deep-2',
    }),
    [type]
  );

  return colors;
}
