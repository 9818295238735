import { useExportRequestsQuery } from '@aid-package/api';
import { useMemo } from 'react';

export function useExportRequestsModel() {
  const { data, loading } = useExportRequestsQuery({ variables: { download_at_exist: 'false' } });

  const countDoc = useMemo(() => {
    return data?.exportRequests.meta?.count || 0;
  }, [data])

  return { countDoc, loading };
}
