import { useCheckPermission } from '@aid-package/context';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { CaseManagementNavigationItems } from '../../../../../../Breadcrumbs/constants';

export function useCaseManagementLinkController() {
  const { client_id } = useParams();

  const { canShow: canCases } = useCheckPermission(
    CaseManagementNavigationItems[0].check,
    false
  );
  const { canShow: canVerifications } = useCheckPermission(
    CaseManagementNavigationItems[1].check,
    false
  );
  const { canShow: canProfiles } = useCheckPermission(
    CaseManagementNavigationItems[2].check,
    false
  );

  const caseManagementAccesiblePath = useMemo(() => {
    const caseManagementCanShows = [canCases, canVerifications, canProfiles];
    const firstAccesiblePathIndex = caseManagementCanShows.indexOf(true);

    const firstAccesiblePath =
      CaseManagementNavigationItems[firstAccesiblePathIndex]?.path;

    return `/${client_id}${firstAccesiblePath}`;
  }, [canCases, canVerifications, canProfiles, client_id]);

  const caseManagementPaths = useMemo(() => {
    return CaseManagementNavigationItems.map(
      (item) => `/${client_id}${item.path}`
    );
  }, [client_id]);

  return {
    caseManagementAccesiblePath,
    caseManagementPaths,
  };
}
