import {
  ApolloLink,
  FetchResult,
  InMemoryCache,
  NextLink,
  Observable,
  Operation,
} from '@apollo/client';
import { MyFeatures } from '../../query';
import { createOperation } from '@apollo/client/link/utils';

class BilingLink extends ApolloLink {
  updateBiling(operation: Operation, forward: NextLink) {
    const context: { cache?: InMemoryCache } = operation.getContext();
    const cache = context.cache;
    return new Observable<FetchResult>((observer) => {
      forward(operation).subscribe(
        (data: FetchResult) => {
          if (data?.data) {
            const createMyFeatureOpreation = createOperation(context, {
              query: MyFeatures,
              operationName: 'MyFeatures',
              extensions: operation.extensions,
            });
            forward(createMyFeatureOpreation).subscribe(
              (myFeatures) => {
                cache?.writeQuery({
                  query: MyFeatures,
                  data: {
                    myFeatures: {
                      data: myFeatures?.data?.myFeatures.data,
                      success: true,
                    },
                  },
                });
                observer.next(data);
                observer.complete();
              },
              (error) => {
                observer.error(error);
              }
            );
          } else {
            observer.error('Forward');
          }
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  request(operation: Operation, forward: NextLink) {
    if (
      operation.operationName === 'CreateCheckup' ||
      operation.operationName === 'Duplicate' ||
      operation.operationName === 'CreateForm' ||
      operation.operationName === 'CreateTransactionScoringMatrix' ||
      operation.operationName === 'CreateTranslationQuote' ||
      operation.operationName === 'SubscribeOnPlan' ||
      operation.operationName === 'VerificationFlowCreate'
    ) {
      return this.updateBiling(operation, forward);
    }
    return forward(operation);
  }
}
export default new BilingLink();
