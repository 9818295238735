import { CreateScenario, RuleTemplates } from './components';
import { useRuleCreateController } from './controller';

export function RuleCreate() {
  const { code } = useRuleCreateController();

  if (code) {
    return <CreateScenario />;
  }

  return <RuleTemplates />;
}
