export const variants = {
  visible: {
    height: 'auto',
    overflow: 'hidden',
    transition: {
      type: 'just',
      duration: 0.15,
    },
  },
  hidden: {
    height: 0,
    transition: {
      type: 'just',
      duration: 0.15,
    },
  },
};
