import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import {
  useConfigContext,
  useVariablesContext,
} from '../../../../../../../../context';
import { FilterValueViewProps } from '../../../../FilterValue/types';
import { FilterTemplateViewProps } from '../types';
import { DateFormat } from '../../../../../../../../../../utils';

export function useFilterTemplateController(
  filterTemplate: FilterTemplateViewProps['filterTemplate']
) {
  const { onChangeVariables, variables, defaultVariables } =
    useVariablesContext();
  const filtersConfig = useConfigContext();
  const onClick = useCallback(() => {
    onChangeVariables((prev) => ({
      ...(prev.filterTemplate?.id === filterTemplate.id
        ? prev
        : defaultVariables),
      filters: undefined,
      q: prev?.q,
      filterTemplate:
        prev.filterTemplate?.id === filterTemplate.id
          ? undefined
          : filterTemplate,
    }));
  }, [onChangeVariables, filterTemplate, defaultVariables]);

  const isActive = useMemo(
    () => filterTemplate.id === variables.filterTemplate?.id,
    [variables.filterTemplate, filterTemplate]
  );

  const selectedFilters = useMemo(
    () =>
      Object.entries(filterTemplate.meta || {}).reduce<FilterValueViewProps[]>(
        (acc, item) => {
          const [itemKey, value] = item;
          if (!value || !filtersConfig?.[itemKey]) {
            return acc;
          }
          if (Array.isArray(value)) {
            const renderValue = value.reduce<string>((acc, option) => {
              return acc ? `${acc}, ${option?.label}` : option?.label || '';
            }, '');
            return [
              ...acc,
              {
                label: filtersConfig?.[itemKey].label || '-',
                value: renderValue,
                itemKey,
              },
            ];
          }
          if (typeof value === 'object') {
            return [
              ...acc,
              {
                label: filtersConfig?.[itemKey].label || '-',
                value: value.label,
                itemKey,
              },
            ];
          }
          if (
            typeof value === 'string' &&
            isNaN(Number(value)) &&
            dayjs(value).isValid()
          ) {
            return [
              ...acc,
              {
                label: filtersConfig?.[itemKey].label || '-',
                value: DateFormat.formatDate(value),
                itemKey,
              },
            ];
          }
          return [
            ...acc,
            {
              label: filtersConfig?.[itemKey].label || '-',
              value: value.toString(),
              itemKey,
            },
          ];
        },
        []
      ),
    [filterTemplate.filters, filtersConfig]
  );

  return { onClick, isActive, selectedFilters };
}
