import gql from 'graphql-tag';

export const ACCOUNT_STATS_FRAGMENT = gql`
  fragment AccountStatsFragment on AccountStats {
    roles {
      total
      last_added_at
    }
    users {
      total
      last_added_at
    }
    transactions {
      total
      last_added_at
    }
    form_tags {
      total
      last_added_at
    }
  }
`;
