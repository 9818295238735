import { SelectOption, SelectViewProps, Select } from '@aid-module/ui';
import { CLIENT_TYPES_OPTIONS } from './constants';

export function ClientTypesSelect(
  props: Pick<
    SelectViewProps<true, SelectOption>,
    'value' | 'onChange' | 'error'
  >
) {
  return (
    <Select
      {...props}
      isMulti
      label="Client types"
      placeholder="Select client types"
      options={CLIENT_TYPES_OPTIONS}
    />
  );
}
