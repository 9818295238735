import loadable from '@loadable/component';
import { PropsWithChildren } from 'react';
import { LoadableFunctionalComponent } from '../../utils';
import { ImageLoader } from '../../shared';
import { ImageComponentViewProps } from './component/types';

export const Image = loadable(() => import('./component'), {
  resolveComponent: (components) => components.ImageComponent,
  fallback: <ImageLoader />,
}) as LoadableFunctionalComponent<PropsWithChildren<ImageComponentViewProps>>;
