import { Icon, Text } from '@aid-module/ui';
import { PropsWithChildren } from 'react';
import { useDropdownController } from './controller';
import {
  ArrowContainer,
  Container,
  TitleContainer,
  ContentContainer,
} from './styles';
import { DropdownViewProps } from './types';
import { AnimatePresence, motion } from 'framer-motion';
import { variants } from './constants';

export function Dropdown({
  icon,
  text,
  children,
}: PropsWithChildren<DropdownViewProps>) {
  const { open, toggleOpen } = useDropdownController();
  return (
    <Container>
      <TitleContainer
        gridTemplateColumns="max-content max-content max-content"
        gap="8"
        alignItems="center"
        onClick={toggleOpen}
      >
        <Icon glyph={icon} color="main-deep-4" size={16} />
        <Text size={12} text={text} />
        <ArrowContainer open={open} alignItems="center" justifyContent="center">
          <Icon glyph="arrowRight" color="main-deep-5" size={12} />
        </ArrowContainer>
      </TitleContainer>
      <AnimatePresence>
        {open && (
          <motion.div
            variants={variants}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            <ContentContainer>{children}</ContentContainer>
          </motion.div>
        )}
      </AnimatePresence>
    </Container>
  );
}
