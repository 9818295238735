import { GridContainer, PrimaryButton, SecondaryButton } from '@aid-module/ui';
import { Card } from './styles';
import { useActionsController } from './controller';

export function Actions() {
  const { disabled, onClickSave, onClickSaveAsDraft } = useActionsController();
  return (
    <Card
      background="main-deep-1"
      loaderForeground="main-deep-1"
      loaderBackground="main-deep-2"
    >
      <GridContainer gap="12" gridTemplateColumns="repeat(2, 1fr)">
        <SecondaryButton
          text="Save as template"
          onClick={onClickSaveAsDraft}
          disabled={disabled}
        />
        <PrimaryButton
          text="Create statistic"
          onClick={onClickSave}
          disabled={disabled}
        />
      </GridContainer>
    </Card>
  );
}
