import { MouseEvent, useCallback } from 'react';
import { useActionsModel } from '../model';
import { MarkAsReadViewProps } from '../types';

export function useActionsController(
  notification: MarkAsReadViewProps['notification']
) {
  const { read, loading } = useActionsModel();

  const onClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (!loading) {
        read(notification.id);
      }
    },
    [read, notification.id, loading]
  );

  return { onClick, loading };
}
