import { GridContainer, ClientAvatar, IconLoader } from '@aid-module/ui';
import { ContentContainer, Label } from '../shared.style';
import { useCLientController } from './controller';
import { ActionContainer } from './styles';
import { ClientViewProps } from './types';

export function Client({ client }: ClientViewProps) {
  const { href } = useCLientController(client);
  return (
    <ActionContainer to={href}>
      <ContentContainer gridTemplateColumns="40px 1fr" gap="8">
        <ClientAvatar client={client} fallback={<IconLoader size={40} />} />
        <GridContainer alignItems="center">
          <Label text={client.client?.name} size={16} weight="Bold" />
        </GridContainer>
      </ContentContainer>
    </ActionContainer>
  );
}
