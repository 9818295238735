import gql from 'graphql-tag';

export const GetStateStats = gql`
  query GetStateStats(
    $from_date: String
    $to_date: String
    $form_configuration_reference_keys: [String!]
    $with_draft: Boolean
  ) {
    getStateStats(
      from_date: $from_date
      to_date: $to_date
      form_configuration_reference_keys: $form_configuration_reference_keys
      with_draft: $with_draft
    )
    @rest(
      type: "ResponseGetStateStats"
      path: "/stats/client/charts_and_diagrams/statuses?{args}"
    ) {
      data @type(name: "StateStatsItem") {
        percent
        count
        name
      }
      success
    }
  }
`;

export const GetRegionStats = gql`
  query GetRegionStats(
    $from_date: String
    $to_date: String
    $form_configuration_reference_keys: [String!]
  ) {
    getRegionStats(
      from_date: $from_date
      to_date: $to_date
      form_configuration_reference_keys: $form_configuration_reference_keys
    )
    @rest(
      type: "ResponseGetRegionStats"
      path: "/stats/client/charts_and_diagrams/fetch_regions?{args}"
    ) {
      data {
        other_count
        top10
      }
      success
    }
  }
`;

export const GetRejectReasonStats = gql`
  query GetRejectReasonStats(
    $from_date: String
    $to_date: String
    $form_configuration_reference_keys: [String!]
  ) {
    getRejectReasonStats(
      from_date: $from_date
      to_date: $to_date
      form_configuration_reference_keys: $form_configuration_reference_keys
    )
    @rest(
      type: "ResponseGetRejectReasonStats"
      path: "/stats/client/charts_and_diagrams/reject_reasons?{args}"
    ) {
      data {
        other_count
        top5
      }
      success
    }
  }
`;
