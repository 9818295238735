import gql from 'graphql-tag';

export const Ping = gql`
  query Ping {
    ping @rest(type: "Ping", path: "/auth/ping") {
      data
      success
    }
  }
`;
