import { useMemo } from 'react';
import { TmEventStatusViewProps } from '../types';
import { getColorByStatus } from './utils';
import { TRANSACTION_DISPLAY_STATUSES } from '../constants';

export function useTmEventStatusController(
  status: TmEventStatusViewProps['status']
) {
  const background = useMemo(() => getColorByStatus(status), [status]);

  const text = useMemo(
    () =>
      status
        ? TRANSACTION_DISPLAY_STATUSES[status] || status.replace('_', '  ')
        : '',
    [status]
  );

  return {
    text,
    background,
  };
}
