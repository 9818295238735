import { AutomaticTooltip, SecondaryButton, Text } from '@aid-module/ui';
import { useCommentsController } from './controller';
import { Container } from './styles';
import { CommentsViewProps, ViewProps } from './types';

function View({ initiateRef, onClick, disabled }: ViewProps) {
  return (
    <Container gridTemplateColumns="158px" ref={initiateRef}>
      <SecondaryButton icon="comments" text="Show Comments" onClick={onClick} disabled={disabled} />
    </Container>
  );
}

export function Comments({ hit, checkupId }: CommentsViewProps) {
  const { onClick, disabled } = useCommentsController(hit, checkupId);

  return (
    <AutomaticTooltip
      canShow={disabled}
      pattern="top,left,right,bottom"
      background="main-deep-3"
      border="main-deep-3"
      view={<View onClick={onClick} disabled={disabled} />}
    >
      <Text
        color="white-primary"
        text="You do not have permissions"
        size={14}
      />
    </AutomaticTooltip>
  );
}
