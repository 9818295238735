import { Text } from '../../../../../Text';
import { ProfilesButton } from './styles';
import { ButtonViewProps } from './types';

export function Button({ initiateRef, count }: ButtonViewProps) {
  return (
    <ProfilesButton
      ref={initiateRef}
      justifyContent="center"
      alignItems="center"
    >
      <Text text={`+${count}`} size={11} color="white-primary" />
    </ProfilesButton>
  );
}
