import { GridContainer } from '../../../containers';
import { ContentLoader } from '../ContentLoader';
import { TableLoader } from '../TableLoader';

export function TableWithHeaderLoader() {
  return (
    <GridContainer gap="20">
      <GridContainer gap="18">
        <ContentLoader width="500px" height="33.8px">
          <rect width="500px" height="33.8px" rx="3" ry="3" />
        </ContentLoader>
        <ContentLoader width="100%" height="32.2px">
          <rect width="100%" height="32.2px" rx="3" ry="3" />
        </ContentLoader>
      </GridContainer>
      <TableLoader />
    </GridContainer>
  );
}
