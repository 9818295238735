import { ContentLoader, GridContainer } from '@aid-module/ui';

export function Fallback() {
  return (
    <GridContainer gap="12">
      <ContentLoader width="100%" height="85.59px">
        <rect rx="4px" ry="4px" width="100%" height="85.59px" />
      </ContentLoader>
      <ContentLoader width="100%" height="800px">
        <rect rx="4px" ry="4px" width="100%" height="800px" />
      </ContentLoader>
    </GridContainer>
  );
}
