import { TableWithHeaderLoader, LoadableFunctionalComponent } from '@aid-module/ui';
import loadable from '@loadable/component';

export const RiskScoringTemplatesRegister = loadable(
  () => import('./component'),
  {
    resolveComponent: (components) =>
      components.RiskScoringTemplatesRegisterComponent,
    fallback: <TableWithHeaderLoader />,
  }
) as LoadableFunctionalComponent<{}>;
