import {
  FlexContainer,
  getColor,
  getSpace,
  GridContainer,
} from '@aid-module/ui';
import styled from '@emotion/styled';
import { EmotionArrowViewProps } from './types';

export const ContentContainer = styled.div`
  margin-left: ${getSpace('6')};
`;

export const TitleContainer = styled(GridContainer)`
  padding: ${getSpace('6')} ${getSpace('6')};
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: ${getColor('main-deep-1')};
  }
`;

export const Container = styled.div`
  position: relative;
`;

export const ArrowContainer = styled(FlexContainer)<EmotionArrowViewProps>`
  transform: rotate(90deg);
  transition: transform 0.2s;
  ${({ open }) =>
    open &&
    `
    transform: rotate(270deg);
  `};
`;
