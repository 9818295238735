export const variants = {
  initial: {
    y: -20,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.1,
      type: 'spring',
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
};

export const CASE_STATUSES = {
  NEW: 'new',
  READY_TO_WORK: 'ready_to_work',
  AUTO_APPROVED: 'auto_approved',
  IN_WORK: 'in_work',
  RFI: 'rfi',
  NEEDS_UPDATE: 'needs_update',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  ABANDONED: 'abandoned',
  ON_HOLD: 'on_hold',
  PRE_APPROVED_FOR_REVIEW: 'pre_approved_for_review',
  NO_RESPONSE: 'no_response',
  CLOSED: 'closed',
  PRE_REJECTED: 'pre_rejected',
};

export const CASE_ACTUAL_DISPLAY_STATUSES = {
  [CASE_STATUSES.NEW]: 'Draft',
  [CASE_STATUSES.READY_TO_WORK]: 'Waiting List',
  [CASE_STATUSES.IN_WORK]: 'In Review',
  [CASE_STATUSES.RFI]: 'Requested for Information',
  [CASE_STATUSES.NEEDS_UPDATE]: 'Needs Update',
  [CASE_STATUSES.AUTO_APPROVED]: 'Auto Approved',
  [CASE_STATUSES.ACCEPTED]: 'Approved',
  [CASE_STATUSES.REJECTED]: 'Rejected',
  [CASE_STATUSES.ABANDONED]: 'Abandoned',
  [CASE_STATUSES.ON_HOLD]: 'On hold',
  [CASE_STATUSES.PRE_APPROVED_FOR_REVIEW]: 'Pre-approved for review',
  [CASE_STATUSES.NO_RESPONSE]: 'No Response',
  [CASE_STATUSES.CLOSED]: 'Closed',
  [CASE_STATUSES.PRE_REJECTED]: 'Pre-Rejected',
};

export const CASE_DISPLAY_STATUSES = {
  [CASE_STATUSES.NEW]: 'New',
  [CASE_STATUSES.READY_TO_WORK]: 'Ready To Work',
  [CASE_STATUSES.IN_WORK]: 'In Work',
  [CASE_STATUSES.RFI]: 'RFI',
  [CASE_STATUSES.NEEDS_UPDATE]: 'Needs Update',
  [CASE_STATUSES.ACCEPTED]: 'Accepted',
  [CASE_STATUSES.AUTO_APPROVED]: 'Auto Approved',
  [CASE_STATUSES.REJECTED]: 'Rejected',
  [CASE_STATUSES.ABANDONED]: 'Abandoned',
  [CASE_STATUSES.ON_HOLD]: 'On Hold',
  [CASE_STATUSES.PRE_APPROVED_FOR_REVIEW]: 'Pre-approved for review',
  [CASE_STATUSES.NO_RESPONSE]: 'No Response',
  [CASE_STATUSES.CLOSED]: 'Closed',
  [CASE_STATUSES.PRE_REJECTED]: 'Pre-Rejected',
};
