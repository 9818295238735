import { useCallback } from 'react';
import { showToast, ModalBodyViewProps } from '@aid-module/ui';
import {
  DraftClients,
  useCreateDraftClientMutation,
  TYPES,
} from '@aid-package/api';

export function useActionsModel(onClose: ModalBodyViewProps['onClose']) {
  const [mutation, { loading }] = useCreateDraftClientMutation();

  const createDraftClient = useCallback(
    async (values: TYPES.CreateDraftBody) => {
      const { data } = await mutation({
        variables: {
          body: values,
        },
        refetchQueries: [DraftClients],
      });

      if (data?.createDraftClient?.success) {
        showToast({
          type: 'success',
          message: 'Changes were successfully saved',
        });
        onClose?.();
        return;
      }

      showToast({
        type: 'error',
        message: 'Something went wrong',
      });
    },
    [mutation, onClose]
  );

  return { createDraftClient, loading };
}
