import { LoadableFunctionalComponent } from '@aid-module/ui';
import loadable from '@loadable/component';
import { FormContainer } from '../../components';
import { Fallback } from './components';

export const CheckupUpdate = loadable(() => import('./component'), {
  resolveComponent: (components) => components.CheckupUpdateComponent,
  fallback: (
    <FormContainer label="Update Verification Setting" icon="checkup" isLoading>
      <Fallback />
    </FormContainer>
  ),
}) as LoadableFunctionalComponent<{}>;
