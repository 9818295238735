import gql from 'graphql-tag';

export const CdsCableRefreshTasks = gql`
  query CdsCableRefreshTasks($page: Int, $per: Int) {
    cdsCableRefreshTasks(page: $page, per: $per)
      @rest(
        type: "ResponseCdsCableRefreshTasks"
        path: "/cds/cable/refresh_tasks?{args}"
      ) {
      data @type(name: "CdsCableRefreshTask") {
        completeness
        created_at
        id
        overall_events
        performed_at
        processed_events
        status
        updated_at
      }
      meta {
        count
        page
        items
        pages
      }
      success
    }
  }
`;

export const CdsCableCsvExports = gql`
  query CdsCableCsvExports(
    $page: Int
    $per: Int
    $status: String
    $table_name: String
  ) {
    cdsCableCsvExports(
      page: $page
      per: $per
      status: $status
      table_name: $table_name
    )
      @rest(
        type: "ResponseCdsCableCsvExports"
        path: "/cds/cable/csv_export_tasks?{args}"
      ) {
      data @type(name: "CdsCableCsvExport") {
        created_at
        delta_from
        delta_upto
        id
        performed_at
        status
        table_name
        updated_at
        uploaded_document_id
      }
      meta {
        count
        page
        items
        pages
      }
      success
    }
  }
`;
