import {
  getColor,
  getSpace,
  GridContainer,
  Radio as UIRadio,
} from '@aid-module/ui';
import styled from '@emotion/styled';

export const Radio = styled(UIRadio)`
  padding-left: ${getSpace('14')};
  grid-template-columns: repeat(2, max-content);
  gap: ${getSpace('12')};
`;

export const ContentSeparator = styled.div`
  border-bottom: 1px solid ${getColor('main-deep-2')};
`;

export const ClearSelection = styled(GridContainer)`
  cursor: pointer;
`;
