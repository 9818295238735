import gql from 'graphql-tag';

export const UpdateMe = gql`
  mutation UpdateMe($body: BodyMeUpdate) {
    updateMe(body: $body)
      @rest(
        type: "MeUpdate"
        path: "/core/accounts/me"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "Account") {
        accounts_clients @type(name: "AccountClient") {
          id
          position_name
          role
          client @type(name: "Client") {
            file_name
            file_size
            id
            logo_id
            name
          }
        }
        avatar_id
        email
        file_name
        file_size
        first_name
        full_name
        id
        last_name
        onboarding_verification_id
        onboarding_verification_status
        phone
        region_code
        role
        status
      }
      success
    }
  }
`;
