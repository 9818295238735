import { useCheckPermission } from '@aid-package/context';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { UsersNavigationItems } from '../../../../../../Breadcrumbs/constants';

export function useUsersLinkController() {
  const { client_id } = useParams();

  const { canShow: canUsers } = useCheckPermission(
    UsersNavigationItems[0].check,
    UsersNavigationItems[0].strong || false
  );
  const { canShow: canInvites } = useCheckPermission(
    UsersNavigationItems[1].check,
    UsersNavigationItems[0].strong || false
  );

  const usersAccesiblePath = useMemo(() => {
    const usersCanShows = [canUsers, canInvites];
    const firstAccesiblePathIndex = usersCanShows.indexOf(true);

    const firstAccesiblePath =
      UsersNavigationItems[firstAccesiblePathIndex]?.path;

    return `/${client_id}${firstAccesiblePath}`;
  }, [canUsers, canInvites, client_id]);

  const usersPaths = useMemo(() => {
    return UsersNavigationItems.map((item) => `/${client_id}${item.path}`);
  }, [client_id]);

  return {
    usersAccesiblePath,
    usersPaths,
  };
}
