import styled from '@emotion/styled';

export const SvgContainer = styled.svg`
  height: 40px;
  width: 40px;
  border-radius: 4px;

  & > text {
    font-family: Manrope;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 26px;
  }
`;
