import { THEME_COLORS } from '../../../shared/colors/types';
import { TRANSACTION_ENTITY_STATUSES } from '../constants';
import { TmEntityStatusViewProps } from '../types';

export function getColorByStatus(
  status?: TmEntityStatusViewProps['status']
): keyof THEME_COLORS {
  switch (status) {
    case TRANSACTION_ENTITY_STATUSES.ACTIVE:
      return 'success-deep-4';
    case TRANSACTION_ENTITY_STATUSES.INACTIVE:
      return 'error-deep-3';
    default:
      return 'warning-deep-4';
  }
}
