import { useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { CheckupKindClientCodeResponse } from '../../../../../../../../../../constants';
import { FallbackViewProps, LoaderType } from '../types';

export function useFallbackController(id: FallbackViewProps['name']) {
  const location = useLocation();
  const key = useMemo(() => {
    if (id) {
      return id;
    }
    const array = location.pathname.split('/');
    return array[array.length - 2];
  }, [id]);

  const makeArrayByCount = useCallback(
    (count: number, types?: Record<number, LoaderType['type']>) =>
      Array.from({ length: count }, (v, i) => ({
        type: types?.[i] || 'input',
      })),
    []
  );

  const loadersByCode = useMemo(() => {
    switch (key) {
      case CheckupKindClientCodeResponse.MAXMIND_GEO_IP:
        return makeArrayByCount(1);
      case CheckupKindClientCodeResponse.AID_BLACK_LIST_BUSINESS:
        return makeArrayByCount(2);
      case CheckupKindClientCodeResponse.AID_BLACK_LIST_PERSON:
        return makeArrayByCount(4);
      case CheckupKindClientCodeResponse.COMPLY_ADVANTAGE_AML_COMMON:
        return makeArrayByCount(8, { 4: 'boolean', 6: 'boolean' });
      case CheckupKindClientCodeResponse.COMPLY_ADVANTAGE_AML:
        return makeArrayByCount(9, { 5: 'boolean', 7: 'boolean' });
      case CheckupKindClientCodeResponse.COMPLY_ADVANTAGE_AML_BUSINESS:
        return makeArrayByCount(6, { 4: 'boolean' });
      case CheckupKindClientCodeResponse.SHUFTIPRO_KYB:
        return makeArrayByCount(2);
      case CheckupKindClientCodeResponse.ELLIPTIC_WALLET:
        return makeArrayByCount(2);
      case CheckupKindClientCodeResponse.FINCEN_PERSON:
        return makeArrayByCount(4);
      case CheckupKindClientCodeResponse.FINCEN_BUSINESS:
        return makeArrayByCount(2);
      case CheckupKindClientCodeResponse.UNIT_21_ENTITY_DOC:
        return makeArrayByCount(12, {
          0: 'image',
          1: 'image',
          2: 'image',
          3: 'image',
          4: 'image',
          5: 'image',
        });
      case CheckupKindClientCodeResponse.VERIFF:
        return makeArrayByCount(5, {
          2: 'image',
          3: 'image',
          4: 'image',
        });
      case CheckupKindClientCodeResponse.UNIT_21_ENTITY_ID:
        return makeArrayByCount(11);
      case CheckupKindClientCodeResponse.UNIT_21_KYB:
        return makeArrayByCount(10);
      default:
        return makeArrayByCount(1);
    }
  }, [key, makeArrayByCount]);

  return { loadersByCode };
}
