import gql from 'graphql-tag';

export const CdsCableScreeningChecks = gql`
  query CdsCableScreeningChecks(
    $page: Int
    $per: Int
    $user_id: String
    $company_id: String
  ) {
    cdsCableScreeningChecks(
      page: $page
      per: $per
      user_id: $user_id
      company_id: $user_id
    )
      @rest(
        type: "ResponseCdsCableScreeningChecks"
        path: "/cds/cable/screening_checks?{args}"
      ) {
      data @type(name: "CdsCableScreeningCheck") {
        id
        check_type
        client_id
        company_id
        created_at
        screening_lists
        status
        updated_at
        user_id
      }
      meta {
        count
        page
        items
        pages
      }
      success
    }
  }
`;
