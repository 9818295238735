import gql from 'graphql-tag';

export const CdsCablePeoples = gql`
  query CdsCablePeoples(
    $page: Int
    $per: Int
    $company_id: String
    $submission_id: String
  ) {
    cdsCablePeoples(
      page: $page
      per: $per
      company_id: $company_id
      submission_id: $submission_id
    ) @rest(type: "ResponseCdsCablePeoples", path: "/cds/cable/people?{args}") {
      data @type(name: "CdsCablePeople") {
        id
        citizenship_status
        client_id
        company_id
        company_role
        created_at
        date_of_birth
        email
        entity_links @type(name: "CdsCableEntityLink") {
          id
          case_id
          created_at
          customer_verification_id
          entity_id
          entity_type
          idv_result_id
          profile_id
          submission_id
          submission_index
          updated_at
        }
        forenames
        is_account_owner
        is_authorised_signatory
        is_beneficial_owner
        is_controlling_person
        is_director
        is_email_verified
        is_phone_number_verified
        perc_ownership
        phone_number
        proof_of_address_provided
        source_of_wealth
        submission_index
        surnames
        tax_identification_number
        tax_identification_number_type
        updated_at
        user_id
      }
      meta {
        count
        page
        items
        pages
      }
      success
    }
  }
`;

export const CdsCablePeople = gql`
  query CdsCablePeople($id: String) {
    cdsCablePeople(id: $id)
      @rest(
        type: "ResponseCdsCablePeople"
        path: "/cds/cable/people/{args.id}"
      ) {
      data @type(name: "CdsCablePeople") {
        id
        citizenship_status
        client_id
        company_id
        company_role
        created_at
        date_of_birth
        email
        entity_links @type(name: "CdsCableEntityLink") {
          id
          case_id
          created_at
          customer_verification_id
          entity_id
          entity_type
          idv_result_id
          profile_id
          submission_id
          submission_index
          updated_at
        }
        forenames
        is_account_owner
        is_authorised_signatory
        is_beneficial_owner
        is_controlling_person
        is_director
        is_email_verified
        is_phone_number_verified
        perc_ownership
        phone_number
        proof_of_address_provided
        source_of_wealth
        submission_index
        surnames
        tax_identification_number
        tax_identification_number_type
        updated_at
        user_id
        cable_account_statuses @type(name: "CdsCableAccountStatus") {
          id
          account_status
          case_id
          client_id
          company_id
          created_at
          updated_at
          user_id
        }
        cable_addresses @type(name: "CdsCableAddress") {
          id
          address_lines
          address_type
          city
          client_id
          company_id
          country
          created_at
          entity_template_id
          postal_code
          state
          submission_index
          updated_at
          user_id
        }
        cable_idv_results @type(name: "CdsCableIdvResult") {
          id
          client_id
          company_id
          created_at
          document_birth_date
          document_check_outcome
          document_expiry_date
          document_extracted_information @type(name: "IdenfyRawResultFull") {
            attempt_count
            client @type(name: "IdenfyRawResultClient") {
              country
              date_of_birth
              date_of_issue
              default_country
              document_number
              document_type
              expiry_date
              name
              nationality
              personal_id_number
              sex
              surname
              utility_address
              videocallsession
            }
            client_identity_document
              @type(name: "IdenfyRawResultClientIdentityDocument") {
              doc_address
              doc_country
              doc_date_of_birth
              doc_date_of_issue
              doc_document_number
              doc_document_type
              doc_expiry_date
              doc_license_categories
              doc_mothers_maiden_name
              doc_name
              doc_nationality
              doc_personal_code
              doc_sex
              doc_surname
              document_type
              manual_additional_step_status
              manual_address
              manual_country
              manual_date_of_birth
              manual_date_of_issue
              manual_document_number
              manual_document_type
              manual_expiry_date
              manual_mothers_maiden_name
              manual_mrz_string
              manual_name
              manual_nationality
              manual_personal_code
              manual_sex
              manual_surname
              manual_utility_address
              mrz_address
              mrz_country
              mrz_date_of_birth
              mrz_date_of_issue
              mrz_document_number
              mrz_document_subtype
              mrz_document_type
              mrz_expiry_date
              mrz_license_categories
              mrz_name
              mrz_nationality
              mrz_optional_data
              mrz_personal_code
              mrz_sex
              mrz_string
              mrz_surname
              org_address
              org_authority
              org_birth_place
              org_name
              org_nationality
              org_surname
              urjanet_utility_data
            }
            document_validity
            face_match_result
            finish_time
            manual_document_validity
            manual_face_match_result
            mismatch_tags
            review_time
            start_time
            suspection_reasons
          }
          document_issue_date
          document_number
          document_type
          outcome
          provider_name
          selfie_check_outcome
          updated_at
          user_id
          verification_type
        }
        cable_onboarding_flows @type(name: "CdsCableOnboardingFlow") {
          id
          case_id
          client_id
          company_id
          created_at
          customer_verification_id
          flow_name
          outcome
          updated_at
          user_id
        }
        cable_risk_assessments @type(name: "CdsCableRiskAssessment") {
          id
          assessment_type
          case_id
          client_id
          company_id
          created_at
          risk_band
          risk_rating
          updated_at
          user_id
        }
        cable_screening_checks @type(name: "CdsCableScreeningCheck") {
          id
          check_type
          client_id
          company_id
          created_at
          screening_lists
          status
          updated_at
          user_id
        }
        cable_company @type(name: "CdsCableCompany") {
          id
          client_id
          company_amount_of_initial_deposit
          company_country_of_incorporation
          company_expected_deposit_amount_per_month
          company_expected_incoming_tx_per_month
          company_expected_outgoing_tx_per_month
          company_expected_withdrawal_amount_per_month
          company_first_transaction_completed_at
          company_id
          company_industry_classification_title
          company_initial_deposit_doc_provided
          company_is_licenced
          company_is_nbfi
          company_legal_name
          company_licence_doc_provided
          company_primary_industries
          company_proof_of_address_provided
          company_register_of_directors_provided
          company_register_of_shareholders_provided
          company_registration_document_type
          company_source_of_initial_deposit
          company_tax_residency_doc_provided
          company_trading_name
          company_type
          created_at
          employer_identification_number
          entity_links @type(name: "CdsCableEntityLink") {
            id
            case_id
            created_at
            customer_verification_id
            entity_id
            entity_type
            idv_result_id
            profile_id
            submission_id
            submission_index
            updated_at
          }
          entity_template_id
          foreign_company_registration_number
          submission_index
          updated_at
        }
      }
      success
    }
  }
`;
