import { createContext, useContext } from 'react';
import { HitsContextProps } from './types';

export const HitsContext = createContext<HitsContextProps>({
  checkedAll: false,
  openForm: false,
  openCommentForm: false,
  checkedHitIds: [],
  setOpenForm: () => {},
  setOpenCommentForm: () => {},
  touchHitId: () => {},
  clearHitIds: () => {},
  toggleAll: () => {},
  visibleIds: {},
});

export function useHitsContext() {
  const context = useContext(HitsContext);

  return context;
}
