import gql from 'graphql-tag';
import { RESOLUTION_FRAGMENT } from '../../../fragment/account/resolutions';

export const UpdateResolutionItem = gql`
  ${RESOLUTION_FRAGMENT}
  mutation UpdateResolutionItem(
    $id: String!
    $body: BodyUpdateResolutionItem!
  ) {
    updateResolutionItem(id: $id, body: $body)
      @rest(
        type: "ResolutionUpdate"
        path: "/core/client/resolution_items/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "Resolution") {
        ...ResolutionFragment
      }
      success
    }
  }
`;

export const CreateResolution = gql`
  ${RESOLUTION_FRAGMENT}
  mutation CreateResolution($body: BodyCreateResolution!) {
    createResolution(body: $body)
      @rest(
        type: "ResolutionCreate"
        path: "/core/client/resolution_items"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "Resolution") {
        ...ResolutionFragment
      }
      success
    }
  }
`;

export const DeleteResolution = gql`
  mutation DeleteResolution($id: String!) {
    deleteResolution(id: $id)
      @rest(
        type: "ResolutionDelete"
        path: "/core/client/resolution_items/{args.id}"
        method: "DELETE"
      ) {
      success
    }
  }
`;
