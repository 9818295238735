import { Container } from './styles';
import { ActionsViewProps } from './types';
import { MarkAsRead, Pinned } from './components';

export function Actions({ notification }: ActionsViewProps) {
  return (
    <Container
      alignItems="center"
      gap="8"
      gridTemplateColumns="12px max-content"
    >
      <Pinned notification={notification} />
      <MarkAsRead notification={notification} />
    </Container>
  );
}
