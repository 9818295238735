import { LoadableFunctionalComponent } from '@aid-module/ui';
import loadable from '@loadable/component';
import { Fallback, FormContainer } from './components';

export const CreateBalance = loadable(() => import('./component'), {
  resolveComponent: (components) => components.CreateBalanceComponent,
  fallback: (
    <FormContainer isLoading>
      <Fallback />
    </FormContainer>
  ),
}) as LoadableFunctionalComponent<{}>;
