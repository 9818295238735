import styled from '@emotion/styled';
import { getColor, getSpace } from '../../../../utils';
import { FlexContainer, GridContainer } from '../../../../containers';

export const Container = styled(GridContainer)`
  border: 1px solid ${getColor('main-deep-4')};
  border-radius: 8px;
  overflow: hidden;
  background: ${getColor('main-deep-1')};
  overflow: hidden;
  cursor: pointer;
`;

export const IconContainer = styled(FlexContainer)`
  background: ${getColor('main-deep-4')};
  height: 100%;
`;

export const ContentContainer = styled(GridContainer)`
  padding: ${getSpace('20')} ${getSpace('60')} ${getSpace('20')}
    ${getSpace('20')};
`;

export const DescriptionContainer = styled(GridContainer)`
  padding: ${getSpace('20')};
`;
