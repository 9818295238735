import { TYPES } from '@aid-package/api';
import { getShow, usePermissionsContext } from '@aid-package/context';
import { Check } from '@aid-package/context';
import { useCallback } from 'react';
import { CheckupKindPermissions } from './constants';

export function useCheckupSelectController() {
  const permissions = usePermissionsContext();

  const reduceData = useCallback(
    (checkup: TYPES.CheckupKindsQuery['checkupKinds']['data'][0]) => {
      const check = CheckupKindPermissions[checkup.code];
      if (check) {
        const canShow = getShow(permissions, check as Check, true);
        if (canShow) {
          return {
            label: checkup.name,
            value: checkup.code,
            checkup,
          };
        }
        return;
      }
      return {
        label: checkup.name,
        value: checkup.code,
        checkup,
      };
    },
    [permissions]
  );

  return { reduceData };
}
