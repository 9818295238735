import { Logo, Navigation } from './components';
import { Container, LogoContainer } from './styles';

export function Menu() {
  return (
    <Container gridTemplateRows="max-content 1fr">
      <LogoContainer flex={1} justifyContent="center">
        <Logo />
      </LogoContainer>
      <Navigation />
    </Container>
  );
}
