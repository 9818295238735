import gql from 'graphql-tag';

export const Dispositions = gql`
  query Dispositions(
    $alert_id: String
    $page: Int
    $per: Int
    $q: String
  ) {
    dispositions(
      alert_id: $alert_id
      page: $page
      per: $per
      q: $q
    )
    @rest(
      type: "ResponseDispositions"
      path: "/tm/dispositions?{args}"
    ) {
      data @type(name: "Disposition") {
        id
        name
        code
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;
