import { useRef } from 'react';
import { Controller } from 'react-hook-form';
import { Blockchain, CryptocurrencySymbol } from './components';
import { FormRenderProps } from '../../../../types';
import { CryptocurrencyViewProps } from './types';
import { GridContainer } from '@aid-module/ui';

export function Cryptocurrency({ field }: CryptocurrencyViewProps) {
  const renderCryptocurrency = useRef(
    ({
      field: { value, onChange },
      fieldState: { error },
    }: FormRenderProps<string>) => {
      return (
        <CryptocurrencySymbol
          field={field}
          error={error}
          value={value}
          onChange={onChange}
        />
      );
    }
  );

  return (
    <GridContainer gap="20">
      <Controller name={field.code} render={renderCryptocurrency.current} />
      <Controller
        name="blockchain"
        render={({ field, fieldState, formState }) => (
          <Blockchain
            field={field}
            fieldState={fieldState}
            formState={formState}
          />
        )}
      />
    </GridContainer>
  );
}
