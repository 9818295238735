import { SuccessButton } from '../../../../../../../Button';
import { DefaultBodyViewProps } from '../../types';
import { Container } from '../Container';

export function Success({
  title,
  body,
  onClickConfirm,
  confirmActionTitle,
}: DefaultBodyViewProps) {
  return (
    <Container gap="32" title={title} body={body}>
      <SuccessButton
        text={confirmActionTitle || 'OK'}
        onClick={onClickConfirm}
      />
    </Container>
  );
}
