export const ONBOARDING_FEE_STATUSES = {
  PAID: 'paid',
  UNPAID: 'unpaid',
  WITHOUT_PAYMENT: 'without_payment',
};

export const ONBOARDING_FEE_DISPLAY_STATUSES = {
  [ONBOARDING_FEE_STATUSES.PAID]: 'Paid',
  [ONBOARDING_FEE_STATUSES.UNPAID]: 'Unpaid',
  [ONBOARDING_FEE_STATUSES.WITHOUT_PAYMENT]: 'Without payment',
};
