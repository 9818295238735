import { useLinkController } from './controller';
import { DefaultLink } from './styles';
import { LinkViewProps } from './types';

export function Link({ value, payloadData, size }: LinkViewProps) {
  const { path, onClick } = useLinkController(value, payloadData);
  return (
    <DefaultLink
      size={size}
      weight="Bold"
      color="main-deep-4"
      onClick={onClick}
      text={payloadData?.body_attributes?.[value]?.display_name}
      to={`/${payloadData?.client_id}${path}${payloadData?.body_attributes?.[value]?.id}`}
    />
  );
}
