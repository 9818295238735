import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { MultiplePathsLinkContainerViewProps } from '../types';

export function useMultiplePathsLinkContainerController(
  paths: MultiplePathsLinkContainerViewProps['paths']
) {
  const { pathname } = useLocation();

  const isActive = useMemo(() => {
    return paths.some((path) => {
      return pathname.startsWith(path);
    });
  }, [pathname, paths]);

  return {
    isActive,
  };
}
