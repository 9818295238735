import { FlexContainer, GridContainer } from '../../containers';
import { IconLoader } from '../../shared';
import { Icon } from '../Icon';
import { Text } from '../Text';
import { useEmptyController } from './controller';
import { Container, ContentContainer } from './styles';
import { EmptyViewProps } from './types';

export function Empty({
  title,
  subtitle,
  className,
  type,
  withoutBorder,
}: EmptyViewProps) {
  const { background, border, loaderBackground, loaderForeground } =
    useEmptyController(type);
  return (
    <Container
      background={background}
      border={!withoutBorder ? border : undefined}
      loaderBackground={loaderBackground}
      loaderForeground={loaderForeground}
      className={className}
    >
      <ContentContainer
        justifyContent="center"
        alignItems="center"
        gridTemplateColumns="1fr"
      >
        <GridContainer
          gridTemplateColumns="1fr"
          justifyContent="center"
          gap="12"
        >
          <FlexContainer justifyContent="center" alignItems="center">
            <Icon glyph="empty" size={40} fallback={<IconLoader size={40} />} />
          </FlexContainer>
          <GridContainer gap="8">
            <Text
              text={title || 'NO RESULTS'}
              color="main-deep-4"
              align="center"
              weight="VeryBig"
            />
            {subtitle && (
              <Text
                text={subtitle}
                color="gray-deep-4"
                align="center"
                size={10}
                weight="Thin"
              />
            )}
          </GridContainer>
        </GridContainer>
      </ContentContainer>
    </Container>
  );
}
