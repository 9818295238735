import { useCallback } from 'react';
import { useEnabledModel } from '../model';
import { EnabledViewProps } from '../types';

export function useEnabledController(
  id: EnabledViewProps['id'],
  mutation: EnabledViewProps['mutation']
) {
  const { toggleEnabled, loading } = useEnabledModel(mutation);

  const onChange = useCallback(
    (value?: boolean) => {
      if (id) {
        toggleEnabled(id, !!value);
      }
    },
    [id]
  );

  return { loading, onChange };
}
