import { THEME_COLORS } from '../../../shared/colors/types';
import { CUSTOMER_MESSAGE_STATUSES } from '../constants';
import { CustomerMessageStatusViewProps } from '../types';

export function getColorByStatus(
  status?: CustomerMessageStatusViewProps['status']
): keyof THEME_COLORS {
  switch (status) {
    case CUSTOMER_MESSAGE_STATUSES.SENT:
      return 'success-deep-4';
    case CUSTOMER_MESSAGE_STATUSES.DRAFT:
      return 'gray-deep-3';
    default:
      return 'warning-deep-4';
  }
}
