import { PropsWithChildren } from 'react';
import { useCheckPermission } from '../CheckPermission';
import { ProtectedViewProps } from './types';

export function Protected({
  check,
  strong = false,
  children,
  empty = null,
}: PropsWithChildren<ProtectedViewProps>) {
  const { canShow } = useCheckPermission(check, strong);
  if (canShow) {
    return <>{children}</>;
  }

  return empty;
}
