import { gql } from '@apollo/client';

export const CreateTransactionScoringMatrix = gql`
  mutation CreateTransactionScoringMatrix(
    $body: BodyCreateTransactionScoringMatrix!
  ) {
    createTransactionScoringMatrix(body: $body)
      @rest(
        type: "CreateTransactionScoringMatrix"
        path: "/tm/scoring/matrices"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "TransactionScoringMatrix") {
        id
        client_channel
        client_type
        created_at
        description
        enabled
        name
        updated_at
        is_test
      }
      success
    }
  }
`;

export const UpdateTransactionScoringMatrix = gql`
  mutation UpdateTransactionScoringMatrix(
    $id: String!
    $body: BodyUpdateTransactionScoringMatrix!
  ) {
    updateTransactionScoringMatrix(id: $id, body: $body)
      @rest(
        type: "UpdateTransactionScoringMatrix"
        path: "/tm/scoring/matrices/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "TransactionScoringMatrix") {
        id
        client_channel
        client_type
        created_at
        description
        enabled
        name
        updated_at
        is_test
      }
      success
    }
  }
`;

export const DeleteTransactionScoringMatrix = gql`
  mutation DeleteTransactionScoringMatrix($id: String!) {
    deleteTransactionScoringMatrix(id: $id)
      @rest(
        type: "DeleteTransactionScoringMatrix"
        path: "/tm/scoring/matrices/{args.id}"
        method: "DELETE"
      ) {
      success
    }
  }
`;

export const ChangeEnabledTransactionScoringMatrix = gql`
  mutation ChangeEnabledTransactionScoringMatrix(
    $id: String!
    $body: BodyChangeEnabledTransactionScoringMatrix!
  ) {
    changeEnabledTransactionScoringMatrix(id: $id, body: $body)
      @rest(
        type: "ChangeEnabledTransactionScoringMatrix"
        path: "/tm/scoring/matrices/{args.id}/change_enabled"
        bodyKey: "body"
        method: "PATCH"
      ) {
      data @type(name: "TransactionScoringMatrix") {
        id
        client_channel
        client_type
        created_at
        description
        enabled
        name
        updated_at
        is_test
      }
      errors
      success
    }
  }
`;

export const ChangeTestTransactionScoringMatrix = gql`
  mutation ChangeTestTransactionScoringMatrix(
    $id: String!
    $body: BodyChangeTestTransactionScoringMatrix!
  ) {
    changeTestTransactionScoringMatrix(id: $id, body: $body)
      @rest(
        type: "ChangeTestTransactionScoringMatrix"
        path: "/tm/scoring/matrices/{args.id}/change_is_test"
        bodyKey: "body"
        method: "PATCH"
      ) {
      data @type(name: "TransactionScoringMatrix") {
        id
        client_channel
        client_type
        created_at
        description
        enabled
        name
        updated_at
        is_test
      }
      success
    }
  }
`;

export const RunTransactionScoringMatrix = gql`
  mutation RunTransactionScoringMatrix(
    $body: BodyRunTransactionScoringMatrix!
  ) {
    runTransactionScoringMatrix(body: $body)
      @rest(
        type: "RunTransactionScoringMatrix"
        path: "/tm/scoring/matrices/run_matrix"
        bodyKey: "body"
        method: "POST"
      ) {
      success
    }
  }
`;

export const DownloadTransactionScoringMatrixXlsx = gql`
  mutation DownloadTransactionScoringMatrixXlsx(
    $body: BodyRunTransactionScoringMatrix!
  ) {
    downloadTransactionScoringMatrixReportXlsx(body: $body)
      @rest(
        type: "DownloadTransactionsScoringMatrixXlsx"
        path: "/tm/scoring/matrices/run_matrix/xlsx"
        bodyKey: "body"
        method: "POST"
      )
  }
`;

export const RunTransactionsScoringMatrix = gql`
  mutation RunTransactionsScoringMatrix(
    $body: BodyRunTransactionsScoringMatrix!
  ) {
    runTransactionsScoringMatrix(body: $body)
      @rest(
        type: "RunTransactionsScoringMatrix"
        path: "/tm/scoring/matrices/run_many_matrices"
        bodyKey: "body"
        method: "POST"
      ) {
      success
    }
  }
`;

export const DownloadTransactionsScoringMatrixXlsx = gql`
  mutation DownloadTransactionsScoringMatrixXlsx(
    $body: BodyRunTransactionsScoringMatrix!
  ) {
    downloadTransactionsScoringMatrixReportXlsx(body: $body)
      @rest(
        type: "DownloadTransactionsScoringMatrixXlsx"
        path: "/tm/scoring/matrices/run_matrix/xlsx"
        bodyKey: "body"
        method: "POST"
      )
  }
`;
