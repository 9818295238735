import { createContext, useContext } from 'react';
import { StructureContextParams } from './types';

export const StructureContext = createContext<StructureContextParams>({
  selected: {},
  onSelect: () => {},
});

export function useStructureContext() {
  const context = useContext(StructureContext);

  return context;
}
