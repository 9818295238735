export const TRANSACTION_CHECKUP_RESULT_STATUSES = {
  DRAFT: 'draft',
  ACCEPTED: 'accepted',
  COMPLETED: 'completed',
  SUCCESS: 'success',
  FAILED: 'failed',
  REJECTED: 'rejected',
  NEW: 'new',
};

export const TRANSACTION_CHECKUP_RESULT_DISPLAY_STATUSES = {
  [TRANSACTION_CHECKUP_RESULT_STATUSES.DRAFT]: 'Draft',
  [TRANSACTION_CHECKUP_RESULT_STATUSES.ACCEPTED]: 'Accepted',
  [TRANSACTION_CHECKUP_RESULT_STATUSES.COMPLETED]: 'Completed',
  [TRANSACTION_CHECKUP_RESULT_STATUSES.SUCCESS]: 'Success',
  [TRANSACTION_CHECKUP_RESULT_STATUSES.FAILED]: 'Failed',
  [TRANSACTION_CHECKUP_RESULT_STATUSES.REJECTED]: 'Rejected',
  [TRANSACTION_CHECKUP_RESULT_STATUSES.NEW]: 'New',
};
