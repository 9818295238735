import {
  SideWidgenProvider,
  SideWidgetComponentViewProps,
} from '@aid-module/ui';

export function Header({ position }: SideWidgetComponentViewProps) {
  return (
    <SideWidgenProvider.Header label="CREATE SCREENING" position={position} />
  );
}
