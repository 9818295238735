import { SelectOption } from '@aid-module/ui';

export const RetentionPeriodsOptions: SelectOption[] = [
  {
    label: '3 days',
    value: '3',
  },
  {
    label: '7 days',
    value: '7',
  },
  {
    label: '10 days',
    value: '10',
  },
  {
    label: '14 days',
    value: '14',
  },
  {
    label: '30 days',
    value: '30',
  },
  {
    label: '180 days',
    value: '180',
  },
  {
    label: '1 year',
    value: '365',
  },
  {
    label: '2 years',
    value: '730',
  },
  {
    label: '5 years',
    value: '1825',
  },
  {
    label: '8 years',
    value: '2920',
  },
  {
    label: '10 years',
    value: '3650',
  },
  {
    label: 'Indefinite',
    value: 'null',
  },
];
