import loadable from '@loadable/component';
import { InputLoader } from '../../shared';
import { TextInputViewProps } from './component/types';
import { LoadableFunctionalComponent } from '../../utils';
import { RefAttributes } from 'react';
export * from './component/types';

export const TextInput = loadable(() => import('./component'), {
  resolveComponent: (components) => components.TextInput,
  fallback: <InputLoader />,
}) as LoadableFunctionalComponent<
  TextInputViewProps & RefAttributes<HTMLInputElement>
>;
