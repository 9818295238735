import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { useCheckAccountController } from './controller';
import { ErrorCritical } from '@aid-module/ui';

export function CheckAccounts({ children }: PropsWithChildren<{}>) {
  const { isAccountsExist, client } = useCheckAccountController();

  if (isAccountsExist) {
    return <>{children}</>;
  }

  if (client) {
    return <Navigate to="/onboarding" />;
  }

  return (
    <ErrorCritical
      type="global"
      description="Something went wrong. We see this error and will try to fix it as soon as possible."
    />
  );
}
