import { useComment } from '@aid-module/ui';
import { useCheckupContext } from '../../../../../../../../../../../context';
import { MouseEvent, useCallback } from 'react';
import { CommentsViewProps } from '../types';
import { PERMISSIONS, useCheckPermission } from '@aid-package/context';

export function useCommentsController(
  hit: CommentsViewProps['hit'],
  checkupId: CommentsViewProps['checkupId']
) {
  const { id: checkup_id } = useCheckupContext();
  const { canShow } = useCheckPermission(PERMISSIONS.SEE_CM_COMMENTS, true);
  const { showComment } = useComment();

  const onClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      showComment({
        type: '/core/client/comments',
        subject_id: checkupId || checkup_id,
        subject_type: 'Checkup',
        subject_entity_type: 'ComplyAdvantage',
        subject_entity_id: hit.doc?.id,
        title: hit.doc?.name || '',
        entity: 'Result',
        check: PERMISSIONS.MANAGE_CM_COMMENTS,
      });
    },
    [showComment]
  );

  return { onClick, disabled: !canShow };
}
