import { FEATURES, PERMISSIONS } from '@aid-package/context';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Stats } from './stats';
import { UsersRegister, UserUpdate } from './users';
import { RolesRegister, RoleCreate, RoleUpdate } from './roles';
import { BalanceRegister, CreateBalance } from './balance';
import { TagsRegister, TagCreate, TagUpdate } from './tags';
import {
  BlacklistReasonsCreate,
  BlacklistReasonsRegister,
  BlacklistReasonsUpdate,
} from './blacklistReasons';
import {
  ErrorPages,
  ProtectedFeaturesRoute,
  ProtectedRoute,
} from '@aid-module/ui';
import {
  ResolutionCreate,
  ResolutionsRegister,
  ResolutionUpdate,
} from './resolutions';
import {
  RiskScoringTemplateCreate,
  RiskScoringTemplatesRegister,
  RiskScoringTemplateUpdate,
} from './riskScoringTemplates';
import {
  CaseRejectReasonsRegister,
  CaseRejectReasonCreate,
  CaseRejectReasonUpdate,
} from './caseRejectReasons';
import { InviteDescription, InvitessRegister } from './invites';
import { DataRetentionConfigsRegister, DataRetentionPlansRegister } from './dataRetention';

export function AccountRouter() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute
            check={[
              PERMISSIONS.CLIENT_INVITE_USERS,
              PERMISSIONS.CLIENT_MANAGE,
              PERMISSIONS.SEE_BALANCE,
              PERMISSIONS.REFILL_BALANCE,
              PERMISSIONS.MANAGE_BLACKLIST_PROFILES,
              PERMISSIONS.MANAGE_ADDITIONAL_RISK_SCORING_TEMPLATES,
              PERMISSIONS.MANAGE_RESOLUTION_ITEMS,
              PERMISSIONS.MANAGE_CASE_REJECTION_REASONS,
            ]}
          />
        }
      >
        <Route index element={<Stats />} />
        <Route
          path="invites"
          element={<ProtectedRoute check={PERMISSIONS.CLIENT_INVITE_USERS} />}
        >
          <Route index element={<InvitessRegister />} />
          <Route path=":id" element={<InviteDescription />} />
        </Route>
        <Route
          path="blacklist_reasons"
          element={
            <ProtectedRoute check={PERMISSIONS.MANAGE_BLACKLIST_PROFILES} />
          }
        >
          <Route index element={<BlacklistReasonsRegister />} />
          <Route
            element={
              <ProtectedFeaturesRoute check={FEATURES.CUSTOMIZATION_TOOLS} />
            }
          >
            <Route path="form" element={<BlacklistReasonsCreate />} />
          </Route>
          <Route
            path="form/:blacklist_reason_id"
            element={<BlacklistReasonsUpdate />}
          />
        </Route>
        <Route
          path="users"
          element={<ProtectedRoute check={PERMISSIONS.CLIENT_MANAGE} />}
        >
          <Route index element={<UsersRegister />} />
          <Route path=":user_id" element={<UserUpdate />} />
        </Route>
        <Route
          path="roles"
          element={<ProtectedRoute check={PERMISSIONS.CLIENT_MANAGE} />}
        >
          <Route index element={<RolesRegister />} />
          <Route path="form" element={<RoleCreate />} />
          <Route path="form/:role_id" element={<RoleUpdate />} />
        </Route>
        <Route
          path="balance"
          element={
            <ProtectedRoute
              check={[PERMISSIONS.SEE_BALANCE, PERMISSIONS.REFILL_BALANCE]}
            />
          }
        >
          <Route index element={<BalanceRegister />} />
          <Route path="form" element={<CreateBalance />} />
        </Route>
        <Route
          path="form_tags"
          element={<ProtectedRoute check={PERMISSIONS.CLIENT_MANAGE} />}
        >
          <Route index element={<TagsRegister />} />
          <Route
            element={<ProtectedFeaturesRoute check={FEATURES.FORM_BUILDER} />}
          >
            <Route path="form" element={<TagCreate />} />
          </Route>
          <Route path="form/:form_tag_id" element={<TagUpdate />} />
        </Route>
        <Route
          path="risk_scoring_templates"
          element={
            <ProtectedRoute
              check={PERMISSIONS.MANAGE_ADDITIONAL_RISK_SCORING_TEMPLATES}
            />
          }
        >
          <Route index element={<RiskScoringTemplatesRegister />} />
          <Route
            element={
              <ProtectedFeaturesRoute check={FEATURES.CASE_RISK_SCORING} />
            }
          >
            <Route path="form" element={<RiskScoringTemplateCreate />} />
          </Route>
          <Route
            path="form/:risk_scoring_template_id"
            element={<RiskScoringTemplateUpdate />}
          />
        </Route>
        <Route
          path="resolutions"
          element={
            <ProtectedRoute check={PERMISSIONS.MANAGE_RESOLUTION_ITEMS} />
          }
        >
          <Route index element={<ResolutionsRegister />} />
          <Route
            element={
              <ProtectedFeaturesRoute check={FEATURES.CUSTOMIZATION_TOOLS} />
            }
          >
            <Route path="form" element={<ResolutionCreate />} />
          </Route>
          <Route path="form/:resolution_id" element={<ResolutionUpdate />} />
        </Route>
        <Route
          path="case_rejection_reasons"
          element={
            <ProtectedRoute check={PERMISSIONS.MANAGE_CASE_REJECTION_REASONS} />
          }
        >
          <Route index element={<CaseRejectReasonsRegister />} />
          <Route
            element={
              <ProtectedFeaturesRoute check={FEATURES.CUSTOMIZATION_TOOLS} />
            }
          >
            <Route path="form" element={<CaseRejectReasonCreate />} />
          </Route>
          <Route
            path="form/:case_rejection_reason_id"
            element={<CaseRejectReasonUpdate />}
          />
        </Route>
        <Route
          path="data"
          element={<ProtectedRoute check={PERMISSIONS.MANAGE_DATA_RETENTION} />}
        >
          <Route index element={<Navigate to="retention_configs" />} />
          <Route path="retention_configs" element={<DataRetentionConfigsRegister />} />
          <Route path="retention_plans" element={<DataRetentionPlansRegister />} />
        </Route>
        <Route path="*" element={<ErrorPages type="local" code={404} />} />
      </Route>
    </Routes>
  );
}
