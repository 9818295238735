import loadable from '@loadable/component';
import { LoadableFunctionalComponent } from '../../../../../utils';
import { PropsWithChildren } from 'react';
import { SideWidgetHeaderViewProps } from './component/types';
import { Fallback } from './components';

export const Header = loadable(() => import('./component'), {
  resolveComponent: (components) => components.HeaderComponent,
  fallback: <Fallback />,
}) as LoadableFunctionalComponent<PropsWithChildren<SideWidgetHeaderViewProps>>;
