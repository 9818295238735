import { THEME_COLORS } from '../../../shared/colors/types';
import { TRANSACTION_STATUSES } from '../constants';
import { TmEventStatusViewProps } from '../types';

export function getColorByStatus(
  status?: TmEventStatusViewProps['status']
): keyof THEME_COLORS {
  switch (status) {
    case TRANSACTION_STATUSES.COMPLETED:
      return 'success-deep-4';
    case TRANSACTION_STATUSES.FAILED:
    case TRANSACTION_STATUSES.REJECTED:
      return 'error-deep-3';
    case TRANSACTION_STATUSES.OFAC_CHECKING:
    case TRANSACTION_STATUSES.PENDING:
      return 'warning-deep-4';
    default:
      return 'warning-deep-4';
  }
}
