import { useTimerLogoutController } from './controller';
import { Timer } from './components';

export function TimerLogout() {
  const { parsedTime } = useTimerLogoutController();

  if (parsedTime) {
    return <Timer parsedTime={parsedTime} />;
  }

  return null;
}
