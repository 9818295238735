import { PropsWithChildren, useCallback } from 'react';
import { Modal } from './components';
import { ConfirmModalContext } from './context';
import { useConfirmModalProviderController } from './controller';
import { ModalController } from './ModalController';

export function ConfirmModalProvider({ children }: PropsWithChildren<{}>) {
  const { value, modals } = useConfirmModalProviderController();

  const renderModals = useCallback((controller: ModalController) => {
    return <Modal key={controller.uuid} controller={controller} />;
  }, []);

  return (
    <>
      <ConfirmModalContext.Provider value={value.current}>
        {children}
      </ConfirmModalContext.Provider>
      {Object.values(modals).map(renderModals)}
    </>
  );
}
