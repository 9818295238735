import { Routes, Route } from 'react-router-dom';
import { FEATURES, PERMISSIONS } from '@aid-package/context';
import { InstantCheckupsDashboard } from './InstantCheckupsDashboard';
import { TranslationsRegister, TranslationCreate } from './Translations';
import {
  CheckUpKindsRegister,
  CheckUpsKindsCard,
} from './Screenings/checkUpKinds';
import {
  CheckupDescription,
  ServiceCheckUpsRegister,
} from './Screenings/serviceCheckUps';
import { InfoHitCard } from './Checkup/component/components/InfoHitCard';
import { CheckupCreateWidget } from './CheckupCreateWidget';
import { Checkup } from './Checkup';
import {
  ProtectedRoute,
  ErrorPages,
  ProtectedFeaturesRoute,
} from '@aid-module/ui';
import { FincentLists } from './Screenings/MassScreening/FincenLists';
import {
  MassScreening,
  MassScreeningCard,
} from './Screenings/MassScreening/MassScreenings';
import { MassScreeningDetails } from './Screenings/MassScreening/MassScreeningDetails';
import { PagginationRegister } from './Screenings/PagginationRegister';
import { ProhibitedCountriesRegister } from './ProhibitedCountries';
import {
  BlacklistedDeviceInfosRegister,
  BlacklistedDeviceInfoCard,
} from './BlacklistedDeviceInfos';
import { StopWordCreate, StopWordUpdate, StopWordsRegister } from './StopWords';
import { AllFallback } from './Screenings/AllFallback';

export function CheckupRouter() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute
            check={[
              PERMISSIONS.CLIENT_MANAGE,
              PERMISSIONS.CHECKUPS_PERFORM_INSTANT,
              PERMISSIONS.AML_BUSINESS,
              PERMISSIONS.AML_INDIVIDUAL,
              PERMISSIONS.BUS_REGISTRY,
              PERMISSIONS.ELLIPTIC,
              PERMISSIONS.UNIT_21_KYB,
              PERMISSIONS.UNIT_21_KYB_DOC,
              PERMISSIONS.UNIT_21_KYB_US,
              PERMISSIONS.UNIT_21_KYB_WORLD,
              PERMISSIONS.CA_AML_INDIVIDUAL,
              PERMISSIONS.CA_AML_BUSINESS,
              PERMISSIONS.FINCEN_LIST,
              PERMISSIONS.FINCEN_INDIVIDUAL,
              PERMISSIONS.FINCEN_BUSINESS,
              PERMISSIONS.BLACKLIST_BUSINESS,
              PERMISSIONS.BLACKLIST_INDIVIDUAL,
              PERMISSIONS.MANAGE_PROHIBITED_COUNTRIES,
              PERMISSIONS.MANAGE_BLACKLISTED_DEVICE_INFO,
              PERMISSIONS.MANAGE_STOP_WORD_FINCEN,
              PERMISSIONS.SEE_SHUFTIPRO_AML_BUSINESS,
              PERMISSIONS.SEE_SHUFTIPRO_AML_INDIVIDUAL,
            ]}
          />
        }
      >
        <Route index element={<InstantCheckupsDashboard />} />
        <Route
          path="translations"
          element={<ProtectedRoute check={PERMISSIONS.CLIENT_MANAGE} />}
        >
          <Route index element={<TranslationsRegister />} />
          <Route
            element={<ProtectedFeaturesRoute check={FEATURES.TRANSLATION} />}
          >
            <Route path="form" element={<TranslationCreate />} />
          </Route>
        </Route>
        <Route
          path="stop_words"
          element={
            <ProtectedRoute check={PERMISSIONS.MANAGE_STOP_WORD_FINCEN} />
          }
        >
          <Route index element={<StopWordsRegister />} />
          <Route
            element={
              <ProtectedFeaturesRoute check={FEATURES.CUSTOMIZATION_TOOLS} />
            }
          >
            <Route path="form" element={<StopWordCreate />} />
          </Route>

          <Route path="form/:id" element={<StopWordUpdate />} />
        </Route>
        <Route
          path="prohibited_countries"
          element={
            <ProtectedRoute check={PERMISSIONS.MANAGE_PROHIBITED_COUNTRIES} />
          }
        >
          <Route index element={<ProhibitedCountriesRegister />} />
        </Route>
        <Route
          path="blacklisted_device_infos"
          element={
            <ProtectedRoute
              check={PERMISSIONS.MANAGE_BLACKLISTED_DEVICE_INFO}
            />
          }
        >
          <Route index element={<BlacklistedDeviceInfosRegister />} />
          <Route
            path=":blacklisted_device_info_id"
            element={<BlacklistedDeviceInfoCard />}
          />
        </Route>
        <Route
          path="checkups_kinds"
          element={
            <ProtectedRoute
              check={[
                PERMISSIONS.CHECKUPS_PERFORM_INSTANT,
                PERMISSIONS.AML_BUSINESS,
                PERMISSIONS.AML_INDIVIDUAL,
                PERMISSIONS.BUS_REGISTRY,
                PERMISSIONS.ELLIPTIC,
                PERMISSIONS.UNIT_21_KYB,
                PERMISSIONS.UNIT_21_KYB_DOC,
                PERMISSIONS.UNIT_21_KYB_US,
                PERMISSIONS.UNIT_21_KYB_WORLD,
                PERMISSIONS.CA_AML_INDIVIDUAL,
                PERMISSIONS.CA_AML_BUSINESS,
                PERMISSIONS.FINCEN_LIST,
                PERMISSIONS.FINCEN_INDIVIDUAL,
                PERMISSIONS.FINCEN_BUSINESS,
                PERMISSIONS.BLACKLIST_BUSINESS,
                PERMISSIONS.BLACKLIST_INDIVIDUAL,
                PERMISSIONS.SEE_SHUFTIPRO_AML_BUSINESS,
                PERMISSIONS.SEE_SHUFTIPRO_AML_INDIVIDUAL,
              ]}
            />
          }
        >
          <Route index element={<CheckUpKindsRegister />} />
          <Route path=":checkup_kind_id" element={<CheckUpsKindsCard />} />
        </Route>
        <Route
          path="fincen_lists"
          element={<ProtectedRoute check={PERMISSIONS.FINCEN_LIST} />}
        >
          <Route index element={<FincentLists />} />
          <Route
            path="mass_screenings_by_fincen_id/:fincen_list_id"
            element={<MassScreening />}
          />
          <Route
            path="mass_screenings_by_fincen_id/:fincen_list_id/mass_screenings/:mass_screen_id"
            element={<MassScreeningCard />}
          />
          <Route
            path="mass_screenings_by_fincen_id/:fincen_list_id/mass_screenings/:mass_screen_id/screening/:mass_screen_details_id"
            element={<MassScreeningDetails />}
          />
        </Route>
        <Route
          path="instant_checkups"
          element={
            <ProtectedRoute
              check={[
                PERMISSIONS.CHECKUPS_PERFORM_INSTANT,
                PERMISSIONS.AML_BUSINESS,
                PERMISSIONS.AML_INDIVIDUAL,
                PERMISSIONS.BUS_REGISTRY,
                PERMISSIONS.ELLIPTIC,
                PERMISSIONS.UNIT_21_KYB,
                PERMISSIONS.UNIT_21_KYB_DOC,
                PERMISSIONS.UNIT_21_KYB_US,
                PERMISSIONS.UNIT_21_KYB_WORLD,
                PERMISSIONS.CA_AML_INDIVIDUAL,
                PERMISSIONS.CA_AML_BUSINESS,
                PERMISSIONS.FINCEN_LIST,
                PERMISSIONS.FINCEN_INDIVIDUAL,
                PERMISSIONS.FINCEN_BUSINESS,
                PERMISSIONS.BLACKLIST_BUSINESS,
                PERMISSIONS.BLACKLIST_INDIVIDUAL,
                PERMISSIONS.SEE_SHUFTIPRO_AML_BUSINESS,
                PERMISSIONS.SEE_SHUFTIPRO_AML_INDIVIDUAL,
              ]}
            />
          }
        >
          <Route
            path="all"
            element={
              <ProtectedRoute check={PERMISSIONS.CHECKUPS_PERFORM_INSTANT} />
            }
          >
            <Route
              index
              element={
                <ServiceCheckUpsRegister key="all" fallback={<AllFallback />} />
              }
            />
            <Route path="form">
              <Route path=":id" element={<CheckupDescription />} />
              <Route path=":id/info/:hit_id" element={<InfoHitCard />} />
            </Route>
          </Route>
          <Route
            path="comply_advantage_aml"
            element={<ProtectedRoute check={PERMISSIONS.CA_AML_INDIVIDUAL} />}
          >
            <Route
              index
              element={
                <PagginationRegister
                  key="comply_advantage_aml"
                  name="comply_advantage_aml"
                />
              }
            />
            <Route path="form">
              <Route index />
              <Route path=":id" element={<CheckupDescription />} />
              <Route path=":id/info/:hit_id" element={<InfoHitCard />} />
            </Route>
          </Route>
          <Route
            path="comply_advantage_aml_common/form/:id"
            element={
              <ProtectedRoute
                check={[
                  PERMISSIONS.CA_AML_INDIVIDUAL,
                  PERMISSIONS.CA_AML_BUSINESS,
                ]}
              />
            }
          >
            <Route index element={<CheckupDescription />} />
            <Route path="info/:hit_id" element={<InfoHitCard />} />
          </Route>
          <Route
            path="comply_advantage_aml_business"
            element={<ProtectedRoute check={PERMISSIONS.CA_AML_BUSINESS} />}
          >
            <Route
              index
              element={
                <PagginationRegister
                  key="comply_advantage_aml_business"
                  name="comply_advantage_aml_business"
                />
              }
            />
            <Route path="form">
              <Route index />
              <Route path=":id" element={<CheckupDescription />} />
              <Route path=":id/info/:hit_id" element={<InfoHitCard />} />
            </Route>
          </Route>
          <Route
            path="fincen_person"
            element={<ProtectedRoute check={PERMISSIONS.FINCEN_INDIVIDUAL} />}
          >
            <Route
              index
              element={
                <PagginationRegister key="fincen_person" name="fincen_person" />
              }
            />
            <Route path="form">
              <Route path=":id" element={<CheckupDescription />} />
            </Route>
          </Route>
          <Route
            path="fincen_business"
            element={<ProtectedRoute check={PERMISSIONS.FINCEN_BUSINESS} />}
          >
            <Route
              index
              element={
                <PagginationRegister
                  key="fincen_business"
                  name="fincen_business"
                />
              }
            />
            <Route path="form">
              <Route path=":id" element={<CheckupDescription />} />
            </Route>
          </Route>
          <Route
            path="blacklisted_profiles_person"
            element={
              <ProtectedRoute check={PERMISSIONS.BLACKLIST_INDIVIDUAL} />
            }
          >
            <Route
              index
              element={
                <ServiceCheckUpsRegister
                  key="blacklisted_profiles_person"
                  name="blacklisted_profiles_person"
                />
              }
            />
            <Route path="form">
              <Route path=":id" element={<CheckupDescription />} />
            </Route>
          </Route>
          <Route
            path="blacklisted_profiles_business"
            element={<ProtectedRoute check={PERMISSIONS.BLACKLIST_BUSINESS} />}
          >
            <Route
              index
              element={
                <ServiceCheckUpsRegister
                  key="blacklisted_profiles_business"
                  name="blacklisted_profiles_business"
                />
              }
            />
            <Route path="form">
              <Route path=":id" element={<CheckupDescription />} />
            </Route>
          </Route>
          <Route
            path="shuftipro_kyb"
            element={<ProtectedRoute check={PERMISSIONS.BUS_REGISTRY} />}
          >
            <Route
              index
              element={
                <ServiceCheckUpsRegister
                  key="shuftipro_kyb"
                  name="shuftipro_kyb"
                />
              }
            />
            <Route path="form">
              <Route path=":id" element={<CheckupDescription />} />
            </Route>
          </Route>
          <Route
            path="elliptic_wallet"
            element={<ProtectedRoute check={PERMISSIONS.ELLIPTIC} />}
          >
            <Route
              index
              element={
                <ServiceCheckUpsRegister
                  key="elliptic_wallet"
                  name="elliptic_wallet"
                />
              }
            />
            <Route path="form">
              <Route path=":id" element={<CheckupDescription />} />
            </Route>
          </Route>
          <Route
            path="shuftipro_aml"
            element={
              <ProtectedRoute
                check={PERMISSIONS.SEE_SHUFTIPRO_AML_INDIVIDUAL}
              />
            }
          >
            <Route
              index
              element={
                <ServiceCheckUpsRegister
                  key="shuftipro_aml"
                  name="shuftipro_aml"
                />
              }
            />
            <Route path="form">
              <Route path=":id" element={<CheckupDescription />} />
            </Route>
          </Route>
          <Route
            path="shuftipro_aml_business"
            element={
              <ProtectedRoute check={PERMISSIONS.SEE_SHUFTIPRO_AML_BUSINESS} />
            }
          >
            <Route
              index
              element={
                <ServiceCheckUpsRegister
                  key="shuftipro_aml_business"
                  name="shuftipro_aml_business"
                />
              }
            />
            <Route path="form">
              <Route path=":id" element={<CheckupDescription />} />
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<ErrorPages type="local" code={404} />} />
      </Route>
    </Routes>
  );
}

export { CheckupCreateWidget, Checkup };
