import {
  FlexContainer,
  GridContainer,
  getColor,
  getSpace,
} from '@aid-module/ui';
import styled from '@emotion/styled';

export const Container = styled(GridContainer)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  background: ${getColor('white-primary')};
  z-index: 1;
  padding: ${getSpace('8')} ${getSpace('60')} ${getSpace('8')} ${getSpace('40')};
  box-sizing: border-box;
`;

export const IconContainer = styled(FlexContainer)`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${getColor('main-deep-1')};
  }
`;
