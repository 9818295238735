import gql from 'graphql-tag';
import { TRANSACTION_ALERT_FRAGMENT } from './TransactionAlert';

export const TRANSACTION_CHECKUP_RESULT_FRAGMENT = gql`
  ${TRANSACTION_ALERT_FRAGMENT}
  fragment TransactionCheckupResultFragment on TransactionCheckupResult {
    created_at
    id
    rule_results @type(name: "RuleResult") {
      aid_checkup_id
      alerts @type(name: "TransactionAlert") {
        ...TransactionAlertFragment
      }
      created_at
      id
      is_for_test
      rule @type(name: "Rule") {
        async
        code
        created_at
        deleted
        description
        enabled
        id
        name
        provider_id
        is_for_test
        ruleset_id
        settings
      }
      state
    }
    rules_expressions @type(name: "RuleExpression") {
      rules_expression
      ruleset_id
      state
      success
    }
    rulesets_history @type(name: "RuleSet") {
      can_sync
      client_id
      created_at
      deleted
      enabled
      id
      name
      rules @type(name: "Rule") {
        async
        code
        created_at
        deleted
        description
        enabled
        id
        name
        provider_id
        is_for_test
        provider @type(name: "RuleProvider") {
          client_id
          created_at
          enabled
          external
          id
          kind
        }
        ruleset_id
        settings
      }
      rules_expression
      rules_expression_is_valid {
        errors
        success
      }
    }
    state
  }
`;
