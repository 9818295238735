import gql from 'graphql-tag';
import {
  CASE_SCORING_FRAGMENT,
  SUBMISSION_SCORING_FRAGMENT,
} from '../../../fragment/scoring';

export const CaseScoring = gql`
  ${CASE_SCORING_FRAGMENT}
  ${SUBMISSION_SCORING_FRAGMENT}
  query CaseScoring($id: String!, $submission_ids: [String!]!) {
    caseScoring(id: $id, submission_ids: $submission_ids)
      @rest(
        type: "ResponseCaseScoring"
        path: "/scoring/cases/by_case_id/{args.id}"
      ) {
      data @type(name: "CaseScoring") {
        ...CaseScoringFragment
        submissions @type(name: "SubmissionScoring") {
          ...SubmissionScoringFragment
        }
      }
      success
    }
  }
`;
