import { ApolloClient, InMemoryCache } from '@apollo/client';
import { link, onboardingLink } from './links';
import { typePolicies } from './typePolicies';

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    typePolicies,
  }),
});

export function getNewInmemoryCache() {
  return new InMemoryCache({
    typePolicies,
  });
}

export function createNewClient(inmemoryCache?: InMemoryCache) {
  return new ApolloClient({
    link,
    cache: inmemoryCache || getNewInmemoryCache(),
  });
}

export const onboardingClient = new ApolloClient({
  link: onboardingLink,
  cache: new InMemoryCache({
    typePolicies,
  }),
});
