import { MouseEvent, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormProps } from '../../../../../types';
import { useActionModel } from '../model';

export function useActionController() {
  const { updateHits, loading } = useActionModel();
  const { formState, handleSubmit } = useFormContext<FormProps>();

  const onSubmit = useCallback(
    (values: FormProps) => {
      updateHits(values);
    },
    [updateHits]
  );

  const onClickSubmit = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      handleSubmit(onSubmit)(e);
    },
    [handleSubmit, onSubmit]
  );

  return { disabled: !formState.isDirty || loading, onClickSubmit };
}
