import { THEME_COLORS } from '../../../shared/colors/types';
import { ALERT_STATUSES } from '../constants';
import { AlertStatusViewProps } from '../types';

export function getColorByStatus(
  status?: AlertStatusViewProps['status']
): keyof THEME_COLORS {
  switch (status) {
    case ALERT_STATUSES.OPEN:
      return 'error-deep-3';
    case ALERT_STATUSES.CLOSED:
      return 'success-deep-4';
    default:
      return 'warning-deep-4';
  }
}
