import gql from 'graphql-tag';

export const PPFincenListsStats = gql`
  query PPFincenListsStats {
    ppFincenListsStats
    @rest(
      type: "ResponseFincenListsStats"
      path: "/pp/fincen/lists/stats"
    ) {
      data @type(name: "ByStatus") {
        by_status {
          no_match_screenings_count
          potential_match_screenings_count
          true_positive_screenings_count
          false_positive_screenings_count
          unknown_screenings_count
        }
      }
      success
    }
  }
`;
