import { gql } from '@apollo/client';

export const CreateFeatureDictionary = gql`
  mutation CreateFeatureDictionary($body: CreateFeatureDictionaryBody!) {
    createFeatureDictionary(body: $body)
      @rest(
        type: "CreateFeatureDictionary"
        path: "/billing/admin/feature_dictionaries"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "FeatureDictionary") {
        code
        created_at
        created_by_id
        id
        is_system
        name
        status
        updated_at
      }
      errors
      success
    }
  }
`;

export const CreateFeatureTemplate = gql`
  mutation CreateFeatureTemplate($body: CreateFeatureTemplateBody!) {
    createFeatureTemplate(body: $body)
      @rest(
        type: "CreateFeatureTemplate"
        path: "/billing/admin/feature_templates"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "FeatureTemplate") {
        code
        created_at
        created_by_id
        description
        feature_dictionary @type(name: "FeatureDictionary") {
          code
          created_at
          created_by_id
          id
          is_system
          name
          status
          updated_at
        }
        feature_dictionary_id
        id
        kind
        name
        status
        updated_at
        is_system
      }
      errors
      success
    }
  }
`;

export const UpdateFeatureTemplate = gql`
  mutation UpdateFeatureTemplate(
    $id: String!
    $body: CreateFeatureTemplateBody!
  ) {
    updateFeatureTemplate(id: $id, body: $body)
      @rest(
        type: "UpdateFeatureTemplate"
        path: "/billing/admin/feature_templates/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "FeatureTemplate") {
        code
        created_at
        created_by_id
        description
        feature_dictionary @type(name: "FeatureDictionary") {
          code
          created_at
          created_by_id
          id
          is_system
          name
          status
          updated_at
        }
        feature_dictionary_id
        id
        kind
        name
        status
        updated_at
        is_system
      }
      errors
      success
    }
  }
`;
