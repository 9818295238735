import { gql } from '@apollo/client';

export const TransactionQueues = gql`
  query TransactionQueues(
    $page: Int
    $per: Int
    $q: String
    $sort_field: String
    $sort_order: String
  ) {
    transactionQueues(
      page: $page
      per: $per
      q: $q
      sort_field: $sort_field
      sort_order: $sort_order
    )
      @rest(
        type: "ResponseTransactionQueues"
        path: "/tm/workflow/queues?{args}"
      ) {
      data @type(name: "TransactionQueue") {
        id
        alert_deadline_days
        created_at
        description
        is_default
        name
        open_alerts_count
        role_ids
        type
        roles @type(name: "Role") {
          id
          name
          created_at
          updated_at
        }
        rules @type(name: "Rule") {
          async
          code
          created_at
          deleted
          description
          enabled
          id
          name
          provider_id
          is_for_test
          provider @type(name: "RuleProvider") {
            client_id
            created_at
            enabled
            external
            id
            kind
          }
          ruleset_id
          settings
        }
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;
