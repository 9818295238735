import { Styles } from 'react-modal';

export const styles: Styles = {
  content: {
    background: 'transparent',
    borderRadius: '8px',
    padding: 0,
    border: 'none',
    width: 'max-content',
    position: 'static',
    justifyContent: 'center',
    overflow: 'visible',
  },
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    bottom: '0',
    zIndex: 1000,
  },
};
