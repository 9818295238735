import gql from 'graphql-tag';

export const INSTANT_CHECKUP_FRAGMENT = gql`
  fragment InstantCheckupFragment on InstantCheckup {
    code
    created_at
    id
    name
    price
    price_addition
    updated_at
  }
`;
