import { PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';
import { ErrorPages } from '../ErrorPages';
import { useProtectedRouteController } from './controller';
import { ProtectedFeaturesRouteViewProps } from './types';

export function ProtectedFeaturesRoute({
  check,
  strong = false,
  children,
}: PropsWithChildren<ProtectedFeaturesRouteViewProps>) {
  const { canAvailabel } = useProtectedRouteController(check, strong);
  if (canAvailabel) {
    return (
      <>
        <Outlet />
        {children}
      </>
    );
  }

  return <ErrorPages code={403} type="local" />;
}
