import gql from 'graphql-tag';

export const FORM_CONFIGURATION_FRAGMENT = gql`
  fragment FormConfigurationFragment on FormConfiguration {
    created_at
    description
    id
    name
    updated_at
  }
`;
