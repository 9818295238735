import { BooleanInputLoader, InputLoader, MediaLoader } from '@aid-module/ui';
import { useFallbackController } from './controller';
import { FallbackViewProps, LoaderType } from './types';
import { FormContainer } from '../../styles';

function renderInputLoader({ type }: LoaderType, index: number) {
  if (type === 'image') {
    return <MediaLoader key={index} />;
  } else if (type === 'boolean') {
    return <BooleanInputLoader key={index} />;
  }
  return <InputLoader key={index} />;
}

export function Fallback({ name }: FallbackViewProps) {
  const { loadersByCode } = useFallbackController(name);

  return (
    <FormContainer
      gridTemplateRows={`repeat(${loadersByCode?.length + 1}, max-content)`}
      gap="20"
    >
      {loadersByCode.map(renderInputLoader)}
      <InputLoader />
    </FormContainer>
  );
}
