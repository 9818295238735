import { gql } from '@apollo/client';
import {
  TRANSACTION_CASE_FRAGMENT,
  TRANSACTION_CHECKUP_RESULT_FRAGMENT,
  TRANSACTION_EVENT_FRAGMENT,
} from '../../../fragment/tm';
import { TRANSACTION_ENTITY_FRAGMENT } from '../../../fragment/tm/TransactionEntity';
import { TRANSACTION_INSTRUMENT_FRAGMENT } from '../../../fragment/tm/TransactionInstrument';

export const TransactionAlerts = gql`
  query TransactionAlerts(
    $ruleset_id: String
    $rule_id: String
    $rule_code: String
    $event_id: String
    $from_date: String
    $to_date: String
    $sender_entity_id: String
    $receiver_entity_id: String
    $deadline_at_from: String
    $deadline_at_to: String
    $profile_id: String
    $disposition: String
    $assigned_to_id: String
    $state: String
    $page: Int
    $per: Int
    $is_for_test: Boolean
    $is_from_scheduled_rule: Boolean
    $q: String
    $sort_field: String
    $sort_order: String
    $alert_ids: [String!]
  ) {
    transactionAlerts(
      ruleset_id: $ruleset_id
      rule_id: $rule_id
      rule_code: $rule_code
      event_id: $event_id
      from_date: $from_date
      to_date: $to_date
      deadline_at_from: $deadline_at_from
      deadline_at_to: $deadline_at_to
      profile_id: $profile_id
      disposition: $disposition
      sender_entity_id: $sender_entity_id
      receiver_entity_id: $receiver_entity_id
      assigned_to_id: $assigned_to_id
      is_for_test: $is_for_test
      is_from_scheduled_rule: $is_from_scheduled_rule
      state: $state
      page: $page
      per: $per
      q: $q
      sort_field: $sort_field
      sort_order: $sort_order
      alert_ids: $alert_ids
    ) @rest(type: "ResponseTransactionAlerts", path: "/tm/alerts?{args}") {
      data @type(name: "TransactionAlert") {
        alert_no
        created_at
        data {
          title
          description
        }
        deadline_at
        event_support_keys
        id
        kind
        disposition @type(name: "TransactionDisposition") {
          id
          name
        }
        disposition_message
        total_flagged_amount
        disposition_updated_at
        profiles @type(name: "Profile") {
          display_name
          id
        }
        assigned_to @type(name: "Account") {
          id
          email
          first_name
          last_name
        }
        rules @type(name: "Rule") {
          async
          code
          created_at
          deleted
          description
          enabled
          id
          name
          provider_id
          ruleset_id
          is_for_test
          settings
        }
        is_for_test
        is_from_scheduled_rule
        rule_name
        state
        unit21_alert_external_id
        unit21_external_id
        unit21_url
        updated_at
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const TransactionAlertsQueued = gql`
  query TransactionAlertsQueued(
    $ruleset_id: String
    $rule_id: String
    $rule_code: String
    $event_id: String
    $from_date: String
    $to_date: String
    $deadline_at_from: String
    $deadline_at_to: String
    $profile_id: String
    $disposition: String
    $assigned_to_id: String
    $state: String
    $page: Int
    $per: Int
    $is_for_test: Boolean
    $q: String
    $sort_field: String
    $sort_order: String
  ) {
    transactionAlertsQueued(
      ruleset_id: $ruleset_id
      rule_id: $rule_id
      rule_code: $rule_code
      event_id: $event_id
      from_date: $from_date
      to_date: $to_date
      deadline_at_from: $deadline_at_from
      deadline_at_to: $deadline_at_to
      profile_id: $profile_id
      disposition: $disposition
      assigned_to_id: $assigned_to_id
      is_for_test: $is_for_test
      state: $state
      page: $page
      per: $per
      q: $q
      sort_field: $sort_field
      sort_order: $sort_order
    )
      @rest(
        type: "ResponseTransactionAlerts"
        path: "/tm/alerts/queued?{args}"
      ) {
      data @type(name: "TransactionAlert") {
        alert_no
        created_at
        data {
          title
          description
        }
        deadline_at
        event_support_keys
        id
        kind
        disposition @type(name: "TransactionDisposition") {
          id
          name
        }
        disposition_message
        total_flagged_amount
        disposition_updated_at
        profiles @type(name: "Profile") {
          display_name
          id
        }
        assigned_to @type(name: "Account") {
          id
          email
          first_name
          last_name
          full_name
        }
        rules @type(name: "Rule") {
          async
          code
          created_at
          deleted
          description
          enabled
          id
          name
          provider_id
          ruleset_id
          is_for_test
          settings
        }
        is_for_test
        is_from_scheduled_rule
        rule_name
        state
        unit21_alert_external_id
        unit21_external_id
        unit21_url
        updated_at
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const TransactionPriorAlerts = gql`
  query TransactionPriorAlerts($id: String!, $page: Int, $per: Int) {
    transactionPriorAlerts(id: $id, page: $page, per: $per)
      @rest(
        type: "ResponseTransactionAlerts"
        path: "/tm/alerts/{args.id}/prior_alerts?page={args.page}&per={args.per}"
      ) {
      data @type(name: "TransactionAlert") {
        created_at
        data {
          title
          description
        }
        deadline_at
        event_support_keys
        id
        kind
        disposition @type(name: "TransactionDisposition") {
          id
          name
        }
        disposition_message
        total_flagged_amount
        disposition_updated_at
        profiles @type(name: "Profile") {
          display_name
          id
        }
        assigned_to @type(name: "Account") {
          id
          email
          first_name
          last_name
        }
        rules @type(name: "Rule") {
          async
          code
          created_at
          deleted
          description
          enabled
          id
          name
          provider_id
          ruleset_id
          is_for_test
          settings
        }
        is_for_test
        is_from_scheduled_rule
        rule_name
        state
        unit21_alert_external_id
        unit21_external_id
        unit21_url
        updated_at
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const TransactionPriorCases = gql`
  ${TRANSACTION_CASE_FRAGMENT}
  query TransactionPriorCases($id: String!, $page: Int, $per: Int) {
    transactionPriorCases(id: $id, page: $page, per: $per)
      @rest(
        type: "ResponseTransactionCases"
        path: "/tm/alerts/{args.id}/prior_cases?page={args.page}&per={args.per}"
      ) {
      data @type(name: "TransactionCase") {
        ...TransactionCaseFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const TransactionAlert = gql`
  ${TRANSACTION_ENTITY_FRAGMENT}
  ${TRANSACTION_INSTRUMENT_FRAGMENT}
  ${TRANSACTION_EVENT_FRAGMENT}
  ${TRANSACTION_CHECKUP_RESULT_FRAGMENT}
  query TransactionAlert($id: String!) {
    transactionAlert(id: $id)
      @rest(type: "ResponseTransactionAlert", path: "/tm/alerts/{args.id}") {
      data @type(name: "TransactionAlert") {
        alert_no
        cases @type(name: "TransactionCase") {
          assigned_toes @type(name: "TransactionAccount") {
            accounts_clients @type(name: "TransactionAccountClient") {
              account_id
              client_id
              id
            }
            created_at
            email
            first_name
            id
            last_name
            phone
            status
          }
          closed_at
          created_at
          created_by @type(name: "TransactionAccount") {
            accounts_clients @type(name: "TransactionAccountClient") {
              account_id
              client_id
              id
            }
            created_at
            email
            first_name
            id
            last_name
            phone
            status
          }
          deadline_at
          description
          id
          report_case_url
          state
          support_key
          tags @type(name: "TransactionTag") {
            created_at
            description
            id
            name
            updated_at
          }
          title
          unit21_case_external_id
          unit21_external_id
        }
        is_for_test
        is_from_scheduled_rule
        created_at
        data {
          title
          description
        }
        deadline_at
        event_support_keys
        id
        kind
        disposition @type(name: "TransactionDisposition") {
          id
          name
        }
        disposition_message
        total_flagged_amount
        disposition_updated_at
        documents
        profiles @type(name: "Profile") {
          display_name
          id
        }
        assigned_to @type(name: "Account") {
          id
          email
          first_name
          last_name
        }
        rules @type(name: "Rule") {
          async
          code
          created_at
          deleted
          description
          enabled
          id
          name
          provider_id
          ruleset_id
          settings
        }
        rule_results @type(name: "RuleResult") {
          aid_checkup_id
          created_at
          id
          state
        }
        attachments @type(name: "Attachment") {
          account @type(name: "Account") {
            created_at
            email
            first_name
            id
            last_name
            phone
            status
          }
          client_id
          created_at
          file_name
          file_size
          id
          kind
          subject_id
          subject_type
          updated_at
          uploaded_document_id
        }
        rule_name
        state
        alert_period_from
        alert_period_to
        unit21_alert_external_id
        unit21_external_id
        unit21_url
        updated_at
        tags @type(name: "TransactionAlertTag") {
          id
          name
          description
          created_at
          updated_at
        }
        events @type(name: "TransactionEvent") {
          ...TransactionEventFragment
          checkup_result @type(name: "TransactionCheckupResult") {
            ...TransactionCheckupResultFragment
          }
          rulesets @type(name: "RuleSet") {
            can_sync
            client_id
            created_at
            deleted
            enabled
            id
            name
            rules_expression
          }
        }
        entities @type(name: "TransactionEntity") {
          ...TransactionEntityFragment
        }
        instruments @type(name: "TransactionInstrument") {
          ...TransactionInstrumentFragment
        }
        entities_count
        events_count
        instruments_count
        description
      }
      success
    }
  }
`;
