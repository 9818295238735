import { useMemo } from 'react';
import { NotificationViewProps } from '../types';

export function useNotificationController(
  payloadData: NotificationViewProps['payloadData']
) {
  const words = useMemo(() => {
    return Array.from(payloadData.body.matchAll(/{{(.*?)}}|.+?(?={{|$)/g)).map(
      (item) => ({
        type: item[1] ? 'dynamic' : 'static',
        value: item[1] || item[0],
      })
    );
  }, []);

  const hasDynamic = useMemo(
    () => words.some((item) => item.type === 'dynamic'),
    [words]
  );

  return {
    words,
    hasDynamic,
  };
}
