import { PropsWithChildren } from 'react';
import { AIDLoader } from '../AIDLoader';
import { ClientContext } from './context';
import { useClientProviderController } from './controller';

export function ClientProvider({ children }: PropsWithChildren<{}>) {
  const { context, loading } = useClientProviderController();

  if (loading) {
    return <AIDLoader />;
  }

  return (
    <ClientContext.Provider value={context}>{children}</ClientContext.Provider>
  );
}
