import { useRef } from 'react';
import { Controller } from 'react-hook-form';
import { ImageField } from '@aid-module/ui';
import { FormRenderProps } from '../../../../types';
import { ImageViewProps } from './types';

export function Image({ field }: ImageViewProps) {
  const renderImage = useRef(
    ({
      field: { value, onChange },
      fieldState: { error },
    }: FormRenderProps<string>) => {
      return (
        <ImageField
          label={`${field.title}${field.required ? ' *' : ''}`}
          error={error}
          value={value}
          onChange={onChange}
        />
      );
    }
  );
  return <Controller name={field.code} render={renderImage.current} />;
}
