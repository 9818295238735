import { GridContainer, ContentLoader } from '@aid-module/ui';

export function Fallback() {
  return (
    <GridContainer gap="32">
      <ContentLoader width="100%" height="162px">
        <rect rx="4px" ry="4px" width="100%" height="162px" />
      </ContentLoader>
      <GridContainer gap="20">
        <ContentLoader width="100%" height="31px">
          <rect rx="4px" ry="4px" width="100%" height="31px" />
        </ContentLoader>
        <GridContainer gap="20" gridTemplateColumns="repeat(4, 1fr)">
          <ContentLoader width="100%" height="84px">
            <rect rx="4px" ry="4px" width="100%" height="84px" />
          </ContentLoader>
          <ContentLoader width="100%" height="84px">
            <rect rx="4px" ry="4px" width="100%" height="84px" />
          </ContentLoader>
          <ContentLoader width="100%" height="84px">
            <rect rx="4px" ry="4px" width="100%" height="84px" />
          </ContentLoader>
          <ContentLoader width="100%" height="84px">
            <rect rx="4px" ry="4px" width="100%" height="84px" />
          </ContentLoader>
          <ContentLoader width="100%" height="84px">
            <rect rx="4px" ry="4px" width="100%" height="84px" />
          </ContentLoader>
          <ContentLoader width="100%" height="84px">
            <rect rx="4px" ry="4px" width="100%" height="84px" />
          </ContentLoader>
          <ContentLoader width="100%" height="84px">
            <rect rx="4px" ry="4px" width="100%" height="84px" />
          </ContentLoader>
          <ContentLoader width="100%" height="84px">
            <rect rx="4px" ry="4px" width="100%" height="84px" />
          </ContentLoader>
          <ContentLoader width="100%" height="84px">
            <rect rx="4px" ry="4px" width="100%" height="84px" />
          </ContentLoader>
          <ContentLoader width="100%" height="84px">
            <rect rx="4px" ry="4px" width="100%" height="84px" />
          </ContentLoader>
          <ContentLoader width="100%" height="84px">
            <rect rx="4px" ry="4px" width="100%" height="84px" />
          </ContentLoader>
          <ContentLoader width="100%" height="84px">
            <rect rx="4px" ry="4px" width="100%" height="84px" />
          </ContentLoader>
        </GridContainer>
      </GridContainer>
    </GridContainer>
  );
}
