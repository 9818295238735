import { getColor, getSpace, Text } from '@aid-module/ui';
import styled from '@emotion/styled/macro';

export const Title = styled(Text)`
  text-transform: uppercase;
  letter-spacing: 0.115em;
`;

export const Container = styled.span`
  text-decoration: none;
  display: flex;
  width: max-content;
  margin-bottom: 8px;
  border-radius: 4px;
  padding: ${getSpace('6')} ${getSpace('6')};

  /* &:hover {
    background: ${getColor('main-deep-2')};
  }

  &.active {
    background: ${getColor('main-deep-2')};
  } */
`;
