import { gql } from '@apollo/client';

export const LinkToProfileSubmission = gql`
  mutation LinkToProfileSubmission(
    $id: String!
    $body: BodyLinkToProfileSubmission!
  ) {
    linkToProfileSubmission(id: $id, body: $body)
      @rest(
        type: "LinkToProfileSubmission"
        path: "/core/client/submissions/{args.id}/link_to_profile"
        bodyKey: "body"
        method: "PUT"
      ) {
      success
    }
  }
`;

export const LinkToCaseSubmission = gql`
  mutation LinkToCaseSubmission(
    $id: String!
    $body: BodyLinkOrUnlinkToCaseSubmission!
  ) {
    linkToCaseSubmission(id: $id, body: $body)
      @rest(
        type: "LinkToCaseSubmission"
        path: "/core/client/submissions/{args.id}/link_to_case"
        bodyKey: "body"
        method: "PUT"
      ) {
      success
    }
  }
`;

export const UnlinkFromCaseSubmission = gql`
  mutation UnlinkFromCaseSubmission(
    $id: String!
    $body: BodyLinkOrUnlinkToCaseSubmission!
  ) {
    unlinkFromCaseSubmission(id: $id, body: $body)
      @rest(
        type: "UnlinkFromCaseSubmission"
        path: "/core/client/submissions/{args.id}/unlink_from_case"
        bodyKey: "body"
        method: "PUT"
      ) {
      success
    }
  }
`;
