import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { CaseExternalRefTypeResponse } from './constants';
import { ExternalRefLinkViewProps } from '../types';
import {
  PERMISSIONS,
  PERMISSIONS_CM_CASE,
  PERMISSIONS_PROFILE,
  PERMISSIONS_SUBMISSION,
  PERMISSIONS_CHECKUPS,
  useCheckPermission,
  usePermissionsContext,
} from '@aid-package/context';

export function useExternalRefLinkController(
  external_ref: ExternalRefLinkViewProps['external_ref']
) {
  const { client_id } = useParams();
  const { permissions } = usePermissionsContext();

  const externalRef = useMemo(() => {
    if (
      external_ref.__typename === 'ExternalRef' ||
      external_ref.__typename === 'TransactionCaseExternalRef'
    ) {
      return external_ref;
    } else if (external_ref.__typename === 'PPExternalRef') {
      return external_ref.external_ref;
    }
  }, [external_ref]);

  const { canShow: canSeeCase } = useCheckPermission(
    PERMISSIONS_CM_CASE,
    false
  );

  const { canShow: canSeeTM } = useCheckPermission([PERMISSIONS.SEE_TM], false);

  const { canShow: canSeeProfile } = useCheckPermission(
    PERMISSIONS_PROFILE,
    false
  );

  const { canShow: canSeeSubmission } = useCheckPermission(
    PERMISSIONS_SUBMISSION,
    false
  );

  const { canShow: canSeeCheckups } = useCheckPermission(
    PERMISSIONS_CHECKUPS,
    false
  );

  const href = useMemo(() => {
    switch (externalRef?.external_entity_type) {
      case CaseExternalRefTypeResponse.CASE:
        return canSeeCase
          ? `/${client_id}/verification/cases/${externalRef?.external_entity_id}`
          : undefined;
      case CaseExternalRefTypeResponse.EVENT:
        return canSeeTM
          ? `/${client_id}/transaction/monitoring/${externalRef?.external_entity_id}`
          : undefined;
      case CaseExternalRefTypeResponse.PROFILE:
        return canSeeProfile
          ? `/${client_id}/profile/profiles/${externalRef?.external_entity_id}`
          : undefined;
      case CaseExternalRefTypeResponse.SUBMISSION:
      case CaseExternalRefTypeResponse.FORM_SUBMISSION:
        return canSeeSubmission
          ? `/${client_id}/verification/verifications/${externalRef?.external_entity_id}`
          : undefined;
      case CaseExternalRefTypeResponse.CHECKUP:
        return canSeeCheckups
          ? `/${client_id}/checkup/instant_checkups/all/form/${externalRef?.external_entity_id}`
          : undefined;
    }
  }, [
    externalRef,
    client_id,
    permissions,
    canSeeCase,
    canSeeTM,
    canSeeProfile,
    canSeeSubmission,
    canSeeCheckups,
  ]);

  const text = useMemo(() => {
    if (
      externalRef?.__typename === 'ExternalRef' &&
      externalRef?.external_entity_name
    ) {
      return externalRef.external_entity_name;
    }
    switch (externalRef?.external_entity_type) {
      case CaseExternalRefTypeResponse.CASE:
        return 'Case';
      case CaseExternalRefTypeResponse.FORM_SUBMISSION:
      case CaseExternalRefTypeResponse.SUBMISSION:
        return 'Verification';
      case CaseExternalRefTypeResponse.PROFILE:
        return 'Profile';
      case CaseExternalRefTypeResponse.EVENT:
        return 'Transaction';
      case CaseExternalRefTypeResponse.CHECKUP:
        return 'Screening';
      case CaseExternalRefTypeResponse.CUSTOMER:
        return 'Customer';
    }
  }, [externalRef]);

  return { href, text };
}
