import { GridContainer } from '../../../../../../../../../../../../../../../../../../../../../containers';
import { ModalBodyViewProps } from '../../../../../../../../../../../../../../../../../../../../Modal/Modal/types';
import { Text } from '../../../../../../../../../../../../../../../../../../../../Text';
import { Form } from './components';

export function Create({ onClose }: ModalBodyViewProps) {
  return (
    <GridContainer gap="32" justifyContent="center" gridTemplateColumns="320px">
      <Text
        text="Save current active filters as a filter template"
        size={14}
        weight="Medium"
        align="center"
      />
      <Form onClose={onClose} />
    </GridContainer>
  );
}
