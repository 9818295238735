import { useMemo } from 'react';
import { CheckupStatusViewProps } from '../types';
import { getTitleSettingsForStatus } from './utils';

export function useCheckupStatusController(
  checkup: CheckupStatusViewProps['checkup']
) {
  const { label, color } = useMemo(
    () => getTitleSettingsForStatus(checkup),
    [checkup]
  );

  return { label, color };
}
