import { GridContainer, Text } from '@aid-module/ui';
import { useReLoginLinkController } from './controller';
import { ReLoginTextLink } from './styles';

export function ReLoginLink() {
  const { onClick } = useReLoginLinkController();

  return (
    <GridContainer
      alignItems="center"
      gridTemplateColumns="repeat(2, max-content)"
      gap="8"
    >
      <Text
        text="Cannot find a profile you have previously created in the list?"
        size={14}
        color="main-deep-5"
        align="center"
        weight="Medium"
      />
      <ReLoginTextLink
        onClick={onClick}
        text="Try to re-login with a different email address"
        size={14}
        color="main-deep-4"
        weight="Medium"
      />
    </GridContainer>
  );
}
