import loadable from '@loadable/component';
import { LoadableFunctionalComponent } from '@aid-module/ui';
import { FormContainer } from '../components';
import { Fallback } from './components';

export const StopWordCreate = loadable(() => import('./component'), {
  resolveComponent: (components) => components.StopWordCreateComponent,
  fallback: (
    <FormContainer label="Create Ignored Word" icon="scheme" isLoading>
      <Fallback />
    </FormContainer>
  ),
}) as LoadableFunctionalComponent<{}>;
