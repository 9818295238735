import gql from 'graphql-tag';

export const SlaRfiPersonal = gql`
  query SlaRfiPersonal($states: [CaseStatus!], $assigned_to_id: String!) {
    slaRfiPersonal(states: $states, assigned_to_id: $assigned_to_id)
      @rest(
        type: "ResponseSlaRfiPersonal"
        path: "/stats/client/sla_rfi_personal?{args}"
      ) {
      data {
        assigned_to_id
        assigned_to_name
        client_id
        created_at
        id
        rfi_to_assigned
        rfi_to_assigned_common
        rfi_to_rfi_common
        total_assigned
        total_assigned_common
        total_assigned_far
        total_assigned_far_to_total_assigned
        total_assigned_far_to_total_assigned_common
        total_assigned_far_to_total_confirmed
        total_confirmed
        total_rfi
        total_rfi_common
        updated_at
      }
      success
    }
  }
`;
