import { ContentLoader, GridContainer } from '@aid-module/ui';

export function Fallback() {
  return (
    <GridContainer gap="20">
      <GridContainer gap="12">
        <ContentLoader width="100%" height="40.2px">
          <rect rx="4" ry="4" width="100%" height="40.2px" />
        </ContentLoader>
        <ContentLoader width="100%" height="300px">
          <rect rx="4" ry="4" width="100%" height="300px" />
        </ContentLoader>
      </GridContainer>
      <GridContainer gap="20">
        <ContentLoader width="100%" height="40.2px">
          <rect rx="4" ry="4" width="100%" height="40.2px" />
        </ContentLoader>
        <ContentLoader width="100%" height="600px">
          <rect rx="4" ry="4" width="100%" height="600px" />
        </ContentLoader>
      </GridContainer>
    </GridContainer>
  );
}
