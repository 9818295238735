import {
  TableWithoutSubHeader,
  LoadableFunctionalComponent,
} from '@aid-module/ui';
import loadable from '@loadable/component';

export const ClientCheckupKindsRegister = loadable(
  () => import('./component'),
  {
    resolveComponent: (components) =>
      components.ClientCheckupKindsRegisterComponent,
    fallback: <TableWithoutSubHeader />,
  }
) as LoadableFunctionalComponent<{}>;
