import { LoadableFunctionalComponent } from '@aid-module/ui';
import loadable from '@loadable/component';
import { FormContainer } from '../components';

import { Fallback } from './components';

export const RiskScoringTemplateUpdate = loadable(() => import('./component'), {
  resolveComponent: (components) =>
    components.RiskScoringTemplateUpdateComponent,
  fallback: (
    <FormContainer label="Update Risk Scoring Template" icon="fieldTemplate">
      <Fallback />
    </FormContainer>
  ),
}) as LoadableFunctionalComponent<{}>;
