import { Default } from './Default';
import { Error } from './Error';
import { Notification } from './Notification';
import { Success } from './Success';
import { ToastComponentObject } from './types';
import { Warning } from './Warning';

export const ToastComponent: ToastComponentObject = {
  default: Default,
  error: Error,
  success: Success,
  warning: Warning,
  notification: Notification,
};
