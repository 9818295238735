import { TYPES } from '@aid-package/api';
import { useCallback, useEffect, useMemo } from 'react';
import { SelectOption } from '@aid-module/ui';
import { useFormContext } from 'react-hook-form';
import { CheckupKindClientCodeResponse } from '../../../../../../../../../../../../../../constants';
import { useCheckupCreateConfigContext } from '../../../../../../../../../../../../../context';
import { useCheckupKindContext } from '../../../../../../../../../../../../../context';

export function useProfileSelectController() {
  const { checkupKind } = useCheckupKindContext();
  const config = useCheckupCreateConfigContext();
  const { setValue } = useFormContext();

  const kind = useMemo(() => {
    switch (checkupKind?.code) {
      case CheckupKindClientCodeResponse.COMPLY_ADVANTAGE_AML_BUSINESS:
      case CheckupKindClientCodeResponse.ELLIPTIC_WALLET:
      case CheckupKindClientCodeResponse.AID_BLACK_LIST_BUSINESS:
      case CheckupKindClientCodeResponse.SHUFTIPRO_KYB:
      case CheckupKindClientCodeResponse.FINCEN_BUSINESS:
      case CheckupKindClientCodeResponse.UNIT_21_KYB:
        return 'legal_entity';
      case CheckupKindClientCodeResponse.COMPLY_ADVANTAGE_AML:
      case CheckupKindClientCodeResponse.AID_BLACK_LIST_PERSON:
      case CheckupKindClientCodeResponse.FINCEN_PERSON:
      case CheckupKindClientCodeResponse.UNIT_21_ENTITY_ID:
      case CheckupKindClientCodeResponse.UNIT_21_ENTITY_DOC:
        return 'physical_entity';
    }
  }, [checkupKind?.code]);

  const reduceData = useCallback(
    (profile: TYPES.ProfilesQuery['profiles']['data'][0]) => ({
      label: profile?.display_name
        ? `${profile.display_name} (${
            profile.kind === 'legal_entity' ? 'Legal entity' : 'Natural person'
          })`
        : 'External profile',
      value: profile.id,
    }),
    []
  );

  useEffect(() => {
    setValue(
      'profiles',
      config?.defaultValues?.profiles
        ? config.defaultValues.profiles.reduce<SelectOption[]>(
            (acc, profile) => {
              if (profile) {
                return [
                  ...acc,
                  {
                    label: profile.display_name || 'External profile',
                    value: profile.id,
                  },
                ];
              }
              return acc;
            },
            []
          )
        : null
    );
  }, [checkupKind?.code, config?.defaultValues?.profiles, setValue]);

  const additional = useMemo(() => ({ kind }), [kind]);

  return { reduceData, additional };
}
