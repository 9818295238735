import { PropsWithChildren } from 'react';
import { Text } from '../../../Text';
import { Title } from './styles';
import { InputDecoratorViewProps } from '../types';
import { ErrorContainer, NoteContainer, ErrorRow } from '../shared.styles';
import { GridContainer } from '../../../../containers';
import { InfoTooltip } from '../InfoTooltip';

export function VerticalInputDecorator({
  error,
  children,
  label,
  note,
  warning,
  className,
  information,
}: PropsWithChildren<InputDecoratorViewProps>) {
  return (
    <GridContainer
      gap="6"
      className={className}
      gridTemplateRows={`${label ? 'max-content ' : ''}1fr${
        note ? ' max-content' : ''
      }${warning ? ' max-content' : ''}${error ? ' max-content' : ''}`}
    >
      {label && (
        <Title size={12} lineHeight="15.59px" weight="VeryBig">
          {label}
          {information ? (
            <>
              {' '}
              <InfoTooltip information={information} />
            </>
          ) : null}
        </Title>
      )}
      {children}
      {note && (
        <NoteContainer>
          <Text text={note} size={12} color="main-deep-4" />
        </NoteContainer>
      )}
      {warning && (
        <NoteContainer>
          <Text text={warning} size={12} color="warning-deep-4" />
        </NoteContainer>
      )}
      {error && error?.message && (
        <ErrorContainer>
          <ErrorRow text={error?.message} size={12} color="error-deep-3" />
        </ErrorContainer>
      )}
    </GridContainer>
  );
}
