import gql from 'graphql-tag';

export const AccountSettings = gql`
  query AccountSettings {
    accountSettings
      @rest(
        type: "ResponseAccountSettings"
        path: "/nh/account_settings/current"
      ) {
      data @type(name: "AccountSettings") {
        email_enabled
        id
        updated_at
        ws_enabled
      }
      success
    }
  }
`;
