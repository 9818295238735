import { useMemo } from 'react';
import { ContentLoader } from '../ContentLoader';
import { ImageLoaderViewProps } from './types';

export function ImageLoader({
  width,
  height = 260,
  radius = 3,
}: ImageLoaderViewProps) {
  const currentWidth = useMemo(() => (width ? `${width}px` : '100%'), [width]);
  return (
    <ContentLoader width={currentWidth} height={`${height}px`}>
      <rect
        x="0"
        y="0"
        rx={`${radius}`}
        ry={`${radius}`}
        width={currentWidth}
        height={`${height}px`}
      />
    </ContentLoader>
  );
}
