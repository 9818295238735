import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export function useSearchModel() {
  const { client_id } = useParams();
  const navigate = useNavigate();

  const onNavigateToSearchHistory = useCallback(() => {
    navigate(`/${client_id}/search/search_entries`);
  }, [client_id, navigate]);

  return {
    onNavigateToSearchHistory,
  };
}
