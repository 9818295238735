import { GridContainer } from '../../../../../../../../../../../../../../../containers';
import { AutomaticTooltip } from '../../../../../../../../../../../../../../AutomaticTooltip';
import { Empty } from '../../../../../../../../../../../../../../Empty';
import { Text } from '../../../../../../../../../../../../../../Text';
import { Card, ContentContainer } from '../shared.styles';
import { Button, Droppable } from './components';
import { useFavoriteController } from './controller';
import { ListItem, UL } from './styles';

export function Favorite() {
  const { favorites } = useFavoriteController();
  return (
    <Card>
      <GridContainer
        gridTemplateColumns="1fr"
        gridTemplateRows="max-content 1fr"
        gap="14"
      >
        <GridContainer
          gridTemplateColumns="repeat(2, max-content)"
          gap="8"
          alignItems="center"
        >
          <Text
            text="favorite"
            size={14}
            color="main-deep-4"
            weight="VeryBig"
            uppercase
          />
          <AutomaticTooltip view={<Button />}>
            <GridContainer gap="8" gridTemplateColumns="328px">
              <UL>
                <ListItem size={11} weight="Medium">
                  Mark as favorite some of your the most frequently used
                  templates to get easy access from Case Management page.
                </ListItem>
                <ListItem size={11} weight="Medium">
                  Change order of templates using drag'n'drop.
                </ListItem>
                <ListItem size={11} weight="Medium" color="warning-deep-4">
                  Maximum number of favorites: 4 templates
                </ListItem>
              </UL>
            </GridContainer>
          </AutomaticTooltip>
        </GridContainer>
        <ContentContainer gridTemplateColumns="1fr">
          {favorites.length ? (
            <Droppable templates={favorites} />
          ) : (
            <GridContainer gridTemplateColumns="1fr" gridTemplateRows="200px">
              <Empty
                title="NO FAVORITE FILTERS"
                subtitle="Please, select your favorite filter templates in Additional section to display them on Case Management page."
                withoutBorder
              />
            </GridContainer>
          )}
        </ContentContainer>
      </GridContainer>
    </Card>
  );
}
