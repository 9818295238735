import { ContentLoader, ListLoader, LoadableFunctionalComponent, GridContainer } from '@aid-module/ui';
import loadable from '@loadable/component';
import { CardContainer } from './components';

export const BlacklistedDeviceInfoCard = loadable(() => import('./component'), {
  resolveComponent: (components) => components.BlacklistedDeviceInfoComponent,
  fallback: (
    <CardContainer isLoading>
      <GridContainer gap="10">
        <ListLoader count={6} />
        <ContentLoader width="100%" height="32.2">
          <rect rx="8" ry="8" width="100%" height="32.2" />
        </ContentLoader>
      </GridContainer>
    </CardContainer>
  ),
}) as LoadableFunctionalComponent<{}>;
