import { ApolloLink, NextLink, Operation } from '@apollo/client';
import { NavigateFunction } from 'react-router-dom';

class NavigateLink extends ApolloLink {
  navigate?: NavigateFunction;

  updateNavigateFunction(navigate?: NavigateFunction) {
    this.navigate = navigate;
  }
  request(operation: Operation, forward?: NextLink) {
    const context = operation.getContext();
    operation.setContext({
      ...context,
      navigate: this.navigate,
    });
    return forward?.(operation) || null;
  }
}

export default new NavigateLink();
