import gql from 'graphql-tag';

export const ExportTemplates = gql`
  query ExportTemplates {
    exportTemplates
      @rest(
        type: "ResponseExportTemplates"
        path: "/pressman/export_templates?per=40"
      ) {
      data @type(name: "ExportTemplate") {
        code
        data_builder_klass
        executor_klass
        filter_contract_klass
        id
        name
      }
      success
    }
  }
`;

export const ExportRequest = gql`
  query ExportRequest($id: String!) {
    exportRequest(id: $id)
      @rest(
        type: "ResponseExportRequest"
        path: "/pressman/export_requests/{args.id}"
      ) {
      data @type(name: "ExportRequest") {
        id
        created_at
        data
        end_at
        execution_errors
        execution_status
        export_sub_requests @type(name: "ExportSubRequest") {
          id
          execution_status
        }
        export_template @type(name: "ExportTemplate") {
          code
          data_builder_klass
          executor_klass
          filter_contract_klass
          id
          name
        }
        filters
        meta
        start_at
        uploaded_document_id
      }
      success
    }
  }
`;

export const ExportRequests = gql`
  query ExportRequests(
    $download_at_exist: String
    $execution_status: String
    $page: Int
    $q: String
  ) {
    exportRequests(
      download_at_exist: $download_at_exist
      execution_status: $execution_status
      page: $page
      per: 20
      q: $q
    )
      @rest(
        type: "ResponseExportRequests"
        path: "/pressman/export_requests?{args}"
      ) {
      data @type(name: "ExportRequest") {
        id
        created_at
        data
        end_at
        execution_errors
        execution_status
        export_sub_requests @type(name: "ExportSubRequest") {
          id
          execution_status
        }
        export_template @type(name: "ExportTemplate") {
          code
          data_builder_klass
          executor_klass
          filter_contract_klass
          id
          name
        }
        filters
        start_at
        uploaded_document_id
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;
