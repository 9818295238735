import {
  TableWithoutSubHeader,
  LoadableFunctionalComponent,
} from '@aid-module/ui';
import loadable from '@loadable/component';

export const ClientProvidersRegister = loadable(() => import('./component'), {
  resolveComponent: (components) => components.ClientProvidersRegisterComponent,
  fallback: <TableWithoutSubHeader />,
}) as LoadableFunctionalComponent<{}>;
