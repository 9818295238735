import gql from 'graphql-tag';
import { CASE_SECTION_QUESTIONNAIRE_ITEM_FRAGMENT } from './CaseSectionQuestionnaireItem';

export const QUESTIONNAIRE_FRAGMENT = gql`
  ${CASE_SECTION_QUESTIONNAIRE_ITEM_FRAGMENT}
  fragment QuestionnaireFragment on Questionnaire {
    case_section @type(name: "CaseSectionQuestionnaire") {
      case_section_items @type(name: "CaseSectionQuestionnaireItem") {
        ...CaseSectionQuestionnaireItemFragment
      }
      case_section_items_count
      failed_questionnaire_items
        @type(name: "CaseSectionQuestionnaireFailedItem") {
        id
        field_submission_id
      }
      created_at
      id
      kind
      state
      updated_at
    }
  }
`;
