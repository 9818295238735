import { useApolloClientControllerContext } from '@aid-package/api';
import { useMemo } from 'react';

export function useTimerLogoutController() {
  const { timerOptions } = useApolloClientControllerContext();

  const parsedTime = useMemo(() => {
    if (timerOptions?.time) {
      const minutes = Math.floor(timerOptions?.time / 60000);
      const seconds = Number(
        (Math.floor(timerOptions?.time % 60000) / 1000).toFixed(0)
      );
      return `${Math.max(0, minutes)}:${seconds < 10 ? '0' : ''}${Math.max(
        0,
        seconds
      )}`;
    }
    return;
  }, [timerOptions]);

  return { parsedTime };
}
