import { LoadableFunctionalComponent } from '@aid-module/ui';
import loadable from '@loadable/component';
import { FormContainer } from '../../../components';
import { Fallback } from './components';

export const ClientCheckupKindUpdate = loadable(() => import('./component'), {
  resolveComponent: (components) => components.ClientCheckupKindUpdateComponent,
  fallback: (
    <FormContainer
      label="Client Checkup Kind"
      icon="kycDocVerification"
      isLoading
    >
      <Fallback />
    </FormContainer>
  ),
}) as LoadableFunctionalComponent<{}>;
