import { LocalStorageProvider } from './provider';
import { ProviderDelegate } from './provider/types';

export class Cache {
  provider: ProviderDelegate;

  get columns() {
    return this.provider.getSavedByKey();
  }

  constructor(key: string) {
    this.provider = LocalStorageProvider.init(key);
  }

  save(next: string[] | undefined) {
    this.provider.saveValueByKey(next);
  }

  static init(key: string) {
    return new Cache(key);
  }
}
