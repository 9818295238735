import { ContentLoader, GridContainer, Icon, IconLoader, Text } from '@aid-module/ui';
import { useExportRequestsController } from './controller';
import { ExportRequestsWrapper } from './styles';
 
export function ExportRequests() {
  const { countDoc, href, loading } = useExportRequestsController();

  return (
    <ExportRequestsWrapper to={href}>
      <GridContainer
            gridTemplateColumns="32px max-content"
            alignItems="center"
            gap="14"
          >
        <Icon glyph="download" size={32} fallback={<IconLoader size={32} />} color="main-deep-4" />
        <GridContainer alignItems="center" gridTemplateRows="18.2px 13px">
          {loading ? (
            <ContentLoader width="76px" height="18.2px">
              <rect x="0" y="0" rx="3" ry="3" width="100%" height="18.2px" />
            </ContentLoader>
          ) : (
            <Text text={`${countDoc}` || '0'} color="main-deep-4" weight="Bold" />
          )}
          <Text text="Document ready" size={10} color="main-deep-3" />
        </GridContainer>
      </GridContainer>
    </ExportRequestsWrapper>
  );
}
