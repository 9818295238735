import { Routes, Route, Navigate } from 'react-router-dom';
import {
  PricingPlansRegister,
  PricingPlanCreate,
  PricingPlanDescription,
  PricingPlanEdit,
} from './PricingPlans';
import { ErrorPages, ProtectedRoute } from '@aid-module/ui';
import { PricingFeatureTemplatesRegister } from './PricingFeatureTemplates';
import { PricingFeatureDictionariesRegister } from './PricingFeatureDictionaries';
import { PERMISSIONS } from '@aid-package/context';
import { PricingClientSubscription } from './PricingClientSubscription';
import { PricingClientPlans } from './PricingClientPlans';

export const ENABLED_PRICING = process.env.REACT_APP_ENABLED_PRICING;

export function PricingAdminRouter() {
  if (ENABLED_PRICING) {
    return (
      <Routes>
        <Route path="/" element={<ProtectedRoute check={PERMISSIONS.ADMIN} />}>
          <Route index element={<Navigate to="plans" />} />
          <Route path="plans">
            <Route index element={<PricingPlansRegister />} />
            <Route path="form" element={<PricingPlanCreate />} />
            <Route path="form/:id" element={<PricingPlanDescription />} />
            <Route path="form/:id/edit" element={<PricingPlanEdit />} />
          </Route>
          <Route path="features">
            <Route index element={<PricingFeatureTemplatesRegister />} />
          </Route>
          <Route path="sections">
            <Route index element={<PricingFeatureDictionariesRegister />} />
          </Route>
          <Route path="*" element={<ErrorPages type="local" code={404} />} />
        </Route>
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="*" element={<ErrorPages type="local" code={404} />} />
    </Routes>
  );
}

export function PricingRouter() {
  if (ENABLED_PRICING) {
    return (
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute check={PERMISSIONS.MANAGE_CLIENT_SUBSCRIPTIONS} />
          }
        >
          <Route path="subscription">
            <Route index element={<PricingClientSubscription />} />
          </Route>
          <Route path="plans">
            <Route index element={<PricingClientPlans />} />
          </Route>
          <Route path="*" element={<ErrorPages type="local" code={404} />} />
        </Route>
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="*" element={<ErrorPages type="local" code={404} />} />
    </Routes>
  );
}
