import gql from 'graphql-tag';

export const CREATED_TRANSLATION_FRAGMENT = gql`
  fragment CreatedTranslationFragment on ResponseCreateTranslation {
    created
    document_id
    filename
    model_id
    source
    status
    target
    translation_id
  }
`;
