import { DashboardCardLoader, GridContainer } from '@aid-module/ui';

export function Fallback() {
  return (
    <GridContainer gridTemplateColumns="repeat(3, 1fr)" gap="60">
      <DashboardCardLoader />
      <DashboardCardLoader />
      <DashboardCardLoader />
      <DashboardCardLoader />
      <DashboardCardLoader />
      <DashboardCardLoader />
    </GridContainer>
  );
}
