import gql from 'graphql-tag';
import {
  AUDIT_CONFIG_FRAGMENT,
  SERVICE_CHECKUP_FRAGMENT,
} from '../../fragment/checkup';
import { AUDIT_FRAGMENT } from '../../fragment/audit';

export const Audits = gql`
  ${AUDIT_FRAGMENT}
  query Audits(
    $from_date: String
    $to_date: String
    $submission_id: String
    $page: Int
  ) {
    audits(
      from_date: $from_date
      to_date: $to_date
      submission_id: $submission_id
      page: $page
      per: 20
    ) @rest(type: "ResponseAudits", path: "/checkups/client/audits?{args}") {
      data @type(name: "Audit") {
        ...AuditFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const AuditRecords = gql`
  query AuditRecords(
    $obj_id: String
    $record_type: String
    $obj_type: String
    $subject_type: String
    $subject_id: String
    $created_before: String
    $created_after: String
    $order: String
    $page: Int
  ) {
    auditRecords(
      obj_id: $obj_id
      record_type: $record_type
      obj_type: $obj_type
      subject_type: $subject_type
      subject_id: $subject_id
      created_before: $created_before
      created_after: $created_after
      order: $order
      page: $page
      per: 20
    ) @rest(type: "ResponseAuditRecords", path: "/audit/records?{args}") {
      data @type(name: "AuditRecord") {
        display_name
        created_at
        obj_type
        obj_id
        subject_meta @type(name: "AuditRecordMeta") {
          display_name
          email
        }
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const Audit = gql`
  ${AUDIT_CONFIG_FRAGMENT}
  ${AUDIT_FRAGMENT}
  ${SERVICE_CHECKUP_FRAGMENT}
  query Audit($id: String!) {
    audit(id: $id)
      @rest(type: "ResponseAudit", path: "/checkups/client/audits/{args.id}") {
      data @type(name: "Audit") {
        ...AuditFragment
        audit_config @type(name: "AuditConfig") {
          ...AuditConfigFragment
        }
        audits_checkups @type(name: "AuditCheckup") {
          id
          checkup_config_id
          checkup @type(name: "ServiceCheckup") {
            ...ServiceCheckupFragment
            form
          }
          checkup_kind @type(name: "CheckupKind") {
            code
            description
            id
            name
            provider_id
          }
        }
      }
      success
    }
  }
`;
