import loadable from '@loadable/component';
import { ContentLoader } from '../../../../shared';
import { LoadableFunctionalComponent } from '../../../../utils';
import { AvatarPickerFieldComponentViewProps } from './component/types';

export const AvatarPickerField = loadable(() => import('./component'), {
  resolveComponent: (components) => components.AvatarPickerFieldComponent,
  fallback: (
    <ContentLoader width="100%" height="250">
      <rect x="0" y="0" rx="3" ry="3" width="100%" height="250" />
    </ContentLoader>
  ),
}) as LoadableFunctionalComponent<AvatarPickerFieldComponentViewProps>;
