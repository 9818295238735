import { gql } from '@apollo/client';
import { SERVICE_CHECKUP_KIND_FRAGMENT } from '../../../fragment/checkup';

export const ServiceCheckupKinds = gql`
  ${SERVICE_CHECKUP_KIND_FRAGMENT}
  query ServiceCheckupKinds($page: Int, $q: String, $per: Int) {
    serviceCheckupKinds(page: $page, per: $per, q: $q)
      @rest(
        type: "ResponseCheckupKinds"
        path: "/checkups/client/checkup_kinds?{args}"
      ) {
      data @type(name: "CheckupKind") {
        ...ServiceCheckupKindFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const ServiceCheckupKind = gql`
  ${SERVICE_CHECKUP_KIND_FRAGMENT}
  query ServiceCheckupKind($id: String!) {
    serviceCheckupKind(id: $id)
      @rest(
        type: "ResponseCheckupKind"
        path: "/checkups/client/checkup_kinds/{args.id}"
      ) {
      data @type(name: "CheckupKind") {
        ...ServiceCheckupKindFragment
        fields_contract {
          keys
        }
        fields_schema
        provider {
          name
        }
      }
      success
    }
  }
`;

export const ServiceCheckupKindByCode = gql`
  ${SERVICE_CHECKUP_KIND_FRAGMENT}
  query ServiceCheckupKindByCode($checkupKindCode: String!) {
    serviceCheckupKindByCode(checkupKindCode: $checkupKindCode)
      @rest(
        type: "ResponseCheckupKind"
        path: "/checkups/client/checkup_kinds/by_code/{args.checkupKindCode}"
      ) {
      data @type(name: "CheckupKind") {
        ...ServiceCheckupKindFragment
        fields_contract {
          keys
        }
        fields_schema
      }
      success
    }
  }
`;
