import { ContentLoader, GridContainer } from '@aid-module/ui';

export function ListFallback() {
  return (
    <GridContainer gap="20" gridTemplateColumns="repeat(4,1fr)">
      <ContentLoader width="100%" height="180.97">
        <rect rx="4" ry="4" width="100%" height="180.97" />
      </ContentLoader>
      <ContentLoader width="100%" height="180.97">
        <rect rx="4" ry="4" width="100%" height="180.97" />
      </ContentLoader>
      <ContentLoader width="100%" height="180.97">
        <rect rx="4" ry="4" width="100%" height="180.97" />
      </ContentLoader>
      <ContentLoader width="100%" height="180.97">
        <rect rx="4" ry="4" width="100%" height="180.97" />
      </ContentLoader>
      <ContentLoader width="100%" height="180.97">
        <rect rx="4" ry="4" width="100%" height="180.97" />
      </ContentLoader>
      <ContentLoader width="100%" height="180.97">
        <rect rx="4" ry="4" width="100%" height="180.97" />
      </ContentLoader>
      <ContentLoader width="100%" height="180.97">
        <rect rx="4" ry="4" width="100%" height="180.97" />
      </ContentLoader>
      <ContentLoader width="100%" height="180.97">
        <rect rx="4" ry="4" width="100%" height="180.97" />
      </ContentLoader>
    </GridContainer>
  );
}

export function Fallback() {
  return (
    <GridContainer gap="20">
      <ContentLoader width="100%" height="36.4">
        <rect rx="4" ry="4" width="100%" height="36.4" />
      </ContentLoader>
      <GridContainer gap="8">
        <ContentLoader width="100%" height="20.8">
          <rect rx="4" ry="4" width="100%" height="20.8" />
        </ContentLoader>
        <ContentLoader width="100%" height="15.59">
          <rect rx="4" ry="4" width="100%" height="15.59" />
        </ContentLoader>
      </GridContainer>
      <ListFallback />
    </GridContainer>
  );
}
