import { GridContainer } from '../../containers';
import { ToProfile } from '../ToProfile';
import { useProfilesTableInfoController } from './controller';
import { ProfilesTableInfoComponentViewProps } from './types';
import { Profiles } from './components';
import { stopPropagation } from '../../utils';

export function ProfilesTableInfo({
  profiles,
  customer,
  titleButton,
  size = 12,
}: ProfilesTableInfoComponentViewProps) {
  const { profile, count, sortedProfiles } = useProfilesTableInfoController(
    profiles,
    customer
  );

  if (profiles && profile) {
    return (
      <GridContainer
        onClick={stopPropagation}
        gridTemplateColumns="1fr max-content"
        gridTemplateRows="24px"
        gap="6"
        alignItems="center"
      >
        <ToProfile
          profile_id={profile.id}
          text={profile.display_name || profile.email}
          withoutIcon
          size={size}
        />
        <Profiles
          titleButton={titleButton}
          profiles={sortedProfiles}
          count={count}
        />
      </GridContainer>
    );
  }

  return null;
}
