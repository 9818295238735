import { DateFormat } from '@aid-module/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, date, DateSchema, array } from 'yup';

export const validationSchema = object().shape({
  name: string().nullable().required('Required'),
  date_from: date().nullable(),
  reference_key: object().nullable().required('Required'),
  date_to: date()
    .when('date_from', <T extends DateSchema>(value: string, schema: T) => {
      if (!!value) {
        return schema.min(
          value,
          `Date must be later or equal than ${DateFormat.formatDate(value)}`
        );
      }
      return schema;
    })
    .nullable(),
  form_field_reference_keys: array()
    .of(string())
    .nullable()
    .required('Required'),
});

export const resolver = yupResolver(validationSchema);
