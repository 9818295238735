import { createContext, PropsWithChildren, useContext } from 'react';
import { useVariablesContextController } from './controller';
import { VariablesContextParams, VariablesProvidersViewProps } from './types';

export const VariablesContext = createContext<VariablesContextParams>({
  variables: {},
  onChangeVariables() {},
  onRemoveFilter() {},
});

export function useVariablesContext() {
  const context = useContext(VariablesContext);

  return context;
}

export function VariablesProviders({
  children,
  defaultVariables,
  parseSort,
  cacheKey,
}: PropsWithChildren<VariablesProvidersViewProps>) {
  const context = useVariablesContextController(
    defaultVariables,
    parseSort,
    cacheKey
  );

  return (
    <VariablesContext.Provider value={context}>
      {children}
    </VariablesContext.Provider>
  );
}

export { isMultiValue } from './types';
