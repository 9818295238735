import { ContentLoader, GridContainer, TableLoader } from '@aid-module/ui';
import { Container } from '../shared.styles';

export function Fallback() {
  return (
    <Container
      gap="60"
      gridTemplateColumns="470px 1fr"
      gridTemplateRows="max-content"
    >
      <GridContainer gap="20" gridTemplateRows="repeat(2, max-content)">
        <ContentLoader width="100%" height="26px">
          <rect rx="4" ry="4" width="100%" height="26px" />
        </ContentLoader>
        <GridContainer gap="20" gridTemplateRows="repeat(2, max-content)">
          <ContentLoader width="100%" height="302.77px">
            <rect rx="4" ry="4" width="100%" height="302.77px" />
          </ContentLoader>
          <ContentLoader width="100%" height="228.98px">
            <rect rx="4" ry="4" width="100%" height="228.98px" />
          </ContentLoader>
        </GridContainer>
      </GridContainer>
      <GridContainer gap="20">
        <ContentLoader width="100%" height="26px">
          <rect rx="4" ry="4" width="100%" height="26px" />
        </ContentLoader>
        <TableLoader />
      </GridContainer>
    </Container>
  );
}
