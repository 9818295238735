import { DefaultButtonLoader } from '@aid-module/ui';
import { Fallback as UIFallback } from '../../../components';

export function Fallback() {
  return (
    <UIFallback>
      <DefaultButtonLoader />
    </UIFallback>
  );
}
