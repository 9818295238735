import { Link } from '../Link';
import { MultiplePathsLinkContainer } from '../MultiplePathsLinkContainer';
import { useEmailsLinkController } from './controller';

export function EmailsLink() {
  const { emailsPaths, emailAccesiblePath } = useEmailsLinkController();

  return (
    <MultiplePathsLinkContainer paths={emailsPaths}>
      <Link to={emailAccesiblePath} text="Emails" />
    </MultiplePathsLinkContainer>
  );
}
