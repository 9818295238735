import { useCallback, useMemo } from 'react';
import { FieldViewProps } from '../types';
import { useStructureContext } from '../../../../../../../context';

export function useFieldController(field: FieldViewProps['field']) {
  const { selected, onSelect } = useStructureContext();
  const canUseField = useMemo(() => {
    const kind = field.field_template.kind;
    return (
      kind === 'select' ||
      kind === 'multiple_select' ||
      kind === 'radio' ||
      kind === 'boolean' ||
      kind === 'checkbox' ||
      kind === 'multiline' ||
      kind === 'string'
    );
  }, [field.field_template.kind]);

  const onClick = useCallback(() => {
    onSelect(field.reference_key);
  }, [onSelect, field.reference_key]);

  const isSelected = useMemo(
    () => selected?.[field.reference_key],
    [field.reference_key, selected]
  );

  return { canUseField, isSelected, onClick };
}
