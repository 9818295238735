import { useForm } from 'react-hook-form';
import { resolver } from './validationSchema';

export function useFormController() {
  const formContext = useForm({
    resolver,
  });

  return formContext;
}
