import { ContentLoader, GridContainer } from '@aid-module/ui';

export function Fallback() {
  return (
    <GridContainer gap="20" gridTemplateRows="max-content 1fr">
      <ContentLoader width="100%" height="32.2px">
        <rect rx="8" ry="8" width="100%" height="32.2px" />
      </ContentLoader>
      <ContentLoader width="100%" height="138.59px">
        <rect rx="8" ry="8" width="100%" height="138.59px" />
      </ContentLoader>
      <ContentLoader width="100%" height="32.2px">
        <rect rx="8" ry="8" width="100%" height="32.2px" />
      </ContentLoader>
      <ContentLoader width="100%" height="1000px">
        <rect rx="8" ry="8" width="100%" height="1000px" />
      </ContentLoader>
    </GridContainer>
  );
}
