import gql from 'graphql-tag';
import { COMMENT_FRAGMENT } from '../../comment';
import { AML_SCREENING_FRAGMENT } from './amlScreenings';
import { AUDIT_LOGS_FRAGMENT } from './auditLogs';
import { CASE_INFO_FRAGMENT } from './caseInfo';
import { CRYPTOCURRENCY_WALLET_SCREENING_FRAGMENT } from './cryptocurrencyWalletScreeningFragment';
import { DEVICE_INFO_FRAGMENT } from './deviceInfo';
import { IDENTITY_VERIFICATIONS_FRAGMENT } from './identityVerifications';
import { LEGAL_ENTITY_REGISTERS_FRAGMENT } from './legalEntityRegistersFragment';
import { QUESTIONNAIRE_FRAGMENT } from './questionnaire';
import { SUPPORTING_DOCUMENTS_FRAGMENT } from './supportingDocuments';

export const CASE_REPORT_FRAGMENT = gql`
  ${AML_SCREENING_FRAGMENT}
  ${AUDIT_LOGS_FRAGMENT}
  ${CASE_INFO_FRAGMENT}
  ${COMMENT_FRAGMENT}
  ${CRYPTOCURRENCY_WALLET_SCREENING_FRAGMENT}
  ${DEVICE_INFO_FRAGMENT}
  ${IDENTITY_VERIFICATIONS_FRAGMENT}
  ${LEGAL_ENTITY_REGISTERS_FRAGMENT}
  ${QUESTIONNAIRE_FRAGMENT}
  ${SUPPORTING_DOCUMENTS_FRAGMENT}
  ${COMMENT_FRAGMENT}
  fragment CaseReportFragment on CaseReport {
    aml_screenings @type(name: "AMLScreening") {
      ...AMLScreeningFragment
    }
    audit @type(name: "AuditLogs") {
      ...AuditLogsFragment
    }
    case_info @type(name: "CaseInfo") {
      ...CaseInfoFragment
    }
    comments @type(name: "PdfComments") {
      comments @type(name: "PdfComment") {
        id
        created_at
        account @type(name: "PdfCommentAccount") {
          first_name
          last_name
        }
        body
      }
    }
    cryptocurrency_wallet_screening
      @type(name: "CryptocurrencyWalletScreening") {
      ...CryptocurrencyWalletScreeningFragment
    }
    device_info @type(name: "DeviceInfo") {
      ...DeviceInfoFragment
    }
    identity_verification @type(name: "IdentityVerification") {
      ...IdentityVerificationsFragment
    }
    legal_entity_registers @type(name: "LegalEntityRegisters") {
      ...LegalEntityRegistersFragment
    }
    questionnaire @type(name: "Questionnaire") {
      ...QuestionnaireFragment
    }
    supporting_documents @type(name: "SupportingDocuments") {
      ...SupportingDocumentsFragment
    }
  }
`;
