import { getSpace } from '../../../../../utils';
import { Card as UICard } from '../../../../Card';
import styled from '@emotion/styled';

export const Card = styled(UICard)`
  padding: ${getSpace('20')};
  border-radius: 0px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`;
