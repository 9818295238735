import { useCallback } from 'react';
import { TYPES } from '@aid-package/api';
import { Client, Fallback } from './components';
import { ClientsContainer } from './styles';
import { useClientsController } from './controller';

export function Clients() {
  const { clients, loading } = useClientsController();

  const renderClient = useCallback(
    (client: TYPES.DraftClientsQuery['draftClients']['data'][0]) => {
      return client ? (
        <Client
          key={client?.id}
          customer_verification={client?.customer_verification}
          status={client?.status}
          id={client?.id}
          name={client?.name}
        />
      ) : null;
    },
    []
  );

  return loading ? (
    <Fallback />
  ) : (
    <ClientsContainer gap="20">{clients?.map(renderClient)}</ClientsContainer>
  );
}
