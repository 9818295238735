import { showToast } from '@aid-module/ui';
import {
  SubmissionsStatsPresets,
  TYPES,
  useCreateSubmissionsStatsPresetMutation,
} from '@aid-package/api';
import { useCallback } from 'react';

export function useActionsModel() {
  const [mutation, { loading }] = useCreateSubmissionsStatsPresetMutation({
    refetchQueries: [SubmissionsStatsPresets],
  });

  const create = useCallback(
    async (
      submission_field_preset: TYPES.CreateOrUpdateSubmissionsStatsPreset
    ) => {
      const { data } = await mutation({
        variables: {
          body: {
            submission_field_preset,
          },
        },
      });

      if (data?.createSubmissionsStatsPreset?.success) {
        showToast({
          type: 'success',
          message: 'Changes were successfully saved',
        });
        return data?.createSubmissionsStatsPreset.data;
      }
      showToast({
        type: 'error',
        message: 'Something went wrong',
      });
    },
    []
  );

  return { create, loading };
}
