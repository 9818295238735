import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchDataContext } from '../../../../../../AMLResult/context';
import { useHitsContext } from '../../../context';

export function useChangeStatusFormController() {
  const { searchData } = useSearchDataContext();
  const { toggleAll, clearHitIds, checkedAll, openForm } = useHitsContext();

  const canShow = useMemo(
    () => !!searchData?.id && openForm,
    [openForm, searchData?.id]
  );

  const formContext = useForm({});

  return { formContext, checkedAll, canShow, toggleAll, clearHitIds };
}
