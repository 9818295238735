import { DefaultButtonLoader } from '@aid-module/ui';
import { FormLoader } from '../../../components';

export function Fallback() {
  return (
    <FormLoader>
      <DefaultButtonLoader />
    </FormLoader>
  );
}
