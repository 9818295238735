import { useMemo } from 'react';
import { Icons } from '../../../../../../../../Icon/Icon/component/components/icons/types';
import { BodyViewProps } from '../../../types';
import { CheckupsClientExternalRefTypeResponse } from '../../../../../../../constants';

export function useExternalRefItemController(
  type?: NonNullable<
    NonNullable<BodyViewProps['externalRefs']>[0]
    >['external_entity_type']
) {
  const iconByType: keyof typeof Icons = useMemo(() => {
    switch (type) {
      case CheckupsClientExternalRefTypeResponse.CASE:
        return 'briefcase';
      case CheckupsClientExternalRefTypeResponse.IDENFY_RESULT:
        return 'identityVerification';
      case CheckupsClientExternalRefTypeResponse.EVENT:
        return 'transactions';
      case CheckupsClientExternalRefTypeResponse.SUBMISSION:
      case CheckupsClientExternalRefTypeResponse.FORM_SUBMISSION:
        return 'checklist';
      case CheckupsClientExternalRefTypeResponse.PROFILE:
        return 'user';
      default:
        return 'alert';
    }
  }, [type]);

  return {
    iconByType,
  };
}
