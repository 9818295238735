export const CASE_STATUSES = {
  NEW: 'new',
  READY_TO_WORK: 'ready_to_work',
  IN_WORK: 'in_work',
  REQUESTED_FOR_INFORMATION: 'requested_for_information',
  ACCEPTED: 'accepted',
  AUTO_APPROVED: 'auto_approved',
  REJECTED: 'rejected',
  ABANDONED: 'abandoned',
  ON_HOLD: 'on_hold',
  PRE_APPROVED_FOR_REVIEW: 'pre_approved_for_review',
  NO_RESPONSE: 'no_response',
  CLOSED: 'closed',
  PRE_REJECTED: 'pre_rejected',
};

export const CASE_SCREENING_STATUSES = {
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
  FAILED: 'failed',
  NO_INFO: 'no_info',
  CLEAN: 'clean',
  WARNING: 'warning',
  ALARM: 'alarm',
};

export const CASE_DISPLAY_SCREENING_STATUSES = {
  [CASE_SCREENING_STATUSES.IN_PROGRESS]: 'In Progress',
  [CASE_SCREENING_STATUSES.COMPLETED]: 'Completed',
  [CASE_SCREENING_STATUSES.FAILED]: 'Failed',
  [CASE_SCREENING_STATUSES.NO_INFO]: 'No info',
  [CASE_SCREENING_STATUSES.CLEAN]: 'Clean',
  [CASE_SCREENING_STATUSES.WARNING]: 'Warning',
  [CASE_SCREENING_STATUSES.ALARM]: 'True Matches or Alerts',
};

export const CASE_DISPLAY_STATUSES = {
  [CASE_STATUSES.NEW]: 'Draft',
  [CASE_STATUSES.READY_TO_WORK]: 'Waiting List',
  [CASE_STATUSES.IN_WORK]: 'In Review',
  [CASE_STATUSES.REQUESTED_FOR_INFORMATION]: 'Requested for Information',
  [CASE_STATUSES.ACCEPTED]: 'Approved',
  [CASE_STATUSES.AUTO_APPROVED]: 'Auto Approved',
  [CASE_STATUSES.REJECTED]: 'Rejected',
  [CASE_STATUSES.ABANDONED]: 'Abandoned',
  [CASE_STATUSES.ON_HOLD]: 'On hold',
  [CASE_STATUSES.PRE_APPROVED_FOR_REVIEW]: 'Pre-approved for review',
  [CASE_STATUSES.NO_RESPONSE]: 'No Response',
  [CASE_STATUSES.CLOSED]: 'Closed',
  [CASE_STATUSES.PRE_REJECTED]: 'Pre-Rejected',
};
