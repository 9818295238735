import { useSideWidgetProviderContext } from '../../../../../../../SideWidget';
import { useCallback } from 'react';
import { Widget } from '../components';
import { ColumnsViewProps } from '../types';
import { useColumnsContext, ColumnsContext } from '../../../../../../context';

export function useColumnsController(columns: ColumnsViewProps['columns']) {
  const { show } = useSideWidgetProviderContext();
  const context = useColumnsContext();

  const onClickButton = useCallback(() => {
    show(
      <ColumnsContext.Provider value={context}>
        <Widget columns={columns} />
      </ColumnsContext.Provider>,
      'table_columns'
    );
  }, [show, columns, context]);

  return { onClickButton };
}
