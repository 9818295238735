import gql from 'graphql-tag';
import { INSTANT_CHECKUP_FRAGMENT } from '../../../../admin/instantCheckups';

export const AML_INSTANT_CHECKUP_RESULT_FRAGMENT = gql`
  ${INSTANT_CHECKUP_FRAGMENT}
  fragment AMLInstantCheckupResultFragment on AMLInstantCheckupResult {
    instant_checkup_field_submissions
      @type(name: "AMLInstantCheckupFieldSubmission") {
      id
      name
      value
    }
    checkup_result_info_items @type(name: "AMLInstantCheckupResultInfo") {
      id
      raw_info {
        doc {
          name
          types
        }
        match_types
      }
    }
    instant_checkup @type(name: "PdfInstantCheckup") {
      ...InstantCheckupFragment
    }
    id
  }
`;
