import { MouseEvent, useCallback, useMemo } from 'react';
import { useEditContext } from '../../../../context';
import { EditViewProps } from '../types';

export function useEditController(
  id: EditViewProps['id'],
  item: EditViewProps['item']
) {
  const { selectedIds, onChangeSelected, bulkConfig } = useEditContext();

  const value = useMemo(() => !!selectedIds[id], [selectedIds, id]);

  const onChange = useCallback(
    (next?: boolean) => {
      onChangeSelected(id, !!next);
    },
    [onChangeSelected, id]
  );

  const onClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      onChangeSelected(id, !value);
    },
    [onChangeSelected, id, value]
  );

  const disabled = useMemo(() => {
    if (!bulkConfig) return false;

    return !bulkConfig.options[item?.[bulkConfig.key].toString()];
  }, [bulkConfig, item]);

  return { value, disabled, onChange, onClick };
}
