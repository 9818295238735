import gql from 'graphql-tag';
import { SUBMISSION_SCORING_FRAGMENT } from '../../../fragment/scoring';

export const FieldScoring = gql`
  query FieldScoring($id: String!) {
    fieldScoring(id: $id)
      @rest(
        type: "ResponseFieldScoring"
        path: "/scoring/rules/by_form_field/{args.id}"
      ) {
      data @type(name: "FieldScoring") {
        coefficient
        expression
        created_at
        form_field_id
        id
        kind
        name
        rule_select_values
        state
        updated_at
        value
        csv_uploading_enabled
      }
      success
    }
  }
`;

export const SubmissionScoring = gql`
  ${SUBMISSION_SCORING_FRAGMENT}
  query SubmissionScoring($id: String!) {
    submissionScoring(id: $id)
      @rest(
        type: "ResponseSubmissionScoring"
        path: "/scoring/submissions/by_form_submission/{args.id}"
      ) {
      data @type(name: "SubmissionScoring") {
        ...SubmissionScoringFragment
      }
      success
    }
  }
`;
