import { TYPES } from "@aid-package/api";
import { useCallback, useMemo } from "react";

export function useFormSelectController() {
  const reduceData = useCallback(
    (form: TYPES.FormsQuery["forms"]["data"][0]) => ({
      label: form.name,
      value: form.reference_key,
      id: form.id,
    }),
    []
  );

  return {
    reduceData,
  };
}
