import {
  DragDropContext,
  Droppable as UIDropable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import { Draggable } from './components';
import { useDroppableController } from './controller';
import { PlaceholderProps } from './controller/types';
import { Container, Placeholder } from './styles';
import { DroppableViewProps } from './types';

function renderDraggable(
  template: DroppableViewProps['templates'][0],
  index: number
) {
  return <Draggable key={template.id} template={template} index={index} />;
}

function renderDroppable(
  templates: DroppableViewProps['templates'],
  placeholderProps?: PlaceholderProps
) {
  return function (
    provided: DroppableProvided,
    snapshot: DroppableStateSnapshot
  ) {
    return (
      <Container ref={provided.innerRef} {...provided.droppableProps}>
        {templates.map(renderDraggable)}
        {provided.placeholder}
        {placeholderProps && snapshot.isDraggingOver && (
          <Placeholder {...placeholderProps} />
        )}
      </Container>
    );
  };
}

export function Droppable({ templates }: DroppableViewProps) {
  const {
    handleDragEnd,
    handleDragStart,
    handleDragUpdate,
    placeholderProps,
    renderTemplates,
  } = useDroppableController(templates);

  return (
    <DragDropContext
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragUpdate={handleDragUpdate}
    >
      <UIDropable droppableId="droppable">
        {renderDroppable(renderTemplates, placeholderProps)}
      </UIDropable>
    </DragDropContext>
  );
}
