import gql from 'graphql-tag';
import { SERVICE_CHECKUP_FRAGMENT } from '../../../fragment/checkup';

export const CreateCheckup = gql`
  ${SERVICE_CHECKUP_FRAGMENT}
  mutation CreateCheckup($body: RequestCheckupCreate!) {
    createCheckup(body: $body)
      @rest(
        type: "CheckupCreate"
        path: "/checkups/client/checkups/create_and_execute"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "ServiceCheckup") {
        ...ServiceCheckupFragment
        form
        serialized_result
      }
      success
    }
  }
`;

export const ServiceCheckupsBulkChangeStatus = gql`
  mutation ServiceCheckupsBulkChangeStatus(
    $body: RequestServiceCheckupsBulkChangeStatus!
  ) {
    serviceCheckupsBulkChangeStatus(body: $body)
      @rest(
        type: "ServiceCheckupsBulkChangeStatus"
        path: "/checkups/client/checkups/bulk_status_change"
        bodyKey: "body"
        method: "PUT"
      ) {
      success
    }
  }
`;
