import gql from 'graphql-tag';

export const NewCases = gql`
  query NewCases(
    $only_approved: Boolean
    $from_date: String!
    $to_date: String!
    $assigned_to_id: String
    $form_configuration_reference_keys: [String!]
    $auto_audit_state: [String!]
  ) {
    newCases(
      only_approved: $only_approved
      from_date: $from_date
      to_date: $to_date
      assigned_to_id: $assigned_to_id
      form_configuration_reference_keys: $form_configuration_reference_keys
      auto_audit_state: $auto_audit_state
    ) @rest(type: "ResponseNewCases", path: "/stats/client/cases_new?{args}") {
      data {
        cases {
          common {
            accepted_count
            accepted_count_filters
            pre_approved_for_review_count
            pre_approved_for_review_count_filters
            status_update_count
            status_update_count_filters
            case_sharings_count
            case_sharings_filters
            closed_count
            closed_count_filters
            remind_finish_count
            remind_finish_filters
            unassigned_count
            unassigned_count_filters
            waiting_list_count
            waiting_list_count_filters
            with_failed_checkups_count
            with_failed_checkups_filters
          }
          assigned {
            assigned_accepted_count
            assigned_accepted_count_filters
            assigned_pre_approved_for_review_count
            assigned_pre_approved_for_review_filters
            assigned_case_sharings_count
            assigned_case_sharings_filters
            assigned_closed_count
            assigned_closed_count_filters
            assigned_remind_finish_count
            assigned_remind_finish_filters
            assigned_in_review_count
            assigned_in_review_count_filters
            assigned_status_update_count
            assigned_status_update_count_filters
            assigned_waiting_list_count
            assigned_waiting_list_count_filters
            assigned_with_failed_checkups_count
            assigned_with_failed_checkups_filters
          }
        }
      }
      success
    }
  }
`;

export const CasesNewRealStats = gql`
  query CasesNewRealStats(
    $only_approved: Boolean
    $from_date: String!
    $to_date: String!
    $assigned_to_id: String
    $form_configuration_reference_keys: [String!]
    $auto_audit_state: [String!]
  ) {
    casesNewRealStats(
      only_approved: $only_approved
      from_date: $from_date
      to_date: $to_date
      assigned_to_id: $assigned_to_id
      form_configuration_reference_keys: $form_configuration_reference_keys
      auto_audit_state: $auto_audit_state
    )
      @rest(
        type: "ResponseCasesNewRealStats"
        path: "/stats/client/cases_new_real?{args}"
      ) {
      success
      data {
        cases {
          assigned {
            assigned_accepted_count
            assigned_case_sharings_count
            assigned_in_review_count
            assigned_remind_finish_count
            assigned_waiting_list_count
            assigned_pre_approved_for_review_count
            assigned_closed_count
            assigned_closed_count_filters
            assigned_pre_approved_for_review_count_filters
            assigned_accepted_count_filters
            assigned_case_sharings_filters
            assigned_in_review_count_filters
            assigned_remind_finish_filters
            assigned_waiting_list_count_filters
          }
          common {
            accepted_count
            case_sharings_count
            remind_finish_count
            pre_approved_for_review_count
            pre_approved_for_review_count_filters
            closed_count
            closed_count_filters
            waiting_list_count
            accepted_count_filters
            case_sharings_filters
            remind_finish_filters
            waiting_list_count_filters
          }
        }
      }
    }
  }
`;

export const CasesStats = gql`
  query CasesStats(
    $ids: [String!]
    $from_date: String
    $to_date: String
    $from_submission_date: String
    $to_submission_date: String
    $from_approved_date: String
    $to_approved_date: String
    $state: [String!]
    $assigned_to_id: String
    $auto_audit_state: [String!]
    $score_more_then: String
    $score_less_then: String
    $is_shared: String
    $profile_id: String
    $tab_registry_id: String
    $search_by_field: String
    $search_id: String
    $form_configuration_reference_keys: [String!]
    $form_submission_id: String
    $entity_type: String
    $q: String
    $profile_kinds: String
    $bank_tariff_name: String
  ) {
    casesStats(
      ids: $ids
      from_date: $from_date
      to_date: $to_date
      from_submission_date: $from_submission_date
      to_submission_date: $to_submission_date
      from_approved_date: $from_approved_date
      to_approved_date: $to_approved_date
      state: $state
      assigned_to_id: $assigned_to_id
      auto_audit_state: $auto_audit_state
      score_more_then: $score_more_then
      score_less_then: $score_less_then
      is_shared: $is_shared
      profile_id: $profile_id
      tab_registry_id: $tab_registry_id
      search_by_field: $search_by_field
      search_id: $search_id
      form_configuration_reference_keys: $form_configuration_reference_keys
      form_submission_id: $form_submission_id
      entity_type: $entity_type
      q: $q
      profile_kinds: $profile_kinds
      bank_tariff_name: $bank_tariff_name
    )
      @rest(
        type: "ResponseCasesStats"
        path: "/core/client/cases/stats?{args}"
      ) {
      data {
        audit_status {
          alarm
          all_cases
          clean
          completed
          failed
          in_progress
          no_info
          warning
        }
        case_status {
          abandoned
          accepted
          all_cases
          in_work
          new
          on_hold
          ready_to_work
          rejected
          requested_for_information
          auto_approved
          pre_approved_for_review
          no_response
          closed
          pre_rejected
        }
        required_action_sources @type(name: "CaseEventStat") {
          code
          count
          id
          name
        }
        require_actions
      }
      success
    }
  }
`;
