import gql from 'graphql-tag';
import { SERVICE_CHECKUP_FRAGMENT } from '../checkup';

export const AUDIT_FRAGMENT = gql`
  ${SERVICE_CHECKUP_FRAGMENT}
  fragment AuditFragment on Audit {
    account_id
    audit_config_id
    audit_status
    execution_status
    finished_at
    id
    started_at
    updated_at
    updater @type(name: "AuditUpdater") {
      id
      first_name
      last_name
    }
  }
`;
