import { ContentLoader, GridContainer } from '@aid-module/ui';

export function Fallback() {
  return (
    <GridContainer gap="20" gridTemplateRows="max-content 1fr">
      <ContentLoader width="100%" height="36.4px">
        <rect rx="8" ry="8" width="100%" height="36.4px" />
      </ContentLoader>
      <ContentLoader width="100%" height="636.74px">
        <rect rx="8" ry="8" width="100%" height="636.74px" />
      </ContentLoader>
      <ContentLoader width="100%" height="600px">
        <rect rx="8" ry="8" width="100%" height="600px" />
      </ContentLoader>
    </GridContainer>
  );
}
