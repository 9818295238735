import { CheckFeature, useCheckAvailableFeature } from '@aid-package/context';

export function useProtectedRouteController(
  check: CheckFeature,
  strong: boolean
) {
  const { canAvailabel } = useCheckAvailableFeature(check, strong);

  return { canAvailabel };
}
