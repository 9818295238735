import {
  ApolloLink,
  Operation,
  NextLink,
  Observable,
  FetchResult,
} from '@apollo/client';
import axios from 'axios';
import {
  BodyCreateOrUpdateComment,
  Attachment,
  CommentType,
  CreateAttachmentsAttribute,
  ResponseComment,
  BodyCreateTransactionCase,
  BodyUpdateTransactionCase,
  BodyUpdateAlert,
} from '../../api';

const API_HOST = process.env.API_HOST || process.env.REACT_APP_API_HOST;

function* asyncAttachments(
  attachments: CreateAttachmentsAttribute[],
  headers: {}
) {
  for (let i = 0, count = attachments.length; i < count; i++) {
    const attachment = attachments[i];
    if (attachment.id) {
      yield attachment;
    } else {
      yield axios({
        method: 'post',
        url: `${API_HOST}/tm/attachments`,
        data: { attachment },
        headers,
        withCredentials: true,
      }).then((data) => data.data.data as Attachment);
    }
  }
}

export class AttachLink extends ApolloLink {
  constructor() {
    super();
  }

  async getAttachmentIds(
    headers: {},
    attachments_attributes?: CreateAttachmentsAttribute[] | null
  ) {
    const attachment_ids: string[] = [];
    const attachments = attachments_attributes || [];
    for await (const attach of asyncAttachments(attachments, headers)) {
      if (attach.id) attachment_ids.push(attach.id);
    }
    return attachment_ids;
  }

  async getCommentAttachmentIds(
    type: CommentType,
    headers: {},
    attachments_attributes?: CreateAttachmentsAttribute[] | null
  ) {
    if (type === '/tm/comments') {
      return this.getAttachmentIds(headers, attachments_attributes);
    }
    return attachments_attributes;
  }

  attachCommentFiles(
    operation: Operation,
    type: CommentType,
    attachments_attributes: BodyCreateOrUpdateComment['comment']['attachments_attributes']
  ) {
    const context = operation.getContext();
    return new Promise<
      string[] | CreateAttachmentsAttribute[] | null | undefined
    >(async (res) => {
      const attachment_ids = await this.getCommentAttachmentIds(
        type,
        context.headers,
        attachments_attributes
      );
      res(attachment_ids);
    });
  }

  getCommentParams(
    attachment_ids: string[] | CreateAttachmentsAttribute[] | null | undefined
  ) {
    if (!attachment_ids || !attachment_ids.length) {
      return {};
    }
    if (typeof attachment_ids[0] === 'string') {
      return { attachment_ids };
    }
    return { attachments_attributes: attachment_ids };
  }

  mutateCommentOperation(
    operation: Operation,
    body: Omit<BodyCreateOrUpdateComment['comment'], 'attachments_attributes'>,
    attachment_ids: string[] | CreateAttachmentsAttribute[] | null | undefined
  ) {
    const params = this.getCommentParams(attachment_ids);
    operation.variables = {
      ...operation.variables,
      body: {
        comment: {
          ...body,
          ...params,
        },
      },
    };
    return operation;
  }

  comment<T extends Record<string, ResponseComment>>(
    operation: Operation,
    type: CommentType,
    body: BodyCreateOrUpdateComment,
    forward: NextLink
  ) {
    return new Observable<FetchResult<T>>((observer) => {
      const { attachments_attributes, ...anyComment } = body.comment;
      this.attachCommentFiles(operation, type, attachments_attributes).then(
        (attachment_ids) => {
          const nextOperation = this.mutateCommentOperation(
            operation,
            anyComment,
            attachment_ids
          );
          forward(nextOperation).subscribe((data) => {
            observer.next(data as FetchResult<T>);
            observer.complete();
          });
        }
      );
    });
  }

  attachCaseFiles(
    operation: Operation,
    attachments_attributes: (
      | BodyCreateTransactionCase
      | BodyUpdateTransactionCase
    )['case']['attachments_attributes']
  ) {
    const context = operation.getContext();
    return new Promise<string[]>(async (res) => {
      const attachment_ids = await this.getAttachmentIds(
        context.headers,
        attachments_attributes
      );
      res(attachment_ids);
    });
  }

  mutateCaseOperation(
    operation: Operation,
    body: Omit<
      (BodyCreateTransactionCase | BodyUpdateTransactionCase)['case'],
      'attachments_attributes'
    >,
    attachment_ids: string[]
  ) {
    operation.variables = {
      ...operation.variables,
      body: {
        case: {
          ...body,
          attachment_ids:
            attachment_ids.length > 0 ? attachment_ids : undefined,
        },
      },
    };
    return operation;
  }

  case<T extends Record<string, ResponseComment>>(
    operation: Operation,
    body: BodyCreateTransactionCase | BodyUpdateTransactionCase,
    forward: NextLink
  ) {
    return new Observable<FetchResult<T>>((observer) => {
      const { attachments_attributes, ...anyComment } = body.case;
      this.attachCaseFiles(operation, attachments_attributes).then(
        (attachment_ids) => {
          const nextOperation = this.mutateCaseOperation(
            operation,
            anyComment,
            attachment_ids
          );
          forward(nextOperation).subscribe((data) => {
            observer.next(data as FetchResult<T>);
            observer.complete();
          });
        }
      );
    });
  }

  mutateAlertOperation(
    operation: Operation,
    body: Omit<BodyUpdateAlert['alert'], 'attachments_attributes'>,
    attachment_ids: string[]
  ) {
    operation.variables = {
      ...operation.variables,
      body: {
        alert: {
          ...body,
          attachment_ids:
            attachment_ids.length > 0 ? attachment_ids : undefined,
        },
      },
    };
    return operation;
  }

  attachAlertFiles(
    operation: Operation,
    attachments_attributes: BodyUpdateAlert['alert']['attachments_attributes']
  ) {
    const context = operation.getContext();
    return new Promise<string[]>(async (res) => {
      const attachment_ids = await this.getAttachmentIds(
        context.headers,
        attachments_attributes
      );
      res(attachment_ids);
    });
  }

  alert<T extends Record<string, ResponseComment>>(
    operation: Operation,
    body: BodyUpdateAlert,
    forward: NextLink
  ) {
    return new Observable<FetchResult<T>>((observer) => {
      const { attachments_attributes, ...anyComment } = body.alert;
      this.attachCaseFiles(operation, attachments_attributes).then(
        (attachment_ids) => {
          const nextOperation = this.mutateAlertOperation(
            operation,
            anyComment,
            attachment_ids
          );
          forward(nextOperation).subscribe((data) => {
            observer.next(data as FetchResult<T>);
            observer.complete();
          });
        }
      );
    });
  }

  request(operation: Operation, forward: NextLink) {
    const {
      variables: { type, body },
    } = operation;
    switch (operation.operationName) {
      case 'CreateComment':
      case 'UpdateComment':
        return this.comment(operation, type, body, forward);
      case 'CreateTransactionCase':
      case 'UpdateTransactionCase':
        return this.case(operation, body, forward);
      case 'UpdateAlert':
        return this.alert(operation, body, forward);
      default:
        return forward(operation);
    }
  }
}
