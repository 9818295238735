import { gql } from '@apollo/client';

export const CreateTransactionScoringDependentRule = gql`
  mutation CreateTransactionScoringDependentRule(
    $body: BodyCreateTransactionScoringDependentRule!
  ) {
    createTransactionScoringDependentRule(body: $body)
      @rest(
        type: "CreateTransactionScoringRule"
        path: "/tm/scoring/dependent_rules"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "TransactionScoringDependentRule") {
        id
        client_id
        created_at
        if_scoring_rule_id
        if_scoring_rule_score
        scoring_matrix_id
        then_scoring_rule_id
        then_scoring_rule_score
        updated_at
      }
      success
    }
  }
`;

export const UpdateTransactionScoringDependentRule = gql`
  mutation UpdateTransactionScoringDependentRule(
    $id: String!
    $body: BodyUpdateTransactionScoringDependentRule!
  ) {
    updateTransactionScoringDependentRule(id: $id, body: $body)
      @rest(
        type: "UpdateTransactionScoringDependentRule"
        path: "/tm/scoring/dependent_rules/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "TransactionScoringDependentRule") {
        id
        client_id
        created_at
        if_scoring_rule_id
        if_scoring_rule_score
        scoring_matrix_id
        then_scoring_rule_id
        then_scoring_rule_score
        updated_at
      }
      success
    }
  }
`;

export const DeleteTransactionScoringDependentRule = gql`
  mutation DeleteTransactionScoringDependentRule($id: String!) {
    deleteTransactionScoringDependentRule(id: $id)
      @rest(
        type: "DeleteTransactionScoringDependentRule"
        path: "/tm/scoring/dependent_rules/{args.id}"
        method: "DELETE"
      ) {
      success
    }
  }
`;
