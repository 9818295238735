import { useMyPermissionsQuery } from '@aid-package/api';
import { useMemo } from 'react';
import { useClientContext } from '../../client';
import { PermissionsParams, PermissionsParentParams } from '../types';

export function usePermissionsProviderModel() {
  const { client } = useClientContext();
  const { loading, data } = useMyPermissionsQuery({
    fetchPolicy: 'cache-first',
  });

  const permissions = useMemo(() => {
    return data?.myPermissions?.data.reduce<PermissionsParentParams>(
      (acc, parentPermission) => {
        if (parentPermission) {
          const permissions =
            parentPermission.permissions?.reduce<PermissionsParams>(
              (acc, permission) => {
                if (permission) {
                  return {
                    ...acc,
                    [permission.code]: true,
                  };
                }
                return acc;
              },
              {}
            );
          const name = parentPermission.name.replace(' ', '_').toLowerCase();
          return {
            ...acc,
            [name]: permissions,
          };
        }
        return acc;
      },
      {}
    );
  }, [data?.myPermissions?.data]);

  const role = useMemo(
    () =>
      client?.role
        ? {
            [client.role]: true,
          }
        : undefined,
    [client]
  );

  return { permissions, role, loading };
}
