import gql from 'graphql-tag';

export const CreateCaseQuestion = gql`
  mutation CreateCaseQuestion($body: BodyCreateCaseQuestion!) {
    createCaseQuestion(body: $body)
      @rest(
        type: "CreateCaseQuestion"
        path: "/core/client/ai/case_questions"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "CaseQuestion") {
        id
        answer_body
        author @type(name: "Account") {
          avatar_id
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        case_id
        case_question_config_id
        comment
        created_at
        is_marked_as_actual
        is_marked_as_irrelevant
        question_body
        status
        updated_at
      }
      success
    }
  }
`;

export const MarkAsActualCaseQuestion = gql`
  mutation MarkAsActualCaseQuestion(
    $id: String!
    $body: BodyMarkAsActualCaseQuestion!
  ) {
    markAsActualCaseQuestion(id: $id, body: $body)
      @rest(
        type: "MarkAsActualCaseQuestion"
        path: "/core/client/ai/case_questions/{args.id}/mark_as_actual"
        method: "PUT"
        bodyKey: "body"
      ) {
      data @type(name: "CaseQuestion") {
        id
        answer_body
        author @type(name: "Account") {
          avatar_id
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        case_id
        case_question_config_id
        comment
        created_at
        is_marked_as_actual
        is_marked_as_irrelevant
        question_body
        status
        updated_at
      }
      success
    }
  }
`;

export const MarkAsIrrelevantCaseQuestion = gql`
  mutation MarkAsIrrelevantCaseQuestion(
    $id: String!
    $body: BodyMarkAsIrrelevantCaseQuestion!
  ) {
    markAsIrrelevantCaseQuestion(id: $id, body: $body)
      @rest(
        type: "MarkAsIrrelevantCaseQuestion"
        path: "/core/client/ai/case_questions/{args.id}/mark_as_irrelevant"
        method: "PUT"
        bodyKey: "body"
      ) {
      data @type(name: "CaseQuestion") {
        id
        answer_body
        author @type(name: "Account") {
          avatar_id
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        case_id
        case_question_config_id
        comment
        created_at
        is_marked_as_actual
        is_marked_as_irrelevant
        question_body
        status
        updated_at
      }
      success
    }
  }
`;

export const CreateCaseQuestionConfig = gql`
  mutation CreateCaseQuestionConfig(
    $body: BodyCreateOrUpdateCaseQuestionConfig!
  ) {
    createCaseQuestionConfig(body: $body)
      @rest(
        type: "CreateCaseQuestionConfig"
        path: "/core/client/ai/case_question_configs"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "CaseQuestionConfig") {
        id
        case_config_id
        client_id
        created_at
        question_body
        updated_at
      }
      success
    }
  }
`;

export const UpdateCaseQuestionConfig = gql`
  mutation UpdateCaseQuestionConfig(
    $id: String!
    $body: BodyCreateOrUpdateCaseQuestionConfig!
  ) {
    updateCaseQuestionConfig(id: $id, body: $body)
      @rest(
        type: "UpdateAISummaryConfig"
        path: "/core/client/ai/case_question_configs/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "CaseQuestionConfig") {
        id
        case_config_id
        client_id
        created_at
        question_body
        updated_at
      }
      success
    }
  }
`;

export const DeleteCaseQuestionConfig = gql`
  mutation DeleteCaseQuestionConfig($id: String!) {
    deleteCaseQuestionConfig(id: $id)
      @rest(
        type: "DeleteCaseQuestionConfig"
        path: "/core/client/ai/case_question_configs/{args.id}"
        method: "DELETE"
      ) {
      success
    }
  }
`;
