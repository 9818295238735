import { useMemo } from 'react';
import { ClientAvatarComponentViewProps } from '../types';

const API_HOST = process.env.API_HOST || process.env.REACT_APP_API_HOST;

export function useClientAvatarController(
  client: ClientAvatarComponentViewProps['client']
) {
  const src = useMemo(() => {
    if (!client) {
      return;
    }
    if (client.logo_id) {
      return `${API_HOST}/documents/public_download/${client.logo_id}/download`;
    }
    return;
  }, [client]);

  const options = useMemo(
    () => ({
      size: 40,
      fontSize: 20,
      border: 6,
    }),
    []
  );

  return { src, options };
}
