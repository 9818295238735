import {
  Card,
  Checkbox,
  FlexContainer,
  GridContainer,
  Icon,
  Select,
  SelectOption,
  Text,
} from '@aid-module/ui';
import { useMemo } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { Actions, CommentForm } from './components';
import { IsWhitelistedOptions, matchStatusOptions } from './constants';
import { riskLevelOptions } from '../../../../../../../../constants';
import { useChangeStatusFormController } from './controller';
import { FormRenderProps } from './types';
import { ClearSelection, ContentSeparator } from './styles';

const renderMatchStatus =
  (options: SelectOption[]) =>
  ({
    field: { value, onChange },
    fieldState: { error },
  }: FormRenderProps<'match_status'>) => {
    return (
      <Select
        placeholder="Select match status"
        label="Change Match Status to"
        value={value}
        onChange={onChange}
        error={error}
        menuPlacement="auto"
        options={options}
      />
    );
  };

const renderRiskLevel =
  (options: SelectOption[]) =>
  ({
    field: { value, onChange },
    fieldState: { error },
  }: FormRenderProps<'risk_level'>) => {
    return (
      <Select
        placeholder="Select risk level"
        label="Change Risk Level to"
        value={value}
        onChange={onChange}
        error={error}
        menuPlacement="auto"
        options={options}
      />
    );
  };

const renderWhitelisted = ({
  field: { value, onChange },
  fieldState: { error },
}: FormRenderProps<'is_whitelisted'>) => {
  return (
    <Select
      placeholder="Select Whitelist value to"
      label="Change Whitelist value to"
      value={value}
      onChange={onChange}
      error={error}
      menuPlacement="auto"
      options={IsWhitelistedOptions}
    />
  );
};

export function ChangeStatusForm() {
  const { formContext, checkedAll, toggleAll, clearHitIds, canShow } =
    useChangeStatusFormController();

  const fields = useMemo(
    () => ({
      matchStatus: renderMatchStatus(matchStatusOptions),
      riskLevel: renderRiskLevel(riskLevelOptions),
      isWhitelisted: renderWhitelisted,
    }),
    []
  );

  if (canShow) {
    return (
      <Card background="main-deep-1" padding="XS">
        <FormProvider {...formContext}>
          <GridContainer gap="12">
            <FlexContainer alignItems="center" justifyContent="space-between">
              <Checkbox
                label="Select all hits"
                value={checkedAll}
                onChange={toggleAll}
              />
              <ClearSelection
                gridTemplateColumns="14px max-content"
                gap="8"
                alignItems="center"
                onClick={clearHitIds}
              >
                <Icon size={14} glyph="trash" color="main-deep-4" />
                <Text text="Clear selection" color="main-deep-4" />
              </ClearSelection>
            </FlexContainer>
            <ContentSeparator />
            <GridContainer gap="12">
              <Text
                text="ACTIONS FOR SELECTED RESULTS"
                size={12}
                color="gray-deep-3"
                weight="Bold"
              />
              <GridContainer
                gap="20"
                alignItems="end"
                gridTemplateColumns="repeat(5, 1fr)"
              >
                <Controller render={fields.matchStatus} name="match_status" />
                <Controller render={fields.riskLevel} name="risk_level" />
                <Controller
                  render={fields.isWhitelisted}
                  name="is_whitelisted"
                />
                <Actions />
              </GridContainer>
            </GridContainer>
            <CommentForm />
          </GridContainer>
        </FormProvider>
      </Card>
    );
  }

  return null;
}
