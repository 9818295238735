import gql from 'graphql-tag';

export const TRANSACTION_ENTITY_FRAGMENT = gql`
  fragment TransactionEntityFragment on TransactionEntity {
    address
    aid_profile_url
    arival_external_id
    client_id
    country
    created_at
    dob
    entity_created_at
    extended_data
    external_url
    first_name
    id
    kind
    last_name
    source
    name
    profile_id
    status
    unit21_entity_external_id
    unit21_external_id
  }
`;
