import gql from 'graphql-tag';

export const AUDIT_LOGS_FRAGMENT = gql`
  fragment AuditLogsFragment on AuditLogs {
    audit_logs @type(name: "AuditLog") {
      created_at
      display_name
      event_id
      id
      obj_id
      obj_type
      obj_meta @type(name: "AuditLogObjMeta") {
        display_name
      }
      record_type
      subject_id
      subject_type
      subject_meta @type(name: "AuditLogSubjectMeta") {
        display_name
        email
      }
      transaction_id
    }
  }
`;
