import { Container, Label } from './styles';
import { Icon } from '../../../../../../../../../../Icon';
import { ButtonViewProps } from './types';
import { AutomaticControllerTooltipInitiateView } from '../../../../../../../../../../AutomaticTooltip/types';
import { FlexContainer } from '../../../../../../../../../../../containers';

export function Button({
  name,
  onClick,
  isActive,
  initiateRef,
  checkedRef,
}: ButtonViewProps & AutomaticControllerTooltipInitiateView) {
  return (
    <Container
      gridTemplateColumns="minmax(0, auto) max-content"
      alignItems="center"
      gap="8"
      onClick={onClick}
      isActive={isActive}
      ref={checkedRef}
    >
      <Label
        text={name}
        color={isActive ? 'white-primary' : 'main-deep-4'}
        weight="Medium"
      />
      <FlexContainer ref={initiateRef}>
        <Icon size={14} glyph="info" color="main-deep-2" fill="main-deep-4" />
      </FlexContainer>
    </Container>
  );
}
