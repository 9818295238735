import styled from '@emotion/styled/macro';
import { getColor } from '../../../../utils';
import { EmotionContainerViewProps } from './types';
import { FlexContainer } from '../../../../containers';
import { Text } from '../../../Text';

export const DescriptionContainer = styled(FlexContainer)``;

export const TitleContainer = styled(FlexContainer)``;

export const FooterText = styled(Text)`
  position: absolute;
  bottom: 5%;
`;

export const Container = styled(FlexContainer)<EmotionContainerViewProps>`
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  min-height: 500px;

  & > * {
    z-index: 1;
  }

  &.local {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom": 0;
    align-items: flex-start;

    :after {
      color: ${getColor('main-deep-2')};
      line-height: 470px;
      margin: 0 0 0 -90px;
    }

    & > ${DescriptionContainer} {
      flex-direction: row;
      justify-content: center;
      margin: -120px 0 0 3%;

      & > ${TitleContainer} {
        min-width: 720px;
        max-width: 746px;
        align-items: flex-start;
        padding: 0 85px 0 0;
      }
    }
  }
`;
