import styled from '@emotion/styled';
import { ToastContainer } from 'react-toastify';
import { getColor } from '../../utils';

export const StyledToastContainer = styled(ToastContainer)`
  min-width: 588px;

  .Toastify__toast {
    background: transparent;
    box-shadow: none;
    padding: 0;
    min-height: fit-content;
    max-width: fit-content;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 1rem;
    margin-left: auto;

    @media only screen and (max-width: 480px) {
      min-width: 100vw;
      max-width: 100vw;
      margin-left: 0;
    }
  }
  .Toastify__toast-body {
    padding: 0;
    margin: 0;
    min-height: fit-content;
  }

  .notification {
    cursor: default;
  }

  .notification_stack {
    .Toastify__progress-bar {
      bottom: 58px;
      left: 4px;
      right: 6px;
      width: calc(100% - 10px);
      border-bottom-left-radius: 30px;
    }
  }

  .Toastify__progress-bar {
    background-color: transparent;
    height: 4px;
    border-bottom-left-radius: 20px;
    overflow: hidden;

    &--warning {
      background: linear-gradient(
        90deg,
        ${getColor('warning-deep-4')} 0%,
        ${getColor('warning-deep-3')} 25%,
        ${getColor('warning-deep-1')} 50%,
        ${getColor('warning-deep-3')} 75%,
        ${getColor('warning-deep-4')} 100%
      );
    }
    &--error {
      background: linear-gradient(
        90deg,
        ${getColor('error-deep-4')} 0%,
        ${getColor('error-deep-3')} 25%,
        ${getColor('error-deep-2')} 50%,
        ${getColor('error-deep-3')} 75%,
        ${getColor('error-deep-4')} 100%
      );
    }
    &--success {
      background: linear-gradient(
        90deg,
        ${getColor('success-deep-4')} 0%,
        ${getColor('success-deep-5')} 25%,
        ${getColor('success-deep-3')} 50%,
        ${getColor('success-deep-4')} 75%,
        ${getColor('success-deep-5')} 100%
      );
    }
    &--default {
      background: linear-gradient(
        90deg,
        ${getColor('main-deep-4')} 0%,
        ${getColor('main-deep-3')} 25%,
        ${getColor('main-deep-2')} 50%,
        ${getColor('main-deep-3')} 75%,
        ${getColor('main-deep-4')} 100%
      );
    }
  }

  @keyframes Toastify__trackProgress {
    0% {
      transform: scaleX(1);
      background-size: 100%;
    }
    50% {
      background-size: 150%;
      background-position: 0%;
    }
    100% {
      transform: scaleX(0);
      background-size: 200%;
    }
  }
`;
