import { PropsWithChildren } from 'react';
import { LoaderThemeProvider } from '../../shared';
import { Container } from './styles';
import { CardViewProps } from './types';
export * from './types';

export function Card({
  border,
  background,
  padding,
  className,
  loaderBackground,
  loaderForeground,
  borderRadius = 8,
  children,
}: PropsWithChildren<CardViewProps>) {
  return (
    <LoaderThemeProvider
      background={loaderBackground}
      foreground={loaderForeground}
    >
      <Container
        border={border}
        background={background}
        padding={padding}
        className={className}
        borderRadius={borderRadius}
      >
        {children}
      </Container>
    </LoaderThemeProvider>
  );
}
