import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  useConfigContext,
  useVariablesContext,
} from '../../../../../../../../../../../../context';
import { useNavigationContext } from '../../../../../../../context';
import { FormFilterConfig } from './types';

export function useFormController() {
  const { navigation } = useNavigationContext();
  const { variables } = useVariablesContext();
  const filtersConfig = useConfigContext();
  const defaultValues = useMemo(() => {
    if (navigation.type === 'filterTemplatesEdit') {
      const filters = Object.keys(filtersConfig || {}).reduce((acc, key) => {
        return {
          ...acc,
          [key]: navigation.item.meta?.[key] || null,
        };
      }, {});

      return { ...filters, filterTemplateNameSupport: navigation.item.name };
    }

    return Object.keys(filtersConfig || {}).reduce((acc, key) => {
      return {
        ...acc,
        [key]: variables?.filters?.[key] || null,
      };
    }, {});
  }, [variables, navigation]);

  const formContext = useForm({ defaultValues });

  useEffect(() => {
    formContext.reset(defaultValues);
  }, [defaultValues]);

  const filterInputs = useMemo(
    () =>
      Object.entries(filtersConfig || {}).reduce<FormFilterConfig>(
        (acc, item) => {
          const [key, config] = item;
          return [...acc, { ...config, name: key }];
        },
        []
      ),
    []
  );

  return {
    formContext,
    filterInputs,
  };
}
