import { ButtonByType } from '../components';
import { ButtonViewProps } from '../types';

export function ErrorSecondaryButton(props: ButtonViewProps) {
  return (
    <ButtonByType
      {...props}
      color="error-deep-3"
      background="white-primary"
      border="error-deep-3"
    />
  );
}
