import gql from 'graphql-tag';
import { USER_FRAGMENT } from '../../../fragment/account/users';
import { META_FRAGMENT } from '../../../fragment/Meta';

export const Users = gql`
  ${USER_FRAGMENT}
  ${META_FRAGMENT}
  query Users(
    $page: Int
    $status: UserStatus
    $enabled: Boolean
    $permission_codes: String
    $has_assigned_cases: String
    $has_assigned_screenings: String
    $has_created_retention_plans: String
    $role_id: String
    $q: String
  ) {
    users(
      page: $page
      per: 20
      status: $status
      enabled: $enabled
      permission_codes: $permission_codes
      has_assigned_cases: $has_assigned_cases
      has_assigned_screenings: $has_assigned_screenings
      has_created_retention_plans: $has_created_retention_plans
      role_id: $role_id
      q: $q
    )
      @rest(
        type: "ResponseUsers"
        path: "/core/client/accounts_clients?{args}"
      ) {
      data @type(name: "User") {
        ...UserFragment
        roles @type(name: "Role") {
          id
          name
          permissions_count
          client_id
          created_at
          updated_at
        }
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const CheckupsUsers = gql`
  ${USER_FRAGMENT}
  ${META_FRAGMENT}
  query CheckupsUsers(
    $page: Int
    $status: UserStatus
    $permission_codes: String
    $has_assigned_cases: String
    $has_assigned_screenings: String
    $has_created_retention_plans: String
    $q: String
  ) {
    checkupsUsers(
      page: $page
      per: 20
      status: $status
      permission_codes: $permission_codes
      has_assigned_cases: $has_assigned_cases
      has_assigned_screenings: $has_assigned_screenings
      has_created_retention_plans: $has_created_retention_plans
      q: $q
    )
      @rest(
        type: "ResponseUsers"
        path: "/checkups/client/accounts_clients?{args}"
      ) {
      data @type(name: "User") {
        ...UserFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const User = gql`
  ${USER_FRAGMENT}
  query User($id: String!) {
    user(id: $id)
      @rest(
        type: "ResponseUser"
        path: "/core/client/accounts_clients/{args.id}"
      ) {
      data @type(name: "User") {
        ...UserFragment
        roles @type(name: "Role") {
          id
          name
          permissions_count
          client_id
          created_at
          updated_at
        }
      }
      success
    }
  }
`;
