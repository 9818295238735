import { useMemo } from 'react';
import { OnboardingFeeStatusViewProps } from '../types';
import { getColorByStatus } from './utils';
import { ONBOARDING_FEE_DISPLAY_STATUSES } from '../constants';

export function useTmCaseStatusController(
  status: OnboardingFeeStatusViewProps['status']
) {
  const background = useMemo(() => getColorByStatus(status), [status]);

  const text = useMemo(
    () =>
      status
        ? ONBOARDING_FEE_DISPLAY_STATUSES[status] || status.replace('_', '  ')
        : '',
    [status]
  );

  return {
    text,
    background,
  };
}
