import { useRef } from 'react';
import { Controller } from 'react-hook-form';
import { TextInput } from '@aid-module/ui';
import { FormRenderProps } from '../../../../types';
import { StringViewProps } from './types';

export function String({ field }: StringViewProps) {
  const renderString = useRef(
    ({
      field: { value, onChange },
      fieldState: { error },
    }: FormRenderProps<string>) => {
      return (
        <TextInput
          label={`${field.title}${field.required ? ' *' : ''}`}
          placeholder={`Enter ${field.title}...`}
          error={error}
          value={value}
          onChange={onChange}
        />
      );
    }
  );
  return <Controller name={field.code} render={renderString.current} />;
}
