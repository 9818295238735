import styled from '@emotion/styled';
import { EmotionTextProps } from '../../../../../../../../../../../../../../Text/types';
import { getDefaultTextProps } from '../../../../../../../../../../../../../../Text/shared.styles';
import { getSpace } from '../../../../../../../../../../../../../../../utils';

export const ListItem = styled.li<EmotionTextProps>`
  ${(props) => getDefaultTextProps(props)}
  margin-top: ${getSpace('8')};
`;

export const UL = styled.ul`
  margin: -8px 0 0 0;
  padding-left: ${getSpace('12')};
`;
