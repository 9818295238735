import { Icon, IconLoader } from '@aid-module/ui';
import { AnimatePresence, motion } from 'framer-motion';
import { Fallback } from '../Fallback';
import { Menu } from './components';
import { variants } from './constants';
import { ModalContext } from './context';
import { useButtonController } from './controller';
import {
  Container,
  IconContainer,
  NotEmpty,
  NotificationContainer,
} from './styles';

export function Button() {
  const {
    loading,
    open,
    isEmpty,
    notifications,
    hasLoadMore,
    context,
    refContainer,
    loadingCount,
    count,
    toggleOpen,
    loadMore,
    infinityRef,
  } = useButtonController();
  if (loading) {
    return <Fallback />;
  }
  return (
    <ModalContext.Provider value={context}>
      <Container ref={refContainer}>
        <IconContainer
          justifyContent="center"
          alignItems="center"
          onClick={toggleOpen}
        >
          <Icon
            glyph="bell"
            size={24}
            color="main-deep-4"
            fallback={<IconLoader size={24} />}
          />
          {!isEmpty && <NotEmpty />}
        </IconContainer>
        <AnimatePresence>
          {open && (
            <NotificationContainer>
              <motion.div
                variants={variants}
                initial="initial"
                animate="visible"
                exit="exit"
              >
                <Menu
                  isEmpty={isEmpty}
                  notifications={notifications}
                  loadMore={loadMore}
                  count={count}
                  loading={loading}
                  hasLoadMore={hasLoadMore}
                  infinityRef={infinityRef}
                  loadingCount={loadingCount}
                />
              </motion.div>
            </NotificationContainer>
          )}
        </AnimatePresence>
      </Container>
    </ModalContext.Provider>
  );
}
