import gql from 'graphql-tag';

export const SlaPeriodCommon = gql`
  query SlaPeriodCommon(
    $states: [CaseStatus!]
    $kind: SlaPeriodKind!
    $start_at: String!
    $end_at: String!
  ) {
    slaPeriodCommon(
      states: $states
      kind: $kind
      start_at: $start_at
      end_at: $end_at
    )
      @rest(
        type: "ResponseSlaPeriodCommon"
        path: "/stats/client/sla_period_common?{args}"
      ) {
      data {
        assigned_far_to_assigned
        confirmed_to_assigned_far
        created_at
        id
        kind
        total_assigned
        total_assigned_far
        total_confirmed
        updated_at
      }
      success
    }
  }
`;

export const SlaPeriodPersonal = gql`
  query SlaPeriodPersonal(
    $states: [CaseStatus!]
    $kind: SlaPeriodKind!
    $start_at: String!
    $end_at: String!
    $assigned_to_id: [String!]
  ) {
    slaPeriodPersonal(
      states: $states
      kind: $kind
      start_at: $start_at
      end_at: $end_at
      assigned_to_id: $assigned_to_id
    )
      @rest(
        type: "ResponseSlaPeriodPersonal"
        path: "/stats/client/sla_period_personal?{args}"
      ) {
      data {
        assigned_far_to_assigned
        assigned_far_to_assigned_common
        assigned_to_id
        assigned_to_name
        created_at
        id
        kind
        total_assigned
        total_assigned_common
        total_assigned_far
        total_confirmed
        total_confirmed_total_assigned_far
        updated_at
      }
      success
    }
  }
`;
