import { GridContainer } from '../../../../../../../../../../../../../../../../../../../../../../../containers';
import { useActionController } from './controller';
import { Button, ErrorButton } from './styles';
import { ActionsViewProps } from './types';

export function Actions({ template }: ActionsViewProps) {
  const {
    onClickEdit,
    onClickDelete,
    loadingDelete,
    loadingUpdate,
    onClickToFavorite,
    loading,
  } = useActionController(template);
  return (
    <GridContainer gridTemplateColumns="repeat(3, 24px)" gap="10">
      <Button
        type="circle"
        icon="starFilled"
        iconSize={10}
        disabled={loadingUpdate || loading}
        onClick={onClickToFavorite}
      />
      <Button type="circle" icon="pencil" iconSize={10} onClick={onClickEdit} />
      <ErrorButton
        type="circle"
        icon="delete"
        iconSize={10}
        disabled={loadingDelete}
        onClick={onClickDelete}
      />
    </GridContainer>
  );
}
