import { useCallback, useEffect, useRef } from 'react';
import { useContext } from 'react';
import { ConfirmModalContext } from './context';
import isFunction from 'lodash.isfunction';
import { UseConfirmModalOptions } from './types';
import { ModalProps, ResolveType } from './ModalController/types';

export function useConfirmModal<CT extends any[], R extends any>({
  onConfirmCallback,
  onFinallyCallback,
  ...viewProps
}: UseConfirmModalOptions<CT, R>) {
  const context = useContext(ConfirmModalContext);
  const refUuid = useRef<string>();

  const callback = useCallback(
    (resolveType: ResolveType | undefined, ...props: CT) =>
      onConfirmCallback?.(resolveType, ...props),
    [onConfirmCallback]
  );
  const final = useCallback(
    (...props: CT) => onFinallyCallback?.(...props),
    [onFinallyCallback]
  );

  const init = useRef(() => {
    const uuid = context.create(viewProps);
    refUuid.current = uuid;
    return uuid;
  });

  useEffect(() => {
    const uuid = init.current();

    const deinit = () => {
      context.remove(uuid);
    };

    return () => {
      deinit();
    };
  }, []);

  const update = useRef((props: ModalProps) => {
    if (refUuid.current) {
      context.update(refUuid.current, props);
    }
  });

  useEffect(() => {
    update.current(viewProps);
  }, [viewProps]);

  const show = useCallback(
    async (...props: CT) => {
      if (isFunction(props?.[0]?.preventDefault)) {
        props?.[0]?.preventDefault();
      }
      if (refUuid.current) {
        try {
          const resolveType = await context.show(refUuid.current);
          return callback(resolveType, ...props);
        } catch (e) {
        } finally {
          return final(...props);
        }
      }
      return;
    },
    [context, callback, final]
  );

  return { show };
}
