import { GridContainer, Text } from '@aid-module/ui';
import { Button, Card } from './styles';
import { FieldViewProps } from './types';
import { useFieldController } from './controller';

export function Field({ field }: FieldViewProps) {
  const { canUseField, isSelected, onClick } = useFieldController(field);
  return (
    <Card
      background={
        canUseField
          ? isSelected
            ? 'warning-deep-1'
            : 'main-deep-1'
          : 'gray-deep-1'
      }
      loaderForeground="main-deep-1"
      loaderBackground="main-deep-2"
    >
      <GridContainer
        alignItems="center"
        gridTemplateColumns="1fr max-content"
        gap="20"
      >
        <GridContainer gap="4">
          <Text size={12} weight="Medium" text={field.name || '-'} />
          <Text
            size={12}
            weight="Medium"
            text={`${field.field_template.name}, ${
              field.options?.validation?.required ? 'Required' : 'Optional'
            }`}
            color="gray-deep-3"
          />
        </GridContainer>
        {canUseField ? (
          isSelected ? (
            <Button onClick={onClick} color="error-deep-4">
              <Text text="-" color="white-primary" size={12} />
            </Button>
          ) : (
            <Button onClick={onClick} color="main-deep-4">
              <Text text="+" color="white-primary" size={12} />
            </Button>
          )
        ) : null}
      </GridContainer>
    </Card>
  );
}
