import { gql } from '@apollo/client';

export const CreateTransactionResolution = gql`
  mutation CreateTransactionResolution(
    $body: BodyCreateTransactionResolution!
  ) {
    createTransactionResolution(body: $body)
      @rest(
        type: "UpdateTransactionResolution"
        path: "/tm/resolutions"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "TransactionResolution") {
        id
        name
        color
      }
      success
      errors {
        name
      }
    }
  }
`;
