import gql from 'graphql-tag';

export const CreateCaseReminder = gql`
  mutation CreateCaseReminder($body: CreateOrUpdateCaseReminderBody!) {
    createCaseReminder(body: $body)
      @rest(
        type: "CreateCaseReminder"
        path: "/core/client/case_reminders"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "CaseReminder") {
        active
        canceled_at
        canceled_by @type(name: "Account") {
          email
          full_name
          id
          phone
        }
        canceled_by_id
        case_id
        completed_at
        created_at
        created_by @type(name: "Account") {
          email
          full_name
          id
          phone
        }
        created_by_id
        finished_at
        id
        name
        case_section_kind
        remind_is_finished
        reminder_kinds @type(name: "ReminderEvent") {
          case_section_kind
          created_at
          id
          name
          repeatable
          repeatable_interval_days
          updated_at
        }
        repeatable
        repeatable_interval_days
        started_at
        updated_at
      }
      success
    }
  }
`;

export const UpdateCaseReminder = gql`
  mutation UpdateCaseReminder(
    $id: String!
    $body: CreateOrUpdateCaseReminderBody!
  ) {
    updateCaseReminder(id: $id, body: $body)
      @rest(
        type: "UpdateCaseReminder"
        path: "/core/client/case_reminders/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "CaseReminder") {
        active
        canceled_at
        canceled_by @type(name: "Account") {
          email
          full_name
          id
          phone
        }
        canceled_by_id
        case_id
        completed_at
        created_at
        created_by @type(name: "Account") {
          email
          full_name
          id
          phone
        }
        created_by_id
        finished_at
        id
        name
        case_section_kind
        remind_is_finished
        reminder_kinds @type(name: "ReminderEvent") {
          case_section_kind
          created_at
          id
          name
          repeatable
          repeatable_interval_days
          updated_at
        }
        repeatable
        repeatable_interval_days
        started_at
        updated_at
      }
      success
    }
  }
`;

export const CreateCaseReminderKind = gql`
  mutation CreateCaseReminderKind($body: CreateOrUpdateCaseReminderKindBody!) {
    createCaseReminderKind(body: $body)
      @rest(
        type: "CreateCaseReminderKind"
        path: "/core/client/reminder_kinds"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "ReminderKind") {
        case_section_kind
        created_at
        id
        name
        repeatable
        repeatable_interval_days
        updated_at
      }
      success
    }
  }
`;

export const UpdateCaseReminderKind = gql`
  mutation UpdateCaseReminderKind(
    $id: String!
    $body: CreateOrUpdateCaseReminderKindBody!
  ) {
    updateCaseReminderKind(id: $id, body: $body)
      @rest(
        type: "UpdateCaseReminderKind"
        path: "/core/client/reminder_kinds/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "ReminderKind") {
        case_section_kind
        created_at
        id
        name
        repeatable
        repeatable_interval_days
        updated_at
      }
      success
    }
  }
`;

export const CreateCaseReminderSetting = gql`
  mutation CreateCaseReminderSetting(
    $body: CreateOrUpdateCaseReminderSettingBody!
  ) {
    createCaseReminderSetting(body: $body)
      @rest(
        type: "CreateCaseReminderSetting"
        path: "/core/client/reminder_settings"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "ReminderSetting") {
        active
        case_state
        client_id
        id
        reminder_kind @type(name: "ReminderKind") {
          case_section_kind
          created_at
          id
          name
          repeatable
          repeatable_interval_days
          updated_at
        }
        reminder_kind_id
        score_rules {
          score_from
          score_to
        }
      }
      success
    }
  }
`;

export const UpdateCaseReminderSetting = gql`
  mutation UpdateCaseReminderSetting(
    $id: String!
    $body: CreateOrUpdateCaseReminderSettingBody!
  ) {
    updateCaseReminderSetting(id: $id, body: $body)
      @rest(
        type: "UpdateCaseReminderSetting"
        path: "/core/client/reminder_settings/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "ReminderSetting") {
        active
        case_state
        client_id
        id
        reminder_kind @type(name: "ReminderKind") {
          case_section_kind
          created_at
          id
          name
          repeatable
          repeatable_interval_days
          updated_at
        }
        reminder_kind_id
        score_rules {
          score_from
          score_to
        }
      }
      success
    }
  }
`;

export const DeleteCaseReminderSetting = gql`
  mutation DeleteCaseReminderSetting($id: String!) {
    deleteCaseReminderSetting(id: $id)
      @rest(
        type: "UpdateCaseReminderSetting"
        path: "/core/client/reminder_settings/{args.id}"
        method: "DELETE"
      ) {
      data {
        id
      }
      success
    }
  }
`;

export const DeleteCaseReminderKind = gql`
  mutation DeleteCaseReminderKind($id: String!) {
    deleteCaseReminderKind(id: $id)
      @rest(
        type: "UpdateCaseReminderSetting"
        path: "/core/client/reminder_kinds/{args.id}"
        method: "DELETE"
      ) {
      data {
        id
      }
      success
    }
  }
`;
