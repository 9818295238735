import { Card, Checkbox, GridContainer, Text, TextInput } from '@aid-module/ui';
import { FormRenderProps } from './types';
import { Controller } from 'react-hook-form';
import { ClientTypesSelect, ClientMethodsSelect } from './components';
import { Border } from './styles';
import { PropsWithChildren } from 'react';

function renderString<TName extends 'name' | 'description'>(
  label: string,
  placeholder: string
) {
  return function ({
    field: { value, onChange },
    fieldState: { error },
  }: FormRenderProps<TName>) {
    return (
      <TextInput
        label={label}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        error={error}
      />
    );
  };
}

function renderClientTypes({
  field: { value, onChange },
  fieldState: { error },
}: FormRenderProps<'client_type'>) {
  return <ClientTypesSelect value={value} onChange={onChange} error={error} />;
}

function renderClientChannel({
  field: { value, onChange },
  fieldState: { error },
}: FormRenderProps<'client_channel'>) {
  return (
    <ClientMethodsSelect
      label="Transaction methods"
      placeholder="Select transaction methods"
      value={value}
      onChange={onChange}
      error={error}
    />
  );
}

function renderIsTest({
  field: { value, onChange },
}: FormRenderProps<'is_test'>) {
  return <Checkbox label="Test matrix" value={value} onChange={onChange} />;
}

const fields = {
  name: renderString<'name'>('Matrix name', 'Enter name'),
  description: renderString<'description'>(
    'Matrix description',
    'Enter description'
  ),
  client_type: renderClientTypes,
  client_channel: renderClientChannel,
  is_test: renderIsTest,
};

export function MainForm({ children }: PropsWithChildren<{}>) {
  return (
    <Card
      background="main-deep-1"
      loaderForeground="main-deep-1"
      loaderBackground="main-deep-2"
    >
      <GridContainer gap="14">
        <GridContainer
          gridTemplateColumns="1fr 1fr"
          gap="20"
          alignItems="flex-start"
        >
          <Controller name="name" render={fields.name} />
          <Controller name="description" render={fields.description} />
        </GridContainer>
        <Border />
        <GridContainer gap="12">
          <Text
            text="This transaction risks score will apply to transactions of following clients:"
            color="main-deep-4"
          />
          <GridContainer
            gridTemplateColumns="1fr 1fr"
            gap="20"
            alignItems="flex-start"
          >
            <Controller name="client_type" render={fields.client_type} />
            {/* <Controller name="client_channel" render={fields.client_channel} /> */}
          </GridContainer>
        </GridContainer>
        <GridContainer
          gridTemplateColumns="max-content"
          alignItems="center"
          justifyContent="flex-end"
          gridTemplateRows="30.2px"
        >
          {children}
        </GridContainer>
      </GridContainer>
    </Card>
  );
}
