import { SecondaryButton, ModalButtonViewProps } from '@aid-module/ui';
import { useButtonController } from './contoller';

export function Button({ onOpen }: ModalButtonViewProps) {
  const { isDisabled, onClick } = useButtonController(onOpen);

  return (
    <SecondaryButton
      text="Create business client"
      disabled={isDisabled}
      onClick={onClick}
      icon="plus"
    />
  );
}
