import { ContentLoader, GridContainer } from '@aid-module/ui';

export function Fallback() {
  return (
    <GridContainer gap="12">
      <ContentLoader width="100%" height="42.3px">
        <rect rx="4" ry="4" width="100%" height="42.3px" />
      </ContentLoader>
      <ContentLoader width="100%" height="800px">
        <rect rx="4" ry="4" width="100%" height="800px" />
      </ContentLoader>
    </GridContainer>
  );
}
