import gql from 'graphql-tag';
import { FORM_FRAGMENT, FORM_SCREEN_FRAGMENT } from '../../../fragment/form';

export const CreateForm = gql`
  ${FORM_FRAGMENT}
  ${FORM_SCREEN_FRAGMENT}
  mutation CreateForm($body: BodyCreateOrUpdateForm!) {
    createForm(body: $body)
      @rest(
        type: "CreateForm"
        path: "/core/client/forms"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "Form") {
        ...FormFragment
        form_screens @type(name: "FormScreen") {
          ...FormScreenFragment
        }
      }
      success
    }
  }
`;

export const UpdateForm = gql`
  ${FORM_FRAGMENT}
  ${FORM_SCREEN_FRAGMENT}
  mutation UpdateForm($id: String!, $body: BodyCreateOrUpdateForm!) {
    updateForm(id: $id, body: $body)
      @rest(
        type: "UpdateForm"
        path: "/core/client/forms/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "Form") {
        ...FormFragment
        form_screens @type(name: "FormScreen") {
          ...FormScreenFragment
        }
      }
      success
    }
  }
`;

export const CreateRevision = gql`
  ${FORM_FRAGMENT}
  ${FORM_SCREEN_FRAGMENT}
  mutation CreateRevision($id: String!, $body: BodyCreateOrUpdateForm!) {
    createRevision(id: $id, body: $body)
      @rest(
        type: "CreateRevision"
        path: "/core/client/forms/{args.id}/create_revision"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "Form") {
        ...FormFragment
        form_screens @type(name: "FormScreen") {
          ...FormScreenFragment
        }
      }
      success
    }
  }
`;
