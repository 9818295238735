import { LoadableFunctionalComponent } from '@aid-module/ui';
import loadable from '@loadable/component';
import { FormContainer } from '../components';
import { Fallback } from './components';

export const TagCreate = loadable(() => import('./component'), {
  resolveComponent: (components) => components.TagCreateComponent,
  fallback: (
    <FormContainer isLoading label="Create Form Tag">
      <Fallback />
    </FormContainer>
  ),
}) as LoadableFunctionalComponent<{}>;
