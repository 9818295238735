import gql from 'graphql-tag';

export const CASE_SECTION_QUESTIONNAIRE_ITEM_FRAGMENT = gql`
  fragment CaseSectionQuestionnaireItemFragment on CaseSectionQuestionnaireItem {
    created_at
    file_name
    file_size
    form_submission_id
    id
    updated_at
    uploaded_document_id
    kind
    form_submission_id
    form_screen @type(name: "PdfFormGroup") {
      created_at
      id
      name
      options
      position
      tags
      updated_at
    }
  }
`;
