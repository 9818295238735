import { PropsWithChildren } from 'react';
import { DefaultLink } from '../DefaultLink';
import { LinkViewProps } from '../types';
import { IconLoader } from '../../../../shared';
import { Icon } from '../../../Icon';

export function Link({
  size = 14,
  color = 'main-deep-4',
  ...props
}: PropsWithChildren<LinkViewProps>) {
  return (
    <DefaultLink {...props} size={size} color={color}>
      <Icon
        glyph="link"
        size={size}
        color={color}
        fallback={<IconLoader size={size} />}
      />
    </DefaultLink>
  );
}
