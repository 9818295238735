import { showToast } from '@aid-module/ui';
import { useOnboardingUrlLazyQuery } from '@aid-package/api';
import { useCallback } from 'react';

export function useClientModel() {
  const [load, { loading }] = useOnboardingUrlLazyQuery({
    fetchPolicy: 'network-only',
  });

  const getUrl = useCallback(
    async (client_draft_id: string) => {
      const { data } = await load({
        variables: { id: client_draft_id },
      });
      if (data?.onboardingUrl?.success) {
        return data?.onboardingUrl?.data?.url;
      }
      showToast({
        type: 'error',
        message: 'Something went wrong',
      });
    },
    [load]
  );

  return { getUrl, loading };
}
