import { LoadableFunctionalComponent } from '@aid-module/ui';
import loadable from '@loadable/component';
import { FormContainer } from '../../components';
import { Fallback } from './components';

export const RegionUpdate = loadable(() => import('./component'), {
  resolveComponent: (components) => components.RegionUpdateComponent,
  fallback: (
    <FormContainer label="Update Region" icon="users" isLoading>
      <Fallback />
    </FormContainer>
  ),
}) as LoadableFunctionalComponent<{}>;
