import { FormCard, GridContainer, Text, FormCardViewProps } from '@aid-module/ui';
import { PropsWithChildren } from 'react';

export function CardContainer({
  children,
  isLoading,
}: PropsWithChildren<Pick<FormCardViewProps, 'isLoading'>>) {
  return (
    <GridContainer gridTemplateColumns="510px" justifyContent="center">
      <FormCard
        label="BLACKLISTED PROFILE"
        icon="kycDocVerification"
        isLoading={isLoading}
      >
        <GridContainer gap="32">
          <Text
            text="Description"
            size={21}
            weight="Medium"
            color="main-deep-4"
            align="center"
            uppercase
          />
          {children}
        </GridContainer>
      </FormCard>
    </GridContainer>
  );
}
