import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { RemindersNavigationItems } from '../../../../../../Breadcrumbs/constants';

export function useRemindersLinkController() {
  const { client_id } = useParams();

  const mainPath = useMemo(
    () => `/${client_id}${RemindersNavigationItems[0].path}`,
    [client_id]
  );

  const remindersPaths = useMemo(() => {
    return RemindersNavigationItems.map((item) => `/${client_id}${item.path}`);
  }, [client_id]);

  return {
    remindersPaths,
    mainPath,
  };
}
