import { gql } from '@apollo/client';

export const CreateRule = gql`
  mutation CreateRule($body: BodyCreateOrUpdateTransactionRule!) {
    createRule(body: $body)
      @rest(
        type: "CreateRule"
        path: "/tm/rules"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "Rule") {
        async
        code
        created_at
        deleted
        description
        created_by @type(name: "Account") {
          first_name
          id
          last_name
        }
        enabled
        id
        name
        is_for_test
        provider_id
        provider @type(name: "RuleProvider") {
          client_id
          created_at
          enabled
          external
          id
          kind
        }
        ruleset_id
        settings
      }
      success
    }
  }
`;

export const TransactionRuleUpdate = gql`
  mutation TransactionRuleUpdate(
    $rule_id: String!
    $body: TransactionRuleUpdateBody!
  ) {
    transactionRuleUpdate(rule_id: $rule_id, body: $body)
      @rest(
        type: "TransactionRuleUpdateBody"
        path: "/tm/rules/{args.rule_id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "Rule") {
        group_title
        events_to
        events_from
        description_for_i
        async
        code
        created_at
        deleted
        description
        enabled
        id
        is_scheduled
        name
        created_by @type(name: "Account") {
          first_name
          id
          last_name
        }
        description
        interval
        provider_id
        ruleset_id
        settings
        is_for_test
        rule_importance @type(name: "TransactionRuleImportance") {
          id
          name
          client_id
          code
          created_at
        }
        provider @type(name: "RuleProvider") {
          id
          kind
          external
          enabled
          created_at
          client_id
          settings
        }
      }
      success
      error
    }
  }
`;

export const TransactionRuleRunScheduled = gql`
  mutation TransactionRuleRunScheduled(
    $rule_id: String!
    $client_id: String!
    $body: TransactionRuleRunScheduledBody!
  ) {
    transactionRuleRunScheduled(
      rule_id: $rule_id
      client_id: $client_id
      body: $body
    )
      @rest(
        type: "TransactionRuleRunScheduledBody"
        path: "/tm/rules/{args.rule_id}/run_scheduled?client_id={args.client_id}"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "Rule") {
        group_title
        events_to
        events_from
        description_for_i
        async
        code
        created_at
        deleted
        description
        enabled
        id
        is_scheduled
        name
        created_by @type(name: "Account") {
          first_name
          id
          last_name
        }
        description
        interval
        provider_id
        ruleset_id
        settings
        is_for_test
        rule_importance @type(name: "TransactionRuleImportance") {
          id
          name
          client_id
          code
          created_at
        }
        provider @type(name: "RuleProvider") {
          id
          kind
          external
          enabled
          created_at
          client_id
          settings
        }
      }
      success
      error
    }
  }
`;
