import gql from 'graphql-tag';

export const FieldUpdatesHistory = gql`
  query FieldUpdatesHistory($form_submission_id: String!, $field_submission_id: String!) {
    fieldUpdatesHistory(form_submission_id: $form_submission_id, field_submission_id: $field_submission_id)
    @rest(
      type: "ResponseFieldUpdatesHistory"
      path: "/core/client/field_update_claims?form_submission_id={args.form_submission_id}&field_submission_id={args.field_submission_id}"
    ) {
      data @type(name: "FieldUpdateItem") {
        id
        account_display_name
        account_id
        comment
        created_at
        field_submission_id
        new_uploaded_document_id
        old_uploaded_document_id
        new_value
        old_value
        status
        value_type
        field_submission @type(name: "UpdateFieldSubmission") {
          id
          submission_id
          field_update_claims_count
          form_field_id
          form_group_index
          form_group_subindex
          value
          uploaded_document_id
        }
      }
      errors
      success
    }
  }
`;
