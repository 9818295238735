import { useMemo } from 'react';
import { THEME_COLORS } from '../../../shared';
import { Icons } from '../../Icon/Icon/component/components/icons/types';
import { TYPE_PLATE } from './constants';

export function usePlateController(type: keyof typeof TYPE_PLATE) {
  const glyph = useMemo<keyof typeof Icons>(() => {
    switch (type) {
      case TYPE_PLATE.success:
      case TYPE_PLATE.passed:
      case TYPE_PLATE.approved:
        return 'checkmark';
      case TYPE_PLATE.fail:
      case TYPE_PLATE.failed:
      case TYPE_PLATE.unprocessed:
      case TYPE_PLATE.denied:
      case TYPE_PLATE.suspected:
      case TYPE_PLATE.expired:
        return 'cross';
      default:
        return 'checkmark';
    }
  }, [type]);

  const colors = useMemo<Record<string, keyof THEME_COLORS>>(() => {
    switch (type) {
      case TYPE_PLATE.success:
      case TYPE_PLATE.passed:
      case TYPE_PLATE.approved:
        return {
          general: 'success-deep-4',
          background: 'success-deep-1',
        };
      case TYPE_PLATE.fail:
      case TYPE_PLATE.failed:
      case TYPE_PLATE.unprocessed:
      case TYPE_PLATE.denied:
      case TYPE_PLATE.suspected:
      case TYPE_PLATE.expired:
        return {
          general: 'error-deep-4',
          background: 'error-deep-1',
        };
      default:
        return {
          general: 'success-deep-4',
          background: 'success-deep-1',
        };
    }
  }, [type]);

  const text = useMemo(() => {
    switch (type) {
      case TYPE_PLATE.success:
        return 'Successful';
      case TYPE_PLATE.fail:
      case TYPE_PLATE.failed:
        return 'Fail';
      case TYPE_PLATE.passed:
        return 'Passed';
      case TYPE_PLATE.unprocessed:
        return 'Unprocessed';
      case TYPE_PLATE.denied:
        return 'Denied';
      case TYPE_PLATE.approved:
        return 'Approved';
      case TYPE_PLATE.suspected:
        return 'Suspected';
      case TYPE_PLATE.expired:
        return 'Expired';
      default:
        return 'Unexpected state';
    }
  }, [type]);

  return { glyph, colors, text };
}
