import styled from '@emotion/styled';
import { getColor, getSpace } from '../../../../../../utils';
import { GridContainer } from '../../../../../../containers';

export const ProfilesButton = styled(GridContainer)`
  padding: ${getSpace('4')} ${getSpace('8')};
  border-radius: 4px;
  background-color: ${getColor('main-deep-4')};
  width: max-content;
  cursor: context-menu;
`;
