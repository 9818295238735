import { Text } from '../../../../../../../../../../../../../../../../Text';
import { Actions } from './components';
import { Container } from './styles';
import { TemplateViewProps } from './types';

export function Template({ template }: TemplateViewProps) {
  return (
    <Container
      gridTemplateColumns="1fr max-content"
      gap="10"
      alignItems="center"
    >
      <Text text={template.name} weight="Bold" color="main-deep-4" />
      <Actions template={template} />
    </Container>
  );
}
