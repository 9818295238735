import { AsyncSelect } from '@aid-module/ui';
import { ServiceCheckupKinds } from '@aid-package/api';
import { useCheckupKindSelectController } from './contoller';
import { SelectContainer } from './styles';

export function CheckupKindsSelect() {
  const { reduceData, onChange, checkupKind } = useCheckupKindSelectController();

  return (
    <SelectContainer>
      <AsyncSelect
        value={checkupKind}
        onChange={onChange}
        isSearchable={true}
        query={ServiceCheckupKinds}
        label='Select screening'
        placeholder='Select screening type...'
        reduceData={reduceData}
      />
    </SelectContainer>
  );
}
