import gql from 'graphql-tag';
import { ACCOUNT_FRAGMENT } from '../admin/accounts';
import { CUSTOMER_FRAGMENT } from '../cm/Customer';
import { PROFILE_FRAGMENT } from '../profiles/profiles';
import { FORM_AUDIT_FRAGMENT } from './FormAudit';
import { FORM_CONFIGURATION_FRAGMENT } from './FormConfiguration';

export const SUBMISSION_FRAGMENT = gql`
  ${FORM_CONFIGURATION_FRAGMENT}
  ${FORM_AUDIT_FRAGMENT}
  ${CUSTOMER_FRAGMENT}
  ${ACCOUNT_FRAGMENT}
  ${PROFILE_FRAGMENT}
  fragment SubmissionFragment on Submission {
    audit_status
    created_at
    account @type(name: "Account") {
      ...AccountFragment
    }
    customer @type(name: "Customer") {
      ...CustomerFragment
    }
    form_audit @type(name: "FormAudit") {
      ...FormAuditFragment
    }
    form_configuration @type(name: "FormConfiguration") {
      ...FormConfigurationFragment
    }
    data_retention @type(name: "DataRetentionInfo") {
      full_delete_date
      period_start_date
      retention_plan_id
      retention_state
      soft_delete_date
    }
    profiles @type(name: "Profile") {
      avatar_id
      created_at
      created_by
      display_name
      email
      id
      is_whitelisted
      kind
      phone
      updated_at
    }
    display_name
    form_invitation_id
    id
    is_actual_revision
    profile_display_name
    reference_key
    revision_number
    status
    ubt_submission_ref
    updated_at
  }
`;
