import { useSideWidgetProviderContext } from '@aid-module/ui';
import { useCallback } from 'react';
import { Widget } from '../components';

export function useActionController() {
  const { show } = useSideWidgetProviderContext();

  const onClick = useCallback(() => {
    show(<Widget />, 'create_submission_statistic');
  }, []);

  return { onClick };
}
