import { gql } from '@apollo/client';

export const TransactionRuleTemplates = gql`
  query TransactionRuleTemplates {
    transactionRuleTemplates
      @rest(
        type: "ResponseTransactionRuleTemplates"
        path: "/tm/rules/templates"
      ) {
      data @type(name: "Rule") {
        group_title
        events_to
        events_from
        description_for_i
        async
        code
        created_at
        deleted
        description
        enabled
        id
        is_scheduled
        name
        description
        interval
        provider_id
        ruleset_id
        settings
        scenario @type(name: "Scenario") {
          text
          scheduled_text_addition
          fields
        }
        is_for_test
        rule_importance @type(name: "TransactionRuleImportance") {
          id
          name
          client_id
          code
          created_at
        }
        provider @type(name: "RuleProvider") {
          id
          kind
          external
          enabled
          created_at
          client_id
          settings
        }
        workflow_queue @type(name: "TransactionQueue") {
          id
          alert_deadline_days
          created_at
          description
          is_default
          name
          open_alerts_count
          role_ids
        }
      }
      success
    }
  }
`;

export const TransactionRule = gql`
  query TransactionRule($rule_id: String!) {
    transactionRule(rule_id: $rule_id)
      @rest(type: "ResponseTransactionRule", path: "/tm/rules/{args.rule_id}") {
      data @type(name: "Rule") {
        rule_no
        group_title
        events_to
        events_from
        description_for_i
        async
        code
        created_at
        deleted
        description
        enabled
        id
        is_scheduled
        name
        created_by @type(name: "Account") {
          first_name
          id
          last_name
        }
        description
        interval
        provider_id
        ruleset_id
        settings
        is_for_test
        rule_importance @type(name: "TransactionRuleImportance") {
          id
          name
          client_id
          code
          created_at
        }
        provider @type(name: "RuleProvider") {
          id
          kind
          external
          enabled
          created_at
          client_id
          settings
        }
        workflow_queue @type(name: "TransactionQueue") {
          id
          alert_deadline_days
          created_at
          description
          is_default
          name
          open_alerts_count
          role_ids
        }
        scenario @type(name: "Scenario") {
          text
          scheduled_text_addition
          fields
        }
      }
      success
      error
    }
  }
`;

export const TransactionRules = gql`
  query TransactionRules(
    $page: Int
    $per: Int
    $q: String
    $enabled: String
    $is_for_test: Boolean
  ) {
    transactionRules(
      page: $page
      per: $per
      q: $q
      enabled: $enabled
      is_for_test: $is_for_test
    ) @rest(type: "ResponseTransactionRules", path: "/tm/rules?{args}") {
      data @type(name: "Rule") {
        rule_no
        async
        code
        created_by @type(name: "Account") {
          first_name
          id
          last_name
        }
        created_at
        deleted
        description
        enabled
        id
        name
        provider_id
        ruleset_id
        settings
        is_for_test
        is_scheduled
        rule_importance @type(name: "TransactionRuleImportance") {
          id
          name
          client_id
          code
          created_at
        }
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const TransactionRuleResults = gql`
  query TransactionRuleResults($rule_id: String!, $page: Int, $per: Int) {
    transactionRuleResults(rule_id: $rule_id, page: $page, per: $per)
      @rest(
        type: "ResponseTransactionRuleResults"
        path: "/tm/rule_results?{args}"
      ) {
      data @type(name: "TransactionRuleResult") {
        id
        aid_checkup_id
        created_at
        is_for_test
        state
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const TransactionRuleFlaggedCounts = gql`
  query TransactionRuleFlaggedCounts($rule_id: String!) {
    transactionRuleFlaggedCounts(rule_id: $rule_id)
      @rest(
        type: "ResponseTransactionRuleFlaggedCounts"
        path: "/tm/rules/{args.rule_id}/flagged_counts"
      ) {
      data @type(name: "RuleFlaggedCounts") {
        alerts_count
        entities_count
        events_count
        instruments_count
      }
      success
      error
    }
  }
`;
