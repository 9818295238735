import { Card } from '@aid-module/ui';
import { PropsWithChildren } from 'react';
import { ContentContainer } from './styles';

export function Container({ children }: PropsWithChildren<{}>) {
  return (
    <Card
      padding="S"
      background="main-deep-1"
      loaderBackground="main-deep-2"
      loaderForeground="main-deep-1"
    >
      <ContentContainer
        gridTemplateColumns="minmax(100px, 1fr) max-content"
        gap="20"
        alignItems="center"
        gridTemplateRows="43.19px"
      >
        {children}
      </ContentContainer>
    </Card>
  );
}
