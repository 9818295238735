import { Form } from './components';
import { useCommentFormController } from './controller';

export function CommentForm() {
  const { canShow } = useCommentFormController();

  if (canShow) {
    return <Form />;
  }

  return null;
}
