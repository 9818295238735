import { useTariffNamesQuery } from '@aid-package/api';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

export function useBankTariffSelectModel() {
  const date_from = useWatch({ name: 'date_from', defaultValue: undefined });
  const date_to = useWatch({ name: 'date_from', defaultValue: undefined });

  const { data } = useTariffNamesQuery({
    variables: {
      date_from,
      date_to,
    },
  });

  const tariffOptions = useMemo(() => {
    if (data?.tariffNames.data) {
      return Object.keys(data?.tariffNames.data).map((item) => ({
        label: `${item} (${data?.tariffNames.data[item]})`,
        value: item,
      }));
    }

    return [];
  }, [data?.tariffNames.data]);

  return {
    tariffOptions,
  };
}
