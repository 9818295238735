import gql from 'graphql-tag';
import { VERIFICATION_STEP_PASSING_FRAGMENT } from './VerificationStepPassing';
import { CASE_FRAGMENT } from '../cm';

export const CUSTOMER_VERIFICATION_FRAGMENT = gql`
  ${VERIFICATION_STEP_PASSING_FRAGMENT}
  ${CASE_FRAGMENT}
  fragment CustomerVerificationFragment on CustomerVerification {
    case_id
    cases @type(name: "Case") {
      ...CaseFragment
    }
    redirect_url
    created_at
    customer_id
    expired
    expires_at
    first_name
    id
    last_name
    pin_code
    profile_id
    profile_name
    profiles @type(name: "Profile") {
      avatar_id
      created_at
      display_name
      email
      id
      is_whitelisted
      kind
      phone
      updated_at
    }
    started_at
    status
    updated_at
    verification_flow_id
    verification_step_passings @type(name: "VerificationStepPassing") {
      ...VerificationStepPassingFragment
    }
  }
`;
