import styled from '@emotion/styled';
import { Card, GridContainer, getSpace, getColor } from '@aid-module/ui';

export const NewClientCard = styled(Card)`
  border-radius: 4px;
  margin-bottom: ${getSpace('20')};
`;

export const IconContainer = styled(GridContainer)`
  padding: ${getSpace('10')};
  background: ${getColor('main-deep-2')};
  border-radius: 8px;
`;
