import { useMemo } from 'react';
import { CaseStatusViewProps } from '../types';
import { getColorByStatus } from './utils';
import {
  CASE_DISPLAY_STATUSES,
  CASE_DISPLAY_SCREENING_STATUSES,
} from '../constants';

export function useCaseStatusController(
  status: CaseStatusViewProps['status'],
  isAudit: CaseStatusViewProps['isAudit'],
  isWhitelisted: CaseStatusViewProps['isWhitelisted']
) {
  const background = useMemo(() => getColorByStatus(status), [status]);

  const text = useMemo(() => {
    if (status) {
      if (isAudit) {
        return CASE_DISPLAY_SCREENING_STATUSES[status];
      }

      if (isWhitelisted) {
        return `${CASE_DISPLAY_STATUSES[status]} (Whitelisted)`;
      }

      return CASE_DISPLAY_STATUSES[status];
    }

    if (!status && !isAudit) {
      return CASE_DISPLAY_STATUSES.new;
    }

    return '';
  }, [status, isAudit]);

  return {
    background,
    text,
  };
}
