import { createPortal } from 'react-dom';
import { View } from './components';
import { PortalWidgetViewProps } from './types';
import { cloneElement, useMemo } from 'react';
import { usePortalWidgetController } from './controller';

export function PortalWidget({
  componentKey,
  button,
  children,
}: PortalWidgetViewProps) {
  const { show, push, widgetOptions, containerName, onTransitionEnd } =
    usePortalWidgetController(componentKey);

  const widgetButton = useMemo(
    () => cloneElement(button, { show, push }),
    [button]
  );
  return (
    <>
      {widgetButton}
      {widgetOptions.currentWidget
        ? createPortal(
            <View
              state={widgetOptions.state}
              position={widgetOptions.currentWidget.position}
              onTransitionEnd={onTransitionEnd.current}
              key={componentKey}
            >
              {children}
            </View>,
            document.getElementById(containerName) as HTMLElement
          )
        : null}
    </>
  );
}
