import { gql } from '@apollo/client';
import { AUDIT_CONFIG_FRAGMENT } from '../../../fragment/checkup';
import { CHECKUP_CONFIG_FRAGMENT } from '../../../fragment/checkup/CheckupConfig';

export const CreateAuditConfig = gql`
  ${AUDIT_CONFIG_FRAGMENT}
  ${CHECKUP_CONFIG_FRAGMENT}
  mutation CreateAuditConfig($body: BodyCreateAuditConfig!) {
    createAuditConfig(body: $body)
      @rest(
        type: "CreateAuditConfig"
        path: "/checkups/client/audit_configs"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "AuditConfig") {
        ...AuditConfigFragment
        account @type(name: "Account") {
          email
          first_name
          id
          last_name
        }
        checkup_configs @type(name: "CheckupConfig") {
          ...CheckupConfigFragment
        }
      }
      success
    }
  }
`;

export const UpdateAuditConfig = gql`
  ${AUDIT_CONFIG_FRAGMENT}
  ${CHECKUP_CONFIG_FRAGMENT}
  mutation UpdateAuditConfig($id: String!, $body: BodyUpdateAuditConfig!) {
    updateAuditConfig(id: $id, body: $body)
      @rest(
        type: "UpdateAuditConfig"
        path: "/checkups/client/audit_configs/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "AuditConfig") {
        ...AuditConfigFragment
        account @type(name: "Account") {
          email
          first_name
          id
          last_name
        }
        checkup_configs @type(name: "CheckupConfig") {
          ...CheckupConfigFragment
        }
      }
      success
    }
  }
`;

export const UpdateLaunchConfig = gql`
  ${AUDIT_CONFIG_FRAGMENT}
  ${CHECKUP_CONFIG_FRAGMENT}
  mutation UpdateLaunchConfig($id: String!, $body: BodyLaunchConfig!) {
    updateLaunchConfig(id: $id, body: $body)
      @rest(
        type: "UpdateLaunchConfig"
        path: "/checkups/client/launch_configs/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "AuditLaunchConfig") {
        id
        ongoing_enabled
        ongoing_ends_at
        ongoing_period
        ongoing_starts_at
      }
      success
    }
  }
`;
