import gql from 'graphql-tag';
import { CASE_SECTION_DEVICE_INFO_FRAGMENT } from './CaseSectionDeviceInfoItem';

export const DEVICE_INFO_FRAGMENT = gql`
  ${CASE_SECTION_DEVICE_INFO_FRAGMENT}
  fragment DeviceInfoFragment on DeviceInfo {
    case_section @type(name: "CaseSectionDeviceInfo") {
      case_section_items @type(name: "CaseSectionDeviceInfoItem") {
        ...CaseSectionDeviceInfoItemFragment
      }
      case_section_items_count
      created_at
      id
      kind
      state
      updated_at
    }
  }
`;
