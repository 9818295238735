import gql from 'graphql-tag';

export const FORM_SCREEN_FRAGMENT = gql`
  fragment FormScreenFragment on FormScreen {
    created_at
    form_configuration_id
    form_groups @type(name: "FormGroup") {
      checkup_ids
      checkups
      created_at
      form_fields @type(name: "FormField") {
        created_at
        description
        field_select_values
        client_dictionary @type(name: "ClientDictionaryItem") {
          id
          name
          code
        }
        client_dictionary_id
        use_name_as_value
        field_template @type(name: "FormFieldTemplate") {
          code
          common
          created_at
          defaults
          field_select_values
          id
          kind
          name
          options
          updated_at
        }
        preloaded_document_id
        field_template_id
        id
        name
        options
        position
        protected_tags
        reference_key
        tags
        updated_at
      }
      form_screen_id
      id
      name
      options
      position
      repeatable
      tags
      updated_at
    }
    id
    name
    options
    position
    tags
    updated_at
  }
`;
