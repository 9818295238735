import { useClientContext } from '@aid-package/context';
import { useEffect, useMemo } from 'react';

export function useCheckAccountController() {
  const { client } = useClientContext();

  const isAccountsExist = useMemo(
    () => !!client?.accounts_clients?.length,
    [client]
  );

  useEffect(() => {
    if (!client) {
      window.location.href = `${process.env.REACT_APP_API_HOST}/auth/login?redirect_uri=${window.location.href}`;
    }
  }, [client]);

  return { isAccountsExist, client };
}
