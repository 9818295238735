import gql from 'graphql-tag';
import { FIELD_TEMPLATE_FRAGMENT } from '../../../fragment/admin/fieldTemplates';

export const FieldTemplateUpdate = gql`
  ${FIELD_TEMPLATE_FRAGMENT}
  mutation FieldTemplateUpdate(
    $id: String!
    $body: BodyCreateOrUpdateFieldTemplate!
  ) {
    updateFieldTemplate(id: $id, body: $body)
      @rest(
        type: "FieldTemplateUpdate"
        path: "/core/admin/field_templates/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "FieldTemplate") {
        ...FieldTemplateFragment
      }
      success
    }
  }
`;

export const FieldTemplateCreate = gql`
  ${FIELD_TEMPLATE_FRAGMENT}
  mutation FieldTemplateCreate($body: BodyCreateOrUpdateFieldTemplate!) {
    createFieldTemplate(body: $body)
      @rest(
        type: "FieldTemplateCreate"
        path: "/core/admin/field_templates"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "FieldTemplate") {
        ...FieldTemplateFragment
      }
      success
    }
  }
`;
