import { MouseEvent, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormProps } from '../../../types';
import { useActionModel } from '../model';
import { useHitsContext } from '../../../../../../../../../context';

export function useActionsController() {
  const { createComment, loading } = useActionModel();
  const { formState, handleSubmit } = useFormContext<FormProps>();
  const { setOpenCommentForm } = useHitsContext();

  const onSubmit = useCallback(
    async (value: FormProps) => {
      const res = await createComment(value.comment);
      if (res) {
        setOpenCommentForm?.(false);
      }
    },
    [createComment]
  );

  const onClose = useCallback(
    () => setOpenCommentForm(false),
    [setOpenCommentForm]
  );

  const onClickSubmit = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      handleSubmit(onSubmit)(e);
    },
    [handleSubmit, onSubmit]
  );

  return {
    disabled: !formState.isDirty || loading,
    onClickSubmit,
    onClose,
  };
}
