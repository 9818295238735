import { Text } from '@aid-module/ui';
import { useCopyrightController } from './controller';

export function Copyright() {
  const { copyrightText } = useCopyrightController();

  return (
    <Text text={copyrightText} size={12} color="main-deep-4" weight="Medium" />
  );
}
