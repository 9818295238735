import gql from 'graphql-tag';

export const CdsFormConfigurations = gql`
  query CdsFormConfigurations(
    $q: String
    $page: Int
    $per: Int
    $only_not_mapped: Boolean
  ) {
    cdsFormConfigurations(
      q: $q
      page: $page
      per: $per
      only_not_mapped: $only_not_mapped
    )
      @rest(
        type: "ResponseCdsFormConfigurations"
        path: "/cds/form_configurations?{args}"
      ) {
      data @type(name: "CdsFormConfiguration") {
        client_id
        created_at
        enabled
        form_mapping @type(name: "CdsFormMapping") {
          created_at
          form_configuration_id
          id
          status
        }
        updated_at
        form_submissions_count
        global
        id
        is_actual_revision
        name
        reference_key
        revision_number
        technical_name
        updated_at
      }
      meta {
        count
        page
        items
        pages
      }
      success
    }
  }
`;

export const CdsFormConfiguration = gql`
  query CdsFormConfiguration($id: String!) {
    cdsFormConfiguration(id: $id)
      @rest(
        type: "ResponseCdsFormConfiguration"
        path: "/cds/form_configurations/{args.id}"
      ) {
      data @type(name: "CdsFormConfiguration") {
        client_id
        created_at
        enabled
        form_fields @type(name: "FormField") {
          created_at
          description
          field_template_id
          id
          name
          options
          position
          protected_tags
          reference_key
          tags
          updated_at
        }
        form_mapping @type(name: "CdsFormMapping") {
          created_at
          form_configuration_id
          id
          status
        }
        updated_at
        form_submissions_count
        global
        id
        is_actual_revision
        name
        reference_key
        revision_number
        technical_name
        updated_at
      }
      success
    }
  }
`;
