import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export function useBreadcrumbItemModel(path?: string) {
  const navigate = useNavigate();

  const navigateToPath = useCallback(
    () => path && navigate(path),
    [navigate, path]
  );

  return { navigateToPath };
}
