import { Text } from '../../../Text';
import { Icon } from '../../../Icon';
import { Container, ContentContainer, IconContainer } from './styles';
import { ToastComponentViewProps } from './types';
import { IconLoader } from '../../../../shared';
import { PropsWithChildren } from 'react';

export function ToastComponent({
  message,
  description,
  icon,
  color,
  children,
  callback,
}: PropsWithChildren<ToastComponentViewProps>) {
  return (
    <Container
      gridTemplateColumns="64px minmax(0px, 1fr)"
      gridTemplateRows="1rf"
      color={color}
      alignItems="center"
      onClick={callback}
    >
      <IconContainer
        color={color}
        flex={1}
        justifyContent="center"
        alignItems="center"
      >
        <Icon
          glyph={icon}
          color="white-primary"
          size={24}
          fallback={<IconLoader size={24} />}
        />
      </IconContainer>
      <ContentContainer gridTemplateRows="1fr" gap="12">
        <Text text={message} size={18} color={color} weight="Bold" uppercase />
        {children}
        {description && <Text text={description} size={14} weight="Medium" />}
      </ContentContainer>
    </Container>
  );
}
